import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerSupportTicket } from "../../../domain/models/CustomerSupportTicket";
import customerSupportTicketServiceFactory from "../../../services/CustomerSupportTicketServiceImpl";
import CustomCheckbox from "../../base/CustomCheckbox";
import Popup from "reactjs-popup";
import ModalWindow from "../../base/ModalWindow";
import CustomerSupportTicketEditForm from "./CustomerSupportTicketEditForm";

interface CustomerSupportTicketDetailsProps {
    t(key?: string): string;
}

const CustomerSupportTicketDetails: FC<CustomerSupportTicketDetailsProps> = (props) => {
    const t = props.t;
    const navigate = useNavigate();
    const { customerSupportTicketId } = useParams();

    const cstService = customerSupportTicketServiceFactory();

    const [customerSupportTicket, setCustomerSupportTicket] = useState<CustomerSupportTicket>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    useEffect(() => {
        const uploadData = async () => {
            cstService.getCachedCustomerSupportTicketById(customerSupportTicketId).then((customerSupportTicket) => {
                setCustomerSupportTicket(customerSupportTicket);
            });
            setCustomerSupportTicket(null);
        };

        if (!customerSupportTicket) {
            uploadData();
        }
    }, [customerSupportTicket, cstService, customerSupportTicketId]);

    const navigateToPayments = () => {
        navigate("/admin/customer-support-tickets");
    };

    const showEditPopup = () => {
        console.log("showEditPopup");
        setShowEditModal(true);
    };

    const resendEmail = async () => {
        if (!customerSupportTicket || customerSupportTicket.emailSent) {
            return;
        }
        const model = await cstService.resendCustomerSupportTicketEmail(customerSupportTicket.id);
        if (model) {
            customerSupportTicket.assignData(model);
            /**
             * That is not mistake. Need to call render function and for that
             * customerSupportTicket should change. Because function is using closure
             * next call replaces model pointer to original customerSupportTicket
             */
            setCustomerSupportTicket(model);
            setCustomerSupportTicket(customerSupportTicket);
        }
    };

    return (
        <main className="main">
            <div className="inner-page__topping">
                <div className="inner-page__wrap">
                    <button onClick={() => navigateToPayments()} className="back-button">
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1.5 5L6 9" stroke="#F9F9F9" strokeLinecap="round"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <section className="inner-page">
                <div className="holy">
                    <table className="holy__info cst__details-table">
                        <tbody>
                            <tr>
                                <td className="holy__info-descr">{t("admin.customerSupport.table.ticketName")}</td>
                                <td className="holy__info-value cst__details-table-c2">{customerSupportTicket?.ticketName}</td>
                            </tr>

                            <tr>
                                <td className="holy__info-descr">{t("admin.customerSupport.table.fullName")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.fullName}</td>
                            </tr>

                            <tr>
                                <td className="holy__info-descr">{t("fields.email")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.email}</td>
                            </tr>

                            <tr>
                                <td className="holy__info-descr">{t("fields.country")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.country}</td>
                            </tr>

                            <tr>
                                <td className="holy__info-descr">{t("fields.phoneNumber")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.phoneNumber}</td>
                            </tr>

                            <tr>
                                <td className="holy__info-descr">{t("admin.customerSupport.table.customerRole")}</td>
                                <td className="holy__info-value">
                                    {t(`enums.supportTicketCustomerRole.${customerSupportTicket?.customerRole}`)}
                                </td>
                            </tr>

                            <tr>
                                <td className="holy__info-descr">{t("admin.customerSupport.table.subjectType")}</td>
                                <td className="holy__info-value">
                                    {t(`enums.supportTicketSubject.${customerSupportTicket?.subjectType}`)}
                                </td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">
                                    {t("admin.customerSupport.table.isCustomerRegistered")}
                                </td>
                                <td className="holy__info-value date">
                                    <CustomCheckbox
                                        defaultValue={false}
                                        value={customerSupportTicket?.isCustomerRegistered}
                                        className="checkbox__input"
                                        additionalLabelClassName="date__text"
                                        wrapperClassName="order__checkbox simple-checkbox"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">
                                    {t("admin.customerSupport.table.isEmailRegistered")}
                                </td>
                                <td className="holy__info-value date">
                                    <CustomCheckbox
                                        defaultValue={false}
                                        value={customerSupportTicket?.isEmailRegistered}
                                        className="checkbox__input"
                                        additionalLabelClassName="date__text"
                                        wrapperClassName="order__checkbox simple-checkbox"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">{t("admin.customerSupport.table.emailSent")}</td>
                                <td className="holy__info-value date">
                                    <CustomCheckbox
                                        defaultValue={false}
                                        value={customerSupportTicket?.emailSent}
                                        className="checkbox__input"
                                        additionalLabelClassName="date__text"
                                        wrapperClassName="order__checkbox simple-checkbox"
                                    />
                                    {customerSupportTicket && !customerSupportTicket.emailSent && (
                                        <button className="blue-button" onClick={resendEmail}>
                                            {t("admin.customerSupport.details.sendEmailButtonLabel")}
                                        </button>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">{t("fields.status")}</td>
                                <td className="holy__info-value">
                                    {t(`enums.recordStatus.${customerSupportTicket?.status}`)}
                                </td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">{t("admin.customerSupport.fields.description")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.description}</td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">{t("fields.comment")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.comment}</td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">{t("fields.language")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.language.toUpperCase()}</td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">{t("fields.createdAt")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.createdAtStr}</td>
                            </tr>
                            <tr>
                                <td className="holy__info-descr">{t("fields.updatedAt")}</td>
                                <td className="holy__info-value">{customerSupportTicket?.updatedAtStr}</td>
                            </tr>
                        </tbody>
                    </table>

                    <button
                        className="blue-button"
                        style={{ marginTop: "20px", height: "46px", width: "150px" }}
                        onClick={showEditPopup}
                    >
                        {t("admin.customerSupport.details.editButtonLabel")}
                    </button>

                    <Popup
                        open={!!showEditModal}
                        modal
                        overlayStyle={{ zIndex: 999999 }}
                        onClose={() => setShowEditModal(false)}
                    >
                        <ModalWindow
                            closeCallback={() => setShowEditModal(false)}
                            bodyClass="modal--main__body cst__modal-container"
                            content={
                                <CustomerSupportTicketEditForm
                                    closeCallback={() => setShowEditModal(false)}
                                    customerSupportTicket={customerSupportTicket}
                                />
                            }
                        />
                    </Popup>
                </div>
            </section>
        </main>
    );
};

export default withTranslation()(CustomerSupportTicketDetails);

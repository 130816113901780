export enum Entity {
    TEMPLE = 1,
    ARTICLE = 2,
    ARTIFACT = 3,
    BANK_ACCOUNT = 4,
    PAYMENT_ACCOUNT = 5,
    PRAYER = 6,
    PRAYER_PRICE_DETAIL = 7,
    PRAYER_REQUIREMENT = 8,
    WEEK_SCHEDULE = 9,
    WORSHIP_SCHEDULE = 10,
    FILE = 11
}

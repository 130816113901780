import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";
import { PaymentAccountDTO } from "../domain/models/dto/PaymentAccountDTO";
import PaymentAccountModel from "../models/PaymentAccount";
import PaymentAccount from "../models/PaymentAccount";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";

class PaymentAccountMapper extends SimpleChangesRequestEntityMapper<
    PaymentAccount,
    PaymentAccountDTO
> {
    protected entityConstructor = PaymentAccountModel;

    protected toDTOFields: string[] = [
        'id',
        'order',
        'templeId',
        'paymentMethod',
        'accountId',
        'recipientName',
        'currency',
        'isDeleted'
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        ...this.toChangesDTOFields,
        "status",
        "createdAt",
        "updatedAt",
    ];

    toDTO(model: PaymentAccount): PaymentAccountDTO {
        if (!model) {
            return null;
        }

        const result = super.toDTO(model);
        result.currency = +result.currency;
        result.paymentMethod = +result.paymentMethod;

        return result;
    }
}

export default function paymentAccountMapperFactory(): ChangesRequestEntityMapper<
    PaymentAccount,
    PaymentAccountDTO
> {
    return new PaymentAccountMapper();
}

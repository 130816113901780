import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormsValidConfirmation } from "../../../../context/FormikFormsProvider";
import { WeekScheduleForm } from "../../../../domain/models/dto/WeekScheduleDTO";
import { Temple } from "../../../../domain/models/Temple";
import { CreateTempleParams } from "../../../../enums/create-temple-params-enum";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import weekScheduleMapperFactory from "../../../../mappers/WeekScheduleMapper";
import weekScheduleServiceFactory from "../../../../services/WeekScheduleServiceImpl";
import { useRedirectToPrevCreateTemplePage } from "../../../../tools/custom-hooks/useRedirect";
import { WeekScheduleSchemaFactory } from "../../../../validation-schemas/temple-register/week-schedule-schemas";
import Footer from "../footer/Footer";
import WeekSchedule from "./week-schedule/WeekSchedule";
import MainWorshipScheduleComponent from "./worship-schedule/MainWorshipScheduleComponent";
import ConflictErrorModal from "../../../base/conflict-error-modal/ConflictErrorModal";

interface SchedulesProps {
    t(key?: string): string;
    temple: Temple;
    setTemple(value: Temple): void;
}

const Schedules: FC<SchedulesProps> = (props) => {
    const initialWeekScheduleFormValues = {
        mondayBegin: props.temple.weekSchedule?.mondayBegin,
        mondayEnd: props.temple.weekSchedule?.mondayEnd,

        tuesdayBegin: props.temple.weekSchedule?.tuesdayBegin,
        tuesdayEnd: props.temple.weekSchedule?.tuesdayEnd,

        wednesdayBegin: props.temple.weekSchedule?.wednesdayBegin,
        wednesdayEnd: props.temple.weekSchedule?.wednesdayEnd,

        thursdayBegin: props.temple.weekSchedule?.thursdayBegin,
        thursdayEnd: props.temple.weekSchedule?.thursdayEnd,

        fridayBegin: props.temple.weekSchedule?.fridayBegin,
        fridayEnd: props.temple.weekSchedule?.fridayEnd,

        saturdayBegin: props.temple.weekSchedule?.saturdayBegin,
        saturdayEnd: props.temple.weekSchedule?.saturdayEnd,

        sundayBegin: props.temple.weekSchedule?.sundayBegin,
        sundayEnd: props.temple.weekSchedule?.sundayEnd,
    };

    const weekScheduleService = weekScheduleServiceFactory();
    const navigate = useNavigate();
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    useRedirectToPrevCreateTemplePage(props.temple, TempleRegisterPage.ABBOT, props.temple.isAbbotComplete);

    const weekScheduleForm: FormikProps<WeekScheduleForm> = useFormik({
        enableReinitialize: true,
        initialValues: initialWeekScheduleFormValues,
        validationSchema: WeekScheduleSchemaFactory({ t: props.t }),
        onSubmit: async (values) => {
            const temple = props.temple;
            const nextUrl = "/create-temple/visitors-info".concat(`?${CreateTempleParams.ID}=${temple.originId}`);

            try {
                setErrorVisibility(false);

                if (_.isEqual(values, weekScheduleForm.initialValues) && temple.weekSchedule.id) {
                    navigate(nextUrl);
                    return;
                }

                const newWeekSchedule = await weekScheduleService.createRequestOrUpdateCurrentEntity(
                    weekScheduleMapperFactory().dateToDTO({
                        ...values, templeId: temple.originId, id: temple.weekSchedule?.id
                    })
                );
                if (!newWeekSchedule?.id) {
                    return setErrorVisibility(true);
                }
                temple.weekSchedule.assignData(newWeekSchedule);

                if (newWeekSchedule) {
                    // templeService.notifyListeners(newTemple);

                    props.setTemple(temple);

                    navigate(nextUrl);
                }
            } catch (err) {
                console.log(err)
                setErrorVisibility(true);
            }
        },
    });

    const { isFormsDataValid } = useFormsValidConfirmation("weekScheduleForm", weekScheduleForm);

    return (
        <div className="date__content">
            <form onSubmit={weekScheduleForm.handleSubmit}>
                <main className="date__main">
                    <h1 className="date__title">
                        {props.t("templeRegister.base.title1")}
                        <br />
                        {props.t("templeRegister.base.title2")}
                    </h1>

                    <WeekSchedule

                        temple={props.temple}
                        isErrorVisible={isErrorVisible}
                        weekScheduleForm={weekScheduleForm}
                    />

                    <MainWorshipScheduleComponent  temple={props.temple} />
                </main>
                <ConflictErrorModal />
                <Footer isFormsDataValid={isFormsDataValid} />
            </form>
        </div>
    );
};

export default withTranslation()(Schedules);

import { StatusCodes } from "http-status-codes";
import { FC } from "react";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { errorServiceFactory } from "../../../services/ErrorServiceImpl";
import { Translate } from "../../../types/Translate";
import ModalWindow from "../ModalWindow";

interface ConflictErrorModalProps {
    t: Translate;
}

const errorService = errorServiceFactory();

const ConflictErrorModal: FC<ConflictErrorModalProps> = (props) => {
    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <Popup
            open={errorService.getStatusFromErrorResponseSubject(StatusCodes.CONFLICT)}
            modal
            overlayStyle={{ zIndex: 999999 }}
            onClose={reloadPage}
        >
            <ModalWindow
                contentClass="modal-remove__content"
                bodyClass=" "
                closeCallback={reloadPage}
                content={
                    <>
                        <div className="modal__text">{props.t(`errors.response.${StatusCodes.CONFLICT}`)}</div>
                        <div className="modal__buttons">
                            <button
                                className="modal__button modal__button-remove"
                                onClick={reloadPage}
                            >
                                {props.t("base.buttons.refreshPage")}
                            </button>
                        </div>
                    </>
                }
            />
        </Popup>
    );
};

export default withTranslation()(ConflictErrorModal);

import { AuthProvider } from "./context/AuthProvider";
import "./index.css";
import App from "./components/app/App";
import LanguageServiceFactory, { LanguageServiceImpl } from "./services/LanguageServiceImpl";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {TempleServiceImpl} from "./services/TempleServiceImpl";

const languageService = LanguageServiceFactory();
languageService.initLanguages();

LanguageServiceImpl.lang$.subscribe((lang) => {
    if (languageService.isCurrentLang(lang)) {
        Root();
    }
});

TempleServiceImpl.temple$.subscribe((temple) => {
    if (temple) {
        Root();
    }
});

function Root() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <App />
            </AuthProvider>
        </BrowserRouter>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();

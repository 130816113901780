import { FC } from "react";
import { apiURL } from "../../../../../Config";

interface ArtifactModalLeftSlideProps {
    filePath: string;
    setActiveImageCallback(): void;
};

const ArtifactModalLeftSlide: FC<ArtifactModalLeftSlideProps> = (props) => {
    return (
        <div className="swiper-slide">
            <div className="modal--main__swiper-image">
                <picture onClick={props.setActiveImageCallback}>
                    <img src={props.filePath && `${apiURL}/files/${props.filePath}`} alt="" />
                </picture>
            </div>
        </div>
    );
};

export default ArtifactModalLeftSlide;

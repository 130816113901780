import _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { apiURL } from "../Config";
import { TempleDTO } from "../domain/models/dto/TempleDTO";
import { TempleRelationsParamsDTO } from "../domain/models/dto/TempleRelationsParamsDTO";
import { Temple, TempleWithCount } from "../domain/models/Temple";
import { TempleService } from "../domain/services/TempleService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { CreateOrUpdateTemplePath } from "../enums/create-or-update-temple-path-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import { TempleSortFields } from "../enums/temple-sort-fields-enum";
import templeMapperFactory from "../mappers/TempleMapper";
import { TempleModel } from "../models/TempleModel";
import { deleteRequest, get } from "../tools/Tools";
import { RemoveResponse } from "../types/RemoveResponse";
import { BaseServiceImpl } from "./BaseServiceImpl";

export class TempleServiceImpl
    extends BaseServiceImpl
    implements TempleService {
    static temple?: Temple;
    static temple$ = new BehaviorSubject(this.temple);
    static temples: TempleWithCount;
    static countries: string[];
    static cities: string[];

    notifyListeners(temple: Temple): void {
        TempleServiceImpl.temple = temple;
        TempleServiceImpl.temple$.next(temple);
    }

    private updateTemplesList(newTemple: Temple): void {
        if (!TempleServiceImpl.temples) {
            return;
        }

        TempleServiceImpl.temples.rows = TempleServiceImpl.temples.rows.map(item => item.id === newTemple.id ? newTemple : item);
    }

    private get isLatestTempleExist(): boolean {
        return TempleServiceImpl.temples?.rows.some(item => item.isLatest);
    }

    private get isSimpleTempleExist(): boolean {
        return TempleServiceImpl.temples?.rows.some(item => !item.isLatest);
    }

    private isEveryTemplesExistByIds(ids: string[]): boolean {
        const originIds = TempleServiceImpl.temples?.rows.map(temple => temple.originId);
        return ids.every(id => originIds.includes(id));
    }

    async getTemples(
        page: number,
        limit: number,
        offset: number,
        sort: TempleSortFields,
        sortingOrder: SortingOrder,
        ids?: string[],
        own: boolean = false,
        getRelations: boolean = false,
        getMainPhoto: boolean = false,
        city: string = null,
        country: string = null,
        religionId: number = null,
        religionCurrentId: number = null,
        religionSubspeciesId: number = null,
        other: string = null,
        isWorshipLinkExist?: boolean,
    ): Promise<TempleWithCount> {
        try {
            const templeMapper = templeMapperFactory();
            let url = `${apiURL}/api/v1/temples/?${this.getPaginationParamsUrl({page, limit, offset, sort, sortingOrder}, TempleSortFields.NAME)}`;

            if (!_.isEmpty(ids)) {
                const templesIds = ids.map(id => `"${id}"`);
                url += `&ids=[${templesIds.join(',')}]`;
            }

            if (own) {
                url += `&own=${own}`;
            }

            if (getRelations) {
                url += `&getRelations=${getRelations}`;
            }

            if (getMainPhoto) {
                url += `&getMainPhoto=${getMainPhoto}`;
            }

            if (city) {
                url += `&city=${city}`;
            }

            if (country) {
                url += `&country=${country}`;
            }

            if (religionId) {
                url += `&religionId=${religionId}`;
            }

            if (religionCurrentId) {
                url += `&religionCurrentId=${religionCurrentId}`;
            }

            if (religionSubspeciesId) {
                url += `&religionSubspeciesId=${religionSubspeciesId}`;
            }

            if (other) {
                url += `&other=${other}`;
            }

            if (isWorshipLinkExist) {
                url += `&isWorshipLinkExist=${isWorshipLinkExist}`;
            }

            const result = await get(url);

            const temples =
                result.data?.map((temple: TempleDTO) =>
                    templeMapper.fromDTO(temple)
                ) || [];

            return {
                totalPages: result.totalPages,
                totalRows: result.totalRows,
                rows: temples,
            };
        } catch (err) {
            console.log("TempleServiceImpl.getTemples => ERROR:");
            console.log(err);
        }
    }

    async getCachedTemples(
        page: number,
        limit: number,
        offset: number,
        sort: TempleSortFields,
        sortingOrder: SortingOrder,
        ids: string[] = [],
        own: boolean = false,
        getRelations: boolean = false,
        getMainPhoto: boolean = false,
        reloadCache: boolean = false,
        city: string = null,
        country: string = null,
        religionId: number = null,
        religionCurrentId: number = null,
        religionSubspeciesId: number = null,
        other?: string,
        isWorshipLinkExist?: boolean,
    ): Promise<TempleWithCount> {
        if (!TempleServiceImpl.temples || reloadCache || this.isLatestTempleExist || (ids && !this.isEveryTemplesExistByIds(ids))) {
            TempleServiceImpl.temples = await this.getTemples(
                page, limit, offset, sort, sortingOrder, ids, own, getRelations, getMainPhoto,
                city, country, religionId, religionCurrentId, religionSubspeciesId, other, isWorshipLinkExist
            );
        }
        return TempleServiceImpl.temples;
    }

    async getTempleById(
        templeId: string,
        getRelations: boolean = false,
        templeRelationsParams?: TempleRelationsParamsDTO,
    ): Promise<Temple> {
        if (!templeId) {
            return;
        }

        if (templeRelationsParams && !getRelations) {
            return;
        }

        try {
            const templeMapper = templeMapperFactory();

            let url = `${apiURL}/api/v1/temple/${templeId}`;

            if (getRelations) {
                url = url + '?getRelations=true';
            }

            if (templeRelationsParams?.beginWorshipScheduleDate) {
                url = url + `&beginWorshipScheduleDate=${templeRelationsParams.beginWorshipScheduleDate}`;
            }

            if (templeRelationsParams?.endWorshipScheduleDate) {
                url = url + `&endWorshipScheduleDate=${templeRelationsParams.endWorshipScheduleDate}`;
            }

            return templeMapper.fromDTO(this.getData(await get(url)));
        } catch (err) {
            console.log("TempleServiceImpl.getTempleById => ERROR:");
            console.log(err);
        }
    }

    async getCachedTempleOrUploadById(templeId: string, getRelations: boolean=false): Promise<Temple> {
        if (!templeId) return;

        const cachedTemple = TempleServiceImpl.temples?.rows.find(
            (temple) => temple.id === templeId && !temple.isLatest
        );

        if (cachedTemple) {
            return cachedTemple;
        }

        const temple = await this.getTempleById(templeId, getRelations);
        this.updateTemplesList(temple);
        return temple;
    }

    getEmptyTemple(): Temple {
        return new TempleModel();
    }

    assignNewTemple(temple: Temple): void {
        TempleServiceImpl.temple = temple;
    }

    addNewTemple(temple: Temple): Temple[] {
        const existingTemple = TempleServiceImpl.temples?.rows.find(
            (el) => el.id === temple.id
        );

        if (!existingTemple) {
            TempleServiceImpl.temples?.rows.push(temple);
        }

        return TempleServiceImpl.temples?.rows;
    }

    removeFromTemples(templeId: string): Temple[] {
        TempleServiceImpl.temples.rows = TempleServiceImpl.temples?.rows.filter(temple => temple.id !== templeId);
        return TempleServiceImpl.temples?.rows;
    }

    async removeById(id: string): Promise<RemoveResponse> {
        return this.getData<RemoveResponse>(
            await deleteRequest(`${this.getBaseEntityUrl(EntitiesBaseUrls.TEMPLE)}/${id}?removePermanently=true`)
        );
    }

    async removeEntity(entity: Temple): Promise<RemoveResponse> {
        return await this.removeEntityById<TempleDTO, Temple>(entity, this.getV2EntityUrl(EntitiesBaseUrls.TEMPLE), templeMapperFactory);
    }

    async uploadCountries(): Promise<string[]> {
        try {
            return this.getData((await get((`${apiURL}/api/v1/countries`))), []);
        } catch (err) {
            console.log(
                "TempleServiceImpl.getCountriesDropDownItems => ERROR:"
            );
            console.log(err);
        }
    }

    async uploadCities(): Promise<string[]> {
        try {
            return this.getData((await get((`${apiURL}/api/v1/cities`))), []);
        } catch (err) {
            console.log(
                "TempleServiceImpl.getCitiesDropDownItems => ERROR:"
            );
            console.log(err);
        }
    }

    async getCachedCountries(): Promise<string[]> {
        if (!TempleServiceImpl.countries) {
            TempleServiceImpl.countries = await this.uploadCountries();
        }

        return TempleServiceImpl.countries;
    }

    async getCachedCities(): Promise<string[]> {
        if (!TempleServiceImpl.cities) {
            TempleServiceImpl.cities = await this.uploadCities();
        }

        return TempleServiceImpl.cities;
    }

    getCountries(): string[] {
        return TempleServiceImpl.countries;
    }

    getCities(): string[] {
        return TempleServiceImpl.cities;
    }

    async getLatestTempleById(templeId: string): Promise<Temple> {
        if (!templeId) {
            return;
        }

        try {
            const templeMapper = templeMapperFactory();
            const temple = templeMapper.fromDTO(this.getData(await get(`${apiURL}/api/v1/temple/latest/${templeId}`)));
            temple.setIsLatest(true);
            return temple;
        } catch (err) {
            console.log("TempleServiceImpl.getLatestTempleById => ERROR:");
            console.log(err);
        }
    }


    async getCachedLatestTempleById(templeId: string): Promise<Temple> {
        if (!templeId) {
            return;
        }

        const cachedTemple = TempleServiceImpl.temples?.rows.find(
            (temple) => (temple.id === templeId || temple.parentId === templeId) && temple.isLatest
        );

        if (cachedTemple) {
            return cachedTemple;
        }

        const latestTemple = await this.getLatestTempleById(templeId);
        this.updateTemplesList(latestTemple);
        return latestTemple;
    }

    async getLatestTemples(
        page: number,
        limit: number,
        offset: number,
        sort: TempleSortFields,
        sortingOrder: SortingOrder,
        ids?: string[],
    ): Promise<TempleWithCount> {
        try {
            const templeMapper = templeMapperFactory();
            let url = `${apiURL}/api/v1/temples/latest?${this.getPaginationParamsUrl({page, limit, offset, sort, sortingOrder}, TempleSortFields.NAME)}`;

            if (!_.isEmpty(ids) && TempleServiceImpl.temples) {
                const templesIds = ids.map(id => `"${id}"`);
                url += `&ids=[${templesIds.join(',')}]`;
            }

            const result = await get(url);

            const temples =
                result.data?.map((dto: TempleDTO) => {
                    const model = templeMapper.fromDTO(dto);
                    model.setIsLatest(true);
                    return model;
                }
                ) || [];

            return {
                totalPages: result.totalPages,
                totalRows: result.totalRows,
                rows: temples,
            };
        } catch (err) {
            console.log("TempleServiceImpl.getLatestTemples => ERROR:");
            console.log(err);
        }
    }

    async getCachedLatestTemples(
        page: number,
        limit: number,
        offset: number,
        sort: TempleSortFields,
        sortingOrder: SortingOrder,
        reloadCache: boolean = false,
        ids?: string[],
    ): Promise<TempleWithCount> {
        if (!TempleServiceImpl.temples || reloadCache || this.isSimpleTempleExist || (ids && !this.isEveryTemplesExistByIds(ids))) {
            TempleServiceImpl.temples = await this.getLatestTemples(page, limit, offset, sort, sortingOrder, ids);
        }

        return TempleServiceImpl.temples;
    }

    async createRequestOrUpdateCurrentEntity(dto: TempleDTO, url?: CreateOrUpdateTemplePath): Promise<Temple> {
        if (!dto.name || !dto.city || !dto.country || !dto.religionId) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<TempleDTO, Temple>(
            dto,
            `${this.getV2EntityUrl(EntitiesBaseUrls.TEMPLE)}/${url}`,
            templeMapperFactory
        );
    }
}

export default function templeServiceFactory(): TempleService {
    return new TempleServiceImpl();
}

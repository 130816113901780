import { FC, MutableRefObject, useEffect, useState } from "react";
import { PrayerRequirement } from "../../../../../domain/models/PrayerRequirement";
import { Temple } from "../../../../../domain/models/Temple";
import prayerRequirementServiceFactory from "../../../../../services/PrayerRequirementServiceImpl";
import DownSVG from "../../../../base/svg/DownSVG";
import UpSVG from "../../../../base/svg/UpSVG";
import DarkEditButtonSVG from "../../../../base/svg/DarkEditButtonSVG";
import { withTranslation } from "react-i18next";
import DarkRemoveSVG from "../../../../base/svg/DarkRemoveSVG";
import prayerRequirementMapperFactory from "../../../../../mappers/PrayerRequirementMapper";

interface PrayerRequirementInfoProps {
    t(key?: string): string;
    temple: Temple;
    prayerRequirement: PrayerRequirement;
    setCurrentPrayerRequirement(value: PrayerRequirement): void;
    setRemovableModel(prayerRequirement: PrayerRequirement): void;

    isOrderChanged: boolean;
    setIsOrderChanged(value: boolean): void;

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    formRef: MutableRefObject<any>;
    additionalWrapperClassName: string;
}

const PrayerRequirementInfo: FC<PrayerRequirementInfoProps> = (props) => {
    const prayerRequirementService = prayerRequirementServiceFactory();
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    const scrollToForm = () => props.formRef.current.scrollIntoView();

    const updateRequirementOrder = (toUp: boolean) => {
        const mapper = prayerRequirementMapperFactory();

        const updateRequirement = async () => {
            let requirements;
            if (toUp) {
                requirements = props.temple.upPrayerRequirement(props.prayerRequirement.order);
            } else {
                requirements = props.temple.downPrayerRequirement(props.prayerRequirement.order);
            }

            let result: PrayerRequirement[];
            result = await Promise.all([
                prayerRequirementService.createRequestOrUpdateCurrentEntity(mapper.toDTO(requirements?.prevElement)),
                prayerRequirementService.createRequestOrUpdateCurrentEntity(mapper.toDTO(requirements?.currentElement)),
            ]).catch(() => (result = []));

            const current = toUp ? result[0] : result[1];

            if (!current && (requirements.currentElement || requirements.prevElement)) {
                setErrorVisibility(true);
            }

            props.setIsOrderChanged(true);
        };

        updateRequirement();
    };

    useEffect(() => {
        if (props.isOrderChanged) {
            props.setIsOrderChanged(false);
        }
    });

    const editInfo = () => {
        scrollToForm();
        props.setIsEditing(true);
        props.setCurrentPrayerRequirement(props.prayerRequirement);
    };


    return (
        <div className={`date__names ${props.additionalWrapperClassName}`}>
            {!props.isEditing && (
                <div className="date__names-buttons">
                    {isErrorVisible && <p>{props.t("errors.common")}</p>}
                    <div className="order__table-filter">
                        <button
                            className="order__table-up order__table-button"
                            onClick={() => updateRequirementOrder(true)}
                            type="button"
                        >
                            <UpSVG />
                        </button>

                        <button
                            className="order__table-down order__table-button"
                            onClick={() => updateRequirementOrder(false)}
                            type="button"
                        >
                            <DownSVG />
                        </button>
                    </div>

                    <div className="order__info-edit">
                        <button
                            type="button"
                            onClick={() => props.setRemovableModel(props.prayerRequirement)}
                        >
                            <DarkRemoveSVG />
                        </button>
                        <button
                            type="button"
                            onClick={() => editInfo()}
                        >
                            <DarkEditButtonSVG />
                        </button>
                    </div>
                </div>
            )}

            <span className="date__name">{props.prayerRequirement?.info}</span>
        </div>
    );
};

export default withTranslation()(PrayerRequirementInfo);

import { withTranslation } from "react-i18next";
import { Translate } from "../../../../types/Translate";
import { FC, useEffect, useRef, useState } from "react";
import PaymentTable from "../../../base/payments/PaymentTable";
import { ALL_PAGES, DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../../constants";
import paymentServiceFactory from "../../../../services/PaymentServiceImpl";
import { PaymentSortFields } from "../../../../enums/payment-sort-fields-enum";
import { Payment, PaymentWithCount } from "../../../../domain/models/Payment";
import { useSortingColumns } from "../../../../tools/custom-hooks/table-hooks/useSortingColumns";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import { UseTableRowsActionsHandlers } from "../../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import { Payout } from "../../../../domain/models/Payout";
import LoadingPanel from "../../../base/LoadingPanel";

interface PayoutCreationModalTableProps {
    t: Translate;
    templeId: string;
    templeName: string;
    isActiveTransactionsTableShown: boolean;
    paymentRowsHandlers: UseTableRowsActionsHandlers<Payment, Payout>
}

const PayoutCreationModalTable: FC<PayoutCreationModalTableProps> = (props) => {
    const [payments, setPayments] = useState<PaymentWithCount>();
    const columnsHandlers = useSortingColumns(SortingOrder.ASC);
    const paymentsTableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.paymentRowsHandlers.selectAllRows) {
            props.paymentRowsHandlers.addRows(payments.rows);
        }
    }, [props.paymentRowsHandlers, payments]);

    useEffect(() => {
        columnsHandlers.setReloadCache(props.paymentRowsHandlers.reloadCache);
    }, [props.paymentRowsHandlers.reloadCache, columnsHandlers.setReloadCache]);

    useEffect(() => {
        const uploadData = async () => {
            columnsHandlers.setReloadCache(false);
            const paymentService = paymentServiceFactory();

            const paymentsPagedData = await paymentService.getTemplePayments(
                props.templeId,
                ALL_PAGES,
                DEFAULT_LIMIT,
                DEFAULT_OFFSET,
                columnsHandlers.currentActiveColumn as PaymentSortFields,
                columnsHandlers.sortingOrder,
                true,
            );

            setPayments(paymentsPagedData);
            props.paymentRowsHandlers.setReloadCache(false);
            columnsHandlers.setReloadCache(false);
        };

        if (!payments) {
            uploadData();
        }
    }, [payments, columnsHandlers, props.templeId, props.paymentRowsHandlers]);

    return (
        <>
            {payments ? (
                <PaymentTable
                    payments={payments.rows}
                    paymentsTableRef={paymentsTableRef}
                    sortingColumnsHandlers={columnsHandlers}
                    rowsHandlers={props.paymentRowsHandlers}
                    isActiveTransactionsTableShown={props.isActiveTransactionsTableShown}
                    recipientName={props.templeName}
                />
            ) : (
                <LoadingPanel isVisible={true}/>
            )}
        </>
    );
};

export default withTranslation()(PayoutCreationModalTable);

import { FC } from "react";

interface OrderPrayerItemProps {
    t(key?: string): string;
    key: number;

    isEmptyPrayer: boolean;
    updateOrderDetails(value: string, currentIndex: number): void;
}

const OrderPrayerItem: FC<OrderPrayerItemProps> = (props) => {
    const updateDetails = (value: string) => {
        props.updateOrderDetails(value, props.key);
    };

    return (
        <div className="order-payer__form-item order-payer__clone" key={"order-payer__clone" + props.key}>
            <label>
                <input
                    disabled={props.isEmptyPrayer}
                    className="order-payer__form"
                    type="text"
                    placeholder={props.t("user.prayer.item.namePlaceholder")}
                    onChange={(e) => updateDetails(e.target.value)}
                />
            </label>
        </div>
    );
};

export default OrderPrayerItem;

import { BaseReducerActions } from "../types/reducers/base-reducer";

export function baseReducer<State, ActionTypes, NextValue>(reducer: (
    state: State,
    actions: BaseReducerActions<ActionTypes, NextValue>) => State
) {
    return function (state: State, actions: BaseReducerActions<ActionTypes, NextValue>) {
        const result = reducer(state, actions);
        if (!result) {
            throw new Error("Unknown action: " + actions.type);
        }
        return result;
    };
}

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

interface ChangeEntityButtonsProps<T> {
    formik: FormikProps<T>;
    initialValues: T;
    disableSaveButton: boolean;
    buttonLabel?: string;

    editFormHandler?(): void;
    resetEntityFormHandler?(): void;
    clearFieldsCallback?(): void;
}

function ChangeEntityButtons<T>(props: ChangeEntityButtonsProps<T>) {
    const { t } = useTranslation();

    const resetForm = () => {
        props.resetEntityFormHandler && props.resetEntityFormHandler();
        props.formik.resetForm({ values: props.initialValues });
        props.clearFieldsCallback && props.clearFieldsCallback();
    };

    return (
        <div className="change__entity__buttons">
            <button onClick={resetForm} className="cancel-button" type="button">
                {t("templeRegister.footer.cancelEntity")}
            </button>

            {!props.disableSaveButton && (
                <button
                    className={!props.formik.isValid ? "disabled-save-button" : "date__button-save"}
                    type="button"
                    disabled={props.formik.isSubmitting}
                    onClick={props.editFormHandler}
                >
                    {props.buttonLabel || t("templeRegister.footer.button")}
                </button>
            )}
        </div>
    );
}

export default ChangeEntityButtons;

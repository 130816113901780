import { FC } from "react";
import { withTranslation } from "react-i18next";
import { WorshipSchedule } from "../../../../../../domain/models/WorshipSchedule";
import DarkRemoveSVG from "../../../../../base/svg/DarkRemoveSVG";
import InfoEditSVG from "../../../../../base/svg/InfoEditSVG";

interface WeekScheduleItemProps {
    schedule: WorshipSchedule;
    setCurrentWorshipSchedule(value: WorshipSchedule): void;

    isEditing: boolean;
    setIsEditing(value: boolean): void;
    setRemovableModel(schedule: WorshipSchedule): void;
}

const WeekScheduleItem: FC<WeekScheduleItemProps> = (props) => {
    const editInfo = () => {
        props.setCurrentWorshipSchedule(props.schedule);
        props.setIsEditing(true);
    };

    return (
        <>
            <td>
                {props.schedule.begin}-{props.schedule.end}
            </td>
            <td>{props.schedule.name}</td>
            <td className="worship__info-edit">
                <button type="button" onClick={() => props.setRemovableModel(props.schedule)}>
                    <DarkRemoveSVG />
                </button>

                <button type="button" onClick={() => editInfo()}>
                    <InfoEditSVG />
                </button>
            </td>
        </>
    );
};

export default withTranslation()(WeekScheduleItem);

import { StatusCodes } from "http-status-codes";
import { errorServiceFactory } from "../services/ErrorServiceImpl";
import { ConfirmationActionTypes, ConfirmationNextValue, ConfirmationState } from "../types/reducers/confirmation-reducer";
import { baseReducer } from "./base-reducer";

export const initialConfirmation: ConfirmationState = {
    inProcess: true,
    success: false,
    alreadyConfirmed: false,
    tokenExpired: false,
    entityNotFound: false,
}

export const confirmationReducer = baseReducer<
    ConfirmationState,
    ConfirmationActionTypes,
    ConfirmationNextValue
>((_state, action) => {
    switch (action.type) {
        case ConfirmationActionTypes.RESET_STATE:
            return initialConfirmation;
        case ConfirmationActionTypes.UPDATE_BY_API_RESPONSE:
            const errorService = errorServiceFactory();
            const alreadyConfirmed = errorService.getStatusFromErrorResponseSubject(StatusCodes.BAD_REQUEST);
            const tokenExpired = errorService.getStatusFromErrorResponseSubject(StatusCodes.FORBIDDEN);
            const entityNotFound = errorService.getStatusFromErrorResponseSubject(StatusCodes.NOT_FOUND);

            return {
                inProcess: false,
                success: action.nextValue.apiResponse,
                alreadyConfirmed,
                tokenExpired,
                entityNotFound,
            };
        default:
            return null;
    }
});

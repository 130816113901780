import { Mapper } from "../domain/mappers/mapper";
import { ReligionSubspeciesDTO } from "../domain/models/dto/ReligionsDTO";
import { ReligionSubspecies } from "../domain/models/Religions";

class ReligionSubspeciesMapper implements Mapper<ReligionSubspecies, ReligionSubspeciesDTO> {
    toDTO(entity: ReligionSubspecies): ReligionSubspeciesDTO {
        return {
            id: parseInt(entity.value),
            label: entity.label,
        }
    }

    fromDTO(data: ReligionSubspeciesDTO): ReligionSubspecies {
        return {
            value: data.id.toString(),
            label: data.label
        }
    }
}

export default function religionSubspeciesMapperFactory(): Mapper<ReligionSubspecies, ReligionSubspeciesDTO> {
    return new ReligionSubspeciesMapper()
}

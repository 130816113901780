import { FC, MutableRefObject } from "react";
import { withTranslation } from "react-i18next";
import { Article } from "../../../../../domain/models/Article";
import { ArticleDTO } from "../../../../../domain/models/dto/ArticleDTO";
import { ArticleService } from "../../../../../domain/services/ArticleService";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import articleServiceFactory from "../../../../../services/ArticleServiceImpl";
import { useRemove } from "../../../../../tools/custom-hooks/remove-hooks/useRemove";
import { Translate } from "../../../../../types/Translate";
import ArticleInfo from "./ArticleInfo";

interface ArticlesProps {
    t: Translate;

    articles: Article[];
    setCurrentArticle(value: Article): void;
    removeArticle(id: string): void;
    articleFormRef: MutableRefObject<any>;
}

const Articles: FC<ArticlesProps> = (props) => {
    const articleService = articleServiceFactory();
    const { isErrorVisible, setRemovableModel } = useRemove<ArticleDTO, Article, ArticleService>(
        articleServiceFactory,
        props.removeArticle
    );

    return (
        <div className="entity-container-scroll">
            {isErrorVisible && props.t("errors.common")}
            {articleService.getSortedArticleByCreateDate(SortingOrder.ASC, props.articles).map((article, index) => (
                <div key={index}>
                    <ArticleInfo
                        articleFormRef={props.articleFormRef}
                        article={article}
                        setRemovableModel={setRemovableModel}
                        setCurrentArticle={props.setCurrentArticle}
                        additionalWrapperClassName={index === 0 ? "" : "establish__info__wrapper"}
                    />
                </div>
            ))}
        </div>
    );
};

export default withTranslation()(Articles);

import { FC, useState } from "react";
import { User } from "../../../domain/models/User";
import AdminTempleName from "./AdminTempleName";
import AdminTempleNameEditor from "./AdminTempleNameEditor";

interface MainAdminTempleNameProps {
    user: User;
}

const MainAdminTempleName: FC<MainAdminTempleNameProps> = (props) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);

    return (
        <div className="sidebar__church sidebar__church__name">
            {!isClicked ? (
                <AdminTempleName templeName={props.user.templeName} setIsClicked={setIsClicked} />
            ) : (
                <AdminTempleNameEditor user={props.user} setIsClicked={setIsClicked} />
            )}
        </div>
    );
};

export default MainAdminTempleName;

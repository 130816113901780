import { FormikProps } from "formik";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { maxFilesGroupSize, maxGalleriesFilesGroupSize } from "../../../../Config";
import { GalleryForm } from "../../../../domain/models/dto/TempleDTO";
import { GalleriesForm as IGalleriesForm, Temple } from "../../../../domain/models/Temple";
import { Owner } from "../../../../enums/parent-enum";
import { Purpose } from "../../../../enums/purpose-enum";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import { useUpdateFileOrder } from "../../../../tools/custom-hooks/useUpdateFileOrder";
import { getValidationClass } from "../../../../tools/Tools";
import { Translate } from "../../../../types/Translate";
import { GallerySchemaFactory } from "../../../../validation-schemas/temple-register/gallery-schemas";
import EditableImagesList from "../../../base/EditableImagesList";
import ChangeEntityButtons from "../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../base/InputRestrictionsInfo";
import WorshipLinkInstruction from "./WorshipLinkInstruction";
import languageServiceFactory from "../../../../services/LanguageServiceImpl";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useUpdateInputFieldBackground } from "../../../../tools/custom-hooks/form-hooks/useUpdateInputFieldBackground";
import TourLinkInstruction from "./TourLinkInstruction";
import Temple3DTourIframe from "../../../base/3d-tour/Temple3DTourIframe";
import { link } from "../../../../constants";
import ContentTooLargeErrorModal from "../../../base/content-too-large-error-modal/ContentTooLargeErrorModal";

interface GalleriesFormProps {
    t: Translate;
    temple: Temple;

    form: FormikProps<GalleryForm>;
    initialValues: IGalleriesForm;
    setErrorVisibility(value: boolean): void;
}

const languageService = languageServiceFactory();

const GalleriesForm: FC<GalleriesFormProps> = (props) => {
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);
    const [isTourPreviewVisible, setTourPreviewVisible] = useState<boolean>(true);

    const updateFileOrder = useUpdateFileOrder<Temple>(props.temple, setIsOrderChanged, props.temple.galleryFiles);

    useEffect(() => {
        if (isOrderChanged) {
            setIsOrderChanged(false);
        }
    }, [isOrderChanged]);

    useUpdateInputFieldBackground(props.form, "contactPhone");
    useUpdateInputFieldBackground(props.form, "contactSecondPhone");

    const gallerySchema = useMemo(() => GallerySchemaFactory({ t: props.t }), [props.t]);

    const phoneHandler = (value: string) => {
        props.form.setFieldValue("contactPhone", value, true);
    };

    const secondPhoneHandler = (value: string) => {
        props.form.setFieldValue("contactSecondPhone", value, true);
    };

    const tourLinkHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const matchArray = e.target.value.match(link);
        if (!matchArray) {
            return props.form.setFieldValue("tourLink", null, true);
        }
        props.form.setFieldValue("tourLink", matchArray[0].split(/["'`]/)[0], true);
    };

    return (
        <div className="additionally">
            <div className="additionally__block">
                <h2 className="date__subtitle additionally__subtitle">{props.t("templeRegister.gallery.subtitle")}</h2>
                <WorshipLinkInstruction />
                <div className="date__form-block">
                    <input
                        className={`date__input ${getValidationClass(props.form, "worshipLink")}`}
                        name="worshipLink"
                        onChange={props.form.handleChange}
                        value={props.form.values.worshipLink}
                        type="text"
                        placeholder={props.t("templeRegister.gallery.linkPlaceholder")}
                        maxLength={gallerySchema.inputRestrictions.worshipLink.max}
                    />
                    <InputRestrictionsInfo error={props.form.errors.worshipLink} />
                </div>
            </div>

            <div className="additionally__block">
                <h2 className="date__subtitle additionally__subtitle tour-3d__title">
                    {props.t("templeRegister.gallery.subtitle2")}
                </h2>
                <TourLinkInstruction />
                <input
                    className={`date__input ${getValidationClass(props.form, "tourLink")}`}
                    name="tourLink"
                    onChange={tourLinkHandler}
                    value={props.form.values.tourLink}
                    type="text"
                    id="3d-file"
                    placeholder={props.t("templeRegister.gallery.label1")}
                    maxLength={gallerySchema.inputRestrictions.tourLink.max}
                />
                <InputRestrictionsInfo error={props.form.errors.tourLink} />
                <button
                    type="button"
                    className="temple-3d-tour-preview__button"
                    onClick={() => setTourPreviewVisible(!isTourPreviewVisible)}
                >
                    {props.t("templeRegister.gallery.tourPreviewButton")}
                </button>
                {isTourPreviewVisible && (
                    <Temple3DTourIframe link={props.form.values.tourLink} className="temple-3d-tour-preview" />
                )}
            </div>

            <div className="additionally__block">
                <h2 className="date__subtitle additionally__gallery-subtitle">
                    {props.t("templeRegister.gallery.subtitle3")}
                </h2>

                <h3 className="additionally__text">
                    {props.t("templeRegister.gallery.additionalPhoto1")}
                    <br />
                    {props.t("templeRegister.gallery.additionalPhoto2")}
                </h3>

                <EditableImagesList
                    files={props.temple.getSortedFiles(SortingOrder.ASC, props.temple.galleryFiles)}
                    setErrorVisibility={props.setErrorVisibility}
                    setIsFilesUpdated={(val) => props.temple.setIsFilesUpdated(val)}
                    ownerType={Owner.TEMPLE}
                    purposeType={Purpose.GALLERY}
                    maxImagesCount={maxGalleriesFilesGroupSize || maxFilesGroupSize}
                    disableTitle={true}
                    getMaxOrder={() => props.temple.getMaxOrder(props.temple.galleryFiles)}
                    getNewOrder={() => props.temple.getNextFileOrder(props.temple.galleryFiles)}
                    moveFileToLeft={updateFileOrder(true)}
                    moveFileToRight={updateFileOrder(false)}
                    removeFileByOrder={(order: number) =>
                        props.temple.removeFileByOrder(order, props.temple.galleryFiles)
                    }
                ></EditableImagesList>
            </div>

            <div className="additionally__block">
                <h2 className="date__subtitle">{props.t("templeRegister.gallery.subtitle4")}</h2>

                <div className="date__form-block  date__form-wrap">
                    <div className="date__form-block">
                        <input
                            className={`date__input ${getValidationClass(props.form, "contactCountry")}`}
                            name="contactCountry"
                            onChange={props.form.handleChange}
                            value={props.form.values.contactCountry}
                            type="text"
                            placeholder={props.t("templeRegister.gallery.country")}
                            maxLength={gallerySchema.inputRestrictions.contactCountry.max}
                        />
                        <InputRestrictionsInfo
                            min={gallerySchema.inputRestrictions.contactCountry.min}
                            max={gallerySchema.inputRestrictions.contactCountry.max}
                            error={props.form.errors.contactCountry}
                        />
                    </div>

                    <div className="date__form-block">
                        <input
                            className={`date__input ${getValidationClass(props.form, "contactCity")}`}
                            name="contactCity"
                            onChange={props.form.handleChange}
                            value={props.form.values.contactCity}
                            type="text"
                            placeholder={props.t("templeRegister.gallery.city")}
                            maxLength={gallerySchema.inputRestrictions.contactCity.max}
                        />
                        <InputRestrictionsInfo
                            min={gallerySchema.inputRestrictions.contactCity.min}
                            max={gallerySchema.inputRestrictions.contactCity.max}
                            error={props.form.errors.contactCity}
                        />
                    </div>
                </div>

                <div className="date__form-block  date__form-wrap">
                    <div className="date__form-block">
                        <input
                            className={`date__input ${getValidationClass(props.form, "contactStreet")}`}
                            name="contactStreet"
                            onChange={props.form.handleChange}
                            value={props.form.values.contactStreet}
                            type="text"
                            placeholder={props.t("templeRegister.gallery.street")}
                            maxLength={gallerySchema.inputRestrictions.contactStreet.max}
                        />
                        <InputRestrictionsInfo
                            min={gallerySchema.inputRestrictions.contactStreet.min}
                            max={gallerySchema.inputRestrictions.contactStreet.max}
                            error={props.form.errors.contactStreet}
                        />
                    </div>

                    <div className="date__form-block">
                        <input
                            className={`date__input ${getValidationClass(props.form, "contactBuildingNumber")}`}
                            name="contactBuildingNumber"
                            onChange={props.form.handleChange}
                            value={props.form.values.contactBuildingNumber}
                            type="number"
                            placeholder={props.t("templeRegister.gallery.building")}
                            maxLength={gallerySchema.inputRestrictions.contactBuildingNumber.max}
                        />
                        <InputRestrictionsInfo error={props.form.errors.contactBuildingNumber} />
                    </div>
                </div>

                <div className="date__form-block date__form-wrap">
                    <div className="date__form-block">
                        <PhoneInput
                            tabIndex="contactPhone"
                            placeholder={props.t("templeRegister.gallery.phone")}
                            name="contactPhone"
                            value={props.form.values.contactPhone}
                            onChange={phoneHandler}
                            international={true}
                            limitMaxLength={true}
                            labels={languageService.getCurrentPhoneNumberLocale()}
                        />
                        <InputRestrictionsInfo
                            additionalWrapperClassName="phone-input-restrictions-info"
                            error={props.form.errors.contactPhone}
                        />
                    </div>

                    <div className="date__form-block">
                        <PhoneInput
                            tabIndex="contactSecondPhone"
                            placeholder={props.t("templeRegister.gallery.phone2")}
                            name="contactSecondPhone"
                            value={props.form.values.contactSecondPhone}
                            onChange={secondPhoneHandler}
                            international={true}
                            limitMaxLength={true}
                            labels={languageService.getCurrentPhoneNumberLocale()}
                        />
                        <InputRestrictionsInfo
                            additionalWrapperClassName="phone-input-restrictions-info"
                            error={props.form.errors.contactSecondPhone}
                        />
                    </div>
                </div>

                <div className="date__form-block">
                    <input
                        className={`date__input ${getValidationClass(props.form, "contactEmail")}`}
                        name="contactEmail"
                        onChange={props.form.handleChange}
                        value={props.form.values.contactEmail}
                        type="text"
                        placeholder="E-mail"
                        maxLength={gallerySchema.inputRestrictions.contactEmail.max}
                    />
                    <InputRestrictionsInfo error={props.form.errors.contactEmail} />
                </div>

                <div className="date__form-block">
                    <textarea
                        className={`additionally__textarea date__input ${getValidationClass(
                            props.form,
                            "contactInfo"
                        )}`}
                        name="contactInfo"
                        onChange={props.form.handleChange}
                        value={props.form.values.contactInfo}
                        cols={30}
                        rows={10}
                        placeholder={props.t("templeRegister.base.title1")}
                        maxLength={gallerySchema.inputRestrictions.contactInfo.max}
                    ></textarea>
                    <InputRestrictionsInfo
                        min={gallerySchema.inputRestrictions.contactInfo.min}
                        max={gallerySchema.inputRestrictions.contactInfo.max}
                        error={props.form.errors.contactInfo}
                    />
                </div>
            </div>

            <ChangeEntityButtons<IGalleriesForm>
                formik={props.form}
                initialValues={props.initialValues}
                disableSaveButton={true}
                resetEntityFormHandler={() => props.temple.resetGalleryFilesFiles()}
            />

            <ContentTooLargeErrorModal />
        </div>
    );
};

export default withTranslation()(GalleriesForm);

import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Temple } from "../../../domain/models/Temple";
import { Page } from "../../../enums/page-enum";
import { PublicParams } from "../../../enums/public-params-enum";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import SupportHeader from "./SupportHeader";
import SupportOfferingWithIntegration from "./SupportOfferingWithIntegration";


interface SupportPageProps {
    isTempleSupport: boolean;
}

const SupportPage: FC<SupportPageProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation().pathname.split("/");

    const templeId = location[2];

    const [temple, setTemple] = useState<Temple>(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        const templeService = templeServiceFactory();

        const uploadData = async () => {
            const temple = await templeService.getCachedTempleOrUploadById(templeId);
            setTemple(temple);
        }

        if (!props.isTempleSupport) {
            return;
        }

        if (!templeId) {
            navigate(`/choose-temple?${PublicParams.REDIRECT}=${Page.SUPPORT}`);
        }

        if (!temple) {
            uploadData();
        }
    });

    return (
        <section className="offering">
            <div className="container--main">
                <SupportHeader
                    isTempleSupport={props.isTempleSupport}
                    templeName={temple?.name}
                />
                <SupportOfferingWithIntegration  isTempleSupport={props.isTempleSupport} templeName={temple?.name} />
            </div>
        </section>
    );
};

export default SupportPage;

import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import LangSelect from "../base/LangSelect";
import HeaderNotificationSVG from "../base/svg/HeaderNotificationSVG";

interface HeaderProps {
    t(key?: string): string;
  }

const Header: FC<HeaderProps> = (props) => {
   const [isDropdownVisible, setDropdownVisibility] = useState<boolean>(false);

    return (
      <>
        <header className="header">
        <div className="header__search-wrap header__block">
          <label>
            <input
              type="text"
              placeholder={props.t("admin.header.searchPlaceholder")}
              className="header__search"
            />
          </label>
        </div>

        <div className="header__links header__block">
          <a href="#" className="header__link">{props.t("templeAdmin.header.edit")}</a>
          <a href="/" className="header__link">{props.t("templeAdmin.header.visit")}</a>
        </div>

        <div className="header__right header__block">
        <LangSelect  />
          <div className="header__notification-wrap">
            <button
              onClick={() => setDropdownVisibility(!isDropdownVisible)}
              className="header__notification"
            >
              <HeaderNotificationSVG />
            </button>

            <div
              id="dropdown"
              className={
                "header__dropdown " + (isDropdownVisible && "header__dropdown--show")
              }
            >
              <ul className="header__dropdown-list">
                <li className="header__dropdown-item header__dropdown-item--new">
                  <a href="#" className="header__dropdown-link">
                    <span>12:20</span>
                    {props.t("admin.header.dropdownList.update")}
                  </a>
                </li>
                <li className="header__dropdown-item header__dropdown-item--new">
                  <a href="#" className="header__dropdown-link">
                    <span>12:20</span>
                    {props.t("admin.header.dropdownList.newComplaint")}
                  </a>
                </li>
                <li className="header__dropdown-item">
                  <a href="#" className="header__dropdown-link">
                    <span>12:20</span>
                    {props.t("admin.header.dropdownList.admission")}
                  </a>
                </li>
                <li className="header__dropdown-item">
                  <a href="#" className="header__dropdown-link">
                    <span>12:20</span>
                    {props.t("admin.header.dropdownList.newComplaint")}
                  </a>
                </li>
                <li className="header__dropdown-item">
                  <a href="#" className="header__dropdown-link">
                    <span>12:20</span>
                    {props.t("admin.header.dropdownList.newComplaint")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
    )
}

export default withTranslation()(Header);

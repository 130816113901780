import { FC, useState } from "react";
import templeChangesRequestServiceFactory from "../../../services/TempleChangesRequestServiceImpl";

interface UpdatesConfirmButtonsProps {
    requestId: string;

    setError: (value: string) => void;
    updateRequestStatus: () => void;
}

const UpdatesConfirmButtons: FC<UpdatesConfirmButtonsProps> = (props) => {
    const templeChangesRequestService = templeChangesRequestServiceFactory();
    const [isButtonPressed, setIsButtonPressed] = useState(false);

    const approveOrDeclineRequest = async (isApprove: boolean) => {
        const execute = async () => {
            const result = await templeChangesRequestService.approveOrDeclineAllByChangeRequestId(props.requestId, isApprove);
            if (!result) {
                props.setError("errors.common");
            }
        };

        setIsButtonPressed(true);
        await execute();
        props.updateRequestStatus();
        setIsButtonPressed(false);
    };

    return (
        <div className="td-confirms">
            <div className="td-confirm" onClick={() => approveOrDeclineRequest(true)}>
                <label>
                    <input checked type="radio" name="currency" className="td-checkbox" />
                    <div className={isButtonPressed ? "td-confirm__style td-confirm__style__disabled" : "td-confirm__style"}>
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 7L7 13L14.5 1" stroke="white" />
                        </svg>
                    </div>
                </label>
            </div>

            <div className="td-confirm" onClick={() => approveOrDeclineRequest(false)}>
                <label>
                    <input checked type="radio" name="currency" className="td-checkbox" />
                    <div className= {isButtonPressed ? "td-cancel__style td-cancel__style__disabled" : "td-cancel__style"}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 11.96L11.9602 0.999806" stroke="white" />
                            <path d="M11.9609 11.96L1.00078 0.999806" stroke="white" />
                        </svg>
                    </div>
                </label>
            </div>
        </div>
    );
};

export default UpdatesConfirmButtons;

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../../types/Translate";
import { Payout } from "../../../../domain/models/Payout";
import { Document, Page, View, Text, StyleSheet, Font } from "@react-pdf/renderer";
import RobotoRegular from "../../../../fonts/Roboto-Regular.ttf";
import { getCurrentCurrencyTranslation } from "../../../../enums/currency-enum";

interface PayoutDetailsInformationPDFProps {
    t: Translate;
    payout: Payout;
}

const PayoutDetailsInformationPDF: FC<PayoutDetailsInformationPDFProps> = (props) => {
    Font.register({
        family: "Roboto",
        src: RobotoRegular,
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: "row",
            marginTop: 5,
            marginLeft: 10,
            flexGrow: 0,
        },
        table: {
            flexDirection: "column",
        },
        row: {
            flexDirection: 'row',
            marginBottom: 5,
        },
        label: {
            width: '37.5%',
            marginRight: 10,
            fontFamily: "Roboto",
            fontSize: 8,
            marginBottom: 5,
        },
        data: {
            width: '20%',
            fontFamily: "Roboto",
            fontSize: 8,
            marginBottom: 5,
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.table}>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.templeName")}</Text>
                        <Text style={styles.data}>{props.payout.templeName}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.transactionAmount")}</Text>
                        <Text style={styles.data}>{props.payout.transactionAmountFloatStr}$</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.transactionPrayersAmount")}</Text>
                        <Text style={styles.data}>{props.payout.transactionPrayersAmountFloatStr}$</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.transactionDonationsAmount")}</Text>
                        <Text style={styles.data}>{props.payout.transactionDonationsAmountFloatStr}$</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.status")}</Text>
                        <Text style={styles.data}>{props.payout.status}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.transactionsCount")}</Text>
                        <Text style={styles.data}>{props.payout.transactionCount}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.transactionsPrayersCount")}</Text>
                        <Text style={styles.data}>{props.payout.transactionPrayersCount}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.transactionsDonationsCount")}</Text>
                        <Text style={styles.data}>{props.payout.transactionDonationsCount}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.createdAt")}</Text>
                        <Text style={styles.data}>{props.payout.createdAt}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.createdByName")}</Text>
                        <Text style={styles.data}>{props.payout.createdByFullName}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.updatedAt")}</Text>
                        <Text style={styles.data}>{props.payout.updatedAt}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.resolvedByName")}</Text>
                        <Text style={styles.data}>{props.payout.resolvedByFullName}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.transactionId")}</Text>
                        <Text style={styles.data}>{props.payout.transactionId}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.paymentMethod")}</Text>
                        <Text style={styles.data}>{props.t(`paymentMethod.${props.payout.paymentMethod}`)}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.superAdminComment")}</Text>
                        <Text style={styles.data}>{props.payout.comment}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.paymentAccountRecipientName")}</Text>
                        <Text style={styles.data}>{props.payout.paymentAccountRecipientName}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.paymentAccountAccountId")}</Text>
                        <Text style={styles.data}>{props.payout.paymentAccountAccountId}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("admin.payouts.table.paymentAccountCurrency")}</Text>
                        <Text style={styles.data}>{getCurrentCurrencyTranslation(props.t, props.payout.paymentAccountCurrency).label}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default withTranslation()(PayoutDetailsInformationPDF);

import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchTempleData as ISearchTempleData } from "../../../domain/models/SearchData";
import SearchEntity from "./SearchEntity";

interface SearchTempleDataProps {
    data: ISearchTempleData;
}

const SearchTempleData: FC<SearchTempleDataProps> = (props) => {
    const navigate = useNavigate();
    const navigateToTemple = () => {
        navigate(`/temple/${props.data.templeId}`);
    };

    const [isVisiblePanel, setIsVisiblePanel] = useState(false);

    const getWrapperClass = (): string => {
        return "ac" + (isVisiblePanel ? " is-active" : "") + " search__data";
    };

    const getPanelClass = (): string => {
        return "ac-panel" + (isVisiblePanel ? " active-panel" : " hidden-panel");
    };

    return (
        <>
            <div className={getWrapperClass()}>
                <h2 className="ac-header" onClick={() => setIsVisiblePanel(!isVisiblePanel)}>
                    <button className="ac-trigger">
                        {props.data.templeName}
                        <span className="circle"></span>
                    </button>
                </h2>
                <div className={getPanelClass()}>
                    <div className="ac-panel-inner">
                        <ul className="search__entity__data__list">
                            {props.data.details.map((entity, index) => (
                                <li key={"search-entity" + index}>
                                    <SearchEntity entity={entity} navigateToTemple={navigateToTemple} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchTempleData;

import { WorshipSchedule } from "../domain/models/WorshipSchedule";
import { WorshipScheduleDTO } from "../domain/models/dto/WorshipScheduleDTO";
import { WorshipScheduleService } from "../domain/services/WorshipScheduleService";
import worshipScheduleMapperFactory from "../mappers/WorshipScheduleMapper";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { RemoveResponse } from "../types/RemoveResponse";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";

export class WorshipScheduleServiceImpl extends BaseServiceImpl implements WorshipScheduleService {
    async createRequestOrUpdateCurrentEntity(dto: WorshipScheduleDTO): Promise<WorshipSchedule> {
        if (!dto.name || !dto.begin || !dto.end || !dto.date || !dto.templeId) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<WorshipScheduleDTO, WorshipSchedule>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.WORSHIP_SCHEDULE), worshipScheduleMapperFactory
        );
    }

    async removeEntity(entity: WorshipSchedule): Promise<RemoveResponse> {
        return await this.removeEntityById<WorshipScheduleDTO, WorshipSchedule>(entity, this.getV2EntityUrl(EntitiesBaseUrls.WORSHIP_SCHEDULE), worshipScheduleMapperFactory);
    }
}

export default function worshipScheduleServiceFactory(): WorshipScheduleService {
    return new WorshipScheduleServiceImpl();
}

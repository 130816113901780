import _ from "lodash";
import { CustomFile } from "../domain/models/CustomFile";
import { TempleDTO } from "../domain/models/dto/TempleDTO";
import { PaymentAccount } from "../domain/models/PaymentAccount";
import { Temple } from "../domain/models/Temple";
import { User } from "../domain/models/User";
import { BankAccount } from "../domain/models/BankAccount";
import { Currency } from "../enums/currency-enum";
import { Artifact } from "../domain/models/Artifact";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";
import { WeekSchedule } from "../domain/models/WeekSchedule";
import { WorshipSchedule, WorshipScheduleWeek } from "../domain/models/WorshipSchedule";
import { Article } from "../domain/models/Article";
import { PrayerRequirement } from "../domain/models/PrayerRequirement";
import { Prayer } from "../domain/models/Prayer";
import { SortingOrder } from "../enums/sorting-order-enum";
import { ReplacedModel } from "../types/ReplacedModel";
import CollectionTools from "../tools/CollectionTools";
import { Week } from "../enums/week-enum";
import { SortingField } from "../enums/sorting-field-enum";
import { WeekScheduleModel } from "./WeekSchedule";
import { DATE_FORMAT } from "../constants";
import { TempleExtension } from "../domain/models/TempleExtension";
import { floatToStr } from "../tools/Tools";

export class TempleModel extends BaseRelatedEntityMixinFactory<TempleDTO>() implements Temple {
    private static _currencies: Currency[] = [];
    private _bankAccounts: BankAccount[] = [];

    isLatest: boolean = false;

    name?: string;
    religionId?: number;
    religionCurrentId?: number;
    religionSubspeciesId?: number;
    country?: string;
    city?: string;
    info?: string;

    abbotInfoFiles: CustomFile[] = [];
    shopInfoFiles: CustomFile[] = [];
    galleryFiles: CustomFile[] = [];

    abbotInfo?: string;

    prayersInfo?: string;
    useStandardPrayersInfo?: boolean;
    templeSupportInfo?: string;
    useStandardTempleSupportInfo?: boolean;
    shopInfo?: string;
    worshipLink?: string;
    tourLink?: string;
    contactCountry?: string;
    contactCity?: string;
    contactStreet?: string;
    contactBuildingNumber?: number;
    contactPhone?: string;
    contactSecondPhone?: string;
    contactEmail?: string;
    contactInfo?: string;

    paymentAccounts?: PaymentAccount[] = [];
    admin?: User;
    income?: number;

    worshipSchedules?: WorshipSchedule[] = [];
    weekSchedule?: WeekSchedule;
    artifacts?: Artifact[] = [];
    articles?: Article[] = [];
    pilgrimsArticles?: Article[] = [];
    prayerRequirements?: PrayerRequirement[] = [];
    prayers?: Prayer[] = [];

    templeExtension: TempleExtension;

    prayersEmail?: string;
    useStandardPrayersEmail?: boolean;
    templeSupportEmail?: string;
    useStandardTempleSupportEmail?: boolean;

    incomeFloatStr: string;

    constructor(data?: TempleDTO) {
        super();

        this.assignData(data);
        this.files = this.files || [];
        this.abbotInfoFiles = this.abbotInfoFiles || [];
        this.galleryFiles = this.galleryFiles || [];

        this.weekSchedule = this.weekSchedule || new WeekScheduleModel();

        this.incomeFloatStr = floatToStr(this.income || 0);
    }

    get originId(): string {
        return this.parentId || this.id;
    }

    get isMainInfoComplete(): boolean {
        return !!(
            this.name &&
            this.religionId &&
            this.country &&
            this.city &&
            this.info
        );
    }
    get isAbbotComplete(): boolean {
        return !!(this.isMainInfoComplete && this.abbotInfo && (this.artifacts.length > 0));
    }
    get isSchedulesComplete(): boolean {
        return !!(this.isAbbotComplete && this.weekSchedule.id);
    }
    get isVisitorsInfoComplete(): boolean {
        return !!(this.isSchedulesComplete && this.shopInfo);
    }
    get isOrdersComplete(): boolean {
        return !!(
            this.isVisitorsInfoComplete &&
            (this.templeSupportInfo || this.useStandardTempleSupportInfo) &&
            (this.prayersInfo || this.useStandardPrayersInfo) &&
            (this.prayerRequirements.length > 0) &&
            (this.prayers.length > 0) &&
            (this.prayersEmail || this.useStandardPrayersEmail) &&
            (this.templeSupportEmail || this.useStandardTempleSupportEmail)
        );
    }
    get isPaymentsDetailsComplete(): boolean {
        return !!(this.isOrdersComplete && (this.paymentAccounts.length > 0));
    }
    get isBankDetailsComplete(): boolean {
        return !!(this.isPaymentsDetailsComplete && (this.bankAccounts.length > 0));
    }
    get isGalleriesComplete(): boolean {
        return !!(
            this.isBankDetailsComplete &&
            this.contactCountry &&
            this.contactCity &&
            this.contactStreet &&
            this.contactBuildingNumber &&
            this.contactPhone
        );
    }
    get isCorrespondenceComplete(): boolean {
        return !!(this.isGalleriesComplete && this.templeExtension?.correspondenceEmail);
    }

    get currencies(): Currency[] {
        return TempleModel._currencies || [];
    }

    get bankAccounts(): BankAccount[] {
        return this._bankAccounts || [];
    }

    set bankAccounts(accounts: BankAccount[]) {
        if (!accounts) return;

        this._bankAccounts = accounts;
        this.bankAccounts.forEach((account) => {
            if (
                account.currency &&
                !this.currencies.includes(account.currency)
            ) {
                TempleModel._currencies.push(account.currency);
            }
        });
    }

    get isTempleContactDataExist(): boolean {
        return !!(
            this.contactStreet ||
            this.contactBuildingNumber ||
            this.contactCity ||
            this.contactCountry
        );
    }

    get isOfficeContactDataExist(): boolean {
        return !!(
            this.contactPhone ||
            this.contactSecondPhone ||
            this.contactEmail
        );
    }

    assignRelatedEntities(oldTemple: Temple): void {
        this.files = oldTemple.files;
        this.abbotInfoFiles = oldTemple.abbotInfoFiles;
        this.shopInfoFiles = oldTemple.shopInfoFiles;
        this.galleryFiles = oldTemple.galleryFiles;

        this.artifacts = oldTemple.artifacts || [];
        this.articles = oldTemple.articles || [];
        this.pilgrimsArticles = oldTemple.pilgrimsArticles || [];
        this.weekSchedule = oldTemple.weekSchedule;
        this.worshipSchedules = oldTemple.worshipSchedules || [];
        this.prayerRequirements = oldTemple.prayerRequirements || [];
        this.prayers = oldTemple.prayers || [];
        this.paymentAccounts = oldTemple.paymentAccounts || [];
        this.bankAccounts = oldTemple.bankAccounts || [];
    }

    assignChangeServiceFields(temple: Temple): void {
        this.id = temple.id;
        this.parentId = temple.parentId;
        this.status = temple.status;
    }

    removeAbbotInfoFiles(files: CustomFile[]) {
        if (_.isEmpty(files)) {
            return;
        }
        files.forEach((file) => {
            this.removeAbbotInfoFile(file);
        });
    }

    removeAbbotInfoFile(file: CustomFile) {
        if (!file) {
            return;
        }
        const index = this.abbotInfoFiles.indexOf(file);
        if (index >= 0) {
            this.abbotInfoFiles.splice(index, 1);
        }
    }

    removeAbandonedAbbotInfoFiles() {
        this.removeAbbotInfoFiles(
            this.abbotInfoFiles.filter((file) => file.isAbandoned)
        );
    }

    removeShopInfoFiles(files: CustomFile[]) {
        if (_.isEmpty(files)) {
            return;
        }
        files.forEach((file) => {
            this.removeShopInfoFile(file);
        });
    }

    removeShopInfoFile(file: CustomFile) {
        if (!file) {
            return;
        }
        const index = this.shopInfoFiles.indexOf(file);
        if (index >= 0) {
            this.shopInfoFiles.splice(index, 1);
        }
    }

    removeAbandonedShopInfoFiles() {
        this.removeShopInfoFiles(
            this.shopInfoFiles.filter((file) => file.isAbandoned)
        );
    }

    removeGalleryFiles(files: CustomFile[]) {
        if (_.isEmpty(files)) {
            return;
        }
        files.forEach((file) => {
            this.removeGalleryFile(file);
        });
    }

    removeGalleryFile(file: CustomFile) {
        if (!file) {
            return;
        }
        const index = this.galleryFiles.indexOf(file);
        if (index >= 0) {
            this.galleryFiles.splice(index, 1);
        }
    }

    removeAbandonedGalleryFiles() {
        this.removeGalleryFiles(
            this.galleryFiles.filter((file) => file.isAbandoned)
        );
    }

    getAccountsByCurrency(currency: Currency): BankAccount[] {
        return this.bankAccounts.filter(
            (account) => currency === account.currency
        );
    }

    setArtifacts(artifacts: Artifact[]): void {
        this.artifacts = artifacts;
    }

    setArticles(articles: Article[]): void {
        this.articles = articles;
    }

    setPilgrimsArticles(pilgrimsArticles: Article[]): void {
        this.pilgrimsArticles = pilgrimsArticles;
    }

    setWorshipSchedules(schedules: WorshipSchedule[]): void {
        this.worshipSchedules = schedules;
    }

    setAbbotInfo(abbotInfo: string): void {
        this.abbotInfo = abbotInfo;
    }

    setShopInfo(shopInfo: string): void {
        this.shopInfo = shopInfo;
    }

    setPrayerRequirements(prayerRequirements: PrayerRequirement[]): void {
        this.prayerRequirements = prayerRequirements;
    }

    setPrayers(prayers: Prayer[]): void {
        this.prayers = prayers;
    }

    setTempleSupportInfo(templeSupportInfo: string): void {
        this.templeSupportInfo = templeSupportInfo;
    }

    setUseStandardTempleSupportInfo(useStandardTempleSupportInfo: boolean): void {
        this.useStandardTempleSupportInfo = useStandardTempleSupportInfo;
    }

    setPrayersInfo(prayersInfo: string): void {
        this.prayersInfo = prayersInfo;
    }

    setUseStandardPrayersInfo(useStandardPrayersInfo: boolean): void {
        this.useStandardPrayersInfo = useStandardPrayersInfo;
    }

    setTempleSupportEmail(templeSupportEmail: string): void {
        this.templeSupportEmail = templeSupportEmail;
    }

    setUseStandardTempleSupportEmail(useStandardTempleSupportEmail: boolean): void {
        this.useStandardTempleSupportEmail = useStandardTempleSupportEmail;
    }

    setPrayersEmail(prayersEmail: string): void {
        this.prayersEmail = prayersEmail;
    }

    setUseStandardPrayersEmail(useStandardPrayersEmail: boolean): void {
        this.useStandardPrayersEmail = useStandardPrayersEmail;
    }

    setPaymentAccounts(accounts: PaymentAccount[]): void {
        this.paymentAccounts = accounts;
    }

    setBankAccounts(accounts: BankAccount[]): void {
        this.bankAccounts = accounts;
    }

    setWorshipLink(worshipLink: string): void {
        this.worshipLink = worshipLink;
    }

    setContactBuildingNumber(contactBuildingNumber: number): void {
        this.contactBuildingNumber = contactBuildingNumber;
    }

    setContactCity(contactCity: string): void {
        this.contactCity = contactCity;
    }

    setContactCountry(contactCountry: string): void {
        this.contactCountry = contactCountry;
    }

    setContactEmail(contactEmail: string): void {
        this.contactEmail = contactEmail;
    }

    setContactInfo(contactInfo: string): void {
        this.contactInfo = contactInfo;
    }

    setContactPhone(contactPhone: string): void {
        this.contactPhone = contactPhone;
    }

    setContactSecondPhone(contactSecondPhone: string): void {
        this.contactSecondPhone = contactSecondPhone;
    }

    setContactStreet(contactStreet: string): void {
        this.contactStreet = contactStreet;
    }

    setTourLink(tourLink: string): void {
        this.tourLink = tourLink;
    }

    groupWorshipSchedulesByDate(): WorshipSchedule[][] {
        const result: WorshipSchedule[][] = [];
        const worshipSchedules = this.worshipSchedules.map(el => {
            return {
                ...el,
                date: el.date.format(DATE_FORMAT)
            }
        })
        const dates = _.uniq(_.map(worshipSchedules, "date"));

        dates.forEach((date) => {
            result.push(
                this.worshipSchedules.filter(
                    (schedule) => schedule.date.format(DATE_FORMAT) === date
                )
            );
        });

        return result.sort((prev, current) => prev[0].date < current[0].date ? -1 : 1);
    }

    groupWorshipSchedulesByDay(): WorshipScheduleWeek {
        return {
            monday: this.worshipSchedules.filter((schedule) => schedule.date.isoWeekday() === Week.MONDAY),
            tuesday: this.worshipSchedules.filter((schedule) => schedule.date.isoWeekday() === Week.TUESDAY),
            wednesday: this.worshipSchedules.filter((schedule) => schedule.date.isoWeekday() === Week.WEDNESDAY),
            thursday: this.worshipSchedules.filter((schedule) => schedule.date.isoWeekday() === Week.THURSDAY),
            friday: this.worshipSchedules.filter((schedule) => schedule.date.isoWeekday() === Week.FRIDAY),
            saturday: this.worshipSchedules.filter((schedule) => schedule.date.isoWeekday() === Week.SATURDAY),
            sunday: this.worshipSchedules.filter((schedule) => schedule.date.isoWeekday() === Week.SUNDAY),
        }
    }

    getSortedWorshipSchedules(sortingOrder: SortingOrder, schedules?: WorshipSchedule[]): WorshipSchedule[] {
        return CollectionTools.sortEntitiesByLocaleCompare<WorshipSchedule>(
            schedules || this.worshipSchedules, sortingOrder, SortingField.BEGIN
        );
    }

    getNextPrayerRequirementOrder(): number {
        return this.prayerRequirements.length + 1;
    }

    getSortedPrayerRequirements(
        sortingOrder: SortingOrder
    ): PrayerRequirement[] {
        return CollectionTools.sortEntities<PrayerRequirement>(this.prayerRequirements, sortingOrder);
    }

    upPrayerRequirement(order: number): ReplacedModel<PrayerRequirement> {
        return CollectionTools.upEntity<PrayerRequirement>(order, this.prayerRequirements);
    }

    downPrayerRequirement(order: number): ReplacedModel<PrayerRequirement> {
        return CollectionTools.downEntity<PrayerRequirement>(order, this.prayerRequirements);
    }

    getNextPrayerOrder(): number {
        return this.prayers.length + 1;
    }

    getSortedPrayers(
        sortingOrder: SortingOrder
    ): Prayer[] {
        return CollectionTools.sortEntities<Prayer>(this.prayers, sortingOrder);
    }

    upPrayer(order: number): ReplacedModel<Prayer> {
        return CollectionTools.upEntity<Prayer>(order, this.prayers);
    }

    downPrayer(order: number): ReplacedModel<Prayer> {
        return CollectionTools.downEntity<Prayer>(order, this.prayers);
    }

    getNextPaymentAccountOrder(): number {
        return this.paymentAccounts.length + 1;
    }

    getSortedPaymentAccounts(
        sortingOrder: SortingOrder
    ): PaymentAccount[] {
        return CollectionTools.sortEntities<PaymentAccount>(this.paymentAccounts, sortingOrder);
    }

    upPaymentAccount(order: number): ReplacedModel<PaymentAccount> {
        return CollectionTools.upEntity<PaymentAccount>(order, this.paymentAccounts);
    }

    downPaymentAccount(order: number): ReplacedModel<PaymentAccount> {
        return CollectionTools.downEntity<PaymentAccount>(order, this.paymentAccounts);
    }

    getNextBankAccountOrder(): number {
        return this.bankAccounts.length + 1;
    }

    getSortedBankAccounts(
        sortingOrder: SortingOrder
    ): BankAccount[] {
        return CollectionTools.sortEntities<BankAccount>(this.bankAccounts, sortingOrder);
    }

    upBankAccount(order: number): ReplacedModel<BankAccount> {
        return CollectionTools.upEntity<BankAccount>(order, this.bankAccounts);
    }

    downBankAccount(order: number): ReplacedModel<BankAccount> {
        return CollectionTools.downEntity<BankAccount>(order, this.bankAccounts);
    }

    resetAbbotInfoFiles() {
        this.abbotInfoFiles = this.getResetFiles(this.abbotInfoFiles);
    };

    resetShopInfoFiles() {
        this.shopInfoFiles = this.getResetFiles(this.shopInfoFiles);
    };

    resetGalleryFilesFiles() {
        this.galleryFiles = this.getResetFiles(this.galleryFiles);
    };

    setIsLatest(isLatest: boolean): void {
        this.isLatest = isLatest;
    }

    setOriginIdToExtension(): void {
        this.templeExtension.setOriginTempleId(this.originId);
    }

    setTempleExtension(templeExtension: TempleExtension): void {
        this.templeExtension = templeExtension;
    }

    resetRequiredAbbotFields(): void {
        this.abbotInfo = null;
    }

    resetRequiredOrdersFields(): void {
        this.templeSupportInfo = null;
        this.useStandardTempleSupportInfo = false;
        this.prayersInfo = null;
        this.useStandardPrayersInfo = false;
    }

    removePrayerByOrder(order: number): Prayer[] {
        return CollectionTools.removeEntityAndReturnNewList<Prayer>(order, this.prayers);
    }

    removePrayerRequirementByOrder(order: number): PrayerRequirement[] {
        return CollectionTools.removeEntityAndReturnNewList<PrayerRequirement>(order, this.prayerRequirements);
    }

    removeBankAccountByOrder(order: number): BankAccount[] {
        return CollectionTools.removeEntityAndReturnNewList<BankAccount>(order, this.bankAccounts);
    }

    removePaymentAccountByOrder(order: number): PaymentAccount[] {
        return CollectionTools.removeEntityAndReturnNewList<PaymentAccount>(order, this.paymentAccounts);
    }
}

import { TemplesInfo } from "../domain/models/Temple";
import { TemplesInfoDTO } from "../domain/models/dto/TempleDTO";
import { BaseModelImpl } from "./BaseModelImpl";

class TemplesInfoModel extends BaseModelImpl<TemplesInfoDTO> implements TemplesInfo {
    totalCount: number;
    inReviewCount: number;
    deletedCount: number;

    constructor(data: TemplesInfoDTO) {
        super();
        this.assignData(data);
    }
}

export default TemplesInfoModel

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { PrayerPriceDetail } from "../../../../domain/models/PrayerPriceDetail";
import { Translate } from "../../../../types/Translate";

interface PriceDetailsItemProps {
    t: Translate;
    priceDetail: PrayerPriceDetail;
}

const PriceDetailsItem: FC<PriceDetailsItemProps> = (props) => {
    return (
        <div className="order-payer__accordion-price">
            {props.t("user.prayer.accordion.details.people1")} {props.priceDetail.maxPeople} {" "}
            {props.t("user.prayer.accordion.details.people2")} - {props.priceDetail.priceFloatFormat} $
        </div>
    );
};

export default withTranslation()(PriceDetailsItem);

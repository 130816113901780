import _ from "lodash";
import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Payment } from "../../../domain/models/Payment";
import CustomCheckbox from "../CustomCheckbox";

interface PaymentRowProps {
    t(key?: string): string;
    payment: Payment;
    isSuperAdminUser?: boolean;
    isTemplePayments?: boolean;
    isSelectedRow: boolean;
    selectRowCallback: () => void;
    recipientName?: string;
}

const PaymentRow: FC<PaymentRowProps> = (props) => {
    const navigate = useNavigate();

    const isPaymentWait = props.payment.inReview ? "status-wait" : undefined;

    const templeId = _.get(props.payment, "temple.id");
    const isTempleExist = !!templeId;
    const templeName = _.get(props.payment, "temple.name", "");

    const navigateToPaymentDetails = () => {
        if (props.isSuperAdminUser) {
            return props.isTemplePayments
                ? navigate(`/admin/temples/${templeId}/payments/${props.payment.id}`)
                : navigate(`/admin/payments/${props.payment.id}`);
        }

        navigate(`/temple-admin/payments/${props.payment.id}`);
    };

    return (
        <tr>
            {props.selectRowCallback ? <td className="td-style--min td-padding1-75 date">
                <CustomCheckbox
                    id={props.payment.id}
                    name={"selectedRowButton" + props.payment.id}
                    defaultValue={false}
                    value={props.isSelectedRow}
                    className="checkbox__input"
                    additionalLabelClassName="date__text"
                    wrapperClassName="order__checkbox simple-checkbox"
                    onChange={props.selectRowCallback}
                />
            </td> : <></>}
            <td className="td-style--min td-padding2" onClick={() => navigateToPaymentDetails()}>
                <span className="td-inner--min2">{props.payment.senderName}</span>
            </td>
            <td className="td-style--min td-padding2" onClick={() => navigateToPaymentDetails()}>
                <span className="td-inner--min3">{props.payment.id}</span>
            </td>
            <td className="td-style--min" onClick={() => navigateToPaymentDetails()}>
                <span className="td-inner--min2">
                    {isTempleExist ? templeName : (props.recipientName || props.t("templeAdmin.payments.platformLabel"))}
                </span>
            </td>
            <td className="status td-padding3" onClick={() => navigateToPaymentDetails()}>
                <span className={`${isPaymentWait}`}>{props.payment.statusDateStr}</span>
            </td>
            <td className="td-style td-time td-padding1 td-print-date" onClick={() => navigateToPaymentDetails()}>{props.payment.paymentDateStr}</td>
            <td className="td-style td-padding2" onClick={() => navigateToPaymentDetails()}>
                <span className="td-inner--min4">{props.payment.amountFloatFormat}</span>
            </td>
            {props.isSuperAdminUser && (
                <td className="td-style td-padding2" onClick={() => navigateToPaymentDetails()}>
                    <span className="td-inner--min4">{props.payment.recipientAmountFloatFormat}</span>
                </td>
            )}
            {props.isSuperAdminUser && (
                <td className="td-style td-padding2" onClick={() => navigateToPaymentDetails()}>
                    <span className="td-inner--min4">{props.payment.platformAmountFloatFormat}</span>
                </td>
            )}
            <td className="td-style--min td-padding1" onClick={() => navigateToPaymentDetails()}>
                <span className="td-inner--min3">{props.t(`transactionType.${props.payment.transactionType}`)}</span>
            </td>
        </tr>
    );
};

export default withTranslation()(PaymentRow);

import { FC } from "react";
import { Translate } from "../../../../../types/Translate";
import { withTranslation } from "react-i18next";
import BasePayments from "../../../payments/BasePayments";
import { useAuth } from "../../../../../context/AuthProvider";
import { Payment } from "../../../../../domain/models/Payment";

interface PayoutDetailsPaymentsTableProps {
    t: Translate;
    payoutId: string;
    templeId: string;
    setPaymentsCallback: (data: Payment[]) => void;
}

const PayoutDetailsPaymentsTable: FC<PayoutDetailsPaymentsTableProps> = (props) => {
    const { user } = useAuth();
    return (
        <>
            <BasePayments
                isSuperAdminUser={user.isAdmin}
                payoutId={props.payoutId}
                templeId={props.templeId}
                disableCharts={true}
                setPaymentsCallback={props.setPaymentsCallback}
            />
        </>
    );
};

export default withTranslation()(PayoutDetailsPaymentsTable);

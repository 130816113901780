import { FC, useCallback, useState } from "react";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";
import PayoutsHeader from "../../admin/payouts/PayoutsHeader";
import { PayoutsPageType } from "../../../enums/payouts-page-enum";
import PayoutsTab from "./PayoutsTab";
import CreditsTab from "../../admin/payouts/credits/CreditsTab";
import { useAuth } from "../../../context/AuthProvider";
import { useTableRowsActions } from "../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import { TempleStatistic } from "../../../domain/models/TempleStatistic";
import { Payout } from "../../../domain/models/Payout";
import { payoutServiceFactory } from "../../../services/PayoutServiceImpl";
import { SelectProps } from "../../../domain/models/SelectProps";
import { MAX_PAYMENT_METHODS_FOR_PAYOUTS } from "../../../constants";
import _ from "lodash";
import PayoutsHeaderShortened from "../../admin/payouts/PayoutsHeaderShortened";

interface PayoutsPageProps {
    t: Translate;
    payoutsPageType: PayoutsPageType;
}

const PayoutsPage: FC<PayoutsPageProps> = (props) => {
    const [totalRows, setTotalRows] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState<SelectProps>(null);

    const isPayouts = props.payoutsPageType === PayoutsPageType.PAYOUTS;
    const isCredits = props.payoutsPageType === PayoutsPageType.CREDITS;

    const { user } = useAuth();

    const totalRowsHandler = useCallback((value: number) => {
        setTotalRows(value);
    }, []);

    const creditRowsHandlers = useTableRowsActions<TempleStatistic, Payout>({
        actionCallback: async (templeStatistic: TempleStatistic) => {
            return payoutServiceFactory().createPayout(templeStatistic.id);
        },
        isErrorCallback: (templeStatistics: TempleStatistic[]) => {
            const paymentMethods = _.uniq(templeStatistics.map((val) => val.defaultPaymentMethod));
            return paymentMethods.length > MAX_PAYMENT_METHODS_FOR_PAYOUTS;
        },
    });

    const payoutRowsHandlers = useTableRowsActions<Payout, null>({
        actionCallback: async (_payout: Payout) => null,
        isErrorCallback: (payouts: Payout[]) => {
            const paymentMethods = _.uniq(payouts.map((val) => val.paymentMethod));
            return paymentMethods.length > MAX_PAYMENT_METHODS_FOR_PAYOUTS;
        },
    });

    const paymentMethodHandleChange = (newMethod: SelectProps) => {
        if (paymentMethod === newMethod) {
            return;
        }

        setPaymentMethod(newMethod);
        isCredits ? creditRowsHandlers.setReloadCache(true) : payoutRowsHandlers.setReloadCache(true);
    };

    const getRowsHandlersByPageType = () => {
        return isCredits ? creditRowsHandlers : payoutRowsHandlers;
    };

    const getPaymentMethodInt = () => {
        return parseInt(paymentMethod?.value);
    };

    return (
        <>
            {user.isTempleAdmin ? (
                <>
                    <PayoutsHeaderShortened totalRows={totalRows} />
                    <div className="inner__content23 inner__content23__payouts-page__temple-admin">
                        <PayoutsTab
                            totalRowsHandler={totalRowsHandler}
                            rowsHandlers={payoutRowsHandlers}
                            paymentMethod={getPaymentMethodInt()}
                        />
                    </div>
                </>
            ) : (
                <>
                    <PayoutsHeader
                        isPayouts={isPayouts}
                        isCredits={isCredits}
                        totalRows={totalRows}
                        payoutsToResolve={payoutRowsHandlers.getData()}
                        paymentMethod={paymentMethod}
                        paymentMethodHandleChange={paymentMethodHandleChange}
                        rowsHandlers={getRowsHandlersByPageType()}
                    />
                    <div className="inner__content23 inner__content23__payouts-page">
                        {isPayouts ? (
                            <PayoutsTab
                                totalRowsHandler={totalRowsHandler}
                                rowsHandlers={payoutRowsHandlers}
                                paymentMethod={getPaymentMethodInt()}
                            />
                        ) : (
                            <CreditsTab
                                totalRowsHandler={totalRowsHandler}
                                rowsHandlers={creditRowsHandlers}
                                defaultPaymentMethod={getPaymentMethodInt()}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default withTranslation()(PayoutsPage);

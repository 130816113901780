import { FormikProps, useFormik } from "formik";
import { FC, MutableRefObject, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";
import { DATE_FORMAT, DISABLED_COLOR, TIME_MASK } from "../../../../../../constants";
import { useFormsValidConfirmation } from "../../../../../../context/FormikFormsProvider";
import { Temple } from "../../../../../../domain/models/Temple";
import {
    WorshipSchedule,
    WorshipScheduleForm as IWorshipScheduleForm,
} from "../../../../../../domain/models/WorshipSchedule";
import worshipScheduleMapperFactory from "../../../../../../mappers/WorshipScheduleMapper";
import worshipScheduleServiceFactory from "../../../../../../services/WorshipScheduleServiceImpl";
import { getValidationClass } from "../../../../../../tools/Tools";
import { WorshipScheduleSchemaFactory } from "../../../../../../validation-schemas/temple-register/worship-schedule-schemas";
import ChangeEntityButtons from "../../../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../../../base/InputRestrictionsInfo";
import AddButtonSVG from "../../../../../base/svg/AddButtonSVG";
import WorshipScheduleDate from "./WorshipScheduleDate";
import ConflictErrorModal from "../../../../../base/conflict-error-modal/ConflictErrorModal";

interface WorshipScheduleFormProps {
    t(key?: string): string;
    worshipSchedule: WorshipSchedule;
    temple: Temple;

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    updateWorshipSchedules(newWorshipSchedule: WorshipSchedule): void;
    resetWorshipScheduleForm(): void;
    worshipScheduleFormRef: MutableRefObject<any>;
}

const WorshipScheduleForm: FC<WorshipScheduleFormProps> = (props) => {
    const begin = props.t("templeRegister.schedules.begin");
    const end = props.t("templeRegister.schedules.end");

    const worshipScheduleService = worshipScheduleServiceFactory();

    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const [date, setDate] = useState<string>("");

    const nullableValues = {
        id: "",
        name: "",
        begin: "",
        end: "",
        date: "",
    };

    const worshipScheduleSchema = useMemo(() => WorshipScheduleSchemaFactory({ t: props.t }), [props.t]);

    const worshipScheduleForm: FormikProps<IWorshipScheduleForm> = useFormik({
        enableReinitialize: true,
        initialValues: nullableValues,
        validationSchema: worshipScheduleSchema.schema,
        onSubmit: async (values) => {
            try {
                setErrorVisibility(false);

                const newWorshipSchedule = await worshipScheduleService.createRequestOrUpdateCurrentEntity(
                    worshipScheduleMapperFactory().dateToDTO({
                        templeId: props.temple.originId,
                        id: values.id || undefined,
                        name: values.name,
                        begin: values.begin,
                        end: values.end,
                        date: values.date,
                    })
                );

                if (!newWorshipSchedule) {
                    return setErrorVisibility(true);
                }

                if (newWorshipSchedule) {
                    props.updateWorshipSchedules(newWorshipSchedule);
                    setIsSubmitted(true);
                    setDate("");
                }
            } catch {
                setErrorVisibility(true);
            }
        },
    });

    useFormsValidConfirmation("worshipScheduleForm", worshipScheduleForm);

    useEffect(() => {
        if (!isSubmitted && worshipScheduleForm.values.date !== date) {
            worshipScheduleForm.setFieldValue("date", date);
        }

        if (isSubmitted) {
            worshipScheduleForm.setValues({
                id: "",
                name: "",
                begin: "",
                end: "",
                date: "",
            });
            worshipScheduleForm.setTouched({});

            setIsSubmitted(false);
        }

        if (props.isEditing) {
            worshipScheduleForm.setValues({
                id: props.worshipSchedule.id,
                name: props.worshipSchedule.name,
                begin: props.worshipSchedule.begin,
                end: props.worshipSchedule.end,
                date: props.worshipSchedule.date.format(DATE_FORMAT),
            });
            props.setIsEditing(false);
        }
    }, [worshipScheduleForm, isSubmitted, props, date]);

    const editWorshipScheduleFormHandler = () => {
        worshipScheduleForm.setValues({
            ...worshipScheduleForm.values,
            id: props.worshipSchedule?.id,
        });
        worshipScheduleForm.handleSubmit();
    };

    const cachedWorshipScheduleDate = useMemo(
        () => (
            <WorshipScheduleDate
                date={date}
                dateError={worshipScheduleForm.errors.date}
                setDate={(newDate: string) => setDate(newDate)}
            />
        ),
        [date, worshipScheduleForm.errors.date]
    );

    return (
        <div className="choose__temple__pagination" ref={props.worshipScheduleFormRef}>
            <div className="date__form-block">
                <input
                    className={`date__input ${getValidationClass(worshipScheduleForm, "name")}`}
                    name="name"
                    onChange={worshipScheduleForm.handleChange}
                    value={worshipScheduleForm.values.name}
                    type="text"
                    placeholder={props.t("templeRegister.schedules.name")}
                    maxLength={worshipScheduleSchema.inputRestrictions.name.max}
                />
                <InputRestrictionsInfo
                    min={worshipScheduleSchema.inputRestrictions.name.min}
                    max={worshipScheduleSchema.inputRestrictions.name.max}
                    error={worshipScheduleForm.errors.name}
                />
            </div>

            <div className="worship__datepicker">
                {cachedWorshipScheduleDate}

                <div className="worship__datepicker-forms">
                    <div className="date__form-block">
                        <ReactInputMask
                            mask={TIME_MASK}
                            name="begin"
                            onChange={worshipScheduleForm.handleChange}
                            value={worshipScheduleForm.values.begin}
                            className={`date__input worship__input ${getValidationClass(worshipScheduleForm, "begin")}`}
                            type="text"
                            placeholder={begin}
                        />
                        {worshipScheduleForm.errors.begin}
                    </div>
                    <div className="date__form-block worship__form-block">
                        <ReactInputMask
                            mask={TIME_MASK}
                            className={`date__input worship__input ${getValidationClass(worshipScheduleForm, "end")}`}
                            name="end"
                            onChange={worshipScheduleForm.handleChange}
                            value={worshipScheduleForm.values.end}
                            type="text"
                            placeholder={end}
                        />
                        {worshipScheduleForm.errors.end}
                    </div>
                    <div className="date__bottom-buttons">
                        <ChangeEntityButtons<IWorshipScheduleForm>
                            formik={worshipScheduleForm}
                            initialValues={nullableValues}
                            disableSaveButton={!props.worshipSchedule.id}
                            editFormHandler={editWorshipScheduleFormHandler}
                            resetEntityFormHandler={props.resetWorshipScheduleForm}
                        />

                        {!props.worshipSchedule.id && (
                            <button
                                className={
                                    !worshipScheduleForm.isValid
                                        ? "disabled-add-button worship__button-add"
                                        : "date__button-add worship__button-add"
                                }
                                type="button"
                                onClick={() => worshipScheduleForm.handleSubmit()}
                                disabled={!!props.worshipSchedule.id || worshipScheduleForm.isSubmitting}
                            >
                                <span>
                                    <AddButtonSVG fill={!worshipScheduleForm.isValid && DISABLED_COLOR} />
                                </span>
                                {props.t("templeRegister.schedules.addEvent")}
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {isErrorVisible && <p>{props.t("errors.common")}</p>}
            <ConflictErrorModal />
        </div>
    );
};

export default withTranslation()(WorshipScheduleForm);

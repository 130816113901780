export interface ConfirmationState {
    inProcess: boolean;
    success: boolean;
    alreadyConfirmed: boolean;
    tokenExpired: boolean;
    entityNotFound: boolean;
}

export enum ConfirmationActionTypes {
    UPDATE_BY_API_RESPONSE,
    RESET_STATE,
}

export interface ConfirmationNextValue {
    apiResponse: boolean;
}

import { FC } from "react";

const DownSVG: FC = () => {
    return (
        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L8 7L15 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    );
};

export default DownSVG;

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../../types/Translate";
import { Payout } from "../../../../domain/models/Payout";
import { getCurrentCurrencyTranslation } from "../../../../enums/currency-enum";

interface PayoutDetailsInformationProps {
    t: Translate;
    payout: Payout;
}

const PayoutDetailsInformation: FC<PayoutDetailsInformationProps> = (props) => {
    return (
        <div className="holy__tabs-content active">
            <div className="inner__content4">
                <table id="payout-information-to-print" className="holy__info">
                    <tbody>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.templeName")}</td>
                            <td className="holy__info-value">{props.payout.templeName}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.transactionAmount")}</td>
                            <td className="holy__info-value">{props.payout.transactionAmountFloatStr}$</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.transactionPrayersAmount")}</td>
                            <td className="holy__info-value">{props.payout.transactionPrayersAmountFloatStr}$</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.transactionDonationsAmount")}</td>
                            <td className="holy__info-value">{props.payout.transactionDonationsAmountFloatStr}$</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.status")}</td>
                            <td className="holy__info-value">{props.t(`payoutStatus.${props.payout.status}`)}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.transactionsCount")}</td>
                            <td className="holy__info-value">{props.payout.transactionCount}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.transactionsPrayersCount")}</td>
                            <td className="holy__info-value">{props.payout.transactionPrayersCount}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.transactionsDonationsCount")}</td>
                            <td className="holy__info-value">{props.payout.transactionDonationsCount}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.createdAt")}</td>
                            <td className="holy__info-value">{props.payout.createdAt}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.createdByName")}</td>
                            <td className="holy__info-value">{props.payout.createdByFullName}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.updatedAt")}</td>
                            <td className="holy__info-value">{props.payout.updatedAt}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.resolvedByName")}</td>
                            <td className="holy__info-value">{props.payout.resolvedByFullName}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.transactionId")}</td>
                            <td className="holy__info-value">{props.payout.transactionId}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.paymentMethod")}</td>
                            <td className="holy__info-value">{props.t(`paymentMethod.${props.payout.paymentMethod}`)}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.superAdminComment")}</td>
                            <td className="holy__info-value">{props.payout.comment}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.paymentAccountRecipientName")}</td>
                            <td className="holy__info-value">{props.payout.paymentAccountRecipientName}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.paymentAccountAccountId")}</td>
                            <td className="holy__info-value">{props.payout.paymentAccountAccountId}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{props.t("admin.payouts.table.paymentAccountCurrency")}</td>
                            <td className="holy__info-value">{getCurrentCurrencyTranslation(props.t, props.payout.paymentAccountCurrency).label}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default withTranslation()(PayoutDetailsInformation);

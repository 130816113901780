import { FC } from "react";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";

interface PrintButtonSVGProps {
    t: Translate;
    onClick: () => void;
}

const PrintButtonSVG: FC<PrintButtonSVGProps> = (props) => {
    return (
        <div
            onClick={props.onClick}
            className="payout-information-button__print"
            title={props.t("admin.payouts.details.print")}
        >
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="28.000000pt"
                height="28.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#57d3db" stroke="none">
                    <path
                        d="M1489 5105 c-145 -29 -268 -95 -380 -205 -167 -164 -228 -342 -229
-667 l0 -103 1677 0 1676 0 -6 173 c-5 123 -12 194 -27 251 -55 215 -196 388
-395 486 -161 80 -77 74 -1225 77 -822 1 -1038 -1 -1091 -12z"
                    />
                    <path
                        d="M609 3815 c-145 -29 -268 -95 -380 -205 -134 -132 -202 -280 -220
-485 -7 -70 -9 -350 -7 -732 4 -598 5 -621 26 -697 61 -219 199 -388 397 -486
112 -55 180 -71 333 -77 l132 -6 0 402 0 401 -62 0 c-40 0 -76 6 -98 18 -107
53 -107 211 0 264 33 17 141 18 1825 18 1684 0 1792 -1 1825 -18 107 -53 107
-211 0 -264 -22 -12 -58 -18 -97 -18 l-63 0 0 -401 0 -402 138 6 c158 6 224
21 337 77 198 97 335 265 397 486 22 78 23 85 23 784 0 699 -1 706 -23 784
-62 221 -199 389 -397 486 -165 82 32 75 -2110 77 -1555 1 -1922 -1 -1976 -12z
m731 -643 c50 -25 80 -75 80 -132 0 -57 -30 -107 -80 -132 -31 -16 -68 -18
-305 -18 -237 0 -274 2 -305 18 -107 53 -107 211 0 264 31 16 68 18 305 18
237 0 274 -2 305 -18z"
                    />
                    <path
                        d="M1182 1083 l3 -848 24 -53 c31 -69 112 -143 181 -165 48 -16 146 -17
1165 -17 1019 0 1117 1 1165 17 69 22 150 96 181 165 l24 53 3 848 3 847
-1376 0 -1376 0 3 -847z m1838 489 c107 -53 107 -211 0 -264 -32 -16 -76 -18
-465 -18 -479 0 -476 0 -522 69 -48 71 -20 175 57 213 32 16 76 18 465 18 389
0 433 -2 465 -18z m0 -640 c107 -53 107 -211 0 -264 -32 -16 -76 -18 -465 -18
-479 0 -476 0 -522 69 -48 71 -20 175 57 213 32 16 76 18 465 18 389 0 433 -2
465 -18z"
                    />
                </g>
            </svg>
        </div>
    );
};

export default withTranslation()(PrintButtonSVG);

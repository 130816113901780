import { AdminStatistic } from "../domain/models/Admin";
import { PaymentsInfo } from "../domain/models/Payment";
import { Temple, TemplePayments, TemplesInfo } from "../domain/models/Temple";
import { AdminStatisticDTO } from "../domain/models/dto/AdminDTO";
import { BaseModelImpl } from "./BaseModelImpl";

class AdminStatisticModel extends BaseModelImpl<AdminStatisticDTO> implements AdminStatistic {
    templesInfo: TemplesInfo;
    paymentsInfo: PaymentsInfo;
    templePayments: TemplePayments[];
    temples: Temple[];
}

export default AdminStatisticModel

import moment from "moment";
import { FC } from "react";
import { withTranslation } from "react-i18next";
import { SHORT_DATE_FORMAT } from "../../../../../constants";
import { WorshipSchedule } from "../../../../../domain/models/WorshipSchedule";
import { Week } from "../../../../../enums/week-enum";
import { Translate } from "../../../../../types/Translate";
import WorshipScheduleItemMainInfo from "./WorshipScheduleItemMainInfo";

interface WorshipScheduleItemProps {
    t: Translate;

    index: number;
    dayType: Week;
    worshipSchedule: WorshipSchedule;
}

const WorshipScheduleItem: FC<WorshipScheduleItemProps> = (props) => {
    const isFirstItem = props.index === 0;
    const defaultTimeLabel = "----";

    return (
        <tr className="information__worship-item" key={"information__worship-item" + props.index}>
            {isFirstItem && (
                <td className="information__worship-days information__style" rowSpan={0}>{props.t(`weekDays.${props.dayType}`)}</td>
            )}
            {isFirstItem && (
                <td className="information__worship-days information__style" rowSpan={0}>
                    {props.worshipSchedule
                        ? props.worshipSchedule.date.format(SHORT_DATE_FORMAT)
                        : moment().startOf("week").day(props.dayType).format(SHORT_DATE_FORMAT)}
                </td>
            )}
            {props.worshipSchedule ? (
                <WorshipScheduleItemMainInfo
                    begin={props.worshipSchedule.begin}
                    end={props.worshipSchedule.end}
                    name={props.worshipSchedule.name}
                />
            ) : (
                <WorshipScheduleItemMainInfo
                    begin={defaultTimeLabel}
                    end={defaultTimeLabel}
                    name={props.t("user.temple.worships.worshipsNotFound")}
                    isNoTimeInfo={true}
                />
            )}
        </tr>
    );
};

export default withTranslation()(WorshipScheduleItem);

import { FormikProps, useFormik } from "formik";
import { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Prayer, PrayerForm as IPrayerForm } from "../../../../../domain/models/Prayer";
import { Temple } from "../../../../../domain/models/Temple";
import prayerServiceFactory from "../../../../../services/PrayerServiceImpl";
import { PrayerSchemaFactory } from "../../../../../validation-schemas/temple-register/prayer-schemas";
import DropDown from "../../../../base/DropDown";
import OrdersAddButtonSVG from "../../../../base/svg/OrdersAddButtonSVG";
import { SelectProps } from "../../../../../domain/models/SelectProps";
import { getValidationClass } from "../../../../../tools/Tools";
import PrayerModel from "../../../../../models/PrayerModel";
import { getCurrentPrayerTypeTranslation, getPrayerTypesTranslation } from "../../../../../enums/prayer-enum";
import {
    getCurrentPrayerPeriodTranslation,
    getPrayerPeriodsTranslation,
} from "../../../../../enums/prayer-period-enum";
import PrayerPriceDetailModel from "../../../../../models/PrayerPriceDetailModel";
import PrayerDetailsForm from "./PrayerDetailsForm";
import prayerPriceDetailServiceFactory from "../../../../../services/PrayerPriceDetailServiceImpl";
import { PrayerPriceDetail } from "../../../../../domain/models/PrayerPriceDetail";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import { withTranslation } from "react-i18next";
import AddButtonSVG from "../../../../base/svg/AddButtonSVG";
import { DISABLED_COLOR } from "../../../../../constants";
import { useFormsValidConfirmation } from "../../../../../context/FormikFormsProvider";
import ChangeEntityButtons from "../../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../../base/InputRestrictionsInfo";
import { Currency } from "../../../../../enums/currency-enum";
import ConflictErrorModal from "../../../../base/conflict-error-modal/ConflictErrorModal";

interface PrayerFormProps {
    t(key?: string): string;
    prayer: Prayer;
    details: PrayerPriceDetail[];
    temple: Temple;

    setIsEditing(value: boolean): void;

    isSubmitting: boolean;
    setIsSubmitting(value: boolean): void;

    isAllowSetValues: boolean;
    setIsAllowSetValues(value: boolean): void;

    updatePrayers(newPrayer: Prayer): void;
    setCurrentPrayer(newPrayer: Prayer): void;
    setCurrentDetails(newDetails: PrayerPriceDetail[]): void;
    resetPrayerForm(): void;
    resetErrorCallback: () => void;

    setPrayerFormRef(value: MutableRefObject<any>): void;
}

const PrayerForm: FC<PrayerFormProps> = (props) => {
    const prayerService = prayerServiceFactory();
    const prayerPriceDetailService = prayerPriceDetailServiceFactory();

    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);
    const [type, setType] = useState<SelectProps>(getCurrentPrayerTypeTranslation(props.t, props.prayer.type));
    const [period, setPeriod] = useState<SelectProps>(getCurrentPrayerPeriodTranslation(props.t, props.prayer.period));

    const prayerFormRef = useRef(null);

    const previousPrayerId = useRef<string>();
    const [currentPrayerId, setCurrentPrayerId] = useState<string>(props.prayer.id);

    const [isDetailsError, setIsDetailsError] = useState<boolean>(false);

    const nullableValues: IPrayerForm = {
        id: "",
        order: undefined,
        name: "",
        info: "",
        type: undefined,
        period: undefined,
    };

    const prayerSchema = useMemo(() => PrayerSchemaFactory({ t: props.t }), [props.t]);

    const prayerForm: FormikProps<IPrayerForm> = useFormik({
        enableReinitialize: true,
        initialValues: nullableValues,
        validationSchema: prayerSchema.schema,
        onSubmit: async (values) => {
            try {
                setErrorVisibility(false);
                props.resetErrorCallback();

                if (isDetailsError) {
                    return setErrorVisibility(true);
                }

                const newPrayer: Prayer | undefined = await prayerService.createRequestOrUpdateCurrentEntity({
                    templeId: props.temple.originId,
                    order: values.order || props.temple.getNextPrayerOrder(),
                    id: values.id || undefined,
                    name: values.name,
                    info: values.info,
                    type: values.type,
                    period: values.period,
                });

                if (!newPrayer) {
                    return setErrorVisibility(true);
                }

                setCurrentPrayerId(newPrayer.id);

                let newPrayerPriceDetails;
                newPrayerPriceDetails = await Promise.all(
                    props.details
                        .filter((el) => el.isChanged)
                        .map((el) =>
                            prayerPriceDetailService.createRequestOrUpdateCurrentEntity({
                                prayerId: newPrayer.id,
                                id: el.id || undefined,
                                maxPeople: el.maxPeople,
                                price: el.priceFloatFormat,
                                currency: Currency.USD,
                            })
                        )
                ).catch(() => (newPrayerPriceDetails = []));

                for (const element of newPrayerPriceDetails) {
                    if (!element) {
                        return setErrorVisibility(true);
                    }
                }

                if (newPrayer) {
                    props.prayer.setPrayerPriceDetails(
                        props.prayer.getSortedPrayerPriceDetails(
                            SortingOrder.ASC,
                            props.details.filter((el) => !el.isChanged).concat(newPrayerPriceDetails)
                        )
                    );
                    newPrayer.assignRelatedEntities(props.prayer);

                    props.updatePrayers(newPrayer);
                    props.setCurrentPrayer(new PrayerModel());

                    props.setIsEditing(false);
                    props.setIsSubmitting(true);
                }
            } catch {
                setErrorVisibility(true);
            }
        },
    });

    useFormsValidConfirmation("prayerForm", prayerForm);

    useEffect(() => {
        previousPrayerId.current = props.prayer.id;
        if (previousPrayerId.current !== currentPrayerId) {
            setErrorVisibility(false);
        }

        if (prayerFormRef) {
            props.setPrayerFormRef(prayerFormRef);
        }

        if (props.isAllowSetValues) {
            prayerForm.setValues({
                id: props.prayer.id,
                order: props.prayer.order,
                name: props.prayer.name,
                info: props.prayer.info,
                type: props.prayer.type,
                period: props.prayer.period,
            });

            setType(getCurrentPrayerTypeTranslation(props.t, props.prayer.type));
            setPeriod(getCurrentPrayerPeriodTranslation(props.t, props.prayer.period));

            props.setIsAllowSetValues(false);
        }

        if (props.isSubmitting) {
            prayerForm.setValues({
                id: "",
                order: undefined,
                name: "",
                info: "",
                type: undefined,
                period: undefined,
            });
            prayerForm.setTouched({});

            setPeriod(null);
            setType(null);

            props.setCurrentDetails([new PrayerPriceDetailModel()]);

            props.setIsSubmitting(false);
        }
    }, [currentPrayerId, previousPrayerId, props, prayerForm]);

    const editPrayerFormHandler = () => {
        prayerForm.setValues({
            ...prayerForm.values,
            id: props.prayer?.id,
            order: props.prayer.order || props.temple.getNextPrayerOrder(),
        });
        prayerForm.handleSubmit();
    };

    const newPrayerFormHandler = () => {
        prayerForm.handleSubmit();
    };

    const prayerTypeHandleChange = (newType: SelectProps) => {
        if (type === newType) {
            return;
        }

        prayerForm.setFieldValue("type", newType.value);
        setType(newType);
    };

    const prayerPeriodHandleChange = (newPeriod: SelectProps) => {
        if (type === newPeriod) {
            return;
        }

        prayerForm.setFieldValue("period", newPeriod.value);
        setPeriod(newPeriod);
    };

    const addNewDetail = () => {
        props.setCurrentDetails(props.details.concat([new PrayerPriceDetailModel()]));
    };

    const clearFieldsCallback = useCallback(() => {
        setType(null);
        setPeriod(null);
        props.setCurrentDetails([new PrayerPriceDetailModel()]);
    }, [props]);

    const resetPrayerForm = useCallback(() => {
        props.resetPrayerForm();
        props.setIsEditing(false);
    }, [props]);

    return (
        <>
            <div ref={prayerFormRef} className="date__form-block choose__temple__pagination">
                <input
                    className={`date__input ${getValidationClass(prayerForm, "name")}`}
                    name="name"
                    onChange={prayerForm.handleChange}
                    value={prayerForm.values.name}
                    type="text"
                    placeholder={props.t("templeRegister.orders.name2")}
                    maxLength={prayerSchema.inputRestrictions.name.max}
                />
                <InputRestrictionsInfo
                    min={prayerSchema.inputRestrictions.name.min}
                    max={prayerSchema.inputRestrictions.name.max}
                    error={prayerForm.errors.name}
                />
            </div>

            <div className="date__form-block">
                <DropDown
                    name="type"
                    value={type}
                    options={getPrayerTypesTranslation(props.t)}
                    onChange={prayerTypeHandleChange}
                    isError={prayerForm.touched.type && !!prayerForm.errors.type}
                    placeholder={props.t("templeRegister.orders.type")}
                />
            </div>

            <div className="date__form-block">
                <DropDown
                    name="period"
                    value={period}
                    options={getPrayerPeriodsTranslation(props.t)}
                    onChange={prayerPeriodHandleChange}
                    isError={prayerForm.touched.period && !!prayerForm.errors.period}
                    placeholder={props.t("templeRegister.orders.period")}
                />
            </div>

            {props.details?.map((element, index) => (
                <div className="date__form-block  date__form-wrap" key={index}>
                    {
                        <PrayerDetailsForm
                            prayer={props.prayer}
                            detail={element}
                            detailIndex={index}
                            setIsDetailsError={setIsDetailsError}
                        />
                    }
                </div>
            ))}

            <div className="date__form-block--nomg ">
                <textarea
                    className={`order__textarea-description date__input ${getValidationClass(prayerForm, "info")}`}
                    name="info"
                    onChange={prayerForm.handleChange}
                    value={prayerForm.values.info}
                    cols={30}
                    rows={10}
                    placeholder={props.t("templeRegister.orders.info")}
                    maxLength={prayerSchema.inputRestrictions.info.max}
                ></textarea>
                <InputRestrictionsInfo
                    min={prayerSchema.inputRestrictions.info.min}
                    max={prayerSchema.inputRestrictions.info.max}
                    error={prayerForm.errors.info}
                />
                <div className="date__bottom-buttons">
                    <ChangeEntityButtons<IPrayerForm>
                        formik={prayerForm}
                        initialValues={nullableValues}
                        disableSaveButton={!props.prayer.id}
                        editFormHandler={editPrayerFormHandler}
                        resetEntityFormHandler={resetPrayerForm}
                        clearFieldsCallback={clearFieldsCallback}
                    />

                    <button className="date__button-add worship__button-add" type="button" onClick={() => addNewDetail()}>
                        <span>
                            <OrdersAddButtonSVG />
                        </span>
                        {props.t("templeRegister.bank.add")}
                    </button>

                    {!props.prayer.id && (
                        <button
                            className={!prayerForm.isValid ? "disabled-add-button worship__button-add" : "date__button-add worship__button-add"}
                            type="button"
                            onClick={() => newPrayerFormHandler()}
                            disabled={!!props.prayer.id || prayerForm.isSubmitting}
                        >
                            <span>
                                <AddButtonSVG fill={!prayerForm.isValid && DISABLED_COLOR} />
                            </span>
                            {props.t("templeRegister.orders.add2")}
                        </button>
                    )}

                    {isErrorVisible && <p>{props.t("errors.common")}</p>}
                </div>
            </div>
            <ConflictErrorModal />
        </>
    );
};

export default withTranslation()(PrayerForm);

import { FC } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Translate } from "../../../../types/Translate";
import { withTranslation } from "react-i18next";

interface TourLinkInstructionModalProps {
    t: Translate;
}

const TourLinkInstructionModal: FC<TourLinkInstructionModalProps> = (props) => {
    return (
        <div className="input-restrictions-info__card">
            <div className="modal-3d-tour-content-inner">
                <h4>{props.t("templeRegister.gallery.tourInstruction.title")}</h4>
                <p>{props.t("templeRegister.gallery.tourInstruction.info")}</p>
                <p>{props.t("templeRegister.gallery.tourInstruction.example")}</p>
                <code>
                    {
                        '<iframe \n    width="100%" \n    height="640" \n    frameborder="0" \n    allow="xr-spatial-tracking; \n    gyroscope; \n    accelerometer" \n    allowfullscreen \n    scrolling="no" src="https://example.com/share/4KrSU?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1">\n</iframe>'
                    }
                </code>
            </div>
        </div>
    );
};

export default withTranslation()(TourLinkInstructionModal);

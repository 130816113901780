import _ from "lodash";
import { Mapper } from "../domain/mappers/mapper";
import { SearchEntityDTO } from "../domain/models/dto/SearchData";
import { SearchEntity } from "../domain/models/SearchData";
import { SearchEntityModel } from "../models/SearchEntityModel";
import searchFieldMapperFactory from "./SearchFieldMapper";
import { SimpleMapper } from "./SimpleMapper";

class SearchEntityMapper extends SimpleMapper<SearchEntity, SearchEntityDTO> {
    protected entityConstructor = SearchEntityModel;

    protected fromDTOFields: string[] = [
        "entityType",
        "entityName",
        "entityId",
    ];

    fromDTO(dto: SearchEntityDTO): SearchEntity {
        const searchFieldMapper = searchFieldMapperFactory();
        const model = super.fromDTO(dto);

        model.entityDetails = !_.isEmpty(dto.entityDetails) ? dto.entityDetails.map(field => searchFieldMapper.fromDTO(field)) : [];
        return model;
    }
}


export default function searchEntityMapperFactory(): Mapper<SearchEntity, SearchEntityDTO> {
    return new SearchEntityMapper();
}

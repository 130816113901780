import { FC } from "react";
import { NonValidPayoutDTO } from "../../../../domain/models/dto/PayoutDTO";
import { Translate } from "../../../../types/Translate";
import { withTranslation } from "react-i18next";
import { getCurrentStatusTranslation } from "../../../../enums/payout-status-enum";

interface NonValidPayoutRowProps {
    t: Translate;
    key: string;
    payout: NonValidPayoutDTO;
}

const NonValidPayoutRow: FC<NonValidPayoutRowProps> = (props) => {
    return (
        <tr key={props.key}>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">{props.payout.csvOrder}</span>
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">{props.payout.id}</span>
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">{props.payout.transactionId}</span>
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">{getCurrentStatusTranslation(props.t, props.payout.status).label}</span>
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">{props.payout.createdAt}</span>
            </td>
        </tr>
    );
};

export default withTranslation()(NonValidPayoutRow);

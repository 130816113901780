import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";


export const PrayerRequirementSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        info: {
            min: 2,
            max: 1000
        }
    }

    return {
        schema: Yup.object().shape({
            id: Yup.string(),
            order: Yup.number(),
            info: Yup.string()
                .min(inputRestrictions.info.min, t("errors.commonErrors.min") + inputRestrictions.info.min)
                .max(inputRestrictions.info.max, t("errors.commonErrors.max") + inputRestrictions.info.max)
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

import { CustomFile } from "../domain/models/CustomFile";
import { FileDTO } from "../domain/models/dto/FileDTO";
import { Owner } from "../enums/parent-enum";
import { Purpose } from "../enums/purpose-enum";
import { BaseEntityImpl } from "./BaseEntity";
import { BaseOrderedEntityMixin } from "./BaseOrderedEntity";

export class FileModel extends BaseOrderedEntityMixin(BaseEntityImpl) implements CustomFile {
    ownerType?: Owner;
    purposeType?: Purpose;
    fileType?: string;
    ownerId?: string;
    filePath?: string;
    thumbnailPath?: string;
    dataUrl?: string;

    constructor(data: FileDTO) {
        super();
        Object.assign(this, data || {});
    }

    get isNewValid(): boolean {
        return !this.id && !!this.dataUrl && !this.isDeleted;
    }

    get thumbnailOrOriginalPath(): string {
        return this.thumbnailPath || this.filePath;
    }

    setOwnerId(ownerId: string) {
        this.ownerId = ownerId;
    }
}

import { FC, useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { TransactionType } from "../../../enums/transaction-type-enum";
import chartServiceFactory from "../../../services/ChartServiceImpl";
import { useEntityChartWithPrevious } from "../../../tools/custom-hooks/chart-hooks/useEntityChartWithPrevious";
import { ChartsParams } from "../../../types/charts/ChartsParams";
import { Translate } from "../../../types/Translate";
import BaseChart from "../../base/charts/BaseChart";
import ChartsHeader from "./ChartsHeader";
import paymentServiceFactory from "../../../services/PaymentServiceImpl";
import { PaymentChartsFields } from "../../../enums/entity-fields/payment-fields-enum";
import { ChartType } from "../../../enums/chart-type-enum";

interface ChartsProps {
    t: Translate;
}

const chartService = chartServiceFactory();

const Charts: FC<ChartsProps> = (props) => {
    const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.DONATE);
    const [chartType, setChartType] = useState<ChartType>(ChartType.PAYMENTS);

    const isAttendance = chartType === ChartType.ATTENDANCE;
    const isPayments = chartType === ChartType.PAYMENTS;

    const chartsDataCallback = useCallback(
        async (chartsParams: ChartsParams) => {
            if (isPayments) {
                return paymentServiceFactory().getPaymentCharts(
                    chartsParams,
                    PaymentChartsFields.AMOUNT,
                    transactionType
                );
            }

            return [];
        },
        [isPayments, transactionType]
    );

    const charts = useEntityChartWithPrevious(true, chartsDataCallback);

    useEffect(() => {
        charts.currentPeriodChart.applyChartsParams();
        charts.prevPeriodChart.applyChartsParams();
    }, [chartType]);

    const transactionTypeHandler = useCallback(
        (value: TransactionType) => {
            setTransactionType(value);
            charts.currentPeriodChart.applyChartsParams();
            charts.prevPeriodChart.applyChartsParams();
        },
        [charts]
    );

    const chartTitle = isPayments ? props.t(`transactionType.${transactionType}`) : "";

    return (
        <>
            <ChartsHeader
                currentPeriodChart={charts.currentPeriodChart}
                prevPeriodChart={charts.prevPeriodChart}
                transactionType={transactionType}
                transactionTypeHandler={transactionTypeHandler}
                isAttendance={isAttendance}
                isPayments={isPayments}
                setChartType={setChartType}
            />

            <BaseChart
                title={chartTitle}
                periodType={charts.currentPeriodChart.getPeriodType()}
                currentPeriod={{
                    label: chartService.getChartLabel(props.t, charts.currentPeriodChart.chartsParams),
                    data: charts.currentPeriodChart.chartsData,
                }}
                prevPeriod={{
                    label: chartService.getChartLabel(props.t, charts.prevPeriodChart.chartsParams),
                    data: charts.prevPeriodChart.chartsData,
                }}
            />
        </>
    );
};

export default withTranslation()(Charts);

import { FC } from "react";
import { withTranslation } from "react-i18next";
interface PaymentHeaderProps {
    t(key?: string): string;
    totalRows: number;

    visibleChart?: boolean;
    setVisibleChart?: (value: boolean) => void;

    error?: string;
}

const PaymentHeader: FC<PaymentHeaderProps> = (props) => {
    return (
        <>
            <h1 className="inner__title">{props.t("admin.customerSupport.header")}</h1>
            <div className="inner__info">
                <div className="inner__info-left">
                    <div className="inner__info-number">
                        {props.t("admin.customerSupport.totalCSTCount")}
                        <span>{props.totalRows}</span>
                    </div>
                </div>
            </div>

            {props.error && props.t(props.error)}
        </>
    );
};

export default withTranslation()(PaymentHeader);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Temple } from "../../../domain/models/Temple";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import { Translate } from "../../../types/Translate";

interface TempleRowProps {
    t: Translate;
    temple: Temple;
}

const TempleRow: FC<TempleRowProps> = (props) => {
    const navigate = useNavigate();
    const religionService = religionsServiceFactory();

    return (
        <tr onClick={() => navigate(`/admin/temples/${props.temple.id}/details`)}>
            <td className="td-style td-padding2">
                <span className="td-inner">{props.temple.name}</span>
            </td>
            <td className="td-style td-padding7">
                <span className="td-inner--min1">{props.temple.country}</span>
            </td>
            <td className="td-style td-padding6">
                <span className="td-inner--min1">
                    {props.t("religions." + religionService.getReligion(props.temple.religionId)?.label)}
                </span>
            </td>
            <td className="td-style td-padding7">
                <span className="td-inner--min1">
                    {props.t("religionCurrents." + religionService.getReligionCurrent(props.temple.religionCurrentId)?.label)}
                </span>
            </td>
            <td className="td-style td-padding7">
                <span className="td-inner--min1">{props.temple.incomeFloatStr}$</span>
            </td>
        </tr>
    );
};

export default withTranslation()(TempleRow);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Translate } from "../../../../types/Translate";
import ModalWindow from "../../../base/ModalWindow";

interface TempleRegisterCancelModalProps {
    t: Translate;
    setIsCancelModalVisibility: (value: boolean) => void;
}

const TempleRegisterCancelModal: FC<TempleRegisterCancelModalProps> = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <ModalWindow
                contentClass="modal-remove__content"
                bodyClass=" "
                closeCallback={() => props.setIsCancelModalVisibility(false)}
                content={
                    <>
                        <div className="modal__text">{props.t("templeRegister.footer.cancelModal.title")}</div>
                        <div className="modal__buttons">
                            <button className="modal__button modal__button-remove" onClick={() => navigate("/temple-admin")}>
                                {props.t("templeRegister.footer.cancelModal.exit")}
                            </button>
                            <button className="modal__button modal__button-cancel" onClick={() => props.setIsCancelModalVisibility(false)}>
                                {props.t("templeRegister.footer.cancelModal.stay")}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
};

export default withTranslation()(TempleRegisterCancelModal);

import { FC, useState } from "react";
import { LanguageService } from "../../domain/services/LanguageService";
import LanguageServiceFactory from "../../services/LanguageServiceImpl";

interface LangSelectProps {
    langColor?: string;
}

const LangSelect: FC<LangSelectProps> = (props) => {
    const langService: LanguageService = LanguageServiceFactory();
    const [isSelectLangVisible, setSelectLangVisibility] = useState<boolean>(false);

    const handleChangeLang = (lang: string) => {
        langService.setCurrentLang(lang);
        langService.notifyListeners(lang);
        setSelectLangVisibility(false);
    };

    return (
        <div className="header__lang-wrap">
            <div className="select__item">
                <div className="select__title">
                    <div className="select__value" onClick={() => setSelectLangVisibility(!isSelectLangVisible)}>
                        <span style={{ color: `${props.langColor ? props.langColor : ""}` }}>
                            {langService.currentLang.toUpperCase()}
                        </span>
                    </div>
                </div>

                <div className="select__options" style={{ display: `${isSelectLangVisible ? "block" : "none"}` }}>
                    {langService.langs.map(
                        (lang) =>
                            !langService.isCurrentLang(lang) && (
                                <div
                                    key={lang}
                                    className="select__option"
                                    style={{ display: "block" }}
                                    onClick={() => handleChangeLang(lang)}
                                >
                                    {lang.toUpperCase()}
                                </div>
                            )
                    )}
                </div>
            </div>
        </div>
    );
};

export default LangSelect;

import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";


export const AbbotSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        abbotInfo: {
            min: 5,
            max: 10000
        }
    }

    return {
        schema: Yup.object().shape({
            abbotInfo: Yup.string()
                .min(inputRestrictions.abbotInfo.min, t("errors.commonErrors.min") + inputRestrictions.abbotInfo.min)
                .max(inputRestrictions.abbotInfo.max, t("errors.commonErrors.max") + inputRestrictions.abbotInfo.max)
                .nullable(true)
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

import { PrayerRequirementDTO } from "../domain/models/dto/PrayerRequirementDTO";
import { PrayerRequirement } from "../domain/models/PrayerRequirement";
import { BaseRelatedOrderedEntityMixinFactory } from "./BaseTempleRelatedModel";

class PrayerRequirementModel extends BaseRelatedOrderedEntityMixinFactory<PrayerRequirementDTO>() implements PrayerRequirement {
    order: number;
    templeId: string;
    info: string;

    constructor(data?: PrayerRequirementDTO) {
        super();
        Object.assign(this, data || {});
    }
}

export default PrayerRequirementModel;

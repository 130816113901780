import { FC } from "react";
import { WorshipSchedule } from "../../../../../domain/models/WorshipSchedule";
import { Week } from "../../../../../enums/week-enum";
import WorshipScheduleItem from "./WorshipScheduleItem";

interface WorshipSchedulesDayProps {
    dayType: Week;
    worshipSchedules: WorshipSchedule[];
}

const WorshipSchedulesDay: FC<WorshipSchedulesDayProps> = (props) => {
    return (
        <table className="information__worship-block">
            {props.worshipSchedules.length ? props.worshipSchedules.map((schedule, index) => (
                <WorshipScheduleItem  index={index} dayType={props.dayType} worshipSchedule={schedule} />
            )) : (
                <WorshipScheduleItem index={0} dayType={props.dayType} worshipSchedule={null} />
            )}
        </table>
    );
};

export default WorshipSchedulesDay;

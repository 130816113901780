import { FC } from "react";
import { PrayerRequirement } from "../../../domain/models/PrayerRequirement";

interface OrderPrayerRequirementProps {
    prayerRequirement: PrayerRequirement;
}

const OrderPrayerRequirement: FC<OrderPrayerRequirementProps> = (props) => {
    return (
        <li className="order-payer__description-item text">
            {props.prayerRequirement.info}
        </li>
    );
};

export default OrderPrayerRequirement;

import { FC } from "react";

interface WorshipScheduleItemMainInfoProps {
    begin: string;
    end: string;
    name: string;
    isNoTimeInfo?: boolean;
}

const WorshipScheduleItemMainInfo: FC<WorshipScheduleItemMainInfoProps> = (props) => {
    return (
        <div className="information__worship-wrap">
            <td className={"information__worship-time information__style" + (props.isNoTimeInfo ? " information__worship-time-no-time" : " ")}>
                {props.begin} - {props.end}
            </td>
            <td className="information__worship-about information__style">{props.name}</td>
        </div>
    );
};

export default WorshipScheduleItemMainInfo;

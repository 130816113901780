import { FC, MutableRefObject } from "react";
import { withTranslation } from "react-i18next";
import { PaymentAccountDTO } from "../../../../domain/models/dto/PaymentAccountDTO";
import { PaymentAccount } from "../../../../domain/models/PaymentAccount";
import { Temple } from "../../../../domain/models/Temple";
import { PaymentAccountService } from "../../../../domain/services/PaymentAccountService";
import paymentAccountMapperFactory from "../../../../mappers/PaymentAccountMapper";
import paymentAccountServiceFactory from "../../../../services/PaymentAccountServiceImpl";
import { useOrderedEntityRemove } from "../../../../tools/custom-hooks/remove-hooks/useOrderedEntityRemove";
import { Translate } from "../../../../types/Translate";
import PaymentDetailInfo from "./PaymentDetailInfo";

interface PaymentDetailsProps {
    t: Translate;

    temple: Temple;
    paymentAccounts: PaymentAccount[];

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    isOrderChanged: boolean;
    setIsOrderChanged(value: boolean): void;
    setIsAllowSetValues(value: boolean): void;

    setCurrentPaymentAccount(value: PaymentAccount): void;
    updatePaymentAccounts(updatedPaymentAccount: PaymentAccount): void;
    removePaymentAccount(order: number): PaymentAccount[];

    paymentAccountFormRef: MutableRefObject<any>;
}

const PaymentDetails: FC<PaymentDetailsProps> = (props) => {
    const accounts: PaymentAccount[] = props.paymentAccounts;
    const removeHandlers = useOrderedEntityRemove<PaymentAccountDTO, PaymentAccount, PaymentAccountService>(
        paymentAccountServiceFactory,
        paymentAccountMapperFactory,
        props.removePaymentAccount,
    );

    return (
        <div className="entity-container-scroll">
            {removeHandlers.isErrorVisible && props.t("errors.common")}
            {accounts.map((account, index) => (
                <div key={index}>
                    <PaymentDetailInfo
                        account={account}
                        temple={props.temple}
                        isEditing={props.isEditing}
                        setIsEditing={props.setIsEditing}
                        isOrderChanged={props.isOrderChanged}
                        setIsOrderChanged={props.setIsOrderChanged}
                        setCurrentPaymentAccount={props.setCurrentPaymentAccount}
                        updatePaymentAccounts={props.updatePaymentAccounts}
                        setRemovableModel={removeHandlers.setRemovableModel}
                        paymentAccountFormRef={props.paymentAccountFormRef}
                        setIsAllowSetValues={props.setIsAllowSetValues}
                        additionalWrapperClassName={index === 0 ? "" : "order__table__wrapper"}
                    />
                </div>
            ))}
        </div>
    );
};

export default withTranslation()(PaymentDetails);

import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";
import { PrayerPriceDetailDTO } from "../domain/models/dto/PrayerPriceDetailDTO";
import { PrayerPriceDetail } from "../domain/models/PrayerPriceDetail";
import PrayerPriceDetailModel from "../models/PrayerPriceDetailModel";
import { fixedToFloatAmountStr } from "../tools/Tools";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";

class PrayerPriceDetailMapper extends SimpleChangesRequestEntityMapper<PrayerPriceDetail, PrayerPriceDetailDTO> {
    protected entityConstructor = PrayerPriceDetailModel;

    protected toDTOFields: string[] = [
        "id",
        "prayerId",
        "maxPeople",
        "price",
        "currency",
        "isDeleted"
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        ...this.toChangesDTOFields,
        "status",
        "createdAt",
        "updatedAt",
    ];

    fromDTO(dto: PrayerPriceDetailDTO): PrayerPriceDetail {
        const model = super.fromDTO(dto);
        model.priceFloatFormat = fixedToFloatAmountStr(model.price);
        return model;
    }
}


export default function prayerPriceDetailMapperFactory(): ChangesRequestEntityMapper<PrayerPriceDetail, PrayerPriceDetailDTO> {
    return new PrayerPriceDetailMapper()
}

import { BaseEntity } from "../domain/models/BaseEntity";
import { SortingField } from "../enums/sorting-field-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import { OrderedEntity } from "../types/OrderedEntity";
import { ReplacedModel } from "../types/ReplacedModel";

export default class CollectionTools {
    static sortEntities<Model>(
        entities: any[],
        sortingOrder: SortingOrder,
        field: SortingField = SortingField.ORDER
    ): Model[] {
        return entities.sort((current, next) =>
            sortingOrder === SortingOrder.ASC ? current[field] - next[field] : next[field] - current[field]
        );
    }

    static sortEntitiesByLocaleCompare<Model>(
        entities: any[],
        sortingOrder: SortingOrder,
        field: SortingField = SortingField.ORDER
    ): Model[] {
        return entities.sort((current, next) =>
            sortingOrder === SortingOrder.ASC
                ? current[field].localeCompare(next[field])
                : next[field].localeCompare(current[field])
        );
    }

    static filterEntities<Model>(entities: any[], field: SortingField, value: any): Model[] {
        return entities.filter((entity) => entity[field] === value);
    }

    static findEntity<Model>(entities: any[], field: SortingField, value: any): Model {
        return entities.find((entity) => entity[field] === value);
    }

    static upEntity<Model extends OrderedEntity>(order: number, entities: Model[]): ReplacedModel<Model> {
        if (order === 1) {
            return;
        }

        const prevElement = entities.find((el) => el.order === order);
        const currentElement = entities.find((el) => el.order === order - 1);

        if (prevElement) {
            prevElement.order = order - 1;
        }

        if (currentElement) {
            currentElement.order = order;
        }

        return { prevElement, currentElement };
    }

    static downEntity<Model extends OrderedEntity>(order: number, entities: Model[]): ReplacedModel<Model> {
        if (order === entities.length) {
            return;
        }

        const prevElement = entities.find((el) => el.order === order + 1);
        const currentElement = entities.find((el) => el.order === order);

        if (prevElement) {
            prevElement.order = order;
        }

        if (currentElement) {
            currentElement.order = order + 1;
        }

        return { prevElement, currentElement };
    }

    static removeEntity<Model extends OrderedEntity>(
        order: number,
        entities: Model[],
        markAsRemoved: boolean = false
    ): Model[] {
        entities
            .filter((entity) => !entity.isDeleted)
            .forEach((entity, index) => {
                if (entity.order === order && !markAsRemoved) {
                    entities.splice(index, 1);
                }

                if (entity.order > order) {
                    entity.order -= 1;
                    entity.markAsChanged && entity.markAsChanged();
                }
            });

        return entities;
    }

    static removeEntityAndReturnNewList<Model extends OrderedEntity>(
        order: number,
        entities: Model[],
    ): Model[] {
        const newEntitiesList: Model[] = [];
        entities
            .filter((entity) => entity.order !== order)
            .forEach((entity) => {
                entity.order -= 1;
                entity.markAsChanged && entity.markAsChanged();
                newEntitiesList.push(entity);
            });

        return newEntitiesList;
    }

    static updateEntityList<Model extends BaseEntity>(newEntities: Model[], oldEntities: Model[]): Model[] {
        return newEntities.concat(
            oldEntities.filter(oldEntity => {
                return newEntities.every(newEntity => {
                    return (
                        oldEntity.id &&
                        (oldEntity.id !== newEntity.id) &&
                        (oldEntity.id !== newEntity.parentId) &&
                        (oldEntity.parentId ? oldEntity.parentId !== newEntity.parentId : true)
                    );
                });
            })
        );
    }
}

import { WeekSchedule } from "../domain/models/WeekSchedule";
import { WeekScheduleDTO } from "../domain/models/dto/WeekScheduleDTO";
import { WeekScheduleService } from "../domain/services/WeekScheduleService";
import { BaseServiceImpl } from "./BaseServiceImpl";
import weekScheduleMapperFactory from "../mappers/WeekScheduleMapper";
import { RemoveResponse } from "../types/RemoveResponse";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";

export class WeekScheduleServiceImpl extends BaseServiceImpl implements WeekScheduleService {
    async createRequestOrUpdateCurrentEntity(dto: WeekScheduleDTO): Promise<WeekSchedule> {
        if (!dto.templeId) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<WeekScheduleDTO, WeekSchedule>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.WEEK_SCHEDULE), weekScheduleMapperFactory
        );
    }

    async removeEntity(entity: WeekSchedule): Promise<RemoveResponse> {
        return await this.removeEntityById<WeekScheduleDTO, WeekSchedule>(entity, this.getV2EntityUrl(EntitiesBaseUrls.WEEK_SCHEDULE), weekScheduleMapperFactory);
    }
}

export default function weekScheduleServiceFactory(): WeekScheduleService {
    return new WeekScheduleServiceImpl();
}

import { withTranslation } from "react-i18next";
import { Translate } from "../../../../types/Translate";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Popup from "reactjs-popup";
import ModalWindow from "../../../base/ModalWindow";
import { useNavigate } from "react-router-dom";
import { payoutServiceFactory } from "../../../../services/PayoutServiceImpl";
import { CreationPayoutForm, Payout } from "../../../../domain/models/Payout";
import { useFormik } from "formik";
import { CreationPayoutSchemaFactory } from "../../../../validation-schemas/admin/creation-payout-schema";
import DropDown from "../../../base/DropDown";
import paymentAccountServiceFactory from "../../../../services/PaymentAccountServiceImpl";
import { SelectProps } from "../../../../domain/models/SelectProps";
import { TempleStatistic } from "../../../../domain/models/TempleStatistic";
import CustomCheckbox from "../../../base/CustomCheckbox";
import PayoutCreationModalTable from "./PayoutCreationModalTable";
import { useTableRowsActions } from "../../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import { Payment } from "../../../../domain/models/Payment";
import { fixedToFloat } from "../../../../tools/Tools";

interface PayoutCreationModalProps {
    t: Translate;
    credit: TempleStatistic;
    isPayoutCreationModalActive: boolean;
    closeModalCallback: () => void;
    setReloadCache: (value: boolean) => void;
    templeId: string;
    templeName: string;
    transactionAmount: string;
    templeStatisticId: string;
}

const PayoutCreationModal: FC<PayoutCreationModalProps> = (props) => {
    const [error, setError] = useState("");
    const [redirectToPayoutDetails, setRedirectToPayoutDetails] = useState(false);
    const [paymentAccounts, setPaymentAccounts] = useState<SelectProps[]>(null);
    const [currentPaymentAccount, setCurrentPaymentAccount] = useState<SelectProps>(null);
    const [isActiveTransactionsTableHidden, setIsActiveTransactionsTableHidden] = useState(true);

    const [finalTempleTransactionAmount, setFinalTempleTransactionAmount] = useState(0);
    const [finalTransactionCount, setFinalTransactionCount] = useState(0);
    const [finalTransactionPrayersCount, setFinalTransactionPrayersCount] = useState(0);
    const [finalTransactionDonationsCount, setFinalTransactionDonationsCount] = useState(0);

    const navigate = useNavigate();

    const closeCallback = () => {
        props.closeModalCallback();
        setError("");
    };

    const initialValues: CreationPayoutForm = {
        templeStatisticId: props.templeStatisticId,
        paymentAccountId: undefined,
    };

    const creationPayoutSchema = useMemo(() => CreationPayoutSchemaFactory({ t: props.t }), [props.t]);

    const paymentRowsHandlers = useTableRowsActions<Payment, Payout>({
        addEntityToListCallback: (entity) => {
            setFinalTempleTransactionAmount(prev => prev + entity.recipientAmount);
            setFinalTransactionCount(prev => prev + 1);
            entity.isPrayer && setFinalTransactionPrayersCount(prev => prev + 1);
            entity.isDonation && setFinalTransactionDonationsCount(prev => prev + 1);
        },
        removeEntityFromListCallback: (entity) => {
            setFinalTempleTransactionAmount(prev => prev - entity.recipientAmount);
            setFinalTransactionCount(prev => prev - 1);
            entity.isPrayer && setFinalTransactionPrayersCount(prev => prev - 1);
            entity.isDonation && setFinalTransactionDonationsCount(prev => prev - 1);
        },
    });

    const creationPayoutForm = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: creationPayoutSchema.schema,
        onSubmit: async (values) => {
            try {
                setError("");
                const paymentIds = paymentRowsHandlers.getData().map(payment => payment.id);
                const payout = await payoutServiceFactory().createPayout(
                    values.templeStatisticId,
                    values.paymentAccountId,
                    paymentIds,
                );
                if (!payout?.id) {
                    return setError(props.t("errors.common"));
                }

                setRedirectToPayoutDetails(false);
                if (redirectToPayoutDetails) {
                    return navigate(`/admin/payouts/${payout.id}`);
                }

                closeCallback();
                props.setReloadCache(true);
            } catch {
                setError("errors.common");
            }
        },
    });

    const paymentAccountHandleChange = useCallback(
        (value: SelectProps) => {
            creationPayoutForm.setFieldValue("paymentAccountId", value?.value);
            setCurrentPaymentAccount(value);
        },
        [creationPayoutForm]
    );

    useEffect(() => {
        const uploadData = async () => {
            const paymentAccountService = paymentAccountServiceFactory();
            const paymentAccountsSelectProps = paymentAccountService.getPaymentAccountSelectProps(
                await paymentAccountService.getPaymentAccountsByTempleId(props.templeId),
                props.t
            );
            setPaymentAccounts(paymentAccountsSelectProps);
            paymentAccountHandleChange(paymentAccountsSelectProps[0]);
        };

        if (!paymentAccounts && props.templeId) {
            uploadData();
        }
    }, []);

    const createPayoutAndRedirectToDetails = () => {
        if (!isActiveTransactionsTableHidden && !paymentRowsHandlers.getData().length) {
            return;
        }
        setRedirectToPayoutDetails(true);
        creationPayoutForm.handleSubmit();
    };

    const createPayoutAndReturnToCredits = () => {
        if (!isActiveTransactionsTableHidden && !paymentRowsHandlers.getData().length) {
            return;
        }
        creationPayoutForm.handleSubmit();
    }

    const getSubmitButtonClass = () => {
        return (
            "modal__button " +
            (
                creationPayoutForm.values.paymentAccountId &&
                (!isActiveTransactionsTableHidden ? paymentRowsHandlers.getData().length : true)
                ? "modal__button-remove modal__button-remove__creation"
                : "modal__button-remove modal__button-remove__creation modal__buttons__creation__disabled")
        );
    };

    const getFinalTempleTransactionAmount = () => {
        return !isActiveTransactionsTableHidden
            ? fixedToFloat(finalTempleTransactionAmount)
            : props.credit.finalTempleTransactionAmount;
    }

    const getFinalTransactionCount = () => {
        return !isActiveTransactionsTableHidden
            ? finalTransactionCount
            : props.credit.finalTransactionCount;
    }

    const getFinalTransactionPrayersCount = () => {
        return !isActiveTransactionsTableHidden
            ? finalTransactionPrayersCount
            : props.credit.finalTransactionPrayersCount;
    }

    const getFinalTransactionDonationsCount = () => {
        return !isActiveTransactionsTableHidden
            ? finalTransactionDonationsCount
            : props.credit.finalTransactionDonationsCount;
    }


    return (
        <Popup open={props.isPayoutCreationModalActive} modal overlayStyle={{ zIndex: 999999 }} onClose={closeCallback}>
            <ModalWindow
                contentClass="modal-remove__content modal-remove__content__payout-creation"
                bodyClass=" "
                closeCallback={closeCallback}
                content={
                    <>
                        <h2 className="update__section__title">{props.t("admin.payouts.credits.title")}</h2>
                        <div className="date order__table-wrap">
                            <table className="date order__table order__table__resolve-modal">
                                <thead>
                                    <tr>
                                        <th>{props.t("admin.payouts.credits.amount")}</th>
                                        <th>{props.t("admin.payouts.credits.table.transactionsPrayersCount")}</th>
                                        <th>{props.t("admin.payouts.credits.table.transactionsCount")}</th>
                                        <th>{props.t("admin.payouts.credits.table.transactionsDonationsCount")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{getFinalTempleTransactionAmount()}$</td>
                                        <td>{getFinalTransactionCount()}</td>
                                        <td>{getFinalTransactionPrayersCount()}</td>
                                        <td>{getFinalTransactionDonationsCount()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal__text">{props.t("admin.payouts.credits.modalText3")}</div>
                        {error}
                        {paymentAccounts ? (
                            <div className="date__form-block date select__value">
                                <DropDown
                                    name="status"
                                    value={currentPaymentAccount}
                                    options={paymentAccounts}
                                    onChange={paymentAccountHandleChange}
                                    isError={
                                        creationPayoutForm.touched.paymentAccountId &&
                                        !!creationPayoutForm.errors.paymentAccountId
                                    }
                                    disableIsClearable={true}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="date checkbox__label__full-length">
                            <CustomCheckbox
                                id="payout-creation-checkbox-id"
                                defaultValue={true}
                                value={isActiveTransactionsTableHidden}
                                className="checkbox__input"
                                additionalLabelClassName="date__text church__filter-item"
                                onChange={() => setIsActiveTransactionsTableHidden(!isActiveTransactionsTableHidden)}
                                labelContent={<>{props.t("admin.payouts.credits.modalText1")}</>}
                            />
                        </div>
                        {isActiveTransactionsTableHidden ? (
                            <></>
                        ) : (
                            <PayoutCreationModalTable
                                isActiveTransactionsTableShown={!isActiveTransactionsTableHidden}
                                templeId={props.templeId}
                                templeName={props.templeName}
                                paymentRowsHandlers={paymentRowsHandlers}
                            />
                        )}
                        <div className="modal__buttons__creation">
                            <button className={getSubmitButtonClass()} onClick={createPayoutAndRedirectToDetails}>
                                {props.t("admin.payouts.credits.modalCreateAndOpenButton")}
                            </button>
                            <button
                                className={getSubmitButtonClass()}
                                onClick={createPayoutAndReturnToCredits}
                            >
                                {props.t("admin.payouts.credits.modalCreateAndReturnButton")}
                            </button>
                        </div>
                        <button
                            className="modal__button modal__button-cancel modal__button-cancel__creation"
                            onClick={closeCallback}
                        >
                            {props.t("admin.payouts.credits.modalDiscardButton")}
                        </button>
                    </>
                }
            />
        </Popup>
    );
};

export default withTranslation()(PayoutCreationModal);

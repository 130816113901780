import { FC, useEffect, useState } from "react";
import { Navigate, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { Route } from "react-router-dom";
import Abbot from "../components/temple-admin/temple-register/abbot/Abbot";
import MainBankDetailsComponent from "../components/temple-admin/temple-register/bank-details/MainBankDetailsComponent";
import MainTempleRegisterComponent from "../components/temple-admin/temple-register/MainTempleRegisterComponent";
import MainGalleriesComponent from "../components/temple-admin/temple-register/galleries/MainGalleriesComponent";
import MainInfo from "../components/temple-admin/temple-register/MainInfo";
import Orders from "../components/temple-admin/temple-register/orders/Orders";
import MainPaymentDetailsComponent from "../components/temple-admin/temple-register/payment-details/MainPaymentDetailsComponent";
import Schedules from "../components/temple-admin/temple-register/schedules/Schedules";
import VisitorsInfo from "../components/temple-admin/temple-register/visitors-info/VisitorsInfo";
import { Religion } from "../domain/models/Religions";
import { Temple } from "../domain/models/Temple";
import { CreateTempleParams } from "../enums/create-temple-params-enum";
import religionsServiceFactory from "../services/ReligionsServiceImpl";
import templeServiceFactory from "../services/TempleServiceImpl";
import LoadingPanelWithContent from "../components/base/LoadingPanelWithContent";
import { FormikFormsProvider } from "../context/FormikFormsProvider";
import CorrespondencePage from "../components/temple-admin/temple-register/correspondence/CorrespondencePage";

interface CreateTempleRoutesProps {}

const CreateTempleRoutes: FC<CreateTempleRoutesProps> = () => {
    const navigate = useNavigate();
    const templeService = templeServiceFactory();

    const [temple, setTemple] = useState<Temple | undefined | null>(null);
    const [religions, setReligions] = useState<Religion[] | null>(null);

    const templeId = useSearchParams()[0].get(CreateTempleParams.ID);
    const isNewTemple = useSearchParams()[0].get(CreateTempleParams.NEW_TEMPLE);

    const isDataExist = !!(temple && religions);
    const currentTemple = isDataExist ? temple : templeService.getEmptyTemple();

    useEffect(() => {
        const setData = async () => {
            const templeService = templeServiceFactory();
            const religionsService = religionsServiceFactory();

            const [
                newTemple,
                cachedReligions
            ] = await Promise.all([
                templeId ? templeService.getCachedLatestTempleById(templeId) : templeService.getEmptyTemple(),
                religionsService.getCachedReligions(),
            ]);

            templeService.assignNewTemple(newTemple);

            setTemple(newTemple);
            setReligions(cachedReligions);
        };

        if ((templeId && !isNewTemple && !temple) || (!templeId && isNewTemple && !temple)) {
            setData();
        }

        // if (!templeId && isNewTemple) {
        //     navigate("/main-info".concat(`?${CreateTempleParams.NEW_TEMPLE}=${true}`));
        // }

        if (!templeId && !isNewTemple) {
            navigate("/temple-admin/main");
        }
    }, [templeId, isNewTemple, temple, navigate]);

    return (
        <Routes>
            <Route path="/" element={<MainTempleRegisterComponent temple={temple} />}>
                <Route
                    path="main-info"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id || isNewTemple ? (
                                    <FormikFormsProvider>
                                        <MainInfo temple={currentTemple} setTemple={setTemple} />
                                    </FormikFormsProvider>
                                )
                                : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                <Route
                    path="abbot"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id ? (
                                    <FormikFormsProvider>
                                        <Abbot temple={currentTemple} setTemple={setTemple} />
                                    </FormikFormsProvider>
                                ) : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                <Route
                    path="schedules"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id ? (
                                    <FormikFormsProvider>
                                        <Schedules temple={currentTemple} setTemple={setTemple} />
                                    </FormikFormsProvider>
                                )
                                : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                <Route
                    path="visitors-info"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id ? (
                                    <FormikFormsProvider>
                                        <VisitorsInfo temple={currentTemple} setTemple={setTemple} />
                                    </FormikFormsProvider>
                                )
                                : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                <Route
                    path="orders"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id ? (
                                    <FormikFormsProvider>
                                        <Orders temple={currentTemple} setTemple={setTemple} />
                                    </FormikFormsProvider>
                                )
                                : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                <Route
                    path="payment-details"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id ? (
                                    <FormikFormsProvider>
                                        <MainPaymentDetailsComponent temple={currentTemple} />
                                    </FormikFormsProvider>
                                )
                                : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                <Route
                    path="bank-details"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id ? (
                                    <FormikFormsProvider>
                                        <MainBankDetailsComponent temple={currentTemple} />
                                    </FormikFormsProvider>
                                )
                                : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                <Route
                    path="galleries"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id ? (
                                    <FormikFormsProvider>
                                        <MainGalleriesComponent temple={currentTemple} />
                                    </FormikFormsProvider>
                                )
                                : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                <Route
                    path="correspondence"
                    element={
                        <LoadingPanelWithContent
                            isVisible={!isDataExist}
                            content={
                                currentTemple.id ? (
                                    <FormikFormsProvider>
                                        <CorrespondencePage temple={currentTemple} />
                                    </FormikFormsProvider>
                                )
                                : (
                                    isDataExist && <Navigate to={"/temple-admin"} replace />
                                )
                            }
                        />
                    }
                />

                {/* <Route
                    path="/"
                    element={
                        <Navigate
                            to={"main-info".concat(`?${CreateTempleParams.ID}=${currentTemple.originId}`)}
                            replace
                        />
                    }
                />

                <Route
                    path="*"
                    element={
                        <Navigate
                            to={"main-info".concat(`?${CreateTempleParams.ID}=${currentTemple.originId}`)}
                            replace
                        />
                    }
                /> */}
            </Route>
        </Routes>
    );
};

export default CreateTempleRoutes;

import { FC } from "react";
import EditPencilSVG from "../../base/svg/EditPencilSVG";

interface AdminTempleNameProps {
    templeName: string;
    setIsClicked: (value: boolean) => void;
}

const AdminTempleName: FC<AdminTempleNameProps> = (props) => {
    return (
        <div onClick={() => props.setIsClicked(true)}>
            {props.templeName}
            <div className="sidebar__church__name__pencil-img">
                <EditPencilSVG />
            </div>
        </div>
    );
};

export default AdminTempleName;

import { FC } from "react";
import DropDown from "../DropDown";
import { TransactionType, getCurrentTransactionTypeTranslation, getTransactionTypeTranslation } from "../../../enums/transaction-type-enum";
import { Translate } from "../../../types/Translate";
import { SelectProps } from "../../../domain/models/SelectProps";
import { withTranslation } from "react-i18next";

interface TransactionTypeDropDownProps {
    t: Translate;
    transactionType: TransactionType;
    setTransactionType: (value: TransactionType) => void;
}

const TransactionTypeDropDown: FC<TransactionTypeDropDownProps> = (props) => {
    const transactionTypeHandler = (selectProps: SelectProps) => {
        if (!selectProps) {
            return;
        }
        props.setTransactionType(parseInt(selectProps.value));
    }

    return (
        <DropDown
            options={getTransactionTypeTranslation(props.t)}
            value={getCurrentTransactionTypeTranslation(props.t, props.transactionType)}
            name="transactionType"
            onChange={transactionTypeHandler}
            placeholder="select transaction type"
            disableIsClearable={true}
        />
    );
};

export default withTranslation()(TransactionTypeDropDown);

import { FC, MutableRefObject, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { PaymentAccount } from "../../../../domain/models/PaymentAccount";
import { Temple } from "../../../../domain/models/Temple";
import paymentAccountMapperFactory from "../../../../mappers/PaymentAccountMapper";
import paymentAccountServiceFactory from "../../../../services/PaymentAccountServiceImpl";
import { Translate } from "../../../../types/Translate";
import DownSVG from "../../../base/svg/DownSVG";
import UpSVG from "../../../base/svg/UpSVG";
import WhiteEditButtonSVG from "../../../base/svg/WhiteEditButtonSVG";
import WhiteRemoveSVG from "../../../base/svg/WhiteRemoveSVG";

interface PaymentDetailInfoProps {
    t: Translate;

    account: PaymentAccount;
    temple: Temple;

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    isOrderChanged: boolean;
    setIsOrderChanged(value: boolean): void;

    setCurrentPaymentAccount(value: PaymentAccount): void;
    updatePaymentAccounts(updatedPaymentAccount: PaymentAccount): void;
    setRemovableModel(account: PaymentAccount): void;
    setIsAllowSetValues(value: boolean): void;

    paymentAccountFormRef: MutableRefObject<any>;
    additionalWrapperClassName: string;
}

const PaymentDetailInfo: FC<PaymentDetailInfoProps> = (props) => {
    const paymentAccountService = paymentAccountServiceFactory();
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    const scrollToPrayerForm = () => props.paymentAccountFormRef.current.scrollIntoView();

    const updateAccountOrder = async (toUp: boolean) => {
        const mapper = paymentAccountMapperFactory();

        let accounts;

        if (toUp) {
            accounts = props.temple.upPaymentAccount(props.account.order);
        } else {
            accounts = props.temple.downPaymentAccount(props.account.order);
        }

        let result: PaymentAccount[];
        result = await Promise.all([
            paymentAccountService.createRequestOrUpdateCurrentEntity(mapper.toDTO(accounts?.prevElement)),
            paymentAccountService.createRequestOrUpdateCurrentEntity(mapper.toDTO(accounts?.currentElement)),
        ]).catch(() => (result = []));

        const current = toUp ? result[0] : result[1];

        if (!current && (accounts.currentElement || accounts.prevElement)) {
            setErrorVisibility(true);
        }

        props.setIsOrderChanged(true);

    };

    const editHandler = () => {
        scrollToPrayerForm();

        props.setIsAllowSetValues(true);
        props.setIsEditing(true);
        props.setCurrentPaymentAccount(props.account);
    };

    useEffect(() => {
        if (props.isOrderChanged) {
            props.setIsOrderChanged(false);
        }
    });

    return (
        <div className={props.additionalWrapperClassName}>
            {isErrorVisible && <p>{props.t("errors.common")}</p>}
            <div className="order__table-wrap">
                <table className="order__table">
                    <thead>
                        <tr>
                            <th>{props.t("templeRegister.payment.method")}</th>
                            <th>{props.t("templeRegister.payment.id")}</th>
                            <th>{props.t("templeRegister.payment.name")}</th>
                            <th>{props.t("templeRegister.payment.currency")}</th>
                            <th>{" "}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>{props.t(`paymentMethod.${props.account.paymentMethod}`)}</td>
                            <td>{props.account.accountId}</td>
                            <td>{props.account.recipientName}</td>
                            <td>{props.t(`currency.${props.account.currency}`)}</td>
                        </tr>
                    </tbody>
                </table>

                {!props.isEditing && (
                    <div className="order__table-buttons">
                        <div className="order__table-filter">
                            <button
                                className="order__table-up order__table-button"
                                onClick={() => updateAccountOrder(true)}
                                type="button"
                            >
                                <UpSVG />
                            </button>

                            <button
                                className="order__table-down order__table-button"
                                onClick={() => updateAccountOrder(false)}
                                type="button"
                            >
                                <DownSVG />
                            </button>
                        </div>

                        <div className="order__info-edit">
                            <button
                                onClick={() => props.setRemovableModel(props.account)}
                                type="button"
                            >
                                <WhiteRemoveSVG />
                            </button>

                            <button onClick={() => editHandler()} type="button">
                                <WhiteEditButtonSVG />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(PaymentDetailInfo);

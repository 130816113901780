import { SearchEntity, SearchField } from "../domain/models/SearchData";
import { SearchEntityDTO } from "../domain/models/dto/SearchData";
import { Entity } from "../enums/change-request-entity-enums/entity-enum";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";

export class SearchEntityModel extends BaseRelatedEntityMixinFactory<SearchEntityDTO>() implements SearchEntity {
    entityType: Entity;
    entityName?: string;
    entityId: string;
    entityDetails: SearchField[];

    constructor(data?: SearchEntity) {
        super();

        this.assignData(data);
    }
}

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Document, Page, View, Text, StyleSheet, Font } from "@react-pdf/renderer";
import { Translate } from "../../../../../types/Translate";
import { Payment } from "../../../../../domain/models/Payment";
import RobotoRegular from "../../../../../fonts/Roboto-Regular.ttf";
import _ from "lodash";
import { getCurrentTransactionTypeTranslation } from "../../../../../enums/transaction-type-enum";
import { truncateText } from "../../../../../tools/Tools";

interface PayoutDetailsPaymentsPDFProps {
    t: Translate;
    payments: Payment[];
    isAdmin: boolean;
}

const PayoutDetailsPaymentsPDF: FC<PayoutDetailsPaymentsPDFProps> = (props) => {
    Font.register({
        family: "Roboto",
        src: RobotoRegular,
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: "row",
            marginTop: 5,
            marginLeft: 10,
            flexGrow: 0,
        },
        table: {
            flexDirection: "column",
        },
        row: {
            flexDirection: "row",
            marginBottom: 5,
        },
        label: {
            width: "20%",
            marginRight: 10,
            fontFamily: "Roboto",
            fontSize: 8,
            marginBottom: 5,
            paddingLeft: "1%",
        },
        labelAmount: {
            width: "10%",
            marginRight: 10,
            fontFamily: "Roboto",
            fontSize: 8,
            marginBottom: 5,
            paddingLeft: "1%",
        },
        data: {
            width: "20%",
            fontFamily: "Roboto",
            fontSize: 8,
            marginBottom: 5,
            overflow: "hidden",
            paddingLeft: "1%",
            textOverflow: "ellipsis",
        },
        dataAmount: {
            width: "10%",
            fontFamily: "Roboto",
            fontSize: 8,
            marginBottom: 5,
            paddingLeft: "1%",
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.table}>
                    <View style={styles.row}>
                        <Text style={styles.label}>{props.t("templeAdmin.payments.table.labels.senderName")}</Text>
                        <Text style={styles.label}>
                            {`${props.t("templeAdmin.payments.table.labels.id")}
                                ${props.t("templeAdmin.payments.table.labels.payment")}`}
                        </Text>
                        <Text style={styles.label}>
                            {props.t("templeAdmin.payments.table.labels.name") +
                                props.t("templeAdmin.payments.table.labels.recipient")}
                        </Text>
                        <Text style={styles.label}>{props.t("templeAdmin.payments.table.labels.status")}</Text>
                        <Text style={styles.label}>{props.t("templeAdmin.payments.table.labels.paymentDate")}</Text>
                        {props.isAdmin ? (
                            <Text style={styles.labelAmount}>
                                {props.t("templeAdmin.payments.table.labels.amount")}
                            </Text>
                        ) : (
                            <Text></Text>
                        )}
                        <Text style={styles.labelAmount}>{props.t("templeAdmin.payments.table.labels.temple")}</Text>
                        {props.isAdmin ? (
                            <Text style={styles.labelAmount}>{props.t("templeAdmin.payments.table.labels.site")}</Text>
                        ) : (
                            <Text></Text>
                        )}
                        <Text style={styles.label}>
                            {props.t("templeAdmin.payments.table.labels.type") +
                                props.t("templeAdmin.payments.table.labels.transaction")}
                        </Text>
                    </View>
                    {props.payments?.map((payment, index) => (
                        <View style={styles.row} key={`temple_admin_payout_payment_${index}`}>
                            <Text style={styles.data}>{truncateText(payment.senderName, 20)}</Text>
                            <Text style={styles.data}>{truncateText(payment.id, 20)}</Text>
                            <Text style={styles.data}>{truncateText(payment.temple.name, 20)}</Text>
                            <Text style={styles.data}>{payment.statusDateStr}</Text>
                            <Text style={styles.data}>{payment.paymentDateStr}</Text>
                            {props.isAdmin ? (
                                <Text style={styles.dataAmount}>{payment.amountFloatFormat}</Text>
                            ) : (
                                <Text></Text>
                            )}
                            <Text style={styles.dataAmount}>{payment.recipientAmountFloatFormat}</Text>
                            {props.isAdmin ? (
                                <Text style={styles.dataAmount}>{payment.platformAmountFloatFormat}</Text>
                            ) : (
                                <Text></Text>
                            )}
                            <Text style={styles.data}>
                                {getCurrentTransactionTypeTranslation(props.t, payment.transactionType).label}
                            </Text>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
};

export default withTranslation()(PayoutDetailsPaymentsPDF);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../types/Translate";
import ModalWindow from "./ModalWindow";

interface SuccessPaymentModalProps {
    t: Translate;

    title: string;
    redirectToMainPage: () => void;
}

const SuccessPaymentModal: FC<SuccessPaymentModalProps> = (props) => {
    return (
        <ModalWindow
            closeCallback={props.redirectToMainPage}
            bodyClass="success__payment__modal"
            content={
                <>
                    <div className="modal__title">{props.title}</div>
                    <div className="modal__buttons">
                        <button className="modal__button modal__button-remove modal__button-remove__resolve" onClick={props.redirectToMainPage}>
                            {props.t("base.successPaymentModal.moveToTemple")}
                        </button>
                    </div>
                </>
            }
        />
    );
};

export default withTranslation()(SuccessPaymentModal);

import _ from "lodash";
import { Mapper } from "../domain/mappers/mapper";
import { ReligionCurrentDTO, ReligionDTO } from "../domain/models/dto/ReligionsDTO";
import { Religion, ReligionCurrent } from "../domain/models/Religions";
import religionCurrentMapperFactory from "./ReligionCurrentMapper";

class ReligionMapper implements Mapper<Religion, ReligionDTO> {
    currentMapper: Mapper<ReligionCurrent, ReligionCurrentDTO>;

    constructor() {
        this.currentMapper = religionCurrentMapperFactory()
    }

    toDTO(entity: Religion): ReligionDTO {
        return {
            id: parseInt(entity.value),
            label: entity.label,
            religionCurrents: !_.isEmpty(entity.religionCurrents)
                ? entity.religionCurrents.map(current => this.currentMapper.toDTO(current))
                : []
        }
    }

    fromDTO(data: ReligionDTO): Religion {
        return {
            value: data.id.toString(),
            label: data.label,
            religionCurrents: !_.isEmpty(data.religionCurrents)
                ? data.religionCurrents!.map(current => this.currentMapper.fromDTO(current))
                : []
        }
    }
}

export default function religionMapperFactory(): Mapper<Religion, ReligionDTO> {
    return new ReligionMapper()
}

import moment from "moment";
import { INameFormattingService } from "../domain/services/NameFormattingService";

export class NameFormattingServiceImpl implements INameFormattingService {
    // 1) accepted - the name of the city or country
    // 2) the function must remove spaces at the beginning of the word, at the end
    // 3) if there are several words without a hyphen between them - leave one space between utilities
    // 4) if there is a hyphen between the phrase - you need to leave only it, without spaces
    // 5) you need to see in lower case, leaving only the first letters of words in upper case
    // 6) must be leafs one space between words

    // examples of normal names: "Kyiv", "Kamenets-Podolsky", "Krivoy Rog".
    static normalizeName(name: string): string {
        if (!name) return "";

        return name
            .trim()
            .replace(/[^A-Za-zА-Яа-я'`ІіЇї\- ]/g, "")
            .replace(/^[`'\s-]+|[`'\s-]+$/g, "") // Remove spaces & dashes & special symbols at the beginning and end of a line
            .replace(/(\s*-+\s*)+/g, '-') // Replace sequences of dashes and spaces with a single dash
            .replace(/\s+/g, ' ') // Replacing sequences of spaces with a single space
            .replace(/([\s-]*[`']+[\s-]*)+/g, "'") // Replacing sequences of special symbols with a single symbol
            .toLowerCase()
            .replace(/(^|[-\s])(\S)/g, (_match, group1, group2) => group1 + group2.toUpperCase()); // Capitalize the first letter of each word
    }

    static getFileName(name: string, prefix?: string): string {
        if (!name) return "";

        const date = moment();
        return (prefix ? prefix : "") + name
        .toLowerCase()
        .replace(/\s+/g, '_')
        .concat(`_${date.year()}_${date.month() + 1}_${date.date()}_${date.hours()}_${date.minutes()}`)
    }
}

export function nameFormattingServiceFactory(): INameFormattingService {
    return new NameFormattingServiceImpl();
}

export enum TempleRegisterPage {
    MAIN_INFO = "main-info",
    ABBOT = "abbot",
    SCHEDULES = "schedules",
    VISITORS_INFO = "visitors-info",
    ORDERS = "orders",
    PAYMENT_DETAILS = "payment-details",
    BANK_DETAILS = "bank-details",
    GALLERIES = "galleries",
    CORRESPONDENCE = "correspondence"
};

import { FileDTO } from "../domain/models/dto/FileDTO";
import { CustomFile } from "../domain/models/CustomFile";
import { FileService } from "../domain/services/FileService";
import fileMapperFactory from "../mappers/FileMapper";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { RemoveResponse } from "../types/RemoveResponse";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";

class FileServiceImpl extends BaseServiceImpl implements FileService {
    // private baseUrl = `${apiURL}/api/v1/file`;
    // private baseFilesUrl = `${apiURL}/api/v1/files`;

    async createRequestOrUpdateCurrentEntity(dto: FileDTO): Promise<CustomFile> {
        if (
            (!dto.fileType && !dto.id) ||
            !dto.ownerId ||
            !dto.ownerType
        ) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<FileDTO, CustomFile>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.FILE), fileMapperFactory
        );
    }

    async removeEntity(entity: CustomFile): Promise<RemoveResponse> {
        return await this.removeEntityById<FileDTO, CustomFile>(
            entity, this.getV2EntityUrl(EntitiesBaseUrls.FILE), fileMapperFactory
        );
    }

    // async removeById(id: string): Promise<RemoveResponse> {
    //     return this.getData<RemoveResponse>(
    //         await deleteRequest(`${this.baseUrl}/${id}?removePermanently=true`)
    //     );
    // }

    // async uploadFiles(files: CustomFile[]): Promise<CustomFile[]> {
    //     if (!files) {
    //         return [];
    //     }

    //     const mapper = fileMapperFactory();

    //     return this.getData<FileDTO[]>(
    //         await post(this.baseFilesUrl, {
    //             files: files.map((file) => mapper.toDTO(file)),
    //         })
    //     ).map((file) => mapper.fromDTO(file));
    // }

    // async changeFile(file: CustomFile): Promise<CustomFile> {
    //     try {
    //         const mapper = fileMapperFactory();
    //         const dto = mapper.toChangesDTO(file);

    //         return mapper.fromDTO(
    //             this.getData<FileDTO>(
    //                 await post(this.baseUrl.concat("/changes"), dto)
    //             )
    //         );
    //     } catch (err) {
    //         console.log("FileServiceImpl.changeFile => ERROR:");
    //         console.log(err);
    //     }
    // }
}

export default function fileServiceFactory(): FileService {
    return new FileServiceImpl();
}

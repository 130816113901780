import moment from "moment";
import { EntityChart } from "../../../types/charts/EntityChart";
import { useEntityChart } from "./useEntityChart";
import { useEffect, useMemo } from "react";
import _ from "lodash";
import { ChartsParams } from "../../../types/charts/ChartsParams";
import chartServiceFactory from "../../../services/ChartServiceImpl";

interface EntityChartWithPrevious {
    currentPeriodChart: EntityChart;
    prevPeriodChart: EntityChart;
}

export const useEntityChartWithPrevious = (visibleChart: boolean, getChartsDataCallback: (chartsParams: ChartsParams) => Promise<number[]>): EntityChartWithPrevious => {
    const currentChartsParams = useMemo(() => {
        const momentDate = moment();
        return {
            year: momentDate.year(),
            month: momentDate.month(),
            day: null,
        }
    }, []);
    const currentPeriodChart = useEntityChart(visibleChart, currentChartsParams, getChartsDataCallback);

    const prevChartsParams = useMemo(() => {
        const paramsInfo = chartServiceFactory().analyzeChartsParams(currentPeriodChart.chartsParams);

        let prevDate = moment({
            year: currentPeriodChart.chartsParams.year,
            month: currentPeriodChart.chartsParams.month,
            date: currentPeriodChart.chartsParams.day,
        }).subtract(
            1, paramsInfo.onlyYear ? "year" : paramsInfo.onlyYearAndMonth ? "month" : paramsInfo.yearAndMonthAndDay ? "day" : "hour"
        );

        return {
            year: prevDate.year(),
            month: !_.isNil(currentPeriodChart.chartsParams.month) ? prevDate.month() : null,
            day: currentPeriodChart.chartsParams.day ? prevDate.date() : null,
        }
    }, [currentPeriodChart.chartsParams]);
    const prevPeriodChart = useEntityChart(visibleChart, prevChartsParams, getChartsDataCallback, true);

    useEffect(() => {
        prevPeriodChart.yearHandler(prevChartsParams.year);
        prevPeriodChart.monthHandler(prevChartsParams.month);
        prevPeriodChart.dayHandler(prevChartsParams.day);
    }, [prevChartsParams]);

    return { currentPeriodChart, prevPeriodChart }
}

import _ from "lodash";
import { ChangeRequestEntity } from "../domain/models/ChangeRequestEntity";
import { ChangeRequestEntityDTO } from "../domain/models/dto/ChangeRequestEntityDTO";
import { EntityChange } from "../domain/models/EntityChange";
import { Entity } from "../enums/change-request-entity-enums/entity-enum";
import { TempleSection } from "../enums/change-request-entity-enums/temple-section-enum";
import { RecordStatus } from "../enums/record-status";


class ChangeRequestEntityModel implements ChangeRequestEntity {
    private static requiredTempleFields = [
        "name",
        "religionId",
        "country",
        "city",
        "info",
        "abbotInfo",
        "shopInfo",
        "contactCountry",
        "contactCity",
        "contactStreet",
        "contactBuildingNumber",
        "contactPhone",
        "contactEmail"
    ];

    private static supportInfoFields = {
        templeSupportInfo: "templeSupportInfo",
        useStandardTempleSupportInfo: "useStandardTempleSupportInfo"
    };

    private static prayerInfoFields = {
        prayersInfo: "prayersInfo",
        useStandardPrayersInfo: "useStandardPrayersInfo"
    };

    private static supportEmailFields = {
        templeSupportEmail: "templeSupportEmail",
        useStandardTempleSupportEmail: "useStandardTempleSupportEmail"
    };

    private static prayerEmailFields = {
        prayersEmail: "prayersEmail",
        useStandardPrayersEmail: "useStandardPrayersEmail"
    };

    entity: Entity;
    status: RecordStatus;
    id: string;
    changes?: EntityChange[] = [];
    section?: TempleSection;
    isDeleted: boolean;

    constructor(data: ChangeRequestEntityDTO, section?: TempleSection) {
        Object.assign(this, data || {});
        this.section = section;
    }

    get isTemple() {
        return this.entity === Entity.TEMPLE;
    }

    get isApproved() {
        return this.status === RecordStatus.APPROVED;
    }

    get isDeclined() {
        return this.status === RecordStatus.DECLINED;
    }

    get isProcessing() {
        return !this.isApproved && !this.isDeclined;
    }

    get isRequiredDataExist(): boolean {
        if (!this.isTemple) {
            return true;
        }

        const fields = this.changes.flatMap(change => (change.isGeneralField && !change.originalValue) ? change.fieldName : []);

        if (_.isEmpty(fields)) {
            return true;
        }

        return (
            ChangeRequestEntityModel.requiredTempleFields.every(field => fields.includes(field)) &&
            (
                fields.includes(ChangeRequestEntityModel.supportInfoFields.templeSupportInfo) ||
                fields.includes(ChangeRequestEntityModel.supportInfoFields.useStandardTempleSupportInfo)
            ) &&
            (
                fields.includes(ChangeRequestEntityModel.prayerInfoFields.prayersInfo) ||
                fields.includes(ChangeRequestEntityModel.prayerInfoFields.useStandardPrayersInfo)
            ) &&
            (
                fields.includes(ChangeRequestEntityModel.supportEmailFields.templeSupportEmail) ||
                fields.includes(ChangeRequestEntityModel.supportEmailFields.useStandardTempleSupportEmail)
            ) &&
            (
                fields.includes(ChangeRequestEntityModel.prayerEmailFields.prayersEmail) ||
                fields.includes(ChangeRequestEntityModel.prayerEmailFields.useStandardPrayersEmail)
            )
        );
    }
}


export default ChangeRequestEntityModel;

import { TempleStatistic } from "../domain/models/TempleStatistic";
import { TempleStatisticDTO } from "../domain/models/dto/TempleStatisticDTO";
import { PaymentMethod } from "../enums/payment-method-enum";
import { floatToStr } from "../tools/Tools";

class TempleStatisticModel implements TempleStatistic {
    id?: string;
    templeName: string;
    income: number;

    templeId: string;

    totalTransactionAmount: number;
    totalTransactionPrayersAmount: number;
    totalTransactionDonationsAmount: number;

    totalPlatformTransactionAmount: number;
    totalPlatformTransactionPrayersAmount: number;
    totalPlatformTransactionDonationsAmount: number;

    totalTempleTransactionAmount: number;
    totalTempleTransactionPrayersAmount: number;
    totalTempleTransactionDonationsAmount: number;
    totalTransactionCount: number;
    totalTransactionPrayersCount: number;
    totalTransactionDonationsCount: number;

    inReviewTempleTransactionAmount: number;
    inReviewTempleTransactionPrayersAmount: number;
    inReviewTempleTransactionDonationsAmount: number;
    inReviewTransactionCount: number;
    inReviewTransactionPrayersCount: number;
    inReviewTransactionDonationsCount: number;

    finalTempleTransactionAmount: number;
    finalTempleTransactionPrayersAmount: number;
    finalTempleTransactionDonationsAmount: number;
    finalTransactionCount: number;
    finalTransactionPrayersCount: number;
    finalTransactionDonationsCount: number;

    templePaidAmount: number;
    paidAmount: number;
    paidCount: number;

    defaultPaymentMethod: PaymentMethod;
    dateOfApprovalLatestPayout?: Date;
    activePayouts: number;

    createdAt?: Date;
    updatedAt?: Date;

    incomeFloatStr: string;
    totalTransactionAmountFloatStr: string;
    totalTransactionPrayersAmountFloatStr: string;
    totalTransactionDonationsAmountFloatStr: string;
    totalPlatformTransactionAmountFloatStr: string;
    totalPlatformTransactionPrayersAmountFloatStr: string;
    totalPlatformTransactionDonationsAmountFloatStr: string;
    totalTempleTransactionAmountFloatStr: string;
    totalTempleTransactionPrayersAmountFloatStr: string;
    totalTempleTransactionDonationsAmountFloatStr: string;
    inReviewTempleTransactionAmountFloatStr: string;
    inReviewTempleTransactionPrayersAmountFloatStr: string;
    inReviewTempleTransactionDonationsAmountFloatStr: string;
    finalTempleTransactionAmountFloatStr: string;
    finalTempleTransactionPrayersAmountFloatStr: string;
    finalTempleTransactionDonationsAmountFloatStr: string;
    templePaidAmountFloatStr: string;
    paidAmountFloatStr: string;

    constructor(data: TempleStatisticDTO) {
        Object.assign(this, data || {});
        this.incomeFloatStr = floatToStr(this.income);
        this.totalTransactionAmountFloatStr = floatToStr(this.totalTransactionAmount);
        this.totalTransactionPrayersAmountFloatStr = floatToStr(this.totalTransactionPrayersAmount);
        this.totalTransactionDonationsAmountFloatStr = floatToStr(this.totalTransactionDonationsAmount);
        this.totalPlatformTransactionAmountFloatStr = floatToStr(this.totalPlatformTransactionAmount);
        this.totalPlatformTransactionPrayersAmountFloatStr = floatToStr(this.totalPlatformTransactionPrayersAmount);
        this.totalPlatformTransactionDonationsAmountFloatStr = floatToStr(this.totalPlatformTransactionDonationsAmount);
        this.totalTempleTransactionAmountFloatStr = floatToStr(this.totalTempleTransactionAmount);
        this.totalTempleTransactionPrayersAmountFloatStr = floatToStr(this.totalTempleTransactionPrayersAmount);
        this.totalTempleTransactionDonationsAmountFloatStr = floatToStr(this.totalTempleTransactionDonationsAmount);
        this.inReviewTempleTransactionAmountFloatStr = floatToStr(this.inReviewTempleTransactionAmount);
        this.inReviewTempleTransactionPrayersAmountFloatStr = floatToStr(this.inReviewTempleTransactionPrayersAmount);
        this.inReviewTempleTransactionDonationsAmountFloatStr = floatToStr(this.inReviewTempleTransactionDonationsAmount);
        this.finalTempleTransactionAmountFloatStr = floatToStr(this.finalTempleTransactionAmount);
        this.finalTempleTransactionPrayersAmountFloatStr = floatToStr(this.finalTempleTransactionPrayersAmount);
        this.finalTempleTransactionDonationsAmountFloatStr = floatToStr(this.finalTempleTransactionDonationsAmount);
        this.templePaidAmountFloatStr = floatToStr(this.templePaidAmount);
        this.paidAmountFloatStr = floatToStr(this.paidAmount);
    }
}

export default TempleStatisticModel;

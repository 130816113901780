import { FC } from "react";
import { ElementInfoModalSlideProps } from "../../../../../domain/components/slider-modal-window/ElementInfoModalSlideProps";
import { Artifact } from "../../../../../domain/models/Artifact";
import ArtifactModalLeft from "./ArtifactModalLeft";
import ArtifactModalRight from "./ArtifactModalRight";

const ArtifactInfoModalSlide: FC<ElementInfoModalSlideProps<Artifact>> = (props) => {
    return (
        <div className="swiper-slide">
            <h3 className="modal--main__title">{props.element.name}</h3>
            <div className="modal--main__inner">
                <ArtifactModalLeft artifact={props.element} />
                <ArtifactModalRight artifact={props.element} />
            </div>
        </div>
    );
};

export default ArtifactInfoModalSlide;

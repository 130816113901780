import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { Prayer } from "../../../../domain/models/Prayer";
import { PrayerPeriod } from "../../../../enums/prayer-period-enum";
import prayerServiceFactory from "../../../../services/PrayerServiceImpl";
import OrderPrayerAccordionItems from "./OrderPrayerAccordionItems";

interface OrderPrayerAccordionProps {
    t(key?: string): string;
    prayers: Prayer[];

    setSelectedPrayer(value: Prayer): void;
}

const OrderPrayerAccordion: FC<OrderPrayerAccordionProps> = (props) => {
    const [isPanelActive, setIsPanelActive] = useState<boolean>(false);
    const prayerService = prayerServiceFactory();

    const getStyle = (): string => {
        return isPanelActive ? " active-panel" : " hidden-panel";
    };

    const oneDayPrayers = prayerService.getSortedPrayersByPeriod(PrayerPeriod.ONE_DAY, props.prayers);
    const longPeriodPrayers = prayerService.getSortedPrayersByPeriod(PrayerPeriod.LONG_PERIOD, props.prayers);

    return (
        <div className="accordion-container">
            <div className={"ac" + (isPanelActive ? " is-active" : "")}>
                <h2 className="ac-header" onClick={() => setIsPanelActive(!isPanelActive)}>
                    <button className="ac-trigger">
                        {props.t("user.prayer.accordion.button")} <span className="circle"></span>
                    </button>
                </h2>
                <div className={"ac-panel" + getStyle()}>
                    <div className="order-payer__accordion-block">
                        <h4 className="order-payer__accordion-subtitle"> {props.t("user.prayer.accordion.subtitle1")} </h4>
                        <OrderPrayerAccordionItems  prayers={oneDayPrayers} setSelectedPrayer={props.setSelectedPrayer} />
                    </div>
                    <div className="order-payer__accordion-block">
                        <h4 className="order-payer__accordion-subtitle"> {props.t("user.prayer.accordion.subtitle2")} </h4>
                        <OrderPrayerAccordionItems  prayers={longPeriodPrayers} setSelectedPrayer={props.setSelectedPrayer} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(OrderPrayerAccordion);

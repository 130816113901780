import { Mapper } from "../domain/mappers/mapper";
import { Payout } from "../domain/models/Payout";
import { PayoutDTO } from "../domain/models/dto/PayoutDTO";
import PayoutModel from "../models/PayoutModel";
import { SimpleMapper } from "./SimpleMapper";

class PayoutMapper extends SimpleMapper<Payout, PayoutDTO> {
    protected entityConstructor = PayoutModel;

    protected toDTOFields: string[] = [];

    protected fromDTOFields: string[] = [
        "id",
        "templeName",

        "templeId",

        "transactionAmount",
        "transactionPrayersAmount",
        "transactionDonationsAmount",
        "transactionCount",
        "transactionPrayersCount",
        "transactionDonationsCount",

        "status",

        "createdByLastName",
        "resolvedByLastName",
        "createdByFirstName",
        "resolvedByFirstName",

        "paymentAccountRecipientName",
        "paymentAccountCurrency",
        "paymentAccountAccountId",

        "transactionId",
        "paymentMethod",
        "comment",

        "createdAt",
        "updatedAt",
        "resolvedAt",
    ];
}

export default function payoutMapperFactory(): Mapper<Payout, PayoutDTO> {
    return new PayoutMapper()
}

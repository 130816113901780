import { Mapper } from "../domain/mappers/mapper";
import { TempleExtensionDTO } from "../domain/models/dto/TempleExtensionDTO";
import { TempleExtension } from "../domain/models/TempleExtension";
import { TempleExtensionModel } from "../models/TempleExtensionModel";
import { SimpleMapper } from "./SimpleMapper";

class TempleExtensionMapper extends SimpleMapper<TempleExtension, TempleExtensionDTO> {
    protected entityConstructor = TempleExtensionModel;

    protected toDTOFields: string[] = [
        "id",
        "originTempleId",
        "correspondenceEmail",
        "correspondenceLanguage",
    ];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        "isCorrespondenceEmailActive",
        "createdAt",
        "updatedAt",
    ];
}


export default function templeExtensionMapperFactory(): Mapper<TempleExtension, TempleExtensionDTO> {
    return new TempleExtensionMapper()
}

import { useCallback, useState } from "react";

interface UseTableRowsActionsProps<T, ReturnObject> {
    actionCallback?: (entity: T) => Promise<ReturnObject>;
    isErrorCallback?: (entities: T[]) => boolean;
    addEntityToListCallback?: (entity: T) => void;
    removeEntityFromListCallback?: (entity: T) => void;
}

interface RowsEntityFields {
    id?: string;
}

export interface UseTableRowsActionsHandlers<T, ReturnObject> {
    selectAllRows: boolean;
    reloadCache: boolean;
    setReloadCache: (value: boolean) => void;
    updateSelectedRows: (entity: T) => void;
    addRows: (entities: T[]) => void;
    selectAllRowsHandler: (resetRows?: boolean) => void;
    sendData: () => Promise<ReturnObject[]>;
    getSelectedRowById: (entityId: string) => T;
    getSelectedRowsCount: () => number;
    isSelectedRowsError: () => boolean;
    getData: () => T[];
}

export function useTableRowsActions<Entity extends RowsEntityFields, ReturnObject>(
    props: UseTableRowsActionsProps<Entity, ReturnObject>
): UseTableRowsActionsHandlers<Entity, ReturnObject> {
    const [selectedRows, setSelectedRows] = useState<Entity[]>([]);
    const [selectAllRows, setSelectAllRows] = useState(false);
    const [reloadCache, setReloadCache] = useState<boolean>(false);

    const updateSelectedRows = useCallback(
        (newEntity: Entity) => {
            let isExist = false;
            const rowsWithoutExistingEntity = selectedRows.filter((entity) => {
                if (entity.id === newEntity.id) {
                    isExist = true;
                    return false;
                }
                return true;
            });

            if (isExist) {
                props.removeEntityFromListCallback && props.removeEntityFromListCallback(newEntity);
                return setSelectedRows(rowsWithoutExistingEntity);
            }
            props.addEntityToListCallback && props.addEntityToListCallback(newEntity);
            setSelectedRows((prevValue) => prevValue.concat(newEntity));
        },
        [selectedRows, props]
    );

    const getSelectedRowById = useCallback(
        (entityId: string) => {
            return selectedRows.find((entity) => entity.id === entityId);
        },
        [selectedRows]
    );

    const sendData = useCallback(async () => {
        const result = props.actionCallback
            ? await Promise.all(selectedRows.map((entity) => props.actionCallback(entity)))
            : [];
        if (!result) {
            return null;
        }

        setSelectedRows([]);
        setReloadCache(true);
        return result;
    }, [selectedRows, props]);

    const getSelectedRowsCount = useCallback(() => {
        return selectedRows.length;
    }, [selectedRows]);

    const addRows = useCallback((entities: Entity[]) => {
        setSelectedRows(entities);
    }, []);

    const selectAllRowsHandler = useCallback((resetRows: boolean = true) => {
        setSelectAllRows((prevValue) => {
            if (prevValue && resetRows) {
                setSelectedRows([]);
            }
            return resetRows ? !prevValue : false;
        });
    }, []);

    const isSelectedRowsError = useCallback(() => {
        return props.isErrorCallback ? props.isErrorCallback(selectedRows) : true;
    }, [props, selectedRows]);

    const getData = useCallback(() => {
        return selectedRows;
    }, [selectedRows]);

    return {
        selectAllRows,
        reloadCache,
        setReloadCache,
        updateSelectedRows,
        addRows,
        selectAllRowsHandler,
        sendData,
        getSelectedRowById,
        getSelectedRowsCount,
        isSelectedRowsError,
        getData,
    };
}

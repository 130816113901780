import { FC } from "react";

const PayoutCreateSVG: FC = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="22.000000pt"
            height="22.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#f0c34e" stroke="none">
                <path
                    d="M1585 4791 c-496 -180 -913 -336 -928 -346 -15 -10 -40 -38 -54 -62
-40 -68 -35 -121 25 -283 l49 -135 707 -3 706 -2 1 222 c0 254 11 315 69 412
19 32 61 82 93 112 108 101 206 134 389 134 65 0 118 1 118 3 0 11 -50 148
-63 174 -32 61 -106 103 -182 103 -16 0 -435 -148 -930 -329z"
                />
                <path
                    d="M2505 4521 c-48 -22 -79 -54 -100 -103 -12 -30 -15 -80 -15 -248 l0
-210 875 0 875 0 0 210 c0 168 -3 218 -15 248 -21 49 -52 81 -100 103 -38 18
-81 19 -760 19 -679 0 -722 -1 -760 -19z"
                />
                <path
                    d="M283 3646 c-134 -42 -245 -164 -273 -299 -7 -36 -10 -503 -8 -1548
l3 -1495 37 -76 c45 -91 103 -147 196 -191 l67 -32 2031 0 2030 0 76 37 c91
45 147 103 191 196 l32 67 3 387 3 387 -423 3 c-411 4 -425 4 -493 27 -255 84
-443 279 -512 531 -27 101 -25 292 6 390 42 136 96 225 196 326 76 76 105 97
185 137 165 80 162 80 628 84 l412 5 0 362 c0 392 -4 434 -55 521 -36 60 -116
132 -183 163 l-57 27 -2025 2 c-1655 1 -2033 -1 -2067 -11z"
                />
                <path
                    d="M3859 2262 c-163 -43 -281 -162 -324 -329 -39 -149 1 -296 114 -416
54 -58 110 -93 192 -118 56 -18 100 -19 610 -19 605 0 596 -1 640 61 l24 34 0
352 c0 339 -1 352 -21 379 -11 15 -33 37 -48 48 -27 21 -39 21 -574 23 -491 2
-554 0 -613 -15z m164 -296 c67 -27 108 -105 92 -175 -10 -45 -63 -98 -108
-106 -59 -11 -107 3 -143 41 -54 57 -57 135 -7 195 46 55 105 71 166 45z"
                />
            </g>
        </svg>
    );
};

export default PayoutCreateSVG;

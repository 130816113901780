import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Prayer } from "../../../domain/models/Prayer";
import { PrayerRequirement } from "../../../domain/models/PrayerRequirement";
import { Temple } from "../../../domain/models/Temple";
import { Page } from "../../../enums/page-enum";
import { PublicParams } from "../../../enums/public-params-enum";
import prayerRequirementServiceFactory from "../../../services/PrayerRequirementServiceImpl";
import prayerServiceFactory from "../../../services/PrayerServiceImpl";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import OrderPrayerAccordion from "./order-prayer-accordion/OrderPrayerAccordion";
import OrderPrayerHeader from "./OrderPrayerHeader";
import OrderPrayerPayerWithIntegration from "./OrderPrayerPayerWithIntegration";

interface OrderPrayerPageProps {}

const OrderPrayerPage: FC<OrderPrayerPageProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation().pathname.split("/");
    const templeId = location[2];

    const [prayers, setPrayers] = useState<Prayer[]>(null);
    const [prayerRequirements, setPrayerRequirements] = useState<PrayerRequirement[]>(null);
    const [selectedPrayer, setSelectedPrayer] = useState<Prayer>(null);
    const [temple, setTemple] = useState<Temple>(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        const prayerService = prayerServiceFactory();
        const prayerRequirementService = prayerRequirementServiceFactory();
        const templeService = templeServiceFactory();

        const uploadData = async () => {
            const [prayers, requirements, temple] = await Promise.all([
                prayerService.getPrayersByTempleId(templeId, true),
                prayerRequirementService.getPrayerRequirementsByTempleId(templeId),
                templeService.getCachedTempleOrUploadById(templeId),
            ]);

            setPrayers(prayers);
            setPrayerRequirements(requirements);
            setTemple(temple);
        };

        if (!templeId) {
            navigate(`/choose-temple?${PublicParams.REDIRECT}=${Page.SUPPORT}`);
        } else {
            if (!prayers && !prayerRequirements) {
                uploadData();
            }
        }
    }, [prayers, prayerRequirements, templeId, props, navigate]);

    return (
        <section className="order-payer">
            <div className="container--main">
                {prayers && prayerRequirements && (
                    <div className="order-payer__content">
                        <OrderPrayerHeader
                            templeName={temple?.name}
                        />
                        <OrderPrayerPayerWithIntegration

                            prayers={prayers}
                            prayerRequirements={prayerRequirements}
                            selectedPrayer={selectedPrayer}
                            setSelectedPrayer={setSelectedPrayer}
                        />
                        <OrderPrayerAccordion

                            prayers={prayers}
                            setSelectedPrayer={setSelectedPrayer}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

export default OrderPrayerPage;

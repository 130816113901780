import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";

export enum PayoutStatus {
    IN_REVIEW = 1,
    EXECUTED = 2,
    CANCELED = 3,
}

export function getCurrentStatusTranslation(t: Translate, payoutStatus: PayoutStatus): SelectProps {
    return { value: payoutStatus?.toString(), label: t(payoutStatus && `payoutStatus.${payoutStatus}`) };
}

export function getStatusTranslation(t: Translate): SelectProps[] {
    return [
        { value: PayoutStatus.IN_REVIEW.toString(), label: t(`payoutStatus.${PayoutStatus.IN_REVIEW}`) },
        { value: PayoutStatus.EXECUTED.toString(), label: t(`payoutStatus.${PayoutStatus.EXECUTED}`) },
        { value: PayoutStatus.CANCELED.toString(), label: t(`payoutStatus.${PayoutStatus.CANCELED}`) },
    ];
}

export function getStatusForResolveTranslation(t: Translate): SelectProps[] {
    return [
        { value: PayoutStatus.EXECUTED.toString(), label: t(`payoutStatus.${PayoutStatus.EXECUTED}`) },
        { value: PayoutStatus.CANCELED.toString(), label: t(`payoutStatus.${PayoutStatus.CANCELED}`) },
    ];
}

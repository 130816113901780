import { AdminShortStatistic } from "../domain/models/Admin";
import { AdminShortStatisticDTO } from "../domain/models/dto/AdminDTO";
import { fixedToFloatAmountStr } from "../tools/Tools";
import { BaseModelImpl } from "./BaseModelImpl";

class AdminShortStatisticModel extends BaseModelImpl<AdminShortStatisticDTO> implements AdminShortStatistic {
    totalCount: number;
    inReviewCount: number;
    doneCount: number;
    totalAmount: number;
    inReviewAmount: number;
    doneAmount: number;

    totalPayoutCount: number;

    totalAmountFloatStr: string;
    doneAmountFloatStr: string;
    inReviewAmountFloatStr: string;

    constructor(data: AdminShortStatisticDTO) {
        super();
        this.assignData(data);
        this.totalAmountFloatStr = fixedToFloatAmountStr(this.totalAmount);
        this.doneAmountFloatStr = fixedToFloatAmountStr(this.doneAmount);
        this.inReviewAmountFloatStr = fixedToFloatAmountStr(this.inReviewAmount);
    }
}

export default AdminShortStatisticModel

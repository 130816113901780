import { FC, MutableRefObject, useState } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../../domain/models/Temple";
import { CreateTempleParams } from "../../../../enums/create-temple-params-enum";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import BankAccountModel from "../../../../models/BankAccount";
import BankAccount from "../../../../models/BankAccount";
import { useRedirectToPrevCreateTemplePage } from "../../../../tools/custom-hooks/useRedirect";
import { BaseEntityManipulationHooks } from "../../../../tools/custom-hooks/BaseEntityManipulationHooks";
import Footer from "../footer/Footer";
import BankDetailForm from "./BankDetailForm";
import BankDetails from "./BankDetails";
import { useFormikFormsContext } from "../../../../context/FormikFormsProvider";

interface MainBankDetailsComponentProps {
    t(key?: string): string;
    temple: Temple;
}

const MainBankDetailsComponent: FC<MainBankDetailsComponentProps> = (props) => {
    const handlers = BaseEntityManipulationHooks<BankAccount>(
        BankAccountModel,
        props.temple.getSortedBankAccounts(SortingOrder.ASC),
        props.temple.setBankAccounts.bind(props.temple),
        props.temple.removeBankAccountByOrder.bind(props.temple),
    );

    useRedirectToPrevCreateTemplePage(props.temple, TempleRegisterPage.PAYMENT_DETAILS, props.temple.isPaymentsDetailsComplete);

    const [errorVisible, setErrorVisibility] = useState<string>(null);

    const [isAllowSetValues, setIsAllowSetValues] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);

    const [bankAccountFormRef, setBankAccountFormRef] = useState<MutableRefObject<any>>(null);

    const { isFormsDataValid } = useFormikFormsContext();

    return (
        <div className="date__content">
            <main className="date__main">
                <h1 className="date__title">{props.t("templeRegister.bank.title")}</h1>

                <BankDetails
                    temple={props.temple}
                    bankAccounts={handlers.entities}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isOrderChanged={isOrderChanged}
                    setIsOrderChanged={setIsOrderChanged}
                    setCurrentBankAccount={handlers.setCurrentEntity}
                    setIsAllowSetValues={setIsAllowSetValues}
                    updateBankAccounts={handlers.updateEntities}
                    bankAccountFormRef={bankAccountFormRef}
                    removeBankAccount={handlers.removeOrderedEntity}
                />

                <BankDetailForm
                    bankAccount={handlers.currentEntity}
                    temple={props.temple}
                    updateBankAccounts={handlers.updateEntities}
                    resetBankAccountForm={handlers.resetFormData}

                    setIsEditing={setIsEditing}

                    isAllowSetValues={isAllowSetValues}
                    setIsAllowSetValues={setIsAllowSetValues}

                    isSubmitting={isSubmitting}
                    setIsSubmitting={setIsSubmitting}

                    errorVisible={errorVisible}
                    setErrorVisibility={setErrorVisibility}
                    setBankAccountFormRef={setBankAccountFormRef}
                />
            </main>
            <Footer
                isButton={true}
                redirectTo={"/create-temple/galleries".concat(`?${CreateTempleParams.ID}=${props.temple.originId}`)}
                currentPath={TempleRegisterPage.BANK_DETAILS}
                temple={props.temple}
                setErrorVisibility={setErrorVisibility}
                isFormsDataValid={isFormsDataValid}
            />
        </div>
    );
};

export default withTranslation()(MainBankDetailsComponent);

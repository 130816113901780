import { apiURL } from "../Config";
import { DonateDTO, OrderDTO } from "../domain/models/dto/PaymentDTO";
import { BasePayPalDTO, PayPalPaymentDTO } from "../domain/models/dto/PayPalPaymentDTO";
import PayPalService from "../domain/services/PayPalService";
import { payPalPaymentMapperFactory } from "../mappers/PayPalPaymentMapper";
import { post } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";

export default class PayPalServiceImpl extends BaseServiceImpl implements PayPalService {
    async createPayment(total: number, currency: string): Promise<PayPalPaymentDTO> {
        if (!total || !currency) {
            return;
        }

        try {
            return payPalPaymentMapperFactory()
                .fromDTO(
                    this.getData<PayPalPaymentDTO>(
                        await post(`${apiURL}/api/v1/paypal/payment`, { total, currency })
                    )
                );
        } catch (err) {
            console.log("PayPalServiceImpl.createPayment => ERROR:");
            console.log(err);
            return null;
        }
    }

    async executeOrderPayment(payPalDTO: BasePayPalDTO, order: OrderDTO): Promise<any> {
        if (!payPalDTO?.paypalPaymentId || !payPalDTO?.paypalPayerId || !order) {
            return;
        }

        try {
            return payPalPaymentMapperFactory()
                .fromDTO(
                    super.getData(
                        await post(`${apiURL}/api/v1/paypal/payment/order/execute`, {
                            ...payPalDTO, ...order
                        })
                    )
                );
        } catch (err) {
            console.log("PayPalServiceImpl.executeOrderPayment => ERROR:");
            console.log(err);
            return null;
        }
    }

    async executeDonatePayment(payPalDTO: BasePayPalDTO, donate: DonateDTO): Promise<any> {
        if (!payPalDTO?.paypalPaymentId || !payPalDTO?.paypalPayerId || !donate) {
            return;
        }

        try {
            return payPalPaymentMapperFactory()
                .fromDTO(
                    super.getData(
                        await post(`${apiURL}/api/v1/paypal/payment/donate/execute`, {
                            ...payPalDTO, ...donate
                        })
                    )
                );
        } catch (err) {
            console.log("PayPalServiceImpl.executeDonatePayment => ERROR:");
            console.log(err);
            return null;
        }
    }
}

export function payPalServiceFactory(): PayPalService {
    return new PayPalServiceImpl();
}

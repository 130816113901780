import { useFormik } from "formik";
import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { BaseRegisterSchemaFactory } from "../../../validation-schemas/user-schemas";
import TogglePassword from "../../base/TogglePassword";
import { RegisterData } from "./Register";

interface LoginDetailsProps {
    t(key?: string): string;
    goToNextStep(value: boolean): void
    setRegisterData(data: RegisterData): void
    data: RegisterData
}


const LoginDetails: FC<LoginDetailsProps> = (props) => {
    const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
    const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
          email: props.data.email,
          password: props.data.password,
          confirmPassword: props.data.confirmPassword
        },
        validationSchema: BaseRegisterSchemaFactory({t: props.t}),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true)

            if (values && values.password === values.confirmPassword) {
                props.setRegisterData({
                    email: values.email,
                    password: values.password,
                    confirmPassword: values.confirmPassword
                })
                props.goToNextStep(true)
            }else {
                formik.setErrors({confirmPassword: props.t("errors.userLogin.passwordsNotMatch")})
            }
        },
      });

    return <form id="regForm" onSubmit={formik.handleSubmit}>
                <h1 className="form__title">{props.t("register.title")}</h1>

                <div className="form__wrap">
                    <input className={`form__input ${formik.touched.email && formik.values.email === "" && "invalid"}`}
                           type="input"
                           placeholder={props.t("login.email")}
                           name="email"
                           id="id_email"
                           autoComplete="email"
                           onChange={formik.handleChange}
                           defaultValue={formik.values.email}
                        />
                </div>
                <p style={{"marginTop": `${formik.touched.email && formik.errors.email ? "-25px" : "5px"}`}}>{formik.touched.email && formik.errors.email}</p>

                <div className="form__wrap">
                    <input className={`form__input form__password ${formik.touched.password && formik.values.password === "" && "invalid"}`}
                           id="id_password"
                           type={!isPasswordVisible ? "password" : "input"}
                           placeholder={props.t("login.password")}
                           name="password"
                           onChange={formik.handleChange}
                           defaultValue={formik.values.password}
                        />

                    <TogglePassword isPasswordVisible={isPasswordVisible} setPasswordVisible={setPasswordVisibility} />

                </div>
                <p style={{"marginTop": `${formik.touched.password && formik.errors.password ? "-25px" : "5px"}`}}>{formik.touched.password && formik.errors.password}</p>

                <div className="form__wrap">
                    <input className={`form__input form__password ${formik.touched.confirmPassword && formik.values.confirmPassword === "" && "invalid"}`}
                           id="id_repeat"
                           type={!isConfirmPasswordVisible ? "password" : "input"}
                           placeholder={props.t("register.confirmPassword")}
                           name="confirmPassword"
                           onChange={formik.handleChange}
                           defaultValue={formik.values.confirmPassword}
                        />
                    <TogglePassword isPasswordVisible={isConfirmPasswordVisible} setPasswordVisible={setConfirmPasswordVisible} />
                </div>
                <p style={{"marginTop": `${formik.touched.confirmPassword && formik.errors.confirmPassword ? "-25px" : "5px"}`}}>{formik.touched.confirmPassword && formik.errors.confirmPassword}</p>

                <div className="form__btns">
                    <button className="form__btn" type="submit" id="nextBtn">{props.t("register.next")}</button>
                </div>

            </form>
}

export default withTranslation()(LoginDetails);

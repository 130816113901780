import _ from "lodash";
import { EntityChangeDTO } from "../domain/models/dto/ChangeRequestEntityDTO";
import { FileDTO } from "../domain/models/dto/FileDTO";
import { EntityChange } from "../domain/models/EntityChange";
import {
    EntityFieldType,
    EnumFields,
    ReligionFields,
} from "../enums/change-request-entity-enums/entity-field-type-enum";
import { TempleSection } from "../enums/change-request-entity-enums/temple-section-enum";
import { FileFields } from "../enums/file-fields-enum";
import { supplementaryFields } from "../constants";

class EntityChangeModel implements EntityChange {
    protected isFile(object: any): object is FileDTO {
        if (!object) {
            return false;
        }

        return (
            FileFields.THUMBNAIL_PATH in object &&
            FileFields.FILE_PATH in object
        );
    }

    fieldName: string;
    originalValue: any;
    currentValue: any;
    type: EntityFieldType;
    section?: TempleSection;

    get isMainSection(): boolean {
        return this.section === TempleSection.MAIN;
    }

    get isAbbotSection(): boolean {
        return this.section === TempleSection.ABBOT;
    }

    get isVisitorsInfoSection(): boolean {
        return this.section === TempleSection.VISITORS_INFO;
    }

    get isOrdersSection(): boolean {
        return this.section === TempleSection.ORDERS;
    }

    get isAdditionalInfoSection(): boolean {
        return this.section === TempleSection.ADDITIONAL_INFO;
    }

    get isGeneralField(): boolean {
        return !supplementaryFields.includes(this.fieldName);
    }

    get isEnumField(): boolean {
        return Object.values(EnumFields).includes(this.fieldName as EnumFields);
    }

    get isReligionField(): boolean {
        return Object.values(ReligionFields).includes(this.fieldName as ReligionFields);
    }

    get isBooleanField(): boolean {
        return _.isBoolean(this.currentValue) || _.isBoolean(this.originalValue);
    }

    checkFieldName(fieldName: string): boolean {
        return this.fieldName === fieldName;
    }

    constructor(data: EntityChangeDTO) {
        Object.assign(this, data || {});
    }

    isOfType(entityType: TempleSection): boolean {
        return entityType === this.section;
    }

    get isSimpleValue(): boolean {
        return this.type === EntityFieldType.SIMPLE_VALUE;
    }

    get isArrayOfSimpleValues(): boolean {
        return this.type === EntityFieldType.ARRAY_OF_SIMPLE_VALUES;
    }

    get isArrayOfFiles(): boolean {
        return this.type === EntityFieldType.ARRAY_OF_FILES;
    }
}

export default EntityChangeModel;

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import ChartFilter from "../charts/ChartFilter";
import ModalButton from "../FilterModalButton";
import ListSVG from "../svg/ListSVG";
import Filter from "./PaymentFilter";
import { EntityChart } from "../../../types/charts/EntityChart";
import TransactionTypeDropDown from "../custom-drop-down/TransactionTypeDropDown";
import { TransactionType } from "../../../enums/transaction-type-enum";
import { TempleStatistic } from "../../../domain/models/TempleStatistic";

interface PaymentHeaderProps {
    t(key?: string): string;
    totalRows: number;
    templeStatistic: TempleStatistic;

    disableChart?: boolean;
    visibleChart: boolean;
    setVisibleChart: (value: boolean) => void;

    currentPeriodChart: EntityChart;
    prevPeriodChart: EntityChart;

    transactionType: TransactionType;
    transactionTypeHandler: (value: TransactionType) => void;
}

enum CurrentPath {
    PAYMENTS = "payments",
}

const PaymentHeader: FC<PaymentHeaderProps> = (props) => {
    const { user } = useAuth();

    const path = useLocation().pathname.split("/");
    const isSuperAdminPayments = path[2] === CurrentPath.PAYMENTS;

    return (
        <>
            {(user?.isTempleAdmin || (user.isAdmin && isSuperAdminPayments)) && (
                <h1 className="inner__title">{props.t("templeAdmin.payments.header.main.title")}</h1>
            )}
            <div className="inner__info">
                <div className="inner__info-left">
                        <div className="inner__info-number">
                            {props.t("templeAdmin.payments.header.main.totalCount")}
                            <span>{props.totalRows}</span>
                        </div>
                        {user.isAdmin && props.templeStatistic ? <>
                            <div className="inner__info-number">
                                {props.t("templeAdmin.payments.header.main.totalAmount")}
                                <span>{props.templeStatistic.totalTransactionAmountFloatStr}$</span>
                            </div>
                            <div className="inner__info-number">
                                {props.t("templeAdmin.payments.header.main.totalTempleAmount")}
                                <span>{props.templeStatistic.totalTempleTransactionAmountFloatStr}$</span>
                            </div>
                            <div className="inner__info-number">
                                {props.t("templeAdmin.payments.header.main.totalPlatformAmount")}
                                <span>{props.templeStatistic.totalPlatformTransactionAmountFloatStr}$</span>
                            </div>
                            <div className="inner__info-number">
                                {props.t("templeAdmin.payments.header.main.templePaidAmount")}
                                <span>{props.templeStatistic.templePaidAmountFloatStr}$</span>
                            </div>
                            <div className="inner__info-number">
                                {props.t("templeAdmin.payments.header.main.inReviewAmount")}
                                <span>{props.templeStatistic.inReviewTempleTransactionAmountFloatStr}$</span>
                            </div>
                            <div className="inner__info-number">
                                {props.t("templeAdmin.payments.header.main.income")}
                                <span>{props.templeStatistic.incomeFloatStr}$</span>
                            </div>
                        </> : <></>}

                    {props.visibleChart ? (
                        <div className="inner__info inner__info-date">
                            <TransactionTypeDropDown
                                transactionType={props.transactionType}
                                setTransactionType={props.transactionTypeHandler}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="inner__info-appearance">
                        <div
                            className={"inner__info-switch inner__info-list " + (!props.visibleChart ? "active" : "")}
                            onClick={() => props.setVisibleChart(!props.visibleChart)}
                        >
                            <ListSVG />
                        </div>

                        {!props.disableChart ? <div
                            className={"inner__info-switch inner__info-graph " + (props.visibleChart ? "active" : "")}
                            onClick={() => props.setVisibleChart(!props.visibleChart)}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20.8125 18.5625H4.6875V3.9375C4.6875 3.83437 4.60312 3.75 4.5 3.75H3.1875C3.08437 3.75 3 3.83437 3 3.9375V20.0625C3 20.1656 3.08437 20.25 3.1875 20.25H20.8125C20.9156 20.25 21 20.1656 21 20.0625V18.75C21 18.6469 20.9156 18.5625 20.8125 18.5625ZM6.375 17.0625H18.9375C19.0406 17.0625 19.125 16.9781 19.125 16.875V6.65625C19.125 6.4875 18.9211 6.40547 18.8039 6.52266L13.875 11.4516L10.9359 8.54531C10.9007 8.51042 10.8531 8.49084 10.8035 8.49084C10.7539 8.49084 10.7063 8.51042 10.6711 8.54531L6.24141 12.9891C6.2242 13.0063 6.21058 13.0268 6.20133 13.0493C6.19208 13.0718 6.18738 13.096 6.1875 13.1203V16.875C6.1875 16.9781 6.27187 17.0625 6.375 17.0625Z"
                                    fill="#323232"
                                />
                            </svg>
                        </div> : <></>}
                    </div>
                </div>
            </div>

            <div className="inner__tabs-content active">
                <div className="inner__info-right--top">
                    <button className="inner__info-reset" onClick={props.currentPeriodChart.resetChartsParams}>
                        {props.t("templeAdmin.payments.header.main.resetFilter")}
                    </button>

                    <ModalButton>
                        {!props.visibleChart ? (
                            <Filter />
                        ) : (
                            <ChartFilter
                                currentPeriodChart={props.currentPeriodChart}
                                prevPeriodChart={props.prevPeriodChart}
                            />
                        )}
                    </ModalButton>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(PaymentHeader);

import { FC, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { CustomFile } from "../../domain/models/CustomFile";
import { Owner } from "../../enums/parent-enum";
import { Purpose } from "../../enums/purpose-enum";
import AddImageButton from "./AddImageButton";
import RemovableImage from "./RemovableImage";

const DEFAULT_MAX_IMAGES_COUNT = 10;

interface IEditableImagesListProps {
    t(key?: string): string;
    files: CustomFile[];
    title?: string;
    disableTitle?: boolean;
    ownerType: Owner;
    purposeType?: Purpose;
    maxImagesCount?: number;

    useOriginalFilePath?: boolean;

    getMaxOrder(): number;
    getNewOrder(): number;
    setErrorVisibility: (isVisible: boolean) => void;
    setIsFilesUpdated(value: boolean): void;

    moveFileToLeft: (order: number) => void;
    moveFileToRight: (order: number) => void;
    removeFileByOrder: (order: number) => void;
}

const EditableImagesList: FC<IEditableImagesListProps> = (props: IEditableImagesListProps) => {
    const [updated, setUpdated] = useState<number>(0);
    const editableListRef = useRef<HTMLDivElement>(null);

    const addPhoto = (newPhoto: CustomFile) => {
        props.files.push(newPhoto);

        props.setIsFilesUpdated(true);
        setUpdated(updated + 1);

        editableListRef.current.scrollLeft = editableListRef.current.scrollWidth;
    };

    const removePhoto = (image: CustomFile) => {
        if (!image) {
            return;
        }
        props.moveFileToLeft(image.order + 1);

        image.markAsRemoved();
        props.removeFileByOrder(image.order);

        props.setIsFilesUpdated(true);
        setUpdated(updated + 1);
    };

    const moveFileTo = (toRight: boolean) => {
        return (value: number) => {
            if (toRight) {
                props.moveFileToRight(value);
            } else {
                props.moveFileToLeft(value);
            }

            props.setIsFilesUpdated(true);
        };
    };

    const activeFilesCount = props.files.filter((file) => !file.isDeleted).length;

    return (
        <div ref={editableListRef} className="date__form-block date__img-block">
            <h3 className="date__form-title">{props.t("templeRegister.base.addPhoto")}</h3>

            <div className="date__form-buttons">
                {props.files
                    .filter((file) => !file.isDeleted)
                    .map((file) => {
                        const isFirstImage = file.order === 1;
                        const isLastImage = file.order === props.getMaxOrder();

                        return (
                            <RemovableImage
                                key={file.id}
                                image={file}
                                removePhoto={removePhoto}
                                moveFileToLeft={moveFileTo(false)}
                                moveFileToRight={moveFileTo(true)}
                                isFirstImage={isFirstImage}
                                isLastImage={isLastImage}
                                useOriginalFilePath={props.useOriginalFilePath}
                            ></RemovableImage>
                        );
                    })}
                {(props.maxImagesCount || DEFAULT_MAX_IMAGES_COUNT) > activeFilesCount && (
                    <AddImageButton
                        key={-1}
                        ownerType={props.ownerType}
                        purposeType={props.purposeType}
                        order={props.getNewOrder()}

                        addImage={addPhoto}
                    />
                )}
            </div>

            {!props.disableTitle && (
                <span className="date__form-hint">{props.title || props.t("templeRegister.mainInfo.photoInfo")}</span>
            )}
        </div>
    );
};

export default withTranslation()(EditableImagesList);

import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface FAQProps {
    t(key?: string): string;
}

enum CurrentPanel {
    ABOUT = 1,
    GETTING_STARTED = 2,
    REGISTRATION = 3,
    SERVICES = 4,
    COOPERATION = 5,
}

const FAQPage: FC<FAQProps> = (props) => {
    const [currentPanel, setCurrentPanel] = useState<CurrentPanel>(null);

    const getActive = (panel: CurrentPanel): string => {
        return currentPanel === panel ? " is-active" : "";
    };

    const getPanel = (panel: CurrentPanel): CurrentPanel => {
        return currentPanel !== panel ? panel : null;
    };

    const getStyle = (panel: CurrentPanel): string => {
        return currentPanel === panel ? " active-panel" : " hidden-panel";
    };

    const navigate = useNavigate();

    return (
        <section id="section_4" className="information">
            <div className="container--min">
                <h2 className="information__title subtitle">{props.t("user.faq.title")}</h2>
                <div className="accordion-container">
                    <div className={"ac" + getActive(CurrentPanel.ABOUT)}>
                        <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.ABOUT))}>
                            <button className="ac-trigger">
                                {props.t("user.faq.about.aboutSubtitle")}
                                <span className="circle"></span>
                            </button>
                        </h2>
                        <div className={"ac-panel" + getStyle(CurrentPanel.ABOUT)}>
                            <div className="ac-panel-inner">
                                <ul className="faq__question-list">
                                    <li>
                                        <a href="#aboutQuestion1" className="faq__question">
                                            {props.t("user.faq.about.aboutQuestion1")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#aboutQuestion2" className="faq__question">
                                            {props.t("user.faq.about.aboutQuestion2")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#aboutQuestion3" className="faq__question">
                                            {props.t("user.faq.about.aboutQuestion3")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#aboutQuestion4" className="faq__question">
                                            {props.t("user.faq.about.aboutQuestion4")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#aboutQuestion5" className="faq__question">
                                            {props.t("user.faq.about.aboutQuestion5")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"ac" + getActive(CurrentPanel.GETTING_STARTED)}>
                        <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.GETTING_STARTED))}>
                            <button className="ac-trigger">
                                {props.t("user.faq.gettingStarted.gettingStartedSubtitle")}
                                <span className="circle"></span>
                            </button>
                        </h2>
                        <div className={"ac-panel" + getStyle(CurrentPanel.GETTING_STARTED)}>
                            <div className="ac-panel-inner">
                                <ul className="faq__question-list">
                                    <li>
                                        <a href="#gettingStartedQuestion1" className="faq__question">
                                            {props.t("user.faq.gettingStarted.gettingStartedQuestion1")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#gettingStartedQuestion2" className="faq__question">
                                            {props.t("user.faq.gettingStarted.gettingStartedQuestion2")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#gettingStartedQuestion3" className="faq__question">
                                            {props.t("user.faq.gettingStarted.gettingStartedQuestion3")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"ac" + getActive(CurrentPanel.REGISTRATION)}>
                        <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.REGISTRATION))}>
                            <button className="ac-trigger">
                                {props.t("user.faq.registration.registrationSubtitle")}
                                <span className="circle"></span>
                            </button>
                        </h2>
                        <div className={"ac-panel" + getStyle(CurrentPanel.REGISTRATION)}>
                            <div className="ac-panel-inner">
                                <ul className="faq__question-list">
                                    <li>
                                        <a href="#registrationQuestion1" className="faq__question">
                                            {props.t("user.faq.registration.registrationQuestion1")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#registrationQuestion2" className="faq__question">
                                            {props.t("user.faq.registration.registrationQuestion2")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#registrationQuestion3" className="faq__question">
                                            {props.t("user.faq.registration.registrationQuestion3")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#registrationQuestion4" className="faq__question">
                                            {props.t("user.faq.registration.registrationQuestion4")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#registrationQuestion5" className="faq__question">
                                            {props.t("user.faq.registration.registrationQuestion5")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#registrationQuestion6" className="faq__question">
                                            {props.t("user.faq.registration.registrationQuestion6")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#registrationQuestion7" className="faq__question">
                                            {props.t("user.faq.registration.registrationQuestion7")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#registrationQuestion8" className="faq__question">
                                            {props.t("user.faq.registration.registrationQuestion8")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"ac" + getActive(CurrentPanel.SERVICES)}>
                        <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.SERVICES))}>
                            <button className="ac-trigger">
                                {props.t("user.faq.services.servicesSubtitle")}
                                <span className="circle"></span>
                            </button>
                        </h2>
                        <div className={"ac-panel" + getStyle(CurrentPanel.SERVICES)}>
                            <div className="ac-panel-inner">
                                <ul className="faq__question-list">
                                    <li>
                                        <a href="#servicesQuestion1" className="faq__question">
                                            {props.t("user.faq.services.servicesQuestion1")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#servicesQuestion2" className="faq__question">
                                            {props.t("user.faq.services.servicesQuestion2")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#servicesQuestion3" className="faq__question">
                                            {props.t("user.faq.services.servicesQuestion3")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#servicesQuestion4" className="faq__question">
                                            {props.t("user.faq.services.servicesQuestion4")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#servicesQuestion5" className="faq__question">
                                            {props.t("user.faq.services.servicesQuestion5")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"ac" + getActive(CurrentPanel.COOPERATION)}>
                        <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.COOPERATION))}>
                            <button className="ac-trigger">
                                {props.t("user.faq.cooperation.cooperationSubtitle")}
                                <span className="circle"></span>
                            </button>
                        </h2>
                        <div className={"ac-panel" + getStyle(CurrentPanel.COOPERATION)}>
                            <div className="ac-panel-inner">
                                <ul className="faq__question-list">
                                    <li>
                                        <a href="#cooperationQuestion1" className="faq__question">
                                            {props.t("user.faq.cooperation.cooperationQuestion1")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#cooperationQuestion2" className="faq__question">
                                            {props.t("user.faq.cooperation.cooperationQuestion2")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#cooperationQuestion3" className="faq__question">
                                            {props.t("user.faq.cooperation.cooperationQuestion3")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="faq">
                <h2 className="information__title2 subtitle">{props.t("user.faq.title2")}</h2>
                <div className="faq__answers">
                    <hr className="faq__hr" />
                    <p className="faq__answers__subtitle-first">{props.t("user.faq.about.aboutSubtitle")}</p>
                    <span className="anchor" id="aboutQuestion1"></span>
                    <p className="faq__answers__question">{props.t("user.faq.about.aboutQuestion1")}</p>
                    <p className="faq__answers__answer">{props.t("user.faq.about.aboutAnswer1")}</p>
                    <span className="anchor" id="aboutQuestion2"></span>
                    <p className="faq__answers__question">{props.t("user.faq.about.aboutQuestion2")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">{props.t("user.faq.about.aboutAnswer2_1")}</p>
                        <ul className="faq__answers__answer__list">
                            <li className="faq__answers__answer__list-item">
                                {props.t("user.faq.about.aboutAnswer2_2")}
                            </li>
                            <li className="faq__answers__answer__list-item">
                                {props.t("user.faq.about.aboutAnswer2_3")}
                            </li>
                            <li className="faq__answers__answer__list-item">
                                {props.t("user.faq.about.aboutAnswer2_4")}
                            </li>
                            <li className="faq__answers__answer__list-item">
                                {props.t("user.faq.about.aboutAnswer2_5")}
                            </li>
                            <li className="faq__answers__answer__list-item">
                                {props.t("user.faq.about.aboutAnswer2_6")}
                            </li>
                        </ul>
                    </div>
                    <span className="anchor" id="aboutQuestion3"></span>
                    <p className="faq__answers__question">{props.t("user.faq.about.aboutQuestion3")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">{props.t("user.faq.about.aboutAnswer3_1")}</p>
                        <p className="faq__answers__answer__list-item">{props.t("user.faq.about.aboutAnswer3_2")}</p>
                        <p className="faq__answers__answer__list-item">{props.t("user.faq.about.aboutAnswer3_3")}</p>
                    </div>
                    <span className="anchor" id="aboutQuestion4"></span>
                    <p className="faq__answers__question">{props.t("user.faq.about.aboutQuestion4")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">{props.t("user.faq.about.aboutAnswer4_1")}</p>
                        <p className="faq__answers__answer__list-item">{props.t("user.faq.about.aboutAnswer4_2")}</p>
                    </div>
                    <span className="anchor" id="aboutQuestion5"></span>
                    <p className="faq__answers__question">{props.t("user.faq.about.aboutQuestion5")}</p>
                    <p className="faq__answers__answer">{props.t("user.faq.about.aboutAnswer5")}</p>
                    <p className="faq__answers__subtitle">
                        {props.t("user.faq.gettingStarted.gettingStartedSubtitle")}
                    </p>
                    <span className="anchor" id="gettingStartedQuestion1"></span>
                    <p className="faq__answers__question">
                        {props.t("user.faq.gettingStarted.gettingStartedQuestion1")}
                    </p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.gettingStarted.gettingStartedAnswer1_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.gettingStarted.gettingStartedAnswer1_2")}
                        </p>
                    </div>
                    <span className="anchor" id="gettingStartedQuestion2"></span>
                    <p className="faq__answers__question">
                        {props.t("user.faq.gettingStarted.gettingStartedQuestion2")}
                    </p>
                    <p className="faq__answers__answer">{props.t("user.faq.gettingStarted.gettingStartedAnswer2")}</p>
                    <span className="anchor" id="gettingStartedQuestion3"></span>
                    <p className="faq__answers__question">
                        {props.t("user.faq.gettingStarted.gettingStartedQuestion3")}
                    </p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.gettingStarted.gettingStartedAnswer3_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.gettingStarted.gettingStartedAnswer3_2")}
                        </p>
                    </div>
                    <p className="faq__answers__subtitle">{props.t("user.faq.registration.registrationSubtitle")}</p>
                    <span className="anchor" id="registrationQuestion1"></span>
                    <p className="faq__answers__question">{props.t("user.faq.registration.registrationQuestion1")}</p>
                    <p className="faq__answers__answer">{props.t("user.faq.registration.registrationAnswer1")}</p>
                    <span className="anchor" id="registrationQuestion2"></span>
                    <p className="faq__answers__question">{props.t("user.faq.registration.registrationQuestion2")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer2_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer2_2")}
                        </p>
                    </div>
                    <span className="anchor" id="registrationQuestion3"></span>
                    <p className="faq__answers__question">{props.t("user.faq.registration.registrationQuestion3")}</p>
                    <p className="faq__answers__answer">{props.t("user.faq.registration.registrationAnswer3")}</p>
                    <span className="anchor" id="registrationQuestion4"></span>
                    <p className="faq__answers__question">{props.t("user.faq.registration.registrationQuestion4")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer4_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer4_2")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer4_3")}
                        </p>
                    </div>
                    <span className="anchor" id="registrationQuestion5"></span>
                    <p className="faq__answers__question">{props.t("user.faq.registration.registrationQuestion5")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer5_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer5_2")}
                        </p>
                    </div>
                    <span className="anchor" id="registrationQuestion6"></span>
                    <p className="faq__answers__question">{props.t("user.faq.registration.registrationQuestion6")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer6_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer6_2")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.registration.registrationAnswer6_3")}
                        </p>
                    </div>
                    <span className="anchor" id="registrationQuestion7"></span>
                    <p className="faq__answers__question">{props.t("user.faq.registration.registrationQuestion7")}</p>
                    <p className="faq__answers__answer">{props.t("user.faq.registration.registrationAnswer7")}</p>
                    <span className="anchor" id="registrationQuestion8"></span>
                    <p className="faq__answers__question">{props.t("user.faq.registration.registrationQuestion8")}</p>
                    <p className="faq__answers__answer">{props.t("user.faq.registration.registrationAnswer8")}</p>
                    <p className="faq__answers__subtitle">{props.t("user.faq.services.servicesSubtitle")}</p>
                    <span className="anchor" id="servicesQuestion1"></span>
                    <p className="faq__answers__question">{props.t("user.faq.services.servicesQuestion1")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer1_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer1_2")}
                        </p>
                    </div>
                    <span className="anchor" id="servicesQuestion2"></span>
                    <p className="faq__answers__question">{props.t("user.faq.services.servicesQuestion2")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer2_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer2_2")}
                        </p>
                    </div>
                    <span className="anchor" id="servicesQuestion3"></span>
                    <p className="faq__answers__question">{props.t("user.faq.services.servicesQuestion3")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer3_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer3_2")}
                        </p>
                    </div>
                    <span className="anchor" id="servicesQuestion4"></span>
                    <p className="faq__answers__question">{props.t("user.faq.services.servicesQuestion4")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer4_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer4_2")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer4_3")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer4_4")}
                        </p>
                    </div>
                    <span className="anchor" id="servicesQuestion5"></span>
                    <p id="servicesQuestion5" className="faq__answers__question">
                        {props.t("user.faq.services.servicesQuestion5")}
                    </p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer5_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer5_2")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer5_3")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.services.servicesAnswer5_4")}
                        </p>
                    </div>
                    <p className="faq__answers__subtitle">{props.t("user.faq.cooperation.cooperationSubtitle")}</p>
                    <span className="anchor" id="cooperationQuestion1"></span>
                    <p className="faq__answers__question">{props.t("user.faq.cooperation.cooperationQuestion1")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer1_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer1_2")}
                        </p>
                    </div>
                    <span className="anchor" id="cooperationQuestion2"></span>
                    <p className="faq__answers__question">{props.t("user.faq.cooperation.cooperationQuestion2")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer2_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer2_2")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer2_3")}
                        </p>
                    </div>
                    <span className="anchor" id="cooperationQuestion3"></span>
                    <p className="faq__answers__question">{props.t("user.faq.cooperation.cooperationQuestion3")}</p>
                    <div className="faq__answers__answer">
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer3_1")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer3_2")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer3_3")}
                        </p>
                        <p className="faq__answers__answer__list-item">
                            {props.t("user.faq.cooperation.cooperationAnswer3_4")}
                        </p>
                    </div>
                </div>
            </div>
            <div className="cst__faq-support-panel">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                    <div style={{ margin: "0 20px 0", textAlign: "center" }}>
                        <span>{props.t("admin.customerSupport.faq.contactUsText")}</span>
                    </div>
                    <button
                        className="blue-button cst__faq-button-height"
                        onClick={() => {
                            navigate("/customer-support");
                            window.scrollTo(0, 0);
                        }}
                    >
                        {props.t("admin.customerSupport.createTicketForm.header")}
                    </button>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(FAQPage);

import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../../types/Translate";
import PayoutDetailsHeader from "./PayoutDetailsHeader";
import { PayoutDetailsPageType } from "../../../../enums/payout-details-page-enum";
import PayoutDetailsInformationTab from "./PayoutDetailsInformationTab";
import { Payout } from "../../../../domain/models/Payout";
import PayoutDetailsPayments from "./payout-details-payments/PayoutDetailsPaymentsTab";
import { payoutServiceFactory } from "../../../../services/PayoutServiceImpl";
import { useParams } from "react-router-dom";
import { Payment } from "../../../../domain/models/Payment";

interface PayoutDetailsProps {
    t: Translate;
}

const PayoutDetails: FC<PayoutDetailsProps> = () => {
    const [payments, setPayments] = useState<Payment[]>([]);
    const [payout, setPayout] = useState<Payout>(payoutServiceFactory().getEmptyPayoutModel());
    const [payoutDetailsPageType, setPayoutDetailsPageType] = useState<PayoutDetailsPageType>(
        PayoutDetailsPageType.INFORMATION
    );

    const [shouldBeUpdate, setShouldBeUpdate] = useState(false);

    const isInformation = payoutDetailsPageType === PayoutDetailsPageType.INFORMATION;
    const isPayments = payoutDetailsPageType === PayoutDetailsPageType.PAYMENTS;

    const { payoutId } = useParams();

    useEffect(() => {
        const uploadData = async () => {
            setPayout(await payoutServiceFactory().getPayoutById(payoutId));
        };

        if (!payout.id || shouldBeUpdate) {
            uploadData();
            setShouldBeUpdate(false);
        }
    }, [payoutId, payout, shouldBeUpdate]);

    return (
        <>
            <PayoutDetailsHeader
                setPayoutDetailsPageType={setPayoutDetailsPageType}
                isPayments={isPayments}
                isInformation={isInformation}
                payout={payout}
                reloadCache={() => setShouldBeUpdate(true)}
                payments={payments}
            />
            {isInformation ? (
                <PayoutDetailsInformationTab payout={payout} />
            ) : (
                <PayoutDetailsPayments setPaymentsCallback={setPayments} payoutId={payoutId} templeId={payout?.templeId} />
            )}
        </>
    );
};

export default withTranslation()(PayoutDetails);

import _ from "lodash";
import { TempleMapper } from "../domain/mappers/temple-mapper";
import { TempleDTO } from "../domain/models/dto/TempleDTO";
import { Temple } from "../domain/models/Temple";
import { TempleModel } from "../models/TempleModel";
import articleMapperFactory from "./ArticleMapper";
import artifactMapperFactory from "./ArtifactMapper";
import bankAccountMapperFactory from "./BankAccountMapper";
import fileMapperFactory from "./FileMapper";
import paymentAccountMapperFactory from "./PaymentAccountMapper";
import prayerMapperFactory from "./PrayerMapper";
import prayerRequirementMapperFactory from "./PrayerRequirementMapper";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";
import userMapperFactory from "./UserMapper";
import weekScheduleMapperFactory from "./WeekScheduleMapper";
import worshipScheduleMapperFactory from "./WorshipScheduleMapper";
import templeExtensionMapperFactory from "./TempleExtensionMapper";


class TempleMapperImpl extends SimpleChangesRequestEntityMapper<Temple, TempleDTO> {
    protected entityConstructor = TempleModel;

    protected toDTOFields: string[] = [
        'id',
        'name',
        'religionId',
        'religionCurrentId',
        'religionSubspeciesId',
        'country',
        'city',
        'info',

        'templeSupportInfo',
        'useStandardTempleSupportInfo',
        'prayersInfo',
        'useStandardPrayersInfo',
        'abbotInfo',
        'shopInfo',
        'worshipLink',
        'tourLink',

        'contactCountry',
        'contactCity',
        'contactStreet',
        'contactBuildingNumber',
        'contactPhone',
        'contactSecondPhone',
        'contactEmail',
        'contactInfo',
        'isDeleted',

        'prayersEmail',
        'useStandardPrayersEmail',
        'templeSupportEmail',
        'useStandardTempleSupportEmail',
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [...this.toDTOFields, ...this.toChangesDTOFields, 'status', 'income'];


    fromDTO(model: TempleDTO): Temple {
        const fileMapper = fileMapperFactory();
        const userMapper = userMapperFactory();
        const bankAccountMapper = bankAccountMapperFactory();
        const paymentAccountMapper = paymentAccountMapperFactory();
        const artifactMapper = artifactMapperFactory();
        const weekScheduleMapper = weekScheduleMapperFactory();
        const worshipScheduleMapper = worshipScheduleMapperFactory();
        const articleMapper = articleMapperFactory();
        const prayerRequirementMapper = prayerRequirementMapperFactory();
        const prayerMapper = prayerMapperFactory();
        const templeExtensionMapper = templeExtensionMapperFactory();

        if (!model) {
            return new TempleModel();
        }

        const temple = super.fromDTO(model);

        temple.files = !_.isEmpty(model.files) ? model.files.map(file => fileMapper.fromDTO(file)) : [];
        temple.abbotInfoFiles = !_.isEmpty(model.abbotInfoFiles) ? model.abbotInfoFiles.map(file => fileMapper.fromDTO(file)) : [];
        temple.shopInfoFiles = !_.isEmpty(model.shopInfoFiles) ? model.shopInfoFiles.map(file => fileMapper.fromDTO(file)) : [];
        temple.galleryFiles = !_.isEmpty(model.galleryFiles) ? model.galleryFiles.map(file => fileMapper.fromDTO(file)) : [];

        temple.artifacts = !_.isEmpty(model.artifacts) ? model.artifacts.map(artifact => artifactMapper.fromDTO(artifact)) : [];
        temple.articles = !_.isEmpty(model.articles) ? model.articles.map(article => articleMapper.fromDTO(article)) : [];
        temple.pilgrimsArticles = !_.isEmpty(model.pilgrimsArticles) ? model.pilgrimsArticles.map(article => articleMapper.fromDTO(article)) : [];
        temple.bankAccounts = !_.isEmpty(model.bankAccounts) ? model.bankAccounts.map(account => bankAccountMapper.fromDTO(account)) : [];
        temple.paymentAccounts = !_.isEmpty(model.paymentAccounts) ? model.paymentAccounts.map(account => paymentAccountMapper.fromDTO(account)) : [];
        temple.admin = userMapper.fromDTO(model.admin || {});
        temple.weekSchedule = weekScheduleMapper.fromDTO(model.weekSchedule);
        temple.worshipSchedules = !_.isEmpty(model.worshipSchedules) ? model.worshipSchedules.map(schedule => worshipScheduleMapper.fromDTO(schedule)) : [];
        temple.prayerRequirements = !_.isEmpty(model.prayerRequirements) ? model.prayerRequirements.map(requirement => prayerRequirementMapper.fromDTO(requirement)) : [];
        temple.prayers = !_.isEmpty(model.prayers) ? model.prayers.map(prayer => prayerMapper.fromDTO(prayer)) : [];
        temple.templeExtension = templeExtensionMapper.fromDTO(model.templeExtension);

        return temple;
    }

    toDTO(model: Temple): TempleDTO {
        if (!model) {
            return;
        }

        const temple = super.toDTO(model);

        temple.abbotInfo = model.abbotInfo;

        return _.omitBy(temple, _.isNil) as TempleDTO;
    }

    toMainInfoDTO(model: Temple): TempleDTO {
        if (!model) {
            return;
        }

        return {
            id: model.originId,
            name: model.name,
            religionId: model.religionId,
            religionCurrentId: model.religionCurrentId,
            religionSubspeciesId: model.religionSubspeciesId,
            country: model.country,
            city: model.city,
            info: model.info,
        } as TempleDTO;
    }

    toAbbotDTO(model: Temple): TempleDTO {
        if (!model) {
            return;
        }

        return {
            ...this.toMainInfoDTO(model),
            abbotInfo: model.abbotInfo,
        };
    }

    toVisitorsInfoDTO(model: Temple): TempleDTO {
        if (!model) {
            return;
        }

        return {
            ...this.toAbbotDTO(model),
            shopInfo: model.shopInfo,
        };
    }

    toOrdersDTO(model: Temple): TempleDTO {
        if (!model) {
            return;
        }

        return {
            ...this.toVisitorsInfoDTO(model),
            templeSupportInfo: model.templeSupportInfo || null,
            useStandardTempleSupportInfo: model.useStandardTempleSupportInfo,
            prayersInfo: model.prayersInfo || null,
            useStandardPrayersInfo: model.useStandardPrayersInfo,
            prayersEmail: model.prayersEmail || null,
            useStandardPrayersEmail: model.useStandardPrayersEmail,
            templeSupportEmail: model.templeSupportEmail || null,
            useStandardTempleSupportEmail: model.useStandardTempleSupportEmail,
        }
    }

    toGalleriesDTO(model: Temple): TempleDTO {
        if (!model) {
            return;
        }

        return {
            ...this.toOrdersDTO(model),
            worshipLink: model.worshipLink || null,
            tourLink: model.tourLink || null,
            contactCountry: model.contactCountry,
            contactCity: model.contactCity,
            contactStreet: model.contactStreet,
            contactBuildingNumber: model.contactBuildingNumber,
            contactPhone: model.contactPhone,
            contactSecondPhone: model.contactSecondPhone || null,
            contactEmail: model.contactEmail,
            contactInfo: model.contactInfo || null,
        }
    }

    toLatestTempleDTO(model: Temple): TempleDTO {
        return model.isGalleriesComplete
            ? this.toGalleriesDTO(model)
            : model.isOrdersComplete
            ? this.toOrdersDTO(model)
            : model.isVisitorsInfoComplete
            ? this.toVisitorsInfoDTO(model)
            : model.isAbbotComplete
            ? this.toAbbotDTO(model)
            : this.toMainInfoDTO(model);
    }
}

export default function templeMapperFactory(): TempleMapper<Temple, TempleDTO> {
    return new TempleMapperImpl()
}

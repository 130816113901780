import { FC, MutableRefObject, useState } from "react";
import { Prayer } from "../../../../../domain/models/Prayer";
import { PrayerPriceDetail } from "../../../../../domain/models/PrayerPriceDetail";
import { Temple } from "../../../../../domain/models/Temple";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import PrayerModel from "../../../../../models/PrayerModel";
import PrayerPriceDetailModel from "../../../../../models/PrayerPriceDetailModel";
import { BaseEntityManipulationHooks } from "../../../../../tools/custom-hooks/BaseEntityManipulationHooks";
import PrayerForm from "./PrayerForm";
import Prayers from "./Prayers";

interface MainPrayerComponentProps {
    temple: Temple;
    resetErrorCallback: () => void;
}

const MainPrayerComponent: FC<MainPrayerComponentProps> = (props) => {
    const [currentDetails, setCurrentDetails] = useState<PrayerPriceDetail[]>([new PrayerPriceDetailModel()]);
    const handlers = BaseEntityManipulationHooks<Prayer>(
        PrayerModel,
        props.temple.getSortedPrayers(SortingOrder.ASC),
        props.temple.setPrayers.bind(props.temple),
        props.temple.removePrayerByOrder.bind(props.temple),
    );

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isAllowSetValues, setIsAllowSetValues] = useState<boolean>(false);

    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);

    const [prayerFormRef, setPrayerFormRef] = useState<MutableRefObject<any>>(null);

    return (
        <>
            <Prayers

                temple={props.temple}
                prayers={handlers.entities}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isOrderChanged={isOrderChanged}
                setIsOrderChanged={setIsOrderChanged}
                setIsAllowSetValues={setIsAllowSetValues}
                setCurrentPrayer={handlers.setCurrentEntity}
                setCurrentDetails={setCurrentDetails}
                updatePrayers={handlers.updateEntities}
                prayerFormRef={prayerFormRef}
                removePrayer={handlers.removeOrderedEntity}
            />

            <PrayerForm
                prayer={handlers.currentEntity}
                details={currentDetails}
                temple={props.temple}
                setIsEditing={setIsEditing}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                isAllowSetValues={isAllowSetValues}
                setIsAllowSetValues={setIsAllowSetValues}
                updatePrayers={handlers.updateEntities}
                setCurrentPrayer={handlers.setCurrentEntity}
                setCurrentDetails={setCurrentDetails}
                setPrayerFormRef={setPrayerFormRef}
                resetPrayerForm={handlers.resetFormData}
                resetErrorCallback={props.resetErrorCallback}
            />
        </>
    );
};

export default MainPrayerComponent;

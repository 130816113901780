import { FC, MutableRefObject } from "react";
import { Artifact } from "../../../../../domain/models/Artifact";
import PhotoSlider from "../../../../base/photo-slider/PhotoSlider";
import InfoEditSVG from "../../../../base/svg/InfoEditSVG";
import InfoSlide from "../../../../base/InfoSlide";
import DarkRemoveSVG from "../../../../base/svg/DarkRemoveSVG";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";

interface ArtifactInfoProps {
    artifact: Artifact;
    setCurrentArtifact(value: Artifact): void;
    setRemovableModel(artifact: Artifact): void;
    artifactFormRef: MutableRefObject<any>;
    additionalWrapperClassName: string;
}

const ArtifactInfo: FC<ArtifactInfoProps> = (props) => {
    const isOnePhoto = props.artifact.files.length === 1;
    const minimumPhotosForLoop = 2;
    const editHandler = () => {
        props.setCurrentArtifact(props.artifact);
        props.artifactFormRef && props.artifactFormRef.current.scrollIntoView();
    };

    return (
        <div className={`establish__info ${props.additionalWrapperClassName}`}>
            <div className="establish__info-edit">
                <button type="button" onClick={() => props.setRemovableModel(props.artifact)}>
                    <DarkRemoveSVG />
                </button>
                <button type="button" onClick={editHandler}>
                    <InfoEditSVG />
                </button>
            </div>

            <div
                className={
                    "establish__info-images" +
                    (isOnePhoto ? " one__establish__photo__slider" : " many__establish__photo__slider")
                }
            >
                <PhotoSlider
                    slidesPerView={isOnePhoto ? 1 : 2}
                    spaceBetween={15}
                    isArrowsDisable={true}
                    allowTouchMove={true}
                    loop={props.artifact.files.length >= minimumPhotosForLoop}
                    content={props.artifact?.getSortedFiles(SortingOrder.ASC).map((file) => (
                        <InfoSlide filePath={file?.thumbnailOrOriginalPath} imageClass="establish__info-thumbnail" />
                    ))}
                />
            </div>

            <div className={"establish__info-text" + (isOnePhoto ? " one__establish__info__text" : "")}>
                <h4 className="establish__info-name">{props.artifact.name}</h4>

                <div className="establish__info-description">{props.artifact.info}</div>
            </div>
        </div>
    );
};

export default ArtifactInfo;

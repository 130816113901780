import { FC, useMemo } from "react";
import { Translate } from "../../../../types/Translate";
import { Trans, withTranslation } from "react-i18next";
import InputRestrictionsInfo from "../../../base/InputRestrictionsInfo";
import { getValidationClass } from "../../../../tools/Tools";
import { FormikProps } from "formik";
import { CorrespondenceForm as ICorrespondenceForm } from "../../../../domain/models/Temple";
import { CorrespondenceSchemaFactory } from "../../../../validation-schemas/temple-register/correspondence-schemas";
import ChangeEntityButtons from "../../../base/formik/ChangeEntityButtons";
import LanguageDropDown from "../../../base/custom-drop-down/LanguageDropDown";
import { Langs } from "../../../../enums/langs-enum";

interface CorrespondenceFormProps {
    t: Translate;
    form: FormikProps<ICorrespondenceForm>;
    initialValues: ICorrespondenceForm;
    disableSaveButton: boolean;

    editFormHandler: () => void;
}

const CorrespondenceForm: FC<CorrespondenceFormProps> = (props) => {
    const gallerySchema = useMemo(() => CorrespondenceSchemaFactory({ t: props.t }), [props.t]);
    const setLanguage = (value: Langs) => props.form.setFieldValue("correspondenceLanguage", value);

    return (
        <div className="date__form-block">
            <h1 className="date__title">{props.t("templeRegister.correspondence.title")}</h1>
            <div className="correspondence-info">
                <Trans i18nKey="templeRegister.correspondence.instructionText">
                    {props.t("templeRegister.correspondence.instructionText")}
                </Trans>
            </div>
            <input
                className={`date__input ${getValidationClass(props.form, "correspondenceEmail")}`}
                name="correspondenceEmail"
                onChange={props.form.handleChange}
                value={props.form.values.correspondenceEmail}
                type="text"
                placeholder="E-mail"
                maxLength={gallerySchema.inputRestrictions.correspondenceEmail.max}
            />
            <InputRestrictionsInfo error={props.form.errors.correspondenceEmail} />

            <ChangeEntityButtons<ICorrespondenceForm>
                formik={props.form}
                initialValues={props.initialValues}
                disableSaveButton={props.disableSaveButton}
                editFormHandler={props.editFormHandler}
                buttonLabel={props.t("templeRegister.correspondence.saveEmailButtonLabel")}
            />
            <h3 className="date__subtitle">{props.t("templeRegister.correspondence.subtitle")}</h3>
            <div className="correspondence-info">
                <Trans i18nKey="templeRegister.correspondence.instructionTextLanguage">
                    {props.t("templeRegister.correspondence.instructionTextLanguage")}
                </Trans>
            </div>
            <LanguageDropDown
                language={props.form.values.correspondenceLanguage}
                setLanguage={setLanguage}
            />
        </div>
    );
};

export default withTranslation()(CorrespondenceForm);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PaymentsInfo } from "../../../domain/models/Payment";
import { TemplePayments } from "../../../domain/models/Temple";

interface PaymentsProps {
    t(key?: string): string;
    paymentsInfo: PaymentsInfo | undefined;
    templePayments: TemplePayments[] | undefined;
}

const Payments: FC<PaymentsProps> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="admin-main__block ">
            <div className="admin-main__top">
                <div className="admin-main__title">{props.t("admin.main.payments.title")}</div>
                <a onClick={() => navigate("/admin/payments")} className="admin-main__view">
                    {props.t("admin.main.payments.mainView")}
                </a>
            </div>

            <div className="admin-main__wrap">
                <div className="admin-main__indicators admin-main__payments">
                    <div className="admin-main__indicator">
                        <h2 className="admin-main__indicator-title admin-main__payments-title">
                            {props.t("admin.main.payments.all")}
                        </h2>
                        <div className="admin-main__indicator-price admin-main__payments-price ">
                            {props.paymentsInfo?.totalAmountFloatStr}$
                        </div>
                        <div className="admin-main__indicator-value">{props.paymentsInfo?.totalCount}</div>
                    </div>

                    <div className="admin-main__indicator">
                        <h2 className="admin-main__indicator-title admin-main__payments-title">
                            {props.t("admin.main.payments.done")}
                        </h2>
                        <div className="admin-main__indicator-price admin-main__payments-price">
                            {props.paymentsInfo?.doneAmountFloatStr}$
                        </div>
                        <div className="admin-main__indicator-value">{props.paymentsInfo?.doneCount}</div>
                    </div>

                    <div className="admin-main__indicator">
                        <h2 className="admin-main__indicator-title  admin-main__payments-title">
                            {props.t("admin.main.payments.processing")}
                        </h2>
                        <div className="admin-main__indicator-price admin-main__payments-price">
                            {props.paymentsInfo?.inReviewAmountFloatStr}$
                        </div>
                        <div className="admin-main__indicator-value">{props.paymentsInfo?.inReviewCount}</div>
                    </div>
                </div>
            </div>

            <div className="admin-main__wrap">
                <div className="admin-main__indicators admin-main__payments">
                    <div className="admin-main__indicator">
                        <h2 className="admin-main__indicator-title admin-main__payments-title">
                            {props.t("admin.main.payments.commission")}
                        </h2>
                        <div className="admin-main__indicator-price admin-main__payments-price">
                            {props.paymentsInfo?.siteCommissionAmountFloatStr}$
                        </div>
                        <div className="admin-main__indicator-value">{props.paymentsInfo?.siteCommissionCount}</div>
                    </div>

                    <div className="admin-main__indicator">
                        <h2 className="admin-main__indicator-title admin-main__payments-title">
                            {props.t("admin.main.payments.support")}
                        </h2>
                        <div className="admin-main__indicator-price admin-main__payments-price">
                            {props.paymentsInfo?.projectSupportAmountFloatStr}$
                        </div>
                        <div className="admin-main__indicator-value">{props.paymentsInfo?.projectSupportCount}</div>
                    </div>
                </div>
            </div>

            <div className="admin-main__items">
                {props.templePayments?.map((element, index) => (
                    <a href="#" key={`payment_${index}`}>
                        <div className="admin-main__item">
                            <div className="admin-main__item-holy">{element.name}</div>
                            <div className="admin-main__item-price">{element.totalPaymentsAmountFloatStr}$</div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default withTranslation()(Payments);

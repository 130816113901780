import moment from "moment";
import React, { createContext, useContext, useMemo, useState } from "react";
import { DATE_FORMAT } from "../constants";

interface IWorshipSchedulesContext {
    beginWeekDate: moment.Moment;
    endWeekDate: moment.Moment;

    isDatesChanged: boolean;
    isCurrentWeek: boolean;

    setIsDatesChanged?: (isDatesChanged: boolean) => void;

    getBeginWeekDateStr?: () => string;
    getEndWeekDateStr?: () => string;

    setNextWeekDates?: () => void;
    setPrevWeekDates?: () => void;
    setCurrentWeekDates?: () => void;
}

const WorshipSchedulesProvider = (props: any) => {
    const currentWeekBeginDate = useMemo(() => moment().startOf("isoWeek"), []);
    const currentWeekEndDate = useMemo(() => moment().startOf("isoWeek").add(6, "day"), []);

    const [beginWeekDate, setBeginWeekDate] = useState<moment.Moment>(currentWeekBeginDate.clone());
    const [endWeekDate, setEndWeekDate] = useState<moment.Moment>(currentWeekEndDate.clone());
    const [isCurrentWeek, setIsCurrentWeek] = useState<boolean>(true);

    const [isDatesChanged, setIsDatesChanged] = useState<boolean>(false);

    const getBeginWeekDateStr = () => {
        return beginWeekDate?.format(DATE_FORMAT) || "";
    };

    const getEndWeekDateStr = () => {
        return endWeekDate?.format(DATE_FORMAT) || "";
    };

    const updateWorshipSchedulesState  = (beginDate: moment.Moment) => {
        setIsCurrentWeek(beginDate.isSameOrAfter(currentWeekBeginDate) && beginDate.isSameOrBefore(currentWeekEndDate));
        setIsDatesChanged(true);
    };

    const setNextWeekDates = () => {
        let beginDate: moment.Moment;
        setBeginWeekDate((prevBeginWeekDate) => {
            beginDate = prevBeginWeekDate.add(7, "day");
            return beginDate;
        });
        setEndWeekDate((prevEndWeekDate) => prevEndWeekDate.add(7, "day"));
        updateWorshipSchedulesState(beginDate);
    };

    const setPrevWeekDates = () => {
        let beginDate: moment.Moment;
        setBeginWeekDate((prevBeginWeekDate) => {
            beginDate = prevBeginWeekDate.subtract(7, "day");
            return beginDate;
        });
        setEndWeekDate((prevEndWeekDate) => prevEndWeekDate.subtract(7, "day"));
        updateWorshipSchedulesState(beginDate);
    };

    const setCurrentWeekDates = () => {
        const beginDate = currentWeekBeginDate.clone();
        setBeginWeekDate(beginDate);
        setEndWeekDate(currentWeekEndDate.clone());
        updateWorshipSchedulesState(beginDate);
    };

    return (
        <WorshipSchedulesContext.Provider
            value={{
                beginWeekDate,
                endWeekDate,
                isDatesChanged,
                isCurrentWeek,
                setIsDatesChanged,
                getBeginWeekDateStr,
                getEndWeekDateStr,
                setNextWeekDates,
                setPrevWeekDates,
                setCurrentWeekDates,
            }}
            {...props}
        />
    );
};

const WorshipSchedulesContext = createContext<IWorshipSchedulesContext>({
    beginWeekDate: null,
    endWeekDate: null,
    isDatesChanged: false,
    isCurrentWeek: false,
});

const useWorshipSchedulesContext = () => useContext(WorshipSchedulesContext);

export { WorshipSchedulesProvider, useWorshipSchedulesContext };

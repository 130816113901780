import { FC, RefObject } from "react";
import { Translate } from "../../../../types/Translate";
import { withTranslation } from "react-i18next";
import SortingColumn from "../../../base/table/SortingColumn";
import { SortingColumnsHandlers } from "../../../../types/SortingColumnsHandlers";
import { TempleStatisticsSortFields } from "../../../../enums/temple-statistics-sort-enum";
import CreditRow from "./CreditRow";
import { TempleStatistic } from "../../../../domain/models/TempleStatistic";
import { UseTableRowsActionsHandlers } from "../../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import { Payout } from "../../../../domain/models/Payout";
import CustomCheckbox from "../../../base/CustomCheckbox";

interface CreditsTableProps {
    t: Translate;
    credits: TempleStatistic[];
    sortingColumnsHandlers: SortingColumnsHandlers;
    creditsTableRef: RefObject<HTMLTableElement>;
    rowsHandlers: UseTableRowsActionsHandlers<TempleStatistic, Payout>;
}

const CreditsTable: FC<CreditsTableProps> = (props) => {
    return (
        <table
            ref={props.creditsTableRef}
            id="tableSort"
            className="inner__table inner__table__max-content sortable-table"
        >
            <thead>
                <tr>
                    <th className="td-padding1-75 td-padding1-75__short date">
                        <CustomCheckbox
                            id="selectAllRowButton"
                            name="selectAllRowButton"
                            defaultValue={false}
                            value={props.rowsHandlers.selectAllRows}
                            className="checkbox__input"
                            additionalLabelClassName="date__text"
                            wrapperClassName="order__checkbox simple-checkbox"
                            onChange={props.rowsHandlers.selectAllRowsHandler}
                        />
                    </th>
                    <th className="td-padding1 td-padding1-75__short">{props.t("admin.payouts.credits.table.actions.columnLabel")}</th>
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={TempleStatisticsSortFields.TEMPLE_NAME}
                        columnTranslationName={props.t("admin.payouts.credits.table.templeName")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={TempleStatisticsSortFields.TOTAL_TEMPLE_TRANSACTION_AMOUNT}
                        columnTranslationName={props.t("admin.payouts.credits.table.transactionAmount")}
                        additionalWrapperClass="td-padding2__right2"
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={TempleStatisticsSortFields.TOTAL_TEMPLE_TRANSACTION_PRAYERS_AMOUNT}
                        columnTranslationName={props.t("admin.payouts.credits.table.transactionPrayersAmount")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={TempleStatisticsSortFields.TOTAL_TEMPLE_TRANSACTION_DONATIONS_AMOUNT}
                        columnTranslationName={props.t("admin.payouts.credits.table.transactionDonationsAmount")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <th className="">{props.t("admin.payouts.credits.table.transactionsCount")}</th>
                    <th className="">{props.t("admin.payouts.credits.table.transactionsPrayersCount")}</th>
                    <th className="">{props.t("admin.payouts.credits.table.transactionsDonationsCount")}</th>
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={TempleStatisticsSortFields.DEFAULT_PAYMENT_METHOD}
                        columnTranslationName={props.t("admin.payouts.credits.table.defaultPaymentMethod")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={TempleStatisticsSortFields.DATE_OF_APPROVAL_LATEST_PAYOUT}
                        columnTranslationName={props.t("admin.payouts.credits.table.dateOfApprovalLatestPayout")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <th className="">{props.t("admin.payouts.credits.table.activePayouts")}</th>
                </tr>
            </thead>

            <tbody>
                {props.credits?.map((credit) => (
                    <CreditRow
                        credit={credit}
                        setReloadCache={props.sortingColumnsHandlers.setReloadCache}
                        selectRowCallback={() => {
                            props.rowsHandlers.updateSelectedRows(credit);
                            props.rowsHandlers.selectAllRowsHandler(false);
                        }}
                        isSelectedRow={!!props.rowsHandlers.getSelectedRowById(credit.id)}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default withTranslation()(CreditsTable);

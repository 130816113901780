import { useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { User } from "../../domain/models/User";
import { Role } from "../../enums/role-enum";
import { UserLoginSchemaFactory } from "../../validation-schemas/user-schemas";
import TogglePassword from "../base/TogglePassword";
import LangSelect from "../base/LangSelect";
import { withTranslation } from "react-i18next";
import { errorServiceFactory } from "../../services/ErrorServiceImpl";
import { StatusCodes } from "http-status-codes";
import HomeButtonSVG from "../base/svg/HomeButton";
import { PublicParams } from "../../enums/public-params-enum";
import { LoginRequiredPages } from "../../enums/login-required-pages";

interface LoginProps {
    t(key?: string): string;
}

const Login: FC<LoginProps> = (props) => {
    const [searchParams] = useSearchParams();
    const pageToRedirect = searchParams.get(PublicParams.REDIRECT) as LoginRequiredPages;

    const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);
    const { signIn } = useAuth();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: UserLoginSchemaFactory({ t: props.t }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);
            setError(null);

            if (!values.email || !values.password) {
                return;
            }

            try {
                const errorService = errorServiceFactory();
                const user: User | undefined = await signIn(values.email || "", values.password || "");

                const forbiddenError = errorService.getStatusFromErrorResponseSubject(StatusCodes.FORBIDDEN);

                if (forbiddenError) {
                    setError("login.notConfirmed");
                    errorService.resetErrorResponseSubject();
                    return;
                }

                if (!user) {
                    setError("errors.common");
                    return;
                }

                if (pageToRedirect) {
                    navigate("/" + pageToRedirect);
                } else if (user.role === Role.SUPER_ADMIN) {
                    navigate("/admin/main");
                } else if (user.role === Role.TEMPLE_ADMIN) {
                    navigate("/temple-admin/main");
                }
            } catch (err) {
                const errorService = errorServiceFactory();
                errorService.setResponseErrorMessage(err as Error, setError);
                return;
            }
        },
    });

    return (
        <div className="form">
            <HomeButtonSVG />
            <div className="header__lang-wrap" style={{ paddingLeft: "311px" }}>
                <div className="select__item">
                    <LangSelect langColor={"white"} />
                </div>
            </div>

            <form id="enterForm" onSubmit={formik.handleSubmit}>
                {error && (formik.values.email === "" || formik.values.password === "") && setError(null)}
                <div className="form__inner">
                    <h1 className="form__title">{props.t("login.title")}</h1>

                    <div className="form__wrap">
                        <input
                            className={`form__input ${formik.touched.email && formik.values.email === "" && "invalid"}`}
                            type="input"
                            placeholder={props.t("login.email")}
                            name="email"
                            id="id_email"
                            autoComplete="email"
                            onChange={formik.handleChange}
                            defaultValue={formik.values.email}
                        />
                    </div>
                    <p style={{ marginTop: `${formik.touched.email && formik.errors.email ? "-25px" : "5px"}` }}>
                        {formik.touched.email && formik.errors.email}
                    </p>

                    <div className="form__wrap">
                        <input
                            className={`form__input form__password ${
                                formik.touched.password && formik.values.password === "" && "invalid"
                            }`}
                            id="id_password"
                            type={!isPasswordVisible ? "password" : "input"}
                            placeholder={props.t("login.password")}
                            name="password"
                            onChange={formik.handleChange}
                            defaultValue={formik.values.password}
                        />

                        <TogglePassword
                            isPasswordVisible={isPasswordVisible}
                            setPasswordVisible={setPasswordVisibility}
                        />
                    </div>
                    <p style={{ marginTop: `${formik.touched.password && formik.errors.password ? "-25px" : "5px"}` }}>
                        {formik.touched.password && formik.errors.password}
                    </p>

                    <p>{error && props.t(error)}</p>

                    <div className="form__btns">
                        <button className="form__btn--next" type="submit">
                            {props.t("login.title")}
                        </button>
                        <Link to="/register" className="form__btn--reg">
                            {props.t("register.title")}
                        </Link>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default withTranslation()(Login);

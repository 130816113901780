import { FC, RefObject, useEffect, useState } from "react";
import PhotoSlider from "./photo-slider/PhotoSlider";
import { Swiper as ISwiper } from "swiper/types";
import PaginationLeft from "./svg/PaginationLeft";
import PaginationRight from "./svg/PaginationRight";
import { DEFAULT_PAGE } from "../../constants";

interface PagePaginationProps {
    pagesCount: number;

    isSearchingParamsUpdated?: boolean;
    scrollIntoView?: boolean;

    currentPageIndex: number;
    setCurrentPageIndex(value: number): void;
    setIsChangeContent(value: boolean): void;

    additionalPaginationClass?: string;

    visibleItemCount: number;

    wrapperRef?: RefObject<HTMLDivElement>;
}

const PagePagination: FC<PagePaginationProps> = (props) => {
    const pages = Array.apply(null, Array(props.pagesCount)).map((_element, index) => index + 1);
    const [paginationSlider, setPaginationSlider] = useState<ISwiper>(null);

    const prevPageIndex = props.currentPageIndex - 1;
    const nextPageIndex = props.currentPageIndex + 1;
    const isFirst = prevPageIndex < pages[0];
    const isLast = nextPageIndex > pages.at(-1);

    const changePage = (toPrev: boolean = false) => {
        if ((toPrev && isFirst) || (!toPrev && isLast)) {
            return;
        }
        //scrolling up
        if (props.wrapperRef) {
            props.scrollIntoView ? props.wrapperRef.current.scrollIntoView() : props.wrapperRef.current.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
        }

        if (toPrev) {
            props.setCurrentPageIndex(prevPageIndex);
            paginationSlider.slidePrev();
            return props.setIsChangeContent(true);
        }

        props.setCurrentPageIndex(nextPageIndex);
        paginationSlider.slideNext();
        props.setIsChangeContent(true);
    };

    useEffect(() => {
        if (props.isSearchingParamsUpdated) {
            paginationSlider.slideTo(DEFAULT_PAGE - 1);
        }
    });

    return (
        <div className={"church__pagination " + (props.additionalPaginationClass || " ")}>
            <a className={"church__pagination-left " + (isFirst ? "disabled-pagination-button" : "")} onClick={() => changePage(true)}>
                <PaginationLeft />
            </a>

            <PhotoSlider
                content={pages}
                hideContent={true}
                slidesPerView={1}
                dynamicBullets={true}
                dynamicMainBullets={props.visibleItemCount}
                loop={false}
                hideSwiperArrows={true}
                currentSwiper={paginationSlider}
                setCurrentSwiper={setPaginationSlider}
                disableAutoplay={true}
            />

            <a className={"church__pagination-right " + (isLast ? "disabled-pagination-button" : "")} onClick={() => changePage()}>
                <PaginationRight />
            </a>
        </div>
    );
};

export default PagePagination;

import { FC, RefObject } from "react";
import { withTranslation } from "react-i18next";
import { Payment } from "../../../domain/models/Payment";
import { PaymentSortFields } from "../../../enums/payment-sort-fields-enum";
import { SortingColumnsHandlers } from "../../../types/SortingColumnsHandlers";
import SortingColumn from "../table/SortingColumn";
import PaymentRow from "./PaymentRow";
import { UseTableRowsActionsHandlers } from "../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import { Payout } from "../../../domain/models/Payout";

interface PaymentTableProps {
    t(key?: string): string;
    payments: Payment[] | undefined;
    isSuperAdminUser?: boolean;
    isTemplePayments?: boolean;
    isActiveTransactionsTableShown?: boolean;
    additionalTableClassName?: string;

    paymentsTableRef: RefObject<HTMLDivElement>;
    sortingColumnsHandlers: SortingColumnsHandlers;
    rowsHandlers?: UseTableRowsActionsHandlers<Payment, Payout>;
    recipientName?: string;
}
const PaymentTable: FC<PaymentTableProps> = (props) => {
    return (
        <div
            id="payout-payments-to-print"
            ref={props.paymentsTableRef}
            className={
                props.isActiveTransactionsTableShown
                    ? "inner__content23 inner__content23__payout-creation-modal"
                    : props.additionalTableClassName
                    ? "inner__content23__temple-payments-page"
                    : "inner__content23"
            }
        >
            <table className="inner__table sortable-table">
                <thead>
                    <tr>
                        {props.rowsHandlers ? (
                            <th className="td-padding2">{props.t("general.selectRowButton")}</th>
                        ) : (
                            <></>
                        )}
                        <th className="td-padding2">{props.t("templeAdmin.payments.table.labels.senderName")}</th>
                        <th className="td-padding2">
                            {props.t("templeAdmin.payments.table.labels.id")}
                            <br />
                            {props.t("templeAdmin.payments.table.labels.payment")}
                        </th>
                        <SortingColumn
                            sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                            columnName={PaymentSortFields.TEMPLE_ID}
                            columnTranslationName={
                                props.t("templeAdmin.payments.table.labels.name") +
                                props.t("templeAdmin.payments.table.labels.recipient")
                            }
                            disableBaseColumnStyle={true}
                            currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                            setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                        />
                        <SortingColumn
                            sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                            columnName={PaymentSortFields.STATUS}
                            columnTranslationName={props.t("templeAdmin.payments.table.labels.status")}
                            additionalWrapperClass="td-padding3"
                            disableBaseColumnStyle={true}
                            currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                            setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                        />
                        <SortingColumn
                            sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                            columnName={PaymentSortFields.PAYMENT_DATE}
                            columnTranslationName={props.t("templeAdmin.payments.table.labels.paymentDate")}
                            additionalWrapperClass="td-padding1"
                            disableBaseColumnStyle={true}
                            currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                            setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                        />
                        <SortingColumn
                            sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                            columnName={PaymentSortFields.AMOUNT}
                            columnTranslationName={props.t("templeAdmin.payments.table.labels.amount")}
                            additionalWrapperClass="td-padding2"
                            disableBaseColumnStyle={true}
                            currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                            setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                        />
                        {props.isSuperAdminUser && (
                            <SortingColumn
                                sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                                columnName={PaymentSortFields.RECIPIENT_AMOUNT}
                                columnTranslationName={props.t("templeAdmin.payments.table.labels.temple")}
                                additionalWrapperClass="td-padding2"
                                disableBaseColumnStyle={true}
                                currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                                setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                            />
                        )}
                        {props.isSuperAdminUser && (
                            <SortingColumn
                                sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                                columnName={PaymentSortFields.PLATFORM_AMOUNT}
                                columnTranslationName={props.t("templeAdmin.payments.table.labels.site")}
                                additionalWrapperClass="td-padding2"
                                disableBaseColumnStyle={true}
                                currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                                setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                            />
                        )}
                        <SortingColumn
                            sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                            columnName={PaymentSortFields.TRANSACTION_TYPE}
                            columnTranslationName={
                                props.t("templeAdmin.payments.table.labels.type") +
                                props.t("templeAdmin.payments.table.labels.transaction")
                            }
                            additionalWrapperClass="td-padding1"
                            disableBaseColumnStyle={true}
                            currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                            setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                        />
                    </tr>
                </thead>

                <tbody>
                    {props.payments?.map((payment, index) => (
                        <PaymentRow
                            key={`payment_${index}`}
                            payment={payment}
                            recipientName={props.recipientName}
                            isSuperAdminUser={props.isSuperAdminUser}
                            isTemplePayments={props.isTemplePayments}
                            selectRowCallback={
                                props.rowsHandlers
                                    ? () => {
                                          props.rowsHandlers.updateSelectedRows(payment);
                                          props.rowsHandlers.selectAllRowsHandler(false);
                                      }
                                    : null
                            }
                            isSelectedRow={!!props.rowsHandlers?.getSelectedRowById(payment.id)}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default withTranslation()(PaymentTable);

import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";

export enum PaymentMethod {
    PAY_PAL = 1,
    PAYONEER = 2,
    SKRILL = 3
}

export function getCurrentMethodTranslation(t: Translate, paymentMethod: PaymentMethod): SelectProps {
    return { value: paymentMethod?.toString(), label: t(paymentMethod && `paymentMethod.${paymentMethod}`) };
}

export function getMethodsTranslation(t: Translate): SelectProps[] {
    return [
        { value: PaymentMethod.PAY_PAL.toString(), label: t(`paymentMethod.${PaymentMethod.PAY_PAL}`) },
        { value: PaymentMethod.PAYONEER.toString(), label: t(`paymentMethod.${PaymentMethod.PAYONEER}`) },
        { value: PaymentMethod.SKRILL.toString(), label: t(`paymentMethod.${PaymentMethod.SKRILL}`) },
    ];
}

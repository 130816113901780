import { Mapper } from "../domain/mappers/mapper";
import { TemplesInfo } from "../domain/models/Temple";
import { TemplesInfoDTO } from "../domain/models/dto/TempleDTO";
import TemplesInfoModel from "../models/TemplesInfoModel";
import { SimpleMapper } from "./SimpleMapper";

class TemplesInfoMapper extends SimpleMapper<TemplesInfo, TemplesInfoDTO> {
    protected entityConstructor = TemplesInfoModel;

    protected fromDTOFields: string[] = [
        "totalCount",
        "inReviewCount",
        "deletedCount",
    ];
}


export default function templesInfoMapperFactory(): Mapper<TemplesInfo, TemplesInfoDTO> {
    return new TemplesInfoMapper()
}

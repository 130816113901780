import { FC, MutableRefObject, useState } from "react";
import { PrayerRequirement } from "../../../../../domain/models/PrayerRequirement";
import { Temple } from "../../../../../domain/models/Temple";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import PrayerRequirementModel from "../../../../../models/PrayerRequirementModel";
import { BaseEntityManipulationHooks } from "../../../../../tools/custom-hooks/BaseEntityManipulationHooks";
import PrayerRequirementForm from "./PrayerRequirementForm";
import PrayerRequirements from "./PrayerRequirements";
import { Translate } from "../../../../../types/Translate";
import { withTranslation } from "react-i18next";

interface MainRequirementComponentProps {
    temple: Temple;
    t: Translate;
    resetErrorCallback: () => void;
}

const MainRequirementComponent: FC<MainRequirementComponentProps> = (props) => {
    const handlers = BaseEntityManipulationHooks<PrayerRequirement>(
        PrayerRequirementModel,
        props.temple.getSortedPrayerRequirements(SortingOrder.ASC),
        props.temple.setPrayerRequirements.bind(props.temple),
        props.temple.removePrayerRequirementByOrder.bind(props.temple),
    );

    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [formRef, setFormRef] = useState<MutableRefObject<any>>(null);

    return (
        <>
            <h3 className="date__subtitle">{props.t("templeRegister.orders.subtitle2")}</h3>
            <PrayerRequirements
                temple={props.temple}
                prayerRequirements={handlers.entities}
                setCurrentPrayerRequirement={handlers.setCurrentEntity}
                isOrderChanged={isOrderChanged}
                setIsOrderChanged={setIsOrderChanged}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                formRef={formRef}
                removePrayerRequirement={handlers.removeOrderedEntity}
            />
            <PrayerRequirementForm
                temple={props.temple}
                prayerRequirement={handlers.currentEntity}
                updatePrayerRequirements={handlers.updateEntities}
                resetPrayerRequirementForm={handlers.resetFormData}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                setFormRef={setFormRef}
                resetErrorCallback={props.resetErrorCallback}
            />
        </>
    );
};

export default withTranslation()(MainRequirementComponent);

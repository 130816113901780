import { FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import UnAuthRoutes from "../../routes/unauthenticated-routes";
import { useAuth } from "../../context/AuthProvider";
import AdminRoutes from "../../routes/admin-routes";
import TempleAdminRoutes from "../../routes/temple-admin-routes";
import LoadingPanel from "../base/LoadingPanel";
import CreateTempleRoutes from "../../routes/create-temple-routes";

const REDIRECT_TO_LOGIN_PATHS = ["admin", "temple-admin"];

interface AppProps {}

const App: FC<AppProps> = () => {
    const { user, loading } = useAuth();
    const currentPath = useLocation().pathname.split("/")[1];

    if (loading) {
        return <LoadingPanel isVisible={loading} />;
    }

    return (
        <Routes>
            {user?.isAdmin && <Route path="/temple-admin/*" element={<Navigate to="/admin/main" />} />}

            {user?.isTempleAdmin && <Route path="/admin/*" element={<Navigate to="/temple-admin/main" />} />}

            {!user?.isTempleAdmin && !user?.isAdmin && REDIRECT_TO_LOGIN_PATHS.includes(currentPath) && (
                <>
                    <Route path="/admin/*" element={<Navigate to="/login" />} />
                    <Route path="/temple-admin/*" element={<Navigate to="/login" />} />
                </>
            )}

            <Route path="/*" element={<UnAuthRoutes  />} />

            {user &&
                ((user.isAdmin && (
                    <>
                        <Route path="/admin/*" element={<AdminRoutes  />} />
                    </>
                )) ||
                    (user.isTempleAdmin && (
                        <>
                            <Route path="/temple-admin/*" element={<TempleAdminRoutes  />} />
                            <Route path="/create-temple/*" element={<CreateTempleRoutes  />} />
                        </>
                    )))}
        </Routes>
    );
};

export default App;

import { ArtifactDTO } from "../domain/models/dto/ArtifactDTO";
import { Artifact } from "../domain/models/Artifact";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";

export class ArtifactModel extends BaseRelatedEntityMixinFactory<ArtifactDTO>() implements Artifact {
    templeId?: string;
    name?: string;
    info?: string;

    constructor(data?: ArtifactDTO) {
        super();

        this.assignData(data);
    }
}

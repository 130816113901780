import { useFormik } from "formik";
import { FC, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { useFormsValidConfirmation } from "../../../../../context/FormikFormsProvider";
import { Prayer } from "../../../../../domain/models/Prayer";
import { PrayerPriceDetail } from "../../../../../domain/models/PrayerPriceDetail";
import { getValidationClass } from "../../../../../tools/Tools";
import { Translate } from "../../../../../types/Translate";
import { PrayerDetailsSchemaFactory } from "../../../../../validation-schemas/temple-register/prayer-detail-schemas";
import InputRestrictionsInfo from "../../../../base/InputRestrictionsInfo";
import ConflictErrorModal from "../../../../base/conflict-error-modal/ConflictErrorModal";

interface PrayerDetailsFormProps {
    t: Translate;

    prayer: Prayer;

    detail: PrayerPriceDetail;
    detailIndex: number;

    setIsDetailsError(value: boolean): void;
}

const PrayerDetailsForm: FC<PrayerDetailsFormProps> = (props) => {
    const prayerDetailsSchema = useMemo(() => PrayerDetailsSchemaFactory({ t: props.t }), [props.t]);

    const prayerDetailForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            maxPeople: props.detail.maxPeople,
            price: props.detail.priceFloatFormat,
        },
        validationSchema: prayerDetailsSchema.schema,
        onSubmit: () => {},
    });

    useFormsValidConfirmation("prayerDetailForm", prayerDetailForm);

    const updateMaxPeople = (e: any) => {
        const value = e.target.value;

        prayerDetailForm.setFieldValue("maxPeople", value);

        props.detail.setMaxPeople(value);
        props.detail.setIsChanged(true);
    };

    const updatePrice = (e: any) => {
        const value = e.target.value;

        prayerDetailForm.setFieldValue("price", value);

        props.detail.setPrice(value);
        props.detail.setIsChanged(true);
    };

    useEffect(() => {
        if (prayerDetailForm.errors.price || prayerDetailForm.errors.maxPeople) {
            props.setIsDetailsError(true);
        } else {
            props.setIsDetailsError(false);
        }
    });

    return (
        <>
            <div className="date__form-block">
                <input
                    className={`date__input ${getValidationClass(prayerDetailForm, "maxPeople")}`}
                    name="maxPeople"
                    onChange={(e) => updateMaxPeople(e)}
                    value={prayerDetailForm.values.maxPeople || ""}
                    type="text"
                    placeholder={props.t("templeRegister.orders.maxPeople2")}
                    maxLength={prayerDetailsSchema.inputRestrictions.maxPeople.max}
                />
                <InputRestrictionsInfo
                    error={prayerDetailForm.errors.maxPeople}
                />
            </div>

            <div className="date__form-block">
                <input
                    className={`date__input ${getValidationClass(prayerDetailForm, "price")}`}
                    name="price"
                    onChange={(e) => updatePrice(e)}
                    value={prayerDetailForm.values.price || ""}
                    type="text"
                    placeholder={props.t("templeRegister.orders.price2")}
                    maxLength={prayerDetailsSchema.inputRestrictions.price.max}
                />
                <InputRestrictionsInfo
                    error={prayerDetailForm.errors.price}
                />
            </div>
            <ConflictErrorModal />
        </>
    );
};

export default withTranslation()(PrayerDetailsForm);

import { FC, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../../../domain/models/Temple";
import { WorshipSchedule } from "../../../../../domain/models/WorshipSchedule";
import { WorshipScheduleModel } from "../../../../../models/WorshipSchedule";
import { BaseEntityManipulationHooks } from "../../../../../tools/custom-hooks/BaseEntityManipulationHooks";
import WorshipScheduleForm from "./form/WorshipScheduleForm";
import WorshipSchedules from "./WorshipSchedules";

interface MainWorshipScheduleComponentProps {
    t(key?: string): string;
    temple: Temple;
}

const MainWorshipScheduleComponent: FC<MainWorshipScheduleComponentProps> = (props) => {
    const handlers = BaseEntityManipulationHooks<WorshipSchedule>(
        WorshipScheduleModel, props.temple.worshipSchedules, props.temple.setWorshipSchedules.bind(props.temple)
    );

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const worshipScheduleFormRef = useRef<HTMLDivElement>(null);

    return (
        <div className="worship">
            <h2 className="date__subtitle worship__subtitle">{props.t("templeRegister.schedules.subtitle")}</h2>

            <WorshipSchedules
                temple={props.temple}
                removeSchedule={handlers.removeEntity}
                setCurrentWorshipSchedule={handlers.setCurrentEntity}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                worshipScheduleFormRef={worshipScheduleFormRef}
            />

            <WorshipScheduleForm
                temple={props.temple}
                worshipSchedule={handlers.currentEntity}
                updateWorshipSchedules={handlers.updateEntities}
                resetWorshipScheduleForm={handlers.resetFormData}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                worshipScheduleFormRef={worshipScheduleFormRef}
            />
        </div>
    );
};

export default withTranslation()(MainWorshipScheduleComponent);

import { FC } from "react";
import { NonValidPayoutDTO } from "../../../../domain/models/dto/PayoutDTO";
import { Translate } from "../../../../types/Translate";
import { withTranslation } from "react-i18next";
import NonValidPayoutRow from "./NonValidPayoutRow";

interface NonValidPayoutsTableProps {
    t: Translate;
    payouts: NonValidPayoutDTO[];
}

const NonValidPayoutsTable: FC<NonValidPayoutsTableProps> = (props) => {
    return (
        <div id="payout-payments-to-print" className="inner__content23 inner__content23__non-valid">
            <h3 className="non-valid-payouts-table-modal__title">{props.t("admin.payouts.nonValid.title")}</h3>
            <table className="inner__table sortable-table">
                <thead>
                    <tr>
                        <th className="td-padding2">{props.t("admin.payouts.nonValid.csvOrder")}</th>
                        <th className="td-padding2">{props.t("admin.payouts.nonValid.id")}</th>
                        <th className="td-padding2">{props.t("admin.payouts.nonValid.transactionId")}</th>
                        <th className="td-padding2">{props.t("admin.payouts.nonValid.status")}</th>
                        <th className="td-padding2">{props.t("admin.payouts.nonValid.createdAt")}</th>
                    </tr>
                </thead>

                <tbody>
                    {props.payouts?.map((payout, index) => (
                        <NonValidPayoutRow key={`non-valid-payout_${index}`} payout={payout} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default withTranslation()(NonValidPayoutsTable);

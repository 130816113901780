import { useCallback, useEffect, useMemo, useState } from "react";
import { ChartsParams } from "../../../types/charts/ChartsParams";
import { EntityChart } from "../../../types/charts/EntityChart";
import { ChartPeriodType } from "../../../types/charts-enums/chart-period-type-enum";
import _ from "lodash";
import chartServiceFactory from "../../../services/ChartServiceImpl";

export const useEntityChart = (
    visibleChart: boolean,
    defaultChartsParams: ChartsParams,
    getChartsDataCallback: (chartsParams: ChartsParams) => Promise<number[]>,
    nonNullableParams: boolean = false
): EntityChart => {
    const handlersChartsParams = useMemo(() => {
        return {
            year: nonNullableParams ? defaultChartsParams.year : null,
            month: nonNullableParams ? defaultChartsParams.month : null,
            day: nonNullableParams ? defaultChartsParams.day : null,
        }
    }, [defaultChartsParams, nonNullableParams]);

    const [chartsData, setChartsData] = useState<number[]>([]);
    const [chartsParams, setChartsParams] = useState<ChartsParams>(defaultChartsParams);
    const [shouldBeUpdated, setShouldBeUpdated] = useState<boolean>(true);

    useEffect(() => {
        const uploadData = async () => {
            setChartsData(await getChartsDataCallback(chartsParams));
        }

        if (visibleChart && shouldBeUpdated) {
            uploadData();
            setShouldBeUpdated(false);
        }
    }, [visibleChart, chartsData, chartsParams, shouldBeUpdated, getChartsDataCallback]);

    const yearHandler = useCallback((value: number) => {
        setChartsParams({
            ...handlersChartsParams,
            year: value || defaultChartsParams.year,
        });
        setShouldBeUpdated(true);
    }, [defaultChartsParams, handlersChartsParams]);

    const monthHandler = useCallback((value: number) => {
        setChartsParams((prevState) => {
            return {
                year: prevState.year,
                month: !_.isNil(value) ? value : handlersChartsParams.month,
                day: handlersChartsParams.day,
            }
        });
        setShouldBeUpdated(true);
    }, [handlersChartsParams]);

    const dayHandler = useCallback((value: number) => {
        setChartsParams((prevState) => {
            return {
                ...prevState,
                day: value || handlersChartsParams.day,
            }
        });
        setShouldBeUpdated(true);
    }, [handlersChartsParams]);

    const applyChartsParams = useCallback(() => {
        setShouldBeUpdated(true);
    }, []);

    const resetChartsParams = useCallback(() => {
        setChartsParams(defaultChartsParams);
        setShouldBeUpdated(true);
    }, [defaultChartsParams]);

    const getPeriodType = useCallback(() => {
        const paramsInfo = chartServiceFactory().analyzeChartsParams(chartsParams);
        return paramsInfo.onlyYear ? ChartPeriodType.YEAR : paramsInfo.onlyYearAndMonth ? ChartPeriodType.MONTH : paramsInfo.yearAndMonthAndDay ? ChartPeriodType.DAY : ChartPeriodType.HOUR;
    }, [chartsParams]);

    return {
        chartsData,
        chartsParams,
        yearHandler,
        monthHandler,
        dayHandler,
        applyChartsParams,
        resetChartsParams,
        getPeriodType,
    }
}

import { FC } from "react";
import Popup from "reactjs-popup";
import ModalWindow from "../../base/ModalWindow";

interface RequestsCompletedModalProps {
    isModalActive: boolean;
    error: string;
    success: string;
    closeModalCallback: () => void;
}

const RequestsCompletedModal: FC<RequestsCompletedModalProps> = (props) => {
    return (
        <Popup
            open={props.isModalActive}
            modal
            overlayStyle={{ zIndex: 999999, flexDirection: "column" }}
            onClose={props.closeModalCallback}
        >
            <ModalWindow
                contentClass="modal-remove__content"
                bodyClass="information__articles-article"
                closeCallback={props.closeModalCallback}
                content={<div className="inner__graphics-tabs-wrap">{props.error ? props.error : props.success}</div>}
            />
        </Popup>
    );
};

export default RequestsCompletedModal;

import { FC } from "react";

const Logo: FC = () => {
    return (
        <a href="/" className="header--main__logo">
            <picture>
                <source className="logo" srcSet="/img/logo.webp" type="image/webp" />
                <img className="logo" src="/img/logo.png" alt="" />
            </picture>
        </a>
    );
};

export default Logo;

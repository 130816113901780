import { Entity } from "./enums/change-request-entity-enums/entity-enum";
import { Currency } from "./enums/currency-enum";
import { RecordStatus } from "./enums/record-status";
import { ResponseErrors } from "./enums/response-errors-enum";
import { SupportTicketCustomerRole } from "./enums/support-ticket-customer-role";
import { SupportTicketSubject } from "./enums/support-ticket-subject";
import { TempleSearchParameters } from "./enums/temple-search-parameters-enum";
import { TempleSortFields } from "./enums/temple-sort-fields-enum";
import { Week } from "./enums/week-enum";
import { Page } from "./enums/page-enum";
import { StatusCodes } from "http-status-codes";
import { Months } from "./types/base-enums/months-enum";
import { PayoutStatus } from "./enums/payout-status-enum";
import { Langs } from "./enums/langs-enum";

export const translations = {
    ua: {
        translation: {
            religions: {
                undefined: "",
                CHRISTIANITY: "Християнство",
            },
            religionCurrents: {
                undefined: "",
                CATHOLICISM: "Католицизм",
                ORTHODOXY: "Православ'я",
                MONOPHYSITISM:
                    "Монофізитство (Давньосхідні православні церкви)",
                NESTORIANISM: "Несторіанство (Ассірійська церква Сходу)",
                PROTESTANTISM: "Протестантизм",
            },
            religionSubspecies: {
                undefined: "",
                ROMAN_CATHOLIC_CHURCH: "Римо-католицька церква",
                ARMENIAN_CATHOLIC_CHURCH: "Вірменська католицька церква",
                UKRAINIAN_GREEK_CATHOLIC_CHURCH:
                    "Українська греко-католицька церква",
                MUKACHEVO_GREEK_CATHOLIC_EPARCHY:
                    "Мукачівська греко-католицька єпархія",
                RUTHENIAN_GREEK_CATHOLIC_CHURCH:
                    "Русинська греко-католицька церква",
                SLOVAK_GREEK_CATHOLIC_CHURCH:
                    "Словацька греко-католицька церква",
                HUNGARIAN_GREEK_CATHOLIC_CHURCH:
                    "Угорська греко-католицька церква",
                APOSTOLIC_EXARCHATE_OF_THE_GREEK_CATHOLIC_CHURCH_IN_THE_CZECH_REPUBLIC:
                    "Апостольський екзархат греко-католицької церкви у Чеській Республіці",
                CROATIAN_GREEK_CATHOLIC_CHURCH:
                    "Хорватська греко-католицька церква",
                BELARUSIAN_GREEK_CATHOLIC_CHURCH:
                    "Білоруська греко-католицька церква",
                RUSSIAN_GREEK_CATHOLIC_CHURCH:
                    "Російська греко-католицька церква",
                ROMANIAN_GREEK_CATHOLIC_CHURCH:
                    "Румунська греко-католицька церква",
                BULGARIAN_CATHOLIC_CHURCH: "Болгарська католицька церква",
                MACEDONIAN_GREEK_CATHOLIC_CHURCH:
                    "Македонська греко-католицька церква",
                GREEK_CATHOLIC_CHURCH: "Грецька католицька церква",
                ALBANIAN_CATHOLIC_CHURCH: "Албанська католицька церква",
                ITALO_ALBANIAN_CATHOLIC_CHURCH:
                    "Італо-албанська католицька церква",
                MELKITE_GREEK_CATHOLIC_CHURCH:
                    "Мелькітська греко-католицька церква",
                COPTIC_CATHOLIC_CHURCH: "Коптська католицька церква",
                ETHIOPIAN_CATHOLIC_CHURCH: "Ефіопська католицька церква",
                MARONITE_CHURCH: "Маронітська католицька церква",
                SYRIAC_CATHOLIC_CHURCH: "Сирійська католицька церква",
                SYRO_MALANKARA_CATHOLIC_CHURCH:
                    "Сиро-маланкарська католицька церква",
                CHALDEAN_CATHOLIC_CHURCH: "Халдейська католицька церква",
                SYRO_MALABAR_CATHOLIC_CHURCH:
                    "Сиро-малабарська католицька церква",

                CONSTANTINOPOLITAN_PATRIARCHATE:
                    "Константинопольський Патріархат",
                ALEXANDRIAN_PATRIARCHATE: "Александрійський Патріархат",
                ANTIOCHIAN_PATRIARCHATE: "Антиохійський Патріархат",
                JERUSALEM_PATRIARCHATE: "Єрусалимський Патріархат",
                MOSCOW_PATRIARCHATE: "Московський Патріархат",
                SERBIAN_PATRIARCHATE: "Сербський Патріархат",
                ROMANIAN_PATRIARCHATE: "Румунський Патріархат",
                BULGARIAN_PATRIARCHATE: "Болгарський Патріархат",
                GEORGIAN_PATRIARCHATE: "Грузинський Патріархат",
                CYPRUS_ORTHODOX_CHURCH: "Кіпрська Православна Церква",
                GREEK_ORTHODOX_CHURCH: "Елладська Православна Церква",
                POLISH_ORTHODOX_CHURCH: "Польська Православна Церква",
                ALBANIAN_ORTHODOX_CHURCH: "Албанська Православна Церква",
                ORTHODOX_CHURCH_CZECH_LANDS_SLOVAKIA:
                    "Православна Церква Чеських Земель і Словаччини",
                ORTHODOX_CHURCH_IN_AMERICA: "Православна церква в Америці",
                UKRAINIAN_ORTHODOX_CHURCH: "Православна церква України",
                UKRAINIAN_ORTHODOX_CHURCH_USA:
                    "Українська православна церква США",
                UKRAINIAN_ORTHODOX_CHURCH_CANADA:
                    "Українська православна церква Канади",
                AMERICAN_CARPATHO_RUSSIAN_ORTHODOX_DIACONATE:
                    "Американська карпаторуська православна єпархія",
                WESTERN_EUROPEAN_EXARCHATE:
                    "Західноєвропейський екзархат російських парафій в Західній Європі",
                FINNISH_ORTHODOX_CHURCH: "Фінляндська Православна Церква",
                ESTONIAN_APOSTOLIC_ORTHODOX_CHURCH:
                    "Естонська Апостольсько-Православна Церква",
                CRETAN_ORTHODOX_CHURCH: "Критська православна церква",
                KOREAN_ORTHODOX_CHURCH: "Корейська православна церква",
                ANTIOCHIAN_ORTHODOX_ARCHDIOCESE_OF_NORTH_AMERICA:
                    "Антіохійська православна архієпархія Північної Америки",
                SINAI_ORTHODOX_CHURCH: "Синайська Православна Церква",
                UKRAINIAN_ORTHODOX_CHURCH_OF_THE_MOSCOW_PATRIARCHATE:
                    "Українська православна церква (Московський патріархат)",
                RUSSIAN_ORTHODOX_CHURCH_ABROAD:
                    "Російська православна церква закордоном",
                MOLDOVAN_ORTHODOX_CHURCH: "Молдовська православна церква",
                LATVIAN_ORTHODOX_CHURCH: "Латвійська православна церква",
                ESTONIAN_ORTHODOX_CHURCH_OF_THE_MOSCOW_PATRIARCHATE:
                    "Естонська Православна Церква Московського Патріархату",
                JAPANESE_ORTHODOX_CHURCH: "Японська Православна Церква",
                CHINESE_AUTONOMOUS_ORTHODOX_CHURCH:
                    "Китайська автономна православна церква",
                BELARUSIAN_EXARCHATE: "Білоруський екзархат",
                ORTHODOX_OHRID_ARCHBISHOPRIC:
                    "Православне Охридське архієпископство",
                BESSARABIAN_METROPOLITANATE: "Бесарабська митрополія",

                ARMENIAN_APOSTOLIC_CHURCH: "Вірменська апостольська церква",
                COPTIC_ORTHODOX_CHURCH: "Коптська православна церква",
                ETHIOPIAN_ORTHODOX_CHURCH: "Ефіопська православна церква",
                SYRIAC_ORTHODOX_CHURCH: "Сирійська православна церква",
                MALANKARA_ORTHODOX_SYRIAN_CHURCH:
                    "Індійська Малабарська православна церква",
                ERITREAN_ORTHODOX_TEWAHEDO_CHURCH:
                    "Еритрейська православна церква",
                MOTHER_SEE_OF_HOLY_ETCHEMIADZIN:
                    "Материнська єпархія святого Ечміадзина",
                ARMENIAN_PATRIARCHATE_OF_JERUSALEM:
                    "Єрусалимський вірменський патріархат",
                ARMENIAN_PATRIARCHATE_OF_CONSTANTINOPLE:
                    "Константинопольський вірменський патріархат",
                CILICIAN_ARMENIAN_CATHOLICATE:
                    "Кілікійський католикосат Вірменської апостольської церкви",

                ASSYRIAN_CHURCH_OF_THE_EAST: "Ассирійська Церква Сходу",
                ANCIENT_CHURCH_OF_THE_EAST: "Давня церква Сходу",

                LUTHERANISM: "Лютеранство",
                ANGLICANISM: "Англіканство",
                CALVINISM: "Кальвінізм",
                ANABAPTISM: "Анабаптизм",
                BAPTISM: "Баптизм",
                EVANGELICALISM: "Євангелізм",
            },
            fields: {
                name: "Назва храму",
                religionId: "Релігія",
                religionCurrentId: "Течія",
                religionSubspeciesId: "Підвид",
                country: "Країна",
                city: "Місто",
                info: "Інформація про храм",
                abbotInfo: "Інформація про настоятеля",
                shopInfo: "Інформація до магазину",
                worshipLink: "Посилання на онлайн богослужіння",
                tourLink: "Посилання на 3D тур",
                contactCountry: "Контакти: країна",
                contactCity: "Контакти: місто",
                contactStreet: "Контакти: вулиця",
                contactBuildingNumber: "Контакти: номер будинку",
                contactPhone: "Контакти: телефон",
                contactSecondPhone: "Контакти: другий телефон",
                contactEmail: "Контакти: пошта",
                contactInfo: "Контакти: додаткова інформація",
                header: "Заголовок",
                body: "Інформаційний текст",
                fullName: "Повне ім'я",
                iban: "IBAN",
                accountId: "Номер рахунку",
                currency: "Валюта",
                paymentMethod: "Спосіб переказу",
                recipientName: "Ім'я отримувача",
                defaultPaymentAccount: "Платіжна система за замовчуванням",
                order: "Порядок",
                type: "Тип",
                period: "Період",
                maxPeople: "Максимальна кількість людей",
                price: "Ціна",
                mondayBegin: "Понеділок, початок",
                mondayEnd: "Понеділок, кінець",
                tuesdayBegin: "Вівторок, початок",
                tuesdayEnd: "Вівторок, кінець",
                wednesdayBegin: "Середа, початок",
                wednesdayEnd: "Середа, кінець",
                thursdayBegin: "Четвер, початок",
                thursdayEnd: "Четвер, кінець",
                fridayBegin: "П'ятниця, початок",
                fridayEnd: "П'ятниця, кінець",
                saturdayBegin: "Субота, початок",
                saturdayEnd: "Субота, кінець",
                sundayBegin: "Неділя, початок",
                sundayEnd: "Неділя, кінець",
                useStandardPrayersInfo:
                    "Використовувати стандартний текст (молитви)",
                useStandardTempleSupportInfo:
                    "Використовувати стандартний текст (підтримка храму)",
                templeSupportInfo: "Інформація про підтримку храму",
                prayersInfo: "Інформація про молитви",
                useStandardPrayersEmail:
                    "Використовувати стандартний текст для email (молитви)",
                useStandardTempleSupportEmail:
                    "Використовувати стандартний текст для email (підтримка храму)",
                templeSupportEmail: "Email про підтримку храму",
                prayersEmail: "Email про молитви",
                isDeleted: "Видалено",
                files: "Зображення",
                abbotInfoFiles: "Зображення настоятеля",
                shopInfoFiles: "Зображення магазину",
                galleryFiles: "Галерея",
                date: "Дата",
                begin: "Початок",
                end: "Кінець",
                email: "Email",
                status: "Статус",
                phoneNumber: "Номер телефону",
                comment: "Коментар",
                language: "Мова",
                createdAt: "Дата створення",
                updatedAt: "Дата оновлення",
            },
            entity: {
                [Entity.TEMPLE]: "Храм",
                [Entity.ARTICLE]: "Стаття",
                [Entity.ARTIFACT]: "Артефакт",
                [Entity.BANK_ACCOUNT]: "Банківські дані",
                [Entity.PAYMENT_ACCOUNT]: "Платіжні системи",
                [Entity.PRAYER]: "Молитви",
                [Entity.PRAYER_PRICE_DETAIL]: "Деталі ціни молитов",
                [Entity.PRAYER_REQUIREMENT]: "Молитовні вимоги",
                [Entity.WEEK_SCHEDULE]: "Тижневий розклад",
                [Entity.WORSHIP_SCHEDULE]: "Розклад богослужінь",
            },
            templeSortFields: {
                [TempleSortFields.NAME]: "За алфавітом",
                [TempleSortFields.CREATED_AT]: "За датою реєстрації",
                [TempleSortFields.POPULAR]: "За популярністю",
                [TempleSortFields.VISITS]: "За відвідуваністю",
            },
            section: {
                1: "Головне",
                2: "Настоятель святині",
                3: "Магазин при святині",
                4: "Додаткова інформація про святиню",
                5: "Замовлення молитов",
            },
            payments: {
                paymentFailedToCreate: "Платіж не створено.",
                paymentFailed: "Виникла помилка при створенні платежу.",
                paymentCreated:
                    "Платіж створено але не завершено. Зверніться до Адміністратора для перевірки стану платежу.",
                paymentWasNotCreated: "Платіж не створено. Спробуйте ще раз.",
            },
            prayer: {
                type: {
                    1: "За здоров'я",
                    2: "За упокій",
                },
                period: {
                    1: "Одноденні",
                    2: "На тривалий термін",
                },
            },
            weekDays: {
                [Week.MONDAY]: "Понеділок",
                [Week.TUESDAY]: "Вівторок",
                [Week.WEDNESDAY]: "Середа",
                [Week.THURSDAY]: "Четвер",
                [Week.FRIDAY]: "П'ятниця",
                [Week.SATURDAY]: "Субота",
                [Week.SUNDAY]: "Неділя",
            },
            shortWeekDays: {
                [Week.MONDAY]: "Пн",
                [Week.TUESDAY]: "Вт",
                [Week.WEDNESDAY]: "Ср",
                [Week.THURSDAY]: "Чт",
                [Week.FRIDAY]: "Пт",
                [Week.SATURDAY]: "Сб",
                [Week.SUNDAY]: "Нд",
            },
            user: {
                header: {
                    search: "Пошук...",
                    religions: "Святині",
                    orderPrayer: "Замовити молитву",
                    support: "Підтримка святинь",
                    temple: "Онлайн богослужіння",
                },
                footer: {
                    pages: "Сторінки:",
                    main: "Головна",
                    religions: "Релігії",
                    popular: "Святині",
                    about: "Про проєкт",
                    temple: "Онлайн богослужіння",
                    orderPrayer: "Замовлення молитви",
                    offering: "Підтримка святинь",
                    support: "Підтримати проєкт",
                    templeRepresentatives: "Представникам храму",
                    confidentiality: "Умови конфіденційності",
                    rules: "Правила користування",
                    fullVersion: "Повна версія сайту",
                },
                main: {
                    top: {
                        current: "Конфесія:",
                        place: "Місцезнаходження:",
                    },
                    holy: {
                        title: "Всі святині в одному місці.",
                        subtitle1: "Відвідування святинь",
                        text1: "Наш проект (назва проекту) дарує Вам унікальну можливість в одному місці, в будь-який час та з будь-якої точки світу відвідати відомі релігійні святі місця та скористатися їх послугами.",
                        text2: "Ми бажаємо, щоб Ваш візит був не лише максимально цікавим, а й дарував щастя, радість та духовне задоволення Вам та Вашим близьким!",
                        text3: "Більшість серед представлених на сайті святинь є також діючими релігійними об’єктами, що проводять богослужіння.",
                        text4: "Ви можете замовити молитви, треби, меси, богослужіння чи скористатись іншими послугами вибраної Вами святині, і Ваші побажання, прохання та звернення прозвучать в ній, навіть якщо Ви знаходитесь за тисячі кілометрів від неї.",
                        subtitle2: "Онлайн Богослужіння",
                        text5: "Проект надає можливість не лише дистанційно відвідати та скористатися послугами різних релігійних об’єктів, а й безпосередньо приймати участь у процесах та богослужіннях, що в них проходять.",
                        text6: "Незважаючи на обставини та де б ви не знаходились, Ви маєте унікальну можливість бути присутніми в таких дійствах користуючись послугою Онлайн богослужінь.",
                    },
                    order: {
                        clickLabel: " у три кліки",
                        selectReligion: "Оберіть релігію",
                        selectTemple: "Оберіть святиню",
                        orderPrayer: "Замовте молитву",
                    },
                    popular: {
                        title: "Популярні святині",
                        religion: "Релігія: ",
                        city: "Місто: ",
                        details: "Детальніше",
                    },
                },
                religions: {
                    redirectPages: {
                        [Page.TEMPLE]: "",
                        [Page.CHOOSE_TEMPLE]: "",
                        [Page.ORDER_PRAYER]:
                            "Оберіть святиню, в якій Ви бажаєте замовити молитву",
                        [Page.SUPPORT]:
                            "Оберіть святиню, якій бажаєте спрямувати пожертву",
                        [TempleSearchParameters.IS_WORSHIP_LINK_EXIST]:
                            "Оберіть святиню для перегляду онлайн богослужіння",
                    },
                    base: {
                        religion: "Релігія",
                        current: "Течія",
                        subspecies: "Підвид",
                    },
                    filter: {
                        title: "Вибір святині",
                        searchButton: "Пошук",
                        resetButton: "Скинути фільтир",
                    },
                    header: {
                        title: "Всі Святині, де Ви можете замовити молитву",
                        sort: "Сортувати",
                        abc: "Алфавіт",
                        popular: "Популярність",
                        visits: "Відвідуваність",
                        date: "Дата реєстрації",
                        choose: "Вибір святині",
                        country: "Країна",
                        city: "Місто",
                        other: "Інше",
                        withoutTemplesTitle: "Храми для відображення відсутні",
                    },
                    row: {
                        location: "Розташування:",
                    },
                },
                temple: {
                    abbot: {
                        subtitle: "Настоятель святині",
                        moreInfo: "Читати більше",
                    },
                    basics: {
                        subtitle: "Основні святині",
                    },
                    contacts: {
                        subtitle: "Контакти",
                        office: "Канцелярія",
                        additional: "Паломницький відділ (поїздки, екскурсії):",
                        phone: " - основний",
                        secondPhone: " - додатковий",
                        email: " - Електронна пошта",
                    },
                    information: {
                        subtitle: "Інформація для відвідувачів",
                        schedule: "Графік Роботи",
                        worship: "Розклад богослужінь",
                        shop: "Церковна лавка",
                        visitors: "Відвідувачам",
                        other: "Інше",
                    },
                    main: {
                        location: "Розташування:",
                        religion: "Релігія:",
                        current: "Течія:",
                        subspecies: "Підвид:",
                        moreInfo: "Читати більше",
                        supportButton: "Підтримка святині",
                        orderPrayerButton: "Замовити молитву",
                    },
                    online: {
                        subtitle: "Онлайн богослужіння",
                    },
                    sidebar: {
                        main: "Про святиню",
                        abbot: "Настоятель святині",
                        basic: "Основні святині",
                        info: "Інформація для відвідувачів",
                        support: "Замовлення молитов",
                        online: "Онлайн богослужіння",
                        online3d: "3D Тур",
                        gallery: "Фотогалерея",
                        contacts: "Контакти",
                    },
                    support: {
                        subtitle1: "Замовлення молитов та підтримка святині",
                        subtitle2: "Замовлення молитов",
                        subtitle3: "Підтримка святині",
                        orderPrayerButton: "Замовити молитву",
                        supportButton: "Підтримка святині",
                        text1: "Якщо Ви не можете відвідати Святиню, але хочете замовити молитву за Ваших близьких та рідних – Ви можете зробити це нижче, надіславши записку з сайту.",
                        text2: "Нижче Ви можете зробити свій внесок і підтримати близьку душу та серцю Святиню, більшість з яких існують завдяки Вашим пожертвуванням. Будь-який Ваш внесок буде безцінним!",
                    },
                    worships: {
                        day: "День",
                        date: "Дата",
                        hour: "Година",
                        worship: "Богослужіння",
                        worshipsNotFound: "Богослужінь не знайдено",
                        backToCurrentWeek: "Повернутися до поточного тижня",
                    },
                },
                prayer: {
                    successModal: {
                        title1: "Записка ",
                        title2: " успішно замовлена. Дякуємо Вам!",
                    },
                    accordion: {
                        button: "Замовлення молитов",
                        subtitle1: "Одноденні записки",
                        subtitle2: "Записки на довгий період",
                        details: {
                            people1: "до",
                            people2: "імен",
                        },
                    },
                    header: {
                        subtitle: "Замовлення молитви",
                        text: "Любі друзі! Якщо Ви не можете відвідати Святиню, але хочете замовити молитву за Ваших близьких та рідних – Ви можете зробити це нижче, надіславши записку з сайту.",
                    },
                    item: {
                        namePlaceholder: "Ім'я",
                    },
                    payer: {
                        prayerNoteName: "Назва записки",
                        addButton: "Добавити ім’я",
                        email: "Ваш E-mail",
                        fullName: "Ваше ПІБ",
                        text: "Ваша примітка",
                        agreement1: "Погоджуюсь із ",
                        agreement2:
                            "Умовами конфіденційності, Правилами користування",
                        agreement3:
                            " і даю згоду на обробку персональних даних.",
                        prayerButton: "Замовити",
                    },
                },
                support: {
                    successModal: {
                        title1: "Допомога ",
                        title2: "святині ",
                        title3: "платформі",
                        title4: " успішно перерахована. Дякуємо Вам!",
                    },
                    temple: {
                        title: "Підтримка святині",
                        text: "Любі друзі! Користуючись нашим сайтом Ви можете зробити свій внесок і підтримати близьку Вашим релігійним і духовним уподобанням Святиню, більшість з яких існують завдяки Вашим пожертвуванням. Будь-який Ваш внесок буде безцінним!",
                    },
                    project: {
                        title: "Підтримати проект",
                        text: "Любі друзі! Реалізація даного проекту покликана робити людей щасливими, і надати кожному можливість дарувати щастя іншим! Ми будемо раді, якщо розділяючи наші прагнення Ви підтримаєте проект своїми пожертвуваннями, які Вам під силу. Підтримуючи проект - Ви інвестуєте в щастя!",
                    },
                    offeringText:
                        "Вибравши валюту та ввівши в поле нижче суму і натиснувши кнопку «Пожертвувати», Ви перейдете до зручного для Вас способу перерахування.",
                    offeringButton: "Пожертвувати",
                    fullNamePlaceholder: "ПІБ",
                },
                about: {
                    title: "Про проєкт",
                    text1: "Сучасний світ розвивається стрімкими темпами, вносячи свої корективи у життя кожного з нас. Щоденна метушня відбирає у нас все більше вільного часу, і все менше його залишається для особистих потреб.",
                    text2: "У кругообігу життя ми часом забуваємо не тільки про рідних, друзів і близьких, але іноді зовсім забуваємо і про необхідність гармонії власної душі та тіла. Такі умови мінімізують духовну гармонію та її розвиток, а інколи, для нього зовсім не залишається місця…",
                    text3: "Саме для вирішення таких викликів і створено проект Holly Place Visit.",
                    text4: " Метою проекту ______ є, максимально використовуючи сучасні тенденції та технологій, мінімізувати їх негативні аспекти. Іншими словами, _____ використовуючи сучасні технології, збільшує можливості та спрощує доступ до відомих Святинь та їх послуг.",
                    text5: "Проект ___ покликаний надавати широкий спектр можливостей, основними з яких є:",
                    text6: "ознайомлення з інформацією про будь-яку Святиню, яку надали її представники;",
                    text7: "у режимі реального часу бути присутнім на духовних процесіях Святинь з використанням функції «Онлайн Богослужіння»;",
                    text8: "у режимі «3D туру» дистанційно відвідувати відомі Святині; ",
                    text9: "за допомогою функції «Замовити молитву», Ви можете замовити молитви, треби, меси, богослужіння або скористатися іншими послугами обраної Вами Святині, і Ваші побажання, прохання та звернення пролунають у ній, навіть якщо Ви знаходитесь за тисячі кілометрів від неї.",
                    text10: "також Ви можете зробити свій внесок і направити пожертвування на підтримку та розвиток близької душі та серцю Святині, скориставшись функцією «Підтримка Святинь».",
                    text11: "Проект _____ є відкритим для всіх Святинь і ми сподіваємося, що він стане місцезнаходженням гармонії для кожного його відвідувача!",
                    text12: "Holly Place Visit - Відомі святині, в одному місці, з будь-якої точки світу!",
                },
                faq: {
                    footerButton: "Support",
                    title: "Найпоширеніші запитання",
                    title2: "Відповіді",
                    about: {
                        aboutSubtitle: "Про проект",
                        aboutQuestion1: "Що таке Holy Place Visit?",
                        aboutAnswer1:
                            "Holy Place Visit - це інтерактивна спеціалізована Інтернет платформа, що дає змогу в дистанційному форматі ознайомиться з різноманітними релігійними святинями світу, а також скористатися їхніми послугами.",
                        aboutQuestion2:
                            "Які можливості надає проект для користувача?",
                        aboutAnswer2_1:
                            "Проект покликаний надавати широкий спектр можливостей, основними з яких є:",
                        aboutAnswer2_2:
                            "ознайомлення з інформацією про будь-яку Святиню, якою поділилися її представники;",
                        aboutAnswer2_3:
                            "у режимі реального часу бути присутнім на духовних процесіях Святинь із використанням функції «Онлайн Богослужіння»;",
                        aboutAnswer2_4:
                            "у режимі «3D туру» дистанційно відвідувати відомі Святині;",
                        aboutAnswer2_5:
                            "за допомогою функції «Замовити молитву», замовити молитви, треби, меси, богослужіння чи скористатись іншими послугами обраної Вами Святині, і Ваші побажання, прохання і звернення пролунають у ній, навіть якщо Ви перебуваєте за тисячі кілометрів від неї;",
                        aboutAnswer2_6:
                            "також Ви можете зробити свій внесок і спрямувати пожертви на підтримку та розвиток близької душі та серцю Святині, скориставшись функцією «Підтримка Святинь».",
                        aboutQuestion3:
                            "Які можливості проект надає Святиням та їхнім Представникам?",
                        aboutAnswer3_1:
                            "Представникам Святинь проект пропонує унікальну можливість зареєструвати Святиню на сайті.",
                        aboutAnswer3_2:
                            "Це дасть змогу в доступній формі максимально поширити інформацію про Святиню, якнайбільше збільшити її популярність, розширити кількість парафіян і послідовників.",
                        aboutAnswer3_3:
                            "Використовуючи сучасні технології, платформа дає можливість Святиням дистанційно надавати всі наявні послуги, включно з платними, а також отримувати пожертви.",
                        aboutQuestion4: "Як скористатися послугами проекту?",
                        aboutAnswer4_1:
                            "Для Користувачів спочатку доступні всі можливості проекту.",
                        aboutAnswer4_2:
                            "Для використання проекту в якості Представника Святині передбачена процедура попередньої реєстрації (створення профілю).",
                        aboutQuestion5:
                            "Що таке Умови надання послуг та Політика конфіденційності?",
                        aboutAnswer5:
                            "Умови надання послуг (Умови використання Сайту) та Політика конфіденційності - це узагальнене зведення його правил та умов, ознайомлення та згода з якими надає Вам право користуватися послугами проекту.",
                    },
                    gettingStarted: {
                        gettingStartedSubtitle: "Початок роботи",
                        gettingStartedQuestion1:
                            "Чи потрібна мені реєстрація для використання проекту?",
                        gettingStartedAnswer1_1:
                            "Ми з повагою ставимося до релігійних почуттів усіх громадян і розуміємо їхні бажання щодо конфіденційності, тому для максимального комфорту Користувачам проекту доступні всі його можливості з першої секунди використання і без будь-якої реєстрації.",
                        gettingStartedAnswer1_2:
                            "Для використання проекту в якості Представника Святині передбачена процедура попередньої реєстрації (створення профілю).",
                        gettingStartedQuestion2:
                            "Чи можу я стати Представником Святині, і які до них вимоги?",
                        gettingStartedAnswer2:
                            "Представнику Святині надається можливість створювати і редагувати профіль Святині на сайті. Тому, Представником Святині може бути тільки особа, яка має відповідні повноваження.",
                        gettingStartedQuestion3:
                            "Як знайти потрібну Святиню на сайті?",
                        gettingStartedAnswer3_1:
                            "На сайті розміщені сторінки тільки тих Святинь, Представники яких подали відповідну заявку на реєстрацію в проекті, і яка була схвалена.",
                        gettingStartedAnswer3_2:
                            "З переліком зареєстрованих Святинь можна ознайомитися в розділі «Святині» або «Релігії».  Святиню, що цікавить, можна відшукати в цих розділах за допомогою наявної системи фільтрів, або скориставшись системою пошуку сайту.",
                    },
                    registration: {
                        registrationSubtitle:
                            "Регістрація Святині (для Представників Святині)",
                        registrationQuestion1:
                            "Хто може реєструвати Святиню на сайті?",
                        registrationAnswer1:
                            "Реєструвати Святиню на сайті може тільки її Представник - особа, яка має відповідні повноваження.",
                        registrationQuestion2:
                            "Які вимоги до Представника Святині?",
                        registrationAnswer2_1:
                            "Представником Святині може бути тільки особа, яка має достатню кількість повноважень для представлення Святині в проекті, внесення та редагування інформації про неї на сайті.",
                        registrationAnswer2_2:
                            "Підтвердження наявності таких повноважень, їх повнота та актуальність можуть бути затребувані представниками проекту HolyPlaceVisit у визначеній ними формі та спосіб, у будь-який час з моменту подачі заявки на участь у проекті і до моменту закінчення участі в ній.",
                        registrationQuestion3: "Хто такий Представник Храму?",
                        registrationAnswer3:
                            "На сайті можуть зустрічатися кнопки зі словосполученням «Представник Храму», функціонал і значення яких ідентичне з поняттям «Представник Святині».",
                        registrationQuestion4:
                            "Як зареєструвати Святиню на сайті, або подати заявку на реєстрацію в проекті як Представника Святині?",
                        registrationAnswer4_1:
                            "Реєструвати Святиню на сайті може тільки її Представник - особа, яка має відповідні повноваження.",
                        registrationAnswer4_2:
                            "Для подачі заявки на реєстрацію в проекті Представник Святині повинен пройти процедуру реєстрації та заповнити необхідну інформацію про Святиню, зазначену в реєстраційній формі.",
                        registrationAnswer4_3:
                            "Наразі реєстрація Святинь на сайті є безкоштовною.",
                        registrationQuestion5:
                            "Яку інформацію необхідно подати для реєстрації Святині в проекті?",
                        registrationAnswer5_1:
                            "Внесення інформації про Святиню здійснюється Представником Святині за встановленою проектом реєстраційною формою.",
                        registrationAnswer5_2:
                            "Реєстраційна форма містить обов'язкові та необов'язкові для заповнення розділи. Мінімальною вимогою для подання заявки на участь у проекті є заповнення всіх обов'язкових розділів реєстраційної форми. Для більш успішного використання сторінки Святині на сайті ми рекомендуємо вказати якомога більше інформації про неї.",
                        registrationQuestion6:
                            "Пожертвування та підтримка Святинь, надання ними платних послуг.",
                        registrationAnswer6_1:
                            "Проект дає можливість Святиням отримувати винагороду за надання ними послуг, оформлених через сайт.  Опис таких послуг, вимоги до них та їхня вартість визначаються Представниками Святинь.",
                        registrationAnswer6_2:
                            "Платформа також дозволяє Святиням отримувати фінансові пожертви.",
                        registrationAnswer6_3:
                            "Пожертви та кошти за платні послуги надходять на зазначені Представником Святині реквізити.",
                        registrationQuestion7:
                            "Який період розгляду заявки на участь у проекті Святині?",
                        registrationAnswer7:
                            "Якщо Представник Святині та надіслана ним заявка відповідають необхідним вимогам - заявку буде схвалено найближчим часом.",
                        registrationQuestion8:
                            "Як дізнатися, що заявку на реєстрацію Святині схвалено? ",
                        registrationAnswer8:
                            "Після схвалення заявки на реєстрацію Святині в проекті, її сторінка буде доступною для Користувачів сайту. З переліком зареєстрованих Святинь можна ознайомитися в розділі «Святині» або «Релігії». Святиню, що цікавить, можна відшукати в цих розділах за допомогою наявної системи фільтрів, або скориставшись системою пошуку сайту.",
                    },
                    services: {
                        servicesSubtitle: "Послуги для користувачів проекту",
                        servicesQuestion1:
                            "З якою інформацією про Святині можна ознайомитися на сайті?",
                        servicesAnswer1_1:
                            "На сторінці Святині відображається тільки та інформація, яка була надана її Представником.",
                        servicesAnswer1_2:
                            "Проєкт передбачає наявність обов'язкових інформаційних розділів, як-от назва Святині, її опис, інформацію про настоятеля, графіки роботи та богослужінь, послуги, що надаються, та іншу корисну інформацію для відвідувачів, проте кінцевий обсяг і зміст інформації сторінки Святині визначається її Представником.",
                        servicesQuestion2: "Що таке «Онлайн богослужіння»?",
                        servicesAnswer2_1:
                            "Це наданий проектом функціонал, який з використанням сучасних технологій дає можливість Користувачеві в режимі реального часу бути присутнім на духовних процесіях Святинь.",
                        servicesAnswer2_2:
                            "Цей функціонал є опціональним, а можливість його надання Користувачам визначається Представником Святині. Перелік усіх Святинь, що дають змогу використовувати функцію «Онлайн богослужіння», можна знайти в розділі з однойменною назвою.",
                        servicesQuestion3: "Що таке 3D тур?",
                        servicesAnswer3_1:
                            "Це наданий проектом функціонал, який дає можливість Користувачеві дистанційно відвідувати територію відомих Святинь з ефектом максимальної присутності.",
                        servicesAnswer3_2:
                            "Цей функціонал є опціональним і можливість його надання Користувачам визначається Представником Святині.",
                        servicesQuestion4:
                            "Що передбачає функція «Замовити молитву»?",
                        servicesAnswer4_1:
                            "За допомогою функції «Замовити молитву» Ви може замовити молитви, треби, меси, богослужіння або скористатися іншими послугами обраної Святині, і Ваші побажання, прохання і звернення пролунають у ній, навіть якщо Ви перебуваєте за тисячі кілометрів від неї.",
                        servicesAnswer4_2:
                            "Зміст таких послуг, вимоги до них та їхня вартість визначаються Представниками Святинь.",
                        servicesAnswer4_3:
                            "Замовити послугу можна на відповідному розділі Святині, що Вас цікавить, в якому міститься форма і вимоги до оформлення замовлення. Перейти на цю сторінку сайту можна натиснувши на кнопку «Замовити молитву» на сторінці обраної Вами Святині.",
                        servicesAnswer4_4:
                            "Перелік усіх Святинь, які дають змогу використовувати функцію «Замовити молитву», можна знайти в розділі з однойменною назвою на головній сторінці сайту.",
                        servicesQuestion5:
                            "Що передбачає функція «Підтримка святинь»?",
                        servicesAnswer5_1:
                            "Проект передбачає можливість зробити свій внесок і спрямувати пожертви на підтримку і розвиток близької душі та серцю Святині, скориставшись функцією «Підтримка Святинь».",
                        servicesAnswer5_2:
                            "Сьогодні більшість Святинь існують завдяки Вашим пожертвам, і будь-який Ваш внесок для них буде безцінним!",
                        servicesAnswer5_3:
                            "Замовити послугу можна на відповідному розділі Святині, що Вас цікавить, в якому міститься форма і вимоги до оформлення замовлення. Перейти на цю сторінку сайту можна натиснувши на кнопку «Підтримка Святині» на сторінці обраної Вами Святині.",
                        servicesAnswer5_4:
                            "Перелік усіх Святинь, що дають змогу використовувати функцію «Підтримка Святині», можна знайти в розділі з однойменною назвою на головній сторінці сайту.",
                    },
                    cooperation: {
                        cooperationSubtitle:
                            "Співпраця, пропозиції та усунення несправностей",
                        cooperationQuestion1:
                            "Куди я можу надіслати свої пропозиції щодо роботи проекту?",
                        cooperationAnswer1_1:
                            "Ми прагнемо стати кращими і завжди будемо раді почути будь-які коментарі або зауваження, що сприятимуть поліпшенню роботи проекту! Ваші звернення з цього питання просимо надсилати на нашу адресу, скориставшись формою зворотного зв'язку із зазначенням у темі листа «Пропозиції».",
                        cooperationAnswer1_2:
                            "Перейти на форму зворотного зв'язку можна шляхом натискання на кнопку «Support» у нижній частині головної сторінки сайту.",
                        cooperationQuestion2:
                            "Як усунути неполадки або зв'язатися з підтримкою сайту?",
                        cooperationAnswer2_1:
                            "Наші співробітники постійно працюють над помилками, які можуть виникати в процесі роботи, і методами їх усунення. Для надання допомоги в цьому питанні, а також, у разі виникнення у Вас будь-яких неполадок у процесі роботи, просимо написати нам, скориставшись формою зворотного зв'язку, із зазначенням у темі листа «Усунення несправностей».",
                        cooperationAnswer2_2:
                            "Перейти на форму зворотного зв'язку можна шляхом натискання на кнопку «Support» у нижній частині головної сторінки сайту. ",
                        cooperationAnswer2_3:
                            "Популярні звернення, пов'язані з неполадками в роботі, а також методи їх усунення, будуть опубліковані в цьому розділі.",
                        cooperationQuestion3:
                            "Як я можу надіслати пропозиції про співпрацю? ",
                        cooperationAnswer3_1:
                            "Метою проекту є підтримка та популяризація світових Святинь. З реалізацією проекту ми сподіваємося хоча б трошки зробити світ кращим, добрішим і спокійнішим!",
                        cooperationAnswer3_2:
                            "Проект є відкритим для всіх Святинь, і ми сподіваємося, що він стане місцем набуття гармонії для кожного його відвідувача!",
                        cooperationAnswer3_3:
                            "Якщо Ваші цілі збігаються з нашими, і Ви маєте цікаві пропозиції щодо співпраці - просимо надіслати їх на нашу адресу, скориставшись формою зворотного зв'язку, обравши «Співпраця» в темі звернення.",
                        cooperationAnswer3_4:
                            "Перейти на форму зворотного зв'язку можна шляхом натискання на кнопку «Support» у нижній частині головної сторінки сайту.",
                    },
                },
            },
            currency: {
                [Currency.USD]: "USD",
                [Currency.EUR]: "EUR",
                [Currency.PLN]: "PLN",
            },
            payoutStatus: {
                [PayoutStatus.IN_REVIEW]: "Очікує",
                [PayoutStatus.CANCELED]: "Скасована",
                [PayoutStatus.EXECUTED]: "Виконана",
            },
            paymentMethod: {
                1: "Pay Pal",
                2: "Payoneer",
                3: "Skrill",
            },
            transactionType: {
                1: "Платіж",
                2: "Пожертвування",
            },
            base: {
                buttons: {
                    refreshPage: "Оновити сторінку",
                },
                photo: {
                    remove: "Видалити",
                },
                successPaymentModal: {
                    moveToTemple: "Повернутися до святині",
                },
                truncateText: {
                    moreInfo: "Читати більше",
                    lessInfo: "Читати менше",
                },
                pagination: {
                    itemsPerPage: "Елементів на сторінці",
                    page: "Сторінка",
                    of: "з",
                },
            },
            templeRegister: {
                correspondence: {
                    title: "Кореспонденція",
                    subtitle: "Мова кореспонденції",
                    instructionText:
                        "<p>Введіть електронну адресу для отримання повідомлень від платформи.</p><p>Ця адреса буде використовуватися для повідомлень про надходження пожертв та замовлення молитв від користувачів.</p><p>Ця адреса є обов'язковою для завершення реєстрації храму.</p>",
                    instructionTextLanguage: "Вибрана мова буде використовуватися для формування текстів email повідомлень, які будуть надходити на вказану вище email-адресу для кореспонденції.",
                    saveEmailButtonLabel: "Відправити лист підтвердження",
                    successEmailModalText:
                        "Лист підтвердження надіслано на пошту",
                },
                base: {
                    title: "Дані",
                    title1: "Додаткова інформація ",
                    title2: "про храм",
                    addPhoto: "Додати фото",
                },
                footer: {
                    button: "Зберегти",
                    cancelEntity: "Скасувати",
                    cancelButton: "Вихід",
                    cancelModal: {
                        exit: "Вийти",
                        stay: "Залишитися",
                        title: "Ви підтверджуєте вихід з процесу реєстрації храму? Не збережені зміни будуть втрачені.",
                    },
                },
                required: {
                    artifacts: "Додайте Основні святині установи",
                    prayers:
                        "Додайте хоча б одну Записку та Побажання до записок",
                    paymentDetails: "Додайте платіжні системи",
                    bankDetails: "Додайте банківські реквізити",
                },
                mainInfo: {
                    titleFirst: "Заповніть дані про храм ",
                    titleSecond: "та додайте фото*",
                    templeName: "Назва святині",
                    country: "Країна",
                    city: "Місто",
                    templeInfo: "Інформація про святиню",
                    addPhoto: "Додати фото",
                    photoInfo: "Перше фото буде головним на сторінці святині",
                    religionPlaceholder: "Релігія",
                    currentPlaceholder: "Течія",
                    subspeciesPlaceholder: "Підвид",
                    customSearchPlaceholder: "Введіть текст для пошуку",
                    onlineWorshipPresence: "Наявність онлайн богослужіння",
                },
                abbot: {
                    subtitle: "Настоятель святині*",
                    infoPlaceholder: "Інформація про настоятеля",
                    label1: "Перше фото буде головним для представителя",
                    subtitle2: "Основні святині установи*",
                    namePlaceholder: "Назва святині",
                    infoPlaceholder2: "Коротка інформація",
                    addShrine: "Додати святиню",
                    abbotInfoTooltip: "Інформація про настоятеля",
                },
                bank: {
                    title: "Банківські реквізити",
                    fullName: "ФІО",
                    account: "Рахунок одержувача",
                    currency: "Валюта рахунку",
                    add: "Додати кількість",
                    iban: "IBAN",
                },
                gallery: {
                    subtitle: "Онлайн Богослужіння",
                    linkPlaceholder: "Посилання на онлайн богослужіння",
                    subtitle2: "3D тур",
                    label1: "Тут ви можете загрузити файл на 3D тур",
                    subtitle3: "Фотогалерея",
                    additionalPhoto1: "Ви можете загрузити додаткові фото ",
                    additionalPhoto2: " свого храму",
                    subtitle4: "Контакти",
                    country: "Введіть країну",
                    city: "Введіть місто",
                    street: "Введіть вулицю",
                    building: "Номер будинку",
                    phone: "Номер телефона",
                    phone2: "Додатковий номер телефона",
                    worshipLinkInstruction: {
                        title: "Як скопіювати посилання на відео з YouTube:",
                        firstStep:
                            "Відкрийте відео на YouTube, яке ви хочете поділитися.",
                        secondStep: 'Натисніть кнопку "Поділитися" під відео.',
                        thirdStep:
                            'У вікні, натисніть на кнопку "Скопіювати" поруч із посиланням на відео.',
                        correctLink:
                            "Перевірте, що автор Вашого посилання надав дозвіл на можливість його перегляду на інших сайтах.",
                        linkExample:
                            "Приклад посилання на відео: https://youtu.be/video-id",
                        finalText:
                            "Тепер посилання на відео скопійовано в буфер обміну і ви можете вставити його в поле нижче.",
                    },
                    tourPreviewButton: "Попередній перегляд",
                    tourInstruction: {
                        title: "Як додати 3D-тур:",
                        info: 'Натисніть "Поділитися" в своєму редакторі онлайн 3D-туру, оберіть "Поділитися за допомогою HTML (Embed code)" та скопіюйте це у поле нижче.',
                        example: "Це може виглядати так:",
                    },
                },
                orders: {
                    title: "Замовлення молитов",
                    subtitle:
                        "Текст-звернення до Відвідувачів для замовлення молитов*",
                    textPlaceholder:
                        "Вступне текстове повідомлення для Відвідувачів з пропозицією замовити молитву (від 30 - 100 символів)",
                    useStandardText: "Використовувати стандартний текст",
                    name: "Назва",
                    type: "Тип",
                    period: "Період",
                    maxPeople: "Людей до:",
                    price: "Ціна",
                    info: "Короткий опис",
                    name2: "Назва записки",
                    maxPeople2: "до 10 людей",
                    price2: "Ціна у доларах",
                    save: "Зберегти записку",
                    label: "Приймаються імена тільки хрещених людей.",
                    subtitle2: "Вимоги та побажання до замовлення молитов*",
                    textPlaceholder2: "Текст побажання (вимоги)",
                    add: "Додати побажання",
                    add2: "Додати записку",
                    subtitle3: "Інформація для замовлення підтримки храму*",
                    textPlaceholder3:
                        "Вступне текстове повідомлення для Відвідувачів з пропозицією підтримати святиню (від 30 - 100 символів)",
                    requiredField:
                        'Треба додати інформацію в поле нижче, або обрати "Використовувати стандартний текст"',
                    subtitle4: "Текст відповіді особі, яка замовила молитву",
                    textPlaceholder4: "Текст відповіді особі, яка замовила молитву",
                    subtitle5: "Текст відповіді особі, яка здійснить пожертву",
                    textPlaceholder5: "Текст відповіді особі, яка здійснить пожертву"
                },
                payment: {
                    title: "Підключення платіжних систем",
                    id: "Ідентифікатор платіжної системи",
                    name: "Ім’я отримувача",
                    currency: "Валюта рахунку",
                    method: "Платіжна система",
                },
                schedules: {
                    title: "Графік роботи",
                    begin: "Початок (гг:мм)",
                    end: "Завершення (гг:мм)",
                    monday: "Понеділок",
                    tuesday: "Вівторок",
                    wednesday: "Середа",
                    thursday: "Четвер",
                    friday: "П'ятниця",
                    saturday: "Субота",
                    sunday: "Неділя",
                    subtitle: "Розклад Богослужінь",
                    name: "Введіть назву Богослужіння",
                    addEvent: "Додати подію",
                },
                visitorsInfo: {
                    subtitle: "Магазин при святині",
                    info: "Коротка інформація",
                    photoInfo: "Перше фото буде головним для представителя",
                    subtitle2: "Паломникам",
                    header: "Заголовок",
                    textPlaceholder:
                        "Наприклад: Місця зупинки, Місця харчування, Екскурсії, тощо.",
                    addInfo: "Додати інформацію",
                    other: "Інше",
                },
                sidebar: {
                    mainInfo: "Основна інформація",
                    abbot: "Настоятель та святині",
                    schedules: "Розклад та графік богослужінь",
                    visitorsInfo: "Інформація для відвідувачів",
                    orders: "Замовлення молитов та пожертви",
                    paymentDetails: "Реквізити платіжних систем",
                    bankDetails: "Банківські реквізити",
                    galleries: "Онлайн богослужіння та галереї",
                    correspondence: "Кореспонденція",
                },
            },
            errors: {
                response: {
                    [ResponseErrors.WRONG_CREDENTIALS]:
                        "Не коректний логін чи пароль",
                    [StatusCodes.CONFLICT]:
                        "Присутні застарілі дані, оновіть сторінку",
                    [StatusCodes.REQUEST_TOO_LONG]:
                        "Неможливо завантажити дані, вміст занадто великий",
                },
                common: "Сталася помилка, спробуйте ще раз",
                commonErrors: {
                    isNumber: "Це повинно бути число",
                    max: "Максимальна довжина - ",
                    min: "Мінімальна довжина - ",
                    required: "Обов'язкове поле",
                    beginLowerThanEnd:
                        "Час початку має бути меншим за час завершення",
                    beginWithEnd: "Час початку має бути із часом завершення",
                    isNotCorrectEmail: "Це не коректний email",
                    isNotCorrectLink: "Це не коректне посилання",
                    invalidPhoneNumber: "Формат номера телефону неправильний",
                    noSpaces: "Не можна використовувати пробіли",
                },
                userLogin: {
                    email: "Це повинен бути email",
                    passwordsNotMatch: "Паролі не співпадають",
                    password: {
                        minSymbols:
                            "Мінімальна кількість спеціальних символів - 1",
                        minLowercase:
                            "Мінімальна кількість символів в нижньому регістрі - 2",
                        minUppercase:
                            "Мінімальна кількість символів в верхньому регістрі - 1",
                        minNumbers: "Мінімальна кількість чисел - 1",
                    },
                },
                photo: {
                    maxSize: "Розмір вибраного файлу перевищує ",
                    acceptType: "Вибраний Вами тип файлу заборонений",
                },
                currencyErrors: {
                    onlyUSD:
                        "На даний момент є можливість додати тільки рахунки в доларах США ($)",
                },
                payouts: {
                    [StatusCodes.BAD_REQUEST]: "Некоректні поля виплати або неактуальна виплата.",
                    [StatusCodes.NOT_FOUND]: "Виплата не знайдена. Перевірте актуальність csv файлу.",
                },
            },
            login: {
                title: "Вхід",
                email: "Логін",
                password: "Пароль",
                notConfirmed:
                    "Будь ласка, підтвердьте свою електронну адресу, щоб увійти",
            },
            register: {
                title: "Реєстрація",
                confirmPassword: "Підтвердження",
                next: "Далі",
                personalTitle: "Заповніть особисті дані",
                name: "Ім’я",
                lastname: "Прізвище (звернення)",
                phone: "Номер телефону",
                temple: "Назва храму",
                successModal: {
                    info: "Для завершення реєстрації перейдіть за посиланням у листі який ми відправили на вказану вами електронну адресу.",
                },
            },
            templeAdmin: {
                footer: {
                    sum: "Сума всього: ",
                },
                header: {
                    edit: "Редагувати сайт",
                    visit: "Відвідати сайт",
                },
                main: {
                    title: "Головна",
                    newTemple: "Додати святиню",
                    activated: "Активовано",
                    inactive: "Неактивний",
                    declined: "Відхилено",
                    edit: "Редагувати",
                    delete: "Видалити",
                    removeTemple: {
                        title: "Ви впевнені що хочете видалити храм?",
                        remove: "Видалити",
                        cancel: "Скасувати",
                    },
                },
                payments: {
                    platformLabel: "Платформа",
                    header: {
                        main: {
                            title: "Платежі",
                            totalCount: "Всього оплат: ",
                            totalAmount: "Всього: ",
                            totalTempleAmount: "Святиня: ",
                            totalPlatformAmount: "Сайт: ",
                            templePaidAmount: "Виплачено святині: ",
                            inReviewAmount: "В роботі: ",
                            income: "Баланс святині: ",
                            resetFilter: "Очистити фільтр",
                        },
                        filter: {
                            filterButton: "Фільтр",
                            applyButtonLabel: "Застосувати",
                            fields: {
                                sender: "Платник",
                                paymentId: "Ідентифікатор",
                                sumFrom: "Сума від:",
                                sumBefore: "Сума до:",
                                status: {
                                    label: "Статус",
                                    value0: "Про здоров'я",
                                    value1: "Про упокій",
                                },
                                dateFrom: "Дата від:     дд/мм/рр",
                                dateBefore: "Дата до:     дд/мм/рр",
                                transactionType: {
                                    label: "Тип транзакції",
                                    value0: "Про здоров'я",
                                    value1: "Про упокій",
                                },
                            },
                        },
                    },
                    table: {
                        labels: {
                            senderName: "ПІБ",

                            id: "Ідентифікатор ",
                            payment: " платежу",

                            name: "Назва ",
                            recipient: " отримувача ",

                            status: "Статус ",
                            paymentDate: "Час надходження ",
                            amount: "Всього ",
                            temple: "Святиня ",
                            site: "Сайт ",

                            type: "Тип ",
                            transaction: " транзакції",
                        },
                    },
                    payment: {
                        senderName: "ПІБ",
                        paymentDate: "Дата надходження",
                        paymentDateConfirmation: "Дата підтвердження",
                        paymentId: "Ідентифікатор платежу",
                        recipientName: "Назва отримувача",
                        transactionType: "Тип транзакції",
                        totalAmount: "Сума всього, $",
                        recipientAmount: "Перераховано отримувачу, $",
                        platformAmount: "Отримав сайт, $",
                        initial: "Було: ",
                        final: "Стало: ",
                        senderEmail: "E-mail платника",
                        paymentMethod: "Платіжна система платника",
                        senderAccountId: "Ідентифікатор платника:",
                        recipientAccountId: "Ідентифікатор отримувача:",
                        manager: "Менеджер",
                        confirmationButtonLabel: "Підтвердити",
                        prayerName: "Назва",
                        openPayoutLabel: "Відкрити Виплату",
                        openCreditsLabel: "Переглянути Кредити",
                    },
                },
            },
            admin: {
                charts: {
                    header: "Графіки",
                    attendance: "Відвідуваність",
                    payments: "Оплати",
                },
                payouts: {
                    label: "Виплати",
                    totalPayouts: "Всього виплат:",
                    downloadCSV: "Експортувати виплати (.csv)",
                    uploadCSV: "Імпортувати виплати (.csv)",
                    successPayoutsCreation: "Виплати створено",
                    successPayoutsResolving: "Виплати завершено",
                    paymentMethodsModal: {
                        title: "Вибрані елементи з різними способами оплати",
                        accept: "Продовжити",
                        cancel: "Скасувати",
                    },
                    nonValid: {
                        title: "Виплати, які неможливо завершити через некоректні дані:",
                        csvOrder: "Номер у таблиці .csv",
                        id: "Ідентифікатор виплати",
                        transactionId: "Ідентифікатор транзакції",
                        status: "Статус виплати",
                        createdAt: "Дата створення виплати",
                        close: "Скасувати",
                        forced: "Продовжити в будь-якому разі",
                    },
                    table: {
                        templeName: "Назва храму",
                        transactionAmount: "Сума, $",
                        transactionPrayersAmount: "Сума платежів, $",
                        transactionDonationsAmount: "Сума пожертв, $",
                        status: "Статус",
                        transactionsCount: "Кількість транзакцій",
                        transactionsPrayersCount: "Кількість платежів",
                        transactionsDonationsCount: "Кількість пожертв",
                        createdAt: "Дата створення",
                        createdByName: "Створив",
                        updatedAt: "Дата оновлення",
                        resolvedByName: "Завершив",
                        transactionId: "ID транзакції платіжної системи",
                        paymentMethod: "Тип платіжної системи",
                        superAdminComment: "Коментар суперадміна",
                        paymentAccountRecipientName: "Ім’я отримувача",
                        paymentAccountAccountId: "Ідентифікатор платіжної системи",
                        paymentAccountCurrency: "Валюта рахунку",
                        actions: {
                            columnLabel: "Дії",
                            executeButtonLabel: "Завершити виплату",
                        },
                    },
                    credits: {
                        label: "Кредити",
                        totalCredits: "Всього кредитів:",
                        table: {
                            templeName: "Назва храму",
                            transactionAmount: "Сума (актуальні транзакції), $",
                            transactionPrayersAmount: "Сума платежів, $",
                            transactionDonationsAmount: "Сума пожертв, $",
                            transactionsCount: "Кількість транзакцій",
                            transactionsPrayersCount: "Кількість платежів",
                            transactionsDonationsCount: "Кількість пожертв",
                            dateOfApprovalLatestPayout:
                                "Дата останньої завершеної виплати",
                            defaultPaymentMethod: "Метод оплати за замовчуванням",
                            activePayouts: "Кількість активних виплат",
                            actions: {
                                columnLabel: "Дії",
                                executeButtonLabel: "Створити виплату",
                            },
                        },
                        modalText1: "Створити виплату для всіх активних транзакцій",
                        title: "Створення виплати",
                        modalText3: "Оберіть реквізити для створення виплати:",
                        modalCreateAndOpenButton: "Створити і відкрити виплату",
                        modalCreateAndReturnButton: "Створити і повернутися до кредитів",
                        modalDiscardButton: "Скасувати",
                        createPayoutsButton: "Створити виплати",
                        amount: "Сума, $",
                    },
                    details: {
                        title: "Деталі виплати",
                        informationTitle: "Інформація",
                        paymentsTitle: "Платежі",
                        finishButton: "Завершити виплату",
                        print: "Роздрукувати",
                        saveAsPDF: "Зберегти як PDF документ",
                    },
                    resolveModal: {
                        transactionId: "ID транзакції платіжної системи",
                        comment: "Коментар",
                        resolveButton: "Завершити",
                    },
                },
                footer: {
                    paymentCount: "Кількість оплат: ",
                    payoutCount: "Кількість виплат: ",
                    pending: "В очікуванні: ",
                    done: "Виконані: ",
                    onAccount: "На рахунку: ",
                },
                header: {
                    searchPlaceholder: "Пошук...",
                    all: "Всього святинь:",
                    dropdownList: {
                        update: "Оновлення",
                        newComplaint: "Нова скарга",
                        admission: "Поступлення",
                    },
                },
                main: {
                    title: "Оновлення",
                    temple: {
                        title: "Храми:",
                        mainView: "дивитися всі",
                        all: "Всього храмів",
                        pending: "В очікуванні",
                        deleted: "Видалено",
                    },
                    payments: {
                        title: "Платежі:",
                        mainView: "дивитися всі",
                        all: "Всього поступило",
                        done: "Виконано",
                        processing: "В обробці",
                        commission: "Комісія сайту",
                        support: "Підтримка проекту",
                    },
                },
                sidebar: {
                    main: "Головна",
                    temples: "Храми",
                    payments: "Платежі",
                    updates: "Історія оновлень",
                    visited: "Статистика",
                    messenger: "Месенджер",
                    graphics: "Графіки",
                    transfers: "Переклади",
                    goOut: "Вийти",
                    customerSupportTickets: "Звернення користувачів",
                    payouts: "Виплати",
                },
                temples: {
                    table: {
                        temple: "Святиня",
                        country: "Країна",
                        religion: "Віросповідання",
                        currents: "Течії",
                        income: "Доходи",
                    },
                    header: {
                        temples: "Храми",
                        total: "Всього храмів: ",
                        clearFilter: "Очистити фільтр",
                    },
                    filter: {
                        temple: "Святиня",
                        country: "Країна",
                        religion: "Віросповідання",
                        current: "Течія",
                        sumFrom: "Сума від:",
                        sumBefore: "Сума до:",
                        applyButton: "Застосувати",
                    },
                    temple: {
                        main: {
                            review: "Перегляд святині",
                            info: "Інформація",
                            payments: "Оплати",
                            activities: "Відвідуваність",
                        },
                        info: {
                            register: "Дата реєстрації",
                            confirm: "Дата підтвердження реєстрації",
                            name: "Назва святині",
                            country: "Країна",
                            city: "Місто",
                            religion: "Віросповідання",
                            current: "Течія",
                            subspecies: "Півдвид",
                            representative: "Представник",
                            phone: "Контакт Представника",
                            email: "E-mail святині",
                            abbot: "Настоятель",
                            payments: "Платіжні системи",
                            paymentsId: "ідентифікатор",
                            bank: "Банківські реквізити ",
                            currency: "Валюта рахунку: ",
                        },
                        payments: {},
                        activities: {},
                    },
                },
                customerSupport: {
                    header: "Звернення користувачів",
                    totalCSTCount: "Всього звернень",
                    table: {
                        ticketName: "Назва",
                        subjectType: "Тема",
                        fullName: "Ім'я користувача",
                        customerRole: "Роль користувача",
                        emailSent: "Email надіслано",
                        isCustomerRegistered: "Користувач авторизований",
                        isEmailRegistered: "Email зареєстрований",
                        createdAt: "Дата створення",
                    },
                    fields: {
                        description: "Звернення",
                    },
                    details: {
                        editButtonLabel: "Змінити статус",
                        sendEmailButtonLabel: "Надіслати Email",
                    },
                    editModal: {
                        title: "Редагувати Звернення",
                    },
                    createTicketForm: {
                        header: "Зв'яжіться з нами",
                        middleName: "По-батькові (не обов'язково)",
                        email: "E-mail (якщо Ви зареєстровані - вкажіть E-mail вказаний при реєстрації)",
                        country: "Країна\\Регіон",
                        customerRole: "Ви звертаєтеся як (виберіть необхідне)",
                        description: "Текст повідомлення",
                        conditionsAccepted:
                            "Поставивши цей прапорець, ви погоджуєтеся з Умовами використання та Політикою конфіденційності сайту, а також даєте згоду зв'язуватися з Вами телефоном та електронною поштою щодо Вашого звернення. Ми будемо обробляти Ваші дані відповідно до нашої Політики конфіденційності.",
                        subjectType: "Оберіть тему питання, яке Вас цікавить",
                        sendButton: "Надіслати",
                        successMessage:
                            "Ваше звернення {{ticketName}} спрямоване в службу підтримки. Ми зв'яжемося з вами за допомогою електронної пошти, яка була вказана при реєстрації звернення.",
                        successTitle: "Звернення створене",
                        successButton: "OK",
                    },
                    faq: {
                        contactUsText: "Залишились запитання?",
                    },
                },
            },
            updates: {
                header: "ІСТОРІЯ ОНОВЛЕННЯ",
                totalUpdates: "Всього оновлень: ",
                inReview: "Запити на оновлення: ",
                table: {
                    temple: "Святиня",
                    statusHeader: "Статус",
                    requestTime: "Час подання заявки",
                    confirmation: "Підтвердження",
                    status: {
                        inReview: "Очікується із ",
                        approved: "Виконано із ",
                        declined: "Відхилено із ",
                    },
                },
                update: {
                    header: "ЗМІНИ",
                    changesCount: "Кількість змін по храмові: ",
                    visitingTemple: "Перегляд святині",
                    current: "Стало",
                    original: "Було",
                    confirmButton: "Підтвердити",
                    approveAllButton: "Прийняти все",
                    declineAllButton: "Скасувати все",
                },
            },
            general: {
                resetFilter: "Очистити фільтр",
                selectRowButton: "Вибрати рядок",
                selectAllRowsButton: "Вибрати всі рядки на сторінці",
            },
            charts: {
                applyButton: "Застосувати",
                compareButton: "Порівняти",
                selectYear: "Виберіть рік",
                selectMonth: "Виберіть місяць",
                selectDay: "Виберіть день",
            },
            enums: {
                supportTicketCustomerRole: {
                    [SupportTicketCustomerRole.GUEST]: "Користувач",
                    [SupportTicketCustomerRole.TEMPLE_ADMIN]:
                        "Представник Храму",
                },
                supportTicketSubject: {
                    [SupportTicketSubject.REGISTRATION]: "Реєстрація",
                    [SupportTicketSubject.ORDER_PRAYER]: "Замовлення молитов",
                    [SupportTicketSubject.SUPPORT_TEMPLE]: "Підтримка Святинь",
                    [SupportTicketSubject.PAYMENTS]: "Платежі",
                    [SupportTicketSubject.OFFER]: "Пропозиції",
                    [SupportTicketSubject.TROUBLESHOOT]:
                        "Усунення несправностей",
                    [SupportTicketSubject.COLLABORATION]: "Співпраця",
                    [SupportTicketSubject.ADVERTIZING]: "Реклама",
                    [SupportTicketSubject.OTHER]: "Інше",
                },
                recordStatus: {
                    [RecordStatus.CREATED]: "Створений",
                    [RecordStatus.APPROVED]: "Підтверджений",
                    [RecordStatus.DECLINED]: "Скасований",
                    [RecordStatus.IN_REVIEW]: "Розглядається",
                },
                language: {
                    [Langs.UA]: "Українська",
                    [Langs.EN]: "Англійська",
                    [Langs.RU]: "Російська",
                }
            },
            confirmation: {
                title: "Підтвердження реєстрації",
                inProcess: "Перевірка реєстрації...",
                error: "Виникла помилка, спробуйте пізніше.",
                expired: "Час реєстрації минув.",
                alreadyExist: "Неможливо повторити операцію підтвердження.",
                success:
                    "Ви успішно підтвердили свою електронну адресу. Для входу в обліковий запис перейдіть за ",
                link: "посиланням",
                subtitle: "Повернутися на головну ",
                subtitleLink: "сторінку",
                correspondenceEmail: {
                    title: "Підтвердження пошти кореспонденції",
                    subtitle: "Повернутися до",
                    subtitleLink: "Адмін-панелі",
                    success:
                        "Ви успішно підтвердили свою електронну адресу. Для повернення до реєстрації святині перейдіть за ",
                },
            },
            months: {
                [Months.JANUARY]: "Січень",
                [Months.FEBRUARY]: "Лютий",
                [Months.MARCH]: "Березень",
                [Months.APRIL]: "Квітень",
                [Months.MAY]: "Травень",
                [Months.JUNE]: "Червень",
                [Months.JULY]: "Липень",
                [Months.AUGUST]: "Серпень",
                [Months.SEPTEMBER]: "Вересень",
                [Months.OCTOBER]: "Жовтень",
                [Months.NOVEMBER]: "Листопад",
                [Months.DECEMBER]: "Грудень",
            },
        },
    },

    en: {
        translation: {
            religions: {
                undefined: "",
                CHRISTIANITY: "Christianity",
            },
            religionCurrents: {
                undefined: "",
                CATHOLICISM: "Catholicism",
                ORTHODOXY: "Orthodoxy",
                MONOPHYSITISM:
                    "Monophysitism (Ancient Eastern Orthodox Churches)",
                NESTORIANISM: "Nestorianism (Assyrian Church of the East)",
                PROTESTANTISM: "Protestantism",
            },
            religionSubspecies: {
                undefined: "",
                ROMAN_CATHOLIC_CHURCH: "Roman Catholic Church",
                ARMENIAN_CATHOLIC_CHURCH: "Armenian Catholic Church",
                UKRAINIAN_GREEK_CATHOLIC_CHURCH:
                    "Ukrainian Greek Catholic Church",
                MUKACHEVO_GREEK_CATHOLIC_EPARCHY:
                    "Mukachevo Greek Catholic Eparchy",
                RUTHENIAN_GREEK_CATHOLIC_CHURCH:
                    "Ruthenian Greek Catholic Church",
                SLOVAK_GREEK_CATHOLIC_CHURCH: "Slovak Greek Catholic Church",
                HUNGARIAN_GREEK_CATHOLIC_CHURCH:
                    "Hungarian Greek Catholic Church",
                APOSTOLIC_EXARCHATE_OF_THE_GREEK_CATHOLIC_CHURCH_IN_THE_CZECH_REPUBLIC:
                    "Apostolic Exarchate of the Greek Catholic Church in the Czech Republic",
                CROATIAN_GREEK_CATHOLIC_CHURCH:
                    "Croatian Greek Catholic Church",
                BELARUSIAN_GREEK_CATHOLIC_CHURCH:
                    "Belarusian Greek Catholic Church",
                RUSSIAN_GREEK_CATHOLIC_CHURCH: "Russian Greek Catholic Church",
                ROMANIAN_GREEK_CATHOLIC_CHURCH:
                    "Romanian Greek Catholic Church",
                BULGARIAN_CATHOLIC_CHURCH: "Bulgarian Catholic Church",
                MACEDONIAN_GREEK_CATHOLIC_CHURCH:
                    "Macedonian Greek Catholic Church",
                GREEK_CATHOLIC_CHURCH: "Greek Catholic Church",
                ALBANIAN_CATHOLIC_CHURCH: "Albanian Catholic Church",
                ITALO_ALBANIAN_CATHOLIC_CHURCH:
                    "Italo-Albanian Catholic Church",
                MELKITE_GREEK_CATHOLIC_CHURCH: "Melkite Greek Catholic Church",
                COPTIC_CATHOLIC_CHURCH: "Coptic Catholic Church",
                ETHIOPIAN_CATHOLIC_CHURCH: "Ethiopian Catholic Church",
                MARONITE_CHURCH: "Maronite Catholic Church",
                SYRIAC_CATHOLIC_CHURCH: "Syriac Catholic Church",
                SYRO_MALANKARA_CATHOLIC_CHURCH:
                    "Syro-Malankara Catholic Church",
                CHALDEAN_CATHOLIC_CHURCH: "Chaldean Catholic Church",
                SYRO_MALABAR_CATHOLIC_CHURCH: "Syro-Malabar Catholic Church",

                CONSTANTINOPOLITAN_PATRIARCHATE:
                    "Patriarchate of Constantinople",
                ALEXANDRIAN_PATRIARCHATE:
                    "Greek Orthodox Patriarchate of Alexandria",
                ANTIOCHIAN_PATRIARCHATE:
                    "Greek Orthodox Patriarchate of Antioch",
                JERUSALEM_PATRIARCHATE:
                    "Greek Orthodox Patriarchate of Jerusalem",
                MOSCOW_PATRIARCHATE: "Russian Orthodox Patriarchate of Moscow",
                SERBIAN_PATRIARCHATE: "Serbian Orthodox Patriarchate",
                ROMANIAN_PATRIARCHATE: "Romanian Orthodox Patriarchate",
                BULGARIAN_PATRIARCHATE: "Bulgarian Orthodox Patriarchate",
                GEORGIAN_PATRIARCHATE: "Georgian Orthodox Patriarchate",
                CYPRUS_ORTHODOX_CHURCH: "Church of Cyprus",
                GREEK_ORTHODOX_CHURCH: "Greek Orthodox Church",
                POLISH_ORTHODOX_CHURCH: "Polish Orthodox Church",
                ALBANIAN_ORTHODOX_CHURCH: "Albanian Orthodox Church",
                ORTHODOX_CHURCH_CZECH_LANDS_SLOVAKIA:
                    "Orthodox Church in the Czech Lands and Slovakia",
                ORTHODOX_CHURCH_IN_AMERICA: "Orthodox Church in America",
                UKRAINIAN_ORTHODOX_CHURCH: "Ukrainian Orthodox Church",
                UKRAINIAN_ORTHODOX_CHURCH_USA:
                    "Ukrainian Orthodox Church of the USA",
                UKRAINIAN_ORTHODOX_CHURCH_CANADA:
                    "Ukrainian Orthodox Church of Canada",
                AMERICAN_CARPATHO_RUSSIAN_ORTHODOX_DIACONATE:
                    "American Carpatho-Russian Orthodox Diocese",
                WESTERN_EUROPEAN_EXARCHATE:
                    "Western European Exarchate of the Russian Orthodox Church",
                FINNISH_ORTHODOX_CHURCH: "Finnish Orthodox Church",
                ESTONIAN_APOSTOLIC_ORTHODOX_CHURCH:
                    "Estonian Apostolic Orthodox Church",
                CRETAN_ORTHODOX_CHURCH: "Cretan Orthodox Church",
                KOREAN_ORTHODOX_CHURCH: "Korean Orthodox Church",
                ANTIOCHIAN_ORTHODOX_ARCHDIOCESE_OF_NORTH_AMERICA:
                    "Antiochian Orthodox Christian Archdiocese of North America",
                SINAI_ORTHODOX_CHURCH: "Orthodox Church of Sinai",
                UKRAINIAN_ORTHODOX_CHURCH_OF_THE_MOSCOW_PATRIARCHATE:
                    "Ukrainian Orthodox Church of the Moscow Patriarchate",
                RUSSIAN_ORTHODOX_CHURCH_ABROAD:
                    "Russian Orthodox Church Outside Russia",
                MOLDOVAN_ORTHODOX_CHURCH: "Moldovan Orthodox Church",
                LATVIAN_ORTHODOX_CHURCH: "Latvian Orthodox Church",
                ESTONIAN_ORTHODOX_CHURCH_OF_THE_MOSCOW_PATRIARCHATE:
                    "Estonian Orthodox Church of the Moscow Patriarchate",
                JAPANESE_ORTHODOX_CHURCH: "Japanese Orthodox Church",
                CHINESE_AUTONOMOUS_ORTHODOX_CHURCH:
                    "Chinese Autonomous Orthodox Church",
                BELARUSIAN_EXARCHATE: "Belarusian Exarchate",
                ORTHODOX_OHRID_ARCHBISHOPRIC: "Orthodox Ohrid Archbishopric",
                BESSARABIAN_METROPOLITANATE: "Bessarabian Metropolitanate",

                ARMENIAN_APOSTOLIC_CHURCH: "Armenian Apostolic Church",
                COPTIC_ORTHODOX_CHURCH: "Coptic Orthodox Church",
                ETHIOPIAN_ORTHODOX_CHURCH: "Ethiopian Orthodox Church",
                SYRIAC_ORTHODOX_CHURCH: "Syriac Orthodox Church",
                MALANKARA_ORTHODOX_SYRIAN_CHURCH:
                    "Malankara Orthodox Syrian Church",
                ERITREAN_ORTHODOX_TEWAHEDO_CHURCH:
                    "Eritrean Orthodox Tewahedo Church",
                MOTHER_SEE_OF_HOLY_ETCHEMIADZIN:
                    "Mother See of Holy Etchmiadzin",
                ARMENIAN_PATRIARCHATE_OF_JERUSALEM:
                    "Armenian Patriarchate of Jerusalem",
                ARMENIAN_PATRIARCHATE_OF_CONSTANTINOPLE:
                    "Armenian Patriarchate of Constantinople",
                CILICIAN_ARMENIAN_CATHOLICATE: "Cilician Armenian Catholicate",

                ASSYRIAN_CHURCH_OF_THE_EAST: "Assyrian Church of the East",
                ANCIENT_CHURCH_OF_THE_EAST: "Ancient Church of the East",

                LUTHERANISM: "Lutheranism",
                ANGLICANISM: "Anglicanism",
                CALVINISM: "Calvinism",
                ANABAPTISM: "Anabaptism",
                BAPTISM: "Baptism",
                EVANGELICALISM: "Evangelicalism",
            },
            fields: {
                name: "Name of the temple",
                religionId: "Religion",
                religionCurrentId: "Current",
                religionSubspeciesId: "Subspecies",
                country: "Country",
                city: "City",
                info: "Information about the temple",
                abbotInfo: "Abbot Info",
                shopInfo: "Shop information",
                worshipLink: "Link to online worship",
                tourLink: "3D Tour Link",
                contactCountry: "Contacts: country",
                contactCity: "Contacts: city",
                contactStreet: "Contacts: street",
                contactBuildingNumber: "Contacts: building number",
                contactPhone: "Contacts: phone",
                contactSecondPhone: "Contacts: second phone",
                contactEmail: "Contacts: mail",
                contactInfo: "Contacts: additional information",
                header: "Header",
                body: "Informative text",
                fullName: "Full name",
                iban: "IBAN",
                accountId: "Account number",
                currency: "Currency",
                paymentMethod: "Transfer method",
                recipientName: "Recipient name",
                defaultPaymentAccount: "Default payment system",
                order: "Order",
                type: "Type",
                period: "Period",
                maxPeople: "Maximum number of people",
                price: "Price",
                mondayBegin: "Monday, beginning",
                mondayEnd: "Monday, end",
                tuesdayBegin: "Tuesday, beginning",
                tuesdayEnd: "Tuesday, end",
                wednesdayBegin: "Wednesday, beginning",
                wednesdayEnd: "Wednesday, end",
                thursdayBegin: "Thursday, beginning",
                thursdayEnd: "Thursday, end",
                fridayBegin: "Friday, beginning",
                fridayEnd: "Friday, end",
                saturdayBegin: "Saturday, beginning",
                saturdayEnd: "Saturday, end",
                sundayBegin: "Sunday, beginning",
                sundayEnd: "Sunday, end",
                useStandardPrayersInfo: "Use standard text (prayers)",
                useStandardTempleSupportInfo:
                    "Use standard text (temple support)",
                templeSupportInfo: "Temple support info",
                prayersInfo: "Prayers info",
                useStandardPrayersEmail: "Use standard text for email (prayers)",
                useStandardTempleSupportEmail:
                    "Use standard text for email (temple support)",
                templeSupportEmail: "Temple support email",
                prayersEmail: "Prayers email",
                isDeleted: "Deleted",
                files: "Images",
                abbotInfoFiles: "Abbot images",
                shopInfoFiles: "Shop images",
                galleryFiles: "Gallery",
                date: "Date",
                begin: "Begin",
                end: "End",
                email: "Email",
                status: "Status",
                phoneNumber: "Phone number",
                comment: "Comment",
                language: "Language",
                createdAt: "Сreated",
                updatedAt: "Updated",
            },
            entity: {
                [Entity.TEMPLE]: "Temple",
                [Entity.ARTICLE]: "Article",
                [Entity.ARTIFACT]: "Artifact",
                [Entity.BANK_ACCOUNT]: "Bank details",
                [Entity.PAYMENT_ACCOUNT]: "Payment systems",
                [Entity.PRAYER]: "Prayers",
                [Entity.PRAYER_PRICE_DETAIL]: "Details of the price of prayers",
                [Entity.PRAYER_REQUIREMENT]: "Prayer requirements",
                [Entity.WEEK_SCHEDULE]: "Weekly schedule",
                [Entity.WORSHIP_SCHEDULE]: "Worship schedule",
            },
            templeSortFields: {
                [TempleSortFields.NAME]: "Alphabetically",
                [TempleSortFields.CREATED_AT]: "By registration date",
                [TempleSortFields.POPULAR]: "By popularity",
                [TempleSortFields.VISITS]: "By attendance",
            },
            section: {
                1: "Main",
                2: "Abbot of the shrine",
                3: "Shop at the shrine",
                4: "Additional information about the shrine",
                5: "Order of prayers",
            },
            payments: {
                paymentFailedToCreate: "Payment was not created.",
                paymentFailed: "There was an error creating the payment.",
                paymentCreated:
                    "Payment created but not completed. Contact the Administrator to check the status of the payment.",
                paymentWasNotCreated:
                    "Payment was not created. Please try later.",
            },
            prayer: {
                type: {
                    1: "For health",
                    2: "For peace",
                },
                period: {
                    1: "One-day",
                    2: "For a long period",
                },
            },
            weekDays: {
                [Week.MONDAY]: "Monday",
                [Week.TUESDAY]: "Tuesday",
                [Week.WEDNESDAY]: "Wednesday",
                [Week.THURSDAY]: "Thursday",
                [Week.FRIDAY]: "Friday",
                [Week.SATURDAY]: "Saturday",
                [Week.SUNDAY]: "Sunday",
            },
            shortWeekDays: {
                [Week.MONDAY]: "Mon",
                [Week.TUESDAY]: "Tue",
                [Week.WEDNESDAY]: "Wed",
                [Week.THURSDAY]: "Thu",
                [Week.FRIDAY]: "Fri",
                [Week.SATURDAY]: "Sat",
                [Week.SUNDAY]: "Sun",
            },
            user: {
                header: {
                    search: "Search...",
                    religions: "The shrines",
                    orderPrayer: "Order a prayer",
                    support: "Support of shrines",
                    temple: "Online worship",
                },
                footer: {
                    pages: "Pages:",
                    main: "Main",
                    religions: "Religions",
                    popular: "The shrines",
                    about: "About the project",
                    temple: "Online worship",
                    orderPrayer: "Order of prayer",
                    offering: "Support of shrines",
                    support: "Support the project",
                    templeRepresentatives:
                        "To the representatives of the temple",
                    confidentiality: "Terms of confidentiality",
                    rules: "Rules of use",
                    fullVersion: "Full version of the site",
                },
                main: {
                    top: {
                        current: "Confession:",
                        place: "Location:",
                    },
                    holy: {
                        title: "All shrines in one place.",
                        subtitle1: "Visiting shrines",
                        text1: "Our project (project name) gives you a unique opportunity to visit famous religious holy places and use their services in one place, at any time and from any part of the world.",
                        text2: "We wish that your visit was not only as interesting as possible, but also brought happiness, joy and spiritual satisfaction to you and your loved ones!",
                        text3: "Most of the sanctuaries presented on the site are also active religious objects that hold services.",
                        text4: "You can order prayers, requiems, masses, divine services or use other services of the shrine of your choice, and your wishes, requests and appeals will be heard in it, even if you are thousands of kilometers away from it.",
                        subtitle2: "Online Worship",
                        text5: "The project provides an opportunity not only to remotely visit and use the services of various religious objects, but also to directly participate in the processes and services that take place in them.",
                        text6: "Regardless of the circumstances and wherever you are, you have a unique opportunity to be present in such events using the Online worships.",
                    },
                    order: {
                        clickLabel: " in three clicks",
                        selectReligion: "Choose a religion",
                        selectTemple: "Choose a shrine",
                        orderPrayer: "Order a prayer",
                    },
                    popular: {
                        title: "Popular shrines",
                        religion: "Religion:",
                        city: "City:",
                        details: "More details",
                    },
                },
                religions: {
                    redirectPages: {
                        [Page.TEMPLE]: "",
                        [Page.CHOOSE_TEMPLE]: "",
                        [Page.ORDER_PRAYER]:
                            "Choose the shrine in which you want to order a prayer",
                        [Page.SUPPORT]:
                            "Select the shrine you wish to donate to",
                        [TempleSearchParameters.IS_WORSHIP_LINK_EXIST]:
                            "Select a temple to view online worship",
                    },
                    base: {
                        religion: "Religion",
                        current: "Current",
                        subspecies: "Subspecies",
                    },
                    filter: {
                        title: "Choosing a shrine",
                        searchButton: "Search",
                        resetButton: "Reset filter",
                    },
                    header: {
                        title: "All Shrines where you can order a prayer",
                        sort: "Sort",
                        abc: "Alphabet",
                        popular: "Popularity",
                        visits: "Attendance",
                        date: "Date of registration",
                        choose: "Choosing a shrine",
                        country: "Country",
                        city: "City",
                        other: "Other",
                        withoutTemplesTitle: "There are no temples to display",
                    },
                    row: {
                        location: "Location:",
                    },
                },
                temple: {
                    abbot: {
                        subtitle: "Abbot of the Shrine",
                        moreInfo: "Read more",
                    },
                    basics: {
                        subtitle: "The main shrines",
                    },
                    contacts: {
                        subtitle: "Contacts",
                        office: "Office",
                        additional:
                            "Pilgrimage department (trips, excursions):",
                        phone: " - primary",
                        secondPhone: " - additional",
                        email: " - E-mail",
                    },
                    information: {
                        subtitle: "Information for visitors",
                        schedule: "Work Schedule",
                        worship: "Schedule of services",
                        shop: "Church shop",
                        visitors: "To visitors",
                        other: "Other",
                    },
                    main: {
                        location: "Location:",
                        religion: "Religion:",
                        current: "Current:",
                        subspecies: "Subspecies:",
                        moreInfo: "Read more",
                        supportButton: "Support shrine",
                        orderPrayerButton: "Order prayer",
                    },
                    online: {
                        subtitle: "Online worship",
                    },
                    sidebar: {
                        main: "About the shrine",
                        abbot: "Abbot of the shrine",
                        basic: "The main shrines",
                        info: "Information for visitors",
                        support: "Prayer order",
                        online: "Online worship",
                        online3d: "3D Tour",
                        gallery: "Photo gallery",
                        contacts: "Contacts",
                    },
                    support: {
                        subtitle1: "Ordering prayers and supporting the shrine",
                        subtitle2: "Order of prayers",
                        subtitle3: "Supporting the shrine",
                        orderPrayerButton: "Order prayer",
                        supportButton: "Support shrine",
                        text1: "If you cannot visit the Shrine, but want to order a prayer for your loved ones and relatives - you can do it below by sending a note from the site.",
                        text2: "Below you can contribute and support the close soul and heart of the Shrine, most of which exist thanks to your donations. Any contribution you make will be invaluable!",
                    },
                    worships: {
                        day: "Day",
                        date: "Date",
                        hour: "Hour",
                        worship: "Worship",
                        worshipsNotFound: "Worships not found",
                        backToCurrentWeek: "Go back to the current week",
                    },
                },
                prayer: {
                    successModal: {
                        title1: "Note ",
                        title2: " ordered successfully. Thank you!",
                    },
                    accordion: {
                        button: "Prayer order",
                        subtitle1: "One Day Notes",
                        subtitle2: "Notes for a long period",
                        details: {
                            people1: "to",
                            people2: "names",
                        },
                    },
                    header: {
                        subtitle: "Order of prayer",
                        text: "Dear friends! If you cannot visit the Shrine, but want to order a prayer for your loved ones and relatives, you can do it below by sending a note from the site.",
                    },
                    item: {
                        namePlaceholder: "Name",
                    },
                    payer: {
                        prayerNoteName: "Note name",
                        addButton: "Add name",
                        email: "Your E-mail",
                        fullName: "Your Full Name",
                        text: "Your note",
                        agreement1: "I agree with ",
                        agreement2: "Privacy Terms, Terms of Use",
                        agreement3:
                            "and I consent to the processing of personal data.",
                        prayerButton: "Order",
                    },
                },
                support: {
                    successModal: {
                        title1: "Help for ",
                        title2: "shrine ",
                        title3: "platform",
                        title4: " listed successfully. Thank you!",
                    },
                    temple: {
                        title: "Support of the shrine",
                        text: "Dear friends! Using our site, you can make your contribution and support the Shrine close to your religious and spiritual preferences, most of which exist thanks to your donations. Any of your contributions will be invaluable!",
                    },
                    project: {
                        title: "Support the project",
                        text: "Dear friends! The implementation of this project is designed to make people happy, and to give everyone the opportunity to give happiness to others! We will be glad if, sharing our aspirations, you will support the project with your donations, which are in your power. By supporting the project, you are investing in happiness!",
                    },
                    offeringText:
                        "After choosing the currency and entering the amount in the field below and clicking the Donate button, you will be transferred to the transfer method convenient for you.",
                    offeringButton: "Donate",
                    fullNamePlaceholder: "Full name",
                },
                about: {
                    title: "About the project",
                    text1: "The modern world is developing at a rapid pace, making adjustments to the lives of each of us. The daily hustle and bustle takes away more and more free time from us, and less and less of it remains for personal needs.",
                    text2: "In the cycle of life, we sometimes forget not only about relatives, friends and loved ones, but sometimes we completely forget about the need for harmony of our own soul and body. Such conditions minimize spiritual harmony and its development, and sometimes, there is no place for it at all... ",
                    text3: "It is to solve such challenges that the Holly Place Visit project was created.",
                    text4: "The goal of the ______ project is to maximize the use of modern trends and technologies, to minimize their negative aspects. In other words, _____ using modern technologies increases opportunities and simplifies access to famous Shrines and their services.",
                    text5: "Project ___ is designed to provide a wide range of opportunities, the main of which are:",
                    text6: "reading information about any Shrine provided by its representatives; ",
                    text7: "to be present in real time at the spiritual processions of the Shrines using the function Online Worship;",
                    text8: "in the 3D tour mode, remotely visit famous Shrines; ",
                    text9: "using the Order a prayer function, you can order prayers, prayers, masses, divine services or use other services of the Shrine of your choice, and your wishes, requests and appeals will be heard in it, even if you are thousands of kilometers away from it. ",
                    text10: "you can also make your contribution and send a donation for the support and development of a close soul and heart to the Shrine, using the Support Shrines function.",
                    text11: "The _____ project is open to all Shrines and we hope that it will become a place of finding harmony for each of its visitors!",
                    text12: "Holly Place Visit - Famous shrines, in one place, from anywhere in the world!",
                },
                faq: {
                    footerButton: "Support",
                    title: "Frequently asked questions",
                    title2: "Answers",
                    about: {
                        aboutSubtitle: "About the project",
                        aboutQuestion1: "What is Holy Place Visit?",
                        aboutAnswer1:
                            "Holy Place Visit is an interactive specialized Internet platform that allows you to get acquainted with a variety of religious websites around the world remotely, as well as use their services.",
                        aboutQuestion2:
                            "What opportunities does the project provide for the user?",
                        aboutAnswer2_1:
                            "The project is designed to provide a wide range of opportunities, the main ones are:",
                        aboutAnswer2_2:
                            "to get acquainted with the information about any Holy Place shared by its representatives;",
                        aboutAnswer2_3:
                            "to attend the spiritual processions of the Holy Place in real time using the «Online Worship Services» function;",
                        aboutAnswer2_4:
                            "in the «3D Tour» mode remotely visit famous Holy Places;",
                        aboutAnswer2_5:
                            "using the «Order a Prayer» function, order prayers, masses, services, or use other services at the Holy Place of your choice, and your wishes, requests, and appeals will be heard at the Holy Place, even if you are thousands of kilometers away.",
                        aboutAnswer2_6:
                            "You can also contribute and send donations to support and develop a Holy Place that is close to your heart and soul by using the «Support the Holy Place» function.",
                        aboutQuestion3:
                            "What opportunities does the project offer to the Holy Place and their Representatives?",
                        aboutAnswer3_1:
                            "The project offers a unique opportunity to register a Holy Place on the website for the representatives of the Holy Places.",
                        aboutAnswer3_2:
                            "This will allow in an accessible form to distribute information about the Holy Place as much as possible, to increase its popularity as much as possible, and to expand the number of parishioners and followers.",
                        aboutAnswer3_3:
                            "Using modern technology the platform allows the Holy Places to remotely provide all the available services, including paid services, as well as receive donations.",
                        aboutQuestion4:
                            "How to use the services of the project?",
                        aboutAnswer4_1:
                            "All the possibilities of the project are available for the Users from the very beginning.",
                        aboutAnswer4_2:
                            "In order to use the project as a Holy Place Representative there is a procedure of preliminary registration (creating a profile).",
                        aboutQuestion5:
                            "What are Terms of Service and Privacy Policy?",
                        aboutAnswer5:
                            "Terms of Service (Terms of Use) and Privacy Policy are the generalized set of its rules and conditions, knowing and agreeing with them gives you the right to use the project's services.",
                    },
                    gettingStarted: {
                        gettingStartedSubtitle: "Getting Started",
                        gettingStartedQuestion1:
                            "Do I need to register to use the project?",
                        gettingStartedAnswer1_1:
                            "We respect the religious feelings of all citizens and understand their desire for privacy, so for maximum comfort all the features of the project are available to its users from the first second of use and without any registration.",
                        gettingStartedAnswer1_2:
                            "To use the project as a Holy Place Representative there is a procedure of preliminary registration (creating a profile).",
                        gettingStartedQuestion2:
                            "Can I become a Holy Place Representative and what are the requirements for them?",
                        gettingStartedAnswer2:
                            "A Holy Place Representative is given the opportunity to create and edit a Holy Place profile on the website. Therefore, only a person with appropriate authority can be a Holy Place Representative.",
                        gettingStartedQuestion3:
                            "How can I find the Holy Relics I'm interested in on the website?",
                        gettingStartedAnswer3_1:
                            "On the website there are pages only of those Holy Places, whose Representatives have applied for registration in the project, and which have been approved.",
                        gettingStartedAnswer3_2:
                            "The list of the registered Holy Place can be seen in the «Holy Places» or «Religions» section. The interested Holy Place can be found in these sections by using the filter system or the website search system.",
                    },
                    registration: {
                        registrationSubtitle:
                            "Registration of a Holy Place (for Holy Place Representatives)",
                        registrationQuestion1:
                            "Who can register a Holy Place on the website?",
                        registrationAnswer1:
                            "Only the Holy Place Representative can register a Holy Place on this website, i.e. a person with corresponding authorization.",
                        registrationQuestion2:
                            "What are the requirements for the representative of the Holy Place? ",
                        registrationAnswer2_1:
                            "The representative of the Holy Place can be only a person with enough authority to represent the Holy Place in the project, making and editing information about it on the website.",
                        registrationAnswer2_2:
                            "The HolyPlaceVisit Project representatives can ask for proof of such authority, its completeness and actuality, in the form and manner they determine, at any time from the moment of applying for participation in the project until the end of the participation in the project.",
                        registrationQuestion3:
                            "Who is the Holy Place Representative?",
                        registrationAnswer3:
                            "There may be buttons on the website with the phrase «Holy Place Representative» whose functionality and meaning is identical to the concept of «HolyPlaceVisit Representative».",
                        registrationQuestion4:
                            "How do I register a Holy Place on the website, or apply for registration in the project as a Holy Place Representative?",
                        registrationAnswer4_1:
                            "Only the Representative - a person having the corresponding powers - can register a Holy Place on the website.",
                        registrationAnswer4_2:
                            "To apply for the registration in the project the Holy Place's Representative has to pass the registration procedure and fill in the necessary information about the Holy Place stated in the registration form.",
                        registrationAnswer4_3:
                            "At present time, the registration for the Holy Place on the website is free of charge.",
                        registrationQuestion5:
                            "What information must be submitted to register a Holy Place in the project?",
                        registrationAnswer5_1:
                            "The Holy Place Representative, using the registration form established by the project, provides information about the Holy Place.",
                        registrationAnswer5_2:
                            "The registration form contains mandatory and non-mandatory sections. The minimum requirement for submitting an application for the project is to complete all mandatory sections of the registration form. For more successful use of the Holy Place page on the website, we recommend that you include as much information about it as possible.",
                        registrationQuestion6:
                            "Donations and support of the Holy Places, their provision of paid services.",
                        registrationAnswer6_1:
                            "The project allows Holy Places to receive remuneration for the services they provide through the website. The description of such services, the requirements for them and their cost are determined by the Holy Place Representatives.",
                        registrationAnswer6_2:
                            "The platform also allows the Holy Places to receive financial donations.",
                        registrationAnswer6_3:
                            "Donations and funds for paid services are being transferred according to details specified by the Holy Place Representative.",
                        registrationQuestion7:
                            "What is the application review period for the Holy Place Project?",
                        registrationAnswer7:
                            "If the Holy Place Representative and the application submitted meet the necessary requirements, the application will be approved as soon as possible.",
                        registrationQuestion8:
                            "How will I know if my application for the Holy Place is approved?",
                        registrationAnswer8:
                            "After the application for the registration of the Holy Place in the project is approved, its page will be available for the Users of the website. The list of the registered Holy Places can be seen in the «Holy Places» or «Religions» section.  The Holy Places of interest can be found in these sections with the help of the filter system or by using the website search system.",
                    },
                    services: {
                        servicesSubtitle: "Services for users of the project",
                        servicesQuestion1:
                            "What information about the Holy Places can be found on the website?",
                        servicesAnswer1_1:
                            "On the page of the Holy Place you can see only the information that was provided by the Representative of the Holy Place.",
                        servicesAnswer1_2:
                            "The project provides the obligatory information sections, such as the name of the Holy Place, its description, information about the rector, working and service schedules, the services provided and other useful information for the visitors, but its Representative defines the final amount and content of the information on the page of the Holy Place.",
                        servicesQuestion2: "What is Online Worship?",
                        servicesAnswer2_1:
                            "It is a functionality provided by the project that, using modern technology, enables the User to be present in real time at the spiritual processions of the Holy Places.",
                        servicesAnswer2_2:
                            "This functionality is optional, and the possibility of its provision to the Users is determined by the Holy Place Representative. The list of all the Holy Places that allow the use of the «Online Worship Service» function can be found in the section with the same name.",
                        servicesQuestion3: "What is a 3D tour?",
                        servicesAnswer3_1:
                            "It is a functionality provided by the project, which allows the User to remotely visit the territory of well-known Holy Places with the effect of maximum presence.",
                        servicesAnswer3_2:
                            "This functionality is optional and the Holy Place Representative determines the possibility of providing it to Users.",
                        servicesQuestion4:
                            "What does «Order a Prayer» function mean?",
                        servicesAnswer4_1:
                            "With «Order a prayer» you can order prayers, services, masses or other services of the chosen Holy Place, and your wishes, requests and appeals will be heard there, even if you are thousands of kilometers away.",
                        servicesAnswer4_2:
                            "The Representatives of the Holy Places determine the content of such services, their requirements and their cost.",
                        servicesAnswer4_3:
                            "You can order the service at the corresponding section of the Holy Place of interest, which contains the form and requirements for ordering. You can go to this page by clicking on the button «Order a prayer» on the page of the chosen Holy Place.",
                        servicesAnswer4_4:
                            "The list of all the Holy Places that allow using the «Order a prayer» function can be found in the section with the same name on the main page.",
                        servicesQuestion5:
                            "What does the function «Support the Holy Place» mean?",
                        servicesAnswer5_1:
                            "The project provides the opportunity to make a contribution and send donations to support and develop the Holy Places that are close to your heart and soul by using the «Support Holy Place» function.",
                        servicesAnswer5_2:
                            "Today most of the Holy Places exist thanks to your donations, and any contribution you make will be invaluable for them!",
                        servicesAnswer5_3:
                            "You can order the service on the appropriate section of the Holy Place you are interested in, which contains the form and ordering requirements. You can go to this page of the website by clicking on the «Support the Holy Place» button on the page of the Holy Place of your choice.",
                        servicesAnswer5_4:
                            "A list of all Holy Places that allow use of the «Support the Holy Place» feature can be found in the section with the same name on the home page of the website.",
                    },
                    cooperation: {
                        cooperationSubtitle:
                            "Cooperation, suggestions and troubleshooting",
                        cooperationQuestion1:
                            "Where can I send my suggestions about how the project works?",
                        cooperationAnswer1_1:
                            "We strive to become better and will always be glad to hear any comments or remarks to improve the work of the project! Your requests on this issue, please send us using the feedback form with the subject line «Suggestions».",
                        cooperationAnswer1_2:
                            "You can go to the feedback form by clicking on the «Support» button at the bottom of the main page.",
                        cooperationQuestion2:
                            "How do I troubleshoot or contact support?",
                        cooperationAnswer2_1:
                            "Our staff is constantly working on bugs that may arise in the process, and methods of fixing them. To assist us in this matter, and, if you have any problems during your work, please write to us using the feedback form with the subject line «Troubleshooting».",
                        cooperationAnswer2_2:
                            "You can access the feedback form by clicking on the «Support» button at the bottom of the home page.",
                        cooperationAnswer2_3:
                            "Popular troubleshooting requests, as well as troubleshooting methods, will be published in this section.",
                        cooperationQuestion3:
                            "How can I submit suggestions for collaboration?",
                        cooperationAnswer3_1:
                            "The aim of the project is to support and promote the world's Holy Places. With this project we hope to make the world a little bit better, kinder and calmer!",
                        cooperationAnswer3_2:
                            "The project is open for all the Holy Places, and we hope that it will become a place of harmony for each of its visitors!",
                        cooperationAnswer3_3:
                            "If your goals are the same as ours, and you have interesting suggestions for cooperation, please send them to us by using the feedback form, selecting «Cooperation» in the subject line.",
                        cooperationAnswer3_4:
                            "You can go to the feedback form by clicking on the button «Support» at the bottom of the home page.",
                    },
                },
            },
            currency: {
                [Currency.USD]: "USD",
                [Currency.EUR]: "EUR",
                [Currency.PLN]: "PLN",
            },
            payoutStatus: {
                [PayoutStatus.IN_REVIEW]: "In review",
                [PayoutStatus.CANCELED]: "Canceled",
                [PayoutStatus.EXECUTED]: "Executed",
            },
            paymentMethod: {
                1: "Pay Pal",
                2: "Payoneer",
                3: "Skrill",
            },
            transactionType: {
                1: "Payment",
                2: "Donation",
            },
            base: {
                buttons: {
                    refreshPage: "Refresh page",
                },
                photo: {
                    remove: "Remove",
                },
                successPaymentModal: {
                    moveToTemple: "Return to the shrine",
                },
                truncateText: {
                    moreInfo: "Read more",
                    lessInfo: "Read less",
                },
                pagination: {
                    itemsPerPage: "Items per page",
                    page: "Page",
                    of: "of",
                },
            },
            templeRegister: {
                correspondence: {
                    title: "Correspondence",
                    instructionText:
                        "<p>Enter an email address to receive notifications from the platform.</p><p>This address will be used to receive notifications of donations and order prayers from users.</p><p>This address is required to complete temple registration.</p>",
                    saveEmailButtonLabel: "Send Confirmation Email",
                    successEmailModalText:
                        "A confirmation email has been sent to the email",
                },
                base: {
                    title: "Data",
                    title1: "Additional Information ",
                    title2: "about the temple",
                    addPhoto: "Add a photo",
                },
                footer: {
                    button: "Save",
                    cancelEntity: "Cancel",
                    cancelButton: "Exit",
                    cancelModal: {
                        exit: "Exit",
                        stay: "Stay",
                        title: "Do you confirm exit from the temple registration process? Unsaved changes will be lost.",
                    },
                },
                required: {
                    artifacts: "Add Institutional Shrines",
                    prayers: "Add at least one Note and Wishes to Notes",
                    paymentDetails: "Add payment systems",
                    bankDetails: "Add bank details",
                },
                mainInfo: {
                    titleFirst: "Fill in the details of the temple ",
                    titleSecond: "and add a photo*",
                    templeName: "The name of the shrine",
                    country: "Country",
                    city: "City",
                    templeInfo: "Information about the shrine",
                    addPhoto: "Add a photo",
                    photoInfo:
                        "The first photo will be the main page of the shrine",
                    religionPlaceholder: "Religion",
                    currentPlaceholder: "Current",
                    subspeciesPlaceholder: "Subspecies",
                    customSearchPlaceholder: "Enter text to search",
                    onlineWorshipPresence: "Availability of online worship",
                },
                abbot: {
                    subtitle: "The abbot of the shrine*",
                    infoPlaceholder: "Information about the rector",
                    label1: "The first photo will be the main one for the representative",
                    subtitle2: "The main sanctuaries of the institution*",
                    namePlaceholder: "The name of the shrine",
                    infoPlaceholder2: "Brief information",
                    addShrine: "Add shrine",
                    abbotInfoTooltip: "Abbot info",
                },
                bank: {
                    title: "Bank details",
                    fullName: "Full name",
                    account: "Recipient's account",
                    currency: "Account currency",
                    add: "Add quantity",
                    iban: "IBAN",
                },
                gallery: {
                    subtitle: "Online Worship",
                    linkPlaceholder: "Link to online worship",
                    subtitle2: "3D tour",
                    label1: "Here you can download a file for a 3D tour",
                    subtitle3: "Photo gallery",
                    additionalPhoto1: "You can upload additional photos ",
                    additionalPhoto2: " his temple",
                    subtitle4: "Contacts",
                    country: "Enter the country",
                    city: "Enter the city",
                    street: "Enter the street",
                    building: "House number",
                    phone: "Phone number",
                    phone2: "Additional phone number",
                    worshipLinkInstruction: {
                        title: "How to copy YouTube video link:",
                        firstStep: "Open the YouTube video you want to share.",
                        secondStep:
                            "Click on the share button below the video.",
                        thirdStep:
                            'In the window that opens, click on the "Copy" button next to the video link.',
                        correctLink:
                            "Check that the author of Your link has given permission for it to be viewed on other sites.",
                        linkExample:
                            "Example of a link: https://youtu.be/video-id",
                        finalText:
                            "The video link has now been copied to your clipboard and you can paste it into the box below.",
                    },
                    tourPreviewButton: "Preview",
                    tourInstruction: {
                        title: "How to add 3D-tour:",
                        info: 'Click "Share" in your online 3D tour editor, choose "Share with HTML (Embed code)" and copy it into the box below.',
                        example: "It might look like this:",
                    },
                },
                orders: {
                    title: "Ordering prayers",
                    subtitle: "Text-appeal to Visitors for ordering prayers*",
                    textPlaceholder:
                        "An introductory text message for Visitors with an offer to order a prayer (from 30 - 100 characters)",
                    useStandardText: "Use standard text",
                    name: "Name",
                    type: "Type",
                    period: "Period",
                    maxPeople: "People to:",
                    price: "Price",
                    info: "Brief description",
                    name2: "The name of the note",
                    maxPeople2: "up to 10 people",
                    price2: "Price in dollars",
                    save: "Save the note",
                    label: "Only baptized people's names are accepted.",
                    subtitle2: "Requirements and wishes for ordering prayers*",
                    textPlaceholder2: "The text of the wish (requirement)",
                    add: "Add wishes",
                    add2: "Add a note",
                    subtitle3: "Information for ordering temple support*",
                    textPlaceholder3:
                        "An introductory text message for Visitors with an offer to support the shrine (від 30 - 100 символів)",
                    requiredField:
                        'You must add information in the field below, or select "Use standard text"',
                    subtitle4: "The text of the response to the person who requested the prayer",
                    textPlaceholder4: "The text of the response to the person who requested the prayer",
                    subtitle5: "Text of the response to the person who will make the donation",
                    textPlaceholder5: "Text of the response to the person who will make the donation"
                },
                payment: {
                    title: "Connection of payment systems",
                    id: "Identifier of the payment system",
                    name: "Recipient's name",
                    currency: "Account currency",
                    method: "Payment system",
                },
                schedules: {
                    title: "Work schedule",
                    begin: "Start (hh:mm)",
                    end: "Completion (hh:mm)",
                    monday: "Monday",
                    tuesday: "Tuesday",
                    wednesday: "Wednasday",
                    thursday: "Thursday",
                    friday: "Friday",
                    saturday: "Saturday",
                    sunday: "Sunday",
                    subtitle: "Schedule of services",
                    name: "Enter the name of the service",
                    addEvent: "Add an event",
                },
                visitorsInfo: {
                    subtitle: "Shop at the shrine",
                    info: "Brief information",
                    photoInfo:
                        "The first photo will be the main one for the representative",
                    subtitle2: "Pilgrims",
                    header: "Header",
                    textPlaceholder:
                        "For example: Stopping places, Food places, Excursions, etc.",
                    addInfo: "Add information",
                    other: "Other",
                },
                sidebar: {
                    mainInfo: "Basic information",
                    abbot: "Abbot and shrine",
                    schedules: "Schedule and services",
                    visitorsInfo: "Information for visitors",
                    orders: "Order prayers and donations",
                    paymentDetails: "Details of payment systems",
                    bankDetails: "Bank details",
                    galleries: "Online worship and galleries",
                    correspondence: "Correspondence",
                },
            },
            errors: {
                response: {
                    [ResponseErrors.WRONG_CREDENTIALS]:
                        "Incorrect login or password",
                    [StatusCodes.CONFLICT]:
                        "Stale data is present, please refresh the page",
                    [StatusCodes.REQUEST_TOO_LONG]:
                        "Unable to load data, content is too large."
                },
                common: "An error has occurred, please try again",
                commonErrors: {
                    isNumber: "This must be a number",
                    max: "Maximum length - ",
                    min: "Minimum length - ",
                    required: "Required field",
                    beginLowerThanEnd:
                        "Begin time should be lower than End time",
                    beginWithEnd: "Begin time should be with End time",
                    isNotCorrectEmail: "This is not a correct email",
                    isNotCorrectLink: "This is not a correct link",
                    invalidPhoneNumber: "Phone number format is incorrect",
                    noSpaces: "No spaces allowed",
                },
                userLogin: {
                    email: "This must be an email",
                    passwordsNotMatch: "Passwords do not match",
                    password: {
                        minSymbols: "Minimum number of special characters - 1",
                        minLowercase: "Minimum lowercase character - 2",
                        minUppercase: "Minimum uppercase character - 1",
                        minNumbers: "Minimum number of numbers - 1",
                    },
                },
                photo: {
                    maxSize: "Selected file size exceed ",
                    acceptType: "Your selected file type is not allow",
                },
                currencyErrors: {
                    onlyUSD:
                        "At the moment it is possible to add only accounts in US dollars ($)",
                },
                payouts: {
                    [StatusCodes.BAD_REQUEST]: "Invalid payout`s fields or irrelevant payout.",
                    [StatusCodes.NOT_FOUND]: "Payment not found. Check for relevance of csv file.",
                },
            },
            login: {
                title: "Sign In",
                email: "Login",
                password: "Password",
                notConfirmed: "Please confirm your email to sign in",
            },
            register: {
                title: "Registration",
                confirmPassword: "Confirmation",
                next: "Next",
                personalTitle: "Fill in personal data",
                name: "Name",
                lastname: "Surname",
                phone: "Phone number",
                temple: "The name of the temple",
                successModal: {
                    info: "To complete the registration, follow the link in the letter we sent to the e-mail address you specified.",
                },
            },
            templeAdmin: {
                footer: {
                    sum: "Total: ",
                },
                header: {
                    edit: "Edit the site",
                    visit: "Visit the site",
                },
                main: {
                    title: "Home",
                    newTemple: "Add a shrine",
                    activated: "Activated",
                    inactive: "Inactive",
                    declined: "Declined",
                    edit: "Edit",
                    delete: "Delete",
                    removeTemple: {
                        title: "Are you sure you want to delete the temple?",
                        remove: "Remove",
                        cancel: "Cancel",
                    },
                },
                payments: {
                    platformLabel: "Platform",
                    header: {
                        main: {
                            title: "Payments",
                            totalCount: "Total payments: ",
                            totalAmount: "Total: ",
                            totalTempleAmount: "Temple: ",
                            totalPlatformAmount: "Site: ",
                            templePaidAmount: "Paid to temple: ",
                            inReviewAmount: "In work: ",
                            income: "Temple balance: ",
                            resetFilter: "Clean the filter",
                        },
                        filter: {
                            filterButton: "Filter",
                            applyButtonLabel: "Apply",
                            fields: {
                                sender: "Payer",
                                paymentId: "Identifier",
                                sumFrom: "Amount from:",
                                sumBefore: "Amount before:",
                                status: {
                                    label: "Status",
                                    value0: "About health",
                                    value1: "About rest",
                                },
                                dateFrom: "Date from:     dd/mm/yy",
                                dateBefore: "Date before:     dd/mm/yy",
                                transactionType: {
                                    label: "Transaction type",
                                    value0: "About health",
                                    value1: "About rest",
                                },
                            },
                        },
                    },
                    table: {
                        labels: {
                            senderName: "Full name",

                            id: "Identifier ",
                            payment: " of payment",

                            name: "Name ",
                            recipient: " of recipient ",

                            status: "Status ",
                            paymentDate: "Arrival time ",
                            amount: "In total ",
                            temple: "Shrine ",
                            site: "Site ",

                            type: "Type ",
                            transaction: " transactions",
                        },
                    },
                    payment: {
                        senderName: "Full name",
                        paymentDate: "Date of receipt",
                        paymentDateConfirmation: "Confirmation date",
                        paymentId: "Payment ID",
                        recipientName: "Recipient's name",
                        transactionType: "Transaction type",
                        totalAmount: "Total amount, $",
                        recipientAmount: "Transferred to the recipient, $",
                        platformAmount: "Got the site, $",
                        initial: "It was: ",
                        final: "It became: ",
                        senderEmail: "E-mail of the payer",
                        paymentMethod: "Payment system of the payer",
                        senderAccountId: "Payer ID:",
                        recipientAccountId: "Recipient ID:",
                        manager: "Manager",
                        confirmationButtonLabel: "Confirm",
                        prayerName: "Name",
                        openPayoutLabel: "Open Payout",
                        openCreditsLabel: "View Credits",
                    },
                },
            },
            admin: {
                charts: {
                    header: "Charts",
                    attendance: "Attendance",
                    payments: "Payments",
                },
                payouts: {
                    label: "Payouts",
                    totalPayouts: "Total payouts:",
                    downloadCSV: "Export payouts (.csv)",
                    uploadCSV: "Import payouts (.csv)",
                    successPayoutsCreation: "Payouts created",
                    successPayoutsResolving: "Payouts completed",
                    paymentMethodsModal: {
                        title: "Selected items with different payment methods",
                        accept: "Continue",
                        cancel: "Cancel",
                    },
                    nonValid: {
                        title: "Payments that cannot be completed due to incorrect data:",
                        csvOrder: "Number in .csv table",
                        id: "Payment ID",
                        transactionId: "Transaction ID",
                        status: "Payout status",
                        createdAt: "Payment creation date",
                        close: "Cancel",
                        forced: "Continue anyway",
                    },
                    table: {
                        templeName: "Temple name",
                        transactionAmount: "Amount, $",
                        transactionPrayersAmount: "Amount of payments, $",
                        transactionDonationsAmount: "Amount of donations, $",
                        status: "Status",
                        transactionsCount: "Number of transactions",
                        transactionsPrayersCount: "Number of payments",
                        transactionsDonationsCount: "Number of donations",
                        createdAt: "Date created",
                        createdByName: "Created by",
                        updatedAt: "Date updated",
                        resolvedByName: "Resolved by",
                        transactionId: "Payment system transaction ID",
                        paymentMethod: "Payment system type",
                        superAdminComment: "Super admin comment",
                        paymentAccountRecipientName: "Recipient name",
                        paymentAccountAccountId: "Payment system ID",
                        paymentAccountCurrency: "Account currency",
                        actions: {
                            columnLabel: "Actions",
                            executeButtonLabel: "Complete payment",
                        },
                    },
                    credits: {
                        label: "Loans",
                        totalCredits: "Total loans:",
                        table: {
                            templeName: "Temple name",
                            transactionAmount: "Amount (current transactions), $",
                            transactionPrayersAmount: "Amount of payments, $",
                            transactionDonationsAmount: "Amount of donations, $",
                            transactionsCount: "Number of transactions",
                            transactionsPrayersCount: "Number of payments",
                            transactionsDonationsCount: "Number of donations",
                            dateOfApprovalLatestPayout:
                                "Date of the last completed payment",
                            defaultPaymentMethod: "Default payment method",
                            activePayouts: "Number of active payments",
                            actions: {
                                columnLabel: "Actions",
                                executeButtonLabel: "Create payout",
                            },
                        },
                        modalText1: "Create a payment for all active transactions",
                        title: "Creating payout",
                        modalText3: "Select details to create payment:",
                        modalCreateAndOpenButton: "Create and open payout",
                        modalCreateAndReturnButton: "Create and return to credits",
                        modalDiscardButton: "Cancel",
                        createPayoutsButton: "Create payouts",
                        amount: "Amount, $",
                    },
                    details: {
                        title: "Payout details",
                        informationTitle: "Information",
                        paymentsTitle: "Payments",
                        finishButton: "Finish payout",
                        print: "Print",
                        saveAsPDF: "Save as PDF document",
                    },
                    resolveModal: {
                        transactionId: "Payment system transaction ID",
                        comment: "Comment",
                        resolveButton: "Finish",
                    },
                },
                footer: {
                    paymentCount: "Number of payments: ",
                    payoutCount: "Number of payouts: ",
                    pending: "Pending: ",
                    done: "Done: ",
                    onAccount: "On the account: ",
                },
                header: {
                    searchPlaceholder: "Search...",
                    all: "Total shrines:",
                    dropdownList: {
                        update: "Update",
                        newComplaint: "New complaint",
                        admission: "Admission",
                    },
                },
                main: {
                    title: "Update",
                    temple: {
                        title: "Temples:",
                        mainView: "watch all",
                        all: "Total temples",
                        pending: "Pending",
                        deleted: "Deleted",
                    },

                    payments: {
                        title: "Payments:",
                        mainView: "watch all",
                        all: "Everything came",
                        done: "Done",
                        processing: "In processing",
                        commission: "Site commission",
                        support: "Project support",
                    },
                },
                sidebar: {
                    main: "Home",
                    temples: "Temples",
                    payments: "Payments",
                    updates: "History of updates",
                    visited: "Statistics",
                    messenger: "Messenger",
                    graphics: "Graphs",
                    transfers: "Translations",
                    goOut: "Go out",
                    customerSupportTickets: "Customer support",
                    payouts: "Payouts",
                },
                temples: {
                    table: {
                        temple: "Shrine",
                        country: "Country",
                        religion: "Religion",
                        currents: "Current",
                        income: "Income",
                    },
                    header: {
                        temples: "Temples",
                        total: "Total temples: ",
                        clearFilter: "Clear filter",
                    },
                    filter: {
                        temple: "Shrine",
                        country: "Country",
                        religion: "Religin",
                        current: "Current",
                        sumFrom: "Sum from:",
                        sumBefore: "Sum before:",
                        applyButton: "Apply",
                    },
                    temple: {
                        main: {
                            review: "Shrine review",
                            info: "Information",
                            payments: "Payments",
                            activities: "Activities",
                        },
                        info: {
                            register: "Registration Date",
                            confirm: "Registration confirmation date",
                            name: "Name of the shrine",
                            country: "Country",
                            city: "City",
                            religion: "Religion",
                            current: "Current",
                            subspecies: "Subspecies",
                            representative: "Representative",
                            phone: "Representative contact",
                            email: "Shrine e-mail",
                            abbot: "Abbot",
                            payments: "Payment system",
                            paymentsId: "identifier",
                            bank: "Bank details ",
                            currency: "Currency of account: ",
                        },
                        payments: {},
                        activities: {},
                    },
                },
                customerSupport: {
                    header: "Customer support",
                    totalCSTCount: "Total count: ",
                    table: {
                        ticketName: "Name",
                        subjectType: "Subject",
                        fullName: "Customer name",
                        customerRole: "Customer Role",
                        emailSent: "Email sent",
                        isCustomerRegistered: "The user is authorized",
                        isEmailRegistered: "Email is registered",
                        createdAt: "Date of creation",
                    },
                    fields: {
                        description: "Decription",
                    },
                    details: {
                        editButtonLabel: "Change status",
                        sendEmailButtonLabel: "Send Email",
                    },
                    editModal: {
                        title: "Edit Appeal",
                    },
                    createTicketForm: {
                        header: "Contact us",
                        middleName: "Patronymic name (optional)",
                        email: "E-mail (if you are a registered user, please provide your e-mail address shown at registration)",
                        country: "Country\\Region",
                        customerRole:
                            "You are referring as (select as appropriate)",
                        description: "Message text",
                        conditionsAccepted:
                            "By checking this box you agree to our Terms of Use and Privacy Policy, and agree to be contacted by phone and e-mail regarding your inquiry. We will process your data in accordance with our Privacy Policy.",
                        subjectType: "Select the issue you are interested in",
                        sendButton: "Submit",
                        successMessage:
                            "Your request {{ticketName}} has been sent to support. We will contact you using the e-mail address that was specified when registering the request.",
                        successTitle: "Appeal created",
                        successButton: "OK",
                    },
                    faq: {
                        contactUsText: "Still have questions?",
                    },
                },
            },
            updates: {
                header: "UPDATE HISTORY",
                totalUpdates: "Total updates: ",
                inReview: "Update requests: ",
                table: {
                    temple: "Shrine",
                    statusHeader: "Status",
                    requestTime: "Request submission time",
                    confirmation: "Confirmation",
                    status: {
                        inReview: "Expected from ",
                        approved: "Executed from ",
                        declined: "Declined from ",
                    },
                },
                update: {
                    header: "CHANGES",
                    changesCount: "Number of changes per temple: ",
                    visitingTemple: "Viewing the Temple",
                    current: "It is",
                    original: "It was",
                    confirmButton: "Confirm",
                    approveAllButton: "Approve all",
                    declineAllButton: "Decline all",
                },
            },
            general: {
                resetFilter: "Clean the filter",
                selectRowButton: "Select row",
                selectAllRowsButton: "Select all rows on page",
            },
            charts: {
                applyButton: "Apply",
                compareButton: "Compare",
                selectYear: "Select Year",
                selectMonth: "Select Month",
                selectDay: "Select Day",
            },
            enums: {
                supportTicketCustomerRole: {
                    [SupportTicketCustomerRole.GUEST]: "User",
                    [SupportTicketCustomerRole.TEMPLE_ADMIN]:
                        "Holy Place Representative",
                },
                supportTicketSubject: {
                    [SupportTicketSubject.REGISTRATION]: "Register",
                    [SupportTicketSubject.ORDER_PRAYER]: "Ordering prayers",
                    [SupportTicketSubject.SUPPORT_TEMPLE]:
                        "Supporting the Holy Place",
                    [SupportTicketSubject.PAYMENTS]: "Payments",
                    [SupportTicketSubject.OFFER]: "Offers",
                    [SupportTicketSubject.TROUBLESHOOT]: "Troubleshooting",
                    [SupportTicketSubject.COLLABORATION]: "Collaboration",
                    [SupportTicketSubject.ADVERTIZING]: "Advertising",
                    [SupportTicketSubject.OTHER]: "Other",
                },
                recordStatus: {
                    [RecordStatus.CREATED]: "Created",
                    [RecordStatus.APPROVED]: "Approved",
                    [RecordStatus.DECLINED]: "Declined",
                    [RecordStatus.IN_REVIEW]: "In review",
                },
                language: {
                    [Langs.UA]: "Ukrainian",
                    [Langs.EN]: "English",
                    [Langs.RU]: "Russian",
                }
            },
            confirmation: {
                title: "Confirmation of registration",
                inProcess: "Checking your registration...",
                error: "An error occurred, please try again later.",
                expired: "The registration time has expired.",
                alreadyExist: "Unable to retry the confirmation operation.",
                success:
                    "You have successfully confirmed your email address. To log in to your account follow the ",
                link: "link",
                subtitle: "Return to main ",
                subtitleLink: "page",
                correspondenceEmail: {
                    title: "Confirmation of correspondence email",
                    subtitle: "Return to ",
                    subtitleLink: "Admin Panels",
                    success:
                        "You have successfully verified your email address. To return to the shrine registration, go to ",
                },
            },
            months: {
                [Months.JANUARY]: "January",
                [Months.FEBRUARY]: "February",
                [Months.MARCH]: "March",
                [Months.APRIL]: "April",
                [Months.MAY]: "May",
                [Months.JUNE]: "June",
                [Months.JULY]: "July",
                [Months.AUGUST]: "August",
                [Months.SEPTEMBER]: "September",
                [Months.OCTOBER]: "October",
                [Months.NOVEMBER]: "November",
                [Months.DECEMBER]: "December",
            },
        },
    },

    ru: {
        translation: {
            religions: {
                undefined: "",
                CHRISTIANITY: "Христианство",
            },
            religionCurrents: {
                undefined: "",
                CATHOLICISM: "Католицизм",
                ORTHODOXY: "Православие",
                MONOPHYSITISM:
                    "Монофизитство (Древневосточные православные церкви)",
                NESTORIANISM: "Несторианство (Ассириская Церковь Востока)",
                PROTESTANTISM: "Протестантизм",
            },
            religionSubspecies: {
                undefined: "",
                ROMAN_CATHOLIC_CHURCH: "Римско-католическая церковь",
                ARMENIAN_CATHOLIC_CHURCH: "Армянская католическая церковь",
                UKRAINIAN_GREEK_CATHOLIC_CHURCH:
                    "Украинская греко-католическая церковь",
                MUKACHEVO_GREEK_CATHOLIC_EPARCHY:
                    "Мукачевская греко-католическая епархия",
                RUTHENIAN_GREEK_CATHOLIC_CHURCH:
                    "Русинская греко-католическая церковь",
                SLOVAK_GREEK_CATHOLIC_CHURCH:
                    "Словацкая греко-католическая церковь",
                HUNGARIAN_GREEK_CATHOLIC_CHURCH:
                    "Венгерская греко-католическая церковь",
                APOSTOLIC_EXARCHATE_OF_THE_GREEK_CATHOLIC_CHURCH_IN_THE_CZECH_REPUBLIC:
                    "Апостольский экзархат греко-католической церкви в Чехии",
                CROATIAN_GREEK_CATHOLIC_CHURCH:
                    "Хорватская греко-католическая церковь",
                BELARUSIAN_GREEK_CATHOLIC_CHURCH:
                    "Белорусская греко-католическая церковь",
                RUSSIAN_GREEK_CATHOLIC_CHURCH:
                    "Русская греко-католическая церковь",
                ROMANIAN_GREEK_CATHOLIC_CHURCH:
                    "Румынская греко-католическая церковь",
                BULGARIAN_CATHOLIC_CHURCH: "Болгарская католическая церковь",
                MACEDONIAN_GREEK_CATHOLIC_CHURCH:
                    "Македонская греко-католическая церковь",
                GREEK_CATHOLIC_CHURCH: "Греко-католическая церковь",
                ALBANIAN_CATHOLIC_CHURCH: "Албанская католическая церковь",
                ITALO_ALBANIAN_CATHOLIC_CHURCH:
                    "Итало-албанская католическая церковь",
                MELKITE_GREEK_CATHOLIC_CHURCH:
                    "Мельхитская греко-католическая церковь",
                COPTIC_CATHOLIC_CHURCH: "Коптская католическая церковь",
                ETHIOPIAN_CATHOLIC_CHURCH: "Эфиопская католическая церковь",
                MARONITE_CHURCH: "Маронитская католическая церковь",
                SYRIAC_CATHOLIC_CHURCH: "Сирийская католическая церковь",
                SYRO_MALANKARA_CATHOLIC_CHURCH:
                    "Сиро-маланкарская католическая церковь",
                CHALDEAN_CATHOLIC_CHURCH: "Халдейская католическая церковь",
                SYRO_MALABAR_CATHOLIC_CHURCH:
                    "Сиро-малабарская католическая церковь",

                CONSTANTINOPOLITAN_PATRIARCHATE: "Патриархат Константинополя",
                ALEXANDRIAN_PATRIARCHATE:
                    "Греческий православный патриархат Александрии",
                ANTIOCHIAN_PATRIARCHATE:
                    "Греческий православный патриархат Антиохии",
                JERUSALEM_PATRIARCHATE:
                    "Греческий православный патриархат Иерусалима",
                MOSCOW_PATRIARCHATE: "Русский православный патриархат Москвы",
                SERBIAN_PATRIARCHATE: "Сербский православный патриархат",
                ROMANIAN_PATRIARCHATE: "Румынский православный патриархат",
                BULGARIAN_PATRIARCHATE: "Болгарский православный патриархат",
                GEORGIAN_PATRIARCHATE: "Грузинский православный патриархат",
                CYPRUS_ORTHODOX_CHURCH: "Церковь Кипра",
                GREEK_ORTHODOX_CHURCH: "Греческая православная церковь",
                POLISH_ORTHODOX_CHURCH: "Польская православная церковь",
                ALBANIAN_ORTHODOX_CHURCH: "Албанская православная церковь",
                ORTHODOX_CHURCH_CZECH_LANDS_SLOVAKIA:
                    "Православная церковь в Чешских землях и Словакии",
                ORTHODOX_CHURCH_IN_AMERICA: "Православная церковь в Америке",
                UKRAINIAN_ORTHODOX_CHURCH: "Украинская православная церковь",
                UKRAINIAN_ORTHODOX_CHURCH_USA:
                    "Украинская православная церковь в США",
                UKRAINIAN_ORTHODOX_CHURCH_CANADA:
                    "Украинская православная церковь в Канаде",
                AMERICAN_CARPATHO_RUSSIAN_ORTHODOX_DIACONATE:
                    "Американская Карпато-русская православная епархия",
                WESTERN_EUROPEAN_EXARCHATE:
                    "Западно-европейский экзархат Русской Православной Церкви",
                FINNISH_ORTHODOX_CHURCH: "Финская православная церковь",
                ESTONIAN_APOSTOLIC_ORTHODOX_CHURCH:
                    "Эстонская апостольская православная церковь",
                CRETAN_ORTHODOX_CHURCH: "Критская православная церковь",
                KOREAN_ORTHODOX_CHURCH: "Корейская православная церковь",
                ANTIOCHIAN_ORTHODOX_ARCHDIOCESE_OF_NORTH_AMERICA:
                    "Антиохийская православная христианская архиепархия Северной Америки",
                SINAI_ORTHODOX_CHURCH:
                    "Православная церковь Синайского полуострова",
                UKRAINIAN_ORTHODOX_CHURCH_OF_THE_MOSCOW_PATRIARCHATE:
                    "Украинская православная церковь Московского патриархата",
                RUSSIAN_ORTHODOX_CHURCH_ABROAD:
                    "Русская православная церковь за границей",
                MOLDOVAN_ORTHODOX_CHURCH: "Молдавская православная церковь",
                LATVIAN_ORTHODOX_CHURCH: "Латвийская православная церковь",
                ESTONIAN_ORTHODOX_CHURCH_OF_THE_MOSCOW_PATRIARCHATE:
                    "Эстонская православная церковь Московского патриархата",
                JAPANESE_ORTHODOX_CHURCH: "Японская православная церковь",
                CHINESE_AUTONOMOUS_ORTHODOX_CHURCH:
                    "Китайская автономная православная церковь",
                BELARUSIAN_EXARCHATE: "Белорусский экзархат",
                ORTHODOX_OHRID_ARCHBISHOPRIC: "Охридская архиепископия",
                BESSARABIAN_METROPOLITANATE: "Бессарабская митрополия",

                ARMENIAN_APOSTOLIC_CHURCH: "Армянская Апостольская Церковь",
                COPTIC_ORTHODOX_CHURCH: "Коптская православная церковь",
                ETHIOPIAN_ORTHODOX_CHURCH: "Эфиопская православная церковь",
                SYRIAC_ORTHODOX_CHURCH: "Сирийская православная церковь",
                MALANKARA_ORTHODOX_SYRIAN_CHURCH:
                    "Маланкарская православная сирийская церковь",
                ERITREAN_ORTHODOX_TEWAHEDO_CHURCH:
                    "Эритрейская православная церковь Тевахедо",
                MOTHER_SEE_OF_HOLY_ETCHEMIADZIN:
                    "Материнская Святая обители Эчмиадзин",
                ARMENIAN_PATRIARCHATE_OF_JERUSALEM:
                    "Армянское патриархат Иерусалима",
                ARMENIAN_PATRIARCHATE_OF_CONSTANTINOPLE:
                    "Армянское патриархат Константинополя",
                CILICIAN_ARMENIAN_CATHOLICATE:
                    "Киликийский армянский католикат",

                ASSYRIAN_CHURCH_OF_THE_EAST: "Ассирийская церковь Востока",
                ANCIENT_CHURCH_OF_THE_EAST: "Древняя церковь Востока",

                LUTHERANISM: "Лютеранство",
                ANGLICANISM: "Англиканство",
                CALVINISM: "Кальвинизм",
                ANABAPTISM: "Анабаптизм",
                BAPTISM: "Баптизм",
                EVANGELICALISM: "Евангелизм",
            },
            fields: {
                name: "Название храма",
                religionId: "Религия",
                religionCurrentId: "Течение",
                religionSubspeciesId: "Подвид",
                country: "Страна",
                city: "Город",
                info: "Информация о храме",
                abbotInfo: "Информация о настоятеле",
                shopInfo: "Информация в магазин",
                worshipLink: "Ссылка на онлайн богослужение",
                tourLink: "Ссылка на 3D тур",
                contactCountry: "Контакты: страна",
                contactCity: "Контакты: город",
                contactStreet: "Контакты: улица",
                contactBuildingNumber: "Контакты: номер дома",
                contactPhone: "Контакты: телефон",
                contactSecondPhone: "Контакты: второй телефон",
                contactEmail: "Контакты: почта",
                contactInfo: "Контакты: дополнительная информация",
                header: "Заголовок",
                body: "Информационный текст",
                fullName: "Полное имя",
                iban: "IBAN",
                accountId: "Номер счета",
                currency: "Валюта",
                paymentMethod: "Способ перевода",
                recipientName: "Имя получателя",
                defaultPaymentAccount: "Платежная система по умолчанию",
                order: "Порядок",
                type: "Тип",
                period: "Период",
                maxPeople: "Максимальное количество людей",
                price: "Цена",
                mondayBegin: "Понедельник, начало",
                mondayEnd: "Понедельник, конец",
                tuesdayBegin: "Вторник, начало",
                tuesdayEnd: "Вторник, конец",
                wednesdayBegin: "Среда, начало",
                wednesdayEnd: "Среда, конец",
                thursdayBegin: "Четверг, начало",
                thursdayEnd: "Четверг, конец",
                fridayBegin: "Пятница, начало",
                fridayEnd: "Пятница, конец",
                saturdayBegin: "Суббота, начало",
                saturdayEnd: "Суббота, конец",
                sundayBegin: "Воскресенье, начало",
                sundayEnd: "Воскресенье, конец",
                useStandardPrayersInfo:
                    "Использовать стандартный текст (молитвы)",
                useStandardTempleSupportInfo:
                    "Использовать стандартный текст (поддержка храма)",
                templeSupportInfo: "Информация о поддержке храма",
                prayersInfo: "Информация о молитвах",
                useStandardPrayersEmail:
                    "Использовать стандартный текст для email (молитвы)",
                useStandardTempleSupportEmail:
                    "Использовать стандартный текст для email (поддержка храма)",
                templeSupportEmail: "Email о поддержке храма",
                prayersEmail: "Email о молитвах",
                isDeleted: "Удалено",
                files: "Изображения",
                abbotInfoFiles: "Изображения настоятеля",
                shopInfoFiles: "Изображения магазина",
                galleryFiles: "Галерея",
                date: "Дата",
                begin: "Начало",
                end: "Конец",
                email: "Email",
                status: "Статус",
                phoneNumber: "Номер телефона",
                comment: "Комментарий",
                language: "Язык",
                createdAt: "Дата создания",
                updatedAt: "Дата обновления",
            },
            entity: {
                [Entity.TEMPLE]: "Храм",
                [Entity.ARTICLE]: "Статья",
                [Entity.ARTIFACT]: "Артефакт",
                [Entity.BANK_ACCOUNT]: "Банковские реквизиты",
                [Entity.PAYMENT_ACCOUNT]: "Платежные системы",
                [Entity.PRAYER]: "Молитвы",
                [Entity.PRAYER_PRICE_DETAIL]: "Информация о цене молитв",
                [Entity.PRAYER_REQUIREMENT]: "Молитвенные требования",
                [Entity.WEEK_SCHEDULE]: "Недельное расписание",
                [Entity.WORSHIP_SCHEDULE]: "Расписание богослужений",
            },
            templeSortFields: {
                [TempleSortFields.NAME]: "По алфавиту",
                [TempleSortFields.CREATED_AT]: "По дате регистрации",
                [TempleSortFields.POPULAR]: "По популярности",
                [TempleSortFields.VISITS]: "По посещаемости",
            },
            section: {
                1: "Основное",
                2: "Настоятель святыни",
                3: "Магазин святыни",
                4: "Дополнительная информация о святыне",
                5: "Заказ молитв",
            },
            payments: {
                paymentFailedToCreate: "Платеж не создан.",
                paymentFailed: "Возникла ошибка при создании платежа.",
                paymentCreated:
                    "Платеж создан, но не завершен. Обратитесь к Администратору для проверки состояния платежа.",
                paymentWasNotCreated: "Платеж не создан. Попытайтесь еще раз.",
            },
            prayer: {
                type: {
                    1: "За здравие",
                    2: "За упокой",
                },
                period: {
                    1: "Однодневные",
                    2: "На долгий период",
                },
            },
            weekDays: {
                [Week.MONDAY]: "Понедельник",
                [Week.TUESDAY]: "Вторник",
                [Week.WEDNESDAY]: "Среда",
                [Week.THURSDAY]: "Четверг",
                [Week.FRIDAY]: "Пятница",
                [Week.SATURDAY]: "Суббота",
                [Week.SUNDAY]: "Воскресенье",
            },
            shortWeekDays: {
                [Week.MONDAY]: "Пн",
                [Week.TUESDAY]: "Вт",
                [Week.WEDNESDAY]: "Ср",
                [Week.THURSDAY]: "Чт",
                [Week.FRIDAY]: "Пт",
                [Week.SATURDAY]: "Сб",
                [Week.SUNDAY]: "Вс",
            },
            user: {
                header: {
                    search: "Поиск...",
                    religions: "Святыни",
                    orderPrayer: "Заказать молитву",
                    support: "Поддержка святынь",
                    temple: "Онлайн богослужение",
                },
                footer: {
                    pages: "Страницы:",
                    main: "Главная",
                    religions: "Религии",
                    popular: "Святыни",
                    about: "О проекте",
                    temple: "Онлайн богослужение",
                    orderPrayer: "Заказ молитвы",
                    offering: "Поддержка святынь",
                    support: "Поддержать проект",
                    templeRepresentatives: "Представителям храма",
                    confidentiality: "Условия конфиденциальности",
                    rules: "Правила пользования",
                    fullVersion: "Полная версия сайта",
                },
                main: {
                    top: {
                        current: "Конфессия:",
                        place: "Место нахождения:",
                    },
                    holy: {
                        title: "Все святыни в одном месте.",
                        subtitle1: "Посещение святынь",
                        text1: "Наш проект (название проекта) дарит Вам уникальную возможность в одном месте, в любое время и из любой точки мира посетить известные религиозные святые места и воспользоваться их услугами.",
                        text2: "Мы желаем, чтобы Ваш визит был не только максимально интересен, но и даровал счастье, радость и духовное удовлетворение Вам и Вашим близким!",
                        text3: "Большинство из представленных на сайте святынь являются также действующими религиозными объектами, проводящими богослужения.",
                        text4: "Вы можете заказать молитвы, требы, мессы, богослужения или воспользоваться другими услугами избранной Вами святыни, и Ваши пожелания, просьбы и обращения прозвучат в ней, даже если Вы находитесь в тысяче километров от нее.",
                        subtitle2: "Онлайн Богослужение",
                        text5: "Проект предоставляет возможность не только дистанционно посетить и воспользоваться услугами различных религиозных объектов, но и непосредственно принимать участие в проходящих в них процессах и богослужениях.",
                        text6: "Несмотря на обстоятельства и где бы вы ни находились, у Вас есть уникальная возможность присутствовать в таких действах пользуясь услугой Онлайн богослужений.",
                    },
                    order: {
                        clickLabel: " в три клика",
                        selectReligion: "Выберите религию",
                        selectTemple: "Выберите святыню",
                        orderPrayer: "Закажите молитву",
                    },
                    popular: {
                        title: "Популярные святыни",
                        religion: "Религия:",
                        city: "Город:",
                        details: "Подробнее",
                    },
                },
                religions: {
                    redirectPages: {
                        [Page.TEMPLE]: "",
                        [Page.CHOOSE_TEMPLE]: "",
                        [Page.ORDER_PRAYER]:
                            "Выберите святыню, в которой Вы хотите заказать молитву",
                        [Page.SUPPORT]:
                            "Выберите святыню, которой хотите направить пожертвование",
                        [TempleSearchParameters.IS_WORSHIP_LINK_EXIST]:
                            "Выберите святыню для просмотра онлайн богослужения",
                    },
                    base: {
                        religion: "Религия",
                        current: "Течение",
                        subspecies: "Подвид",
                    },
                    filter: {
                        title: "Выбор святыни",
                        searchButton: "Поиск",
                        resetButton: "Сбросить фильтр",
                    },
                    header: {
                        title: "Все Святыни, где Вы можете заказать молитву",
                        sort: "Сортировать",
                        abc: "Алфавит",
                        popular: "Популярность",
                        visits: "Посещаемость",
                        date: "Дата регистрации",
                        choose: "Выбор святыни",
                        country: "Страна",
                        city: "Город",
                        other: "Другое",
                        withoutTemplesTitle:
                            "Храмы для отображения отсутствуют",
                    },
                    row: {
                        location: "Расположение:",
                    },
                },
                temple: {
                    abbot: {
                        subtitle: "Настоятель святыни",
                        moreInfo: "Читать больше",
                    },
                    basics: {
                        subtitle: "Основные святыни",
                    },
                    contacts: {
                        subtitle: "Контакты",
                        office: "Канцелярия",
                        additional: "Паломнический отдел (поездки, экскурсии):",
                        phone: " - основной",
                        secondPhone: " - дополнительный",
                        email: " - Электронная почта",
                    },
                    information: {
                        subtitle: "Информация для посетителей",
                        schedule: "График Работы",
                        worship: "Расписание богослужений",
                        shop: "Церковная лавка",
                        visitors: "Посетителям",
                        other: "Другое",
                    },
                    main: {
                        location: "Расположение:",
                        religion: "Религия:",
                        current: "Течение:",
                        subspecies: "Подвид:",
                        moreInfo: "Читать больше",
                        supportButton: "Поддержка святыни",
                        orderPrayerButton: "Заказать молитву",
                    },
                    online: {
                        subtitle: "Онлайн богослужение",
                    },
                    sidebar: {
                        main: "О святыне",
                        abbot: "Настоятель святыни",
                        basic: "Основные святыни",
                        info: "Информация для посетителей",
                        support: "Заказ молитв",
                        online: "Онлайн богослужение",
                        online3d: "3D Тур",
                        gallery: "Фотогалерея",
                        contacts: "Контакты",
                    },
                    support: {
                        subtitle1: "Заказ молитв и поддержка святыни",
                        subtitle2: "Заказ молитв",
                        subtitle3: "Поддержка святыни",
                        orderPrayerButton: "Заказать молитву",
                        supportButton: "Поддержка святыни",
                        text1: "Если вы не можете посетить Святыню, но хотите заказать молитву за ваших близких и родных – вы можете сделать это ниже, отправив записку с сайта.",
                        text2: "Ниже Вы можете внести свой вклад и поддержать близкую душу и сердцу Святыню, большинство из которых существуют благодаря Вашим пожертвованиям. Любой Ваш вклад будет бесценен!",
                    },
                    worships: {
                        day: "День",
                        date: "Дата",
                        hour: "Час",
                        worship: "Богослужение",
                        worshipsNotFound: "Богослужений не знайдено",
                        backToCurrentWeek: "Вернуться к текущей неделе",
                    },
                },
                prayer: {
                    successModal: {
                        title1: "Записка ",
                        title2: " успешно заказана. Спасибо Вам!",
                    },
                    accordion: {
                        button: "Заказ молитв",
                        subtitle1: "Однодневные записки",
                        subtitle2: "Записки на долгий период",
                        details: {
                            people1: "до",
                            people2: "имен",
                        },
                    },
                    header: {
                        subtitle: "Заказ молитвы",
                        text: "Дорогие друзья! Если Вы не можете посетить Святыню, но хотите заказать молитву за Ваших близких и родных – Вы можете сделать это ниже, отправив записку с сайта.",
                    },
                    item: {
                        namePlaceholder: "Имя",
                    },
                    payer: {
                        prayerNoteName: "Название записки",
                        addButton: "Добавить имя",
                        email: "Ваш E-mail",
                        fullName: "Ваш ФИО",
                        text: "Ваше примечание",
                        agreement1: "Соглашаюсь с ",
                        agreement2:
                            "Условиями конфиденциальности, Правилами пользования",
                        agreement3:
                            " и даю согласие на обработку персональных данных.",
                        prayerButton: "Заказать",
                    },
                },
                support: {
                    successModal: {
                        title1: "Помощь ",
                        title2: "святыне ",
                        title3: "платформе",
                        title4: " успешно перечислена. Спасибо Вам!",
                    },
                    temple: {
                        title: "Поддержка святыни",
                        text: "Дорогие друзья! Пользуясь нашим сайтом Вы можете внести свой вклад и поддержать близкую Вашим религиозным и духовным пристрастиям Святыню, большинство из которых существуют благодаря Вашим пожертвованиям. Любой Ваш вклад будет бесценен!",
                    },
                    project: {
                        title: "Поддержать проект",
                        text: "Дорогие друзья! Реализация данного проекта призвана делать людей счастливыми и предоставить каждому возможность дарить счастье другим! Мы будем рады, если разделяя наши стремления, Вы поддержите проект своими пожертвованиями, которые Вам по силам. Поддерживая проект – Вы инвестируете в счастье!",
                    },
                    offeringText:
                        "Выбрав валюту и введя в поле ниже сумму и нажав кнопку Пожертвовать, Вы перейдете к удобному для Вас способу перечисления.",
                    offeringButton: "Пожертвовать",
                    fullNamePlaceholder: "ФИО",
                },
                about: {
                    title: "О проекте",
                    text1: "Современный мир развивается стремительными темпами, внося свои коррективы в жизнь каждого из нас. Ежедневная суета отнимает у нас все больше свободного времени, и все меньше его остается для личных нужд.",
                    text2: "В круговороте жизни мы порой забываем не только о родных, друзьях и близких, но иногда совсем забываем и о необходимости гармонии собственной души и тела. Такие условия минимизируют духовную гармонию и ее развитие, а иногда, для него совсем не остается места…",
                    text3: "Именно для решения таких вызовов и создан проект Holly Place Visit.",
                    text4: "Целью проекта ______ является, максимально используя современные тенденции и технологии, минимизировать их негативные аспекты. Другими словами, _____ используя современные технологии, увеличивает возможности и упрощает доступ к известным Святыням и их услугам.",
                    text5: "Проект __ призван предоставлять широкий спектр возможностей, основными из которых являются:",
                    text6: "знакомство с информацией о любой Святыне, которую предоставили ее представители; ",
                    text7: "в режиме реального времени присутствовать на духовных процессиях Святынь с использованием функции Онлайн Богослужения;",
                    text8: "в режиме «3D тура» дистанционно посещать известные Святыни; ",
                    text9: "с помощью функции Заказать молитву, Вы можете заказать молитвы, требы, мессы, богослужения или воспользоваться другими услугами избранной Вами Святыни, и Ваши пожелания, просьбы и обращения прозвучат в ней, даже если Вы находитесь в тысяче километров от нее. ",
                    text10: "также Вы можете внести свой вклад и направить пожертвования на поддержку и развитие близкой души и сердцу Святыни, воспользовавшись функцией «Поддержка Святынь».",
                    text11: "Проект _____ является открытым для всех Святынь и мы надеемся, что он станет местом обретения гармонии для каждого его посетителя!",
                    text12: "Holly Place Visit - Известные святыни, в одном месте, из любой точки мира!",
                },
                faq: {
                    footerButton: "Support",
                    title: "Часто задаваемые вопросы",
                    title2: "Ответы",
                    about: {
                        aboutSubtitle: "О проекте",
                        aboutQuestion1: "Что такое Holy Place Visit?",
                        aboutAnswer1:
                            "Holy Place Visit это интерактивная специализированная интернет платформа,  позволяющая в дистанционном формате ознакомится с разнообразными религиозными святынями мира, а также воспользоваться их услугами.",
                        aboutQuestion2:
                            "Какие возможности предоставляет проект для пользователя?",
                        aboutAnswer2_1:
                            "Проект призван предоставлять широкий спектр возможностей, основными из которых являются:",
                        aboutAnswer2_2:
                            "ознакомление с информацией о любой Святыне, которой поделились ее представители;",
                        aboutAnswer2_3:
                            "в режиме реального времени присутствовать на духовных процессиях Святынь с использованием функции «Онлайн Богослужения»;",
                        aboutAnswer2_4:
                            "в режиме «3D тура» дистанционно посещать известные Святыни;",
                        aboutAnswer2_5:
                            "с помощью функции «Заказать молитву», заказать молитвы, требы, мессы, богослужения или воспользоваться другими услугами выбранной Вами Святыни, и Ваши пожелания, просьбы и обращения прозвучат в ней, даже если Вы находитесь за тысячи километров от нее.",
                        aboutAnswer2_6:
                            "также Вы можете сделать свой вклад и направить пожертвования на поддержку и развитие близкой душе и сердцу Святыни, воспользовавшись функцией «Поддержка Святынь».",
                        aboutQuestion3:
                            "Какие возможности проект предоставляет Святыням и их Представителям?",
                        aboutAnswer3_1:
                            "Представителям Святынь проект предлагает уникальную возможность зарегистрировать Святыню на сайте.",
                        aboutAnswer3_2:
                            "Это позволит в доступной форме максимально распространить информацию о Святыне, как можно больше увеличить ее популярность, расширить количество прихожан и последователей.",
                        aboutAnswer3_3:
                            "Используя современные технологии, платформа дает возможность Святыням дистанционно предоставлять все имеющиеся услуги, включая платные, а также получать пожертвования.",
                        aboutQuestion4: "Как воспользоваться услугами проекта?",
                        aboutAnswer4_1:
                            "Для Пользователей изначально доступны все возможности проекта.",
                        aboutAnswer4_2:
                            "Для использования проекта в качестве Представителя Святыни предусмотрена процедура предварительной регистрации (создания профиля).",
                        aboutQuestion5:
                            "Что такое Условия предоставления услуг и Политика конфиденциальности?",
                        aboutAnswer5:
                            "Условия предоставления услуг (Условия использования Сайта) и Политика конфиденциальности это обобщенный свод его правил и условий, ознакомление и согласие с которыми предоставляет Вам право пользоваться услугами проекта.",
                    },
                    gettingStarted: {
                        gettingStartedSubtitle: "Начало работы",
                        gettingStartedQuestion1:
                            "Нужна ли мне регистрация для использования проекта?",
                        gettingStartedAnswer1_1:
                            "Мы с уважением относимся к религиозным чувствам всех граждан и понимаем их желания конфиденциальности, поэтому для максимальной комфортности Пользователям проекта доступны все его возможности с первой секунды использования и без какой-либо регистрации.",
                        gettingStartedAnswer1_2:
                            "Для использования проекта в качестве Представителя Святыни предусмотрена процедура предварительной регистрации (создания профиля).",
                        gettingStartedQuestion2:
                            "Могу ли я стать Представителем Святыни, и какие к ним требования?",
                        gettingStartedAnswer2:
                            "Представителю Святыни предоставляется  возможность создавать и редактировать профиль Святыни на сайте. Поэтому, Представителем Святыни может быть только лицо, обладающее соответствующими полномочиями.",
                        gettingStartedQuestion3:
                            "Как найти интересующую Святыню на сайте?",
                        gettingStartedAnswer3_1:
                            "На сайте размещены страницы только тех Святынь, Представители которых подали соответствующую заявку на регистрацию в проекте, и которая была одобрена.",
                        gettingStartedAnswer3_2:
                            "С перечнем зарегистрированных Святынь можно ознакомиться в разделе  «Святыни» либо «Религии».  Интересующую святыню можно отыскать в данных разделах с помощью имеющейся системы фильтров, либо воспользовавшись системой поиска сайта.",
                    },
                    registration: {
                        registrationSubtitle:
                            "Регистрация Святыни (для Представителей Святыни)",
                        registrationQuestion1:
                            "Кто может регистрировать Святыню на сайте?",
                        registrationAnswer1:
                            "Регистрировать Святыню на сайте может только его Представитель – лицо, обладающее соответствующими полномочиями.",
                        registrationQuestion2:
                            "Какие требования к Представителю Святыни?",
                        registrationAnswer2_1:
                            "Представителем Святыни может быль только лицо, обладающее достаточным количеством полномочий для представления Святыни в проекте, внесения и редактирования информации о ней на сайте.",
                        registrationAnswer2_2:
                            "Подтверждение наличия  таких полномочий, их полнота и актуальность могут быть затребованы представителями проекта HolyPlaceVisit в определенной ими форме и способ, в любое время с момента подачи заявки на участие в проекте и до момента окончания участия в ней.",
                        registrationQuestion3: "Кто такой Представитель Храма?",
                        registrationAnswer3:
                            "На сайте могут встречаться кнопки со словосочетанием «Представитель Храма», функционал и значение которых идентично с понятием  «Представитель Святыни».",
                        registrationQuestion4:
                            "Как зарегистрировать Святыню на сайте, либо подать заявку на регистрацию в проекте в качестве Представителя Святыни?",
                        registrationAnswer4_1:
                            "Регистрировать Святыню на сайте может только его Представитель – лицо, обладающее соответствующими полномочиями.",
                        registrationAnswer4_2:
                            "Для подачи заявки на регистрацию в проекте Представитель Святыни должен пройти процедуру регистрации и заполнить необходимую информацию о Святыне указанную в регистрационной форме.",
                        registrationAnswer4_3:
                            "В настоящее время регистрация Святынь на сайте является бесплатной.",
                        registrationQuestion5:
                            "Какую информацию необходимо подать для регистрации Святыни в проекте?",
                        registrationAnswer5_1:
                            "Внесение информации о Святыне производится Представителем Святыни по установленной проектом регистрационной форме.",
                        registrationAnswer5_2:
                            "Регистрационная форма содержит обязательные и необязательные для заполнения разделы. Минимальным требованием для подачи заявки на участие в проекте является заполнение всех обязательные разделов регистрационной формы. Для более успешного использования страницы Святыни на сайте мы рекомендуем указать как можно больше информации о ней.",
                        registrationQuestion6:
                            "Пожертвования и поддержка Святынь, предоставление ими платных услуг.",
                        registrationAnswer6_1:
                            "Проект дает возможность Святыням получать вознаграждение за оказание ими услуг,  оформленных через сайт. Описание таких услуг, требования к ним и их стоимость определяются Представителями Святынь.",
                        registrationAnswer6_2:
                            "Платформа также позволяет Святыням получать финансовые пожертвования.",
                        registrationAnswer6_3:
                            "Пожертвования и средства за платные услуги поступают на указанные Представителем Святыни реквизиты.",
                        registrationQuestion7:
                            "Какой период рассмотрения заявки на участие в проекте Святыни?",
                        registrationAnswer7:
                            "Если Представитель Святыни и направленная им заявка отвечают необходимым требованиям -  заявка будет одобрена в ближайшее время.",
                        registrationQuestion8:
                            "Как узнать, что заявку на регистрацию Святыни одобрено?",
                        registrationAnswer8:
                            "После одобрения заявки на регистрацию Святыни в проекте, ее страница будет доступной для Пользователей сайта. С перечнем зарегистрированных Святынь можно ознакомиться в разделе  «Святыни» либо «Религии». Интересующую святыню можно отыскать в данных разделах с помощью имеющейся системы фильтров, либо воспользовавшись системой поиска сайта.",
                    },
                    services: {
                        servicesSubtitle: "Услуги для пользователей проекта",
                        servicesQuestion1:
                            "С какой информацией о Святынях можно ознакомиться на сайте?",
                        servicesAnswer1_1:
                            "На странице Святыни отображается только та информация, которая была предоставленная ее Представителем.",
                        servicesAnswer1_2:
                            "Проект предусматривает наличие обязательных информационных разделов, таких как название Святыни, ее описание, информацию о настоятеле, графики работы и богослужений, предоставляемые услуги и другую полезную информацию для посетителей, однако конечный объём и содержание информации страницы Святыни определяется ее Представителем.",
                        servicesQuestion2: "Что такое «Онлайн богослужение»?",
                        servicesAnswer2_1:
                            "Это предоставляемый проектом функционал, который с использованием современных технологий дает возможность Пользователю в режиме реального времени присутствовать на духовных процессиях Святынь.",
                        servicesAnswer2_2:
                            "Данный функционал является опциональным, а возможность его предоставления Пользователям определяется Представителем Святыни. Перечень всех Святынь позволяющих использовать функцию «Онлайн богослужение» можно найти в разделе с одноименным названием.",
                        servicesQuestion3: "Что такое 3D тур?",
                        servicesAnswer3_1:
                            "Это предоставляемый проектом функционал, который дает возможность Пользователю дистанционно посещать территорию известных Святынь с эффектом максимального присутствия.",
                        servicesAnswer3_2:
                            "Данный функционал является опциональным и возможность его предоставления Пользователям определяется Представителем Святыни.",
                        servicesQuestion4:
                            "Что подразумевает функция «Заказать молитву»?",
                        servicesAnswer4_1:
                            "С помощью функции «Заказать молитву» Вы может заказать молитвы, требы, мессы, богослужения или воспользоваться другими услугами выбранной Святыни, и Ваши пожелания, просьбы и обращения прозвучат в ней, даже если Вы находитесь за тысячи километров от неё.",
                        servicesAnswer4_2:
                            "Содержание таких услуг, требования к ним и их стоимость определяются Представителями Святынь.",
                        servicesAnswer4_3:
                            "Заказать услугу можно на соответствующем разделе интересующего Вас Святыни, в котором содержится форма и требования к оформлению заказа. Перейти на данную страницу сайта можно нажав на кнопку «Заказать молитву» на странице выбранной Вами Святыни.",
                        servicesAnswer4_4:
                            "Перечень всех Святынь позволяющих использовать функцию «Заказать молитву» можно найти в разделе с одноименным названием на главной странице сайта.",
                        servicesQuestion5:
                            "Что подразумевает функция «Поддержка святынь»?",
                        servicesAnswer5_1:
                            "Проект предусматривает возможность сделать свой вклад и направить пожертвования на поддержку и развитие близкой душе и сердцу Святыни, воспользовавшись функцией «Поддержка Святынь».",
                        servicesAnswer5_2:
                            "Сегодня большинство Святынь существуют благодаря Вашим пожертвованиям, и любой Ваш вклад для них будет бесценен!",
                        servicesAnswer5_3:
                            "Заказать услугу можно на соответствующем разделе интересующего Вас Святыни, в котором  содержится форма и требования к оформлению заказа. Перейти на данную страницу сайта можно нажав на кнопку «Поддержка Святыни» на странице выбранной Вами Святыни.",
                        servicesAnswer5_4:
                            "Перечень всех Святынь позволяющих использовать функцию «Поддержка Святыни» можно найти в разделе с одноименным названием на главной странице сайта.",
                    },
                    cooperation: {
                        cooperationSubtitle:
                            "Сотрудничество, предложения и устранение неисправностей",
                        cooperationQuestion1:
                            "Куда я могу направить свои предложения по работе проекта?",
                        cooperationAnswer1_1:
                            "Мы стремимся стать лучше и всегда будем рады услышать любые комментарии или замечания, способствующие улучшению работы проекта! Ваши обращения по данному вопросу просим направлять в наш адрес, воспользовавшись формой обратной связи с указанием в теме письма «Предложения».",
                        cooperationAnswer1_2:
                            "Перейти на форму обратной связи можно путем нажатия на кнопку «Support» в нижней части главной страницы сайта.",
                        cooperationQuestion2:
                            "Как устранить неполадки или связаться с поддержкой сайта?",
                        cooperationAnswer2_1:
                            "Наши сотрудники постоянно работают над ошибками, которые могут возникать в процессе работы, и методами их устранения. Для оказания помощи в данном вопросе, а также, в случае возникновения у Вас каких-либо неполадок в процессе работы, просим написать нам воспользовавшись формой обратной связи с указанием в теме письма «Устранение неисправностей».",
                        cooperationAnswer2_2:
                            "Перейти на форму обратной связи можно путем нажатия на кнопку «Support» в нижней части главной страницы сайта.",
                        cooperationAnswer2_3:
                            "Популярные обращения, связанные с неполадками в работе, а также методы их устранения, будут опубликованы в данном разделе.",
                        cooperationQuestion3:
                            "Как я могу направить предложения о сотрудничестве?",
                        cooperationAnswer3_1:
                            "Целью проекта является поддержка и популяризация мировых Святынь. С реализацией проекта мы надеемся хотя б немножко сделать мир лучше, добрее и спокойнее!",
                        cooperationAnswer3_2:
                            "Проект является открытым для всех Святынь, и мы надеемся, что он станет местом обретения гармонии для каждого его посетителя!",
                        cooperationAnswer3_3:
                            "Если Ваши цели совпадают с нашими, и у Вас есть интересные предложения к сотрудничеству – просим направить их в наш адрес воспользовавшись формой обратной связи, выбрав «Сотрудничество» в теме обращения.",
                        cooperationAnswer3_4:
                            "Перейти на форму обратной связи можно путем нажатия на кнопку «Support» в нижней части главной страницы сайта.",
                    },
                },
            },
            currency: {
                [Currency.USD]: "USD",
                [Currency.EUR]: "EUR",
                [Currency.PLN]: "PLN",
            },
            payoutStatus: {
                [PayoutStatus.IN_REVIEW]: "Ожидает",
                [PayoutStatus.CANCELED]: "Отклонена",
                [PayoutStatus.EXECUTED]: "Выполнена",
            },
            paymentMethod: {
                1: "Pay Pal",
                2: "Payoneer",
                3: "Skrill",
            },
            transactionType: {
                1: "Платеж",
                2: "Пожертвование",
            },
            base: {
                buttons: {
                    refreshPage: "Обновить страницу",
                },
                photo: {
                    remove: "Удалить",
                },
                successPaymentModal: {
                    moveToTemple: "Вернуться к святыне",
                },
                truncateText: {
                    moreInfo: "Читать больше",
                    lessInfo: "Читать меньше",
                },
                pagination: {
                    itemsPerPage: "Элементов на странице",
                    page: "Страница",
                    of: "из",
                },
            },
            templeRegister: {
                correspondence: {
                    title: "Корреспонденция",
                    instructionText:
                        "<p>Введите адрес электронной почты для получения сообщений от платформы.</p><p>Этот адрес будет использоваться для сообщений о поступлении пожертвований и заказе молитв от пользователей.</p><p>Этот адрес является обязательным для завершения регистрации храма.</p>",
                    saveEmailButtonLabel: "Отправить письмо подтверждения",
                    successEmailModalText:
                        "Письмо подтверждения отправлено на почту",
                },
                base: {
                    title: "Данные",
                    title1: "Дополнительная информация ",
                    title2: " о храме",
                    addPhoto: "Добавить фото",
                },
                footer: {
                    button: "Сохранить",
                    cancelEntity: "Отменить",
                    cancelButton: "Выход",
                    cancelModal: {
                        exit: "Выйти",
                        stay: "Остаться",
                        title: "Вы подтверждаете выход из процесса регистрации храма? Не сохраненные изменения будут утрачены.",
                    },
                },
                required: {
                    artifacts: "Добавьте Основные святыни учреждения",
                    prayers:
                        "Добавьте хотя бы одну Записку и Пожелания к запискам",
                    paymentDetails: "Добавьте платежные системы",
                    bankDetails: "Добавьте банковские реквизиты",
                },
                mainInfo: {
                    titleFirst: "Заполните данные про храм ",
                    titleSecond: "и добавьте фото*",
                    templeName: "Название святыни",
                    country: "Страна",
                    city: "Город",
                    templeInfo: "Информация про святыню",
                    addPhoto: "Добавить фото",
                    photoInfo: "Первое фото будет главным на странице",
                    religionPlaceholder: "Религия",
                    currentPlaceholder: "Течение",
                    subspeciesPlaceholder: "Подвид",
                    customSearchPlaceholder: "Введите текст для поиска",
                    onlineWorshipPresence: "Наличие онлайн богослужения",
                },
                abbot: {
                    subtitle: "Настоятель святыни*",
                    infoPlaceholder: "Информация о настоятеле",
                    label1: "Первое фото будет главным для представителя",
                    subtitle2: "Основные святыни учреждения*",
                    namePlaceholder: "Название святыни",
                    infoPlaceholder2: "Краткая информация",
                    addShrine: "Добавить святыню",
                    abbotInfoTooltip: "Інформация про настоятеля",
                },
                bank: {
                    title: "Банковские реквизиты",
                    fullName: "ФИО",
                    account: "Счет получателя",
                    currency: "Валюта счета",
                    add: "Добавить количество",
                    iban: "IBAN",
                },
                gallery: {
                    subtitle: "Онлайн Богослужение",
                    linkPlaceholder: "Ссылка на онлайн благослужение",
                    subtitle2: "3D тур",
                    label1: "Здесь вы можете загрузить файл на 3D тур",
                    subtitle3: "Фотогалерея",
                    additionalPhoto1: "Вы можете загрузить дополнительные фото",
                    additionalPhoto2: "своего храма",
                    subtitle4: "Контакты",
                    country: "Введите страну",
                    city: "Введите город",
                    street: "Введите улицу",
                    building: "Номер дома",
                    phone: "Номер телефона",
                    phone2: "Дополнительный номер телефона",
                    worshipLinkInstruction: {
                        title: "Как скопировать ссылку на видео с YouTube:",
                        firstStep:
                            "Откройте видео на YouTube, которое вы хотите поделиться.",
                        secondStep: 'Нажмите на кнопку "Поделиться" под видео.',
                        thirdStep:
                            'В открывшемся окне нажмите на кнопку "Скопировать" рядом с ссылкой на видео.',
                        correctLink:
                            "Проверьте, что автор Вашей ссылки предоставил разрешение на возможность его просмотра на других сайтах.",
                        linkExample:
                            "Пример ссылки на видео: https://youtu.be/video-id",
                        finalText:
                            "Теперь ссылка на видео скопирована в буфер обмена и вы можете вставить ее в поле ниже.",
                    },
                    tourPreviewButton: "Предварительный просмотр",
                    tourInstruction: {
                        title: "Как добавить 3D-тур:",
                        info: 'Нажмите "Поделиться" в своем редакторе онлайн 3D-тура, выберите "Поделиться с помощью HTML (Embed code)" и скопируйте это в поле ниже.',
                        example: "Это может выглядеть так:",
                    },
                },
                orders: {
                    title: "Заказ молитв",
                    subtitle:
                        "Текст-обращение к Посетителям для заказа молитв*",
                    textPlaceholder:
                        "Вступительное текстовое сообщение для Посетителей с предложением заказать молитву (от 30 - 100 символов)",
                    useStandardText: "Использовать стандартный текст",
                    name: "Название",
                    type: "Тип",
                    period: "Период",
                    maxPeople: "Людей к:",
                    price: "Цена",
                    info: "Краткое описание",
                    name2: "Название записки",
                    maxPeople2: "до 10 человек",
                    price2: "Цена в долларах",
                    save: "Сохранить записку",
                    label: "Принимаются имена только крестных людей.",
                    subtitle2: "Пожелания и требования к заказу молитв*",
                    textPlaceholder2: "Текст пожеланий (требований)",
                    add: "Добавить пожелания",
                    add2: "Добавить записку",
                    subtitle3: "Информация для заказа поддержки храма*",
                    textPlaceholder3:
                        "Вступительное текстовое сообщение для Посетителей с предложением поддержать святыню (от 30 - 100 символов)",
                    requiredField:
                        'Нужно добавить информацию в поле ниже или выбрать "Использовать стандартный текст"',
                    subtitle4: "Текст ответа лицу, заказавшему молитву",
                    textPlaceholder4: "Текст ответа лицу, заказавшему молитву",
                    subtitle5: "Текст ответа лицу, которое осуществит пожертвование",
                    textPlaceholder5: "Текст ответа лицу, которое осуществит пожертвование"
                },
                payment: {
                    title: "Подключение платежных систем",
                    id: "Идентификатор платежной системы",
                    name: "Имя получателя",
                    currency: "Валюта счета",
                    method: "Платежная система",
                },
                schedules: {
                    title: "График работы",
                    begin: "Начало (гг:мм)",
                    end: "Завершение (гг:мм)",
                    monday: "Понедельник",
                    tuesday: "Вторник",
                    wednesday: "Среда",
                    thursday: "Четверг",
                    friday: "Пятница",
                    saturday: "Суббота",
                    sunday: "Воскресенье",
                    subtitle: "Расписание Богослужений",
                    name: "Введите название Богослужения",
                    addEvent: "Добавить событие",
                },
                visitorsInfo: {
                    title: "График работы",
                    begin: "Начало (чч:мм)",
                    end: "Завершение (чч:мм)",
                    monday: "Понедельник",
                    tuesday: "Вторник",
                    wednesday: "Среда",
                    thursday: "Четверг",
                    friday: "Пятница",
                    saturday: "Суббота",
                    sunday: "Воскресенье",
                    subtitle: "Расписание Богослужений",
                    name: "Введите название Богослужения",
                },
                sidebar: {
                    mainInfo: "Основная информация",
                    abbot: "Настоятель и святыни",
                    schedules: "Расписание и график богослужений",
                    visitorsInfo: "Информация для посетителей",
                    orders: "Заказ молитв и пожертвования",
                    paymentDetails: "Реквизиты платежных систем",
                    bankDetails: "Банковские реквизиты",
                    galleries: "Онлайн богослужения и галереи",
                    correspondence: "Корреспонденция",
                },
            },

            errors: {
                response: {
                    [ResponseErrors.WRONG_CREDENTIALS]:
                        "Некорректный логин или пароль",
                    [StatusCodes.CONFLICT]:
                        "Присутствуют устаревшие данные, обновите страницу",
                    [StatusCodes.REQUEST_TOO_LONG]:
                        "Невозможно загрузить данные, содержимое слишком велико",
                },
                common: "Произошла ошибка, попробуйте еще раз",
                commonErrors: {
                    isNumber: "Это должно быть число",
                    max: "Максимальная длина - ",
                    min: "Минимальная длина - ",
                    required: "Обязательное поле",
                    beginLowerThanEnd:
                        "Время начала должно быть меньше времени завершения",
                    beginWithEnd:
                        "Время начала должно быть со временем завершения",
                    isNotCorrectEmail: "Это не корректный email",
                    isNotCorrectLink: "Это не корректная ссылка",
                    invalidPhoneNumber: "Неверный формат номера телефона",
                    noSpaces: "Нельзя использовать пробелы",
                },
                userLogin: {
                    email: "Это должен быть email",
                    passwordsNotMatch: "Пароли не совпадают",
                    password: {
                        minSymbols:
                            "Минимальное количество специальных символов - 1",
                        minLowercase:
                            "Минимальное количество символов в нижнем регистре - 2",
                        minUppercase:
                            "Минимальное количество символов в верхнем регистре - 1",
                        minNumbers: "Минимальное количество чисел - 1",
                    },
                },
                photo: {
                    maxSize: "Выбранный размер файла превышает ",
                    acceptType: "Выбранный Вами тип файла не разрешен",
                },
                currencyErrors: {
                    onlyUSD:
                        "На данный момент есть возможность добавить только счета в долларах США ($)",
                },
                payouts: {
                    [StatusCodes.BAD_REQUEST]: "Некорректные поля выплаты или неактуальная выплата.",
                    [StatusCodes.NOT_FOUND]: "Выплата не найдена. Проверьте актуальность csv файла.",
                },
            },
            login: {
                title: "Вход",
                email: "Логин",
                password: "Пароль",
                notConfirmed:
                    "Пожалуйста, подтвердите свою почту, чтобы войти в аккаунт",
            },
            register: {
                title: "Регистрация",
                confirmPassword: "Подтверждение",
                next: "Далее",
                personalTitle: "Заполните личные данные",
                name: "Имя",
                lastname: "Фамилия (обращение)",
                phone: "Телефонный номер",
                temple: "Название храма",
                successModal: {
                    info: "Для завершения регистрации перейдите по ссылке в письме, которое мы отправили на указанный вами электронный адрес.",
                },
            },

            templeAdmin: {
                footer: {
                    sum: "Всего поступило: ",
                },
                header: {
                    edit: "Редактировать сайт",
                    visit: "Посетить сайт",
                },
                main: {
                    title: "Главная",
                    newTemple: "Добавить святыню",
                    activated: "Активировано",
                    inactive: "Неактивный",
                    declined: "Отклонено",
                    edit: "Редактировать",
                    delete: "Удалить",
                    removeTemple: {
                        title: "Вы уверены, что хотите удалить храм?",
                        remove: "Удалить",
                        cancel: "Отменить",
                    },
                },
                payments: {
                    platformLabel: "Платформа",
                    header: {
                        main: {
                            title: "Платежи",
                            totalCount: "Всего оплат: ",
                            totalAmount: "Всего: ",
                            totalTempleAmount: "Святыня: ",
                            totalPlatformAmount: "Сайт: ",
                            templePaidAmount: "Выплачено святыне: ",
                            inReviewAmount: "В работе: ",
                            income: "Баланс святыни: ",
                            resetFilter: "Очистить фильтр",
                        },
                        filter: {
                            filterButton: "Фильтр",
                            applyButtonLabel: "Применить",
                            fields: {
                                sender: "Плательщик",
                                paymentId: "Идентификатор",
                                sumFrom: "Сумма от:",
                                sumBefore: "Сумма до:",
                                status: {
                                    label: "Статус",
                                    value0: "О здоровье",
                                    value1: "О покое",
                                },
                                dateFrom: "Дата от:     дд/мм/гг",
                                dateBefore: "Дата до:     дд/мм/гг",
                                transactionType: {
                                    label: "Тип транзакции",
                                    value0: "О здоровье",
                                    value1: "О покое",
                                },
                            },
                        },
                    },
                    table: {
                        labels: {
                            senderName: "ФИО",

                            id: "Идентификатор ",
                            payment: " платежа",

                            name: "Название ",
                            recipient: " получателя ",

                            status: "Статус ",
                            paymentDate: "Время поступления ",
                            amount: "Всего ",
                            temple: "Святыня ",
                            site: "Сайт ",

                            type: "Тип ",
                            transaction: " транзакции",
                        },
                    },
                    payment: {
                        senderName: "ФИО",
                        paymentDate: "Дата поступления",
                        paymentDateConfirmation: "Дата подтверждения",
                        paymentId: "Идентификатор платежа",
                        recipientName: "Название получателя",
                        transactionType: "Тип транзакции",
                        totalAmount: "Сумма всего, $",
                        recipientAmount: "Перечисленно получателю, $",
                        platformAmount: "Получил сайт, $",
                        initial: "Было: ",
                        final: "Стало: ",
                        senderEmail: "E-mail плательщика",
                        paymentMethod: "Платежная система плательщика",
                        senderAccountId: "Идентификатор плательщика:",
                        recipientAccountId: "Идентификатор получателя:",
                        manager: "Менеджер",
                        confirmationButtonLabel: "Подтвердить",
                        prayerName: "Название",
                        openPayoutLabel: "Открыть Выплату",
                        openCreditsLabel: "Просмотреть Кредиты",
                    },
                },
            },

            admin: {
                charts: {
                    header: "Графики",
                    attendance: "Посещаемость",
                    payments: "Оплаты",
                },
                payouts: {
                    label: "Выплаты",
                    totalPayouts: "Всего выплат:",
                    downloadCSV: "Экспортировать виплаты (.csv)",
                    uploadCSV: "Импортировать виплаты (.csv)",
                    successPayoutsCreation: "Выплаты созданы",
                    successPayoutsResolving: "Выплаты завершены",
                    paymentMethodsModal: {
                        title: "Выбраны элементы с разными способами оплаты",
                        accept: "Продолжить",
                        cancel: "Отменить",
                    },
                    nonValid: {
                        title: "Выплаты, которые невозможно завершить из-за некорректных данных:",
                        csvOrder: "Номер в таблице .csv",
                        id: "Идентификатор выплаты",
                        transactionId: "Идентификатор транзакции",
                        status: "Статус виплаты",
                        createdAt: "Дата создания выплаты",
                        close: "Отменить",
                        forced: "Продолжить в любом случае",
                    },
                    table: {
                        templeName: "Название храма",
                        transactionAmount: "Сумма, $",
                        transactionPrayersAmount: "Сумма платежей, $",
                        transactionDonationsAmount: "Сумма пожертвований, $",
                        status: "Статус",
                        transactionsCount: "Количество транзакций",
                        transactionsPrayersCount: "Количество платежей",
                        transactionsDonationsCount: "Количество пожертвований",
                        createdAt: "Дата создания",
                        createdByName: "Создал",
                        updatedAt: "Дата обновления",
                        resolvedByName: "Завершил",
                        transactionId: "ID транзакции платёжной системы",
                        paymentMethod: "Тип платёжной системы",
                        superAdminComment: "Комментарий суперадмина",
                        paymentAccountRecipientName: "Имя получателя",
                        paymentAccountAccountId: "Идентификатор платежной системы",
                        paymentAccountCurrency: "Валюта счета",
                        actions: {
                            columnLabel: "Действия",
                            executeButtonLabel: "Завершить выплату",
                        },
                    },
                    credits: {
                        label: "Кредиты",
                        totalCredits: "Всего кредитов:",
                        table: {
                            templeName: "Название храма",
                            transactionAmount: "Сумма (актуальные транзакции)",
                            transactionPrayersAmount: "Сумма платежей",
                            transactionDonationsAmount: "Сумма пожертвований",
                            transactionsCount: "Количество транзакций",
                            transactionsPrayersCount: "Количество платежей",
                            transactionsDonationsCount: "Количество пожертвований",
                            dateOfApprovalLatestPayout:
                                "Дата крайней завершенной выплаты",
                            defaultPaymentMethod: "Метод оплаты по умолчанию",
                            activePayouts: "Количество активных выплат",
                            actions: {
                                columnLabel: "Действия",
                                executeButtonLabel: "Создать выплату",
                            },
                        },
                        modalText1: "Создать выплату для всех активных транзакций",
                        title: "Создание выплаты",
                        modalText3: "Выберите реквизиты для создания выплаты:",
                        modalCreateAndOpenButton: "Создать и открыть выплату",
                        modalCreateAndReturnButton: "Создать и вернуться к кредитам",
                        modalDiscardButton: "Отменить",
                        createPayoutsButton: "Создать выплаты",
                        amount: "Сумма, $",
                    },
                    details: {
                        title: "Детали выплаты",
                        informationTitle: "Информация",
                        paymentsTitle: "Платежи",
                        finishButton: "Завершить выплату",
                        print: "Распечатать",
                        saveAsPDF: "Сохранить как PDF документ",
                    },
                    resolveModal: {
                        transactionId: "ID транзакции платежной системы",
                        comment: "Комментарий",
                        resolveButton: "Завершить",
                    },
                },
                footer: {
                    paymentCount: "Количество оплат: ",
                    payoutCount: "Количество выплат: ",
                    pending: "На балансе: ",
                    done: "Выполнены: ",
                    onAccount: "Выплачено: ",
                },
                header: {
                    searchPlaceholder: "Поиск...",
                    all: "Всего святынь:",
                    dropdownList: {
                        update: "Обновления",
                        newComplaint: "Новая жалоба",
                        admission: "Поступления",
                    },
                },
                main: {
                    title: "Обновления",
                    temple: {
                        title: "Храмы:",
                        mainView: "смотреть все",
                        all: "Всего храмов",
                        pending: "В ожидании",
                        deleted: "Удалено",
                    },

                    payments: {
                        title: "Платежи:",
                        mainView: "смотреть все",
                        all: "Всего поступило",
                        done: "Выполнено",
                        processing: "В обработке",
                        commission: "Комиссия сайта",
                        support: "Поддержка проекта",
                    },
                },
                sidebar: {
                    main: "Главная",
                    temples: "Храмы",
                    payments: "Платежи",
                    updates: "История обновлений",
                    visited: "Статистика",
                    messenger: "Мессенджер",
                    graphics: "Графики",
                    transfers: "Переводы",
                    goOut: "Выйти",
                    customerSupportTickets: "Обращения пользователей",
                    payouts: "Выплаты",
                },
                temples: {
                    table: {
                        temple: "Святыня",
                        country: "Страна",
                        religion: "Вероисповедание",
                        currents: "Течения",
                        income: "Доходы",
                    },
                    header: {
                        temples: "Храмы",
                        total: "Всего храмов: ",
                        clearFilter: "Очистить фильтр",
                    },
                    filter: {
                        temple: "Святыня",
                        country: "Страна",
                        religion: "Вероисповедание",
                        current: "Течение",
                        sumFrom: "Сумма от:",
                        sumBefore: "Сумма до:",
                        applyButton: "Применить",
                    },
                    temple: {
                        main: {
                            review: "Просмотр святыни",
                            info: "Информация",
                            payments: "Оплаты",
                            activities: "Посещаемость",
                        },
                        info: {
                            register: "Дата регистрации",
                            confirm: "Дата подтверждения регистрации",
                            name: "Название святыни",
                            country: "Страна",
                            city: "Город",
                            religion: "Вероисповедание",
                            current: "Течение",
                            subspecies: "Подвид",
                            representative: "Представитель",
                            phone: "Контакт Представителя",
                            email: "E-mail святыни",
                            abbot: "Настоятель",
                            payments: "Платежные системы",
                            paymentsId: "идентификатор",
                            bank: "Банковские реквизиты ",
                            currency: "Валюта счета: ",
                        },
                        payments: {},
                        activities: {},
                    },
                },
                customerSupport: {
                    header: "Обращение пользователей",
                    totalCSTCount: "Всего обращений",
                    table: {
                        ticketName: "Название",
                        subjectType: "Тема",
                        fullName: "Имя пользователя",
                        customerRole: "Роль пользователя",
                        emailSent: "Email отправлен",
                        isCustomerRegistered: "Пользователь авторизован",
                        isEmailRegistered: "Email зарегистрирован",
                        createdAt: "Дата создания",
                    },
                    fields: {
                        description: "Обращение",
                    },
                    details: {
                        editButtonLabel: "Изменить статус",
                        sendEmailButtonLabel: "Отправить Email",
                    },
                    editModal: {
                        title: "Редактировать Обращение",
                    },
                    createTicketForm: {
                        header: "Свяжитесь с нами",
                        middleName: "Отчество (не обязательно)",
                        email: "E-mail (если Вы зарегистрированы - укажите E-mail указанный при регистрации)",
                        country: "Страна\\Регион",
                        customerRole:
                            "Вы обращаетесь как (выберите необходимое):",
                        description: "Текст сообщения",
                        conditionsAccepted:
                            "Поставив этот флажок, вы соглашаетесь с Условиями использования и Политикой конфиденциальности сайта, а также даете согласие связываться с Вами по телефону и электронной почте относительно Вашего обращения. Мы будем обрабатывать Ваши данные в соответствии с нашей Политикой конфиденциальности.",
                        subjectType:
                            "Выберите тему вопроса, который Вас интересует",
                        sendButton: "Отправить",
                        successMessage:
                            "Ваше обращение {{ticketName}} направлено в службу поддержки. Мы свяжемся с вами с помощью электронной почты, указанной при регистрации обращения.",
                        successTitle: "Обращение создано",
                        successButton: "OK",
                    },
                    faq: {
                        contactUsText: "Все еще остались вопросы?",
                    },
                },
            },
            updates: {
                header: "ИСТОРИЯ ОБНОВЛЕНИЯ",
                totalUpdates: "Всего обновлений: ",
                inReview: "Запросы на обновление: ",
                table: {
                    temple: "Святиня",
                    statusHeader: "Статус",
                    requestTime: "Время подачи заявки",
                    confirmation: "Подтверждение",
                    status: {
                        inReview: "Ожидается из ",
                        approved: "Выполнено из ",
                        declined: "Отклонено из ",
                    },
                },
                update: {
                    header: "ИЗМЕНЕНИЯ",
                    changesCount: "Количество изменений по храму: ",
                    visitingTemple: "Просмотр святыни",
                    current: "Стало",
                    original: "Было",
                    confirmButton: "Подтвердить",
                    approveAllButton: "Принять все",
                    declineAllButton: "Отменить все",
                },
            },
            general: {
                resetFilter: "Очистить фильтр",
                selectRowButton: "Выбрать строку",
                selectAllRowsButton: "Выбрать все строки на странице",
            },
            charts: {
                applyButton: "Применить",
                compareButton: "Сравнить",
                selectYear: "Выберите год",
                selectMonth: "Выберите месяц",
                selectDay: "Выберите день",
            },
            enums: {
                supportTicketCustomerRole: {
                    [SupportTicketCustomerRole.GUEST]: "Пользователь",
                    [SupportTicketCustomerRole.TEMPLE_ADMIN]:
                        "Представитель Храма",
                },
                supportTicketSubject: {
                    [SupportTicketSubject.REGISTRATION]: "Регистрация",
                    [SupportTicketSubject.ORDER_PRAYER]: "Заказ молитв",
                    [SupportTicketSubject.SUPPORT_TEMPLE]: "Поддержка Святынь",
                    [SupportTicketSubject.PAYMENTS]: "Платежи",
                    [SupportTicketSubject.OFFER]: "Предложения",
                    [SupportTicketSubject.TROUBLESHOOT]:
                        "Устранение неисправностей",
                    [SupportTicketSubject.COLLABORATION]: "Сотрудничество",
                    [SupportTicketSubject.ADVERTIZING]: "Реклама",
                    [SupportTicketSubject.OTHER]: "Другое",
                },
                recordStatus: {
                    [RecordStatus.CREATED]: "Создан",
                    [RecordStatus.APPROVED]: "Подтвержден",
                    [RecordStatus.DECLINED]: "Отклонен",
                    [RecordStatus.IN_REVIEW]: "Рассматривается",
                },
                language: {
                    [Langs.UA]: "Украинский",
                    [Langs.EN]: "Английский",
                    [Langs.RU]: "Русский",
                }
            },
            confirmation: {
                title: "Подтверждение регистрации",
                inProcess: "Проверка регистрации...",
                error: "Произошла ошибка, попробуйте позже.",
                expired: "Время регистрации прошло.",
                alreadyExist: "Невозможно повторить операцию подтверджения.",
                success:
                    "Вы успешно подтвердили свой электронный адрес. Для входа в учетную запись перейдите по ",
                link: "ссылке",
                subtitle: "Вернуться на главную ",
                subtitleLink: "страницу",
                correspondenceEmail: {
                    title: "Подтверждение почты корреспонденции",
                    subtitle: "Вернуться к ",
                    subtitleLink: "Админ-панели",
                    success:
                        "Вы успешно подтвердили свой электронный адрес. Для возвращения к регистрации святыни перейдите по ",
                },
            },
            months: {
                [Months.JANUARY]: "Январь",
                [Months.FEBRUARY]: "Февраль",
                [Months.MARCH]: "Март",
                [Months.APRIL]: "Апрель",
                [Months.MAY]: "Май",
                [Months.JUNE]: "Июнь",
                [Months.JULY]: "Июль",
                [Months.AUGUST]: "Август",
                [Months.SEPTEMBER]: "Сентябрь",
                [Months.OCTOBER]: "Октябрь",
                [Months.NOVEMBER]: "Ноябрь",
                [Months.DECEMBER]: "Декабрь",
            },
        },
    },
};

import { FC, MutableRefObject } from "react";
import { Prayer } from "../../../../../domain/models/Prayer";
import { PrayerPriceDetail } from "../../../../../domain/models/PrayerPriceDetail";
import { Temple } from "../../../../../domain/models/Temple";
import PrayerInfo from "./PrayerInfo";
import { PrayerService } from "../../../../../domain/services/PrayerService";
import prayerServiceFactory from "../../../../../services/PrayerServiceImpl";
import { Translate } from "../../../../../types/Translate";
import { withTranslation } from "react-i18next";
import { PrayerDTO } from "../../../../../domain/models/dto/PrayerDTO";
import { useOrderedEntityRemove } from "../../../../../tools/custom-hooks/remove-hooks/useOrderedEntityRemove";
import prayerMapperFactory from "../../../../../mappers/PrayerMapper";

interface PrayersProps {
    t: Translate;

    temple: Temple;
    prayers: Prayer[];

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    isOrderChanged: boolean;
    setIsOrderChanged(value: boolean): void;

    setIsAllowSetValues(value: boolean): void;

    setCurrentPrayer(value: Prayer): void;
    setCurrentDetails(value: PrayerPriceDetail[]): void;
    updatePrayers(updatedPrayer: Prayer): void;
    removePrayer(order: number): Prayer[];

    prayerFormRef: MutableRefObject<any>;
}

const Prayers: FC<PrayersProps> = (props) => {
    const removeHandlers = useOrderedEntityRemove<PrayerDTO, Prayer, PrayerService>(
        prayerServiceFactory,
        prayerMapperFactory,
        props.removePrayer,
    );

    return (
        <div className="entity-container-scroll">
            {removeHandlers.isErrorVisible && props.t("errors.common")}
            {props.prayers?.map((prayer, index) => (
                <div key={index}>
                    {prayer?.id && (
                        <PrayerInfo
                            temple={props.temple}
                            prayer={prayer}

                            isEditing={props.isEditing}
                            setIsEditing={props.setIsEditing}
                            setCurrentPrayer={props.setCurrentPrayer}
                            setCurrentDetails={props.setCurrentDetails}
                            isOrderChanged={props.isOrderChanged}
                            setIsOrderChanged={props.setIsOrderChanged}
                            setIsAllowSetValues={props.setIsAllowSetValues}
                            updatePrayers={props.updatePrayers}
                            prayerFormRef={props.prayerFormRef}
                            setRemovableModel={removeHandlers.setRemovableModel}
                            additionalWrapperClassName={index === 0 ? "" : "order__table__wrapper"}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default withTranslation()(Prayers);

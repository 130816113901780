import { FC } from "react";
import { Article } from "../../../../../domain/models/Article";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import ArticlePhotoSlide from "./ArticlePhotoSlide";
import PhotoSlider from "../../../../base/photo-slider/PhotoSlider";
import Popup from "reactjs-popup";
import FullScreenModal from "../../../../base/full-screen-modal/FullScreenModal";
import { useFullScreenModal } from "../../../../../tools/custom-hooks/modal-hooks/useFullScreenModal";

interface ArticleSlideProps {
    article: Article;
}

const ArticleSlide: FC<ArticleSlideProps> = (props) => {
    const sortedFiles = props.article.getSortedFiles(SortingOrder.ASC);
    const fullScreenModalHandlers = useFullScreenModal();

    const minimumPhotosForLoop = 4;

    return (
        <div className="information__articles information__articles__shortened shop__info">
            <div className="information__shop">
                <div className="text-truncate">
                    <h5 className="information__article-title"> {props.article?.header} </h5>
                </div>
                <p className="information__article-text text info-text">{props.article?.body}</p>
                <PhotoSlider
                    slidesPerView={3}
                    spaceBetween={15}
                    loop={props.article.files.length >= minimumPhotosForLoop}
                    allowTouchMove={true}
                    hideSwiperArrows={false}
                    dynamicBullets={true}
                    dynamicMainBullets={4}
                    disableAutoplay={true}
                    autoHeight={true}
                    content={sortedFiles.map((element, index) => (
                        <div key={"articles_information__article" + index}>
                            <ArticlePhotoSlide
                                element={element}
                                openModal={() => fullScreenModalHandlers.openFullScreenModal(sortedFiles, index)}
                            />
                        </div>
                    ))}
                />
            </div>
            <Popup
                open={fullScreenModalHandlers.openedFullScreenModal}
                modal
                overlayStyle={{ zIndex: 999999 }}
                onClose={fullScreenModalHandlers.closeFullScreenModal}
                className="full-screen-modal__popup"
            >
                <FullScreenModal
                    photos={sortedFiles}
                    closeCallback={fullScreenModalHandlers.closeFullScreenModal}
                    activeIndex={fullScreenModalHandlers.activeImageIndex}
                    toPreviousPhoto={fullScreenModalHandlers.toPreviousPhoto}
                    toNextPhoto={fullScreenModalHandlers.toNextPhoto}
                />
            </Popup>
        </div>
    );
};

export default ArticleSlide;

import { FormikProps, useFormik } from "formik";
import { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { DISABLED_COLOR } from "../../../../constants";
import { useFormsValidConfirmation } from "../../../../context/FormikFormsProvider";
import { PaymentAccount, PaymentAccountForm as IPaymentAccountForm } from "../../../../domain/models/PaymentAccount";
import { SelectProps } from "../../../../domain/models/SelectProps";
import { Temple } from "../../../../domain/models/Temple";
import { getCurrencies, getCurrentCurrencyTranslation } from "../../../../enums/currency-enum";
import { getCurrentMethodTranslation, getMethodsTranslation } from "../../../../enums/payment-method-enum";
import paymentAccountServiceFactory from "../../../../services/PaymentAccountServiceImpl";
import { getValidationClass } from "../../../../tools/Tools";
import { Translate } from "../../../../types/Translate";
import { PaymentAccountSchemaFactory } from "../../../../validation-schemas/temple-register/payment-account-schemas";
import DropDown from "../../../base/DropDown";
import ChangeEntityButtons from "../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../base/InputRestrictionsInfo";
import AddButtonSVG from "../../../base/svg/AddButtonSVG";
import ConflictErrorModal from "../../../base/conflict-error-modal/ConflictErrorModal";

interface PaymentDetailFormProps {
    t: Translate;
    paymentAccount: PaymentAccount;
    temple: Temple;

    setIsEditing(value: boolean): void;

    isAllowSetValues: boolean;
    setIsAllowSetValues(value: boolean): void;

    isSubmitting: boolean;
    setIsSubmitting(value: boolean): void;

    updatePaymentAccounts(newPaymentAccount: PaymentAccount): void;
    resetPaymentAccountForm(): void;

    errorVisible: string;
    setErrorVisibility(value: string): void;

    setPaymentAccountFormRef(value: MutableRefObject<any>): void;
}

const PaymentDetailForm: FC<PaymentDetailFormProps> = (props) => {
    const paymentAccountService = paymentAccountServiceFactory();

    const [paymentMethod, setPaymentMethod] = useState<SelectProps>(
        getCurrentMethodTranslation(props.t, props.paymentAccount.paymentMethod)
    );
    const [currency, setCurrency] = useState<SelectProps>(
        getCurrentCurrencyTranslation(props.t, props.paymentAccount.currency)
    );

    const formRef = useRef(null);

    const nullableValues: IPaymentAccountForm = {
        id: "",
        order: null,
        recipientName: "",
        accountId: "",
        paymentMethod: null,
        currency: null,
    };

    const paymentAccountSchema = useMemo(() => PaymentAccountSchemaFactory({ t: props.t }), [props.t]);

    const paymentAccountForm: FormikProps<IPaymentAccountForm> = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            order: undefined,
            paymentMethod: props.paymentAccount?.paymentMethod,
            accountId: props.paymentAccount?.accountId,
            recipientName: props.paymentAccount.recipientName,
            currency: props.paymentAccount.currency,
        },
        validationSchema: paymentAccountSchema.schema,
        onSubmit: async (values) => {
            try {
                props.setErrorVisibility(null);

                const newPaymentAccount: PaymentAccount | undefined =
                    await paymentAccountService.createRequestOrUpdateCurrentEntity({
                        templeId: props.temple.originId,
                        id: values.id || undefined,
                        order: values.order || props.temple.getNextPaymentAccountOrder(),
                        paymentMethod: values.paymentMethod,
                        accountId: values.accountId,
                        recipientName: values.recipientName,
                        currency: values.currency,
                    });

                if (!newPaymentAccount) {
                    return props.setErrorVisibility("errors.common");
                }

                if (newPaymentAccount) {
                    props.updatePaymentAccounts(newPaymentAccount);

                    props.setIsEditing(false);
                    props.setIsSubmitting(true);
                }
            } catch {
                props.setErrorVisibility("errors.common");
            }
        },
    });

    useFormsValidConfirmation("paymentAccountForm", paymentAccountForm);

    useEffect(() => {
        if (props.isAllowSetValues) {
            paymentAccountForm.setValues({
                id: props.paymentAccount.id,
                order: props.paymentAccount.order,
                recipientName: props.paymentAccount.recipientName,
                accountId: props.paymentAccount.accountId,
                paymentMethod: props.paymentAccount.paymentMethod,
                currency: props.paymentAccount.currency,
            });

            setPaymentMethod(getCurrentMethodTranslation(props.t, props.paymentAccount.paymentMethod));
            setCurrency(getCurrentCurrencyTranslation(props.t, props.paymentAccount.currency));

            props.setIsAllowSetValues(false);
        }

        if (formRef) {
            props.setPaymentAccountFormRef(formRef);
        }

        if (props.isSubmitting) {
            paymentAccountForm.setValues({
                id: "",
                order: null,
                recipientName: "",
                accountId: "",
                paymentMethod: null,
                currency: null,
            });
            paymentAccountForm.setTouched({});

            setCurrency(null);
            setPaymentMethod(null);

            props.setIsSubmitting(false);
        }
    }, [props, formRef, paymentAccountForm]);

    const editPaymentAccountFormHandler = () => {
        paymentAccountForm.setValues({
            ...paymentAccountForm.values,
            id: props.paymentAccount?.id,
            order: props.paymentAccount.order || props.temple.getNextPaymentAccountOrder(),
        });
        paymentAccountForm.handleSubmit();
    };

    const paymentMethodHandleChange = (newMethod: SelectProps) => {
        if (paymentMethod === newMethod) {
            return;
        }

        paymentAccountForm.setFieldValue("paymentMethod", newMethod.value);
        setPaymentMethod(newMethod);
    };

    const paymentCurrencyHandleChange = (newCurrency: SelectProps) => {
        if (currency === newCurrency) {
            return;
        }

        paymentAccountForm.setFieldValue("currency", parseInt(newCurrency.value));
        setCurrency(newCurrency);
    };

    const clearFieldsCallback = useCallback(() => {
        setPaymentMethod(null);
        setCurrency(null);
    }, []);

    const resetPaymentAccountForm = useCallback(() => {
        props.resetPaymentAccountForm();
        props.setIsEditing(false);
    }, [props]);

    return (
        <div ref={formRef} className="payments choose__temple__pagination">
            <div className="date__form-block">
                <DropDown
                    name="paymentMethod"
                    value={paymentMethod}
                    options={getMethodsTranslation(props.t)}
                    onChange={paymentMethodHandleChange}
                    isError={paymentAccountForm.touched.paymentMethod && !!paymentAccountForm.errors.paymentMethod}
                    placeholder={props.t("fields.paymentMethod")}
                />
            </div>

            <div className="date__form-block">
                <input
                    className={`date__input ${getValidationClass(paymentAccountForm, "accountId")}`}
                    name="accountId"
                    onChange={paymentAccountForm.handleChange}
                    value={paymentAccountForm.values.accountId}
                    type="text"
                    placeholder={props.t("templeRegister.payment.id")}
                    maxLength={paymentAccountSchema.inputRestrictions.accountId.max}
                />
                <InputRestrictionsInfo
                    min={paymentAccountSchema.inputRestrictions.accountId.min}
                    max={paymentAccountSchema.inputRestrictions.accountId.max}
                    error={paymentAccountForm.errors.accountId}
                />
            </div>

            <div className="date__form-block  date__form-wrap">
                <div className="date__form-block">
                    <input
                        className={`date__input ${getValidationClass(paymentAccountForm, "recipientName")}`}
                        name="recipientName"
                        onChange={paymentAccountForm.handleChange}
                        value={paymentAccountForm.values.recipientName}
                        type="text"
                        placeholder={props.t("templeRegister.payment.name")}
                        maxLength={paymentAccountSchema.inputRestrictions.recipientName.max}
                    />
                    <InputRestrictionsInfo
                        min={paymentAccountSchema.inputRestrictions.recipientName.min}
                        max={paymentAccountSchema.inputRestrictions.recipientName.max}
                        error={paymentAccountForm.errors.recipientName}
                    />
                </div>

                <div className="date__form-block">
                    <DropDown
                        name="currency"
                        value={currency}
                        options={getCurrencies(props.t)}
                        onChange={paymentCurrencyHandleChange}
                        isError={paymentAccountForm.touched.currency && !!paymentAccountForm.errors.currency}
                        placeholder={props.t("templeRegister.bank.currency")}
                    />
                    <p>{props.t("errors.currencyErrors.onlyUSD")}</p>
                </div>
            </div>

            {props.errorVisible && <p className="input-restrictions-info__error">{props.t(props.errorVisible)}</p>}

            <div className="date__bottom-buttons">
                <ChangeEntityButtons<IPaymentAccountForm>
                    formik={paymentAccountForm}
                    initialValues={nullableValues}
                    disableSaveButton={!props.paymentAccount.id}
                    editFormHandler={editPaymentAccountFormHandler}
                    resetEntityFormHandler={resetPaymentAccountForm}
                    clearFieldsCallback={clearFieldsCallback}
                />

                {!props.paymentAccount.id && (
                    <button
                        className={
                            !paymentAccountForm.isValid
                                ? "disabled-add-button worship__button-add"
                                : "date__button-add worship__button-add"
                        }
                        type="button"
                        disabled={paymentAccountForm.isSubmitting}
                        onClick={() => paymentAccountForm.handleSubmit()}
                    >
                        <span>
                            <AddButtonSVG fill={!paymentAccountForm.isValid && DISABLED_COLOR} />
                        </span>
                        {props.t("templeRegister.bank.add")}
                    </button>
                )}
            </div>
            <ConflictErrorModal />
        </div>
    );
};

export default withTranslation()(PaymentDetailForm);

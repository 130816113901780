import { FC, MutableRefObject } from "react";
import { Temple } from "../../../../../domain/models/Temple";
import { WorshipSchedule } from "../../../../../domain/models/WorshipSchedule";
import WorshipScheduleInfo from "./info/WorshipScheduleInfo";

interface WorshipSchedulesProps {
    temple: Temple;
    setCurrentWorshipSchedule(value: WorshipSchedule): void;

    isEditing: boolean;
    setIsEditing(value: boolean): void;
    removeSchedule(id: string): void;
    worshipScheduleFormRef: MutableRefObject<any>;
}

const WorshipSchedules: FC<WorshipSchedulesProps> = (props) => {
    const worshipSchedules = props.temple.groupWorshipSchedulesByDate();

    return (
        <div className="entity-container-scroll">
            {worshipSchedules?.map((schedules, index) => (
                <div key={"register-worship-schedule." + index}>
                    <WorshipScheduleInfo
                        schedules={schedules}
                        removeSchedule={props.removeSchedule}
                        setCurrentWorshipSchedule={props.setCurrentWorshipSchedule}
                        isEditing={props.isEditing}
                        setIsEditing={props.setIsEditing}
                        worshipScheduleFormRef={props.worshipScheduleFormRef}
                        additionalWrapperClassName={index === 0 ? "" : "worship__block__wrapper"}
                    />
                </div>
            ))}
        </div>
    );
};

export default WorshipSchedules;

import { FC } from "react";
import { Temple } from "../../../../domain/models/Temple";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import TempleGallerySlide from "./TempleGallerySlide";
import Popup from "reactjs-popup";
import FullScreenModal from "../../../base/full-screen-modal/FullScreenModal";
import PhotoSlider from "../../../base/photo-slider/PhotoSlider";
import { useFullScreenModal } from "../../../../tools/custom-hooks/modal-hooks/useFullScreenModal";
import { withTranslation } from "react-i18next";

interface TempleGalleryProps {
    t(key?: string): string;
    temple: Temple;
}

const TempleGallery: FC<TempleGalleryProps> = (props) => {
    const sortedFiles = props.temple.getSortedFiles(SortingOrder.ASC, props.temple.galleryFiles);
    const fullScreenModalHandlers = useFullScreenModal();

    const minimumPhotosForLoop = 4;

    return (
        <section id="section_8">
            <div id="gallery-slider" className="gallery">
                <h2 className="gallery__title subtitle ">{props.t("user.temple.sidebar.gallery")}</h2>
                <div className="container--min">
                    <div className="basic__swiper-thumbs">
                        <PhotoSlider
                            slidesPerView={3}
                            spaceBetween={10}
                            isArrowsDisable={false}
                            loop={props.temple.files.length >= minimumPhotosForLoop}
                            allowTouchMove={true}
                            dynamicBullets={true}
                            dynamicMainBullets={4}
                            content={sortedFiles.map((element, index) => (
                                <TempleGallerySlide
                                    element={element}
                                    openModal={() => fullScreenModalHandlers.openFullScreenModal(sortedFiles, index)}
                                />
                            ))}
                        />
                    </div>
                </div>
                <Popup
                    open={fullScreenModalHandlers.openedFullScreenModal}
                    modal
                    overlayStyle={{ zIndex: 999999 }}
                    onClose={fullScreenModalHandlers.closeFullScreenModal}
                    className="full-screen-modal__popup"
                >
                    <FullScreenModal
                        photos={sortedFiles}
                        closeCallback={fullScreenModalHandlers.closeFullScreenModal}
                        activeIndex={fullScreenModalHandlers.activeImageIndex}
                        toPreviousPhoto={fullScreenModalHandlers.toPreviousPhoto}
                        toNextPhoto={fullScreenModalHandlers.toNextPhoto}
                    />
                </Popup>
            </div>
        </section>
    );
};

export default withTranslation()(TempleGallery);

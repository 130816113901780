import { useState } from "react";
import { Swiper as ISwiper } from "swiper/types";
import { SliderModalWindow } from "../../../types/SliderModalWindow";

interface IUseSliderModalWindow extends SliderModalWindow {}

export const useSliderModalWindow = (): IUseSliderModalWindow => {
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

    const [modalSwiper, setModalSwiper] = useState<ISwiper>(null);
    const [mainSlideIndex, setMainSlideIndex] = useState<number>(0);

    const closeModal = () => {
        setModalSwiper(null);
        setIsVisibleModal(false);
    };

    const openModal = (index: number) => {
        setMainSlideIndex(index);
        setIsVisibleModal(true);
    };

    return {
        isVisibleModal,
        modalSwiper,
        mainSlideIndex,
        setMainSlideIndex,
        setModalSwiper,
        openModal,
        closeModal,
    };
};

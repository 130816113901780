import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { maxTemplesCount } from "../../../Config";
import { DEFAULT_OFFSET, DEFAULT_PAGE } from "../../../constants";
import { Temple } from "../../../domain/models/Temple";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import { TempleSortFields } from "../../../enums/temple-sort-fields-enum";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import TempleButton from "./TempleButton";
import TempleItem from "./TempleItem";

interface TempleAdminTemplesListProps {
    t(key?: string): string;
}

const TempleAdminTemplesList: FC<TempleAdminTemplesListProps> = (props) => {
    const [temples, setTemples] = useState<Temple[]>([]);

    useEffect(() => {
        const setData = async () => {
            const templeService = templeServiceFactory();
            const temples = await templeService.getCachedLatestTemples(
                DEFAULT_PAGE,
                maxTemplesCount,
                DEFAULT_OFFSET,
                TempleSortFields.NAME,
                SortingOrder.ASC,
            );
            setTemples(temples.rows);
        };
        setData();
    });

    return (
        <>
            <title>{props.t("templeAdmin.main.title")}</title>
            <main className="main">
                <div className="my-church">
                    {temples?.map((temple, index) => (
                        <div key={index}>
                            <TempleItem

                                temple={temple}
                                setTemples={setTemples}
                            />
                        </div>
                    ))}
                    {temples.length < maxTemplesCount && <TempleButton  />}
                </div>
            </main>
        </>
    );
};

export default withTranslation()(TempleAdminTemplesList);

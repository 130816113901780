import { FC } from "react";
import { withTranslation } from "react-i18next";
import { EntityChange } from "../../../../domain/models/EntityChange";
import { Entity } from "../../../../enums/change-request-entity-enums/entity-enum";
import templeChangesRequestServiceFactory from "../../../../services/TempleChangesRequestServiceImpl";
import { Translate } from "../../../../types/Translate";
import ChangeRequestEntityText from "./ChangeRequestEntityText";

interface ChangeRequestEntityItemDataProps {
    t: Translate;

    changes: EntityChange[];
    entityType: Entity;
}

const ChangeRequestEntityItemData: FC<ChangeRequestEntityItemDataProps> = (props) => {
    const templeChangeRequestService = templeChangesRequestServiceFactory();

    return (
        <>
            {props.changes?.map((change, index) => (
                <div key={"update__history-body" + index}>
                    {change.isGeneralField && (
                        <ChangeRequestEntityText
                            fieldName={change.fieldName}
                            fieldTranslation={templeChangeRequestService.getEntityFieldTranslation(
                                props.t,
                                props.entityType,
                                change
                            )}
                        />
                    )}
                </div>
            ))}
        </>
    );
};

export default withTranslation()(ChangeRequestEntityItemData);

import { FC, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Artifact } from "../../../../../domain/models/Artifact";
import { Temple } from "../../../../../domain/models/Temple";
import { ArtifactModel } from "../../../../../models/ArtifactModel";
import { BaseEntityManipulationHooks } from "../../../../../tools/custom-hooks/BaseEntityManipulationHooks";
import ArtifactForm from "./ArtifactForm";
import Artifacts from "./Artifacts";

interface MainArtifactComponentProps {
    t(key?: string): string;
    temple: Temple;
    resetErrorCallback: () => void;
}

const MainArtifactComponent: FC<MainArtifactComponentProps> = (props) => {
    const handlers = BaseEntityManipulationHooks<Artifact>(
        ArtifactModel,
        props.temple.artifacts,
        props.temple.setArtifacts.bind(props.temple)
    );
    const artifactFormRef = useRef<HTMLDivElement>(null);

    return (
        <div className="establish date__section">
            <h2 className="date__subtitle establish__subtitle">{props.t("templeRegister.abbot.subtitle2")}</h2>

            <Artifacts
                removeArtifact={handlers.removeEntity}
                artifacts={handlers.entities}
                setCurrentArtifact={handlers.setCurrentEntity}
                artifactFormRef={artifactFormRef}
            />
            <ArtifactForm
                temple={props.temple}
                artifact={handlers.currentEntity}
                updateArtifacts={handlers.updateEntities}
                resetArtifactForm={handlers.resetFormData}
                resetErrorCallback={props.resetErrorCallback}
                artifactFormRef={artifactFormRef}
            />
        </div>
    );
};

export default withTranslation()(MainArtifactComponent);

import { FC } from "react";
import { Temple } from "../../../../domain/models/Temple";
import PhotoSlider from "../../../base/photo-slider/PhotoSlider";
import TopSliderSlide from "./TopSliderSlide";

interface TopSliderProps {
    temples: Temple[];
}

const TopSlider: FC<TopSliderProps> = (props) => {
    return (
        <section className="top">
            <div className="top__swiper">
                <PhotoSlider
                    centeredSlides={true}
                    allowTouchMove={false}
                    slidesPerView={1}
                    isArrowsDisable={true}
                    autoplayDelay={10000}
                    loop={false}
                    content={props.temples.map((temple) => <TopSliderSlide  temple={temple} />)}
                />
            </div>
        </section>
    );
};

export default TopSlider;

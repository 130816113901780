import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Temple } from "../../../domain/models/Temple";

interface TempleSupportProps {
    t(key?: string): string;
    temple: Temple;
}

const TempleSupport: FC<TempleSupportProps> = (props) => {
    const navigate = useNavigate();

    return (
        <section id="section_5" className="support">
            <div className="container--min">
                <h2 className="support__title subtitle">{props.t("user.temple.support.subtitle1")}</h2>
                <div className="support__content">
                    <div className="support__block">
                        <h3 className="support__subtitle">{props.t("user.temple.support.subtitle2")}</h3>
                        <p className="support__text text">
                            {props.temple.useStandardPrayersInfo
                                ? props.t("user.temple.support.text1")
                                : props.temple.prayersInfo}
                        </p>
                        <div
                            className="button-wrap"
                            onClick={() => navigate(`/temple/${props.temple.id}/order-prayer`)}
                        >
                            <a className="button">
                                {props.t("user.temple.support.orderPrayerButton")}
                            </a>
                        </div>
                    </div>
                    <div className="support__block">
                        <h3 className="support__subtitle ">{props.t("user.temple.support.subtitle3")}</h3>
                        <p className="support__text text">
                            {props.temple.useStandardTempleSupportInfo
                                ? props.t("user.temple.support.text2")
                                : props.temple.templeSupportInfo}
                        </p>
                        <div className="button-wrap" onClick={() => navigate(`/temple/${props.temple.id}/support`)}>
                            <a className="button">
                                {props.t("user.temple.support.supportButton")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(TempleSupport);

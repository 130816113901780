import { Mapper } from "../domain/mappers/mapper";
import { PaymentsInfo } from "../domain/models/Payment";
import { PaymentsInfoDTO } from "../domain/models/dto/PaymentDTO";
import PaymentsInfoModel from "../models/PaymentsInfoModel";
import { SimpleMapper } from "./SimpleMapper";

class PaymentsInfoMapper extends SimpleMapper<PaymentsInfo, PaymentsInfoDTO> {
    protected entityConstructor = PaymentsInfoModel;

    protected fromDTOFields: string[] = [
        "totalAmount",
        "doneAmount",
        "inReviewAmount",
        "siteCommissionAmount",
        "projectSupportAmount",

        "totalCount",
        "doneCount",
        "inReviewCount",
        "siteCommissionCount",
        "projectSupportCount",
    ];
}


export default function paymentsInfoMapperFactory(): Mapper<PaymentsInfo, PaymentsInfoDTO> {
    return new PaymentsInfoMapper()
}

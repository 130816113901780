import { StatusCodes } from "http-status-codes";
import { useEffect, useState } from "react";
import { Mapper } from "../../../domain/mappers/mapper";
import { BaseOrderedEntity } from "../../../domain/models/BaseOrderedEntity";
import { errorServiceFactory } from "../../../services/ErrorServiceImpl";
import { EntityService } from "../../../types/services/EntityService";

interface IUseOrderedEntityRemove<Model extends BaseOrderedEntity> {
    isErrorVisible: boolean;
    setRemovableModel: (value: Model) => void;
}

export function useOrderedEntityRemove<EntityDTO, Model extends BaseOrderedEntity, Service extends EntityService<Model, EntityDTO>>(
    serviceFactory: () => Service,
    mapperFactory: () => Mapper<Model, EntityDTO>,
    removeCallback: (order: number) => Model[],
): IUseOrderedEntityRemove<Model> {
    const service = serviceFactory();
    const errorService = errorServiceFactory();

    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
    const [removableModel, setRemovableModel] = useState<Model>(null);

    useEffect(() => {
        const remove = async () => {
            const newModelsList = removeCallback(removableModel.order);
            const modelsToUpdate = newModelsList.filter(model => model.order >= removableModel.order);

            const result = await service.removeEntity(removableModel);
            const updatedModels = await Promise.all(modelsToUpdate.map(
                model => service.createRequestOrUpdateCurrentEntity(mapperFactory().toDTO(model)))
            );

            if (!result || !updatedModels || errorService.getStatusFromErrorResponseSubject(StatusCodes.CONFLICT)) {
                setIsErrorVisible(true);
            }
        };

        if (removableModel) {
            remove();
            setRemovableModel(null);
        }
    }, [removableModel, removeCallback, service, errorService, mapperFactory]);

    return {isErrorVisible, setRemovableModel};
};

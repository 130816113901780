import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormsValidConfirmation } from "../../../../context/FormikFormsProvider";
import { GalleriesForm as IGalleriesForm, Temple } from "../../../../domain/models/Temple";
import { CreateOrUpdateTemplePath } from "../../../../enums/create-or-update-temple-path-enum";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import templeMapperFactory from "../../../../mappers/TempleMapper";
import templeServiceFactory from "../../../../services/TempleServiceImpl";
import { useRedirectToPrevCreateTemplePage } from "../../../../tools/custom-hooks/useRedirect";
import { GallerySchemaFactory } from "../../../../validation-schemas/temple-register/gallery-schemas";
import Footer from "../footer/Footer";
import GalleriesForm from "./GalleriesForm";
import { CreateTempleParams } from "../../../../enums/create-temple-params-enum";
import ConflictErrorModal from "../../../base/conflict-error-modal/ConflictErrorModal";

interface MainGalleriesComponentProps {
    t(key?: string): string;
    temple: Temple;
}

const MainGalleriesComponent: FC<MainGalleriesComponentProps> = (props) => {
    const templeService = templeServiceFactory();
    const navigate = useNavigate();
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    useRedirectToPrevCreateTemplePage(props.temple, TempleRegisterPage.BANK_DETAILS, props.temple.isBankDetailsComplete);

    const initialValues: IGalleriesForm = {
        worshipLink: props.temple.worshipLink || "",
        tourLink: props.temple.tourLink || "",
        contactCountry: props.temple.contactCountry || "",
        contactCity: props.temple.contactCity || "",
        contactStreet: props.temple.contactStreet || "",
        contactBuildingNumber: props.temple.contactBuildingNumber || undefined,
        contactPhone: props.temple.contactPhone || "",
        contactSecondPhone: props.temple.contactSecondPhone || "",
        contactEmail: props.temple.contactEmail || "",
        contactInfo: props.temple.contactInfo || "",
    };

    const galleriesForm: FormikProps<IGalleriesForm> = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: GallerySchemaFactory({ t: props.t }).schema,
        onSubmit: async (values) => {
            const temple = props.temple;
            const nextUrl = `/create-temple/correspondence?${CreateTempleParams.ID}=${temple.originId}`;

            try {
                setErrorVisibility(false);

                if (_.isEqual(values, galleriesForm.initialValues) && !temple.isFilesUpdated) {
                    navigate(nextUrl);
                    return;
                }

                temple.setWorshipLink(values.worshipLink);
                temple.setTourLink(values.tourLink);
                temple.setContactCountry(values.contactCountry);
                temple.setContactCity(values.contactCity);
                temple.setContactStreet(values.contactStreet);
                temple.setContactBuildingNumber(values.contactBuildingNumber);
                temple.setContactPhone(values.contactPhone);
                temple.setContactSecondPhone(values.contactSecondPhone);
                temple.setContactEmail(values.contactEmail);
                temple.setContactInfo(values.contactInfo);

                if (!temple.isGalleriesComplete) {
                    return setErrorVisibility(true);
                }

                const newTemple: Temple | undefined = await templeService.createRequestOrUpdateCurrentEntity(
                    templeMapperFactory().toLatestTempleDTO(temple), CreateOrUpdateTemplePath.ADDITIONAL_INFO
                );

                if (!newTemple) {
                    return setErrorVisibility(true);
                }

                const files = await temple.resolveFiles(newTemple?.id, temple.galleryFiles);
                if (files.isErrorVisible) {
                    return setErrorVisibility(true);
                }

                if (newTemple) {
                    temple.galleryFiles = temple.getUpdatedFilesList(files.images, temple.galleryFiles);

                    templeService.notifyListeners(temple);
                    navigate(nextUrl);
                }
            } catch (err) {
                setErrorVisibility(true);
            }
        },
    });

    const { isFormsDataValid } = useFormsValidConfirmation("galleriesForm", galleriesForm);

    return (
        <div className="date__content">
            <form onSubmit={galleriesForm.handleSubmit}>
                <main className="date__main">
                    <h1 className="date__title">
                        {props.t("templeRegister.base.title1")}
                        <br />
                        {props.t("templeRegister.base.title2")}
                    </h1>

                    {isErrorVisible && <p>{props.t("errors.common")}</p>}
                    <GalleriesForm
                        initialValues={initialValues}
                        form={galleriesForm}
                        temple={props.temple}
                        setErrorVisibility={setErrorVisibility}
                    />
                </main>
                <ConflictErrorModal />
                <Footer isFormsDataValid={isFormsDataValid} />
            </form>
        </div>
    );
};

export default withTranslation()(MainGalleriesComponent);

import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import statisticServiceFactory from "../../services/StatisticServiceImpl";
import TitleTruncateWrapper from "./TitleTruncateWrapper";
import { AdminShortStatistic } from "../../domain/models/Admin";

interface AdminFooterProps {
    t(key?: string): string;
    isTempleAdmin: boolean;
}

const AdminFooter: FC<AdminFooterProps> = (props) => {
    const [shortAdminStatistic, setShortAdminStatistic] = useState<AdminShortStatistic>();

    useEffect(() => {
        if (shortAdminStatistic) {
            return;
        }

        (async function () {
            const statisticService = statisticServiceFactory();
            const statistic = await statisticService.getShortAdminStatistic();
            if (statistic) {
                setShortAdminStatistic(statistic);
            }
        })();
    }, [shortAdminStatistic]);

    return (
        <>
            <footer className="footer">
                <div className="footer__blocks">
                    <div className="footer__block">
                        <div className="footer__item footer__statistics">
                            {props.t("admin.footer.payoutCount")}
                            <span>{shortAdminStatistic?.totalPayoutCount}</span>
                        </div>
                        <div className="church__row-title__block footer__statistics">
                            <TitleTruncateWrapper
                                content={
                                    <div className="footer__item footer__statistics">
                                        {props.t("admin.footer.paymentCount")}
                                        <span>{shortAdminStatistic?.totalCount}</span>
                                    </div>
                                }
                                dataHover={`${props.t("admin.footer.pending")}${
                                    shortAdminStatistic?.inReviewCount
                                }, ${props.t("admin.footer.done")}${shortAdminStatistic?.doneCount}`}
                                wrapperClassName="hover-text-item-admin-footer"
                                textSpecsArray={[{ 0: 0 }, { 99999: 76 }]}
                            />
                        </div>
                    </div>
                    <div className="footer__block">
                        {props.isTempleAdmin && (
                            <div className="footer__item footer__statistics">
                                {props.t("templeAdmin.footer.sum")}
                                <span>{shortAdminStatistic?.totalAmountFloatStr}$</span>
                            </div>
                        )}
                        <div className="footer__item footer__statistics">
                            {props.t("admin.footer.pending")}
                            <span>{shortAdminStatistic?.inReviewAmountFloatStr}$</span>
                        </div>
                        <div className="footer__item footer__statistics">
                            {props.t("admin.footer.onAccount")}
                            <span>{shortAdminStatistic?.doneAmountFloatStr}$</span>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default withTranslation()(AdminFooter);

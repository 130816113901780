import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { AdminStatistic } from "../../domain/models/Admin";
import statisticServiceFactory from "../../services/StatisticServiceImpl";
import LangSelect from "../base/LangSelect";
import HeaderNotificationSVG from "../base/svg/HeaderNotificationSVG";

interface HeaderProps {
    t(key?: string): string;
}

const Header: FC<HeaderProps> = (props) => {
    const statisticService = statisticServiceFactory();

    const [isDropdownVisible, setDropdownVisibility] = useState<boolean>(false);
    const [adminStatistic, setAdminStatistic] = useState<AdminStatistic>();

    useEffect(() => {
        if (adminStatistic) {
            return;
        }

        (async function () {
            const statistic = await statisticService.getCachedSuperAdminStatistic();
            if (statistic) {
                setAdminStatistic(statistic);
            }
        })();
    }, [statisticService, adminStatistic]);

    return (
        <>
            <header className="header">
                <div className="header__search-wrap header__block">
                    <label>
                        <input
                            type="text"
                            placeholder={props.t("admin.header.searchPlaceholder")}
                            className="header__search"
                        />
                    </label>
                </div>

                <div className="header__holy header__block">
                    <div className="header__holy-all">{props.t("admin.header.all")}</div>
                    <div className="header__holy-num">{adminStatistic?.templesInfo.totalCount}</div>
                </div>

                <div className="header__right header__block">
                    <LangSelect  />
                    <div className="header__notification-wrap">
                        <button
                            onClick={() => setDropdownVisibility(!isDropdownVisible)}
                            className="header__notification"
                        >
                            <HeaderNotificationSVG />
                        </button>

                        <div
                            id="dropdown"
                            className={"header__dropdown " + (isDropdownVisible && "header__dropdown--show")}
                        >
                            <ul className="header__dropdown-list">
                                <li className="header__dropdown-item header__dropdown-item--new">
                                    <a href="#" className="header__dropdown-link">
                                        <span>12:20</span>
                                        {props.t("admin.header.dropdownList.update")}
                                    </a>
                                </li>
                                <li className="header__dropdown-item header__dropdown-item--new">
                                    <a href="#" className="header__dropdown-link">
                                        <span>12:20</span>
                                        {props.t("admin.header.dropdownList.newComplaint")}
                                    </a>
                                </li>
                                <li className="header__dropdown-item">
                                    <a href="#" className="header__dropdown-link">
                                        <span>12:20</span>
                                        {props.t("admin.header.dropdownList.admission")}
                                    </a>
                                </li>
                                <li className="header__dropdown-item">
                                    <a href="#" className="header__dropdown-link">
                                        <span>12:20</span>
                                        {props.t("admin.header.dropdownList.newComplaint")}
                                    </a>
                                </li>
                                <li className="header__dropdown-item">
                                    <a href="#" className="header__dropdown-link">
                                        <span>12:20</span>
                                        {props.t("admin.header.dropdownList.newComplaint")}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default withTranslation()(Header);

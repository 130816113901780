import { FC } from "react";

interface NoPhotoSVGProps {
    wrapperClassName?: string;
}

const NoPhotoSVG: FC<NoPhotoSVGProps> = (props) => {
    return <div className={props.wrapperClassName || "no-photo-img"}><img src="../img/no_photo.png" /></div>;
};

export default NoPhotoSVG;

import { FC } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DATE_FORMAT, MAX_SCHEDULE_DATE } from "../../../../../../constants";
import moment from "moment";

interface WorshipScheduleDateProps {
    date: string;
    setDate(value: string): void;
    dateError: string;
}

const WorshipScheduleDate: FC<WorshipScheduleDateProps> = (props) => {
    const locale = moment.locale('en', {
        week: {
            dow: 1,
        },
    });


    return (
        <div className="worship__datepicker-calendar">
            <LocalizationProvider dateAdapter={AdapterMoment} locale={locale}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={moment(props.date, DATE_FORMAT)}
                    onChange={(newValue) => props.setDate(newValue.format(DATE_FORMAT))}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={moment()}
                    maxDate={moment(MAX_SCHEDULE_DATE, DATE_FORMAT)}
                />
            </LocalizationProvider>
            {props.dateError}
        </div>
    );
};

export default WorshipScheduleDate;

import { StatusCodes } from "http-status-codes";
import Cookies from "js-cookie";
import { BehaviorSubject, Subscription } from "rxjs";
import { ErrorService } from "../domain/services/ErrorService";
import { ErrorResponseSubject } from "../types/ErrorResponseSubject";

export class ErrorServiceImpl implements ErrorService {
    private constructor () {}
    private static _instance: ErrorService;
    private static _errorResponseSubject: ErrorResponseSubject = ErrorServiceImpl.getInitialErrorResponseSubject();

    private static getInitialErrorResponseSubject(): ErrorResponseSubject {
        return {
            [StatusCodes.CONFLICT]: new BehaviorSubject(false),
            [StatusCodes.BAD_REQUEST]: new BehaviorSubject(false),
            [StatusCodes.FORBIDDEN]: new BehaviorSubject(false),
            [StatusCodes.NOT_FOUND]: new BehaviorSubject(false),
            [StatusCodes.REQUEST_TOO_LONG]: new BehaviorSubject(false),
        };
    }

    static getInstance() {
        if (!ErrorServiceImpl._instance) {
            ErrorServiceImpl._instance = new ErrorServiceImpl();
        }
        return ErrorServiceImpl._instance;
    }

    private clearToken() {
        Cookies.remove('token', {domain: window.location.hostname});
    }

    private async throwResponseError(response: any): Promise<void> {
        const responseJSON = await response.json();
        throw new Error(
            JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                data: responseJSON,
            })
        );
    }

    subscribeOnError(statusCode: StatusCodes, onErrorCallback?: () => void): Subscription {
        return ErrorServiceImpl._errorResponseSubject[statusCode].subscribe((error) => {
            if (error && onErrorCallback) {
                onErrorCallback();
            }
        });
    }

    getStatusFromErrorResponseSubject(statusCode: StatusCodes): boolean {
        return ErrorServiceImpl._errorResponseSubject[statusCode].getValue();
    }

    notifyErrorResponseSubjectListeners(statusCode: StatusCodes, value: boolean): void {
        ErrorServiceImpl._errorResponseSubject[statusCode].next(value);
    }

    async handleResponseError(response: Response): Promise<void> {
        switch (response.status) {
            case StatusCodes.UNAUTHORIZED:
                this.clearToken();
                break;
            case StatusCodes.CONFLICT:
                this.notifyErrorResponseSubjectListeners(StatusCodes.CONFLICT, true);
                break;
            case StatusCodes.BAD_REQUEST:
                this.notifyErrorResponseSubjectListeners(StatusCodes.BAD_REQUEST, true);
                break;
            case StatusCodes.FORBIDDEN:
                this.notifyErrorResponseSubjectListeners(StatusCodes.FORBIDDEN, true);
                break;
            case StatusCodes.NOT_FOUND:
                this.notifyErrorResponseSubjectListeners(StatusCodes.NOT_FOUND, true);
                break;
            case StatusCodes.REQUEST_TOO_LONG:
                this.notifyErrorResponseSubjectListeners(StatusCodes.REQUEST_TOO_LONG, true);
                break;
            default:
                await this.throwResponseError(response);
                break;
        }
    }

    setResponseErrorMessage(err: Error, setError: (value: string) => void): void {
        const errorMessage = JSON.parse(err.message);
        setError("errors.response." + errorMessage.data.messages[0]);
    }

    resetErrorResponseSubject(): void {
        ErrorServiceImpl._errorResponseSubject = ErrorServiceImpl.getInitialErrorResponseSubject();
    }
}

export function errorServiceFactory(): ErrorService {
    return ErrorServiceImpl.getInstance();
}

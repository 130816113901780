import { FC } from "react";
import { SelectProps } from "../../../domain/models/SelectProps";
import DropDown from "../DropDown";

interface CustomDropDownProps {
    name: string;
    elements: any[];

    currentElement: SelectProps;
    handleElementChange(value: SelectProps): void;
    placeholder?: string;
}

const CustomDropDown: FC<CustomDropDownProps> = (props) => {
    const getElementsDropDownItems = (): SelectProps[] => {
        return props.elements?.map(
            (value, index) => {
                return {
                    value: (index + 1).toString(),
                    label: value
                }
            }
        );
    }

    return (
        <DropDown
            name={props.name}
            value={props.currentElement}
            options={getElementsDropDownItems()}
            onChange={props.handleElementChange}
            placeholder={props.placeholder}
        />
    );
};

export default CustomDropDown;

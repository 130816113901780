import { FC } from "react";
import { TempleWithCount } from "../../../domain/models/Temple";
import FoundTemplesCardsList from "./FoundTemplesCardsList";
import FoundTemplesRowsList from "./FoundTemplesRowsList";

interface TempleSearchPageContentProps {
    isList: boolean;
    temples: TempleWithCount;
}

const TempleSearchPageContent: FC<TempleSearchPageContentProps> = (props) => {
    return (
        <div className="church__items church__items--active">
            {props.temples?.rows &&
                (props.isList ? (
                    <FoundTemplesCardsList  temples={props.temples.rows} />
                ) : (
                    <FoundTemplesRowsList  temples={props.temples.rows} />
                ))}
        </div>
    );
};

export default TempleSearchPageContent;

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../../../domain/models/Temple";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import { Translate } from "../../../../../types/Translate";
import PhotoSlider from "../../../../base/photo-slider/PhotoSlider";
import ShopPhotoSlide from "./ShopPhotoSlide";
import Popup from "reactjs-popup";
import FullScreenModal from "../../../../base/full-screen-modal/FullScreenModal";
import { useFullScreenModal } from "../../../../../tools/custom-hooks/modal-hooks/useFullScreenModal";

interface ShopProps {
    t: Translate;
    temple: Temple;
}

const Shop: FC<ShopProps> = (props) => {
    const sortedFiles = props.temple.getSortedFiles(SortingOrder.ASC, props.temple.shopInfoFiles);
    const fullScreenModalHandlers = useFullScreenModal();

    return (
        <div className="information__articles shop__info">
            <div className="information__shop">
                <div className="text-truncate">
                    <h2 className="information__article-title">
                        {props.t("user.temple.information.shop").toUpperCase()}
                    </h2>
                </div>
                <p className="information__article-text text info-text">{props.temple.shopInfo}</p>
                <PhotoSlider
                    slidesPerView={3}
                    spaceBetween={15}
                    loop={true}
                    allowTouchMove={true}
                    hideSwiperArrows={false}
                    dynamicBullets={true}
                    dynamicMainBullets={4}
                    disableAutoplay={true}
                    autoHeight={true}
                    content={sortedFiles.map((element, index) => (
                        <div key={"shop_information__article" + index}>
                            <ShopPhotoSlide
                                element={element}
                                openModal={() => fullScreenModalHandlers.openFullScreenModal(sortedFiles, index)}
                            />
                        </div>
                    ))}
                />
            </div>
            <Popup
                open={fullScreenModalHandlers.openedFullScreenModal}
                modal
                overlayStyle={{ zIndex: 999999 }}
                onClose={fullScreenModalHandlers.closeFullScreenModal}
                className="full-screen-modal__popup"
            >
                <FullScreenModal
                    photos={sortedFiles}
                    closeCallback={fullScreenModalHandlers.closeFullScreenModal}
                    activeIndex={fullScreenModalHandlers.activeImageIndex}
                    toPreviousPhoto={fullScreenModalHandlers.toPreviousPhoto}
                    toNextPhoto={fullScreenModalHandlers.toNextPhoto}
                />
            </Popup>
        </div>
    );
};

export default withTranslation()(Shop);

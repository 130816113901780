import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../../types/Translate";
import { PayoutDetailsPageType } from "../../../../enums/payout-details-page-enum";
import PayoutResolveFormModal from "../../../admin/payouts/PayoutResolveFormModal";
import { Payout } from "../../../../domain/models/Payout";
import { useAuth } from "../../../../context/AuthProvider";
import PrintButtonSVG from "../../svg/PrintButtonSVG";
import DownloadPDFButtonSVG from "../../svg/DownloadPDFButtonSVG";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PayoutsDetailsInformationPDF from "./PayoutsDetailsInformationPDF";
import { NameFormattingServiceImpl } from "../../../../services/NameFormattingService";
import { useNavigate } from "react-router-dom";
import { Payment } from "../../../../domain/models/Payment";
import PayoutDetailsPaymentsPDF from "./payout-details-payments/PayoutDetailsPaymentsPDF";
import { paymentsPDFPrefix, payoutPDFPrefix } from "../../../../constants";

interface PayoutDetailsHeaderProps {
    t: Translate;

    setPayoutDetailsPageType: (value: PayoutDetailsPageType) => void;

    payout: Payout;
    payments: Payment[];
    isInformation: boolean;
    isPayments: boolean;
    reloadCache: () => void;
}

const PayoutDetailsHeader: FC<PayoutDetailsHeaderProps> = (props) => {
    const [isPayoutResolveFormModalActive, setPayoutResolveFormModalActive] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const isPayoutPDF = props.isInformation || !props.payments.length;
    const document = isPayoutPDF ? (
        <PayoutsDetailsInformationPDF payout={props.payout} />
    ) : (
        <PayoutDetailsPaymentsPDF isAdmin={user.isAdmin} payments={props.payments} />
    );
    const fileName = NameFormattingServiceImpl.getFileName(
        props.payout.templeName,
        isPayoutPDF ? payoutPDFPrefix : paymentsPDFPrefix
    );

    const navigateToPayouts = () => {
        if (user.isAdmin) {
            return navigate("/admin/payouts");
        }
        navigate("/temple-admin/payouts");
    };

    return (
        <>
            <div className="inner-page__wrap">
                <button onClick={() => navigateToPayouts()} className="back-button">
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 1L1.5 5L6 9" stroke="#F9F9F9" strokeLinecap="round"></path>
                    </svg>
                </button>
                <div className="inner-page__title">{props.t("admin.payouts.details.title").toUpperCase()}</div>
            </div>
            <div className="inner__graphics-tabs-wrap">
                <div className="inner__graphics-tabs">
                    <div
                        className={props.isInformation ? "active" : ""}
                        onClick={() => props.setPayoutDetailsPageType(PayoutDetailsPageType.INFORMATION)}
                    >
                        {props.t("admin.payouts.details.informationTitle")}
                    </div>
                    <div
                        className={props.isPayments ? "active" : ""}
                        onClick={() => props.setPayoutDetailsPageType(PayoutDetailsPageType.PAYMENTS)}
                    >
                        {props.t("admin.payouts.details.paymentsTitle")}
                    </div>
                </div>
                {user.isTempleAdmin || !props.payout.inReview ? (
                    ""
                ) : (
                    <button
                        onClick={() => setPayoutResolveFormModalActive(true)}
                        className="payout-information-button payout-information-button__finish"
                    >
                        {props.t("admin.payouts.details.finishButton")}
                    </button>
                )}
                <PrintButtonSVG onClick={() => window.print()} />
                <PDFDownloadLink document={document} fileName={fileName}>
                    <DownloadPDFButtonSVG />
                </PDFDownloadLink>
                <PayoutResolveFormModal
                    isPayoutResolveFormModalActive={isPayoutResolveFormModalActive}
                    closeModalCallback={() => setPayoutResolveFormModalActive(false)}
                    payout={props.payout}
                    reloadCache={props.reloadCache}
                />
            </div>
        </>
    );
};

export default withTranslation()(PayoutDetailsHeader);

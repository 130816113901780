import { FC, useEffect, useState } from "react";
import TableHeaderSVG from "../svg/TableHeaderSVG";
import { SortingOrder } from "../../../enums/sorting-order-enum";

interface SortingColumnProps {
    sortingOrderHandler: (sortingOrder: SortingOrder) => void;

    columnName: string;
    columnTranslationName: string;
    additionalWrapperClass?: string;
    disableBaseColumnStyle?: boolean;

    currentActiveColumn: string;
    setCurrentActiveColumn: (columnName: string) => void;
}

const SortingColumn: FC<SortingColumnProps> = (props) => {
    const [isActiveColumn, setIsActiveColumn] = useState<boolean>(false);
    const changeSortingOrder = () => {
        if (!isActiveColumn) {
            props.setCurrentActiveColumn(props.columnName);
            props.sortingOrderHandler(SortingOrder.ASC);
        } else {
            props.sortingOrderHandler(SortingOrder.DESC);
        }

        setIsActiveColumn(!isActiveColumn);
    };

    useEffect(() => {
        if (props.currentActiveColumn !== props.columnName && isActiveColumn) {
            setIsActiveColumn(false);
        }
    }, [props.currentActiveColumn, props.columnName, isActiveColumn]);

    return (
        <th
            className={
                "th-sort " +
                (props.additionalWrapperClass || " ") +
                (isActiveColumn ? " active" : " ") +
                (!props.disableBaseColumnStyle ? " th-style" : " ")
            }
            onClick={changeSortingOrder}
        >
            <span>
                <div className="payouts-table-column-text">{props.columnTranslationName}</div>
                <TableHeaderSVG />
            </span>
        </th>
    );
};

export default SortingColumn;

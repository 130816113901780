import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";

export enum Prayer {
    FOR_HEALTH = 1,
    FOR_PEACE = 2,
}

export function getPrayerTypesTranslation(t: Translate): SelectProps[] {
    return [
        { value: Prayer.FOR_HEALTH.toString(), label: t(`prayer.type.${Prayer.FOR_HEALTH}`) },
        { value: Prayer.FOR_PEACE.toString(), label: t(`prayer.type.${Prayer.FOR_PEACE}`) },
    ];
}

export function getCurrentPrayerTypeTranslation(t: Translate, type: Prayer): SelectProps {
    return { value: type?.toString(), label: t(type && `prayer.type.${type}`) };
}

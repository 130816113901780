import { Mapper } from "../domain/mappers/mapper";
import { TemplePayments } from "../domain/models/Temple";
import { TemplePaymentsDTO } from "../domain/models/dto/TempleDTO";
import TemplePaymentsModel from "../models/TemplePaymentsModel";
import { SimpleMapper } from "./SimpleMapper";

class TemplePaymentsMapper extends SimpleMapper<TemplePayments, TemplePaymentsDTO> {
    protected entityConstructor = TemplePaymentsModel;

    protected fromDTOFields: string[] = [
        "id",
        "name",
        "totalPaymentsAmount",
    ];
}


export default function templePaymentsMapperFactory(): Mapper<TemplePayments, TemplePaymentsDTO> {
    return new TemplePaymentsMapper()
}

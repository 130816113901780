import { FC } from "react";

const PaginationLeft: FC = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="23" height="23" rx="3" fill="#DCDCDC" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8536 7.14645C11.0488 7.34171 11.0488 7.65829 10.8536 7.85355L7.70711 11H16.5C16.7761 11 17 11.2239 17 11.5C17 11.7761 16.7761 12 16.5 12H7.70711L10.8536 15.1464C11.0488 15.3417 11.0488 15.6583 10.8536 15.8536C10.6583 16.0488 10.3417 16.0488 10.1464 15.8536L6.14645 11.8536C5.95118 11.6583 5.95118 11.3417 6.14645 11.1464L10.1464 7.14645C10.3417 6.95118 10.6583 6.95118 10.8536 7.14645Z"
                fill="#323232"
            />
        </svg>
    );
};

export default PaginationLeft;

import { FC, MutableRefObject } from "react";
import { WorshipSchedule } from "../../../../../../domain/models/WorshipSchedule";
import InfoEditSVG from "../../../../../base/svg/InfoEditSVG";
import { SHORT_DATE_FORMAT } from "../../../../../../constants";
import WorshipScheduleItem from "./WorshipScheduleItem";
import { withTranslation } from "react-i18next";
import DarkRemoveSVG from "../../../../../base/svg/DarkRemoveSVG";
import { WorshipScheduleService } from "../../../../../../domain/services/WorshipScheduleService";
import worshipScheduleServiceFactory from "../../../../../../services/WorshipScheduleServiceImpl";
import { useRemove } from "../../../../../../tools/custom-hooks/remove-hooks/useRemove";
import { WorshipScheduleDTO } from "../../../../../../domain/models/dto/WorshipScheduleDTO";

interface WorshipScheduleInfoProps {
    t(key?: string): string;
    schedules: WorshipSchedule[];
    setCurrentWorshipSchedule(value: WorshipSchedule): void;

    isEditing: boolean;
    setIsEditing(value: boolean): void;
    removeSchedule(id: string): void;
    worshipScheduleFormRef: MutableRefObject<any>;
    additionalWrapperClassName: string;
}

const WorshipScheduleInfo: FC<WorshipScheduleInfoProps> = (props) => {
    const firstSchedule = props.schedules[0];

    const editInfo = () => {
        props.setCurrentWorshipSchedule(firstSchedule);
        props.setIsEditing(true);
        props.worshipScheduleFormRef && props.worshipScheduleFormRef.current.scrollIntoView();
    };

    const { isErrorVisible, setRemovableModel } = useRemove<WorshipScheduleDTO, WorshipSchedule, WorshipScheduleService>(
        worshipScheduleServiceFactory,
        props.removeSchedule
    );

    return (
        <>
            {isErrorVisible && props.t("errors.common")}
            <div className={`worship__block ${props.additionalWrapperClassName}`}>
                <table className="worship__table">
                    <tbody>
                    <tr>
                            <td rowSpan={props.schedules.length}>
                                {props.t(`weekDays.${firstSchedule.date.isoWeekday()}`)} <br />
                                {firstSchedule.date.format(SHORT_DATE_FORMAT)}
                            </td>
                            <td className="worship__table-date">
                                {firstSchedule.begin}-{firstSchedule.end}
                            </td>
                            <td className="worship__table-item">{firstSchedule.name}</td>
                            <td className="worship__info-edit">
                                <button type="button" onClick={() => setRemovableModel(firstSchedule)}>
                                    <DarkRemoveSVG />
                                </button>

                                <button type="button" onClick={() => editInfo()}>
                                    <InfoEditSVG />
                                </button>
                            </td>
                        </tr>

                        {props.schedules.slice(1).map((schedule, index) => (
                            <tr key={"register-worship-schedule-info." + index}>
                                <WorshipScheduleItem
                                    setRemovableModel={setRemovableModel}
                                    schedule={schedule}
                                    setCurrentWorshipSchedule={props.setCurrentWorshipSchedule}
                                    isEditing={props.isEditing}
                                    setIsEditing={props.setIsEditing}
                                />
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default withTranslation()(WorshipScheduleInfo);

import { FC } from "react";

const PaginationRight: FC = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="23" height="23" rx="3" transform="matrix(-1 0 0 1 23 0)" fill="#DCDCDC" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1464 7.14645C11.9512 7.34171 11.9512 7.65829 12.1464 7.85355L15.2929 11H6.5C6.22386 11 6 11.2239 6 11.5C6 11.7761 6.22386 12 6.5 12H15.2929L12.1464 15.1464C11.9512 15.3417 11.9512 15.6583 12.1464 15.8536C12.3417 16.0488 12.6583 16.0488 12.8536 15.8536L16.8536 11.8536C17.0488 11.6583 17.0488 11.3417 16.8536 11.1464L12.8536 7.14645C12.6583 6.95118 12.3417 6.95118 12.1464 7.14645Z"
                fill="#323232"
            />
        </svg>
    );
};

export default PaginationRight;

import _ from "lodash";
import { SelectProps } from "../../domain/models/SelectProps";
import { Translate } from "../Translate";

export enum Months {
    JANUARY,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER
}

export function getCurrentMonthTranslation(t: Translate, month: Months): SelectProps {
    return { value: month?.toString(), label: t(!_.isNil(month) && `months.${month}`) };
}

export function getMonthsTranslation(t: Translate): SelectProps[] {
    return [
        { value: Months.JANUARY.toString(), label: t(`months.${Months.JANUARY}`) },
        { value: Months.FEBRUARY.toString(), label: t(`months.${Months.FEBRUARY}`) },
        { value: Months.MARCH.toString(), label: t(`months.${Months.MARCH}`) },
        { value: Months.APRIL.toString(), label: t(`months.${Months.APRIL}`) },
        { value: Months.MAY.toString(), label: t(`months.${Months.MAY}`) },
        { value: Months.JUNE.toString(), label: t(`months.${Months.JUNE}`) },
        { value: Months.JULY.toString(), label: t(`months.${Months.JULY}`) },
        { value: Months.AUGUST.toString(), label: t(`months.${Months.AUGUST}`) },
        { value: Months.SEPTEMBER.toString(), label: t(`months.${Months.SEPTEMBER}`) },
        { value: Months.OCTOBER.toString(), label: t(`months.${Months.OCTOBER}`) },
        { value: Months.NOVEMBER.toString(), label: t(`months.${Months.NOVEMBER}`) },
        { value: Months.DECEMBER.toString(), label: t(`months.${Months.DECEMBER}`) }
    ];
}

export function getMonthsListTranslation(t: Translate): string[] {
    return [
        t(`months.${Months.JANUARY}`),
        t(`months.${Months.FEBRUARY}`),
        t(`months.${Months.MARCH}`),
        t(`months.${Months.APRIL}`),
        t(`months.${Months.MAY}`),
        t(`months.${Months.JUNE}`),
        t(`months.${Months.JULY}`),
        t(`months.${Months.AUGUST}`),
        t(`months.${Months.SEPTEMBER}`),
        t(`months.${Months.OCTOBER}`),
        t(`months.${Months.NOVEMBER}`),
        t(`months.${Months.DECEMBER}`)
    ];
}

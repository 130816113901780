import { FC, MutableRefObject } from "react";
import { withTranslation } from "react-i18next";
import { PrayerRequirementDTO } from "../../../../../domain/models/dto/PrayerRequirementDTO";
import { PrayerRequirement } from "../../../../../domain/models/PrayerRequirement";
import { Temple } from "../../../../../domain/models/Temple";
import { PrayerRequirementService } from "../../../../../domain/services/PrayerRequirementService";
import prayerRequirementMapperFactory from "../../../../../mappers/PrayerRequirementMapper";
import prayerRequirementServiceFactory from "../../../../../services/PrayerRequirementServiceImpl";
import { useOrderedEntityRemove } from "../../../../../tools/custom-hooks/remove-hooks/useOrderedEntityRemove";
import { Translate } from "../../../../../types/Translate";
import PrayerRequirementInfo from "./PrayerRequirementInfo";

interface PrayerRequirementsProps {
    t: Translate;

    temple: Temple;
    prayerRequirements: PrayerRequirement[];
    setCurrentPrayerRequirement(value: PrayerRequirement): void;
    removePrayerRequirement(order: number): PrayerRequirement[];

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    isOrderChanged: boolean;
    setIsOrderChanged(value: boolean): void;

    formRef: MutableRefObject<any>;
}

const PrayerRequirements: FC<PrayerRequirementsProps> = (props) => {
    const removeHandlers = useOrderedEntityRemove<PrayerRequirementDTO, PrayerRequirement, PrayerRequirementService>(
        prayerRequirementServiceFactory,
        prayerRequirementMapperFactory,
        props.removePrayerRequirement
    );

    return (
        <div className="entity-container-scroll">
            {props.prayerRequirements?.map((prayerRequirement, index) => (
                <div key={index}>
                    {prayerRequirement?.id && (
                        <PrayerRequirementInfo
                            temple={props.temple}
                            prayerRequirement={prayerRequirement}
                            setRemovableModel={removeHandlers.setRemovableModel}
                            isOrderChanged={props.isOrderChanged}
                            setIsOrderChanged={props.setIsOrderChanged}
                            setCurrentPrayerRequirement={props.setCurrentPrayerRequirement}
                            isEditing={props.isEditing}
                            setIsEditing={props.setIsEditing}
                            formRef={props.formRef}
                            additionalWrapperClassName={index === 0 ? "" : "date__names__wrapper"}
                        />
                    )}
                </div>
            ))}
            {removeHandlers.isErrorVisible && props.t("errors.common")}
        </div>
    );
};

export default withTranslation()(PrayerRequirements);

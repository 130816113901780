import { FC } from "react";
import { useArtifactDoubleModalContext } from "../../../../../context/ArtifactDoubleModalProvider";
import { Artifact } from "../../../../../domain/models/Artifact";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import MainImageSlide from "../../../../base/photo-slider/MainImageSlide";
import PhotoSlider from "../../../../base/photo-slider/PhotoSlider";
import ArtifactModalLeftSlide from "./ArtifactModalLeftSlide";

interface ArtifactModalLeftProps {
    artifact: Artifact;
}

const ArtifactModalLeft: FC<ArtifactModalLeftProps> = (props) => {
    const slidesPerView = 3;
    const sortedFiles = props.artifact?.getSortedFiles(SortingOrder.ASC);

    const modalHandlers = useArtifactDoubleModalContext();
    const activeImagePath = sortedFiles[modalHandlers.activeImageIndex]?.filePath;

    return (
        <div className="modal--main__left">
            <div className={(sortedFiles.length > slidesPerView ? "modal--main__image-less" : "modal--main__image") + (!activeImagePath ? " base__no__photo__svg" : " ")}>
                <MainImageSlide
                    filePath={activeImagePath}
                    slideOnClick={() => modalHandlers.openFullScreenModal(sortedFiles)}
                    wrapClassName="modal--main__image-wrapper"
                />
            </div>
            <div className="modal--main__swiper">
                <PhotoSlider
                    slidesPerView={slidesPerView}
                    spaceBetween={15}
                    loop={sortedFiles.length > slidesPerView}
                    dynamicBullets={true}
                    dynamicMainBullets={4}
                    disableAutoplay={true}
                    content={sortedFiles.map((file, index) => (
                        <ArtifactModalLeftSlide filePath={file.filePath} setActiveImageCallback={() => modalHandlers.setActiveImageIndex(index)} />
                    ))}
                />
            </div>
        </div>
    );
};

export default ArtifactModalLeft;

import { PaymentAccountDTO } from "../domain/models/dto/PaymentAccountDTO";
import { PaymentAccount } from "../domain/models/PaymentAccount";
import { Currency } from "../enums/currency-enum";
import { PaymentMethod } from "../enums/payment-method-enum";
import { BaseRelatedOrderedEntityMixinFactory } from "./BaseTempleRelatedModel";

class PaymentAccountModel extends BaseRelatedOrderedEntityMixinFactory<PaymentAccountDTO>() implements PaymentAccount {
    order: number;
    templeId?: string;
    paymentMethod?: PaymentMethod;
    accountId?: string;
    recipientName?: string;
    currency?: Currency;

    constructor(data?: PaymentAccountDTO) {
        super();
        Object.assign(this, data || {});
    }
}


export default PaymentAccountModel

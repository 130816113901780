import * as Yup from "yup"
import { SchemaFactoryProps } from "../../types/SchemaFactory";


export const OrderPrayerSchemaFactory = (props: SchemaFactoryProps) => {
    const t = props.t;
    return Yup.object().shape({
        prayerId: Yup.string().required(t("errors.commonErrors.required")),
        conditionsAccepted: Yup.boolean().required(t("errors.commonErrors.required"))
            .test(
                "conditionsAccepted",
                t("errors.commonErrors.required"),
                function (value) {
                    return value;
                }
            ),
        senderName: Yup.string().required(t("errors.commonErrors.required")),
        senderEmail: Yup.string().email(t("errors.commonErrors.isNotCorrectEmail")).required(t("errors.commonErrors.required")),
        details: Yup.string().max(1000, t("errors.commonErrors.max") + "1000").nullable(),
        orderDetails: Yup.array().of(Yup.string()).nullable(),
    });
}

import { ArtifactDTO } from "../domain/models/dto/ArtifactDTO";
import { Artifact } from "../domain/models/Artifact";
import { ArtifactModel } from "../models/ArtifactModel";
import fileMapperFactory from "./FileMapper";
import _ from "lodash";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";
import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";

class ArtifactMapper extends SimpleChangesRequestEntityMapper<Artifact, ArtifactDTO> {
    protected entityConstructor = ArtifactModel;

    protected toDTOFields: string[] = [
        "id",
        "templeId",
        "name",
        "info",
        "isDeleted"
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        ...this.toChangesDTOFields,
        "status",
        "createdAt",
        "updatedAt",
    ];

    fromDTO(model: ArtifactDTO): Artifact {
        const fileMapper = fileMapperFactory();

        if (!model) {
            return new ArtifactModel();
        }

        const artifact = super.fromDTO(model);

        artifact.files = !_.isEmpty(model.files) ? model.files.map(file => fileMapper.fromDTO(file)) : [];

        return artifact;
    }

    toDTO(model: Artifact): ArtifactDTO {
        if (!model) {
            return;
        }

        const artifact = super.toDTO(model);

        return _.omitBy(artifact, _.isNil) as ArtifactDTO;
    }
}

export default function artifactMapperFactory(): ChangesRequestEntityMapper<Artifact, ArtifactDTO> {
    return new ArtifactMapper()
}

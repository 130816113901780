import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import { userServiceFactory } from "../../../services/UserServiceImpl";
import SidebarLogoutSVG from "../../base/svg/SidebarLogoutSVG";
import MainAdminTempleName from "./MainAdminTempleName";

interface SidebarProps {
    t(key?: string): string;
}

const Sidebar: FC<SidebarProps> = (props) => {
    const location = useLocation().pathname.split("/")[2];
    const { signOut } = useAuth();
    const currentUser = userServiceFactory().currentUser;

    return (
        <div className="sidebar">
            <MainAdminTempleName user={currentUser} />
            <div className="sidebar__nav-wrap">
                <ul className="sidebar__nav">
                    <li className="sidebar__item">
                        <Link
                            to="/temple-admin/main"
                            className={"sidebar__link" + (location === "main" ? " active" : "")}
                        >
                            {props.t("admin.sidebar.main")}
                        </Link>
                    </li>
                    <li className="sidebar__item">
                        <Link
                            to="/temple-admin/payments"
                            className={"sidebar__link " + (location === "payments" ? "active" : "")}
                        >
                            {props.t("admin.sidebar.payments")}
                        </Link>
                        {/* <span className="sidebar__notification">+3</span> */}
                    </li>
                    <li className="sidebar__item">
                        <Link
                            to="/temple-admin/payouts"
                            className={"sidebar__link" + (location === "payouts" ? " active" : "")}
                        >
                            {props.t("admin.sidebar.payouts")}
                        </Link>
                    </li>
                    <li className="sidebar__item">
                        <Link
                            to="/temple-admin/updates"
                            className={"sidebar__link " + (location === "updates" ? "active" : "")}
                        >
                            {props.t("admin.sidebar.updates")}
                        </Link>
                        {/* <span className="sidebar__notification">+3</span> */}
                    </li>
                    {/* <li className="sidebar__item">
                        <Link
                            to="/temple-admin/visited"
                            className={"sidebar__link " + (location === "visited" ? "active" : "")}
                        >
                            {props.t("admin.sidebar.visited")}
                        </Link>
                    </li>
                    <li className="sidebar__item">
                        <Link
                            to="/temple-admin/messenger"
                            className={"sidebar__link " + (location === "messenger" ? "active" : "")}
                        >
                            {props.t("admin.sidebar.messenger")}
                        </Link>
                    </li> */}
                </ul>
            </div>
            <a href="#" className="sidebar__logout" onClick={() => signOut()}>
                <SidebarLogoutSVG />
                <span onClick={() => signOut()}>{props.t("admin.sidebar.goOut")}</span>
            </a>
        </div>
    );
};

export default withTranslation()(Sidebar);

import { FormikProps, useFormik } from "formik";
import { FC, MutableRefObject, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { maxFilesGroupSize } from "../../../../../Config";
import { DISABLED_COLOR } from "../../../../../constants";
import { useFormsValidConfirmation } from "../../../../../context/FormikFormsProvider";
import { Artifact, ArtifactForm as IArtifactForm } from "../../../../../domain/models/Artifact";
import { Temple } from "../../../../../domain/models/Temple";
import { Owner } from "../../../../../enums/parent-enum";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import artifactServiceFactory from "../../../../../services/ArtifactServiceImpl";
import { useUpdateFileOrder } from "../../../../../tools/custom-hooks/useUpdateFileOrder";
import { getValidationClass } from "../../../../../tools/Tools";
import { ArtifactSchemaFactory } from "../../../../../validation-schemas/temple-register/artifact-schemas";
import EditableImagesList from "../../../../base/EditableImagesList";
import ChangeEntityButtons from "../../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../../base/InputRestrictionsInfo";
import AddButtonSVG from "../../../../base/svg/AddButtonSVG";
import ConflictErrorModal from "../../../../base/conflict-error-modal/ConflictErrorModal";
import ContentTooLargeErrorModal from "../../../../base/content-too-large-error-modal/ContentTooLargeErrorModal";

interface ArtifactFormProps {
    t(key?: string): string;
    artifact: Artifact;
    temple: Temple;

    updateArtifacts(newArtifact: Artifact): void;
    resetArtifactForm(): void;
    resetErrorCallback(): void;
    artifactFormRef: MutableRefObject<any>;
}

const ArtifactForm: FC<ArtifactFormProps> = (props) => {
    const artifactService = artifactServiceFactory();

    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);

    const updateFileOrder = useUpdateFileOrder<Artifact>(props.artifact, setIsOrderChanged);

    const nullableValues: IArtifactForm = {
        id: "",
        name: "",
        info: "",
    };

    const artifactSchema = useMemo(() => ArtifactSchemaFactory({ t: props.t }), [props.t]);

    const artifactForm: FormikProps<IArtifactForm> = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            name: props.artifact?.name,
            info: props.artifact?.info,
        },
        validationSchema: artifactSchema.schema,
        onSubmit: async (values, actions) => {
            const artifact = props.artifact;

            try {
                setErrorVisibility(false);
                props.resetErrorCallback();
                actions.setSubmitting(true);

                const newArtifact: Artifact | undefined = await artifactService.createRequestOrUpdateCurrentEntity({
                    templeId: props.temple.originId,
                    id: values.id || undefined,
                    name: values.name,
                    info: values.info,
                });

                if (!newArtifact) {
                    return setErrorVisibility(true);
                }

                const files = await artifact.resolveFiles(newArtifact?.id);
                if (files.isErrorVisible) {
                    return setErrorVisibility(true);
                }

                if (newArtifact) {
                    artifact.id && newArtifact.setCreatedAt(artifact.createdAt);
                    newArtifact.setFiles(artifact.getUpdatedFilesList(files.images));

                    props.updateArtifacts(newArtifact);
                }

                actions.resetForm({
                    values: nullableValues,
                    touched: {},
                    errors: {},
                    isSubmitting: false,
                });
            } catch {
                setErrorVisibility(true);
            }
        },
    });

    useFormsValidConfirmation("artifactForm", artifactForm);

    const editArtifactFormHandler = () => {
        artifactForm.setValues({
            ...artifactForm.values,
            id: props.artifact?.id,
        });
        artifactForm.handleSubmit();
    };

    useEffect(() => {
        if (isOrderChanged) {
            setIsOrderChanged(false);
        }
    }, [isOrderChanged]);

    return (
        <div className="choose__temple__pagination" ref={props.artifactFormRef}>
            <div className="date__form-block">
                <input
                    className={`date__input ${getValidationClass(artifactForm, "name")}`}
                    name="name"
                    onChange={artifactForm.handleChange}
                    value={!artifactForm.isSubmitting ? artifactForm.values.name : nullableValues.name}
                    type="text"
                    placeholder={props.t("templeRegister.abbot.namePlaceholder")}
                    maxLength={artifactSchema.inputRestrictions.name.max}
                />
                <InputRestrictionsInfo
                    min={artifactSchema.inputRestrictions.name.min}
                    max={artifactSchema.inputRestrictions.name.max}
                    error={artifactForm.errors.name}
                />
            </div>

            <div className="date__form-block">
                <textarea
                    className={`date__textarea date__input ${getValidationClass(artifactForm, "info")}`}
                    name="info"
                    onChange={artifactForm.handleChange}
                    value={!artifactForm.isSubmitting ? artifactForm.values.info : nullableValues.info}
                    cols={30}
                    rows={10}
                    placeholder={props.t("templeRegister.abbot.infoPlaceholder2")}
                    maxLength={artifactSchema.inputRestrictions.info.max}
                ></textarea>
                <InputRestrictionsInfo
                    min={artifactSchema.inputRestrictions.info.min}
                    max={artifactSchema.inputRestrictions.info.max}
                    error={artifactForm.errors.info}
                />
            </div>

            <EditableImagesList
                files={props.artifact?.getSortedFiles(SortingOrder.ASC)}
                setErrorVisibility={setErrorVisibility}
                setIsFilesUpdated={(val) => props.artifact.setIsFilesUpdated(val)}
                ownerType={Owner.ARTIFACT}
                maxImagesCount={maxFilesGroupSize}
                getMaxOrder={props.artifact.getMaxOrder}
                getNewOrder={props.artifact.getNextFileOrder}
                moveFileToLeft={updateFileOrder(true)}
                moveFileToRight={updateFileOrder(false)}
                removeFileByOrder={(order: number) => props.artifact.removeFileByOrder(order)}
            ></EditableImagesList>

            {isErrorVisible && <p>{props.t("errors.common")}</p>}
            <div className="date__bottom-buttons">
                <ChangeEntityButtons<IArtifactForm>
                    formik={artifactForm}
                    initialValues={nullableValues}
                    disableSaveButton={!props.artifact.id}
                    editFormHandler={editArtifactFormHandler}
                    resetEntityFormHandler={props.resetArtifactForm}
                />

                {!props.artifact.id && (
                    <div className="date__button-add__block">
                        <button
                            className={
                                !artifactForm.isValid ? "disabled-add-button establish__button-add" : "date__button-add establish__button-add"
                            }
                            type="button"
                            disabled={artifactForm.isSubmitting}
                            onClick={() => artifactForm.handleSubmit()}
                        >
                            <span>
                                <AddButtonSVG fill={!artifactForm.isValid && DISABLED_COLOR} />
                            </span>
                            {props.t("templeRegister.abbot.addShrine")}
                        </button>
                    </div>
                )}
            </div>
            <ConflictErrorModal />
            <ContentTooLargeErrorModal />
        </div>
    );
};

export default withTranslation()(ArtifactForm);

export enum PaymentFields {
    TEMPLE_ID = "templeId",
    STATUS = "status",
    TRANSACTION_TYPE = "transactionType",
    PAYMENT_ID = "paymentId",
    SENDER_NAME = "senderName",
    PAYMENT_DATE = "paymentDate",
    AMOUNT = "amount",
    RECIPIENT_AMOUNT = "recipientAmount",
    PLATFORM_AMOUNT = "platformAmount",
    CREATED_AT = "createdAt"
}

export enum PaymentChartsFields {
    AMOUNT = "amount",
}


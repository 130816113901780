import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Temple } from "../../../domain/models/Temple";
import { CreateTempleParams } from "../../../enums/create-temple-params-enum";
import { TempleRegisterPage } from "../../../enums/temple-register-page-enum";

interface SidebarProps {
    t(key?: string): string;
    newTemple: Temple | undefined | null;
}

const Sidebar: FC<SidebarProps> = (props) => {
    const location = useLocation().pathname.split("/")[2];
    const navigate = useNavigate();

    const isMainInfo = location === TempleRegisterPage.MAIN_INFO;
    const isAbbot = location === TempleRegisterPage.ABBOT;
    const isSchedules = location === TempleRegisterPage.SCHEDULES;
    const isVisitorsInfo = location === TempleRegisterPage.VISITORS_INFO;
    const isOrders = location === TempleRegisterPage.ORDERS;
    const isPaymentsDetails = location === TempleRegisterPage.PAYMENT_DETAILS;
    const isBankDetails = location === TempleRegisterPage.BANK_DETAILS;
    const isGalleries = location === TempleRegisterPage.GALLERIES;
    const isCorrespondence = location === TempleRegisterPage.CORRESPONDENCE;

    return (
        <div className="date__sidebar">
            <ul className="date__steps-list">
                <li
                    className={
                        "date__step" +
                        (isMainInfo ? " active" : "") +
                        (props.newTemple?.isMainInfoComplete && !isMainInfo ? " finish" : "") +
                        (props.newTemple?.isMainInfoComplete && isMainInfo ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isMainInfoComplete && navigate("/create-temple/".concat(TempleRegisterPage.MAIN_INFO).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>1. {props.t("templeRegister.sidebar.mainInfo")}</span>
                </li>

                <li
                    className={
                        "date__step" +
                        (isAbbot ? " active" : "") +
                        (props.newTemple?.isAbbotComplete && !isAbbot ? " finish" : "") +
                        (props.newTemple?.isAbbotComplete && isAbbot ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isAbbotComplete && navigate("/create-temple/".concat(TempleRegisterPage.ABBOT).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>2. {props.t("templeRegister.sidebar.abbot")}</span>
                </li>

                <li
                    className={
                        "date__step" +
                        (isSchedules ? " active" : "") +
                        (props.newTemple?.isSchedulesComplete && !isSchedules ? " finish" : "") +
                        (props.newTemple?.isSchedulesComplete && isSchedules ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isSchedulesComplete && navigate("/create-temple/".concat(TempleRegisterPage.SCHEDULES).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>3. {props.t("templeRegister.sidebar.schedules")}</span>
                </li>

                <li
                    className={
                        "date__step" +
                        (isVisitorsInfo ? " active" : "") +
                        (props.newTemple?.isVisitorsInfoComplete && !isVisitorsInfo ? " finish" : "") +
                        (props.newTemple?.isVisitorsInfoComplete && isVisitorsInfo ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isVisitorsInfoComplete && navigate("/create-temple/".concat(TempleRegisterPage.VISITORS_INFO).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>4. {props.t("templeRegister.sidebar.visitorsInfo")}</span>
                </li>

                <li
                    className={
                        "date__step" +
                        (isOrders ? " active" : "") +
                        (props.newTemple?.isOrdersComplete && !isOrders ? " finish" : "") +
                        (props.newTemple?.isOrdersComplete && isOrders ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isOrdersComplete && navigate("/create-temple/".concat(TempleRegisterPage.ORDERS).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>5. {props.t("templeRegister.sidebar.orders")}</span>
                </li>

                <li
                    className={
                        "date__step" +
                        (isPaymentsDetails ? " active" : "") +
                        (props.newTemple?.isPaymentsDetailsComplete && !isPaymentsDetails ? " finish" : "") +
                        (props.newTemple?.isPaymentsDetailsComplete && isPaymentsDetails ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isPaymentsDetailsComplete && navigate("/create-temple/".concat(TempleRegisterPage.PAYMENT_DETAILS).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>6. {props.t("templeRegister.sidebar.paymentDetails")}</span>
                </li>

                <li
                    className={
                        "date__step" +
                        (isBankDetails ? " active" : "") +
                        (props.newTemple?.isBankDetailsComplete && !isBankDetails ? " finish" : "") +
                        (props.newTemple?.isBankDetailsComplete && isBankDetails ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isBankDetailsComplete && navigate("/create-temple/".concat(TempleRegisterPage.BANK_DETAILS).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>7. {props.t("templeRegister.sidebar.bankDetails")}</span>
                </li>

                <li
                    className={
                        "date__step" +
                        (isGalleries ? " active" : "") +
                        (props.newTemple?.isGalleriesComplete && !isGalleries ? " finish" : "") +
                        (props.newTemple?.isGalleriesComplete && isGalleries ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isGalleriesComplete && navigate("/create-temple/".concat(TempleRegisterPage.GALLERIES).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>8. {props.t("templeRegister.sidebar.galleries")}</span>
                </li>

                <li
                    className={
                        "date__step" +
                        (isCorrespondence ? " active" : "") +
                        (props.newTemple?.isCorrespondenceComplete && !isCorrespondence ? " finish" : "") +
                        (props.newTemple?.isCorrespondenceComplete && isCorrespondence ? " active__finish" : "")
                    }
                    onClick={() => {
                        props.newTemple?.isCorrespondenceComplete && navigate("/create-temple/".concat(TempleRegisterPage.CORRESPONDENCE).concat(`?${CreateTempleParams.ID}=${props.newTemple.originId}`));
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>9. {props.t("templeRegister.sidebar.correspondence")}</span>
                </li>
            </ul>
        </div>
    );
};

export default withTranslation()(Sidebar);

import { FC } from "react";
import { Outlet } from "react-router-dom";
import AdminFooter from "../base/AdminFooter";
import Header from "./Header";
import Sidebar from "./sidebar/Sidebar";

interface MainTempleAdminComponentProps {}

const MainTempleAdminComponent: FC<MainTempleAdminComponentProps> = () => {
    return (
        <>
            <div className="wrapper">
                <Sidebar  />
                <div className="container">
                    <div className="content">
                        <Header  />
                        <main className="main">
                            <div className="inner__content7">
                                <Outlet />
                            </div>
                        </main>
                        <AdminFooter  isTempleAdmin={true} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainTempleAdminComponent;

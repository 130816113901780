import _ from "lodash";
import { Mapper } from "../domain/mappers/mapper";
import { SearchTempleDataDTO } from "../domain/models/dto/SearchData";
import { SearchTempleData } from "../domain/models/SearchData";
import { SearchTempleDataModel } from "../models/SearchTempleDataModel";
import searchEntityMapperFactory from "./SearchEntityMapper";
import { SimpleMapper } from "./SimpleMapper";

class SearchTempleDataMapper extends SimpleMapper<SearchTempleData, SearchTempleDataDTO> {
    protected entityConstructor = SearchTempleDataModel;

    protected fromDTOFields: string[] = [
        "templeId",
        "templeName",
    ];

    fromDTO(dto: SearchTempleDataDTO): SearchTempleData {
        const searchEntityMapper = searchEntityMapperFactory();
        const model = super.fromDTO(dto);

        model.details = !_.isEmpty(dto.details) ? dto.details.map(detail => searchEntityMapper.fromDTO(detail)) : [];
        return model;
    }
}


export default function searchTempleDataMapperFactory(): Mapper<SearchTempleData, SearchTempleDataDTO> {
    return new SearchTempleDataMapper();
}

import { FC } from "react";

interface AddButtonSVGProps {
    fill?: string;
}

const AddButtonSVG: FC<AddButtonSVGProps> = (props) => {
    const fill = props.fill ? props.fill : "#fff";
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
                <path
                    d="M2455 5106 c-83 -20 -146 -56 -211 -120 -65 -66 -105 -137 -123 -224
-8 -37 -11 -316 -11 -902 l0 -850 -850 0 c-586 0 -865 -3 -902 -11 -86 -18
-158 -58 -224 -123 -177 -178 -177 -455 0 -631 66 -65 138 -105 224 -124 37
-8 307 -11 902 -11 l850 0 0 -850 c0 -586 3 -865 11 -902 18 -87 58 -158 123
-223 179 -178 455 -178 631 -1 65 66 105 138 124 224 8 37 11 307 11 902 l0
850 850 0 c586 0 865 3 902 11 87 18 158 58 223 123 178 179 178 454 1 632
-66 66 -144 108 -227 124 -31 6 -390 10 -900 10 l-849 0 0 850 c0 586 -3 865
-11 902 -18 86 -58 158 -123 224 -114 114 -267 158 -421 120z"
                />
            </g>
        </svg>
    );
};

export default AddButtonSVG;

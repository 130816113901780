import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../../../../Config";
import { Temple } from "../../../../domain/models/Temple";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import religionsServiceFactory from "../../../../services/ReligionsServiceImpl";


interface TopSliderSlideProps {
    t(key?: string): string;
    temple: Temple;
}

const TopSliderSlide: FC<TopSliderSlideProps> = (props) => {
    const navigate = useNavigate();

    const religionsService = religionsServiceFactory();

    const filePath = props.temple.getSortedFiles(SortingOrder.ASC)[0].filePath;

    return (
        <div className="top__swiper-inner swiper-slide public-main-top-slide">
            <div className="top__left">
                <div className="top__container">
                    <div className="top__left-inner">
                        <h1 className="top__title" onClick={() => navigate(`/temple/${props.temple.id}`)}>{props.temple.name}</h1>
                        <div className="top__description">
                            <div className="top__item">
                                <span className="top__item-type">{props.t("user.main.popular.religion")}</span>
                                <span className="top__item-text">{props.t("religions." + religionsService.getReligion(props.temple.religionId)?.label)}</span>
                            </div>
                            <div className="top__item">
                                <span className="top__item-type">{props.t("user.main.top.current")}</span>
                                <span className="top__item-text">{props.t("religionCurrents." + religionsService.getReligionCurrent(props.temple.religionCurrentId)?.label)}</span>
                            </div>
                            <div className="top__item">
                                <span className="top__item-type">{props.t("user.main.top.place")}</span>
                                <span className="top__item-text">{props.temple.country}, {props.temple.city}</span>
                            </div>
                        </div>
                        <div className="top__image" onClick={() => navigate(`/temple/${props.temple.id}`)}>
                            {filePath && (<img src={filePath && `${apiURL}/files/${filePath}`} alt="" />)}
                        </div>
                        <div className="top__buttons">
                            <a href={`/temple/${props.temple.id}/support`} className="button">
                                {props.t("user.temple.main.supportButton")}
                            </a>
                            <a href={`/temple/${props.temple.id}/order-prayer`} className="button">
                                {props.t("user.header.orderPrayer")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top__right" onClick={() => navigate(`/temple/${props.temple.id}`)}>
                    {filePath && (<img src={filePath && `${apiURL}/files/${filePath}`} alt="" />)}
            </div>
        </div>
    );
};

export default withTranslation()(TopSliderSlide);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import { TempleChangesRequest } from "../../../domain/models/TempleChangesRequest";
import { UserParams } from "../../../enums/user-params-enum";
import UpdatesConfirmButtons from "./UpdatesConfirmButtons";

interface TempleChangesRequestsRowProps {
    t(key?: string): string;

    isSuperAdminUser?: boolean;
    request: TempleChangesRequest;

    setError: (value: string) => void;
    updateRequestStatus: () => void;
}

const TempleChangesRequestsRow: FC<TempleChangesRequestsRowProps> = (props) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const statusDate = props.request.isApproved
        ? props.t("updates.table.status.approved") + props.request.approvedAtStr
        : (
            props.request.isDeclined ?
                props.t("updates.table.status.declined") + props.request.updatedAtStr :
                props.t("updates.table.status.inReview") + props.request.updatedAtStr
        );

    const statusStyle = props.request.isApproved ? "status-complete" : (!props.request.isDeclined && "status-wait");

    const navigateToRequest = () => {
        const baseUrl = props.isSuperAdminUser ? "/admin" : "/temple-admin";
        navigate(`${baseUrl}/updates/${props.request.id}?${UserParams.UPDATED_AT}=${props.request.updatedAtStr}&${UserParams.TEMPLE_ID}=${props.request.templeId}`)
    };

    return (
        <>
            <tr>
                <td className="td-style td-padding3" onClick={() => navigateToRequest()}>
                    <span className="td-inner">{props.request.templeName}</span>
                </td>
                <td onClick={() => navigateToRequest()} className="status ">
                    <span className={statusStyle}>{statusDate}</span>
                </td>
                <td onClick={() => navigateToRequest()} className="td-style td-time td-padding7">
                    {props.request.createdAtStr}
                </td>

                {!props.request.isProcessing ? (
                    <td className="td-style td-padding8" onClick={() => navigateToRequest()}>{props.request.fullName}</td>
                ) : props.isSuperAdminUser ? (
                    <td>
                        <UpdatesConfirmButtons
                            requestId={props.request.id}
                            setError={props.setError}
                            updateRequestStatus={props.updateRequestStatus}
                        />
                    </td>
                ) : (
                    <td className="td-style td-padding8" onClick={() => navigateToRequest()}>{user?.fullName}</td>
                )}
            </tr>
        </>
    );
};

export default withTranslation()(TempleChangesRequestsRow);

import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { useFormik } from "formik";
import { getValidationClass } from "../../../tools/Tools";
import PhoneInput from 'react-phone-number-input';
import { CustomerSupportTicketSchemaFactory } from "../../../validation-schemas/public-pages/customer-support-ticket-schema";
import DropDown from "../../base/DropDown";
import { SelectProps } from "../../../domain/models/SelectProps";
import { getSupportTicketCustomerRoleDropdownOptions } from "../../../enums/support-ticket-customer-role";
import * as _ from "lodash";
import { getSupportTicketSubjectDropdownOptions } from "../../../enums/support-ticket-subject";
import CustomCheckbox from "../../base/CustomCheckbox";
import customerSupportTicketServiceFactory from "../../../services/CustomerSupportTicketServiceImpl";
import { useAuth } from "../../../context/AuthProvider";
import Popup from "reactjs-popup";
import { CustomerSupportTicket } from "../../../domain/models/CustomerSupportTicket";
import CSTSuccessModal from "./CSTSuccessModal";
import { CustomerSupportTicketDTO } from "../../../domain/models/dto/CustomerSupportTicketDTO";
import languageServiceFactory from "../../../services/LanguageServiceImpl";
import { useUpdateInputFieldBackground } from "../../../tools/custom-hooks/form-hooks/useUpdateInputFieldBackground";

interface CreateCustomerSupportTicketFormProps {
    t(key?: string, values?: object): string;
}

const CreateCustomerSupportTicketForm: FC<CreateCustomerSupportTicketFormProps> = (props) => {
    const { user } = useAuth();

    const customerSupportTicketService = customerSupportTicketServiceFactory();
    const languageService = languageServiceFactory();

    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);
    const [customerRole, setCustomerRole] = useState<SelectProps>();
    const [subjectType, setSubjectType] = useState<SelectProps>();
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [createdTicket, setCreatedTicket] = useState<CustomerSupportTicket>(null);
    const [isSendButtonDisabled, setSendButtonDisabled] = useState<boolean>(false);

    const getDefaultValues = (): CustomerSupportTicketDTO => {
        return {
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            middleName: "",
            email: user?.email || "",
            country: "",
            phoneNumber: user?.phone || "",
            customerRole: null,
            subjectType: null,
            conditionsAccepted: false,
            description: "",
        };
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: getDefaultValues(),
        validationSchema: CustomerSupportTicketSchemaFactory({ t: props.t }),
        onSubmit: async (values: CustomerSupportTicketDTO) => {
            try {
                setSendButtonDisabled(true);
                setErrorVisibility(false);
                // console.log(values);

                // TODO :: add types
                const result = await customerSupportTicketService.createCustomerSupportTicket({
                    ...values,
                    language: languageService.currentLang
                });

                if (result.id) {
                    setCreatedTicket(result);
                    setShowSuccessModal(true);
                }
                setSendButtonDisabled(false);
                // console.log(result);
            } catch {
                setErrorVisibility(true);
                setSendButtonDisabled(false);
            }
        },
    });

    useUpdateInputFieldBackground(formik, "phoneNumber");

    const customerRoleChangeHandler = (newCustomerRole: SelectProps) => {
        if (customerRole === newCustomerRole) {
            return;
        }

        formik.setFieldValue("customerRole", _.get(newCustomerRole, "value"));
        setCustomerRole(newCustomerRole);
    };

    const subjectTypeChangeHandler = (newSubjectType: SelectProps) => {
        if (subjectType === newSubjectType) {
            return;
        }

        formik.setFieldValue("subjectType", _.get(newSubjectType, "value"));
        setSubjectType(newSubjectType);
    };

    const updateConditionsAccepted = () => {
        setConditionsAcceptedValue(!formik.values.conditionsAccepted);
    };

    const setConditionsAcceptedValue = (value: boolean) => {
        formik.setFieldValue("conditionsAccepted", value);
    };

    const closeSuccessModal = () => {
        formik.resetForm();
        setShowSuccessModal(false);
        setCreatedTicket(null);
        setCustomerRole(null);
        setSubjectType(null);
        setConditionsAcceptedValue(false);
    };

    const phoneHandler = (value: string) => {
        formik.setFieldValue("phoneNumber", value, true);
    }

    return (
        /**
         * Section is added to styles to be working correctly.
         * DropDown styles depends on class "date" of one of the parents.
         * That is wrong. Each component should have independent styles.
         */
        <section className="container--main date">
            <form onSubmit={formik.handleSubmit}>
                <main className="date__main-support">
                    <div>
                        <h1 className="date__title">{props.t("admin.customerSupport.createTicketForm.header")}</h1>

                        <div className="date__forms">
                            {isErrorVisible && <p>{props.t("errors.common")}</p>}
                            <div className="cst__form-row">
                                <div className="date__form-block validated-form-field">
                                    <input
                                        className={`date__input ${getValidationClass(formik, "firstName")}`}
                                        type="text"
                                        placeholder={props.t("register.name")}
                                        name="firstName"
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                        title={props.t("register.name")}
                                    />
                                    <p className="form-error">{formik.touched.firstName && formik.errors.firstName}</p>
                                </div>
                                <div className="cst__row-spacer">&nbsp;</div>
                                <div className="date__form-block validated-form-field">
                                    <input
                                        className={`date__input ${getValidationClass(formik, "lastName")}`}
                                        type="text"
                                        placeholder={props.t("register.lastname")}
                                        name="lastName"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                        title={props.t("register.lastname")}
                                    />
                                    <p className="form-error">{formik.touched.lastName && formik.errors.lastName}</p>
                                </div>
                            </div>

                            <div className="date__form-block validated-form-field">
                                <input
                                    className={`date__input ${getValidationClass(formik, "middleName")}`}
                                    type="text"
                                    placeholder={props.t("admin.customerSupport.createTicketForm.middleName")}
                                    name="middleName"
                                    onChange={formik.handleChange}
                                    value={formik.values.middleName}
                                    title={props.t("admin.customerSupport.createTicketForm.middleName")}
                                />
                                <p className="form-error">{formik.touched.middleName && formik.errors.middleName}</p>
                            </div>

                            <div className="date__form-block validated-form-field">
                                <input
                                    className={`date__input ${getValidationClass(formik, "email")}`}
                                    type="input"
                                    placeholder={props.t("admin.customerSupport.createTicketForm.email")}
                                    name="email"
                                    autoComplete="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    title={props.t("admin.customerSupport.createTicketForm.email")}
                                />
                                <p className="form-error">{formik.touched.email && formik.errors.email}</p>
                            </div>

                            <div className="cst__form-row">
                                <div className="date__form-block validated-form-field">
                                    <input
                                        className={`date__input ${getValidationClass(formik, "country")}`}
                                        type="text"
                                        placeholder={props.t("admin.customerSupport.createTicketForm.country")}
                                        name="country"
                                        onChange={formik.handleChange}
                                        value={formik.values.country}
                                        title={props.t("admin.customerSupport.createTicketForm.country")}
                                    />
                                    <p className="form-error">{formik.touched.country && formik.errors.country}</p>
                                </div>
                                <div className="cst__row-spacer">&nbsp;</div>
                                <div className="date__form-block validated-form-field">
                                    <PhoneInput
                                        tabIndex="phoneNumber"
                                        placeholder={props.t("register.phone")}
                                        name="phoneNumber"
                                        value={formik.values.phoneNumber}
                                        onChange={phoneHandler}
                                        international={true}
                                        limitMaxLength={true}
                                        labels={languageService.getCurrentPhoneNumberLocale()}
                                    />
                                    <p className="form-error">
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    </p>
                                </div>
                            </div>

                            <div className="cst__form-row cst__form-row-mobile">
                                <div className="date__form-block validated-form-field">
                                    <DropDown
                                        name="customerRole"
                                        value={customerRole}
                                        options={getSupportTicketCustomerRoleDropdownOptions(props.t)}
                                        onChange={customerRoleChangeHandler}
                                        isError={formik.touched.customerRole && !!formik.errors.customerRole}
                                        placeholder={props.t("admin.customerSupport.createTicketForm.customerRole")}
                                        title={props.t("admin.customerSupport.createTicketForm.customerRole")}
                                    />
                                    <p className="form-error">
                                        {formik.touched.customerRole && formik.errors.customerRole}
                                    </p>
                                </div>
                                <div className="cst__row-spacer cst__row-spacer-mobile">&nbsp;</div>
                                <div className="date__form-block validated-form-field">
                                    <DropDown
                                        name="subjectType"
                                        value={subjectType}
                                        options={getSupportTicketSubjectDropdownOptions(props.t)}
                                        onChange={subjectTypeChangeHandler}
                                        isError={formik.touched.subjectType && !!formik.errors.subjectType}
                                        placeholder={props.t("admin.customerSupport.createTicketForm.subjectType")}
                                        title={props.t("admin.customerSupport.createTicketForm.subjectType")}
                                    />
                                    <p className="form-error">
                                        {formik.touched.subjectType && formik.errors.subjectType}
                                    </p>
                                </div>
                            </div>

                            <div className="date__form-block validated-form-field">
                                <CustomCheckbox
                                    id="formAgreement"
                                    name="conditionsAccepted"
                                    onChange={updateConditionsAccepted}
                                    defaultValue={false}
                                    className="checkbox__input"
                                    wrapperClassName="order-payer__checkbox cst__checkbox"
                                    value={formik.values.conditionsAccepted}
                                    labelContent={
                                        <span>
                                            {props.t("admin.customerSupport.createTicketForm.conditionsAccepted")}
                                        </span>
                                    }
                                />
                                <p className="form-error">
                                    {formik.touched.conditionsAccepted && formik.errors.conditionsAccepted}
                                </p>
                            </div>

                            <div className="date__form-block">
                                <textarea
                                    className={`date__textarea date__input  ${getValidationClass(
                                        formik,
                                        "description"
                                    )}`}
                                    name="description"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    cols={30}
                                    rows={10}
                                    placeholder={props.t("admin.customerSupport.createTicketForm.description")}
                                    title={props.t("admin.customerSupport.createTicketForm.description")}
                                ></textarea>

                                <p className="form-error">{formik.touched.description && formik.errors.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer-button-container" style={{ marginTop: "20px" }}>
                        <button className={"save-button save-button-support"} type={"submit"} disabled={isSendButtonDisabled}>
                            {props.t("admin.customerSupport.createTicketForm.sendButton")}
                        </button>
                    </div>
                    <Popup open={showSuccessModal} modal overlayStyle={{ zIndex: 999999 }} onClose={closeSuccessModal}>
                        <CSTSuccessModal closeSuccessModal={closeSuccessModal} createdTicket={createdTicket} />
                    </Popup>
                </main>
            </form>
        </section>
    );
};

export default withTranslation()(CreateCustomerSupportTicketForm);

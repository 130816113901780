import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../domain/models/Temple";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import { Translate } from "../../../types/Translate";
import ModalWindow from "../../base/ModalWindow";

interface TempleRemoveModalProps {
    t: Translate;

    removingTempleId: string;
    setRemovingTempleId(value: string): void;
    setTemples(value: Temple[]): void;

    setErrorVisibility(value: boolean): void;
}

const TempleRemoveModal: FC<TempleRemoveModalProps> = (props) => {
    const templeService = templeServiceFactory();

    const removeTemple = () => {
        const remove = async () => {
            props.setErrorVisibility(false);

            const result = await templeService.removeById(props.removingTempleId);
            if (!result) {
                return props.setErrorVisibility(true);
            }

            props.setTemples(templeService.removeFromTemples(props.removingTempleId));
        };

        props.setErrorVisibility(false);
        props.setRemovingTempleId(null);

        remove();
    };

    const cancelButton = () => {
        props.setErrorVisibility(false);
        props.setRemovingTempleId(null);
    };

    return (
        <>
            <ModalWindow
                contentClass="modal-remove__content"
                bodyClass=" "
                closeCallback={() => props.setRemovingTempleId(null)}
                content={
                    <>
                        <div className="modal__title">{props.t("templeAdmin.main.removeTemple.title")}</div>
                        <div className="modal__buttons">
                            <button className="modal__button modal__button-remove" onClick={() => removeTemple()}>
                                {props.t("templeAdmin.main.removeTemple.remove")}
                            </button>
                            <button className="modal__button modal__button-cancel" onClick={() => cancelButton()}>
                                {props.t("templeAdmin.main.removeTemple.cancel")}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
};

export default withTranslation()(TempleRemoveModal);

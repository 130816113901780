import { t } from "i18next";
import { Payout } from "../domain/models/Payout";
import { PayoutDTO } from "../domain/models/dto/PayoutDTO";
import { Currency, getCurrentCurrencyTranslation } from "../enums/currency-enum";
import { PaymentMethod, getCurrentMethodTranslation } from "../enums/payment-method-enum";
import { PayoutStatus, getCurrentStatusTranslation } from "../enums/payout-status-enum";
import { floatToStr } from "../tools/Tools";

class PayoutModel implements Payout {
    id?: string;
    templeName: string;

    templeId: string;

    transactionAmount: number;
    transactionPrayersAmount: number;
    transactionDonationsAmount: number;
    transactionCount: number;
    transactionPrayersCount: number;
    transactionDonationsCount: number;

    status: PayoutStatus;

    createdByLastName: string;
    resolvedByLastName?: string;
    createdByFirstName: string;
    resolvedByFirstName?: string;

    paymentAccountRecipientName: string;
    paymentAccountCurrency: Currency;
    paymentAccountAccountId: string;

    transactionId: string;
    paymentMethod: PaymentMethod;
    comment: string;

    createdAt?: Date;
    updatedAt?: Date;
    resolvedAt?: Date;

    transactionAmountFloatStr: string;
    transactionPrayersAmountFloatStr: string;
    transactionDonationsAmountFloatStr: string;

    constructor(data: PayoutDTO) {
        Object.assign(this, data || {});
        this.transactionAmountFloatStr = floatToStr(this.transactionAmount);
        this.transactionPrayersAmountFloatStr = floatToStr(this.transactionPrayersAmount);
        this.transactionDonationsAmountFloatStr = floatToStr(this.transactionDonationsAmount);
    }

    get createdByFullName(): string {
        return (this.createdByFirstName + " " + this.createdByLastName) || "";
    }

    get resolvedByFullName(): string {
        return (this.resolvedByFirstName + " " + this.resolvedByLastName) || "";
    }

    get inReview(): boolean {
        return this.status === PayoutStatus.IN_REVIEW;
    }

    get statusTranslations(): string {
        return getCurrentStatusTranslation(t, this.status).label;
    }

    get paymentMethodTranslations(): string {
        return getCurrentMethodTranslation(t, this.paymentMethod).label;
    }

    get paymentAccountCurrencyTranslations(): string {
        return getCurrentCurrencyTranslation(t, this.paymentAccountCurrency).label;
    }
}

export default PayoutModel;

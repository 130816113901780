import { FC, useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../tools/custom-hooks/base-hooks/useWindowDimensions";
import { sortByKeys } from "../../tools/Tools";
import { TitleTextSpec } from "../../types/Specs";

interface TitleTruncateWrapperProps {
    textSpecsArray: TitleTextSpec[];
    content: any;
    wrapperClassName: string;
    dataHover: string;
}

/*
    For proper use of this component you have to provide max screen width (key of object)
        and max height of title block (value of object).
    Those objects are considered as array, so you can mention more than one.
*/

const TitleTruncateWrapper: FC<TitleTruncateWrapperProps> = (props) => {
    const { width } = useWindowDimensions();
    const [isTruncateActive, setIsTruncateActive] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!wrapperRef.current || !width) {
            return;
        }

        const sortedTextSpecsArray = sortByKeys(props.textSpecsArray);
        const firstElement = sortedTextSpecsArray[0];
        const defaultHeight = firstElement[Object.keys(firstElement)[0]];

        const requiredHeight = sortedTextSpecsArray.reduce((res: number, spec: TitleTextSpec) => {
            const screenSize = parseInt(Object.keys(spec)[0]);

            if (screenSize < width) {
                res = spec[screenSize];
            }
            return res;
        }, defaultHeight);

        const isTextOverflowing = wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight;

        setIsTruncateActive(isTextOverflowing || requiredHeight < wrapperRef.current.clientHeight);
    }, [width, props.textSpecsArray]);

    return (
        <div className={isTruncateActive ? props.wrapperClassName : ""} data-hover={props.dataHover}>
            <div ref={wrapperRef} className={isTruncateActive ? "text-truncate" : " "}>
                {props.content}
            </div>
        </div>
    );
};

export default TitleTruncateWrapper;

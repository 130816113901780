import { FC } from "react";
import { withTranslation } from "react-i18next";
import { CustomerSupportTicket } from "../../../domain/models/CustomerSupportTicket";
import { CustomerSupportTicketFields } from "../../../enums/customer-support-ticket-fields-enum";
import { SortingColumnsHandlers } from "../../../types/SortingColumnsHandlers";
import SortingColumn from "../../base/table/SortingColumn";
import CustomerSupportTicketRow from "./CustomerSupportTicketRow";

interface CustomerSupportTicketsTableProps {
    t(key?: string): string;
    customerSupportTickets: CustomerSupportTicket[] | undefined;

    sortingColumnsHandlers: SortingColumnsHandlers;
}

const CustomerSupportTicketsTable: FC<CustomerSupportTicketsTableProps> = (props) => {
    return (
        <table id="tableSort" className="inner__table inner__table__max-content sortable-table">
            <thead>
                <tr>
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.TICKET_NAME}
                        columnTranslationName={props.t("admin.customerSupport.table.ticketName")}
                        additionalWrapperClass="td-padding2"
                        disableBaseColumnStyle={true}

                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.SUBJECT_TYPE}
                        columnTranslationName={props.t("admin.customerSupport.table.subjectType")}
                        additionalWrapperClass="td-padding2"
                        disableBaseColumnStyle={true}

                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.FULL_NAME}
                        columnTranslationName={props.t("admin.customerSupport.table.fullName")}
                        additionalWrapperClass="td-padding2"
                        disableBaseColumnStyle={true}

                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.EMAIL}
                        columnTranslationName={props.t("fields.email")}
                        additionalWrapperClass="td-padding2"
                        disableBaseColumnStyle={true}

                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.IS_EMAIL_REGISTERED}
                        columnTranslationName={props.t("admin.customerSupport.table.isEmailRegistered")}
                        disableBaseColumnStyle={true}
                        additionalWrapperClass="td-padding0"
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.CUSTOMER_ROLE}
                        columnTranslationName={props.t("admin.customerSupport.table.customerRole")}
                        additionalWrapperClass="td-padding2"
                        disableBaseColumnStyle={true}

                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.STATUS}
                        columnTranslationName={props.t("fields.status")}
                        additionalWrapperClass="td-padding2"
                        disableBaseColumnStyle={true}

                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.EMAIL_SENT}
                        columnTranslationName={props.t("admin.customerSupport.table.emailSent")}
                        disableBaseColumnStyle={true}
                        additionalWrapperClass="td-padding1"
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.USER_ID}
                        columnTranslationName={props.t("admin.customerSupport.table.isCustomerRegistered")}
                        disableBaseColumnStyle={true}
                        additionalWrapperClass="td-padding0"
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}

                        columnName={CustomerSupportTicketFields.CREATED_AT}
                        columnTranslationName={props.t("admin.customerSupport.table.createdAt")}
                        additionalWrapperClass="td-padding2"
                        disableBaseColumnStyle={true}

                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                </tr>
            </thead>

            <tbody>
                {props.customerSupportTickets?.map((customerSupportTicket, index) => (
                    <CustomerSupportTicketRow
                        key={`customer_support_ticket_${index}`}
                        customerSupportTicket={customerSupportTicket}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default withTranslation()(CustomerSupportTicketsTable);

import { ScheduleMapper } from "../domain/mappers/schedule-mapper";
import { WeekScheduleDTO } from "../domain/models/dto/WeekScheduleDTO";
import { WeekSchedule } from "../domain/models/WeekSchedule";
import { WeekScheduleModel } from "../models/WeekSchedule";
import { SimpleScheduleMapper } from "./SimpleScheduleMapper";

class WeekScheduleMapper extends SimpleScheduleMapper<WeekSchedule, WeekScheduleDTO> {
    protected entityConstructor = WeekScheduleModel;

    protected scheduleFields = [
        "mondayBegin",
        "mondayEnd",

        "tuesdayBegin",
        "tuesdayEnd",

        "wednesdayBegin",
        "wednesdayEnd",

        "thursdayBegin",
        "thursdayEnd",

        "fridayBegin",
        "fridayEnd",

        "saturdayBegin",
        "saturdayEnd",

        "sundayBegin",
        "sundayEnd",
    ];

    protected toDTOFields: string[] = ["id", "templeId", "isDeleted", ...this.scheduleFields];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        ...this.toChangesDTOFields,
        ...this.scheduleFields,
        "status",
        "createdAt",
        "updatedAt",
    ];

    fromDTO(model: WeekScheduleDTO): WeekSchedule {
        if (!model) {
            return new WeekScheduleModel();
        }

        this.dateFromDTO(model);
        const weekSchedule = super.fromDTO(model);

        return weekSchedule;
    }

    toDTO(model: WeekSchedule): WeekScheduleDTO {
        if (!model) {
            return;
        }

        const weekSchedule = super.toDTO(model);
        this.dateToDTO(weekSchedule);

        return weekSchedule;
    }
}

export default function weekScheduleMapperFactory(): ScheduleMapper<
    WeekSchedule,
    WeekScheduleDTO
> {
    return new WeekScheduleMapper();
}

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useWorshipSchedulesContext } from "../../../../../context/WorshipSchedulesProvider";
import { Translate } from "../../../../../types/Translate";
import NextArrowSVG from "../../../../base/svg/NextArrowSVG";
import PrevArrowSVG from "../../../../base/svg/PrevArrowSVG";

interface WorshipSchedulePaginationProps {
    t: Translate;
}

const WorshipSchedulePagination: FC<WorshipSchedulePaginationProps> = (props) => {
    const {
        isCurrentWeek,
        getBeginWeekDateStr,
        getEndWeekDateStr,
        setNextWeekDates,
        setPrevWeekDates,
        setCurrentWeekDates,
    } = useWorshipSchedulesContext();

    return (
        <div className="worship__schedule__pagination">
            <PrevArrowSVG
                additionalClass="worship__schedule__pagination__prev"
                onclick={setPrevWeekDates}
            />
            <div>
                <div className="worship__schedule__pagination__date">
                    {getBeginWeekDateStr()} - {getEndWeekDateStr()}
                </div>
                {!isCurrentWeek && (
                    <div
                        className="worship__schedule__pagination__back-to-current-week"
                        onClick={setCurrentWeekDates}
                    >
                        {props.t("user.temple.worships.backToCurrentWeek")}
                    </div>
                )}
            </div>
            <NextArrowSVG
                additionalClass="worship__schedule__pagination__next"
                onclick={setNextWeekDates}
            />
        </div>
    );
};

export default withTranslation()(WorshipSchedulePagination);

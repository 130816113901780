import { FC, useCallback, useRef, useState } from "react";
import { DEFAULT_OFFSET } from "../../../constants";
import { TempleChangesRequestWithCount } from "../../../domain/models/TempleChangesRequest";
import { ChangesRequestSortFields } from "../../../enums/changes-request-sort-fields-enum";
import templeChangesRequestServiceFactory from "../../../services/TempleChangesRequestServiceImpl";
import { useSortingColumns } from "../../../tools/custom-hooks/table-hooks/useSortingColumns";
import AdminPagePagination from "../AdminPagePagination";
import TempleChangesRequestsHeader from "./TempleChangesRequestsHeader";
import TempleChangesRequestsList from "./TempleChangesRequestsList";

interface TempleChangesRequestsTabProps {
    isSuperAdminUser?: boolean;
}

const TempleChangesRequestsTab: FC<TempleChangesRequestsTabProps> = (props) => {
    const [data, setData] = useState<TempleChangesRequestWithCount>(null);
    const [error, setError] = useState<string>(null);
    const [reloadCache, setReloadCache] = useState<boolean>(false);
    const dataTableRef = useRef<HTMLDivElement>(null);
    const columnsHandlers = useSortingColumns();

    const total = props.isSuperAdminUser ? data?.inReviewCount || 0 : data?.totalRows || 0;

    const paginationCallback = useCallback(
        async (currentPage: number, pageSize: number, changeContent: boolean) =>
            templeChangesRequestServiceFactory().getCachedTempleChangesRequests(
                {
                    page: currentPage,
                    limit: pageSize,
                    offset: DEFAULT_OFFSET,
                    sort: columnsHandlers.currentActiveColumn as ChangesRequestSortFields,
                    sortingOrder: columnsHandlers.sortingOrder,
                },
                changeContent || columnsHandlers.reloadCache || reloadCache
            ),
        [columnsHandlers.currentActiveColumn, columnsHandlers.reloadCache, columnsHandlers.sortingOrder, reloadCache]
    );

    return (
        <section className="inner">
            <TempleChangesRequestsHeader isSuperAdminUser={props.isSuperAdminUser} total={total} error={error} />

            <div ref={dataTableRef} className="inner__content23">
                <TempleChangesRequestsList
                    isSuperAdminUser={props.isSuperAdminUser}
                    requests={data?.rows}
                    setError={setError}
                    updateRequestStatus={() => setReloadCache(true)}
                    sortingColumnsHandlers={columnsHandlers}
                />
            </div>

            <div className="church__pagination__admin__block">
                <AdminPagePagination
                    additionalPaginationClass="search__data__pagination"
                    pagesCount={data?.totalPages}
                    wrapperRef={dataTableRef}
                    data={data}
                    reloadCache={columnsHandlers.reloadCache || reloadCache}
                    setData={setData}
                    setReloadCache={(value: boolean) => {
                        columnsHandlers.setReloadCache(value);
                        setReloadCache(value);
                    }}
                    serviceCallback={paginationCallback}
                />
            </div>
        </section>
    );
};

export default TempleChangesRequestsTab;

import { SelectProps } from "../domain/models/SelectProps";
import { getEnumTranslatedDropDownOptions } from "../tools/enum-tools";
import { Translate } from "../types/Translate";

export enum SupportTicketSubject {
    REGISTRATION = 1,
    ORDER_PRAYER = 2,
    SUPPORT_TEMPLE = 3,
    PAYMENTS = 4,
    OFFER = 5,
    TROUBLESHOOT = 6,
    COLLABORATION = 7,
    ADVERTIZING = 8,
    OTHER = 9,
}

const translatePrefix = "enums.supportTicketSubject.";

export function getSupportTicketSubjectTranslation(
    t: Translate,
    requestCustomerRole: SupportTicketSubject
): SelectProps {
    return {
        value: requestCustomerRole?.toString(),
        label: t(
            requestCustomerRole && `${translatePrefix}${requestCustomerRole}`
        ),
    };
}

export function getSupportTicketSubjectDropdownOptions(
    t: Translate
): SelectProps[] {
    return getEnumTranslatedDropDownOptions(
        SupportTicketSubject,
        translatePrefix,
        t
    );
}

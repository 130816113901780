import { FC } from "react";
import { Prayer } from "../../../../domain/models/Prayer";
import OrderPrayerAccordionItem from "./OrderPrayerAccordionItem";

interface OrderPrayerAccordionItemsProps {
    prayers: Prayer[];

    setSelectedPrayer(value: Prayer): void;
}

const OrderPrayerAccordionItems: FC<OrderPrayerAccordionItemsProps> = (props) => {
    const changePrayer = (prayer: Prayer) => {
        window.scrollTo(0, 0);
        props.setSelectedPrayer(prayer);
    };

    return (
        <div className="order-payer__accordion-items">
            {props.prayers.map((prayer) => (
                <div key={prayer.id} onClick={() => changePrayer(prayer)}>
                    <OrderPrayerAccordionItem  prayer={prayer} />
                </div>
            ))}
        </div>
    );
};

export default OrderPrayerAccordionItems;

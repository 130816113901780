import { Payout, PayoutsCollection } from "../domain/models/Payout";
import { PayoutDTO, NonValidPayoutDTO } from "../domain/models/dto/PayoutDTO";
import { PayoutService } from "../domain/services/PayoutService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { PaymentMethod } from "../enums/payment-method-enum";
import { PayoutStatus } from "../enums/payout-status-enum";
import { PayoutsSortFields } from "../enums/payouts-sort-fields-enum";
import payoutMapperFactory from "../mappers/PayoutMapper";
import { get, patch, post, sendFile } from "../tools/Tools";
import { EntityResolvingResponse, EntityResolvingByCSVResponse } from "../types/EntityResolvingResponse";
import { PaginationParams } from "../types/PaginationParams";
import { BaseServiceImpl } from "./BaseServiceImpl";

export class PayoutServiceImpl
    extends BaseServiceImpl
    implements PayoutService
{
    async createPayout(templeStatisticId: string, paymentAccountId?: string, paymentIds?: string[]): Promise<Payout> {
        try {
            const payoutMapper = payoutMapperFactory();

            const url = this.getBaseEntityUrl(EntitiesBaseUrls.PAYOUT);
            const data: any = { templeStatisticId };
            if (paymentAccountId) {
                data.paymentAccountId = paymentAccountId;
            }

            if (paymentIds?.length) {
                data.ids = paymentIds;
            }

            const result = await post(url, data);

            return payoutMapper.fromDTO(this.getData<PayoutDTO>(result));
        } catch (err) {
            console.log("PayoutServiceImpl.createPayout => ERROR:");
            console.log(err);
        }
    }

    async getAllPayouts(
        params: PaginationParams<PayoutsSortFields>,
        paymentMethod?: PaymentMethod,
    ): Promise<PayoutsCollection> {
        try {
            const payoutMapper = payoutMapperFactory();

            let url = this.getBaseEntityUrl(EntitiesBaseUrls.PAYOUT).concat(
                `/all?${this.getPaginationParamsUrl<PayoutsSortFields>(
                    params,
                    PayoutsSortFields.TEMPLE_NAME
                )}`
            );

            if (paymentMethod) {
                url += `&paymentMethod=${paymentMethod}`;
            }

            const result = await get(url);

            return {
                totalPages: result.totalPages || 0,
                totalRows: result.totalRows || 0,
                rows:
                    this.getData<PayoutDTO[]>(result)?.map((dto) =>
                        payoutMapper.fromDTO(dto)
                    ) || [],
            };
        } catch (err) {
            console.log("PayoutServiceImpl.getAllPayouts => ERROR:");
            console.log(err);
        }
    }

    getEmptyPayoutCollection(): PayoutsCollection {
        return this.getEmptyCollection();
    }

    getEmptyPayoutModel(): Payout {
        return payoutMapperFactory().fromDTO(null);
    }

    async getPayoutById(payoutId: string): Promise<Payout> {
        try {
            const payoutMapper = payoutMapperFactory();

            const url = this.getBaseEntityUrl(EntitiesBaseUrls.PAYOUT).concat(
                `/${payoutId}`
            );
            const result = await get(url);

            return payoutMapper.fromDTO(this.getData<PayoutDTO>(result));
        } catch (err) {
            console.log("PayoutServiceImpl.getPayoutById => ERROR:");
            console.log(err);
        }
    }

    async resolvePayout(
        id: string,
        status: PayoutStatus,
        comment: string,
        transactionId?: string,
    ): Promise<EntityResolvingResponse> {
        try {
            const url = this.getBaseEntityUrl(EntitiesBaseUrls.PAYOUT).concat(`/${id}/resolve`);
            const data: any = {
                status, comment
            };
            if (transactionId) {
                data.transactionId = transactionId;
            }
            const result = await patch(url, data);

            return this.getData<EntityResolvingResponse>(result);
        } catch (err) {
            console.log("PayoutServiceImpl.resolvePayout => ERROR:");
            console.log(err);
        }
    }

    getPayoutsToResolve(payouts: Payout[]): Payout[] {
        return payouts.filter((payout) => payout.inReview);
    }

    async resolvePayoutsByCSV(csv: File, forced: boolean = false): Promise<EntityResolvingByCSVResponse<NonValidPayoutDTO>> {
        try {
            let url = this.getBaseEntityUrl(EntitiesBaseUrls.PAYOUT).concat(`/csv/resolve`);
            if (forced) {
                url += `&forced=${forced}`;
            }
            const result = await sendFile(csv, url);
            return this.getData<EntityResolvingByCSVResponse<NonValidPayoutDTO>>(result);
        } catch (err) {
            console.log("PayoutServiceImpl.resolvePayoutsByCSV => ERROR:");
            console.log(err);
        }
    }
}

export function payoutServiceFactory(): PayoutService {
    return new PayoutServiceImpl();
}

import * as Yup from "yup"
import { email, link, youtubeComplexRegex } from "../../constants";
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";
import { isValidPhoneNumber } from "react-phone-number-input";



export const GallerySchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        worshipLink: {
            min: 2,
            max: 200
        },
        tourLink: {
            min: 2,
            max: 500
        },
        contactCountry: {
            min: 2,
            max: 200
        },
        contactCity: {
            min: 2,
            max: 200
        },
        contactStreet: {
            min: 2,
            max: 200
        },
        contactBuildingNumber: {
            min: 1,
            max: 999999
        },
        contactPhone: {
            min: 13,
            max: 13
        },
        contactEmail: {
            min: 2,
            max: 200
        },
        contactInfo: {
            min: 5,
            max: 5000
        },
    };

    return {
        schema: Yup.object().shape({
            worshipLink: Yup.string()
                .matches(youtubeComplexRegex, t("errors.commonErrors.isNotCorrectLink"))
                .min(inputRestrictions.worshipLink.min, t("errors.commonErrors.min") + inputRestrictions.worshipLink.min)
                .max(inputRestrictions.worshipLink.max, t("errors.commonErrors.max") + inputRestrictions.worshipLink.max)
                .nullable(),
            tourLink: Yup.string()
                .matches(link, t("errors.commonErrors.isNotCorrectLink"))
                .min(inputRestrictions.tourLink.min, t("errors.commonErrors.min") + inputRestrictions.tourLink.min)
                .max(inputRestrictions.tourLink.max, t("errors.commonErrors.max") + inputRestrictions.tourLink.max)
                .nullable(),
            contactCountry: Yup.string()
                .min(inputRestrictions.contactCountry.min, t("errors.commonErrors.min") + inputRestrictions.contactCountry.min)
                .max(inputRestrictions.contactCountry.max, t("errors.commonErrors.max") + inputRestrictions.contactCountry.max)
                .required(t("errors.commonErrors.required")),
            contactCity: Yup.string()
                .min(inputRestrictions.contactCity.min, t("errors.commonErrors.min") + inputRestrictions.contactCity.min)
                .max(inputRestrictions.contactCity.max, t("errors.commonErrors.max") + inputRestrictions.contactCity.max)
                .required(t("errors.commonErrors.required")),
            contactStreet: Yup.string()
                .min(inputRestrictions.contactStreet.min, t("errors.commonErrors.min") + inputRestrictions.contactStreet.min)
                .max(inputRestrictions.contactStreet.max, t("errors.commonErrors.max") + inputRestrictions.contactStreet.max)
                .required(t("errors.commonErrors.required")),
            contactBuildingNumber: Yup.number()
                .min(inputRestrictions.contactBuildingNumber.min, t("errors.commonErrors.min") + inputRestrictions.contactBuildingNumber.min)
                .max(inputRestrictions.contactBuildingNumber.max, t("errors.commonErrors.max") + inputRestrictions.contactBuildingNumber.max)
                .typeError(t("errors.commonErrors.isNumber"))
                .required(t("errors.commonErrors.required")),
            contactPhone: Yup.string()
                .required(t("errors.commonErrors.required"))
                .test(
                    "contactPhone",
                    t("errors.commonErrors.invalidPhoneNumber"),
                    function (value) {
                        return value && isValidPhoneNumber(value);
                    }
                ),
            contactSecondPhone: Yup.string()
                .test(
                    "contactSecondPhone",
                    t("errors.commonErrors.invalidPhoneNumber"),
                    function (value) {
                        return value ? isValidPhoneNumber(value) : true;
                    }
                ),
            contactEmail: Yup.string()
                .matches(email, t("errors.commonErrors.isNotCorrectEmail"))
                .min(inputRestrictions.contactEmail.min, t("errors.commonErrors.min") + inputRestrictions.contactEmail.min)
                .max(inputRestrictions.contactEmail.max, t("errors.commonErrors.max") + inputRestrictions.contactEmail.max)
                .required(t("errors.commonErrors.required")),
            contactInfo: Yup.string()
                .min(inputRestrictions.contactInfo.min, t("errors.commonErrors.min") + inputRestrictions.contactInfo.min)
                .max(inputRestrictions.contactInfo.max, t("errors.commonErrors.max") + inputRestrictions.contactInfo.max)
                .nullable()
        }), inputRestrictions
    };
}

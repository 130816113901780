import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";

export const ArtifactSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        name: {
            min: 5,
            max: 200
        },
        info: {
            min: 5,
            max: 5000
        }
    }

    return {
        schema: Yup.object().shape({
            id: Yup.string(),
            name: Yup.string()
                .min(inputRestrictions.name.min, t("errors.commonErrors.min") + inputRestrictions.name.min)
                .max(inputRestrictions.name.max, t("errors.commonErrors.max") + inputRestrictions.name.max)
                .required(t("errors.commonErrors.required")),
            info: Yup.string()
                .min(inputRestrictions.info.min, t("errors.commonErrors.min") + inputRestrictions.info.min)
                .max(inputRestrictions.info.max, t("errors.commonErrors.max") + inputRestrictions.info.max)
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

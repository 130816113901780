import { FC } from "react";
import { withTranslation } from "react-i18next";
import { SearchEntity as ISearchEntity } from "../../../domain/models/SearchData";
import { getEntityFieldNameTranslation } from "../../../tools/Tools";
import { Translate } from "../../../types/Translate";
import TextTruncateWrapper from "../../base/TextTruncateWrapper";

interface SearchEntityProps {
    t: Translate;
    entity: ISearchEntity;

    navigateToTemple: () => void;
}

const SearchEntity: FC<SearchEntityProps> = (props) => {
    return (
        <>
            <h3 className="search__entity__data__title" onClick={props.navigateToTemple}>
                {props.t(`entity.${props.entity.entityType}`)}: {props.entity.entityName}
            </h3>
            {props.entity.entityDetails.map(
                (details, index) =>
                    details.fieldToVisibility && (
                        <div
                            key={"search-field" + index}
                            className="search__entity__data"
                            onClick={props.navigateToTemple}
                        >
                            {getEntityFieldNameTranslation(props.t, details.fieldName)}:{" "}
                            <TextTruncateWrapper text={details.value} className="" height={8} />
                        </div>
                    )
            )}
        </>
    );
};

export default withTranslation()(SearchEntity);

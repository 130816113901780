import { FC, useState } from "react";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";
import { Payout } from "../../../domain/models/Payout";
import { useAuth } from "../../../context/AuthProvider";
import PayoutResolveFormModal from "../../admin/payouts/PayoutResolveFormModal";
import PayoutResolveSVG from "../svg/PayoutResolveSVG";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../CustomCheckbox";

interface PayoutRowProps {
    t: Translate;
    payout: Payout;
    navigateToPayoutDetails: () => void;
    reloadCache: () => void;
    selectRowCallback: () => void;
    isSelectedRow: boolean;
}

const PayoutRow: FC<PayoutRowProps> = (props) => {
    const [isPayoutResolveFormModalActive, setPayoutResolveFormModalActive] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();

    const navigateToTemplePayments = () => {
        navigate(`/admin/temples/${props.payout.templeId}/payments`);
    };

    const getRowClassName = () => {
        return !props.payout.inReview
            ? "payouts-row__disabled"
            : "";
    };

    return (
        <tr className={getRowClassName()}>
            {user.isAdmin ? (
                <>
                    <td className="td-style--min td-padding1-75 td-padding1-75__short date">
                        <CustomCheckbox
                            id={props.payout.id}
                            name={"selectedRowButton" + props.payout.id}
                            defaultValue={false}
                            value={props.isSelectedRow}
                            className="checkbox__input"
                            additionalLabelClassName="date__text"
                            wrapperClassName="order__checkbox simple-checkbox"
                            onChange={props.payout.inReview ? props.selectRowCallback : null}
                        />
                    </td>
                    <td className="td-style--min td-padding1 td-padding1-75__short">
                        <div onClick={() => setPayoutResolveFormModalActive(props.payout.inReview)} className="payout-button">
                            <PayoutResolveSVG />
                        </div>
                    </td>
                </>
            ) : (
                ""
            )}
            <td
                onClick={user.isAdmin ? navigateToTemplePayments : props.navigateToPayoutDetails}
                className="td-style--min"
            >
                <span className={`td-inner--min2 ${user.isAdmin ? "" : "td-inner--min2__padded"}`}>
                    {props.payout.templeName}
                </span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.transactionAmountFloatStr}$</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.transactionPrayersAmountFloatStr}$</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.transactionDonationsAmountFloatStr}$</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.t(`payoutStatus.${props.payout.status}`)}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.transactionCount}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.transactionPrayersCount}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.transactionDonationsCount}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.createdAt}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.createdByFullName}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.updatedAt}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.resolvedByFullName}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.payout.transactionId}</span>
            </td>
            <td onClick={props.navigateToPayoutDetails} className="td-style--min">
                <span className="td-inner--min3">{props.t(`paymentMethod.${props.payout.paymentMethod}`)}</span>
            </td>
            <PayoutResolveFormModal
                isPayoutResolveFormModalActive={isPayoutResolveFormModalActive}
                closeModalCallback={() => setPayoutResolveFormModalActive(false)}
                payout={props.payout}
                reloadCache={props.reloadCache}
            />
        </tr>
    );
};

export default withTranslation()(PayoutRow);

import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";
import { PrayerRequirementDTO } from "../domain/models/dto/PrayerRequirementDTO";
import { PrayerRequirement } from "../domain/models/PrayerRequirement";
import PrayerRequirementModel from "../models/PrayerRequirementModel";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";

class PrayerRequirementMapper extends SimpleChangesRequestEntityMapper<PrayerRequirement, PrayerRequirementDTO> {
    protected entityConstructor = PrayerRequirementModel;

    protected toDTOFields: string[] = [
        "id",
        "templeId",
        "info",
        "order",
        "isDeleted"
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [...this.toDTOFields, ...this.toChangesDTOFields, "status", "createdAt", "updatedAt"];
}


export default function prayerRequirementMapperFactory(): ChangesRequestEntityMapper<PrayerRequirement, PrayerRequirementDTO> {
    return new PrayerRequirementMapper()
}

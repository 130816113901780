import { WeekScheduleDTO } from "../domain/models/dto/WeekScheduleDTO";
import { WeekSchedule } from "../domain/models/WeekSchedule";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";

export class WeekScheduleModel extends BaseRelatedEntityMixinFactory<WeekScheduleDTO>() implements WeekSchedule {
    templeId: string;

    mondayBegin: string;
    mondayEnd: string;

    tuesdayBegin: string;
    tuesdayEnd: string;

    wednesdayBegin: string;
    wednesdayEnd: string;

    thursdayBegin: string;
    thursdayEnd: string;

    fridayBegin: string;
    fridayEnd: string;

    saturdayBegin: string;
    saturdayEnd: string;

    sundayBegin: string;
    sundayEnd: string;


    constructor(data?: WeekScheduleDTO) {
        super();

        this.assignData(data);
    }
}

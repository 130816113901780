import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { apiURL } from "../../../Config";
import { Temple } from "../../../domain/models/Temple";
import { Page } from "../../../enums/page-enum";
import { PublicParams } from "../../../enums/public-params-enum";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import { useRedirectToTempleNextPage } from "../../../tools/custom-hooks/useRedirect";
import NoPhotoSVG from "../../base/svg/NoPhotoSVG";
import TitleTruncateWrapper from "../../base/TitleTruncateWrapper";

interface RowsListProps {
    t(key?: string): string;
    temple: Temple;
}

const FoundTempleRow: FC<RowsListProps> = (props) => {
    const religionService = religionsServiceFactory();

    const filePath = props.temple.getSortedFiles(SortingOrder.ASC)[0].filePath;

    const redirect = useSearchParams()[0].get(PublicParams.REDIRECT) as Page;

    const redirectToNextPage = useRedirectToTempleNextPage(props.temple.id);

    return (
        <div className="church__row-item church__row-bottom">
            <a onClick={() => redirectToNextPage(redirect, false)}>
                <div className="church__row-img">
                    {filePath ? (
                        <img
                            className="church__row__temple__img"
                            src={filePath && `${apiURL}/files/${filePath}`}
                            alt=""
                        />
                    ) : (
                        <NoPhotoSVG />
                    )}
                </div>
                <div className="church__row-descriptions">
                    <div className="church__row-title__block">
                        <TitleTruncateWrapper
                            content={<h3 className="church__row-title">{props.temple.name}</h3>}
                            dataHover={props.temple.name}
                            wrapperClassName="hover-text-title"
                            textSpecsArray={[{ 1200: 58 }, { 99999: 76 }]}
                        />
                    </div>
                    <div className="church__row-text-block">
                        <div className="church__row-types">
                            <div className="church__row-type">{props.t("user.religions.base.religion")}:</div>
                            <div className="church__row-text">
                                {props.t("religions." + religionService.getReligion(props.temple.religionId)?.label)}
                            </div>
                        </div>
                        <div className="church__row-types">
                            <div className="church__row-type">{props.t("user.religions.base.current")}:</div>
                            <div className="church__row-text">
                                {props.t(
                                    "religionCurrents." +
                                        religionService.getReligionCurrent(props.temple.religionCurrentId)?.label
                                )}
                            </div>
                        </div>
                        <div className="church__row-types">
                            <div className="church__row-type">{props.t("user.religions.base.subspecies")}:</div>
                            <div className="church__row-text">
                                {props.t(
                                    "religionSubspecies." +
                                        religionService.getReligionCurrentSubspecies(props.temple.religionSubspeciesId)
                                            ?.label
                                )}
                            </div>
                        </div>
                        <div className="church__row-types">
                            <div className="church__row-type">{props.t("user.religions.row.location")}</div>
                            <div className="church__row-text">
                                {props.temple.country}, {props.temple.city}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default withTranslation()(FoundTempleRow);

import { BaseService } from "../domain/services/BaseService";
import * as _ from 'lodash';
import { PaginationParams } from "../types/PaginationParams";
import { ResultWithCount } from "../domain/models/base-result";
import { RemoveResponse } from "../types/RemoveResponse";
import { deleteRequest, get, post } from "../tools/Tools";
import { Mapper } from "../domain/mappers/mapper";
import { BaseEntityDTO } from "../domain/models/dto/BaseEntityDTO";
import { apiURL } from "../Config";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { BaseEntity } from "../domain/models/BaseEntity";
import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";
import { errorServiceFactory } from "./ErrorServiceImpl";
import { StatusCodes } from "http-status-codes";
import { ChartsParams } from "../types/charts/ChartsParams";
import { SortingOrder } from "../enums/sorting-order-enum";

export class BaseServiceImpl implements BaseService {
    private static BASE_API_URL = `${apiURL}/api/v1`;
    private static V2_API_URL = `${apiURL}/api/v2`;

    getData<T>(obj: any, defaultValue?: T): T {
        return _.get(obj, 'data', defaultValue);
    }

    protected getBaseEntityUrl(entityUrl: EntitiesBaseUrls): string {
        return BaseServiceImpl.BASE_API_URL.concat(`/${entityUrl}`);
    }

    protected getV2EntityUrl(entityUrl: EntitiesBaseUrls): string {
        return BaseServiceImpl.V2_API_URL.concat(`/${entityUrl}`);
    }

    protected getChartsUrl(entityUrl: EntitiesBaseUrls): string {
        return BaseServiceImpl.BASE_API_URL.concat(`/${entityUrl}/charts`);
    }

    protected getPaginationParamsUrl<SortingFields>(params: PaginationParams<SortingFields>, defaultSortValue: string) {
        return `page=${params.page}&limit=${params.limit}&offset=${
            params.offset
        }&sort=${
            params.sort || defaultSortValue
        }&sortingOrder=${params.sortingOrder || SortingOrder.ASC}`;
    }

    protected convertToRemoveResponse<EntityDTO extends BaseEntityDTO>(data: any): RemoveResponse {
        return {
            status: data.status || !!this.getData<EntityDTO>(data)?.id
        };
    }

    protected async getPaginationData<Model extends ResultWithCount, SortingFields>(
        entities: Model,
        getEntities: (params: PaginationParams<SortingFields>) => Promise<Model>,
        params: PaginationParams<SortingFields>,
        reloadCache: boolean=false,
        updateData: boolean=false,
    ): Promise<Model> {
        const totalPages = entities.totalPages;
        const isEmptyRows = _.isEmpty(entities.rows);
        const isMaxRows = entities.rows.length === entities.totalRows;

        if (isEmptyRows || updateData || (reloadCache && !isMaxRows && ((totalPages >= params.page) || isEmptyRows))) {
            const data = await getEntities(
                {
                    page: params.page,
                    limit: params.limit,
                    offset: params.offset,
                    sort: params.sort,
                    sortingOrder: params.sortingOrder
                }
            );

            if (updateData) {
                entities = data;
            } else {
                entities = {
                    ...data,
                    rows: entities.rows.concat(data.rows)
                }
            }

        }

        return entities;
    }

    protected async removeEntityById<EntityDTO, Model extends BaseEntity>(
        model: Model,
        baseEntityUrl: string,
        mapperFactory: () => ChangesRequestEntityMapper<Model, EntityDTO>
    ): Promise<RemoveResponse> {
        try {
            const mapper = mapperFactory();
            errorServiceFactory().subscribeOnError(StatusCodes.CONFLICT);
            return this.convertToRemoveResponse(
                await deleteRequest(baseEntityUrl, mapper.toDTO(model)),
            );
        } catch (err) {
            console.log("BaseServiceImpl.removeEntityById => ERROR:");
            console.log(err);
        }
    }

    protected async updateEntityOrCreateChangeRequest<EntityDTO, Model>(
        entityDto: EntityDTO,
        url: string,
        mapperFactory: () => Mapper<Model, EntityDTO>,
    ): Promise<Model> {
        try {
            const mapper = mapperFactory();
            return mapper.fromDTO(this.getData<EntityDTO>(await post(url, entityDto)));
        } catch (err) {
            console.log("BaseServiceImpl.updateEntityOrCreateChangeRequest => ERROR:");
            console.log(err);
        }
    }

    protected async getEntitiesCharts(
        baseUrl: EntitiesBaseUrls,
        params: ChartsParams,
        field: string,
        additionalParams: Object = null,
    ): Promise<number[]> {
        try {
            if (!params.year) {
                return null;
            }

            let url = this.getChartsUrl(baseUrl).concat(`?year=${params.year}`);
            if (!_.isNil(params.month)) {
                url += `&month=${params.month}`;
            }
            if (params.day) {
                url += `&day=${params.day}`;
            }
            if (field) {
                url += `&field=${field}`;
            }
            if (additionalParams) {
                for (const [key, value] of Object.entries(additionalParams)) {
                    if (!value) {
                        continue;
                    }
                    url += `&${key}=${value}`;
                }
            }

            return this.getData(await get(url));
        } catch (err) {
            console.log("BaseServiceImpl.getEntitiesCharts => ERROR:");
            console.log(err);
        }
    }

    protected getEmptyCollection (): ResultWithCount {
        return {
            totalPages: 0,
            totalRows: 0,
            rows: [],
        }
    }
}

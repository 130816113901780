import { FC, useState } from "react";
import { Article } from "../../../../../domain/models/Article";
import ArticleSlide from "./ArticleSlide";
import PrevArrowSVG from "../../../../base/svg/PrevArrowSVG";
import NextArrowSVG from "../../../../base/svg/NextArrowSVG";
import PhotoSlider from "../../../../base/photo-slider/PhotoSlider";
import { Swiper } from "swiper/types";

interface BaseInfoElementsProps {
    articles: Article[];
    isActive: boolean;
}

const BaseInfoElements: FC<BaseInfoElementsProps> = (props) => {
    const [swiper, setSwiper] = useState<Swiper>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    const isOnlySlide = currentSlide === 0 && currentSlide === props.articles.length - 1;
    const isEmptyArticle = !props.articles.length;

    const prevSlide = () => {
        swiper?.slidePrev()
        setCurrentSlide(swiper.activeIndex)
    }
    const nextSlide = () => {
        swiper?.slideNext()
        setCurrentSlide(swiper.activeIndex)
    }

    const minimumPhotosForLoop = 2;

    return (
        <div className="information__articles-article information__article-block">
            <div className={(props.isActive && !isOnlySlide) && !isEmptyArticle ? "information__prev-arrow-button" : "church__items"}>
                <PrevArrowSVG
                    onclick={prevSlide}
                />
            </div>
            <PhotoSlider
                hideSwiperArrows={true}
                isArrowsDisable={true}
                allowTouchMove={false}
                slidesPerView={1}
                setCurrentSwiper={setSwiper}
                disableAutoplay={true}
                loop={props.articles.length >= minimumPhotosForLoop}

                content={props.articles.map(article => <ArticleSlide article={article} />)}
            />
            <div className={(props.isActive && !isOnlySlide) && !isEmptyArticle ? "information__next-arrow-button" : "church__items"}>
                <NextArrowSVG
                    onclick={nextSlide}
                />
            </div>
        </div>
    );
};

export default BaseInfoElements;

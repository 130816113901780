import _ from "lodash";
import { environment } from "./environments/environment";

export const apiURL = environment.API_URL;
export const pageSize = _.get(environment, 'PAGE_SIZE', 15);

export const maxFilesSizeKb = +_.get(environment, 'MAX_FILE_SIZE_KB', 5);
export const maxFileSizeBytes = maxFilesSizeKb * 1024;

export const maxFilesGroupSize = +_.get(environment, 'MAX_FILES_GROUP_SIZE', 10);
export const maxGalleriesFilesGroupSize = +_.get(environment, 'MAX_GALLERIES_FILES_GROUP_SIZE', 20);

export const maxTemplesCount = +_.get(environment, 'MAX_TEMPLES_COUNT', 1);

export const paypalClientId = _.get(environment, 'PAYPAL_CLIENT_ID', "");

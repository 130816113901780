import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { DEFAULT_OFFSET, TEMPLE_PAGE_SIZE } from "../../../constants";
import { TempleWithCount } from "../../../domain/models/Temple";
import { Page } from "../../../enums/page-enum";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import { TempleSortFields } from "../../../enums/temple-sort-fields-enum";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import { useRedirectToTempleNextPage } from "../../../tools/custom-hooks/useRedirect";
import PopularTemples from "./popular-temples/PopularTemples";
import TopSlider from "./top-slider/TopSlider";

interface PublicPageMainProps {
    t(key?: string): string;
}

const PublicPageMain: FC<PublicPageMainProps> = (props) => {
    const [isDataExist, setIsDataExist] = useState<boolean>(false);
    const [temples, setTemples] = useState<TempleWithCount>();

    const redirectToTempleNextPage = useRedirectToTempleNextPage(null);

    useEffect(() => {
        const templeService = templeServiceFactory();
        const religionsService = religionsServiceFactory();

        const uploadData = async () => {
            const [temples, religions] = await Promise.all([
                await templeService.getCachedTemples(
                    1,
                    TEMPLE_PAGE_SIZE,
                    DEFAULT_OFFSET,
                    TempleSortFields.NAME,
                    SortingOrder.ASC,
                    [], false, false, true, false
                ),
                religionsService.getCachedReligions(),
            ]);

            setTemples(temples);
            setIsDataExist(!!religions.length && !!temples.rows.length);
        };

        if (!isDataExist) {
            uploadData();
        }
    }, [temples, isDataExist]);

    return (
        <>
            {!!temples?.rows && isDataExist && <TopSlider  temples={temples.rows} />}
            <section className="holy--main">
                <div className="container--main">
                    <h2 className="holy--main__title title">{props.t("user.main.holy.title")}</h2>
                    <div className="holy--main__item">
                        <div className="holy--main__item-left ">
                            <h2 className="holy--main__item-subtitle subtitle">{props.t("user.main.holy.subtitle1")}</h2>
                            <div className="holy--main__item-text holy--main__item-church">
                                <p className="holy--main__item-par text">
                                    {props.t("user.main.holy.text1")}
                                </p>
                                <p className="holy--main__item-par text">
                                    {props.t("user.main.holy.text2")}
                                </p>
                            </div>
                            <div className="button-wrap" onClick={() => redirectToTempleNextPage(Page.SUPPORT)}>
                                <a href="#" className="button" >
                                    {props.t("user.footer.offering")}
                                </a>
                            </div>
                        </div>
                        <div className="holy--main__item-right">
                            <h2 className="holy--main__item-subtitle subtitle">{props.t("user.main.holy.subtitle1")}</h2>
                            <div className="holy--main__item-image">
                                <picture>
                                    <source srcSet="img/holy1.webp" type="image/webp" />
                                    <img src="img/holy1.png" alt="" />
                                </picture>
                            </div>
                        </div>
                    </div>
                    <div className="holy--main__item">
                        <div className="holy--main__item-left">
                            <h2 className="holy--main__item-subtitle subtitle">{props.t("user.header.orderPrayer")}</h2>
                            <div className="holy--main__item-text holy--main__item-book">
                                <p className="holy--main__item-par text">
                                    {props.t("user.main.holy.text3")}
                                </p>
                                <p className="holy--main__item-par text">
                                {props.t("user.main.holy.text4")}
                                </p>
                            </div>
                            <div className="button-wrap" onClick={() => redirectToTempleNextPage(Page.ORDER_PRAYER)}>
                                <a href="#" className="button">
                                    {props.t("user.header.orderPrayer")}
                                </a>
                            </div>
                        </div>
                        <div className="holy--main__item-right">
                            <h2 className="holy--main__item-subtitle subtitle">{props.t("user.header.orderPrayer")}</h2>
                            <div className="holy--main__item-image">
                                <picture>
                                    <source srcSet="img/holy2.webp" type="image/webp" />
                                    <img src="img/holy2.png" alt="" />
                                </picture>
                            </div>
                        </div>
                    </div>
                    <div className="holy--main__item">
                        <div className="holy--main__item-left ">
                            <h2 className="holy--main__item-subtitle subtitle">{props.t("user.main.holy.subtitle2")}</h2>
                            <div className="holy--main__item-text holy--main__item-person">
                                <p className="holy--main__item-par text">
                                {props.t("user.main.holy.text5")}
                                </p>
                                <p className="holy--main__item-par text">
                                    {props.t("user.main.holy.text6")}
                                </p>
                            </div>
                            <div className="button-wrap">
                                <a href="#" className="button ">
                                    {props.t("user.main.holy.subtitle2")}
                                </a>
                            </div>
                        </div>
                        <div className="holy--main__item-right">
                            <h2 className="holy--main__item-subtitle subtitle">{props.t("user.main.holy.subtitle2")}</h2>
                            <div className="holy--main__item-image">
                                <picture>
                                    <source srcSet="img/holy3.webp" type="image/webp" />
                                    <img src="img/holy3.png" alt="" />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="order">
                <div className="container--main">
                    <h2 className="title order__title">{props.t("user.header.orderPrayer")}{props.t("user.main.order.clickLabel")}</h2>
                    <div className="order__items">
                        <div className="order__item">
                            <div className="order__item-num">1</div>
                            <p className="order__item-par">{props.t("user.main.order.selectReligion")}</p>
                        </div>
                        <div className="order__item">
                            <div className="order__item-num">2</div>
                            <p className="order__item-par">{props.t("user.main.order.selectTemple")}</p>
                        </div>
                        <div className="order__item">
                            <div className="order__item-num">3</div>
                            <p className="order__item-par">{props.t("user.main.order.orderPrayer")}</p>
                        </div>
                    </div>
                    <div className="button-wrap" onClick={() => redirectToTempleNextPage(Page.ORDER_PRAYER)}>
                        <a href="#" className="order__button button">
                            {props.t("user.header.orderPrayer")}
                        </a>
                    </div>
                </div>
            </section>
            <PopularTemples  />
        </>
    );
};

export default withTranslation()(PublicPageMain);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { TempleChangesRequest } from "../../../domain/models/TempleChangesRequest";
import { ChangesRequestSortFields } from "../../../enums/changes-request-sort-fields-enum";
import { SortingColumnsHandlers } from "../../../types/SortingColumnsHandlers";
import { Translate } from "../../../types/Translate";
import SortingColumn from "../table/SortingColumn";
import TempleChangesRequestsRow from "./TempleChangesRequestsRow";

interface TempleChangesRequestsListProps {
    t: Translate;
    isSuperAdminUser?: boolean;
    requests: TempleChangesRequest[];

    setError: (value: string) => void;
    updateRequestStatus: () => void;

    sortingColumnsHandlers: SortingColumnsHandlers;
}

const TempleChangesRequestsList: FC<TempleChangesRequestsListProps> = (props) => {
    return (
        <table className="inner__table sortable-table">
            <thead>
                <tr>
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={ChangesRequestSortFields.TEMPLE_NAME}
                        columnTranslationName={props.t("updates.table.temple")}
                        additionalWrapperClass="td-padding7"
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={ChangesRequestSortFields.STATUS}
                        columnTranslationName={props.t("updates.table.statusHeader")}
                        additionalWrapperClass="td-padding7"
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={ChangesRequestSortFields.CREATED_AT}
                        columnTranslationName={props.t("updates.table.requestTime")}
                        additionalWrapperClass="td-padding7"
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={ChangesRequestSortFields.APPROVED_BY}
                        columnTranslationName={props.t("updates.table.confirmation")}
                        additionalWrapperClass="td-padding7"
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                </tr>
            </thead>

            <tbody>
                {props.requests?.map(
                    (request) =>
                        request.isRequiredDataExist && (
                            <TempleChangesRequestsRow
                                isSuperAdminUser={props.isSuperAdminUser}
                                request={request}
                                setError={props.setError}
                                updateRequestStatus={props.updateRequestStatus}
                            />
                        )
                )}
            </tbody>
        </table>
    );
};

export default withTranslation()(TempleChangesRequestsList);

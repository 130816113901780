import { FC } from "react";

interface UploadCSVButtonProps {
    title: string;
    onClick: () => void;
}

const UploadCSVButton: FC<UploadCSVButtonProps> = (props) => {
    return (
        <div style={{ cursor: "pointer" }} onClick={props.onClick} title={props.title}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#57d3db" viewBox="0 -960 960 960" height="40" width="48">
                <path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
            </svg>
        </div>
    );
};

export default UploadCSVButton;

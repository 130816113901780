export enum EntityFieldType {
    SIMPLE_VALUE = 1,
    ARRAY_OF_SIMPLE_VALUES = 2,
    ARRAY_OF_FILES = 3,
}

export enum ReligionFields {
    RELIGION_ID = "religionId",
    RELIGION_CURRENT_ID = "religionCurrentId",
    RELIGION_SUBSPECIES_ID = "religionSubspeciesId"
}

export enum EnumFields {
    CURRENCY = "currency",
    PAYMENT_METHOD = "paymentMethod",
    PRAYER_TYPE = "type",
    PRAYER_PERIOD = "period",
    PURPOSE_TYPE = "purposeType"
}

import { FC, useEffect, useReducer } from "react";
import { PublicParams } from "../../../enums/public-params-enum";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";
import { initialConfirmation, confirmationReducer } from "../../../reducers/confirmation-reducer";
import { ConfirmationActionTypes } from "../../../types/reducers/confirmation-reducer";
import templeExtensionServiceFactory from "../../../services/TempleExtensionServiceImpl";
import { useAuth } from "../../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { LoginRequiredPages } from "../../../enums/login-required-pages";

interface CorrespondenceEmailConfirmationProps {
    t: Translate;
}

const CorrespondenceEmailConfirmation: FC<CorrespondenceEmailConfirmationProps> = (props) => {
    /**
     * Using native location object because react-router-dom
     *      get not correctly token: replaces '+' to 'spaces'
     * And this bug generated 403 api error (token expired),
     *      but token still does not expired
    */
    const correspondenceEmailToken = window.location.search.split(PublicParams.CORRESPONDENCE_EMAIL_TOKEN + '=')[1];

    const [confirmation, confirmationDispatch] = useReducer(confirmationReducer, initialConfirmation);

    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const confirmCorrespondenceEmail = async () => {
            const templeExtensionService = templeExtensionServiceFactory();

            if (!user.id) {
                return navigate(
                    `/login?${PublicParams.REDIRECT}=${LoginRequiredPages.CORRESPONDENCE_EMAIL_CONFIRMATION}?${PublicParams.CORRESPONDENCE_EMAIL_TOKEN}=${correspondenceEmailToken}`
                );
            }

            const apiResponse = await templeExtensionService.confirmCorrespondenceEmail(correspondenceEmailToken);
            confirmationDispatch({
                type: ConfirmationActionTypes.UPDATE_BY_API_RESPONSE,
                nextValue: { apiResponse }
            });
        }

        confirmCorrespondenceEmail();
    }, []);

    return (
        <div>
            <h2 className="information__title subtitle">{props.t("confirmation.correspondenceEmail.title")}</h2>
            <h3 className="confirmation__title">{props.t("confirmation.correspondenceEmail.subtitle")}<a href="/temple-admin/main">{props.t("confirmation.correspondenceEmail.subtitleLink")}</a></h3>
            <i>
                <h3 className="confirmation__title">
                    {
                        confirmation.inProcess
                            ? props.t("confirmation.inProcess")
                            : confirmation.entityNotFound
                            ? props.t("confirmation.error")
                            : confirmation.alreadyConfirmed
                            ? props.t("confirmation.alreadyExist")
                            : confirmation.tokenExpired
                            ? props.t("confirmation.expired")
                            : (
                                <div>{props.t("confirmation.correspondenceEmail.success")}<a href="/login">{props.t("confirmation.link")}</a></div>
                            )
                    }
                </h3>
            </i>
        </div>
    );
}

export default withTranslation()(CorrespondenceEmailConfirmation);

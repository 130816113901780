import { FC, ReactElement } from "react";


interface ModalWindowProps {
    content: ReactElement;

    closeCallback(): void;

    contentClass?: string;
    bodyClass?: string;
}

const ModalWindow: FC<ModalWindowProps> = (props) => {
    return (
        <div className={props.contentClass || "modal--main__content"}>
            <span className="modal--main__close" onClick={() => props.closeCallback()}>&times;</span>
            <div className={props.bodyClass || "modal--main__body"}>
                {props.content}
            </div>
        </div>
    );
};

export default ModalWindow;

import { FC } from "react";

interface DownloadCSVButtonProps {
    title: string;
    disable: boolean;
}

const DownloadCSVButton: FC<DownloadCSVButtonProps> = (props) => {
    return (
        <div style={{ cursor: "pointer" }} title={props.title}>
            <svg className={props.disable ? "payout-information-button__disabled" : ""} xmlns="http://www.w3.org/2000/svg" fill="#57d3db" viewBox="0 -960 960 960" height="40" width="48">
                <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
            </svg>
        </div>
    );
};

export default DownloadCSVButton;

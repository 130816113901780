import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PhotoSlider from "../../../base/photo-slider/PhotoSlider";
import PopularTemplesSlide from "./PopularTemplesSlide";
import templeServiceFactory from "../../../../services/TempleServiceImpl";
import { AVG_DESKTOP_WIDTH, DEFAULT_OFFSET, DEFAULT_PAGE, MAX_MOBILE_WIDTH } from "../../../../constants";
import { TempleSortFields } from "../../../../enums/temple-sort-fields-enum";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import { TempleWithCount } from "../../../../domain/models/Temple";
import religionsServiceFactory from "../../../../services/ReligionsServiceImpl";
import useWindowDimensions from "../../../../tools/custom-hooks/base-hooks/useWindowDimensions";

interface PopularTemplesProps {
    t(key?: string): string;
}

const POPULAR_PAGE_SIZE = 10;
const MAX_SLIDES_COUNT = 3;
const AVG_SLIDES_COUNT = 2;
const MIN_SLIDES_COUNT = 1;

const minimumPhotosForLoop = 4;

const PopularTemples: FC<PopularTemplesProps> = (props) => {
    const [data, setData] = useState<TempleWithCount>(null);
    const [isDataExist, setIsDataExist] = useState(false);

    const { width } = useWindowDimensions();
    const isMaxMobileVersion = width < MAX_MOBILE_WIDTH;
    const isAvgDesktopVersion = width < AVG_DESKTOP_WIDTH;

    useEffect(() => {
        const uploadData = async () => {
            const templeService = templeServiceFactory();
            const religionService = religionsServiceFactory();
            const [temples, religions] = await Promise.all([
                templeService.getTemples(
                    DEFAULT_PAGE,
                    POPULAR_PAGE_SIZE,
                    DEFAULT_OFFSET,
                    TempleSortFields.NAME,
                    SortingOrder.ASC,
                    null,
                    null,
                    null,
                    true
                ),
                religionService.getCachedReligions(),
            ]);
            setData(temples);
            setIsDataExist(!!religions.length && !!temples);
        };
        if (!data || !isDataExist) {
            uploadData();
        }
    }, []);

    return (
        <section id="popularChurch" className="popular">
            <div className="container--main">
                <h2 className="popular__title title">{props.t("user.main.popular.title")}</h2>
            </div>
            {data?.rows && (
                <PhotoSlider
                    dynamicBullets={true}
                    dynamicMainBullets={4}
                    sliderClass="popular"
                    allowTouchMove={true}
                    slidesPerView={
                        isMaxMobileVersion
                            ? MIN_SLIDES_COUNT
                            : isAvgDesktopVersion
                            ? AVG_SLIDES_COUNT
                            : MAX_SLIDES_COUNT
                    }
                    spaceBetween={15}
                    loop={
                        isMaxMobileVersion
                            ? data.rows.length >= AVG_SLIDES_COUNT
                            : isAvgDesktopVersion
                            ? data.rows.length >= MAX_SLIDES_COUNT
                            : data.rows.length >= minimumPhotosForLoop
                    }
                    content={data?.rows.map((element, index) => (
                        <div id={"popular-temples-slide" + index}>
                            <PopularTemplesSlide temple={element} />
                        </div>
                    ))}
                />
            )}
        </section>
    );
};

export default withTranslation()(PopularTemples);

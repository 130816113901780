import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../../types/Translate";

interface WorshipLinkInstructionProps {
    t: Translate
}

const WorshipLinkInstruction: FC<WorshipLinkInstructionProps> = (props) => {
    return (
        <div className="input-restrictions-info__card">
            <h4>{props.t("templeRegister.gallery.worshipLinkInstruction.title")}</h4>
            <ol>
                <li>{props.t("templeRegister.gallery.worshipLinkInstruction.firstStep")}</li>
                <li>{props.t("templeRegister.gallery.worshipLinkInstruction.secondStep")}</li>
                <li>{props.t("templeRegister.gallery.worshipLinkInstruction.thirdStep")}</li>
            </ol>
            <p>
                {props.t("templeRegister.gallery.worshipLinkInstruction.correctLink")}
            </p>
            <p>
                {props.t("templeRegister.gallery.worshipLinkInstruction.linkExample")}
            </p>
            <p>
                {props.t("templeRegister.gallery.worshipLinkInstruction.finalText")}
            </p>
        </div>
    );
};

export default withTranslation()(WorshipLinkInstruction);

import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";

export enum TempleSortFields {
    NAME = "name",
    CREATED_AT = "createdAt",
    POPULAR = "popular",
    VISITS = "visits",
    COUNTRY = "country",
    RELIGION_ID = "religionId",
    RELIGION_CURRENT_ID = "religionCurrentId",
    INCOME = "income",
}

export function getTempleSortFieldsTranslation(t: Translate): SelectProps[] {
    return [
        { value: TempleSortFields.NAME.toString(), label: t(`templeSortFields.${TempleSortFields.NAME}`) },
        { value: TempleSortFields.CREATED_AT.toString(), label: t(`templeSortFields.${TempleSortFields.CREATED_AT}`) },
        { value: TempleSortFields.POPULAR.toString(), label: t(`templeSortFields.${TempleSortFields.POPULAR}`) },
        { value: TempleSortFields.VISITS.toString(), label: t(`templeSortFields.${TempleSortFields.VISITS}`) },
    ];
}

export function getCurrentTempleSortFieldTranslation(t: Translate, field: TempleSortFields): SelectProps {
    return { value: field?.toString(), label: t(field && `templeSortFields.${field}`) };
}

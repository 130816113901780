import { Mapper } from "../domain/mappers/mapper";
import { PayPalPaymentDTO } from "../domain/models/dto/PayPalPaymentDTO";
import { PayPalPayment } from "../domain/models/PayPalPayment";
import { PayPalPaymentModel } from "../models/PayPalPaymentModel";
import { SimpleMapper } from "./SimpleMapper";

export class PayPalPaymentMapper extends SimpleMapper<PayPalPayment, PayPalPaymentDTO> {
    protected fromDTOFields: string[] = [
        'state',
        'transactionId',
        'links'
    ];
    protected entityConstructor = PayPalPaymentModel;
}

export function payPalPaymentMapperFactory(): Mapper<PayPalPayment, PayPalPaymentDTO> {
    return new PayPalPaymentMapper();
}

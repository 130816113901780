import { TempleStatisticDTO } from "../domain/models/dto/TempleStatisticDTO";
import { TempleStatistic, TempleStatisticsCollection } from "../domain/models/TempleStatistic";
import { TempleStatisticService } from "../domain/services/TempleStatisticService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { PaymentMethod } from "../enums/payment-method-enum";
import { TempleStatisticsSortFields } from "../enums/temple-statistics-sort-enum";
import templeStatisticMapperFactory from "../mappers/TempleStatisticMapper";
import { get } from "../tools/Tools";
import { PaginationParams } from "../types/PaginationParams";
import { BaseServiceImpl } from "./BaseServiceImpl";

export class TempleStatisticServiceImpl
    extends BaseServiceImpl
    implements TempleStatisticService
{
    async getAllTempleStatistics(
        params: PaginationParams<TempleStatisticsSortFields>,
        defaultPaymentMethod: PaymentMethod,
    ): Promise<TempleStatisticsCollection> {
        try {
            const templeStatisticMapper = templeStatisticMapperFactory();

            let url = this.getBaseEntityUrl(
                EntitiesBaseUrls.TEMPLE_STATISTIC
            ).concat(
                `/all?${this.getPaginationParamsUrl<TempleStatisticsSortFields>(
                    params,
                    TempleStatisticsSortFields.TEMPLE_NAME
                )}`
            );

            if (defaultPaymentMethod) {
                url += `&defaultPaymentMethod=${defaultPaymentMethod}`;
            }

            const result = await get(url);

            return {
                totalPages: result.totalPages || 0,
                totalRows: result.totalRows || 0,
                rows:
                    this.getData<TempleStatisticDTO[]>(result)?.map((dto) =>
                        templeStatisticMapper.fromDTO(dto)
                    ) || [],
            };
        } catch (err) {
            console.log(
                "TempleStatisticServiceImpl.getAllTempleStatistics => ERROR:"
            );
            console.log(err);
        }
    }

    async getTempleStatisticByTempleId(templeId: string): Promise<TempleStatistic> {
        try {
            const templeStatisticMapper = templeStatisticMapperFactory();

            const url = this.getBaseEntityUrl(EntitiesBaseUrls.TEMPLE_STATISTIC).concat(`/${templeId}`);
            const result = await get(url);

            return templeStatisticMapper.fromDTO(this.getData<TempleStatistic>(result));
        } catch (err) {
            console.log(
                "TempleStatisticServiceImpl.getTempleStatisticByTempleId => ERROR:"
            );
            console.log(err);
        }
    }

    getEmptyTempleStatisticCollection(): TempleStatisticsCollection {
        return this.getEmptyCollection();
    }
}

export function templeStatisticServiceFactory(): TempleStatisticService {
    return new TempleStatisticServiceImpl();
}

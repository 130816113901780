import { FC } from "react";
import { Swiper as ISwiper } from "swiper/types";
import NextArrowSVG from "../svg/NextArrowSVG";
import PrevArrowSVG from "../svg/PrevArrowSVG";
import { DEFAULT_SLIDE_INDEX } from "../../../constants";

interface  ElementSwiperArrowsProps {
    swiper: ISwiper;
    loop?: boolean;
}

const ElementSwiperArrows: FC<ElementSwiperArrowsProps> = (props) => {
    const slideNext = () => {
        if (props.loop && props.swiper.isEnd) {
            return props.swiper.slideTo(DEFAULT_SLIDE_INDEX);
        }
        props.swiper.slideNext();
    }

    const slidePrev = () => {
        if (props.loop && props.swiper.isBeginning) {
            return props.swiper.slideTo(props.swiper.slides.length);
        }
        props.swiper.slidePrev();
    }

    return (
        <div className="modal--main__slide-arrows">
            <div className="basic-button-next popular__arrow--next" onClick={slideNext}>
                <NextArrowSVG />
            </div>
            <div className="basic-button-prev popular__arrow--prev" onClick={slidePrev}>
                <PrevArrowSVG />
            </div>
        </div>
    );
};

export default ElementSwiperArrows;

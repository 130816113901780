import * as Yup from "yup"
import YupPassword from 'yup-password'
import { SchemaFactoryProps } from "../types/SchemaFactory"
import { isValidPhoneNumber } from "react-phone-number-input"

YupPassword(Yup)

export const UserLoginSchemaFactory = (props: SchemaFactoryProps) => {
    const t = props.t

    return Yup.object().shape({
        email: Yup.string()
            .email(t("errors.userLogin.email"))
            .max(100, t("errors.commonErrors.max") + "100")
            .required(t("errors.commonErrors.required")),
        password: Yup.string().password()
            .minSymbols(1, t("errors.userLogin.password.minSymbols"))
            .minLowercase(2, t("errors.userLogin.password.minLowercase"))
            .minUppercase(1, t("errors.userLogin.password.minUppercase"))
            .minNumbers(1, t("errors.userLogin.password.minNumbers"))
            .min(8, t("errors.commonErrors.min") + "8")
            .max(16, t("errors.commonErrors.max") + "16")
            .required(t("errors.commonErrors.required"))
    });
}

export const BaseRegisterSchemaFactory = (props: SchemaFactoryProps) => {
    const t = props.t

    return Yup.object().shape({
        email: Yup.string().email(t("errors.userLogin.email"))
            .max(100, t("errors.commonErrors.max") + "100")
            .required(t("errors.commonErrors.required")),
        password: Yup.string().password()
            .minSymbols(1, t("errors.userLogin.password.minSymbols"))
            .minLowercase(2, t("errors.userLogin.password.minLowercase"))
            .minUppercase(1, t("errors.userLogin.password.minUppercase"))
            .minNumbers(1, t("errors.userLogin.password.minNumbers"))
            .min(8, t("errors.commonErrors.min") + "8")
            .max(16, t("errors.commonErrors.max") + "16")
            .required(t("errors.commonErrors.required")),
        confirmPassword: Yup.string().required(t("errors.commonErrors.required"))
    });
}

export const PersonalDataRegisterSchemaFactory = (props: SchemaFactoryProps) => {
    const t = props.t

    return Yup.object().shape({
        name: Yup.string()
            .min(2, t("errors.commonErrors.min") + "2")
            .max(40, t("errors.commonErrors.max") + "40")
            .required(t("errors.commonErrors.required")),
        lastname: Yup.string()
            .min(2, t("errors.commonErrors.min") + "2")
            .max(40, t("errors.commonErrors.max") + "40")
            .required(t("errors.commonErrors.required")),
        phone: Yup.string()
            .required(t("errors.commonErrors.required"))
            .test(
                "phone",
                t("errors.commonErrors.invalidPhoneNumber"),
                function (value) {
                    return value && isValidPhoneNumber(value);
                }
            ),
        temple: Yup.string()
            .min(2, t("errors.commonErrors.min") + "2")
            .max(200, t("errors.commonErrors.max") + "200")
            .required(t("errors.commonErrors.required"))
    });
}

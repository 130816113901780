import { FC } from "react";
import { CustomerSupportTicket } from "../../../domain/models/CustomerSupportTicket";
import ModalWindow from "../../base/ModalWindow";
import { withTranslation } from "react-i18next";

interface CSTSuccessModalProps {
    t(key?: string, values?: object): string;
    closeSuccessModal: () => void;
    createdTicket: CustomerSupportTicket;
}

const CSTSuccessModal: FC<CSTSuccessModalProps> = (props: CSTSuccessModalProps) => {
    return (
        <ModalWindow
            closeCallback={props.closeSuccessModal}
            bodyClass="modal--main__body cst__modal-container"
            content={
                <section className="date cst__form-container">
                    <h1 className="date__title">{props.t("admin.customerSupport.createTicketForm.successTitle")}</h1>
                    <div style={{ margin: "40px 0px", textAlign: "justify", color: "#323232" }}>
                        <span>
                            {props.t("admin.customerSupport.createTicketForm.successMessage", {
                                ticketName: props.createdTicket?.ticketName,
                            })}
                        </span>
                    </div>
                    <div className="modal-footer-button-container">
                        <button className={"save-button-support"} onClick={props.closeSuccessModal}>
                            {props.t("admin.customerSupport.createTicketForm.successButton")}
                        </button>
                    </div>
                </section>
            }
        />
    );
};

export default withTranslation()(CSTSuccessModal);

import { withTranslation } from "react-i18next";
import { Translate } from "../../../types/Translate";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSortingColumns } from "../../../tools/custom-hooks/table-hooks/useSortingColumns";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import PayoutsTable from "./PayoutsTable";
import AdminPagePagination from "../AdminPagePagination";
import { Payout, PayoutsCollection } from "../../../domain/models/Payout";
import { payoutServiceFactory } from "../../../services/PayoutServiceImpl";
import { PayoutsSortFields } from "../../../enums/payouts-sort-fields-enum";
import { DEFAULT_OFFSET } from "../../../constants";
import { UseTableRowsActionsHandlers } from "../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import { PaymentMethod } from "../../../enums/payment-method-enum";

interface PayoutsTabProps {
    t: Translate;
    totalRowsHandler: (value: number) => void;
    rowsHandlers: UseTableRowsActionsHandlers<Payout, null>;
    paymentMethod: PaymentMethod;
}

const payoutService = payoutServiceFactory();

const PayoutsTab: FC<PayoutsTabProps> = (props) => {
    const [data, setData] = useState<PayoutsCollection>(payoutService.getEmptyPayoutCollection());
    const columnsHandlers = useSortingColumns(SortingOrder.DESC);
    const payoutsTableRef = useRef<HTMLTableElement>(null);
    const paginationCallback = useCallback(
        async (currentPage: number, pageSize: number) => {
            const result = await payoutService.getAllPayouts({
                page: currentPage,
                limit: pageSize,
                offset: DEFAULT_OFFSET,
                sort: columnsHandlers.currentActiveColumn as PayoutsSortFields,
                sortingOrder: columnsHandlers.sortingOrder,
            }, props.paymentMethod);

            props.totalRowsHandler(result.totalRows);
            props.rowsHandlers.setReloadCache(false);
            columnsHandlers.setReloadCache(false);
            return result;
        },
        [columnsHandlers, props]
    );

    const filteredDataRows = useMemo(() => {
        return payoutService.getPayoutsToResolve(data.rows);
    }, [data.rows]);

    useEffect(() => {
        if (props.rowsHandlers.selectAllRows) {
            props.rowsHandlers.addRows(filteredDataRows);
        }
    }, [props.rowsHandlers, filteredDataRows]);

    useEffect(() => {
        columnsHandlers.setReloadCache(props.rowsHandlers.reloadCache);
    }, [props.rowsHandlers.reloadCache, columnsHandlers.setReloadCache]);

    return (
        <>
            <PayoutsTable
                payoutsTableRef={payoutsTableRef}
                payouts={data.rows}
                sortingColumnsHandlers={columnsHandlers}
                rowsHandlers={props.rowsHandlers}
            />
            <div className="church__pagination__admin__block">
                <AdminPagePagination
                    additionalPaginationClass="search__data__pagination"
                    pagesCount={data.totalPages}
                    wrapperRef={payoutsTableRef}
                    data={data}
                    reloadCache={columnsHandlers.reloadCache}
                    setData={setData}
                    setReloadCache={columnsHandlers.setReloadCache}
                    serviceCallback={paginationCallback}
                />
            </div>
        </>
    );
};

export default withTranslation()(PayoutsTab);

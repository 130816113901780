import { FC } from "react";
import { withTranslation } from "react-i18next";

interface PaymentFilterProps {
    t(key?: string): string;
}

const PaymentFilter: FC<PaymentFilterProps> = (props) => {
    return (
        <>
            <div className="inner__info-block active">
                <div className="inner__info-row">
                    <div className="inner__info-item">
                        <label>
                            <input className="inner__info-form" type="text" placeholder={props.t("templeAdmin.payments.header.filter.fields.sender")} />
                        </label>
                    </div>

                    <div className="inner__info-item">
                        <label>
                            <input className="inner__info-form" type="text" placeholder={props.t("templeAdmin.payments.header.filter.fields.paymentId")} />
                        </label>
                    </div>
                </div>

                <div className="inner__info-row">
                    <div className="inner__info-item">
                        <label>
                            <input className="inner__info-form" type="text" placeholder={props.t("templeAdmin.payments.header.filter.fields.sumFrom")} />
                        </label>
                    </div>

                    <div className="inner__info-item">
                        <label>
                            <input className="inner__info-form" type="text" placeholder={props.t("templeAdmin.payments.header.filter.fields.sumBefore")} />
                        </label>
                    </div>

                    <div className="inner__info-item">
                        <select className="form__select">
                            <option value="value1">{props.t("templeAdmin.payments.header.filter.fields.status.label")}</option>
                            <option value="value2">{props.t("templeAdmin.payments.header.filter.fields.status.value0")}</option>
                            <option value="value3">{props.t("templeAdmin.payments.header.filter.fields.status.value1")}</option>
                            <option value="value4">{props.t("templeAdmin.payments.header.filter.fields.status.value0")}</option>
                            <option value="value5">{props.t("templeAdmin.payments.header.filter.fields.status.value1")}</option>
                        </select>
                    </div>
                </div>

                <div className="inner__info-row">
                    <div className="inner__info-item">
                        <label>
                            <input className="inner__info-form" type="text" placeholder={props.t("templeAdmin.payments.header.filter.fields.dateFrom")} />
                        </label>
                    </div>
                    <div className="inner__info-item">
                        <label>
                            <input className="inner__info-form" type="text" placeholder={props.t("templeAdmin.payments.header.filter.fields.dateBefore")} />
                        </label>
                    </div>

                    <div className="inner__info-item">
                        <select className="form__select">
                            <option value="value1">{props.t("templeAdmin.payments.header.filter.fields.transactionType.label")}</option>
                            <option value="value2">{props.t("templeAdmin.payments.header.filter.fields.transactionType.value0")}</option>
                            <option value="value3">{props.t("templeAdmin.payments.header.filter.fields.transactionType.value1")}</option>
                            <option value="value4">{props.t("templeAdmin.payments.header.filter.fields.transactionType.value0")}</option>
                            <option value="value5">{props.t("templeAdmin.payments.header.filter.fields.transactionType.value1")}</option>
                        </select>
                    </div>
                </div>

                <button className="inner__info-apply">{props.t("templeAdmin.payments.header.filter.applyButtonLabel")}</button>
            </div>
        </>
    );
};

export default withTranslation()(PaymentFilter);

import { FC } from "react"
import { Outlet } from "react-router-dom";
import AdminFooter from "../base/AdminFooter";
import Header from "./Header";
import Sidebar from "./Sidebar";

interface MainAdminComponentProps {}

const MainAdminComponent: FC<MainAdminComponentProps> = () => {
    return <>
    <div className="wrapper">
        <Sidebar  />

        <div className="container">
            <div className="content">
                <Header  />
                    <main className="main">
                        <div className="inner__content7">
                            <Outlet/>
                        </div>
                    </main>
                <AdminFooter  isTempleAdmin={false} />
            </div>
        </div>
    </div>
  </>
}


export default MainAdminComponent;

import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import { Role } from "../enums/role-enum";

class UserModel implements User {
    id?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    templeName?: string;

    role?: Role | undefined;

    createdAt?: string;
    updatedAt?: string;

    constructor(data: UserDTO) {
        Object.assign(this, data || {});
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    get isAdmin() {
        return this.role === Role.SUPER_ADMIN
    }
    get isTempleAdmin() {
        return this.role === Role.TEMPLE_ADMIN
    }

    setTempleName(templeName: string) {
        this.templeName = templeName;
    }
}


export default UserModel

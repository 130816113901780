import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";


export const PaymentAccountSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        accountId: {
            min: 2,
            max: 200
        },
        recipientName: {
            min: 2,
            max: 200
        },
    }

    return {
        schema: Yup.object().shape({
            id: Yup.string(),
            paymentMethod: Yup.number()
                .required(t("errors.commonErrors.required")),
            accountId: Yup.string()
                .min(inputRestrictions.accountId.min, t("errors.commonErrors.min") + inputRestrictions.accountId.min)
                .max(inputRestrictions.accountId.max, t("errors.commonErrors.max") + inputRestrictions.accountId.max)
                .required(t("errors.commonErrors.required")),
            recipientName: Yup.string()
                .min(inputRestrictions.recipientName.min, t("errors.commonErrors.min") + inputRestrictions.recipientName.min)
                .max(inputRestrictions.recipientName.max, t("errors.commonErrors.max") + inputRestrictions.recipientName.max)
                .required(t("errors.commonErrors.required")),
            currency: Yup.number()
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

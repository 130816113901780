import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { Temple } from "../../../domain/models/Temple";
import { Translate } from "../../../types/Translate";
import TempleRemoveModal from "./TempleRemoveModal";

interface TempleRemoveItemButtonProps {
    t: Translate;

    templeId: string;
    setTemples(value: Temple[]): void;

    setErrorVisibility(value: boolean): void;
};

const TempleRemoveItemButton: FC<TempleRemoveItemButtonProps> = (props) => {
    const [removingTempleId, setRemovingTempleId] = useState<string>(null);

    return (
        <>
            <button
                className="my-church__remove my-church__function"
                onClick={() => setRemovingTempleId(props.templeId)}
            >
                {props.t("templeAdmin.main.delete")}
            </button>

            <Popup open={!!removingTempleId} modal overlayStyle={{ zIndex: 999999 }} onClose={() => setRemovingTempleId(null)}>
                <TempleRemoveModal

                    removingTempleId={removingTempleId}
                    setRemovingTempleId={setRemovingTempleId}
                    setTemples={props.setTemples}
                    setErrorVisibility={props.setErrorVisibility}
                />
            </Popup>
        </>
    );
};

export default withTranslation()(TempleRemoveItemButton);

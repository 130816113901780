import { supplementaryFields } from "../constants";
import { SearchField } from "../domain/models/SearchData";
import { SearchFieldDTO } from "../domain/models/dto/SearchData";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";

export class SearchFieldModel extends BaseRelatedEntityMixinFactory<SearchFieldDTO>()  implements SearchField {
    fieldName: string;
    value: string;

    constructor(data?: SearchField) {
        super();

        this.assignData(data);
    }

    get fieldToVisibility(): boolean {
        return !supplementaryFields.includes(this.fieldName) && !!this.value;
    }
}

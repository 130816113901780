import { PrayerPriceDetailDTO } from "../domain/models/dto/PrayerPriceDetailDTO";
import { PrayerPriceDetail } from "../domain/models/PrayerPriceDetail";
import { PrayerPriceDetailService } from "../domain/services/PrayerPriceDetailService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import prayerPriceDetailMapperFactory from "../mappers/PrayerPriceDetailMapper";
import { RemoveResponse } from "../types/RemoveResponse";
import { BaseServiceImpl } from "./BaseServiceImpl";

class PrayerPriceDetailServiceImpl
    extends BaseServiceImpl
    implements PrayerPriceDetailService
{
    async createRequestOrUpdateCurrentEntity(dto: PrayerPriceDetailDTO): Promise<PrayerPriceDetail> {
        if (
            !dto.maxPeople ||
            !dto.prayerId ||
            !dto.price
        ) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<PrayerPriceDetailDTO, PrayerPriceDetail>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.PRAYER_PRICE_DETAIL), prayerPriceDetailMapperFactory
        );
    }

    async removeEntity(entity: PrayerPriceDetail): Promise<RemoveResponse> {
        return await this.removeEntityById<PrayerPriceDetailDTO, PrayerPriceDetail>(entity, this.getV2EntityUrl(EntitiesBaseUrls.PRAYER_PRICE_DETAIL), prayerPriceDetailMapperFactory);
    }
}

export default function prayerPriceDetailServiceFactory(): PrayerPriceDetailService {
    return new PrayerPriceDetailServiceImpl();
}

import { FC } from "react";
import CustomCheckbox from "../../CustomCheckbox";

interface ChangeRequestEntityCheckboxProps {
    value: boolean;
}

const ChangeRequestEntityCheckbox: FC<ChangeRequestEntityCheckboxProps> = (props) => {
    return (
        <CustomCheckbox
            defaultValue={props.value}
            wrapperClassName="order-payer__checkbox"
            className="checkbox__input"
            onChange={null}
        />
    );
};

export default ChangeRequestEntityCheckbox;

import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";
import * as _ from "lodash";

export function getEnumTranslatedDropDownOptions(
    enumeration: any,
    translatePrefix: string,
    t: Translate,
    excludeValues: any[] = []
): SelectProps[] {
    let result = _.keys(enumeration)
        .filter((key: any) => _.isNumber(enumeration[key]))
        .map((key: any) => {
            return {
                value: enumeration[key].toString(),
                label: t(`${translatePrefix}${enumeration[key]}`),
            };
        });
    if (!_.isEmpty(excludeValues)) {
        result = result.filter(
            (value) => excludeValues.indexOf(value.value) < 0
        );
    }
    return result as any;
}

import { FC, useEffect, useState } from "react";
import { Prayer } from "../../../domain/models/Prayer";
import { SelectProps } from "../../../domain/models/SelectProps";
import { getPrayerTypesTranslation } from "../../../enums/prayer-enum";
import prayerServiceFactory from "../../../services/PrayerServiceImpl";
import DropDown from "../../base/DropDown";
import OrderPrayerItem from "./OrderPrayerItem";
import { Prayer as PrayerType } from "../../../enums/prayer-enum";
import { useFormik } from "formik";
import { OrderPrayerSchemaFactory } from "../../../validation-schemas/public-pages/order-prayer-schemas";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import { PrayerRequirement } from "../../../domain/models/PrayerRequirement";
import prayerRequirementServiceFactory from "../../../services/PrayerRequirementServiceImpl";
import OrderPrayerRequirement from "./OrderPrayerRequirement";
import { PayPalPayment } from "../../../domain/models/PayPalPayment";
import PayPalCheckoutButton from "../../integrations/paypal/PayPalCheckoutButton";
import { payPalServiceFactory } from "../../../services/PayPalServiceImpl";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import SuccessPaymentModal from "../../base/SuccessPaymentModal";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCheckbox from "../../base/CustomCheckbox";


interface OrderPrayerPayerWithIntegrationProps {
    t(key?: string): string;

    prayers: Prayer[];
    prayerRequirements: PrayerRequirement[];

    selectedPrayer: Prayer;
    setSelectedPrayer(value: Prayer): void;
}

const OrderPrayerPayerWithIntegration: FC<OrderPrayerPayerWithIntegrationProps> = (props) => {
    const navigate = useNavigate();

    const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState<boolean>(false);

    const location = useLocation().pathname.split("/");
    const templeId = location[2];

    const [errorVisible, setErrorVisibility] = useState<string>(null);

    const prayerService = prayerServiceFactory();
    const paypalService = payPalServiceFactory();
    const prayerRequirementService = prayerRequirementServiceFactory();

    const [prayerType, setPrayerType] = useState<SelectProps>(null);
    const [prayer, setPrayer] = useState<SelectProps>(null);
    const [orderDetails, setOrderDetails] = useState<string[]>([]);

    const [orderPrayers, setOrderPrayers] = useState<typeof OrderPrayerItem[]>([OrderPrayerItem]);

    const currentPrayer = prayerService.getPrayerByIdFromPrayers(prayer?.value as string, props.prayers);
    const prayerPriceDetails = currentPrayer?.getSortedPrayerPriceDetails(SortingOrder.ASC);
    const currentPrayerPriceDetail = prayerPriceDetails?.at(-1);
    const minPrayerPriceDetail = prayerPriceDetails?.at(0);
    const maxPeople = currentPrayerPriceDetail?.maxPeople;

    const isMaxPeople = orderDetails.length >= maxPeople;
    const isMaxOrderPrayers = orderPrayers.length >= maxPeople;

    const addOrderPrayer = () => {
        if ((orderDetails.length !== orderPrayers.length) || isMaxPeople) {
            return;
        }

        orderPrayers.push(OrderPrayerItem);
        setOrderPrayers([...orderPrayers]);
    };

    const getPrayerNamesTranslation = (type?: PrayerType): SelectProps[] => {
        return prayerService.getSortedPrayersByType(
            type || parseInt(prayerType?.value) as PrayerType, props.prayers
        ).map(
            (prayer) => {
                return {
                    value: prayer.id,
                    label: prayer.name
                }
            }
        );
    };

    const orderPrayerForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            prayerId: "",
            conditionsAccepted: false,

            senderName: "",
            senderEmail: "",

            details: "",
            orderDetails: [],
        },
        validationSchema: OrderPrayerSchemaFactory({ t: props.t }),
        onSubmit: async () => {},
    });

    const handlePrayerTypeChange = (value: SelectProps) => {
        setPrayerType(value);
        setPrayer(null);

        setOrderPrayers([OrderPrayerItem]);
        setOrderDetails([]);

        if (!value) {
            orderPrayerForm.setFieldValue("prayerId", undefined);
        }
    };

    const handlePrayerChange = (value: SelectProps) => {
        setPrayer(value);

        setOrderPrayers([OrderPrayerItem]);
        setOrderDetails([]);

        orderPrayerForm.setFieldValue("prayerId", value?.value || undefined);
    };

    const updateConditionsAccepted = () => {
        orderPrayerForm.setFieldValue(
            "conditionsAccepted",
            !orderPrayerForm.values.conditionsAccepted
        );
    };

    const updateOrderDetails = (newValue: string, currentIndex: number) => {
        if (!newValue) {
            orderDetails.splice(currentIndex, 1);

            orderPrayerForm.setFieldValue("orderDetails", orderDetails);
            return setOrderDetails(orderDetails);
        }

        const currentElement = orderDetails.at(currentIndex);

        if (currentElement) {
            orderDetails[currentIndex] = newValue;
        } else {
            orderDetails.push(newValue);
        }

        orderPrayerForm.setFieldValue("orderDetails", orderDetails);
        setOrderDetails(orderDetails);
    };

    useEffect(() => {
        const prayer = props.selectedPrayer;
        if (!prayer) {
            return
        }

        props.setSelectedPrayer(null);

        handlePrayerTypeChange(
            getPrayerTypesTranslation(props.t).find(el => parseInt(el.value) === prayer.type)
        );
        handlePrayerChange(
            getPrayerNamesTranslation(prayer.type).find(el => el.value === prayer.id)
        );
    });

    const isFormFilled = !!(
        orderPrayerForm.values.prayerId &&
        orderPrayerForm.values.conditionsAccepted &&
        orderPrayerForm.values.senderName &&
        orderPrayerForm.values.senderEmail
    );

    const createPaymentHandler = () => {
        setErrorVisibility("errors.common");
    };

    const paymentHandler = (paymentResult: PayPalPayment) => {
        if (paymentResult && paymentResult.isApproved) {
            setErrorVisibility(null);
            setIsVisibleSuccessModal(true);

            window.scrollTo(0, 0);
            return;
        }

        let error = "";
        if (!paymentResult) {
            error = "payments.paymentFailedToCreate";
        } else if (paymentResult.isFailed) {
            error = "payments.paymentFailed";
        } else if (paymentResult.isCreated) {
            error = "payments.paymentCreated";
        } else if (paymentResult.isNotCreated) {
            error = "payments.paymentWasNotCreated";
        } else {
            error = "errors.common";
        }
        setErrorVisibility(error);
    };

    return (
        <div className="order-payer__inner">
            <div className="order-payer__forms">
                <div className="order-payer__form-item">
                    <DropDown
                        name="type"
                        value={prayerType}
                        options={getPrayerTypesTranslation(props.t)}
                        onChange={handlePrayerTypeChange}
                        placeholder={props.t("templeRegister.orders.type")}
                    />
                    {!prayerType && orderPrayerForm.errors.prayerId}
                </div>
                <div className="order-payer__form-item">
                    <DropDown
                        name={"prayer-name"}
                        value={prayer}
                        options={getPrayerNamesTranslation()}
                        onChange={handlePrayerChange}
                        placeholder={props.t("user.prayer.payer.prayerNoteName")}
                    />
                    {!prayer && orderPrayerForm.errors.prayerId}
                </div>
                {orderPrayers.map((element, index) => element({
                    t: props.t,
                    key: index,
                    isEmptyPrayer: !prayer?.value,
                    updateOrderDetails: updateOrderDetails
                }))}
                {(!isMaxPeople && !isMaxOrderPrayers) && <div className="order-payer__add text" onClick={() => addOrderPrayer()}>
                    <div className="circle"></div> {props.t("user.prayer.payer.addButton")}
                </div>}
                <div className="order-payer__form-item">
                    <label>
                        <input
                            type="text"
                            placeholder={props.t("user.prayer.payer.email")}
                            className="order-payer__form"
                            name="senderEmail"
                            onChange={orderPrayerForm.handleChange}
                            defaultValue={orderPrayerForm.values.senderEmail}
                        />
                        {orderPrayerForm.errors.senderEmail}
                    </label>
                </div>
                <div className="order-payer__form-item">
                    <label>
                        <input
                            className="order-payer__form"
                            type="text"
                            placeholder={props.t("user.prayer.payer.fullName")}
                            name="senderName"
                            onChange={orderPrayerForm.handleChange}
                            defaultValue={orderPrayerForm.values.senderName}
                        />
                        {orderPrayerForm.errors.senderName}
                    </label>
                </div>
                <div className="order-payer__form-item">
                    <textarea
                        className="order-payer__form order-payer__textarea"
                        placeholder={props.t("user.prayer.payer.text")}
                        name="details"
                        onChange={orderPrayerForm.handleChange}
                        defaultValue={orderPrayerForm.values.details}
                        cols={30}
                        rows={10}
                    ></textarea>
                    {orderPrayerForm.errors.details}
                </div>
                <CustomCheckbox
                    id="formAgreement"
                    name="conditionsAccepted"
                    onChange={updateConditionsAccepted}
                    defaultValue={orderPrayerForm.values.conditionsAccepted}
                    className="checkbox__input"
                    wrapperClassName="order-payer__checkbox"
                    labelContent={
                        <span>
                            {props.t("user.prayer.payer.agreement1")}<a href="#">{props.t("user.prayer.payer.agreement2")}</a>{props.t("user.prayer.payer.agreement3")}
                        </span>
                    }
                    errors={orderPrayerForm.errors.conditionsAccepted}
                />
                <div className="button-wrap">
                    {/* <button
                        type="button"
                        className="order-payer__button"
                        onClick={() => orderPrayerForm.handleSubmit()}
                    >
                        {props.t("user.prayer.payer.prayerButton")}
                    </button> */}
                    <PayPalCheckoutButton
                        value={minPrayerPriceDetail?.priceFloatFormat || 0}
                        data={{
                            ..._.pickBy(orderPrayerForm.values, _.identity),
                            orderDetails: _.compact(orderDetails),
                            prayerPriceDetailId: currentPrayerPriceDetail?.id,
                        }}
                        isValidData={orderPrayerForm.isValid && isFormFilled}
                        setCommonPaymentError={createPaymentHandler}
                        paymentHandler={paymentHandler}
                        executeServiceMethod={paypalService.executeOrderPayment}
                    ></PayPalCheckoutButton>
                    {errorVisible && <p>{props.t(errorVisible)}</p>}
                    <b className="order-payer__button__label">
                        {" $ " + (currentPrayer?.getMaxPriceDetailByPeopleAmount(orderDetails.length) || minPrayerPriceDetail?.priceFloatFormat || 0)}
                    </b>
                </div>
            </div>
            <div className="order-payer__description">
                <ul className="order-payer__description-list">
                    {prayerRequirementService.getSortedPrayerRequirementsByOrder(SortingOrder.ASC, props.prayerRequirements).map((prayerRequirement) => (
                        <OrderPrayerRequirement prayerRequirement={prayerRequirement} />
                    ))}
                </ul>
            </div>

            <Popup open={isVisibleSuccessModal} modal overlayStyle={{ zIndex: 999999 }} onClose={() => navigate("/")}>
                <SuccessPaymentModal
                    title={props.t("user.prayer.successModal.title1") + prayer?.label + props.t("user.prayer.successModal.title2")}
                    redirectToMainPage={() => navigate(templeId ? `/temple/${templeId}` : "/")}
                />
            </Popup>
        </div>
    );
};

export default withTranslation()(OrderPrayerPayerWithIntegration);

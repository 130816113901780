import { FC } from "react";
import { Navigate, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import AboutPage from "../components/public-pages/about/AboutPage";
import PublicPageComponent from "../components/public-pages/PublicPageComponent";
import PublicPageMain from "../components/public-pages/main/PublicPageMain";
import OrderPrayerPage from "../components/public-pages/order-prayer/OrderPrayerPage";
import SupportPage from "../components/public-pages/support/SupportPage";
import TempleSearchPage from "../components/public-pages/temple-search/TempleSearchPage";
import TemplePage from "../components/user/temple/TemplePage";
import CreateCustomerSupportTicketForm from "../components/public-pages/customer-support/CreateCustomerSupportTicketForm";
import FAQPage from "../components/public-pages/faq/FAQ";
import SearchPage from "../components/public-pages/search/SearchPage";
import { WorshipSchedulesProvider } from "../context/WorshipSchedulesProvider";
import RegistrationConfirmation from "../components/public-pages/confirmation-pages/RegistrationConfirmation";
import CorrespondenceEmailConfirmation from "../components/public-pages/confirmation-pages/CorrespondenceEmailConfirmation";

interface PublicRoutesProps {}

const PublicRoutes: FC<PublicRoutesProps> = () => {
    return (
        <Routes>
            <Route path="/" element={<PublicPageComponent />}>
                <Route path="/" element={<PublicPageMain />} />
                <Route path="choose-temple" element={<TempleSearchPage />} />

                <Route
                    path="/temple/:id"
                    element={
                        <WorshipSchedulesProvider>
                            <TemplePage />
                        </WorshipSchedulesProvider>
                    }
                />
                <Route path="/temple/:id/order-prayer" element={<OrderPrayerPage />} />
                <Route path="/temple/:id/support" element={<SupportPage isTempleSupport={true} />} />

                {/* TODO :: redirect to / and visible popular-container*/}
                <Route path="popular" element={<h1>popular_temples</h1>} />
                <Route path="support" element={<SupportPage isTempleSupport={false} />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="faq" element={<FAQPage />} />
                <Route path="search" element={<SearchPage />} />
                <Route path="customer-support" element={<CreateCustomerSupportTicketForm />} />
                <Route path="registration-confirmation" element={<RegistrationConfirmation />} />
                <Route path="correspondence-email-confirmation" element={<CorrespondenceEmailConfirmation />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
        </Routes>
    );
};

export default PublicRoutes;

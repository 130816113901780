import { Mapper } from "../domain/mappers/mapper";
import { AdminShortStatistic } from "../domain/models/Admin";
import { AdminShortStatisticDTO } from "../domain/models/dto/AdminDTO";
import AdminShortStatisticModel from "../models/AdminShortStatisticModel";
import { SimpleMapper } from "./SimpleMapper";

class AdminShortStatisticMapper extends SimpleMapper<AdminShortStatistic, AdminShortStatisticDTO> {
    protected entityConstructor = AdminShortStatisticModel;
    protected fromDTOFields: string[] = [
        "totalCount",
        "inReviewCount",
        "doneCount",
        "totalAmount",
        "inReviewAmount",
        "doneAmount",

        "totalPayoutCount",
    ];
}


export default function adminShortStatisticMapperFactory(): Mapper<AdminShortStatistic, AdminShortStatisticDTO> {
    return new AdminShortStatisticMapper()
}

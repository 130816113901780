import { FC } from "react";
import { Navigate, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import MainTempleAdminComponent from "../components/temple-admin/MainTempleAdminComponent";
import TempleAdminTemplesList from "../components/temple-admin/main/TempleAdminTemplesList";
import BasePayments from "../components/base/payments/BasePayments";
import BasePayment from "../components/base/payments/BasePayment";
import BaseUpdates from "../components/base/change-requests/TempleChangesRequestsTab";
import BaseUpdate from "../components/base/change-requests/change-request/ChangeRequestEntityTab";
import PayoutsPage from "../components/base/payouts-tab/PayoutsPage";
import PayoutDetails from "../components/base/payouts-tab/payout-details/PayoutDetails";

interface TempleAdminRoutesProps {}

const TempleAdminRoutes: FC<TempleAdminRoutesProps> = () => {
    return (
        <Routes>
            <Route path="/" element={<MainTempleAdminComponent  />}>
                <Route path="main" element={<TempleAdminTemplesList  />} />
                <Route path="payments" element={<BasePayments  />} />
                <Route path="payments/:paymentId" element={<BasePayment  />} />

                <Route path="updates" element={<BaseUpdates  />} />
                <Route path="updates/:requestId" element={<BaseUpdate />} />

                <Route path="payouts" element={<PayoutsPage />} />
                <Route path="payouts/:payoutId" element={<PayoutDetails />} />

                {/* <Route path="visited" element={<h1>visited</h1>} />
                <Route path="messenger" element={<h1>messenger</h1>} /> */}

                <Route path="/" element={<Navigate to="main" replace />} />
                <Route path="*" element={<Navigate to="main" replace />} />
            </Route>
        </Routes>
    );
};

export default TempleAdminRoutes;

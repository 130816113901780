import { FC } from "react";
import { withTranslation } from "react-i18next";

interface OrderPrayerHeaderProps {
    t(key?: string): string;
    templeName: string;
}

const OrderPrayerHeader: FC<OrderPrayerHeaderProps> = (props) => {
    return (
        <>
            <h1 className="order-payer__title">{props.templeName}</h1>
            <h2 className="order-payer__subtitle">{props.t("user.prayer.header.subtitle")}</h2>
            <div className="order-payer__text">
                {props.t("user.prayer.header.text")}
            </div>
        </>
    );
};

export default withTranslation()(OrderPrayerHeader);

import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../../../Config";
import { Temple } from "../../../domain/models/Temple";
import { CreateTempleParams } from "../../../enums/create-temple-params-enum";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import { Translate } from "../../../types/Translate";
import TempleRemoveItemButton from "./TempleRemoveItemButton";

interface TempleItemProps {
    t: Translate;

    temple: Temple;
    setTemples(value: Temple[]): void;
}

const TempleItem: FC<TempleItemProps> = (props) => {
    const navigate = useNavigate();

    const id = `?${CreateTempleParams.ID}=${props.temple.originId}`;
    const filePath = props.temple.getSortedFiles(SortingOrder.ASC)[0]?.filePath;

    const [isErrorVisibility, setErrorVisibility] = useState<boolean>(false);

    return (
        <>
            {isErrorVisibility && props.t("errors.common")}
            <div className="my-church__item">
                <div className="my-church__img">
                    <img src={filePath && `${apiURL}/files/${filePath}`} alt="" />
                </div>
                <div className="my-church__block">
                    <a className="my-church__name" href="#">
                        {props.temple.name}
                    </a>
                    <div className="my-church__functions">
                        {props.temple.isApproved ? (
                            <div className="my-church__status my-church__function">
                                {props.t("templeAdmin.main.activated")}
                            </div>
                        ) : (
                            <div className="my-church__function">{props.temple.isDeclined ? props.t("templeAdmin.main.declined") : props.t("templeAdmin.main.inactive")}</div>
                        )}
                        {!props.temple.isDeclined && (
                            <div className="my-church__edit my-church__function">
                                <a href="#" onClick={() => navigate("/create-temple/main-info" + (id ? id : ""))}>
                                    {props.t("templeAdmin.main.edit")}
                                </a>
                            </div>
                        )}
                        <TempleRemoveItemButton
                            templeId={props.temple?.id}
                            setTemples={props.setTemples}
                            setErrorVisibility={setErrorVisibility}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(TempleItem);

import { apiURL } from "../Config";
import { Mapper } from "../domain/mappers/mapper";
import {
    CustomerSupportTicket,
    CustomerSupportTicketsCollection,
} from "../domain/models/CustomerSupportTicket";
import { CustomerSupportTicketDTO } from "../domain/models/dto/CustomerSupportTicketDTO";
import { CustomerSupportTicketService } from "../domain/services/CustomerSupportTicketService";
import { CustomerSupportTicketFields } from "../enums/customer-support-ticket-fields-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import customerSupportTicketMapperFactory from "../mappers/CustomerSupportTicketMapper";
import { get, patch, post } from "../tools/Tools";
import { PaginationParams } from "../types/PaginationParams";
import { BaseServiceImpl } from "./BaseServiceImpl";

export class CustomerSupportTicketServiceImpl
    extends BaseServiceImpl
    implements CustomerSupportTicketService
{
    private static _customerSupportTickets: CustomerSupportTicketsCollection = null
    mapper: Mapper<CustomerSupportTicket, CustomerSupportTicketDTO> =
        customerSupportTicketMapperFactory();

    async createCustomerSupportTicket(
        model: CustomerSupportTicket
    ): Promise<CustomerSupportTicket> {
        if (!model) {
            return null;
        }
        try {
            return this.getData(
                await post(
                    `${apiURL}/api/v1/customer-support-tickets`,
                    this.mapper.toDTO(model)
                )
            );
        } catch (err) {
            console.log(
                "CustomerSupportTicketServiceImpl.createCustomerSupportTicket => ERROR:"
            );
            console.log(err);
        }
    }

    async getCachedCustomerSupportTickets(
        page: number,
        limit: number,
        offset: number,
        sort: CustomerSupportTicketFields,
        sortingOrder: SortingOrder,
        reloadCache?: boolean
    ): Promise<CustomerSupportTicketsCollection> {
        if (
            !CustomerSupportTicketServiceImpl._customerSupportTickets ||
            reloadCache
        ) {
            CustomerSupportTicketServiceImpl._customerSupportTickets =
                await this.getCustomerSupportTickets({
                    page,
                    limit,
                    offset,
                    sort,
                    sortingOrder,
                });
        }

        return CustomerSupportTicketServiceImpl._customerSupportTickets;
    }

    async getCustomerSupportTickets(
        params: PaginationParams<CustomerSupportTicketFields>
    ): Promise<CustomerSupportTicketsCollection> {
        try {
            const result = await get(
                `${apiURL}/api/v1/customer-support-tickets?${this.getPaginationParamsUrl(params, CustomerSupportTicketFields.STATUS)}`
            );
            const customerSupportTickets =
                result.data?.map(
                    (customerSupportTicket: CustomerSupportTicketDTO) =>
                        this.mapper.fromDTO(customerSupportTicket)
                ) || [];

            return {
                totalPages: result.totalPages,
                totalRows: result.totalRows,
                rows: customerSupportTickets,
            };
        } catch (err) {
            console.log(
                "CustomerSupportTicketServiceImpl.getCustomerSupportTickets => ERROR:"
            );
            console.log(err);
        }
    }

    async getCachedCustomerSupportTicketById(
        customerSupportTicketId: string
    ): Promise<CustomerSupportTicket> {
        if (!customerSupportTicketId) return null;

        return (
            CustomerSupportTicketServiceImpl._customerSupportTickets?.rows.find(
                (customerSupportTicket) =>
                    customerSupportTicket.id === customerSupportTicketId
            ) ||
            (await this.getCustomerSupportTicketById(customerSupportTicketId))
        );
    }

    private async getCustomerSupportTicketById(
        customerSupportTicketId: string
    ): Promise<CustomerSupportTicket> {
        try {
            return this.mapper.fromDTO(
                this.getData(
                    await get(
                        `${apiURL}/api/v1/customer-support-tickets/${customerSupportTicketId}`
                    )
                )
            );
        } catch (err) {
            console.log(
                "CustomerSupportTicketServiceImpl.getCustomerSupportTicketById => ERROR:"
            );
            console.log(err);
        }
    }

    async updateCustomerSupportTicket(
        model: CustomerSupportTicket
    ): Promise<CustomerSupportTicket> {
        if (!model) {
            return null;
        }
        try {
            return this.mapper.fromDTO(
                this.getData(
                    await patch(
                        `${apiURL}/api/v1/customer-support-tickets`,
                        this.mapper.toDTO(model)
                    )
                )
            );
        } catch (err) {
            console.log(
                "CustomerSupportTicketServiceImpl.updateCustomerSupportTicket => ERROR:"
            );
            console.log(err);
        }
    }

    async resendCustomerSupportTicketEmail(
        id: string
    ): Promise<CustomerSupportTicket> {
        if (!id) {
            return null;
        }
        try {
            return this.mapper.fromDTO(
                this.getData(
                    await patch(
                        `${apiURL}/api/v1/customer-support-tickets/${id}/send-email`
                    )
                )
            );
        } catch (err) {
            console.log(
                "CustomerSupportTicketServiceImpl.resendCustomerSupportTicketEmail => ERROR:"
            );
            console.log(err);
        }
    }

    async getCachedPaginationData(
        page: number,
        limit: number,
        offset: number,
        sort: any,
        sortingOrder: SortingOrder,
        reloadCache: boolean,
        updateData: boolean
    ): Promise<CustomerSupportTicketsCollection> {
        const data = await this.getPaginationData<
            CustomerSupportTicketsCollection,
            CustomerSupportTicketFields
        >(
            CustomerSupportTicketServiceImpl._customerSupportTickets,
            this.getCustomerSupportTickets.bind(this),
            { page, limit, offset, sort, sortingOrder },
            reloadCache,
            updateData
        );
        CustomerSupportTicketServiceImpl._customerSupportTickets = data;

        return data;
    }
}

export default function customerSupportTicketServiceFactory(): CustomerSupportTicketService {
    return new CustomerSupportTicketServiceImpl();
}

import { Mapper } from "../domain/mappers/mapper";
import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import UserModel from "../models/UserModel";
import { SimpleMapper } from "./SimpleMapper";

class UserMapper extends SimpleMapper<User, UserDTO> {
    protected entityConstructor = UserModel;

    protected fromDTOFields: string[] = [
        "id",
        "firstName",
        "lastName",
        "phone",
        "email",
        "templeName",
        "createdAt",
        "updatedAt",
        "role",
    ];
}


export default function userMapperFactory(): Mapper<User, UserDTO> {
    return new UserMapper()
}

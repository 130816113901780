import { apiURL } from "../Config";
import { AdminShortStatistic, AdminStatistic } from "../domain/models/Admin";
import { StatisticService } from "../domain/services/StatisticService";
import AdminStatisticMapperFactory from "../mappers/AdminStatisticMapper";
import AdminShortStatisticMapperFactory from "../mappers/AdminShortStatisticMapper";
import { get } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";


class StatisticServiceImpl extends BaseServiceImpl implements StatisticService {
    private static superAdminStatistic: AdminStatistic;

    private async getSuperAdminStatistic(): Promise<AdminStatistic> {
        try {
            const adminMapper = AdminStatisticMapperFactory();

            let result = await get(`${apiURL}/api/v1/admin/statistic`);
            result = result.data;

            return adminMapper.fromDTO(result);
        } catch (err) {
            console.log('AdminServiceImpl.getCachedSuperAdminStatistic => ERROR:');
            console.log(err);
        }
    }

    async getCachedSuperAdminStatistic(): Promise<AdminStatistic> {
        if (!StatisticServiceImpl.superAdminStatistic) {
            StatisticServiceImpl.superAdminStatistic = await this.getSuperAdminStatistic();
        }

        return StatisticServiceImpl.superAdminStatistic;
    }

    async getShortAdminStatistic(): Promise<AdminShortStatistic> {
        try {
            const adminMapper = AdminShortStatisticMapperFactory();
            const result = await get(`${apiURL}/api/v1/admin/short-statistic`);

            return adminMapper.fromDTO(result.data);
        } catch (err) {
            console.log('AdminServiceImpl.getShortAdminStatistic => ERROR:');
            console.log(err);
        }
    }
}


export default function statisticServiceFactory(): StatisticService {
    return new StatisticServiceImpl();
}

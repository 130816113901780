import { FC, ReactElement } from "react";
import { apiURL } from "../../../Config";
import NoPhotoSVG from "../svg/NoPhotoSVG";

interface MainImageSlideProps {
    filePath: string;
    additionalContent?: ReactElement<any, any>;

    wrapClassName?: string;
    imageClassName?: string;
    swiperImageClassName?: string;

    slideOnClick: () => void;
}

const MainImageSlide: FC<MainImageSlideProps> = (props) => {
    return (
        <div className={props.wrapClassName || ""} onClick={props.slideOnClick}>
            <div className={(props.imageClassName || "") + (!props.filePath ? " base__no__photo__svg" : "")}>
                <picture>
                    {props.filePath ? (
                        <img
                            className={props.swiperImageClassName || ""}
                            src={props.filePath && `${apiURL}/files/${props.filePath}`}
                            alt=""
                        />
                    ) : (
                        <NoPhotoSVG />
                    )}
                </picture>
            </div>
            {props.additionalContent && props.additionalContent}
        </div>
    );
};

export default MainImageSlide;

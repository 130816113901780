import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";

export const BankAccountSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        fullName: {
            min: 2,
            max: 200
        },
        accountId: {
            min: 2,
            max: 200
        },
        iban: {
            min: 2,
            max: 200
        },
    }

    return {
        schema: Yup.object().shape({
            id: Yup.string(),
            fullName: Yup.string()
                .min(inputRestrictions.fullName.min, t("errors.commonErrors.min") + inputRestrictions.fullName.min)
                .max(inputRestrictions.fullName.max, t("errors.commonErrors.max") + inputRestrictions.fullName.max)
                .required(t("errors.commonErrors.required")),
            accountId: Yup.string()
                .min(inputRestrictions.accountId.min, t("errors.commonErrors.min") + inputRestrictions.accountId.min)
                .max(inputRestrictions.accountId.max, t("errors.commonErrors.max") + inputRestrictions.accountId.max)
                .required(t("errors.commonErrors.required")),
            iban: Yup.string()
                .min(inputRestrictions.iban.min, t("errors.commonErrors.min") + inputRestrictions.iban.min)
                .max(inputRestrictions.iban.max, t("errors.commonErrors.max") + inputRestrictions.iban.max)
                .required(t("errors.commonErrors.required")),
            currency: Yup.number()
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

import { FC } from "react";
import { MainElementInfoSlideProps } from "../../../../../domain/components/slider-modal-window/MainElementInfoSlideProps";
import { CustomFile } from "../../../../../domain/models/CustomFile";
import MainImageSlide from "../../../../base/photo-slider/MainImageSlide";

const ShopPhotoSlide: FC<MainElementInfoSlideProps<CustomFile>> = (props) => {
    return (
        <MainImageSlide
            filePath={props.element?.filePath}
            imageClassName="shop__img"
            slideOnClick={props.openModal}
            wrapClassName="information__article-img"
        />
    );
};

export default ShopPhotoSlide;

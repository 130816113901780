import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";

export enum Currency {
    USD = 1,
    EUR = 2,
    PLN = 3
}

export function getCurrentCurrencyTranslation(t: Translate, currency: Currency): SelectProps {
    return { value: currency?.toString(), label: t(currency && `currency.${currency}`) };
}

export function getCurrencies(t: Translate): SelectProps[] {
    return [
        { value: Currency.USD.toString(), label: t(`currency.${Currency.USD}`) },
        { value: Currency.EUR.toString(), label: t(`currency.${Currency.EUR}`) },
        { value: Currency.PLN.toString(), label: t(`currency.${Currency.PLN}`) },
    ];
}

import { Article } from "../domain/models/Article";
import { ArticleDTO } from "../domain/models/dto/ArticleDTO";
import { ArticleService } from "../domain/services/ArticleService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { SortingField } from "../enums/sorting-field-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import articleMapperFactory from "../mappers/ArticleMapper";
import CollectionTools from "../tools/CollectionTools";
import { RemoveResponse } from "../types/RemoveResponse";
import { BaseServiceImpl } from "./BaseServiceImpl";

export class ArticleServiceImpl extends BaseServiceImpl implements ArticleService {
    async createRequestOrUpdateCurrentEntity(dto: ArticleDTO): Promise<Article> {
        if (!dto.header || !dto.body || !dto.templeId) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<ArticleDTO, Article>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.ARTICLE), articleMapperFactory
        );
    }

    getSortedArticleByCreateDate(sortingOrder: SortingOrder, articles: Article[]): Article[] {
        if (!articles) {
            return [];
        }

        return CollectionTools.sortEntitiesByLocaleCompare<Article>(articles, sortingOrder, SortingField.CREATED_AT);
    }

    async removeEntity(entity: Article): Promise<RemoveResponse> {
        return await this.removeEntityById<ArticleDTO, Article>(entity, this.getV2EntityUrl(EntitiesBaseUrls.ARTICLE), articleMapperFactory);
    }
}

export default function articleServiceFactory(): ArticleService {
    return new ArticleServiceImpl();
}

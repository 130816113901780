import { FC, useCallback, useRef, useState } from "react";
import { DEFAULT_OFFSET } from "../../../constants";
import { TempleWithCount } from "../../../domain/models/Temple";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import { TempleSortFields } from "../../../enums/temple-sort-fields-enum";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import { useSortingColumns } from "../../../tools/custom-hooks/table-hooks/useSortingColumns";
import AdminPagePagination from "../../base/AdminPagePagination";
import TempleHeader from "./TempleHeader";
import TempleTable from "./TempleTable";

interface TemplesProps {}

const Temples: FC<TemplesProps> = () => {
    const [temples, setTemples] = useState<TempleWithCount>();
    const templesTableRef = useRef<HTMLDivElement>(null);
    const columnsHandlers = useSortingColumns(SortingOrder.DESC);

    const paginationCallback = useCallback(
        async (currentPage: number, pageSize: number, changeContent: boolean) =>
            templeServiceFactory().getCachedTemples(
                currentPage,
                pageSize,
                DEFAULT_OFFSET,
                columnsHandlers.currentActiveColumn as TempleSortFields,
                columnsHandlers.sortingOrder,
                null,
                null,
                null,
                null,
                columnsHandlers.reloadCache || changeContent
            ),
        [columnsHandlers.currentActiveColumn, columnsHandlers.reloadCache, columnsHandlers.sortingOrder]
    );

    return (
        <>
            {temples && (
                <>
                    <TempleHeader totalRows={temples.totalRows} />
                    <div className="inner__content23">
                        <TempleTable
                            temples={temples.rows}
                            templesTableRef={templesTableRef}
                            sortingColumnsHandlers={columnsHandlers}
                        />
                    </div>
                </>
            )}

            <div className="church__pagination__admin__block">
                <AdminPagePagination
                    additionalPaginationClass="search__data__pagination"
                    pagesCount={temples?.totalPages}
                    wrapperRef={templesTableRef}
                    data={temples}
                    reloadCache={columnsHandlers.reloadCache}
                    setData={setTemples}
                    setReloadCache={columnsHandlers.setReloadCache}
                    serviceCallback={paginationCallback}
                />
            </div>
        </>
    );
};

export default Temples;

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../types/Translate";
import ModalWindow from "../../base/ModalWindow";

interface SuccessRegistrationModalProps {
    t: Translate;
    closeCallback: () => void;
}

const SuccessRegistrationModal: FC<SuccessRegistrationModalProps> = (props) => {
    return (
        <ModalWindow
            contentClass="modal-success__registration"
            bodyClass=" "
            closeCallback={props.closeCallback}
            content={<div className="modal__title">{props.t("register.successModal.info")}</div>}
        />
    );
};

export default withTranslation()(SuccessRegistrationModal);

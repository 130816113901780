import { FC } from "react";

interface PrevArrowSVGProps {
    additionalClass?: string;
    width?: number;
    height?: number;
    onclick?: () => void;
}

const PrevArrowSVG: FC<PrevArrowSVGProps> = (props) => {
    const width = props.width || 15;
    const height = props.height || 27;

    return (
        <svg
            className={"base__arrow__button " + (props.additionalClass || " ")}
            width={width}
            height={height}
            viewBox="0 0 30 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={props.onclick}
        >
            <path d="M29 1L2 28.5L29 56" stroke="#000" strokeWidth="4" strokeLinecap="round" />
        </svg>
    );
};

export default PrevArrowSVG;

import { FC } from "react";
import PayoutDetailsPaymentsTable from "./PayoutDetailsPaymentsTable";
import { Payment } from "../../../../../domain/models/Payment";

interface PayoutDetailsPaymentsProps {
    payoutId: string;
    templeId: string;
    setPaymentsCallback: (data: Payment[]) => void;
}

const PayoutDetailsPayments: FC<PayoutDetailsPaymentsProps> = (props) => {
    return <PayoutDetailsPaymentsTable setPaymentsCallback={props.setPaymentsCallback} payoutId={props.payoutId} templeId={props.templeId}/>;
};

export default PayoutDetailsPayments;

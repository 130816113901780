import { FC } from "react";

const PayoutResolveSVG: FC = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="24.000000pt"
            height="24.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#f0c34e" stroke="none">
                <path
                    d="M1001 4784 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3340 0 -3426
45 -173 178 -307 350 -352 51 -13 176 -15 883 -15 781 0 824 1 817 18 -67 176
-70 389 -5 562 37 100 893 1850 937 1918 96 144 274 248 461 268 l62 7 0 481
c0 395 -3 492 -15 539 -45 173 -178 307 -350 352 -86 22 -2710 22 -2794 -1z
m2285 -970 c53 -39 69 -71 69 -134 0 -63 -16 -95 -69 -134 -27 -21 -35 -21
-1024 -24 -681 -2 -1009 0 -1034 8 -101 29 -140 168 -71 251 52 61 -4 59 1095
56 999 -2 1007 -2 1034 -23z m-160 -640 c53 -39 69 -71 69 -134 0 -63 -16 -95
-69 -134 -27 -21 -36 -21 -944 -24 -625 -2 -929 0 -954 8 -101 29 -140 168
-71 251 52 61 3 59 1015 56 918 -2 927 -2 954 -23z m-320 -640 c53 -39 69 -71
69 -134 0 -63 -16 -95 -69 -134 -27 -21 -38 -21 -784 -24 -513 -2 -769 0 -794
8 -101 29 -140 168 -71 251 52 61 17 59 855 56 757 -2 767 -2 794 -23z m-320
-640 c53 -39 69 -71 69 -134 0 -63 -16 -95 -69 -134 -27 -21 -39 -21 -624 -24
-401 -2 -609 0 -634 8 -101 29 -140 168 -71 251 51 60 32 59 695 56 595 -2
607 -2 634 -23z"
                />
                <path
                    d="M4060 3074 c-63 -23 -121 -65 -153 -111 -14 -21 -225 -445 -467 -941
-314 -643 -445 -922 -456 -970 -25 -106 -15 -175 61 -395 72 -211 95 -247 167
-265 48 -12 84 -4 285 67 265 92 308 130 436 387 307 614 820 1682 829 1727
15 69 4 134 -35 210 -43 82 -87 113 -303 216 -162 77 -182 84 -248 88 -48 2
-85 -2 -116 -13z"
                />
            </g>
        </svg>
    );
};

export default PayoutResolveSVG;

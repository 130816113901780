import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../../domain/models/Temple";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import MainImageSlide from "../../../base/photo-slider/MainImageSlide";
import PhotoSlider from "../../../base/photo-slider/PhotoSlider";
import TextTruncateWrapper from "../../../base/TextTruncateWrapper";
import TempleAbbotPhoto from "./TempleAbbotPhoto";
import useWindowDimensions from "../../../../tools/custom-hooks/base-hooks/useWindowDimensions";
import {
    AVG_DESKTOP_WIDTH,
    MAX_DESKTOP_WIDTH,
    MIN_DESKTOP_WIDTH,
    PRE1_DESKTOP_WIDTH,
    UPPER_DESKTOP_WIDTH,
} from "../../../../constants";
import Popup from "reactjs-popup";
import FullScreenModal from "../../../base/full-screen-modal/FullScreenModal";
import { useFullScreenModal } from "../../../../tools/custom-hooks/modal-hooks/useFullScreenModal";

interface TempleAbbotProps {
    t(key?: string): string;
    temple: Temple;
}

const AMOUNT_OF_STRINGS_1500PX = 25;
const AMOUNT_OF_STRINGS_1200PX = 23;
const AMOUNT_OF_STRINGS_1024PX = 18;
const AMOUNT_OF_STRINGS_900PX = 23;
const AMOUNT_OF_STRINGS_767PX = 19;
const AMOUNT_OF_STRINGS_600PX = 5;

const TempleAbbot: FC<TempleAbbotProps> = (props) => {
    const sortedFiles = props.temple.getSortedFiles(SortingOrder.ASC, props.temple.abbotInfoFiles);
    const fullScreenModalHandlers = useFullScreenModal();

    const { width } = useWindowDimensions();
    const templeMainTextHeightDesktop =
        width < MIN_DESKTOP_WIDTH
            ? AMOUNT_OF_STRINGS_600PX
            : width < PRE1_DESKTOP_WIDTH
            ? AMOUNT_OF_STRINGS_767PX
            : width < AVG_DESKTOP_WIDTH
            ? AMOUNT_OF_STRINGS_900PX
            : width < UPPER_DESKTOP_WIDTH
            ? AMOUNT_OF_STRINGS_1024PX
            : width < MAX_DESKTOP_WIDTH
            ? AMOUNT_OF_STRINGS_1200PX
            : AMOUNT_OF_STRINGS_1500PX;

    return (
        <section id="section_2" className="prior--main">
            <div className="container--min">
                <h2 className="prior--main__title subtitle ">{props.t("user.temple.abbot.subtitle")}</h2>
                <div className="prior--main__inner">
                    <div className="prior--main__text">
                        <TextTruncateWrapper
                            text={props.temple.abbotInfo}
                            className="abbot-gradient text"
                            height={templeMainTextHeightDesktop}
                            blur={true}
                        />
                    </div>
                    <div className="prior--main__image">
                        <TempleAbbotPhoto
                            element={sortedFiles[fullScreenModalHandlers.activeImageIndex]}
                            openModal={() => fullScreenModalHandlers.openFullScreenModal(sortedFiles)}
                        />
                        <Popup
                            open={fullScreenModalHandlers.openedFullScreenModal}
                            modal
                            overlayStyle={{ zIndex: 999999 }}
                            onClose={fullScreenModalHandlers.closeFullScreenModal}
                            className="full-screen-modal__popup"
                        >
                            <FullScreenModal
                                photos={sortedFiles}
                                closeCallback={fullScreenModalHandlers.closeFullScreenModal}
                                activeIndex={fullScreenModalHandlers.activeImageIndex}
                                toPreviousPhoto={fullScreenModalHandlers.toPreviousPhoto}
                                toNextPhoto={fullScreenModalHandlers.toNextPhoto}
                            />
                        </Popup>
                        <div className="about-church__swiper">
                            <PhotoSlider
                                loop={false}
                                slidesPerView={4}
                                spaceBetween={15}
                                isArrowsDisable={true}
                                allowTouchMove={true}
                                content={sortedFiles.map((file, index) => (
                                    <MainImageSlide
                                        filePath={file.filePath}
                                        slideOnClick={() => fullScreenModalHandlers.setActiveImageIndex(index)}
                                        swiperImageClassName="abbot__image__swiper-img"
                                    />
                                ))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(TempleAbbot);

import { FormikProps } from "formik";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

interface ContextFormStatus {
    id: string;
    value: boolean;
}

interface IFormikFormsContext {
    forms: ContextFormStatus[];
    isFormsDataValid: boolean;
    updateForms?: (form: ContextFormStatus) => void;
    resetForms?: () => void;
}

const FormikFormsProvider = (props: any) => {
    const [forms, setForms] = useState<ContextFormStatus[]>([]);

    const updateForms = useCallback((form: ContextFormStatus) => {
        setForms((prevForms) => {
            const formIndex = prevForms.findIndex((prevForm) => prevForm.id === form.id);
            if (formIndex !== -1) {
                return prevForms.map((item, index) => (index === formIndex ? form : item));
            } else {
                return [...prevForms, form];
            }
        });
    }, []);

    const resetForms = () => {
        setForms([]);
    };

    const isFormsDataValid = useMemo(() => forms.every((item) => item.value), [forms]);

    return <FormikFormsContext.Provider value={{ forms, isFormsDataValid, updateForms, resetForms }} {...props} />;
};

const FormikFormsContext = createContext<IFormikFormsContext>({
    forms: [],
    isFormsDataValid: false,
});

const useFormikFormsContext = () => useContext(FormikFormsContext);

const useFormsValidConfirmation = (formId: string, formikForm: FormikProps<any>) => {
    const { isFormsDataValid, updateForms, resetForms } = useFormikFormsContext();

    useEffect(() => {
        resetForms();
    }, []);

    useEffect(() => {
        const valuesExist = Object.keys(formikForm.values).some((key) => !!formikForm.values[key]);
        updateForms({
            id: formId,
            value: valuesExist ? formikForm.isValid : true,
        });
    }, [updateForms, formId, formikForm.isValid, formikForm.values]);

    return { isFormsDataValid };
}

export { FormikFormsProvider, useFormikFormsContext, useFormsValidConfirmation };

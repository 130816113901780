import { FormikProps, useFormik } from "formik";
import { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { DISABLED_COLOR } from "../../../../constants";
import { useFormsValidConfirmation } from "../../../../context/FormikFormsProvider";
import { BankAccount, BankAccountForm as IBankAccountForm } from "../../../../domain/models/BankAccount";
import { SelectProps } from "../../../../domain/models/SelectProps";
import { Temple } from "../../../../domain/models/Temple";
import { getCurrencies, getCurrentCurrencyTranslation } from "../../../../enums/currency-enum";
import bankAccountServiceFactory from "../../../../services/BankAccountServiceImpl";
import { getValidationClass } from "../../../../tools/Tools";
import { Translate } from "../../../../types/Translate";
import { BankAccountSchemaFactory } from "../../../../validation-schemas/temple-register/bank-account-schemas";
import DropDown from "../../../base/DropDown";
import ChangeEntityButtons from "../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../base/InputRestrictionsInfo";
import AddButtonSVG from "../../../base/svg/AddButtonSVG";
import ConflictErrorModal from "../../../base/conflict-error-modal/ConflictErrorModal";

interface BankDetailFormProps {
    t: Translate;
    bankAccount: BankAccount;
    temple: Temple;

    setIsEditing(value: boolean): void;

    isAllowSetValues: boolean;
    setIsAllowSetValues(value: boolean): void;

    isSubmitting: boolean;
    setIsSubmitting(value: boolean): void;

    updateBankAccounts(newBankAccount: BankAccount): void;
    resetBankAccountForm(): void;

    errorVisible: string;
    setErrorVisibility(value: string): void;

    setBankAccountFormRef(value: MutableRefObject<any>): void;
}

const BankDetailForm: FC<BankDetailFormProps> = (props) => {
    const bankAccountService = bankAccountServiceFactory();

    const [currency, setCurrency] = useState<SelectProps>(
        getCurrentCurrencyTranslation(props.t, props.bankAccount.currency)
    );

    const formRef = useRef(null);

    const nullableValues: IBankAccountForm = {
        id: "",
        order: null,
        fullName: "",
        accountId: "",
        iban: "",
        currency: null,
    };

    const bankAccountSchema = useMemo(() => BankAccountSchemaFactory({ t: props.t }), [props.t]);

    const bankAccountForm: FormikProps<IBankAccountForm> = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            order: undefined,
            fullName: props.bankAccount?.fullName,
            accountId: props.bankAccount?.accountId,
            iban: props.bankAccount?.iban,
            currency: props.bankAccount?.currency,
        },
        validationSchema: bankAccountSchema.schema,
        onSubmit: async (values) => {
            try {
                props.setErrorVisibility(null);

                const newBankAccount: BankAccount | undefined =
                    await bankAccountService.createRequestOrUpdateCurrentEntity({
                        templeId: props.temple.originId,
                        order: values.order || props.temple.getNextBankAccountOrder(),
                        id: values.id || undefined,
                        accountId: values.accountId,
                        fullName: values.fullName,
                        iban: values.iban,
                        currency: values.currency,
                    });

                if (!newBankAccount) {
                    return props.setErrorVisibility("errors.common");
                }

                if (newBankAccount) {
                    props.updateBankAccounts(newBankAccount);

                    props.setIsEditing(false);
                    props.setIsSubmitting(true);
                }
            } catch {
                props.setErrorVisibility("errors.common");
            }
        },
    });

    useFormsValidConfirmation("bankAccountForm", bankAccountForm);

    useEffect(() => {
        if (props.isAllowSetValues) {
            bankAccountForm.setValues({
                id: props.bankAccount.id,
                order: props.bankAccount.order,
                fullName: props.bankAccount.fullName,
                accountId: props.bankAccount.accountId,
                iban: props.bankAccount.iban,
                currency: props.bankAccount.currency,
            });

            setCurrency(getCurrentCurrencyTranslation(props.t, props.bankAccount.currency));
            props.setIsAllowSetValues(false);
        }

        if (formRef) {
            props.setBankAccountFormRef(formRef);
        }

        if (props.isSubmitting) {
            bankAccountForm.setValues({
                id: "",
                order: null,
                fullName: "",
                accountId: "",
                iban: "",
                currency: null,
            });
            bankAccountForm.setTouched({});

            setCurrency(null);

            props.setIsSubmitting(false);
        }
    }, [props, formRef, bankAccountForm]);

    const editBankAccountFormHandler = () => {
        bankAccountForm.setValues({
            ...bankAccountForm.values,
            id: props.bankAccount?.id,
            order: props.bankAccount.order || props.temple.getNextBankAccountOrder(),
        });
        bankAccountForm.handleSubmit();
    };

    const bankCurrencyHandleChange = (newCurrency: SelectProps) => {
        if (currency === newCurrency) {
            return;
        }

        bankAccountForm.setFieldValue("currency", parseInt(newCurrency.value));
        setCurrency(newCurrency);
    };

    const clearFieldsCallback = useCallback(() => {
        setCurrency(null);
    }, []);

    const resetBankAccountForm = useCallback(() => {
        props.resetBankAccountForm();
        props.setIsEditing(false);
    }, [props]);

    return (
        <div ref={formRef} className="payments choose__temple__pagination">
            <div className="date__form-block">
                <input
                    className={`date__input ${getValidationClass(bankAccountForm, "fullName")}`}
                    name="fullName"
                    onChange={bankAccountForm.handleChange}
                    value={bankAccountForm.values.fullName}
                    type="text"
                    placeholder={props.t("templeRegister.bank.fullName")}
                    maxLength={bankAccountSchema.inputRestrictions.fullName.max}
                />
                <InputRestrictionsInfo
                    min={bankAccountSchema.inputRestrictions.fullName.min}
                    max={bankAccountSchema.inputRestrictions.fullName.max}
                    error={bankAccountForm.errors.fullName}
                />
            </div>

            <div className="date__form-block">
                <input
                    className={`date__input ${getValidationClass(bankAccountForm, "iban")}`}
                    name="iban"
                    onChange={bankAccountForm.handleChange}
                    value={bankAccountForm.values.iban}
                    type="text"
                    placeholder="IBAN"
                    maxLength={bankAccountSchema.inputRestrictions.iban.max}
                />
                <InputRestrictionsInfo
                    min={bankAccountSchema.inputRestrictions.iban.min}
                    max={bankAccountSchema.inputRestrictions.iban.max}
                    error={bankAccountForm.errors.iban}
                />
            </div>

            <div className="date__form-block  date__form-wrap">
                <div className="date__form-block">
                    <input
                        className={`date__input ${getValidationClass(bankAccountForm, "accountId")}`}
                        name="accountId"
                        onChange={bankAccountForm.handleChange}
                        value={bankAccountForm.values.accountId}
                        type="text"
                        placeholder={props.t("templeRegister.bank.account")}
                        maxLength={bankAccountSchema.inputRestrictions.accountId.max}
                    />
                    <InputRestrictionsInfo
                        min={bankAccountSchema.inputRestrictions.accountId.min}
                        max={bankAccountSchema.inputRestrictions.accountId.max}
                        error={bankAccountForm.errors.accountId}
                    />
                </div>

                <div className="date__form-block">
                    <DropDown
                        name="currency"
                        value={currency}
                        options={getCurrencies(props.t)}
                        onChange={bankCurrencyHandleChange}
                        isError={bankAccountForm.touched.currency && !!bankAccountForm.errors.currency}
                        placeholder={props.t("templeRegister.bank.currency")}
                    />
                    <p>{props.t("errors.currencyErrors.onlyUSD")}</p>
                </div>
            </div>

            {props.errorVisible && <p className="input-restrictions-info__error">{props.t(props.errorVisible)}</p>}

            <div className="date__bottom-buttons">
                <ChangeEntityButtons<IBankAccountForm>
                    formik={bankAccountForm}
                    initialValues={nullableValues}
                    disableSaveButton={!props.bankAccount.id}
                    editFormHandler={editBankAccountFormHandler}
                    resetEntityFormHandler={resetBankAccountForm}
                    clearFieldsCallback={clearFieldsCallback}
                />

                {!props.bankAccount.id && (
                    <button
                        className={
                            !bankAccountForm.isValid
                                ? "disabled-add-button worship__button-add"
                                : "date__button-add worship__button-add"
                        }
                        type="button"
                        disabled={bankAccountForm.isSubmitting}
                        onClick={() => bankAccountForm.handleSubmit()}
                    >
                        <span>
                            <AddButtonSVG fill={!bankAccountForm.isValid && DISABLED_COLOR} />
                        </span>
                        {props.t("templeRegister.bank.add")}
                    </button>
                )}
            </div>
            <ConflictErrorModal />
        </div>
    );
};

export default withTranslation()(BankDetailForm);

import moment from "moment";
import { Mapper } from "../domain/mappers/mapper";
import { PaymentDTO } from "../domain/models/dto/PaymentDTO";
import { Payment } from "../domain/models/Payment";
import PaymentModel from "../models/PaymentModel";
import { SimpleMapper } from "./SimpleMapper";
import { fixedToFloatAmountStr } from "../tools/Tools";

class PaymentMapper extends SimpleMapper<Payment, PaymentDTO> {
    protected entityConstructor = PaymentModel;

    protected fromDTOFields: string[] = [
        "id",
        "payoutId",
        "templeId",
        "prayerId",
        "details",
        "conditionsAccepted",
        "orderDetails",

        "status",
        "transactionType",
        "paymentMethod",
        "paymentId",
        "adminId",

        "recipientPaymentAccountId",

        "senderName",
        "senderAccountId",
        "senderEmail",

        "paymentDate",
        "confirmationDate",

        "amount",
        "recipientAmount",
        "platformAmount",

        "initialRecipientBalance",
        "finalRecipientBalance",
        "initialPlatformBalance",
        "finalPlatformBalance",

        "createdAt",
        "updatedAt",
    ];

    fromDTO(dto: PaymentDTO) {
        const model = super.fromDTO(dto);

        model.amountFloatFormat = fixedToFloatAmountStr(model.amount);
        model.recipientAmountFloatFormat = fixedToFloatAmountStr(model.recipientAmount);
        model.platformAmountFloatFormat = fixedToFloatAmountStr(model.platformAmount);
        model.initialRecipientBalanceFloatFormat = fixedToFloatAmountStr(model.initialRecipientBalance);
        model.initialPlatformBalanceFloatFormat = fixedToFloatAmountStr(model.initialPlatformBalance);
        model.finalRecipientBalanceFloatFormat = fixedToFloatAmountStr(model.finalRecipientBalance);
        model.finalPlatformBalanceFloatFormat = fixedToFloatAmountStr(model.finalPlatformBalance);

        model.paymentDate = model.paymentDate ? moment(model.paymentDate) : model.paymentDate;
        model.confirmationDate = model.confirmationDate ? moment(model.confirmationDate) : model.confirmationDate;

        return model;
    }
}


export default function paymentMapperFactory(): Mapper<Payment, PaymentDTO> {
    return new PaymentMapper()
}

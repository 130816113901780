import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { Temple } from "../../../../domain/models/Temple";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import TempleRegisterCancelModal from "./TempleRegisterCancelModal";

interface FooterProps {
    t(key?: string): string;

    isButton?: boolean;
    redirectTo?: string;

    currentPath?: TempleRegisterPage;
    temple?: Temple;
    setErrorVisibility?(value: string): void;

    isFormsDataValid: boolean;
}

const Footer: FC<FooterProps> = (props) => {
    const navigate = useNavigate();

    const [isCancelModalVisibility, setIsCancelModalVisibility] = useState<boolean>(false);

    const footerButtonHandler = () => {
        if (
            props.currentPath === TempleRegisterPage.PAYMENT_DETAILS &&
            !props.temple?.isPaymentsDetailsComplete &&
            props.setErrorVisibility
        ) {
            return props.setErrorVisibility("templeRegister.required.paymentDetails");
        }

        if (
            props.currentPath === TempleRegisterPage.BANK_DETAILS &&
            !props.temple?.isBankDetailsComplete &&
            props.setErrorVisibility
        ) {
            return props.setErrorVisibility("templeRegister.required.bankDetails");
        }

        if (props.isButton) {
            navigate(props.redirectTo);
        }

        window.scrollTo(0, 0);
    };

    return (
        <footer className="date__footer">
            <button
                onClick={() => setIsCancelModalVisibility(true)}
                className="cancel-button"
                type="button"
            >
                {props.t("templeRegister.footer.cancelButton")}
            </button>

            <button
                onClick={() => footerButtonHandler()}
                className={!props.isFormsDataValid ? "disabled-general-save-button" : "save-button"}
                type={props.isButton ? "button" : "submit"}
                id="nextBtn"
                disabled={!props.isFormsDataValid}
            >
                {props.t("templeRegister.footer.button")}
            </button>

            <Popup open={isCancelModalVisibility} modal overlayStyle={{ zIndex: 999999 }} onClose={() => setIsCancelModalVisibility(false)}>
                <TempleRegisterCancelModal setIsCancelModalVisibility={setIsCancelModalVisibility} />
            </Popup>
        </footer>
    );
};

export default withTranslation()(Footer);

import { BankAccount } from "../domain/models/BankAccount";
import { BankAccountDTO } from "../domain/models/dto/BankAccountDTO";
import { Currency } from "../enums/currency-enum";
import { BaseRelatedOrderedEntityMixinFactory } from "./BaseTempleRelatedModel";

class BankAccountModel extends BaseRelatedOrderedEntityMixinFactory<BankAccountDTO>() implements BankAccount {
    order: number;
    templeId?: string;
    fullName?: string;
    iban?: string;
    accountId?: string;
    currency?: Currency;

    constructor(data?: BankAccountDTO) {
        super();
        Object.assign(this, data || {});
    }
}


export default BankAccountModel

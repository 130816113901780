export enum EntitiesBaseUrls {
    TEMPLE = "temple",
    ARTICLE = "article",
    ARTIFACT = "artifact",
    BANK_ACCOUNT = "bank-account",
    PAYMENT_ACCOUNT = "payment-account",
    PRAYER = "prayer",
    PRAYER_PRICE_DETAIL = "prayer-price-detail",
    PRAYER_REQUIREMENT = "prayer-requirement",
    WEEK_SCHEDULE = "week-schedule",
    WORSHIP_SCHEDULE = "worship-schedule",
    FILE = "file",
    PAYMENTS = "payments",
    TEMPLE_EXTENSION = "temple-extension",
    TEMPLE_STATISTIC = "temple-statistic",
    PAYOUT = "payout",
}

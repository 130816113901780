import { KeyboardEvent } from "react";
import { KEYS } from "../../../types/base-enums/keys-enum";

interface IUseKeyDownEvent {
    handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export const useKeyDownEvent = (callback: () => void, key: KEYS = KEYS.ENTER): IUseKeyDownEvent => {
    return {
        handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === key) {
                callback();
            }
        }
    };
};

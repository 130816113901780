import { apiURL } from "../Config";
import { NewUserDTO } from "../domain/models/dto/NewUserDTO";
import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import { UserService } from "../domain/services/UserService";
import UserMapperFactory from "../mappers/UserMapper";
import { get, patch, post } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";
import languageServiceFactory from "./LanguageServiceImpl";

export class UserServiceImpl extends BaseServiceImpl implements UserService {

    static _instance: UserService;
    currentUser?: User;

    private constructor() {
        super();
    }

    static getInstance(): UserService {
        if (!UserServiceImpl._instance) {
            UserServiceImpl._instance = new UserServiceImpl();
        }
        return UserServiceImpl._instance;
    }

    async getUser(): Promise<User | undefined> {
        if (!this.currentUser) {
            this.currentUser = await this.getCurrentUser();
        }
        return this.currentUser;
    }

    async login(email: string, password: string): Promise<User | undefined> {
        if (!email || !password) {
            return;
        }

        this.currentUser = UserMapperFactory().fromDTO(this.getData<UserDTO>(await post(`${apiURL}/api/v1/login`, { email, password })));
        return this.currentUser;
    }

    async register(user: NewUserDTO): Promise<User | undefined> {
        if (!user.email || !user.password || !user.confirmPassword || !user.firstName || !user.lastName || !user.phone || !user.templeName) {
            return;
        }
        const langService = languageServiceFactory();
        return UserMapperFactory().fromDTO(this.getData<UserDTO>(await post(`${apiURL}/api/v1/user`, {
            email: user.email,
            password: user.password,
            confirmPassword: user.confirmPassword,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            templeName: user.templeName,
            language: langService.currentLang,
        })));
    }

    async logout(): Promise<void> {
        return this.getData(await get(`${apiURL}/api/v1/logout`));
    }

    async getCurrentUser(): Promise<User | undefined> {
        try {
            return UserMapperFactory()
                .fromDTO(
                    this.getData<UserDTO>(await get(`${apiURL}/api/v1/user`))
                );
        } catch (err) {
            console.log('UserServiceImpl.getCurrentUser => ERROR:');
            console.log(err);
        }
    }

    async updateUser(templeName: string): Promise<User> {
        try {
            return UserMapperFactory()
                .fromDTO(
                    this.getData<UserDTO>(await patch(`${apiURL}/api/v1/user`, {templeName}))
                );
        } catch (err) {
            console.log('UserServiceImpl.updateUser => ERROR:');
            console.log(err);
        }
    }

    async confirmRegistration(registrationToken: string): Promise<boolean> {
        try {
            return !!(await post(`${apiURL}/api/v1/registration-confirmation`, { registrationToken })).data;
        } catch (err) {
            console.log("UserServiceImpl.confirmRegistration => ERROR:");
            console.log(err);
        }
    }
}

export function userServiceFactory(): UserService {
    return UserServiceImpl.getInstance();
}

const closeBurgerMenu = () => {
    const burgerOverlay = document.querySelector(".menu-overlay");
    const burgerCtr = document.querySelector("#burger-menu");
    const menuCtr = document.querySelector("#mobile-menu");
    if (burgerCtr) {
        burgerCtr.classList.remove("active");
        menuCtr.classList.remove("show_mobile_menu"); // то закрываем окно навигации, удаляя активный класс
        burgerOverlay.classList.remove("active");
    }
};

window.addEventListener("click", (e) => {
    const target = e.target as any;
    if (!target.closest("#mobile-menu") && !target.closest("#burger-menu")) {
        closeBurgerMenu();
    }
});

export { closeBurgerMenu };

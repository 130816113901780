import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomerSupportTicket } from "../../../domain/models/CustomerSupportTicket";
import { t } from "i18next";
import CustomCheckbox from "../../base/CustomCheckbox";

interface CustomerSupportTicketRowProps {
    t(key?: string): string;
    customerSupportTicket: CustomerSupportTicket;
}

const CustomerSupportTicketRow: FC<CustomerSupportTicketRowProps> = (props) => {
    const navigate = useNavigate();

    const navigateToPaymentDetails = () => {
        return navigate(`/admin/customer-support-tickets/${props.customerSupportTicket.id}`);
    };

    return (
        <tr onClick={() => navigateToPaymentDetails()}>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">{props.customerSupportTicket.ticketName}</span>
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">
                    {t(`enums.supportTicketSubject.${props.customerSupportTicket.subjectType}`)}
                </span>
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">{props.customerSupportTicket.fullName}</span>
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2">{props.customerSupportTicket.email}</span>
            </td>
            <td className="td-style--min td-padding3 date">
                <CustomCheckbox
                    defaultValue={false}
                    value={props.customerSupportTicket.isEmailRegistered}
                    className="checkbox__input"
                    additionalLabelClassName="date__text"
                    wrapperClassName="order__checkbox simple-checkbox"
                />
            </td>
            <td className="td-style--min td-padding2">
                <span
                    className="td-inner--min2"
                    title={t(`enums.supportTicketCustomerRole.${props.customerSupportTicket.customerRole}`)}
                >
                    {t(`enums.supportTicketCustomerRole.${props.customerSupportTicket.customerRole}`)}
                </span>
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2" title={t(`enums.recordStatus.${props.customerSupportTicket.status}`)}>
                    {t(`enums.recordStatus.${props.customerSupportTicket.status}`)}
                </span>
            </td>
            <td className="td-style--min td-padding3 date">
                <CustomCheckbox
                    defaultValue={false}
                    value={props.customerSupportTicket.emailSent}
                    className="checkbox__input"
                    additionalLabelClassName="date__text"
                    wrapperClassName="order__checkbox simple-checkbox"
                />
            </td>
            <td className="td-style--min td-padding4 date">
                <CustomCheckbox
                    defaultValue={false}
                    value={props.customerSupportTicket.isCustomerRegistered}
                    className="checkbox__input"
                    additionalLabelClassName="date__text"
                    wrapperClassName="order__checkbox simple-checkbox"
                />
            </td>
            <td className="td-style--min td-padding2">
                <span className="td-inner--min2" title={props.customerSupportTicket.createdAtStr}>
                    {props.customerSupportTicket.createdAtStr}
                </span>
            </td>
        </tr>
    );
};

export default withTranslation()(CustomerSupportTicketRow);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Temple } from "../../../../domain/models/Temple";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import religionsServiceFactory from "../../../../services/ReligionsServiceImpl";
import MainImageSlide from "../../../base/photo-slider/MainImageSlide";
import PhotoSlider from "../../../base/photo-slider/PhotoSlider";
import TextTruncateWrapper from "../../../base/TextTruncateWrapper";
import TempleMainPhoto from "./TempleMainPhoto";
import useWindowDimensions from "../../../../tools/custom-hooks/base-hooks/useWindowDimensions";
import { MAX_DESKTOP_WIDTH } from "../../../../constants";
import FullScreenModal from "../../../base/full-screen-modal/FullScreenModal";
import Popup from "reactjs-popup";
import { useFullScreenModal } from "../../../../tools/custom-hooks/modal-hooks/useFullScreenModal";

interface TempleMainProps {
    t(key?: string): string;
    temple: Temple;
}

const AMOUNT_OF_STRINGS_1500PX = 11;
const AMOUNT_OF_STRINGS_1200PX = 5;
const AMOUNT_OF_STRINGS_1024PX = 8;

const TempleMain: FC<TempleMainProps> = (props) => {
    const navigate = useNavigate();
    const religionService = religionsServiceFactory();

    const sortedFiles = props.temple?.getSortedFiles(SortingOrder.ASC);
    const fullScreenModalHandlers = useFullScreenModal();

    const { width } = useWindowDimensions();
    const templeMainTextHeightDesktop = width < MAX_DESKTOP_WIDTH ? AMOUNT_OF_STRINGS_1200PX : AMOUNT_OF_STRINGS_1500PX;
    return (
        <section id="section_1" className="about-church">
            <div className="container--min">
                <div className="about-church__inner">
                    <div className="about-church__left">
                        <h1 className="about-church__title">{props.temple.name}</h1>
                        <TempleMainPhoto
                            element={sortedFiles[fullScreenModalHandlers.activeImageIndex]}
                            openModal={() => fullScreenModalHandlers.openFullScreenModal(sortedFiles)}
                        />
                        <Popup
                            open={fullScreenModalHandlers.openedFullScreenModal}
                            modal
                            overlayStyle={{ zIndex: 999999 }}
                            onClose={fullScreenModalHandlers.closeFullScreenModal}
                            className="full-screen-modal__popup"
                        >
                            <FullScreenModal
                                photos={sortedFiles}
                                closeCallback={fullScreenModalHandlers.closeFullScreenModal}
                                activeIndex={fullScreenModalHandlers.activeImageIndex}
                                toPreviousPhoto={fullScreenModalHandlers.toPreviousPhoto}
                                toNextPhoto={fullScreenModalHandlers.toNextPhoto}
                            />
                        </Popup>
                        <div className="about-church__swiper">
                            <PhotoSlider
                                loop={false}
                                slidesPerView={4}
                                spaceBetween={15}
                                isArrowsDisable={true}
                                allowTouchMove={true}
                                content={sortedFiles.map((file, index) => (
                                    <MainImageSlide
                                        filePath={file.filePath}
                                        slideOnClick={() => fullScreenModalHandlers.setActiveImageIndex(index)}
                                        swiperImageClassName="about-church__swiper-img"
                                    />
                                ))}
                            />
                        </div>
                    </div>
                    <div className="about-church__right">
                        <h1 className="about-church__title"> {props.temple.name} </h1>
                        <div className="about-church__descriptions">
                            <div className="about-church__description">
                                <span className="about-church__type">{props.t("user.temple.main.location")}</span>
                                <span className="about-church__text">
                                    {props.temple.country}, {props.temple.city}
                                </span>
                            </div>
                            <div className="about-church__description">
                                <span className="about-church__type">{props.t("user.temple.main.religion")}</span>
                                <span className="about-church__text">
                                    {props.t(
                                        "religions." + religionService.getReligion(props.temple.religionId)?.label
                                    )}
                                </span>
                            </div>
                            <div className="about-church__description">
                                <span className="about-church__type">{props.t("user.temple.main.current")}</span>
                                <span className="about-church__text">
                                    {props.t(
                                        "religionCurrents." +
                                            religionService.getReligionCurrent(props.temple.religionCurrentId)?.label
                                    )}
                                </span>
                            </div>
                            <div className="about-church__description">
                                <span className="about-church__type">{props.t("user.temple.main.subspecies")}</span>
                                <span className="about-church__text">
                                    {props.t(
                                        "religionSubspecies." +
                                            religionService.getReligionCurrentSubspecies(
                                                props.temple.religionSubspeciesId
                                            )?.label
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="about-church__history">
                            <TextTruncateWrapper
                                text={props.temple.info}
                                className="main-gradient about_temple_info text"
                                height={templeMainTextHeightDesktop}
                                blur={true}
                            />
                        </div>
                        <div className="about-church__buttons">
                            <a
                                className="about-church__button button"
                                onClick={() => navigate(`/temple/${props.temple?.id}/support`)}
                            >
                                {props.t("user.temple.main.supportButton")}
                            </a>
                            <a
                                className="about-church__button button"
                                onClick={() => navigate(`/temple/${props.temple?.id}/order-prayer`)}
                            >
                                {props.t("user.temple.main.orderPrayerButton")}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="about-church__history">
                    <TextTruncateWrapper
                        text={props.temple.info}
                        className="main-gradient about_temple_info text"
                        height={AMOUNT_OF_STRINGS_1024PX}
                        blur={true}
                    />
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(TempleMain);

import { FC } from "react";
import { withTranslation } from "react-i18next";

interface SupportHeaderProps {
    t(key?: string): string;
    isTempleSupport: boolean;
    templeName: string;
}

const SupportHeader: FC<SupportHeaderProps> = (props) => {
    return (
        <>
            <h1 className="order-payer__title">
                {props.isTempleSupport ? props.templeName : props.t("user.support.project.title")}
            </h1>
            {props.isTempleSupport && <h2 className="order-payer__subtitle">{props.t("user.support.temple.title")}</h2>}

            <div className="order-payer__text">
                {props.isTempleSupport ? props.t("user.support.temple.text") : props.t("user.support.project.text")}
            </div>
        </>
    );
};

export default withTranslation()(SupportHeader);

import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import { FC, useCallback, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormsValidConfirmation } from "../../../../context/FormikFormsProvider";
import { Temple, TempleSupportForm } from "../../../../domain/models/Temple";
import { CreateOrUpdateTemplePath } from "../../../../enums/create-or-update-temple-path-enum";
import { CreateTempleParams } from "../../../../enums/create-temple-params-enum";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import templeMapperFactory from "../../../../mappers/TempleMapper";
import templeServiceFactory from "../../../../services/TempleServiceImpl";
import { useRedirectToPrevCreateTemplePage } from "../../../../tools/custom-hooks/useRedirect";
import { getValidationClass } from "../../../../tools/Tools";
import { TempleSupportSchemaFactory } from "../../../../validation-schemas/temple-register/temple-support-schemas";
import CustomCheckbox from "../../../base/CustomCheckbox";
import ChangeEntityButtons from "../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../base/InputRestrictionsInfo";
import Footer from "../footer/Footer";
import MainRequirementComponent from "./prayer-requirement/MainRequirementComponent";
import MainPrayerComponent from "./prayer/MainPrayerComponent";
import ConflictErrorModal from "../../../base/conflict-error-modal/ConflictErrorModal";

interface OrdersProps {
    t(key?: string): string;
    temple: Temple;
    setTemple(value: Temple): void;
}

const Orders: FC<OrdersProps> = (props) => {
    const templeService = templeServiceFactory();
    const navigate = useNavigate();
    const [errorVisible, setErrorVisibility] = useState<string>(null);

    const resetErrorCallback = useCallback(() => {
        setErrorVisibility(null);
    }, []);

    useRedirectToPrevCreateTemplePage(
        props.temple,
        TempleRegisterPage.VISITORS_INFO,
        props.temple.isVisitorsInfoComplete
    );

    const initialValues = {
        templeSupportInfo: props.temple.templeSupportInfo,
        useStandardTempleSupportInfo: props.temple.useStandardTempleSupportInfo,
        prayersInfo: props.temple.prayersInfo,
        useStandardPrayersInfo: props.temple.useStandardPrayersInfo,
        templeSupportEmail: props.temple.templeSupportEmail,
        useStandardTempleSupportEmail: props.temple.useStandardTempleSupportEmail,
        prayersEmail: props.temple.prayersEmail,
        useStandardPrayersEmail: props.temple.useStandardPrayersEmail,
    };

    const templeSupportSchema = useMemo(() => TempleSupportSchemaFactory({ t: props.t }), [props.t]);

    const templeSupportForm: FormikProps<TempleSupportForm> = useFormik({
        enableReinitialize: false,
        initialValues: initialValues,
        validationSchema: templeSupportSchema.schema,
        onSubmit: async (values) => {
            const mapper = templeMapperFactory();
            const temple = props.temple;
            const nextUrl = "/create-temple/payment-details".concat(`?${CreateTempleParams.ID}=${temple.originId}`);

            try {
                setErrorVisibility(null);

                if (_.isEqual(values, templeSupportForm.initialValues) && temple.isOrdersComplete) {
                    navigate(nextUrl);
                    return;
                }

                temple.setTempleSupportInfo(values.templeSupportInfo);
                temple.setUseStandardTempleSupportInfo(values.useStandardTempleSupportInfo);
                temple.setPrayersInfo(values.prayersInfo);
                temple.setUseStandardPrayersInfo(values.useStandardPrayersInfo);

                temple.setTempleSupportEmail(values.templeSupportEmail);
                temple.setUseStandardTempleSupportEmail(values.useStandardTempleSupportEmail);
                temple.setPrayersEmail(values.prayersEmail);
                temple.setUseStandardPrayersEmail(values.useStandardPrayersEmail);

                if (!temple.isOrdersComplete) {
                    temple.resetRequiredOrdersFields();
                    return setErrorVisibility("templeRegister.required.prayers");
                }

                const newTemple: Temple | undefined = await templeService.createRequestOrUpdateCurrentEntity(
                    mapper.toLatestTempleDTO(temple),
                    CreateOrUpdateTemplePath.ORDERS_INFO
                );

                if (!newTemple) {
                    return setErrorVisibility("errors.common");
                }

                if (newTemple) {
                    templeService.notifyListeners(temple);
                    props.setTemple(temple);

                    navigate(nextUrl);
                }
            } catch (err) {
                setErrorVisibility("errors.common");
            }
        },
    });

    const { isFormsDataValid } = useFormsValidConfirmation("templeSupportForm", templeSupportForm);

    const updateUseStandardTempleSupportInfo = () => {
        templeSupportForm.setFieldValue(
            "useStandardTempleSupportInfo",
            !templeSupportForm.values.useStandardTempleSupportInfo
        );
    };

    const updateUseStandardPrayersInfo = () => {
        templeSupportForm.setFieldValue("useStandardPrayersInfo", !templeSupportForm.values.useStandardPrayersInfo);
    };

    const updateUseStandardPrayersEmail = () => {
        templeSupportForm.setFieldValue(
            "useStandardPrayersEmail",
            !templeSupportForm.values.useStandardPrayersEmail
        );
    };

    const updateUseStandardTempleSupportEmail = () => {
        templeSupportForm.setFieldValue("useStandardTempleSupportEmail", !templeSupportForm.values.useStandardTempleSupportEmail);
    };

    return (
        <div className="date__content">
            <form onSubmit={templeSupportForm.handleSubmit}>
                <main className="date__main">
                    <h1 className="date__title">{props.t("templeRegister.orders.title")}</h1>

                    <div>
                        <h2 className="date__subtitle ">{props.t("templeRegister.orders.subtitle")}</h2>

                        {errorVisible && <p className="input-restrictions-info__error">{props.t(errorVisible)}</p>}

                        <div className="date__form-block">
                            <textarea
                                className={`order__textarea date__input ${getValidationClass(
                                    templeSupportForm,
                                    "prayersInfo"
                                )}`}
                                name="prayersInfo"
                                onChange={templeSupportForm.handleChange}
                                value={templeSupportForm.values.prayersInfo}
                                disabled={!!templeSupportForm.values.useStandardPrayersInfo}
                                cols={30}
                                rows={10}
                                placeholder={props.t("templeRegister.orders.textPlaceholder")}
                                maxLength={templeSupportSchema.inputRestrictions.prayersInfo.max}
                            ></textarea>
                            <InputRestrictionsInfo
                                min={templeSupportSchema.inputRestrictions.prayersInfo.min}
                                max={templeSupportSchema.inputRestrictions.prayersInfo.max}
                                error={templeSupportForm.errors.prayersInfo}
                            />

                            <CustomCheckbox
                                id="orderAgreement2"
                                defaultValue={props.temple.useStandardPrayersInfo}
                                value={templeSupportForm.values.useStandardPrayersInfo}
                                name="useStandardPrayersInfo"
                                className="checkbox__input"
                                onChange={updateUseStandardPrayersInfo}
                                labelContent={<>{props.t("templeRegister.orders.useStandardText")}</>}
                                additionalLabelClassName="date__text"
                            />

                            <ChangeEntityButtons<TempleSupportForm>
                                formik={templeSupportForm}
                                initialValues={initialValues}
                                disableSaveButton={true}
                            />
                        </div>

                        <MainPrayerComponent temple={props.temple} resetErrorCallback={resetErrorCallback} />
                        <MainRequirementComponent temple={props.temple} resetErrorCallback={resetErrorCallback} />

                        <div className="date__form-block--nomg">
                            <h2 className="date__subtitle date__subtitle--support">
                                {props.t("templeRegister.orders.subtitle3")}
                            </h2>

                            {errorVisible && <p>{props.t(errorVisible)}</p>}

                            <textarea
                                className={`order__textarea date__input ${getValidationClass(
                                    templeSupportForm,
                                    "templeSupportInfo"
                                )}`}
                                name="templeSupportInfo"
                                onChange={templeSupportForm.handleChange}
                                value={templeSupportForm.values.templeSupportInfo}
                                disabled={!!templeSupportForm.values.useStandardTempleSupportInfo}
                                cols={30}
                                rows={10}
                                placeholder={props.t("templeRegister.orders.textPlaceholder3")}
                                maxLength={templeSupportSchema.inputRestrictions.templeSupportInfo.max}
                            ></textarea>
                            <InputRestrictionsInfo
                                min={templeSupportSchema.inputRestrictions.templeSupportInfo.min}
                                max={templeSupportSchema.inputRestrictions.templeSupportInfo.max}
                                error={templeSupportForm.errors.templeSupportInfo}
                            />

                            <CustomCheckbox
                                id="orderAgreement"
                                defaultValue={props.temple.useStandardTempleSupportInfo}
                                value={templeSupportForm.values.useStandardTempleSupportInfo}
                                name="useStandardTempleSupportInfo"
                                className="checkbox__input"
                                onChange={updateUseStandardTempleSupportInfo}
                                labelContent={<>{props.t("templeRegister.orders.useStandardText")}</>}
                                additionalLabelClassName="date__text"
                            />

                            <ChangeEntityButtons<TempleSupportForm>
                                formik={templeSupportForm}
                                initialValues={initialValues}
                                disableSaveButton={true}
                            />
                        </div>

                        <div className="date__form-block--nomg">
                            <h2 className="date__subtitle date__subtitle--support">
                                {props.t("templeRegister.orders.subtitle4")}
                            </h2>

                            {errorVisible && <p>{props.t(errorVisible)}</p>}

                            <textarea
                                className={`order__textarea date__input ${getValidationClass(
                                    templeSupportForm,
                                    "prayersEmail"
                                )}`}
                                name="prayersEmail"
                                onChange={templeSupportForm.handleChange}
                                value={templeSupportForm.values.prayersEmail}
                                disabled={!!templeSupportForm.values.useStandardPrayersEmail}
                                cols={30}
                                rows={10}
                                placeholder={props.t("templeRegister.orders.textPlaceholder4")}
                                maxLength={templeSupportSchema.inputRestrictions.prayersEmail.max}
                            ></textarea>
                            <InputRestrictionsInfo
                                min={templeSupportSchema.inputRestrictions.prayersEmail.min}
                                max={templeSupportSchema.inputRestrictions.prayersEmail.max}
                                error={templeSupportForm.errors.prayersEmail}
                            />

                            <CustomCheckbox
                                id="orderEmailAgreement"
                                defaultValue={props.temple.useStandardPrayersEmail}
                                value={templeSupportForm.values.useStandardPrayersEmail}
                                name="useStandardPrayersEmail"
                                className="checkbox__input"
                                onChange={updateUseStandardPrayersEmail}
                                labelContent={<>{props.t("templeRegister.orders.useStandardText")}</>}
                                additionalLabelClassName="date__text"
                            />

                            <ChangeEntityButtons<TempleSupportForm>
                                formik={templeSupportForm}
                                initialValues={initialValues}
                                disableSaveButton={true}
                            />
                        </div>

                        <div className="date__form-block--nomg">
                            <h2 className="date__subtitle date__subtitle--support">
                                {props.t("templeRegister.orders.subtitle5")}
                            </h2>

                            {errorVisible && <p>{props.t(errorVisible)}</p>}

                            <textarea
                                className={`order__textarea date__input ${getValidationClass(
                                    templeSupportForm,
                                    "templeSupportEmail"
                                )}`}
                                name="templeSupportEmail"
                                onChange={templeSupportForm.handleChange}
                                value={templeSupportForm.values.templeSupportEmail}
                                disabled={!!templeSupportForm.values.useStandardTempleSupportEmail}
                                cols={30}
                                rows={10}
                                placeholder={props.t("templeRegister.orders.textPlaceholder5")}
                                maxLength={templeSupportSchema.inputRestrictions.templeSupportEmail.max}
                            ></textarea>
                            <InputRestrictionsInfo
                                min={templeSupportSchema.inputRestrictions.templeSupportEmail.min}
                                max={templeSupportSchema.inputRestrictions.templeSupportEmail.max}
                                error={templeSupportForm.errors.templeSupportEmail}
                            />

                            <CustomCheckbox
                                id="templeSupportEmailAgreement"
                                defaultValue={props.temple.useStandardTempleSupportEmail}
                                value={templeSupportForm.values.useStandardTempleSupportEmail}
                                name="useStandardTempleSupportEmail"
                                className="checkbox__input"
                                onChange={updateUseStandardTempleSupportEmail}
                                labelContent={<>{props.t("templeRegister.orders.useStandardText")}</>}
                                additionalLabelClassName="date__text"
                            />

                            <ChangeEntityButtons<TempleSupportForm>
                                formik={templeSupportForm}
                                initialValues={initialValues}
                                disableSaveButton={true}
                            />
                        </div>
                    </div>
                </main>
                <ConflictErrorModal />
                <Footer isFormsDataValid={isFormsDataValid} />
            </form>
        </div>
    );
};

export default withTranslation()(Orders);

import { BaseTempleRelatedModel } from "../../domain/models/BaseTempleRelatedModel";
import { CustomFile } from "../../domain/models/CustomFile";

export function useUpdateFileOrder<M extends BaseTempleRelatedModel>(entity: M, setIsOrderChanged: (value: boolean) => void, customFiles?: CustomFile[]) {
    return (toUp: boolean) => {
        return (currentFileOrder: number) => {
            let files;

            if (toUp) {
                files = entity.moveFileForward(currentFileOrder, customFiles);
            } else {
                files = entity.moveFileBackward(currentFileOrder, customFiles);
            }

            if (!files) {
                return;
            }

            files.currentElement?.markAsChanged();
            files.prevElement?.markAsChanged();

            setIsOrderChanged(true);
        };
    };
};

import { FC } from "react";
import { withTranslation } from "react-i18next";

interface TempleFilterProps {
    t(key?: string): string;
}

const TempleFilter: FC<TempleFilterProps> = (props) => {
    return (
        <div className="inner__info-block active">
            <div className="inner__info-row">
                <div className="inner__info-item">
                    <label>
                        <input className="inner__info-form" type="text" placeholder={props.t("admin.temples.filter.temple")} />
                    </label>
                </div>
                <div className="inner__info-item">
                    <label>
                        <input className="inner__info-form" type="text" placeholder={props.t("admin.temples.filter.country")} />
                    </label>
                </div>

                <div className="inner__info-item">
                    <label>
                        <input className="inner__info-form" type="text" placeholder={props.t("admin.temples.filter.religion")} />
                    </label>
                </div>

                <div className="inner__info-item">
                    <label>
                        <input className="inner__info-form" type="text" placeholder={props.t("admin.temples.filter.current")} />
                    </label>
                </div>
            </div>

            <div className="inner__info-row">
                <div className="inner__info-item">
                    <label>
                        <input className="inner__info-form" type="text" placeholder={props.t("admin.temples.filter.sumFrom")} />
                    </label>
                </div>

                <div className="inner__info-item">
                    <label>
                        <input className="inner__info-form" type="text" placeholder={props.t("admin.temples.filter.sumBefore")} />
                    </label>
                </div>
            </div>

            <button className="inner__info-apply">{props.t("admin.temples.filter.applyButton")}</button>
        </div>
    );
};

export default withTranslation()(TempleFilter);

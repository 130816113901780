import { srcRegex } from "../constants";
import { YoutubeLinkService } from "../domain/services/YoutubeLinkService";
import { get } from "../tools/Tools";
import { YoutubeEmbed } from "../types/YoutubeEmbed";

class YoutubeLinkServiceImpl implements YoutubeLinkService {
    private static baseEmbedLink = "https://www.youtube.com/oembed";

    private getFullEmbedLink(url: string): string {
        return `${YoutubeLinkServiceImpl.baseEmbedLink}?url=${url}&format=json`;
    }

    private getIframeSrc(iframeString: string): string {
        return iframeString.match(srcRegex)[1];
    }

    async getYoutubeEmbedSrc(url: string): Promise<string> {
        const data = (await get(this.getFullEmbedLink(url), "same-origin")) as YoutubeEmbed;
        return this.getIframeSrc(data.html);
    }
}

export default function youtubeLinkServiceFactory(): YoutubeLinkService {
    return new YoutubeLinkServiceImpl();
}

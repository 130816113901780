import { FC } from "react";
import { Temple } from "../../../../domain/models/Temple";
import MainArtifactInfoSlide from "./MainArtifactInfoSlide";
import { withTranslation } from "react-i18next";
import ArtifactInfoModalSlide from "./artifact-modal/ArtifactInfoModalSlide";
import Popup from "reactjs-popup";
import FullScreenModal from "../../../base/full-screen-modal/FullScreenModal";
import { useArtifactDoubleModalContext } from "../../../../context/ArtifactDoubleModalProvider";
import SliderModalWindowLayout from "../../../base/slider-modal-window/SliderModalWindowLayout";

interface MainArtifactInfoProps {
    t(key?: string): string;
    temple: Temple;
}

const MainArtifactInfo: FC<MainArtifactInfoProps> = (props) => {
    const doubleModalHandlers = useArtifactDoubleModalContext();

    return (
        <section id="section_3">
            <SliderModalWindowLayout
                slidesPerView={3}
                spaceBetween={15}
                isArrowsDisable={true}
                loop={false}
                allowTouchMove={true}
                id="artifact-slider"
                wrapperClassName="basic"
                title={props.t("user.temple.basics.subtitle")}
                elements={props.temple.artifacts}
                MainElementInfoSlide={MainArtifactInfoSlide}
                ElementInfoModalSlide={ArtifactInfoModalSlide}
                isVisibleModal={doubleModalHandlers.isVisibleModal}
                modalSwiper={doubleModalHandlers.modalSwiper}
                mainSlideIndex={doubleModalHandlers.mainSlideIndex}
                setMainSlideIndex={doubleModalHandlers.setMainSlideIndex}
                setModalSwiper={doubleModalHandlers.setModalSwiper}
                openModal={doubleModalHandlers.openModal}
                closeModal={doubleModalHandlers.closeModal}
            />

            <Popup
                open={doubleModalHandlers.openedFullScreenModal}
                modal
                overlayStyle={{ zIndex: 999999 }}
                onClose={doubleModalHandlers.closeFullScreenModal}
                className="full-screen-modal__popup"
            >
                <FullScreenModal
                    photos={doubleModalHandlers.files}
                    closeCallback={doubleModalHandlers.closeFullScreenModal}
                    activeIndex={doubleModalHandlers.activeImageIndex}
                    toPreviousPhoto={doubleModalHandlers.toPreviousPhoto}
                    toNextPhoto={doubleModalHandlers.toNextPhoto}
                />
            </Popup>
        </section>
    );
};

export default withTranslation()(MainArtifactInfo);

import _ from "lodash";
import { apiURL } from "../Config";
import { ReligionDTO } from "../domain/models/dto/ReligionsDTO";
import { Religion, ReligionCurrent, ReligionSubspecies } from "../domain/models/Religions";
import { ReligionsService } from "../domain/services/ReligionsService";
import religionMapperFactory from "../mappers/ReligionMapper";
import { get } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { Langs } from "../enums/langs-enum";
import { Translate } from "../types/Translate";
import languageServiceFactory from "./LanguageServiceImpl";


const Religions: ReligionDTO[] = require('./mock-religions.json');

class ReligionsServiceImpl extends BaseServiceImpl implements ReligionsService {
    static religions: Religion[] = [];

    private static _religionsTranslations: Religion[] = [];
    private static _religionCurrentsTranslations: Religion[] = [];
    private static _religionSubspeciesTranslations: Religion[] = [];

    private static _currentReligionsTranslationsLang: Langs = Langs.EN;

    private static setCurrentReligionsTranslationsLang() {
        ReligionsServiceImpl._currentReligionsTranslationsLang = languageServiceFactory().currentLang as Langs;
    }

    async getReligionsData(): Promise<ReligionDTO[]> {
        try {
            return super.getData(await get(`${apiURL}/api/v1/religions`));
        } catch (err) {
            console.log('ReligionsServiceImpl.getReligionsData => ERROR:');
            console.log(err);
        }
    }

    async getCachedReligions(): Promise<Religion[]> {
        const mapper = religionMapperFactory();

        if (!ReligionsServiceImpl.religions.length) {
            ReligionsServiceImpl.religions = (await this.getReligionsData() || Religions).map(rel => mapper.fromDTO(rel))
        }
        return ReligionsServiceImpl.religions;
    }

    getReligions(): Religion[] {
        return ReligionsServiceImpl.religions;
    }

    setReligions(value: Religion[]) {
        return ReligionsServiceImpl.religions = value;
    }

    getReligionCurrents(religionId: number): ReligionCurrent[] {
        if (!religionId) {
            return []
        }

        const id = religionId.toString();

        return _.get(
            ReligionsServiceImpl.religions.find(
                el => el.value === id
            ),
            'religionCurrents',
            []
        );
    }

    getReligionSubspecies(religionId: number, currentId: number): ReligionSubspecies[] {
        if (!religionId || !currentId) {
            return []
        }

        const id = currentId.toString();

        return _.get(
            this.getReligionCurrents(religionId).find(
                el => el.value === id
            ),
            'religionSubspecies',
            []
        );
    }

    getAllReligionCurrents(): ReligionCurrent[] {
        return ReligionsServiceImpl.religions
            .reduce((acc, religion) => {
                acc = acc.concat(religion.religionCurrents);
                return acc;
            }, [])
            .filter(current => current);
    }

    getAllReligionSubspecies(): ReligionCurrent[] {
        return this.getAllReligionCurrents()
            .reduce((acc, current) => {
                acc = acc.concat(current.religionSubspecies);
                return acc;
            }, [])
            .filter(subspecies => subspecies);
    }

    getReligion(religionId: number): Religion {
        if (!religionId) {
            return;
        }

        const id = religionId.toString();
        return ReligionsServiceImpl.religions.find((el) => el.value === id);
    }

    getReligionCurrent(currentId: number): ReligionCurrent {
        if (!currentId) {
            return;
        }

        const id = currentId.toString();
        return this.getAllReligionCurrents()
            .find(current => current.value === id);
    }

    getReligionCurrentSubspecies(subspeciesId: number): ReligionSubspecies {
        if (!subspeciesId) {
            return;
        }

        const id = subspeciesId.toString();
        return this.getAllReligionSubspecies()
            .find(subspecies => subspecies.value === id);
    }

    getReligionTranslations(religionId: number): Religion {
        if (!religionId || !ReligionsServiceImpl._religionsTranslations.length) {
            return;
        }

        const id = religionId.toString();
        return ReligionsServiceImpl._religionsTranslations.find((el) => el.value === id);
    }
    getReligionsTranslations(t: Translate): Religion[] {
        const langService = languageServiceFactory();
        if (
            (langService.currentLang !== ReligionsServiceImpl._currentReligionsTranslationsLang) ||
            !ReligionsServiceImpl._religionsTranslations.length
        ) {
            ReligionsServiceImpl._religionsTranslations = ReligionsServiceImpl.religions.map((religion) => {
                return {
                    value: religion.value,
                    label: t(`religions.${religion.label}`),
                    religionCurrents: religion.religionCurrents,
                }
            });
            ReligionsServiceImpl.setCurrentReligionsTranslationsLang();
        }

        return ReligionsServiceImpl._religionsTranslations;
    }

    getReligionCurrentTranslations(t: Translate, religionId: number, religionCurrentId: number): Religion {
        if (!religionCurrentId) {
            return;
        }
        const currents = ReligionsServiceImpl._religionCurrentsTranslations.length ?
            ReligionsServiceImpl._religionCurrentsTranslations :
            this.getReligionCurrentsTranslations(t, religionId);

        const id = religionCurrentId.toString();
        return currents.find((el) => el.value === id);
    }
    getReligionCurrentsTranslations(t: Translate, religionId: number): Religion[] {
        if (!religionId) {
            this.resetReligionCurrentsTranslations();
            return ReligionsServiceImpl._religionCurrentsTranslations;
        }

        const langService = languageServiceFactory();
        if (
            (langService.currentLang !== ReligionsServiceImpl._currentReligionsTranslationsLang) ||
            !ReligionsServiceImpl._religionCurrentsTranslations.length
        ) {
            ReligionsServiceImpl._religionCurrentsTranslations = this.getReligionCurrents(religionId)
                .map((current) => {
                    return {
                        value: current.value,
                        label: t(`religionCurrents.${current.label}`),
                        religionCurrents: current.religionSubspecies,
                    }
                });
            ReligionsServiceImpl.setCurrentReligionsTranslationsLang();
        }

        return ReligionsServiceImpl._religionCurrentsTranslations;
    }

    getReligionCurrentSubspeciesTranslations(t: Translate, religionId: number, religionCurrentId: number, religionSubspeciesId: number): Religion {
        if (!religionSubspeciesId) {
            return;
        }

        const subspecies = ReligionsServiceImpl._religionSubspeciesTranslations.length ?
            ReligionsServiceImpl._religionSubspeciesTranslations :
            this.getReligionSubspeciesTranslations(t, religionId, religionCurrentId);

        const id = religionSubspeciesId.toString();
        return subspecies.find((el) => el.value === id);
    }
    getReligionSubspeciesTranslations(t: Translate, religionId: number, religionCurrentId: number): Religion[] {
        if (!religionCurrentId || !religionId) {
            this.resetReligionSubspeciesTranslations();
            return ReligionsServiceImpl._religionSubspeciesTranslations;
        }

        const langService = languageServiceFactory();
        if (
            (langService.currentLang !== ReligionsServiceImpl._currentReligionsTranslationsLang) ||
            !ReligionsServiceImpl._religionSubspeciesTranslations.length
        ) {
            ReligionsServiceImpl._religionSubspeciesTranslations = this.getReligionSubspecies(religionId, religionCurrentId)
                .map((subspecies) => {
                    return {
                        value: subspecies.value,
                        label: t(`religionSubspecies.${subspecies.label}`),
                    }
                });
            ReligionsServiceImpl.setCurrentReligionsTranslationsLang();
        }

        return ReligionsServiceImpl._religionSubspeciesTranslations;
    }

    resetReligionCurrentsTranslations(): void {
        ReligionsServiceImpl._religionCurrentsTranslations = [];
    }
    resetReligionSubspeciesTranslations(): void {
        ReligionsServiceImpl._religionSubspeciesTranslations = [];
    }
}


export default function religionsServiceFactory(): ReligionsService {
    return new ReligionsServiceImpl()
}

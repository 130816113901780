import { FC } from "react";
import { Prayer } from "../../../../../domain/models/Prayer";
import { PrayerPriceDetail } from "../../../../../domain/models/PrayerPriceDetail";

interface PrayerDetailsInfoProps {
    detail: PrayerPriceDetail;

    prayer: Prayer;
    updatePrayers(updatedPrayer: Prayer): void;

    setErrorVisibility(value: boolean): void;
}

const PrayerDetailsInfo: FC<PrayerDetailsInfoProps> = (props) => {
    // const prayerPriceDetailService = prayerPriceDetailServiceFactory();

    // const removeCurrentDetail = () => {
    //     const remove = async () => {
    //         const result = await prayerPriceDetailService.removeEntity(props.detail.id);
    //         if (!result) {
    //             props.setErrorVisibility(true);
    //         }

    //         props.prayer.setPrayerPriceDetails(
    //             props.prayer.prayerPriceDetails.filter((detail) => detail.id !== props.detail.id)
    //         );
    //         props.updatePrayers(props.prayer);
    //     };

    //     remove();
    // };

    return (
        <tr>
            <td style={{paddingRight: "0px"}}>{props.detail.maxPeople}</td>
            <td style={{paddingRight: "0px"}}>{props.detail.priceFloatFormat}$</td>
            {/* <td>
                <button className="order__info__edit__details" onClick={() => removeCurrentDetail()} type="button" />
            </td> */} { /* TODO */ }
        </tr>
    );
};

export default PrayerDetailsInfo;

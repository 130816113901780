import { FC } from "react";
import { apiURL } from "../../Config";

interface ArtifactInfoSlideProps {
    filePath: string;
    imageClass?: string;
};

const ArtifactInfoSlide: FC<ArtifactInfoSlideProps> = (props) => {
    return (
        <div className="swiper-slide">
            <picture>
                <img className={"establish__info-image " + (props.imageClass ? props.imageClass : "")} src={`${apiURL}/files/${props.filePath}`} alt="" />
            </picture>
        </div>
    );
};

export default ArtifactInfoSlide;

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TemplesInfoDTO } from "../../../domain/models/dto/TempleDTO";
import { Temple } from "../../../domain/models/Temple";

interface TemplesProps {
    t(key?: string): string;
    templesInfo: TemplesInfoDTO | undefined;
    temples: Temple[] | undefined;
}

const Temples: FC<TemplesProps> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="admin-main__block">
            <div className="admin-main__top">
                <div className="admin-main__title">{props.t("admin.main.temple.title")}</div>
                <a onClick={() => navigate("/admin/temples")} className="admin-main__view">
                    {props.t("admin.main.temple.mainView")}
                </a>
            </div>

            <div className="admin-main__indicators">
                <div className="admin-main__indicator">
                    <h2 className="admin-main__indicator-title admin-main__holy-title">
                        {props.t("admin.main.temple.all")}
                    </h2>
                    <div className="admin-main__indicator-num admin-main__holy-num">
                        {props.templesInfo?.totalCount}
                    </div>
                </div>

                <div className="admin-main__indicator">
                    <h2 className="admin-main__indicator-title admin-main__holy-title">
                        {props.t("admin.main.temple.pending")}
                    </h2>
                    <div className="admin-main__indicator-num admin-main__holy-num">
                        {props.templesInfo?.inReviewCount}
                    </div>
                </div>

                <div className="admin-main__indicator">
                    <h2 className="admin-main__indicator-title admin-main__holy-title">
                        {props.t("admin.main.temple.deleted")}
                    </h2>
                    <div className="admin-main__indicator-num admin-main__holy-num">
                        {props.templesInfo?.deletedCount}
                    </div>
                </div>
            </div>

            <div className="admin-main__items">
                {props.temples?.map((temple, index) => (
                    <a className="admin-main__item" href="#" key={`temple_${index}`}>
                        {temple.name}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default withTranslation()(Temples);

import { FC } from "react";
import { MainElementInfoSlideProps } from "../../../../domain/components/slider-modal-window/MainElementInfoSlideProps";
import { Artifact } from "../../../../domain/models/Artifact";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import MainImageSlide from "../../../base/photo-slider/MainImageSlide";

const MainArtifactInfoSlide: FC<MainElementInfoSlideProps<Artifact>> = (props) => {
    return (
        <MainImageSlide
            filePath={props.element?.getSortedFiles(SortingOrder.ASC)[0]?.filePath}
            additionalContent={
                <h4 className="basic__thumbs-text">{props.element.name}</h4>
            }
            // wrapClassName="basic__wrap modal--main-btn"
            imageClassName="basic__artifact-info-slide-img"
            slideOnClick={props.openModal}
        />
    );
};

export default MainArtifactInfoSlide;

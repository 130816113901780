import _ from "lodash";
import { apiURL } from "../Config";
import { DonateDTO, OrderDTO, PaymentDTO } from "../domain/models/dto/PaymentDTO";
import { Payment, PaymentWithCount } from "../domain/models/Payment";
import { Temple } from "../domain/models/Temple";
import { PaymentService } from "../domain/services/PaymentService";
import { PaymentSortFields } from "../enums/payment-sort-fields-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import paymentMapperFactory from "../mappers/PaymentMapper";
import PaymentModel from "../models/PaymentModel";
import { get, post } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { PaymentChartsFields } from "../enums/entity-fields/payment-fields-enum";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { ChartsParams } from "../types/charts/ChartsParams";
import { TransactionType } from "../enums/transaction-type-enum";

class PaymentServiceImpl extends BaseServiceImpl implements PaymentService {
    private static payments: PaymentWithCount;

    async getCachedPaymentById(
        paymentId: string,
    ): Promise<Payment> {
        if (!paymentId) return null;

        return PaymentServiceImpl.payments?.rows.find(
            (payment) => payment.id === paymentId
        ) || await this.getPaymentById(paymentId);
    }

    getEmptyPayment(): Payment {
        return new PaymentModel();
    }

    getBasePaymentWithCount(): PaymentWithCount {
        return {
            totalPages: 0,
            totalRows: 0,
            rows: [this.getEmptyPayment()],
        };
    }

    async getCachedTemplePayments(
        templeId: string,
        page: number,
        limit: number,
        offset: number,
        sort: PaymentSortFields,
        sortingOrder: SortingOrder,
        reloadCache: boolean = false
    ): Promise<PaymentWithCount> {
        if (!PaymentServiceImpl.payments || reloadCache) {
            PaymentServiceImpl.payments = await this.getTemplePayments(
                templeId,
                page,
                limit,
                offset,
                sort,
                sortingOrder
            );
        }

        return PaymentServiceImpl.payments;
    }

    async getCachedPayments(
        page: number,
        limit: number,
        offset: number,
        sort: PaymentSortFields,
        sortingOrder: SortingOrder,
        reloadCache: boolean
    ): Promise<PaymentWithCount> {
        if (!PaymentServiceImpl.payments || reloadCache) {
            PaymentServiceImpl.payments = await this.getPayments(
                page,
                limit,
                offset,
                sort,
                sortingOrder
            );
        }

        return PaymentServiceImpl.payments;
    }

    private async getPaymentById(paymentId: string): Promise<Payment> {
        try {
            const paymentMapper = paymentMapperFactory();
            return paymentMapper.fromDTO(this.getData(await get(
                `${apiURL}/api/v1/payment/${paymentId}`
            )));
        } catch (err) {
            console.log("PaymentServiceImpl.getPaymentById => ERROR:");
            console.log(err);
        }
    }

    async getPayments(
        page: number,
        limit: number,
        offset: number,
        sort: PaymentSortFields,
        sortingOrder: SortingOrder,
        payoutId?: string,
    ): Promise<PaymentWithCount> {
        try {
            const paymentMapper = paymentMapperFactory();

            let url = `${apiURL}/api/v1/payment/all?${this.getPaginationParamsUrl({page, limit, offset, sort, sortingOrder}, PaymentSortFields.STATUS)}`;
            if (payoutId) {
                url += `&payoutId=${payoutId}`;
            }
            const result = await get(url);
            const payments =
                this.getData<PaymentDTO[]>(result)?.map((payment: PaymentDTO) =>
                    paymentMapper.fromDTO(payment)
                ) || [];

            return {
                totalPages: result.totalPages,
                totalRows: result.totalRows,
                rows: payments,
            };
        } catch (err) {
            console.log("PaymentServiceImpl.getPayments => ERROR:");
            console.log(err);
        }
    }

    async getTemplePayments(
        templeId: string,
        page: number,
        limit: number,
        offset: number,
        sort: PaymentSortFields,
        sortingOrder: SortingOrder,
        excludePayoutsPayments: boolean = false,
        payoutId?: string,
    ): Promise<PaymentWithCount> {
        try {
            const paymentMapper = paymentMapperFactory();

            let url = `${apiURL}/api/v1/temple/${templeId}/payments?${this.getPaginationParamsUrl({page, limit, offset, sort, sortingOrder}, PaymentSortFields.STATUS)}`;
            if (excludePayoutsPayments) {
                url += `&excludePayoutsPayments=${excludePayoutsPayments}`;
            }
            if (payoutId) {
                url += `&payoutId=${payoutId}`;
            }
            const result = await get(url);
            const payments =
                result.data?.map((payment: PaymentDTO) =>
                    paymentMapper.fromDTO(payment)
                ) || [];

            return {
                totalPages: result.totalPages,
                totalRows: result.totalRows,
                rows: payments,
            };
        } catch (err) {
            console.log("PaymentServiceImpl.getTemplePayments => ERROR:");
            console.log(err);
        }
    }

    setTemplesToPayments(payments: Payment[], temples: Temple[]) {
        if (_.isEmpty(payments) || _.isEmpty(temples)) {
            return;
        }

        payments.forEach(payment => {
            if (!payment.templeId) {
                return;
            }
            payment.setTemple(temples.find(tmpl => tmpl.id === payment.templeId));
        });
    };

    async createOrder(order: OrderDTO): Promise<boolean> {
        try {
            return !!this.getData(await post(`${apiURL}/api/v1/order`, order));
        } catch (err) {
            console.log("PaymentServiceImpl.createOrder => ERROR:");
            console.log(err);
        }
    }

    async createDonate(donate: DonateDTO): Promise<boolean> {
        try {
            return !!this.getData(await post(`${apiURL}/api/v1/donate`, donate));
        } catch (err) {
            console.log("PaymentServiceImpl.createDonate => ERROR:");
            console.log(err);
        }
    }

    async getPaymentCharts(
        chartsParams: ChartsParams,
        field: PaymentChartsFields,
        transactionType: TransactionType = TransactionType.DONATE,
        templeId: string = null,
    ): Promise<number[]> {
        try {
            return super.getEntitiesCharts(
                EntitiesBaseUrls.PAYMENTS,
                chartsParams,
                field,
                { transactionType, templeId }
            );
        } catch (err) {
            console.log("PaymentServiceImpl.getPaymentCharts => ERROR:");
            console.log(err);
        }
    }
}

export default function paymentServiceFactory(): PaymentService {
    return new PaymentServiceImpl();
}

import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { maxFilesGroupSize } from "../../../../Config";
import { useFormsValidConfirmation } from "../../../../context/FormikFormsProvider";
import { AbbotInfoForm, Temple } from "../../../../domain/models/Temple";
import { CreateOrUpdateTemplePath } from "../../../../enums/create-or-update-temple-path-enum";
import { CreateTempleParams } from "../../../../enums/create-temple-params-enum";
import { Owner } from "../../../../enums/parent-enum";
import { Purpose } from "../../../../enums/purpose-enum";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import templeMapperFactory from "../../../../mappers/TempleMapper";
import templeServiceFactory from "../../../../services/TempleServiceImpl";
import { useRedirectToPrevCreateTemplePage } from "../../../../tools/custom-hooks/useRedirect";
import { useUpdateFileOrder } from "../../../../tools/custom-hooks/useUpdateFileOrder";
import { getValidationClass } from "../../../../tools/Tools";
import { AbbotSchemaFactory } from "../../../../validation-schemas/temple-register/abbot-schemas";
import EditableImagesList from "../../../base/EditableImagesList";
import ChangeEntityButtons from "../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../base/InputRestrictionsInfo";
import Footer from "../footer/Footer";
import MainArtifactComponent from "./artifact/MainArtifactComponent";
import ConflictErrorModal from "../../../base/conflict-error-modal/ConflictErrorModal";
import ContentTooLargeErrorModal from "../../../base/content-too-large-error-modal/ContentTooLargeErrorModal";

interface AbbotProps {
    t(key?: string): string;
    temple: Temple;
    setTemple(value: Temple): void;
}

const Abbot: FC<AbbotProps> = (props) => {
    const [errorVisible, setErrorVisibility] = useState<string>(null);
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);

    const updateFileOrder = useUpdateFileOrder<Temple>(props.temple, setIsOrderChanged, props.temple.abbotInfoFiles);
    useRedirectToPrevCreateTemplePage(props.temple, TempleRegisterPage.MAIN_INFO, props.temple.isMainInfoComplete);

    const templeService = templeServiceFactory();
    const navigate = useNavigate();

    const setIsErrorVisibility = (value: boolean) => {
        setErrorVisibility(value ? "errors.common" : null);
    };

    const resetErrorCallback = useCallback(() => {
        setErrorVisibility(null);
    }, []);

    const initialValues = {
        abbotInfo: props.temple.abbotInfo,
    };

    const abbotSchema = useMemo(() => AbbotSchemaFactory({ t: props.t }), [props.t]);

    const abbotForm: FormikProps<AbbotInfoForm> = useFormik({
        enableReinitialize: false,
        initialValues: initialValues,
        validationSchema: abbotSchema.schema,
        onSubmit: async (values) => {
            const mapper = templeMapperFactory();
            const temple = props.temple;
            const nextUrl = "/create-temple/schedules".concat(`?${CreateTempleParams.ID}=${temple.originId}`);

            try {
                setErrorVisibility(null);

                if (_.isEqual(values, abbotForm.initialValues) && !temple.isFilesUpdated && temple.isAbbotComplete) {
                    navigate(nextUrl);
                    return;
                }

                temple.setAbbotInfo(values.abbotInfo);

                if (!temple.isAbbotComplete) {
                    temple.resetRequiredAbbotFields();
                    return setErrorVisibility("templeRegister.required.artifacts");
                }

                const newTemple: Temple | undefined = await templeService.createRequestOrUpdateCurrentEntity(
                    mapper.toLatestTempleDTO(temple), CreateOrUpdateTemplePath.ABBOT_INFO
                );

                if (!newTemple) {
                    return setErrorVisibility("errors.common");
                }

                const files = await temple.resolveFiles(newTemple?.id, temple.abbotInfoFiles);
                if (files.isErrorVisible) {
                    return setErrorVisibility("errors.common");
                }

                if (newTemple) {
                    temple.abbotInfoFiles = temple.getUpdatedFilesList(files.images, temple.abbotInfoFiles);

                    templeService.notifyListeners(temple);
                    props.setTemple(temple);

                    navigate(nextUrl);
                }
            } catch (err) {
                setErrorVisibility("errors.common");
            }
        },
    });

    const { isFormsDataValid } = useFormsValidConfirmation("abbotForm", abbotForm);

    useEffect(() => {
        if (isOrderChanged) {
            setIsOrderChanged(false);
        }
    }, [isOrderChanged]);

    return (
        <div className="date__content">
            <form onSubmit={abbotForm.handleSubmit}>
                <main className="date__main">
                    <h1 className="date__title">
                        {props.t("templeRegister.base.title1")}
                        <br />
                        {props.t("templeRegister.base.title2")}
                    </h1>

                    <div className="prior date__section">
                        <div className="prior__block">
                            <h2 className="date__subtitle prior__subtitle">
                                {props.t("templeRegister.abbot.subtitle")}
                            </h2>

                            {/* <AbbotInfoTooltip /> */}
                        </div>

                        <div className="date__form-block">
                            {errorVisible && <p className="input-restrictions-info__error">{props.t(errorVisible)}</p>}
                            <textarea
                                className={`date__textarea date__input  ${getValidationClass(abbotForm, "abbotInfo")}`}
                                name="abbotInfo"
                                onChange={abbotForm.handleChange}
                                value={abbotForm.values.abbotInfo}
                                cols={30}
                                rows={10}
                                placeholder={props.t("templeRegister.abbot.infoPlaceholder")}
                                maxLength={abbotSchema.inputRestrictions.abbotInfo.max}
                            ></textarea>
                            <InputRestrictionsInfo
                                min={abbotSchema.inputRestrictions.abbotInfo.min}
                                max={abbotSchema.inputRestrictions.abbotInfo.max}
                                error={abbotForm.errors.abbotInfo}
                            />
                        </div>

                        <EditableImagesList
                            files={props.temple.getSortedFiles(SortingOrder.ASC, props.temple.abbotInfoFiles)}
                            setErrorVisibility={setIsErrorVisibility}
                            setIsFilesUpdated={val => props.temple.setIsFilesUpdated(val)}

                            ownerType={Owner.TEMPLE}
                            purposeType={Purpose.ABBOT_INFO}
                            maxImagesCount={maxFilesGroupSize}

                            getMaxOrder={() => props.temple.getMaxOrder(props.temple.abbotInfoFiles)}
                            getNewOrder={() => props.temple.getNextFileOrder(props.temple.abbotInfoFiles)}

                            moveFileToLeft={updateFileOrder(true)}
                            moveFileToRight={updateFileOrder(false)}
                            removeFileByOrder={(order: number) => props.temple.removeFileByOrder(order, props.temple.abbotInfoFiles)}
                        ></EditableImagesList>
                    </div>
                    <ChangeEntityButtons<AbbotInfoForm>
                        formik={abbotForm}
                        initialValues={initialValues}
                        disableSaveButton={true}
                        resetEntityFormHandler={() => props.temple.resetAbbotInfoFiles()}
                    />
                    <MainArtifactComponent temple={props.temple} resetErrorCallback={resetErrorCallback} />
                </main>
                <ConflictErrorModal />
                <ContentTooLargeErrorModal />
                <Footer isFormsDataValid={isFormsDataValid} />
            </form>
        </div>
    );
};

export default withTranslation()(Abbot);

import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import TextTruncate from "react-text-truncate";
import { Translate } from "../../types/Translate";

interface TextTruncateWrapperProps {
    t: Translate;
    text: string;
    className: string;
    height: number;
    blur?: boolean;
}

const TextTruncateWrapper: FC<TextTruncateWrapperProps> = (props) => {
    const [scrollTop, setScrollTop] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const textTruncateRef = useRef<HTMLDivElement>(null);

    const updateTextState = (event: MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        setExpanded(!expanded);
        setScrollTop(true);
    };

    useEffect(() => {
        if (!expanded && scrollTop && textTruncateRef.current) {
            textTruncateRef.current.scrollIntoView();
            setScrollTop(false);
        }
    }, [expanded, scrollTop]);

    const handleTruncates = () => {
        setShowButton(true);
    };

    return (
        <div ref={textTruncateRef}>
            <TextTruncate
                line={expanded ? false : props.height}
                element="div"
                truncateText="..."
                text={props.text}
                containerClassName={
                    (!expanded && props.blur && showButton ? "about-church__" : "") + props.className
                }
                onTruncated={handleTruncates}
            />
            {showButton && (
                <button className="churchBtn" onClick={updateTextState}>
                    {expanded ? props.t("base.truncateText.lessInfo") : props.t("base.truncateText.moreInfo")}
                </button>
            )}
        </div>
    );
};

export default withTranslation()(TextTruncateWrapper);

import _ from "lodash";
import { FC, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Religion, ReligionCurrent, ReligionSubspecies } from "../../../domain/models/Religions";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import { Translate } from "../../../types/Translate";
import DropDown from "../DropDown";

interface ReligionsDropDownProps {
    t: Translate
    itemClass: string;

    resetReligions: boolean;
    setResetReligions(value: boolean): void;

    setReligionId(value: number): void;
    setReligionCurrentId(value: number): void;
    setReligionSubspeciesId(value: number): void;
};

const ReligionsDropDown: FC<ReligionsDropDownProps> = (props) => {
    const religionsService = religionsServiceFactory();

    const religionCurrentComponent: any = useRef();
    const religionSubspeciesComponent: any = useRef();


    const [religion, setReligion] = useState<Religion | undefined>(religionsService.getReligion());

    const [religionCurrent, setReligionCurrent] = useState<ReligionCurrent | undefined>(religionsService.getReligionCurrent());

    const [religionSubspecies, setReligionSubspecies] = useState<ReligionSubspecies | undefined>(religionsService.getReligionCurrentSubspecies());

    const handleReligionSelected = (newReligion?: Religion) => {
        if ((religion === newReligion) && !props.resetReligions) {
            return;
        }
        setReligion(newReligion);
        clearReligiousCurrent();
        clearReligiousSubspecies();

        props.setReligionId(parseInt(_.get(newReligion, "value")));
        props.setResetReligions(false);
    };

    const handleCurrentSelected = (newReligionCurrent?: ReligionCurrent) => {
        if ((religionCurrent === newReligionCurrent) && !props.resetReligions) {
            return;
        }
        setReligionCurrent(newReligionCurrent);
        clearReligiousSubspecies();

        props.setReligionCurrentId(parseInt(_.get(newReligionCurrent, "value")));
        props.setResetReligions(false);
    };

    const handleSubspeciesSelected = (newReligionSubspecies?: ReligionSubspecies) => {
        if ((religionSubspecies === newReligionSubspecies) && !props.resetReligions) {
            return;
        }
        setReligionSubspecies(newReligionSubspecies);

        props.setReligionSubspeciesId(parseInt(_.get(newReligionSubspecies, "value")));
        props.setResetReligions(false);
    };

    const clearReligiousCurrent = () => {
        (religionCurrentComponent.current as any)?.clearValue();
        setReligionCurrent(undefined);
    };

    const clearReligiousSubspecies = () => {
        (religionSubspeciesComponent.current as any)?.clearValue();
        setReligionSubspecies(undefined);
    };

    return (
        <>
            <div className={props.itemClass}>
                <DropDown
                    name="religionId"
                    value={!props.resetReligions ? religion : null}
                    options={religionsService.getReligions()}
                    onChange={handleReligionSelected}
                    placeholder={props.t("templeRegister.mainInfo.religionPlaceholder")}
                    labelPrefix="religions."
                ></DropDown>
            </div>
            <div className={props.itemClass}>
                <DropDown
                    name="religionCurrentId"
                    ref={religionCurrentComponent}
                    value={!props.resetReligions ? religionCurrent : null}
                    options={religionsService.getReligionCurrents(parseInt(religion?.value))}
                    onChange={handleCurrentSelected}
                    placeholder={props.t("templeRegister.mainInfo.currentPlaceholder")}
                    labelPrefix="religionCurrents."
                ></DropDown>
            </div>
            <div className={props.itemClass}>
                <DropDown
                    name="religionSubspeciesId"
                    ref={religionSubspeciesComponent}
                    value={!props.resetReligions ? religionSubspecies : null}
                    options={religionsService.getReligionSubspecies(parseInt(religion?.value), parseInt(religionCurrent?.value))}
                    onChange={handleSubspeciesSelected}
                    placeholder={props.t("templeRegister.mainInfo.subspeciesPlaceholder")}
                    labelPrefix="religionSubspecies."
                ></DropDown>
            </div>
        </>
    );
};

export default withTranslation()(ReligionsDropDown);

export enum PublicParams {
    REDIRECT = "redirect",
    TEMPLE_NAME = "templeName",
    SORT = "sort",
    ORDER = "order",
    RELOAD_CACHE = "reloadCache",
    QUERY = "query",
    REGISTRATION_TOKEN = "rt",
    CORRESPONDENCE_EMAIL_TOKEN = "ct",
}

import { FC } from "react";
import { Temple } from "../../../domain/models/Temple";
import FoundTempleRow from "./FoundTempleRow";

interface FoundTemplesRowsListProps {
    temples: Temple[];
}

const FoundTemplesRowsList: FC<FoundTemplesRowsListProps> = (props) => {
    return (
        <div className="church__row">
            {props.temples.map((temple, index) => (
                <FoundTempleRow  temple={temple} key={`found_temple_${index}`} />
            ))}
        </div>
    );
};

export default FoundTemplesRowsList;

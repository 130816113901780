import moment from "moment";
import { Months } from "./types/base-enums/months-enum";

export const DEFAULT_PAGE = 1;
export const DEFAULT_OFFSET = 0;
export const DEFAULT_LIMIT = 10;
export const ALL_PAGES = -1;

export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD";
export const SHORT_TIME_FORMAT = "HH:mm";
export const SHORT_DATE_FORMAT = "DD.MM.YY";
export const MAX_SCHEDULE_DATE = "2040.01.01";

export const TEMPLE_PAGE_SIZE = 9;
export const PHOTO_SLIDER_DEFAULT_MAIN_BULLETS = 4;
export const FILE_TYPES = ["jpeg", "jpg", "png", "bmp"];

export const DEFAULT_PRAYER_MAX_PEOPLE = 10;
export const DEFAULT_PRAYER_PRICE = 10;

export const TIME_MASK = "99:99";

export const PHONE_TYPES = ["+380"];

export const MAX_VALUE = 999999;

export const youtubeEmbedLink = "https://www.youtube.com/embed/";

export const DISABLED_COLOR = "#858585";
export const INVALID_INPUT_COLOR = "#ffdddd";
export const DEFAULT_INPUT_COLOR = "#f9f9f9";

export const MAX_DESKTOP_WIDTH = 1500;
export const UPPER_DESKTOP_WIDTH = 1200;
export const AVG_DESKTOP_WIDTH = 1024;
export const PRE1_DESKTOP_WIDTH = 900;
export const PRE2_DESKTOP_WIDTH = 800;
export const MIN_DESKTOP_WIDTH = 767;
export const MAX_MOBILE_WIDTH = 600;
export const AVG_MOBILE_WIDTH = 479;
export const PRE1_MOBILE_WIDTH = 411;
export const PRE2_MOBILE_WIDTH = 359;

export const PAGE_SIZE_ARRAY = [15, 20, 30, 50, 100];

// regular expressions
export const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const link = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const noSpaces = /^\S*$/;
// YouTube
export const youtubeBaseRegex = /https:\/\/www\.youtube\.com\/watch\?.+/;
export const youtubeEmbedRegex = /https:\/\/youtu\.be\/.+/;
export const youtubeBaseEmbedRegex = /https:\/\/www\.youtube\.com\/live\/.+\?feature=share$/;
export const youtubeComplexRegex = new RegExp(`(${youtubeBaseRegex.source})|(${youtubeEmbedRegex.source})|(${youtubeBaseEmbedRegex.source})`);
export const srcRegex = /src="(.*?)"/;

export const DEFAULT_SLIDE_INDEX = 0;

export const PAYMENTS_DECIMAL_PLACES = 2;


export const MIN_CHARTS_YEAR = 2022;
export const MAX_CHARTS_YEAR = moment().year();
export const years = Array.from({ length: (MAX_CHARTS_YEAR - MIN_CHARTS_YEAR + 1) }, (_element, index) => {
    const element = (MIN_CHARTS_YEAR + index).toString();
    return {
        value: element,
        label: element,
    };
});

export const months = [
    Months.JANUARY,
    Months.FEBRUARY,
    Months.MARCH,
    Months.APRIL,
    Months.MAY,
    Months.JUNE,
    Months.JULY,
    Months.AUGUST,
    Months.SEPTEMBER,
    Months.OCTOBER,
    Months.NOVEMBER,
    Months.DECEMBER
];

export const MONTHS_IN_YEAR = 12;
export const HOURS_IN_DAY = 24;


export const payoutPDFPrefix = "payout_";
export const paymentsPDFPrefix = "payments_";


export const supplementaryFields = [
    "id",
    "templeId",
    "userId",
    "parentId",
    "prayerId",
    "templeChangesRequestId",
    "income",
    "status",
    "purposeType",
    "isChanges",
    "isDeleted",
    "createdAt",
    "updatedAt",
];


export const MAX_PAYMENT_METHODS_FOR_PAYOUTS = 1;
export const payoutsCSVPrefix = "payouts_";

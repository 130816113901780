import { FC } from "react";
import Popup from "reactjs-popup";
import ModalWindow from "../../base/ModalWindow";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../types/Translate";

interface PaymentMethodsModalProps {
    t: Translate;
    isModalActive: boolean;
    closeModalCallback: () => void;
    acceptPaymentMethodsCallback: () => void;
}

const PaymentMethodsModal: FC<PaymentMethodsModalProps> = (props) => {
    return (
        <Popup
            open={props.isModalActive}
            modal
            overlayStyle={{ zIndex: 999999, flexDirection: "column" }}
            onClose={props.closeModalCallback}
        >
            <ModalWindow
                contentClass="modal-remove__content"
                bodyClass="information__articles-article"
                closeCallback={props.closeModalCallback}
                content={
                    <>
                        <div className="modal__title modal__title__payment-methods">
                            {props.t("admin.payouts.paymentMethodsModal.title")}
                        </div>
                        <div className="modal__buttons">
                            <button
                                className="modal__button modal__button__text-weighted modal__button-remove modal__button-remove__resolve"
                                onClick={props.acceptPaymentMethodsCallback}
                            >
                                {props.t("admin.payouts.paymentMethodsModal.accept")}
                            </button>
                            <button
                                className="modal__button modal__button-cancel modal__button-cancel__resolve"
                                onClick={props.closeModalCallback}
                            >
                                {props.t("admin.payouts.paymentMethodsModal.cancel")}
                            </button>
                        </div>
                    </>
                }
            />
        </Popup>
    );
};

export default withTranslation()(PaymentMethodsModal);

import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { maxFilesGroupSize } from "../../../../Config";
import { useFormsValidConfirmation } from "../../../../context/FormikFormsProvider";
import { ShopInfoForm, Temple } from "../../../../domain/models/Temple";
import { CreateOrUpdateTemplePath } from "../../../../enums/create-or-update-temple-path-enum";
import { CreateTempleParams } from "../../../../enums/create-temple-params-enum";
import { Owner } from "../../../../enums/parent-enum";
import { Purpose } from "../../../../enums/purpose-enum";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import templeMapperFactory from "../../../../mappers/TempleMapper";
import templeServiceFactory from "../../../../services/TempleServiceImpl";
import { useRedirectToPrevCreateTemplePage } from "../../../../tools/custom-hooks/useRedirect";
import { useUpdateFileOrder } from "../../../../tools/custom-hooks/useUpdateFileOrder";
import { getValidationClass } from "../../../../tools/Tools";
import { ShopSchemaFactory } from "../../../../validation-schemas/temple-register/shop-schemas";
import EditableImagesList from "../../../base/EditableImagesList";
import ChangeEntityButtons from "../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../base/InputRestrictionsInfo";
import Footer from "../footer/Footer";
import MainArticleComponent from "./articles/MainArticleComponent";
import ConflictErrorModal from "../../../base/conflict-error-modal/ConflictErrorModal";
import ContentTooLargeErrorModal from "../../../base/content-too-large-error-modal/ContentTooLargeErrorModal";

interface VisitorsInfoProps {
    t(key?: string): string;
    temple: Temple;
    setTemple(value: Temple): void;
}

const VisitorsInfo: FC<VisitorsInfoProps> = (props) => {
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);
    const updateFileOrder = useUpdateFileOrder<Temple>(props.temple, setIsOrderChanged, props.temple.shopInfoFiles);
    useRedirectToPrevCreateTemplePage(props.temple, TempleRegisterPage.SCHEDULES, props.temple.isSchedulesComplete);

    useEffect(() => {
        if (isOrderChanged) {
            setIsOrderChanged(false);
        }
    }, [isOrderChanged]);

    const templeService = templeServiceFactory();

    const navigate = useNavigate();
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    const initialValues = {
        shopInfo: props.temple.shopInfo,
    };

    const shopSchema = useMemo(() => ShopSchemaFactory({ t: props.t }), [props.t]);

    const shopInfoForm: FormikProps<ShopInfoForm> = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: shopSchema.schema,
        onSubmit: async (values) => {
            const mapper = templeMapperFactory();
            const temple = props.temple;
            const nextUrl = "/create-temple/orders".concat(`?${CreateTempleParams.ID}=${temple.originId}`);

            try {
                setErrorVisibility(false);

                if (_.isEqual(values, shopInfoForm.initialValues) && !temple.isFilesUpdated) {
                    navigate(nextUrl);
                    return;
                }

                temple.setShopInfo(values.shopInfo);

                if (!temple.isVisitorsInfoComplete) {
                    return setErrorVisibility(true);
                }

                const newTemple: Temple | undefined = await templeService.createRequestOrUpdateCurrentEntity(
                    mapper.toLatestTempleDTO(temple), CreateOrUpdateTemplePath.VISITORS_INFO
                );

                if (!newTemple) {
                    return setErrorVisibility(true);
                }

                const files = await temple.resolveFiles(newTemple?.id, temple.shopInfoFiles);
                if (files.isErrorVisible) {
                    return setErrorVisibility(true);
                }

                if (newTemple) {
                    temple.shopInfoFiles = temple.getUpdatedFilesList(files.images, temple.shopInfoFiles);

                    templeService.notifyListeners(temple);
                    props.setTemple(temple);

                    navigate(nextUrl);
                }
            } catch (err) {
                setErrorVisibility(true);
            }
        },
    });

    const { isFormsDataValid } = useFormsValidConfirmation("shopInfoForm", shopInfoForm);

    return (
        <div className="date__content">
            <form onSubmit={shopInfoForm.handleSubmit}>
                <main className="date__main">
                    <h1 className="date__title">
                        {props.t("templeRegister.base.title1")}
                        <br />
                        {props.t("templeRegister.base.title2")}
                    </h1>

                    <div className="prior date__section">
                        <h2 className="date__subtitle">{props.t("templeRegister.visitorsInfo.subtitle")}</h2>

                        <div className="date__form-block">
                            {isErrorVisible && <p>{props.t("errors.common")}</p>}
                            <textarea
                                className={`date__textarea date__input  ${getValidationClass(
                                    shopInfoForm,
                                    "shopInfo"
                                )}`}
                                name="shopInfo"
                                onChange={shopInfoForm.handleChange}
                                value={shopInfoForm.values.shopInfo}
                                cols={30}
                                rows={10}
                                placeholder={props.t("templeRegister.visitorsInfo.info")}
                                maxLength={shopSchema.inputRestrictions.shopInfo.max}
                            ></textarea>
                            <InputRestrictionsInfo
                                min={shopSchema.inputRestrictions.shopInfo.min}
                                max={shopSchema.inputRestrictions.shopInfo.max}
                                error={shopInfoForm.errors.shopInfo}
                            />
                        </div>

                        <EditableImagesList
                            files={props.temple.getSortedFiles(SortingOrder.ASC, props.temple.shopInfoFiles)}
                            setErrorVisibility={setErrorVisibility}
                            setIsFilesUpdated={val => props.temple.setIsFilesUpdated(val)}

                            ownerType={Owner.TEMPLE}
                            purposeType={Purpose.SHOP_INFO}
                            maxImagesCount={maxFilesGroupSize}
                            title={props.t("templeRegister.visitorsInfo.photoInfo")}

                            getMaxOrder={() => props.temple.getMaxOrder(props.temple.shopInfoFiles)}
                            getNewOrder={() => props.temple.getNextFileOrder(props.temple.shopInfoFiles)}

                            moveFileToLeft={updateFileOrder(true)}
                            moveFileToRight={updateFileOrder(false)}
                            removeFileByOrder={(order: number) => props.temple.removeFileByOrder(order, props.temple.shopInfoFiles)}
                        ></EditableImagesList>
                    </div>

                    <ChangeEntityButtons<ShopInfoForm>
                        formik={shopInfoForm}
                        initialValues={initialValues}
                        disableSaveButton={true}
                        resetEntityFormHandler={() => props.temple.resetShopInfoFiles()}
                    />

                    <MainArticleComponent  temple={props.temple} isPilgrims={true} />
                    <MainArticleComponent  temple={props.temple} isPilgrims={false} />
                </main>
                <ConflictErrorModal />
                <ContentTooLargeErrorModal />
                <Footer isFormsDataValid={isFormsDataValid} />
            </form>
        </div>
    );
};

export default withTranslation()(VisitorsInfo);

import { FC, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { EntityFieldTranslation } from "../../../../domain/models/EntityChange";
import { Translate } from "../../../../types/Translate";
import ChangeRequestEntityCheckbox from "./ChangeRequestEntityCheckbox";
import ChangeRequestEntityImage from "./ChangeRequestEntityImage";

interface ChangeRequestEntityTextProps {
    t: Translate;

    fieldName: string;
    fieldTranslation: EntityFieldTranslation;
}

const ChangeRequestEntityText: FC<ChangeRequestEntityTextProps> = (props) => {
    const [height, setHeight] = useState<number>(0);
    const currentHistoryText = useRef(null);
    const originHistoryText = useRef(null);

    const isBooleanValue = props.fieldTranslation.isBoolean;

    useEffect(() => {
        setHeight(
            ((currentHistoryText.current?.clientHeight > originHistoryText.current?.clientHeight
                ? currentHistoryText.current?.clientHeight
                : originHistoryText.current?.clientHeight) || 0) + 45
        );
    }, [currentHistoryText, originHistoryText]);

    const current = isBooleanValue ? (
        <ChangeRequestEntityCheckbox value={props.fieldTranslation.currentValue as boolean} />
    ) : props.fieldTranslation.isArrayOfFile ? (
        <ChangeRequestEntityImage files={props.fieldTranslation.currentValue as string[]} />
    ) : (
        props.fieldTranslation.currentValue?.toString()
    );

    const origin = isBooleanValue ? (
        <ChangeRequestEntityCheckbox value={props.fieldTranslation.originalValue as boolean} />
    ) : props.fieldTranslation.isArrayOfFile ? (
        <ChangeRequestEntityImage files={props.fieldTranslation.originalValue as string[]} />
    ) : (
        props.fieldTranslation.originalValue?.toString()
    );

    return (
        <div className="update__history-body">
            <div className="update__history-blocks update__field__name">
                <h2 className="update__history-title">{props.t(`fields.${props.fieldName}`)}</h2>
            </div>
            <div className="update__history-blocks">
                <div className="update__history-block update__history-present">
                    <h2 className="update__history-title update__history-title--present">
                        {props.t("updates.update.current")}
                    </h2>

                    {!isBooleanValue ? (
                        <div className="update__history-text" style={{ height: height }}>
                            <p ref={currentHistoryText}>{current}</p>
                        </div>
                    ) : (
                        <div style={{ marginLeft: "400px" }}>{current}</div>
                    )}
                </div>

                <div className="update__history-block update__history-past">
                    <h2 className="update__history-title update__history-title--past">
                        {props.t("updates.update.original")}
                    </h2>

                    {!isBooleanValue ? (
                        <div className="update__history-text" style={{ height: height }}>
                            <p ref={originHistoryText}>{origin}</p>
                        </div>
                    ) : (
                        <div style={{ marginRight: "400px" }}>{origin}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(ChangeRequestEntityText);

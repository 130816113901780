import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/public-pages/Login";
import Register from "../components/public-pages/register/Register";
import PublicRoutes from "./public-routes";

interface UnAuthRoutesProps {}

const UnAuthRoutes: FC<UnAuthRoutesProps> = () => {

    return <Routes>
            <Route path="/*" element={<PublicRoutes  />} />
            <Route path="/login" element={<Login  />} />
            <Route path="/register" element={<Register  />} />
    </Routes>
}

export default UnAuthRoutes;

import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../domain/models/Temple";
import youtubeLinkServiceFactory from "../../../services/YoutubeLinkServiceImpl";
import NoPhotoSVG from "../../base/svg/NoPhotoSVG";

interface TempleOnlineProps {
    t(key?: string): string;
    temple: Temple;
}

const TempleOnline: FC<TempleOnlineProps> = (props) => {
    const [youtubeIframeSrc, setYoutubeIframeSrc] = useState<string>(null);

    useEffect(() => {
        const uploadYoutubeEmbedData = async () => {
            const src = await youtubeLinkServiceFactory().getYoutubeEmbedSrc(props.temple.worshipLink);
            setYoutubeIframeSrc(src);
        }
        uploadYoutubeEmbedData();
    }, []);

    return (
        <section id="section_6" className="online">
            <div className="container--min">
                <h2 className="online__title subtitle">{props.t("user.temple.online.subtitle")}</h2>
                <div className="online__stream">
                    {youtubeIframeSrc ? (
                        <iframe
                            className="play"
                            src={youtubeIframeSrc.concat("?color=white")}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    ) : (
                        <NoPhotoSVG wrapperClassName="online__no-photo" />
                    )}
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(TempleOnline);

import { FC } from "react";
import { Temple } from "../../../domain/models/Temple";
import Temple3DTourIframe from "../../base/3d-tour/Temple3DTourIframe";

interface TempleOnline3DProps {
    temple: Temple;
}

const TempleOnline3D: FC<TempleOnline3DProps> = (props) => {
    return (
        <section id="section_7" className="online-3d">
            <div className="container--min">
                <div className="online-3d__block">
                    <Temple3DTourIframe link={props.temple.tourLink} />
                </div>
            </div>
        </section>
    );
};

export default TempleOnline3D;

import ImageUploading, { ImageListType } from "react-images-uploading";
import { FC, useState } from "react";
import { Owner } from "../../enums/parent-enum";
import { Purpose } from "../../enums/purpose-enum";
import { FileModel } from "../../models/FileModel";
import { CustomFile } from "../../domain/models/CustomFile";
import _ from "lodash";
import { FILE_TYPES } from "../../constants";
import { maxFileSizeBytes, maxFilesSizeKb } from "../../Config";
import { withTranslation } from "react-i18next";

interface AddImageButtonProps {
    t(key?: string): string;
    ownerType: Owner;
    purposeType?: Purpose;
    order: number;

    addImage(image: CustomFile): void;
}

const AddImageButton: FC<AddImageButtonProps> = (props) => {
    const [images, setImage] = useState<ImageListType>([]);
    const onChange = (newImages: ImageListType) => {
        const image = newImages[0];
        if (!image.file) {
            return;
        }
        const file = new FileModel({
            dataUrl: image.dataURL,
            ownerType: props.ownerType,
            purposeType: props.purposeType,
            fileType: _.get(image, "file.type", "").split("/")[1],
            order: props.order
        });
        setImage(newImages);
        props.addImage(file);
    };

    return (
        <ImageUploading maxFileSize={maxFileSizeBytes} acceptType={FILE_TYPES} value={images} onChange={onChange} multiple={false}>
            {({ onImageUpload, dragProps, errors }) => (
                <>
                    <button className="date__form-button" type="button" onClick={onImageUpload} {...dragProps}>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 0V23" stroke="#858585" strokeWidth="2" />
                            <path d="M23 12L4.76837e-07 12" stroke="#858585" strokeWidth="2" />
                        </svg>
                    </button>
                    {errors?.acceptType && <span className="file-error">{props.t("errors.photo.acceptType")}</span>}
                    {errors?.maxFileSize && <span className="file-error">{props.t("errors.photo.maxSize")}{maxFilesSizeKb}kb</span>}
                </>
            )}
        </ImageUploading>
    );
};

export default withTranslation()(AddImageButton);

import { apiURL } from "../Config";
import { PrayerRequirementDTO } from "../domain/models/dto/PrayerRequirementDTO";
import { PrayerRequirement } from "../domain/models/PrayerRequirement";
import { PrayerRequirementService } from "../domain/services/PrayerRequirementService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import prayerRequirementMapperFactory from "../mappers/PrayerRequirementMapper";
import CollectionTools from "../tools/CollectionTools";
import { get } from "../tools/Tools";
import { RemoveResponse } from "../types/RemoveResponse";
import { BaseServiceImpl } from "./BaseServiceImpl";

class PrayerRequirementServiceImpl extends BaseServiceImpl implements PrayerRequirementService {
    async createRequestOrUpdateCurrentEntity(dto: PrayerRequirementDTO): Promise<PrayerRequirement> {
        if (!dto.info || !dto.templeId) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<PrayerRequirementDTO, PrayerRequirement>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.PRAYER_REQUIREMENT), prayerRequirementMapperFactory
        );
    }

    async getPrayerRequirementsByTempleId(templeId: string): Promise<PrayerRequirement[]> {
        if (!templeId) {
            return;
        }

        try {
            const prayerRequirementMapper = prayerRequirementMapperFactory();
            return this.getData<PrayerRequirement[]>(await get(`${apiURL}/api/v1/temple/${templeId}/requirements`)).map(
                (prayerRequirement) => prayerRequirementMapper.fromDTO(prayerRequirement)
            );
        } catch (err) {
            console.log("PrayerRequirementServiceImpl.getPrayerRequirementsByTempleId => ERROR:");
            console.log(err);
        }
    }

    getSortedPrayerRequirementsByOrder(sortingOrder: SortingOrder, prayerRequirements: PrayerRequirement[]): PrayerRequirement[] {
        return CollectionTools.sortEntities<PrayerRequirement>(prayerRequirements, sortingOrder);
    }

    async removeEntity(entity: PrayerRequirement): Promise<RemoveResponse> {
        return await this.removeEntityById<PrayerRequirementDTO, PrayerRequirement>(entity, this.getV2EntityUrl(EntitiesBaseUrls.PRAYER_REQUIREMENT), prayerRequirementMapperFactory);
    }
}

export default function prayerRequirementServiceFactory(): PrayerRequirementService {
    return new PrayerRequirementServiceImpl();
}

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../domain/models/Temple";

interface TempleContactsProps {
    t(key?: string): string;
    temple: Temple;
}

const TempleContacts: FC<TempleContactsProps> = (props) => {
    return (
        <section id="section_9" className="contacts">
            <div className="container--min">
                <h2 className="contacts__title subtitle">{props.t("user.temple.contacts.subtitle")}</h2>
                <div className="contacts__inner">
                    {props.temple.isTempleContactDataExist && (
                        <div className="contacts__block">
                            <h6 className="contacts__subtitle">{props.temple.name}</h6>
                            <div className="contacts__text text">
                                {props.temple.contactStreet}, {props.temple.contactBuildingNumber},
                                {props.temple.contactCity}, {props.temple.contactCountry}
                            </div>
                        </div>
                    )}

                    {props.temple.isOfficeContactDataExist && (
                        <div className="contacts__block">
                            <h6 className="contacts__subtitle">{props.t("user.temple.contacts.office")}</h6>
                            <div className="contacts__text text">
                                {props.temple.contactPhone &&
                                    props.temple.contactPhone + " " + props.t("user.temple.contacts.phone")}
                            </div>
                            <div className="contacts__text text">
                                {props.temple.contactSecondPhone &&
                                    props.temple.contactSecondPhone + " " + props.t("user.temple.contacts.secondPhone")}
                            </div>
                            <div className="contacts__text text">
                                {props.temple.contactEmail &&
                                    props.temple.contactEmail + " " + props.t("user.temple.contacts.email")}
                            </div>
                        </div>
                    )}

                    {props.temple.contactInfo && (
                        <div className="contacts__block">
                            <h6 className="contacts__subtitle">{props.t("user.temple.contacts.additional")}</h6>
                            <div className="contacts__text text">{props.temple.contactInfo}</div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(TempleContacts);

import { FC, MutableRefObject } from "react";
import { Artifact } from "../../../../../domain/models/Artifact";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import artifactServiceFactory from "../../../../../services/ArtifactServiceImpl";
import ArtifactInfo from "./ArtifactInfo";
import { useRemove } from "../../../../../tools/custom-hooks/remove-hooks/useRemove";
import { ArtifactService } from "../../../../../domain/services/ArtifactService";
import { Translate } from "../../../../../types/Translate";
import { withTranslation } from "react-i18next";
import { ArtifactDTO } from "../../../../../domain/models/dto/ArtifactDTO";

interface ArtifactsProps {
    t: Translate;

    artifacts: Artifact[];
    setCurrentArtifact(value: Artifact): void;
    removeArtifact(id: string): void;
    artifactFormRef: MutableRefObject<any>;
}

const Artifacts: FC<ArtifactsProps> = (props) => {
    const artifactService = artifactServiceFactory();
    const { isErrorVisible, setRemovableModel } = useRemove<ArtifactDTO, Artifact, ArtifactService>(
        artifactServiceFactory,
        props.removeArtifact
    );

    return (
        <div className="entity-container-scroll">
            {isErrorVisible && props.t("errors.common")}
            {artifactService
                .getSortedArtifactsByCreateDate(SortingOrder.ASC, props.artifacts)
                .map((artifact, index) => (
                    <div key={index}>
                        <ArtifactInfo
                            artifact={artifact}
                            setCurrentArtifact={props.setCurrentArtifact}
                            setRemovableModel={setRemovableModel}
                            artifactFormRef={props.artifactFormRef}
                            additionalWrapperClassName={index === 0 ? "" : "establish__info__wrapper"}
                        />
                    </div>
                ))}
        </div>
    );
};

export default withTranslation()(Artifacts);

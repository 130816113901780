import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateTempleParams } from "../../../enums/create-temple-params-enum";
import { Translate } from "../../../types/Translate";

interface TempleButtonProps {
    t: Translate;
}

const TempleButton: FC<TempleButtonProps> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="my-church__btn">
            <div className="my-church__btn-block" onClick={() => navigate(`/create-temple/main-info?${CreateTempleParams.NEW_TEMPLE}=true`)}>
                <div className="plus"></div>
                <span>{props.t("templeAdmin.main.newTemple")}</span>
            </div>
        </div>
    );
};

export default withTranslation()(TempleButton);

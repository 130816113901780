import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";

export const ArticleSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        header: {
            min: 2,
            max: 100
        },
        body: {
            min: 5,
            max: 5000
        }
    }

    return {
        schema: Yup.object().shape({
            id: Yup.string(),
            header: Yup.string()
                .min(inputRestrictions.header.min, t("errors.commonErrors.min") + inputRestrictions.header.min)
                .max(inputRestrictions.header.max, t("errors.commonErrors.max") + inputRestrictions.header.max)
                .required(t("errors.commonErrors.required")),
            body: Yup.string()
                .min(inputRestrictions.body.min, t("errors.commonErrors.min") + inputRestrictions.body.min)
                .max(inputRestrictions.body.max, t("errors.commonErrors.max") + inputRestrictions.body.max)
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

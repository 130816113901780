import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Payment as IPayment } from "../../../domain/models/Payment";
import { Prayer } from "../../../domain/models/Prayer";
import { Temple } from "../../../domain/models/Temple";
import paymentServiceFactory from "../../../services/PaymentServiceImpl";
import prayerServiceFactory from "../../../services/PrayerServiceImpl";
import templeServiceFactory from "../../../services/TempleServiceImpl";

interface BasePaymentProps {
    t(key?: string): string;
    isSuperAdminUser?: boolean;
    isTemplePayments?: boolean;
}

const BasePayment: FC<BasePaymentProps> = (props) => {
    const t = props.t;
    const navigate = useNavigate();
    const { templeId, paymentId } = useParams();

    const paymentService = paymentServiceFactory();
    const templeService = templeServiceFactory();
    const prayerService = prayerServiceFactory();

    const [payment, setPayment] = useState<IPayment>();
    const [temple, setTemple] = useState<Temple>();
    const [prayer, setPrayer] = useState<Prayer>();

    const recipientName = payment?.templeId ? temple?.name : t("templeAdmin.payments.platformLabel");

    useEffect(() => {
        const uploadData = async () => {
            paymentService.getCachedPaymentById(paymentId).then((payment) => {
                setPayment(payment);

                templeService.getCachedTempleOrUploadById(templeId || payment.templeId).then((temple) => {
                    setTemple(temple);
                });

                prayerService.getPrayerById(payment?.prayerId).then((prayer) => {
                    setPrayer(prayer);
                });
            });
        };

        if (!payment && !temple && !prayer) {
            uploadData();
        }
    }, [payment, paymentId, paymentService, templeService, temple, templeId, prayer, prayerService]);

    const navigateToPayments = () => {
        if (props.isSuperAdminUser) {
            return props.isTemplePayments
                ? navigate(`/admin/temples/${temple.id}/payments`)
                : navigate("/admin/payments");
        }

        navigate("/temple-admin/payments");
    };

    const isResolvePayoutButtonActive = props.isSuperAdminUser && payment?.inReview;
    const navigateToPayouts = () => {
        if (!payment?.id) {
            return;
        }

        if (payment.payoutId) {
            navigate(`/admin/payouts/${payment.payoutId}`);
        } else {
            navigate("/admin/payouts/credits");
        }
    };

    const getResolvePayoutButtonTranslations = () => {
        return payment?.payoutId
            ? props.t("templeAdmin.payments.payment.openPayoutLabel")
            : props.t("templeAdmin.payments.payment.openCreditsLabel");
    };

    return (
        <main className="main">
            <section className="inner-page">
                <div className="inner-page__topping">
                    <div className="inner-page__wrap inner-page__wrap__centralized">
                        <button onClick={() => navigateToPayments()} className="back-button">
                            <svg
                                width="7"
                                height="10"
                                viewBox="0 0 7 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M6 1L1.5 5L6 9" stroke="#F9F9F9" strokeLinecap="round"></path>
                            </svg>
                        </button>

                        <div className="inner-page__title">{recipientName}</div>

                        {isResolvePayoutButtonActive ? (
                            <button
                                onClick={() => navigateToPayouts()}
                                className="payout-information-button payout-information-button__finish"
                            >
                                {getResolvePayoutButtonTranslations()}
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>

                    {props.isSuperAdminUser && (
                        <div className="inner-page__wrap">
                            <div className="inner-page__money">
                                <div className="inner-page__money-block">
                                    {t("templeAdmin.payments.payment.initial")}
                                    <span>{payment?.initialPlatformBalanceFloatFormat}$</span>
                                </div>

                                <div className="inner-page__money-block">
                                    {t("templeAdmin.payments.payment.final")}
                                    <span>{payment?.finalPlatformBalanceFloatFormat}$</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="holy">
                    <div className="inner__content15">
                        <table className="holy__info">
                            <tbody>
                                <tr>
                                    <td className="holy__info-descr">{t("templeAdmin.payments.payment.senderName")}</td>
                                    <td className="holy__info-value">{payment?.senderName}</td>
                                </tr>

                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.paymentDate")}
                                    </td>
                                    <td className="holy__info-value">{payment?.paymentDateStr}</td>
                                </tr>
                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.paymentDateConfirmation")}
                                    </td>
                                    <td className="holy__info-value">{payment?.confirmationDateStr}</td>
                                </tr>

                                <tr>
                                    <td className="holy__info-descr">{t("templeAdmin.payments.payment.paymentId")}</td>
                                    <td className="holy__info-value">{payment?.paymentId}</td>
                                </tr>

                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.recipientName")}
                                    </td>
                                    <td className="holy__info-value">{recipientName}</td>
                                </tr>

                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.transactionType")}
                                    </td>
                                    <td className="holy__info-value">
                                        {t(`transactionType.${payment?.transactionType}`)}
                                    </td>
                                    {!!payment?.prayerId && <td className="holy__info-value ">{prayer?.name}</td>}
                                    {!!payment?.prayerId && <td className="holy__info-value ">{prayer?.info}</td>}
                                </tr>
                                {props.isSuperAdminUser ? (
                                    <tr>
                                        <td className="holy__info-descr">
                                            {t("templeAdmin.payments.payment.totalAmount")}
                                        </td>
                                        <td className="holy__info-value">{payment?.amountFloatFormat}$</td>
                                    </tr>
                                ) : (
                                    ""
                                )}
                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.recipientAmount")}
                                    </td>
                                    <td className="holy__info-value">{payment?.recipientAmountFloatFormat}$</td>
                                    <td className="holy__info-value">
                                        {t("templeAdmin.payments.payment.initial")}
                                        {payment?.initialRecipientBalanceFloatFormat}$
                                    </td>
                                    <td className="holy__info-value">
                                        {t("templeAdmin.payments.payment.final")}
                                        {payment?.finalRecipientBalanceFloatFormat}$
                                    </td>
                                </tr>

                                {props.isSuperAdminUser && (
                                    <tr>
                                        <td className="holy__info-descr">
                                            {t("templeAdmin.payments.payment.platformAmount")}
                                        </td>
                                        <td className="holy__info-value">{payment?.platformAmountFloatFormat}$</td>
                                        <td className="holy__info-value">
                                            {t("templeAdmin.payments.payment.initial")}
                                            {payment?.initialPlatformBalanceFloatFormat}$
                                        </td>
                                        <td className="holy__info-value">
                                            {t("templeAdmin.payments.payment.final")}
                                            {payment?.finalPlatformBalanceFloatFormat}$
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.senderEmail")}
                                    </td>
                                    <td className="holy__info-value">{payment?.senderEmail}</td>
                                </tr>

                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.paymentMethod")}
                                    </td>
                                    <td className="holy__info-value">{t(`paymentMethod.${payment?.paymentMethod}`)}</td>
                                </tr>

                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.senderAccountId")}
                                    </td>
                                    <td className="holy__info-value">{payment?.senderAccountId}</td>
                                </tr>

                                <tr>
                                    <td className="holy__info-descr">
                                        {t("templeAdmin.payments.payment.recipientAccountId")}
                                    </td>
                                    <td className="holy__info-value">{payment?.recipientPaymentAccountId}</td>
                                </tr>

                                <tr>
                                    <td className="holy__info-descr">{t("templeAdmin.payments.payment.manager")}</td>
                                    <td className="holy__info-value">{payment?.adminId}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default withTranslation()(BasePayment);

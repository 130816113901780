import { useState } from "react";
import { BaseEntity } from "../../domain/models/BaseEntity";
import CollectionTools from "../CollectionTools";

interface IBaseEntityManipulationHooks<Model> {
    currentEntity: Model;
    entities: Model[];

    setCurrentEntity: (entity: Model) => void;
    updateEntities: (newEntity: Model) => void;
    removeEntity: (entityId: string, order?: number) => void;
    removeOrderedEntity: (order: number) => Model[];
    resetFormData: () => void;
}

export function BaseEntityManipulationHooks<Model extends BaseEntity>(
    entityConstructor: any,
    initialEntities: Model[],
    setEntityCallback: (entity: Model[]) => void,
    removeOrderedEntityCallback?: (order: number) => Model[],
): IBaseEntityManipulationHooks<Model> {
    const [currentEntity, setCurrentEntity] = useState<Model>(new entityConstructor());
    const [entities, setEntities] = useState<Model[]>(initialEntities);

    const updateEntities = (newEntity: Model) => {
        const newEntitiesList = CollectionTools.updateEntityList([newEntity], entities);
        setEntityCallback(newEntitiesList);

        setEntities(newEntitiesList);
        setCurrentEntity(new entityConstructor());
    };

    const removeEntity = (entityId: string) => {
        const newEntitiesList = entities.filter((entity) => entity.id !== entityId);
        setEntityCallback(newEntitiesList);
        setEntities(newEntitiesList);
    }

    const removeOrderedEntity = (order: number) => {
        if (!removeOrderedEntityCallback) {
            return;
        }

        const newEntitiesList = removeOrderedEntityCallback(order);
        setEntityCallback(newEntitiesList);
        setEntities(newEntitiesList);

        return newEntitiesList;
    }

    const resetFormData = () => {
        setCurrentEntity(new entityConstructor());
    }

    return {
        currentEntity,
        entities,
        setCurrentEntity,
        updateEntities,
        removeEntity,
        removeOrderedEntity,
        resetFormData,
    };
};

import { FormikProps, useFormik } from "formik";
import { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { DISABLED_COLOR } from "../../../../../constants";
import { useFormsValidConfirmation } from "../../../../../context/FormikFormsProvider";
import {
    PrayerRequirement,
    PrayerRequirementForm as IPrayerRequirementForm,
} from "../../../../../domain/models/PrayerRequirement";
import { Temple } from "../../../../../domain/models/Temple";
import prayerRequirementServiceFactory from "../../../../../services/PrayerRequirementServiceImpl";
import { getValidationClass } from "../../../../../tools/Tools";
import { PrayerRequirementSchemaFactory } from "../../../../../validation-schemas/temple-register/prayer-requirement-schemas";
import ChangeEntityButtons from "../../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../../base/InputRestrictionsInfo";
import AddButtonSVG from "../../../../base/svg/AddButtonSVG";
import ConflictErrorModal from "../../../../base/conflict-error-modal/ConflictErrorModal";

interface PrayerRequirementFormProps {
    t(key?: string): string;
    prayerRequirement: PrayerRequirement;
    temple: Temple;

    updatePrayerRequirements(newPrayerRequirement: PrayerRequirement): void;
    resetPrayerRequirementForm(): void;
    resetErrorCallback: () => void;

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    setFormRef(value: MutableRefObject<any>): void;
}

const PrayerRequirementForm: FC<PrayerRequirementFormProps> = (props) => {
    const prayerRequirementService = prayerRequirementServiceFactory();

    const formRef = useRef(null);

    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    const nullableValues: IPrayerRequirementForm = {
        id: "",
        order: undefined,
        info: "",
    };

    const prayerRequirementSchema = useMemo(() => PrayerRequirementSchemaFactory({ t: props.t }), [props.t]);

    const prayerRequirementForm: FormikProps<IPrayerRequirementForm> = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            order: undefined,
            info: props.prayerRequirement?.info,
        },
        validationSchema: prayerRequirementSchema.schema,
        onSubmit: async (values, actions) => {
            try {
                setErrorVisibility(false);
                props.resetErrorCallback();
                actions.setSubmitting(true);

                const newPrayerRequirement: PrayerRequirement | undefined =
                    await prayerRequirementService.createRequestOrUpdateCurrentEntity({
                        templeId: props.temple.originId,
                        order: values.order || props.temple.getNextPrayerRequirementOrder(),
                        id: values.id || undefined,
                        info: values.info,
                    });

                if (!newPrayerRequirement) {
                    return setErrorVisibility(true);
                }

                if (newPrayerRequirement) {
                    props.updatePrayerRequirements(newPrayerRequirement);
                    props.setIsEditing(false);
                }

                actions.resetForm({
                    values: nullableValues,
                    touched: {},
                    errors: {},
                    isSubmitting: false,
                });
            } catch {
                setErrorVisibility(true);
            }
        },
    });

    useFormsValidConfirmation("prayerRequirementForm", prayerRequirementForm);

    const editPrayerRequirementFormHandler = () => {
        prayerRequirementForm.setValues({
            ...prayerRequirementForm.values,
            id: props.prayerRequirement?.id,
            order: props.prayerRequirement.order || props.temple.getNextPrayerRequirementOrder(),
        });
        prayerRequirementForm.handleSubmit();
    };

    useEffect(() => {
        if (formRef) {
            props.setFormRef(formRef);
        }
    });

    const resetPrayerRequirementForm = useCallback(() => {
        props.resetPrayerRequirementForm();
        props.setIsEditing(false);
    }, [props]);

    return (
        <div ref={formRef} className="date__form-block choose__temple__pagination">
            <textarea
                className={`order__textarea-description date__input ${getValidationClass(
                    prayerRequirementForm,
                    "info"
                )}`}
                name="info"
                onChange={prayerRequirementForm.handleChange}
                value={!prayerRequirementForm.isSubmitting ? prayerRequirementForm.values.info : nullableValues.info}
                cols={30}
                rows={10}
                placeholder={props.t("templeRegister.orders.textPlaceholder2")}
                maxLength={prayerRequirementSchema.inputRestrictions.info.max}
            ></textarea>
            <InputRestrictionsInfo
                min={prayerRequirementSchema.inputRestrictions.info.min}
                max={prayerRequirementSchema.inputRestrictions.info.max}
                error={prayerRequirementForm.errors.info}
            />

            <div className="date__bottom-buttons">
                <ChangeEntityButtons<IPrayerRequirementForm>
                    formik={prayerRequirementForm}
                    initialValues={nullableValues}
                    disableSaveButton={!props.prayerRequirement.id}
                    editFormHandler={editPrayerRequirementFormHandler}
                    resetEntityFormHandler={resetPrayerRequirementForm}
                />

                {!props.prayerRequirement.id && (
                    <button
                        className={
                            !prayerRequirementForm.isValid
                                ? "disabled-add-button worship__button-add"
                                : "date__button-add worship__button-add"
                        }
                        type="button"
                        onClick={() => prayerRequirementForm.handleSubmit()}
                        disabled={!!props.prayerRequirement?.id || prayerRequirementForm.isSubmitting}
                    >
                        <span>
                            <AddButtonSVG fill={!prayerRequirementForm.isValid && DISABLED_COLOR} />
                        </span>
                        {props.t("templeRegister.orders.add")}
                    </button>
                )}

                {isErrorVisible && <p>{props.t("errors.common")}</p>}
            </div>
            <ConflictErrorModal />
        </div>
    );
};

export default withTranslation()(PrayerRequirementForm);

import { Mapper } from "../domain/mappers/mapper";
import { TempleStatistic } from "../domain/models/TempleStatistic";
import { TempleStatisticDTO } from "../domain/models/dto/TempleStatisticDTO";
import TempleStatisticModel from "../models/TempleStatisticModel";
import { SimpleMapper } from "./SimpleMapper";

class TempleStatisticMapper extends SimpleMapper<TempleStatistic, TempleStatisticDTO> {
    protected entityConstructor = TempleStatisticModel;

    protected toDTOFields: string[] = [];

    protected fromDTOFields: string[] = [
        "id",
        "templeName",
        "income",

        "templeId",

        "totalTransactionAmount",
        "totalTransactionPrayersAmount",
        "totalTransactionDonationsAmount",

        "totalPlatformTransactionAmount",
        "totalPlatformTransactionPrayersAmount",
        "totalPlatformTransactionDonationsAmount",

        "totalTempleTransactionAmount",
        "totalTempleTransactionPrayersAmount",
        "totalTempleTransactionDonationsAmount",
        "totalTransactionCount",
        "totalTransactionPrayersCount",
        "totalTransactionDonationsCount",

        "inReviewTempleTransactionAmount",
        "inReviewTempleTransactionPrayersAmount",
        "inReviewTempleTransactionDonationsAmount",
        "inReviewTransactionCount",
        "inReviewTransactionPrayersCount",
        "inReviewTransactionDonationsCount",

        "finalTempleTransactionAmount",
        "finalTempleTransactionPrayersAmount",
        "finalTempleTransactionDonationsAmount",
        "finalTransactionCount",
        "finalTransactionPrayersCount",
        "finalTransactionDonationsCount",

        "templePaidAmount",
        "paidAmount",
        "paidCount",

        "defaultPaymentMethod",
        "dateOfApprovalLatestPayout",
        "activePayouts",

        "createdAt",
        "updatedAt",
    ];
}

export default function templeStatisticMapperFactory(): Mapper<TempleStatistic, TempleStatisticDTO> {
    return new TempleStatisticMapper()
}

import * as Yup from "yup"
import { SchemaFactoryData, SchemaFactoryProps } from "../../types/SchemaFactory";


export const MainInfoSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        templeName: {
            min: 5,
            max: 1000,
        },
        country: {
            min: 2,
            max: 100
        },
        city: {
            min: 2,
            max: 100
        },
        templeInfo: {
            min: 2,
            max: 20000
        }
    }

    return {
        schema: Yup.object().shape({
            templeName: Yup.string()
                .min(inputRestrictions.templeName.min, t("errors.commonErrors.min") + inputRestrictions.templeName.min)
                .max(inputRestrictions.templeName.max, t("errors.commonErrors.max") + inputRestrictions.templeName.max)
                .required(t("errors.commonErrors.required")),
            religionId: Yup.number()
                .required(t("errors.commonErrors.required")),
            religionCurrentId: Yup.number()
                .required(t("errors.commonErrors.required")),
            religionSubspeciesId: Yup.number()
                .required(t("errors.commonErrors.required")),
            country: Yup.string()
                .min(inputRestrictions.country.min, t("errors.commonErrors.min") + inputRestrictions.country.min)
                .max(inputRestrictions.country.max, t("errors.commonErrors.max") + inputRestrictions.country.max)
                .required(t("errors.commonErrors.required")),
            city: Yup.string()
                .min(inputRestrictions.city.min, t("errors.commonErrors.min") + inputRestrictions.city.min)
                .max(inputRestrictions.city.max, t("errors.commonErrors.max") + inputRestrictions.city.max)
                .required(t("errors.commonErrors.required")),
            templeInfo: Yup.string()
                .min(inputRestrictions.templeInfo.min, t("errors.commonErrors.min") + inputRestrictions.templeInfo.min)
                .max(inputRestrictions.templeInfo.max, t("errors.commonErrors.max") + inputRestrictions.templeInfo.max)
                .required(t("errors.commonErrors.required"))
        }), inputRestrictions
    };
}

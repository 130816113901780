import { CSSProperties, FC } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../../domain/models/Temple";
import religionsServiceFactory from "../../../../services/ReligionsServiceImpl";
import { apiURL } from "../../../../Config";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import TitleTruncateWrapper from "../../../base/TitleTruncateWrapper";

interface PopularTemplesSlideProps {
    t(key?: string): string;
    temple: Temple;
}

const PopularTemplesSlide: FC<PopularTemplesSlideProps> = (props) => {
    const religionService = religionsServiceFactory();

    const filePath = props.temple.getSortedFiles(SortingOrder.ASC)[0]?.filePath;
    const swiperSlideStyles: CSSProperties = {
        backgroundImage: `url(${apiURL}/files/${encodeURIComponent(filePath)})`,
    };

    return (
        <div className="swiper-slide" style={filePath && swiperSlideStyles}>
            <div className="popular__slide-block">
                <TitleTruncateWrapper
                    content={<h4 className="popular__slide-title">{props.temple.name}</h4>}
                    dataHover={props.temple.name}
                    wrapperClassName="hover-text-title"
                    textSpecsArray={[{ 900: 52 }, { 99999: 76 }]}
                />
                <div className="popular__slide-item">
                    <span className="popular__slide-type">{props.t("user.main.popular.religion")}</span>
                    <span className="popular__slide-text">
                        {props.t("religions." + religionService.getReligion(props.temple.religionId)?.label)}
                    </span>
                </div>
                <div className="popular__slide-item">
                    <span className="popular__slide-type">{props.t("user.main.popular.city")}</span>
                    <span className="popular__slide-text">{props.temple.city}</span>
                </div>
                <a href={`/temple/${props.temple.id}`} className="popular__slide-btn">
                    {props.t("user.main.popular.details")}
                </a>
            </div>
        </div>
    );
};

export default withTranslation()(PopularTemplesSlide);

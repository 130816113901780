import { FormikProps, useFormik } from "formik";
import { FC, MutableRefObject, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { maxFilesGroupSize } from "../../../../../Config";
import { DISABLED_COLOR } from "../../../../../constants";
import { useFormsValidConfirmation } from "../../../../../context/FormikFormsProvider";
import { Article, ArticleForm as IArticleForm } from "../../../../../domain/models/Article";
import { Temple } from "../../../../../domain/models/Temple";
import { ArticlePurpose } from "../../../../../enums/article-purpose-enum";
import { Owner } from "../../../../../enums/parent-enum";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import articleServiceFactory from "../../../../../services/ArticleServiceImpl";
import { useUpdateFileOrder } from "../../../../../tools/custom-hooks/useUpdateFileOrder";
import { getValidationClass } from "../../../../../tools/Tools";
import { ArticleSchemaFactory } from "../../../../../validation-schemas/temple-register/article-schema";
import EditableImagesList from "../../../../base/EditableImagesList";
import ChangeEntityButtons from "../../../../base/formik/ChangeEntityButtons";
import InputRestrictionsInfo from "../../../../base/InputRestrictionsInfo";
import AddButtonSVG from "../../../../base/svg/AddButtonSVG";
import ConflictErrorModal from "../../../../base/conflict-error-modal/ConflictErrorModal";
import ContentTooLargeErrorModal from "../../../../base/content-too-large-error-modal/ContentTooLargeErrorModal";

interface ArticleFormProps {
    t(key?: string): string;
    article: Article;
    temple: Temple;

    isPilgrims: boolean;
    updateArticles(newArticle: Article): void;
    resetArticleForm(): void;
    articleFormRef: MutableRefObject<any>;
}

const ArticleForm: FC<ArticleFormProps> = (props) => {
    const articleService = articleServiceFactory();

    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);

    const updateFileOrder = useUpdateFileOrder<Article>(props.article, setIsOrderChanged);

    const nullableValues: IArticleForm = {
        id: "",
        header: "",
        body: "",
    };

    const articleSchema = useMemo(() => ArticleSchemaFactory({ t: props.t }), [props.t]);

    const articleForm: FormikProps<IArticleForm> = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            header: props.article?.header,
            body: props.article?.body,
        },
        validationSchema: articleSchema.schema,
        onSubmit: async (values, actions) => {
            const article = props.article;

            try {
                setErrorVisibility(false);
                actions.setSubmitting(true);

                const newArticle = await articleService.createRequestOrUpdateCurrentEntity({
                    templeId: props.temple.originId,
                    id: values.id || undefined,
                    purposeType: props.isPilgrims ? ArticlePurpose.FOR_PILGRIMS : ArticlePurpose.OTHER,
                    header: values.header,
                    body: values.body,
                });

                if (!newArticle) {
                    return setErrorVisibility(true);
                }

                const files = await article.resolveFiles(newArticle?.id);
                if (files.isErrorVisible) {
                    return setErrorVisibility(true);
                }

                if (newArticle) {
                    article.id && newArticle.setCreatedAt(article.createdAt);
                    newArticle.setFiles(article.getUpdatedFilesList(files.images));

                    props.updateArticles(newArticle);
                }

                actions.resetForm({
                    values: nullableValues,
                    touched: {},
                    errors: {},
                    isSubmitting: false,
                });
            } catch {
                setErrorVisibility(true);
            }
        },
    });

    useFormsValidConfirmation("articleForm", articleForm);

    const editArticleFormHandler = () => {
        articleForm.setValues({
            ...articleForm.values,
            id: props.article?.id,
        });
        articleForm.handleSubmit();
    };

    useEffect(() => {
        if (isOrderChanged) {
            setIsOrderChanged(false);
        }
    }, [isOrderChanged]);

    return (
        <div className="choose__temple__pagination" ref={props.articleFormRef}>
            <div className="date__form-block">
                <input
                    className={`date__input ${getValidationClass(articleForm, "header")}`}
                    name="header"
                    onChange={articleForm.handleChange}
                    value={!articleForm.isSubmitting ? articleForm.values.header : nullableValues.header}
                    type="text"
                    placeholder={
                        props.isPilgrims
                            ? props.t("templeRegister.visitorsInfo.textPlaceholder")
                            : props.t("templeRegister.visitorsInfo.header")
                    }
                    maxLength={articleSchema.inputRestrictions.header.max}
                />
                <InputRestrictionsInfo
                    min={articleSchema.inputRestrictions.header.min}
                    max={articleSchema.inputRestrictions.header.max}
                    error={articleForm.errors.header}
                />
            </div>

            <div className="date__form-block">
                <textarea
                    className={`date__textarea date__input ${getValidationClass(articleForm, "body")}`}
                    name="body"
                    onChange={articleForm.handleChange}
                    value={!articleForm.isSubmitting ? articleForm.values.body : nullableValues.body}
                    cols={30}
                    rows={10}
                    placeholder={props.t("templeRegister.visitorsInfo.info")}
                    maxLength={articleSchema.inputRestrictions.body.max}
                ></textarea>
                <InputRestrictionsInfo
                    min={articleSchema.inputRestrictions.body.min}
                    max={articleSchema.inputRestrictions.body.max}
                    error={articleForm.errors.body}
                />
            </div>

            <EditableImagesList
                files={props.article?.getSortedFiles(SortingOrder.ASC)}
                setErrorVisibility={setErrorVisibility}
                setIsFilesUpdated={(val) => props.article.setIsFilesUpdated(val)}
                title={props.t("templeRegister.visitorsInfo.photoInfo")}
                ownerType={Owner.ARTICLE}
                maxImagesCount={maxFilesGroupSize}
                getMaxOrder={props.article.getMaxOrder}
                getNewOrder={props.article.getNextFileOrder}
                moveFileToLeft={updateFileOrder(true)}
                moveFileToRight={updateFileOrder(false)}
                removeFileByOrder={(order: number) => props.article.removeFileByOrder(order)}
            ></EditableImagesList>

            <div className="date__bottom-buttons">
                <ChangeEntityButtons<IArticleForm>
                    formik={articleForm}
                    initialValues={nullableValues}
                    disableSaveButton={!props.article.id}
                    editFormHandler={editArticleFormHandler}
                    resetEntityFormHandler={props.resetArticleForm}
                />

                {isErrorVisible && <p>{props.t("errors.common")}</p>}

                {!props.article.id && (
                    <button
                        className={
                            !articleForm.isValid
                                ? "disabled-add-button worship__button-add"
                                : "date__button-add worship__button-add"
                        }
                        type="button"
                        onClick={() => articleForm.handleSubmit()}
                        disabled={!!props.article.id || articleForm.isSubmitting}
                    >
                        <span>
                            <AddButtonSVG fill={!articleForm.isValid && DISABLED_COLOR} />
                        </span>
                        {props.t("templeRegister.visitorsInfo.addInfo")}
                    </button>
                )}
                <ConflictErrorModal />
                <ContentTooLargeErrorModal />
            </div>
        </div>
    );
};

export default withTranslation()(ArticleForm);

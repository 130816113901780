import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Translate } from "../../../../types/Translate";
import { withTranslation } from "react-i18next";
import CreditsTable from "./CreditsTable";
import { useSortingColumns } from "../../../../tools/custom-hooks/table-hooks/useSortingColumns";
import AdminPagePagination from "../../../base/AdminPagePagination";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import { TempleStatistic, TempleStatisticsCollection } from "../../../../domain/models/TempleStatistic";
import { templeStatisticServiceFactory } from "../../../../services/TempleStatisticServiceImpl";
import { DEFAULT_OFFSET } from "../../../../constants";
import { TempleStatisticsSortFields } from "../../../../enums/temple-statistics-sort-enum";
import { UseTableRowsActionsHandlers } from "../../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import { Payout } from "../../../../domain/models/Payout";
import { PaymentMethod } from "../../../../enums/payment-method-enum";

interface CreditsTabProps {
    t: Translate;
    totalRowsHandler: (value: number) => void;
    rowsHandlers: UseTableRowsActionsHandlers<TempleStatistic, Payout>;
    defaultPaymentMethod: PaymentMethod;
}

const templeStatisticService = templeStatisticServiceFactory();

const CreditsTab: FC<CreditsTabProps> = (props) => {
    const [data, setData] = useState<TempleStatisticsCollection>(
        templeStatisticService.getEmptyTempleStatisticCollection()
    );
    const columnsHandlers = useSortingColumns(SortingOrder.DESC);
    const creditsTableRef = useRef<HTMLTableElement>(null);
    const paginationCallback = useCallback(
        async (currentPage: number, pageSize: number) => {
            const result = await templeStatisticService.getAllTempleStatistics({
                page: currentPage,
                limit: pageSize,
                offset: DEFAULT_OFFSET,
                sort: columnsHandlers.currentActiveColumn as TempleStatisticsSortFields,
                sortingOrder: columnsHandlers.sortingOrder,
            }, props.defaultPaymentMethod);

            props.totalRowsHandler(result.totalRows);
            props.rowsHandlers.setReloadCache(false);
            columnsHandlers.setReloadCache(false);
            return result;
        },
        [columnsHandlers, props]
    );

    useEffect(() => {
        if (props.rowsHandlers.selectAllRows) {
            props.rowsHandlers.addRows(data.rows);
        }
    }, [props.rowsHandlers, data]);

    useEffect(() => {
        columnsHandlers.setReloadCache(props.rowsHandlers.reloadCache);
    }, [props.rowsHandlers.reloadCache, columnsHandlers.setReloadCache]);

    return (
        <>
            <CreditsTable
                creditsTableRef={creditsTableRef}
                credits={data.rows}
                sortingColumnsHandlers={columnsHandlers}
                rowsHandlers={props.rowsHandlers}
            />
            <div className="church__pagination__admin__block">
                <AdminPagePagination
                    additionalPaginationClass="search__data__pagination"
                    pagesCount={data.totalPages}
                    wrapperRef={creditsTableRef}
                    data={data}
                    reloadCache={columnsHandlers.reloadCache}
                    setData={setData}
                    setReloadCache={columnsHandlers.setReloadCache}
                    serviceCallback={paginationCallback}
                />
            </div>
        </>
    );
};

export default withTranslation()(CreditsTab);

import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../../domain/models/Temple";
import Articles from "./articles/Articles";
import Schedule from "./Schedule";
import Shop from "./shop/Shop";
import WorshipSchedules from "./worship-schedules/WorshipSchedules";

interface TempleInformationProps {
    t(key?: string): string;
    temple: Temple;
}

enum CurrentPanel {
    SCHEDULE = 1,
    WORSHIP = 2,
    SHOP = 3,
    VISITORS = 4,
    OTHER = 5,
}

const TempleInformation: FC<TempleInformationProps> = (props) => {
    const [currentPanel, setCurrentPanel] = useState<CurrentPanel>(null);

    const getActive = (panel: CurrentPanel): string => {
        return currentPanel === panel ? " is-active" : "";
    };

    const getPanel = (panel: CurrentPanel): CurrentPanel => {
        return currentPanel !== panel ? panel : null;
    };

    const getStyle = (panel: CurrentPanel): string => {
        return currentPanel === panel ? " active-panel" : " hidden-panel";
    };

    const isPilgrimsArticlesExist = !!props.temple.pilgrimsArticles.length;
    const isArticlesExist = !!props.temple.articles.length;

    return (
        <section id="section_4" className="information">
            <div className="container--min">
                <h2 className="information__title subtitle">{props.t("user.temple.information.subtitle")}</h2>
                <div className="accordion-container">
                    <div className={"ac" + getActive(CurrentPanel.SCHEDULE)}>
                        <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.SCHEDULE))}>
                            <button className="ac-trigger">
                                {props.t("user.temple.information.schedule")}
                                <span className="circle"></span>
                            </button>
                        </h2>
                        <div className={"ac-panel" + getStyle(CurrentPanel.SCHEDULE)}>
                            <div className="ac-panel-inner">
                                <Schedule weekSchedule={props.temple.weekSchedule} />
                            </div>
                        </div>
                    </div>
                    <div className={"ac" + getActive(CurrentPanel.WORSHIP)}>
                        <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.WORSHIP))}>
                            <button className="ac-trigger">
                                {props.t("user.temple.information.worship")}
                                <span className="circle"></span>
                            </button>
                        </h2>
                        <div className={"ac-panel" + getStyle(CurrentPanel.WORSHIP)}>
                            <div className="ac-panel-inner">
                                <WorshipSchedules temple={props.temple} />
                            </div>
                        </div>
                    </div>
                    <div className={"ac" + getActive(CurrentPanel.SHOP)}>
                        <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.SHOP))}>
                            <button className="ac-trigger">
                                {props.t("user.temple.information.shop")}
                                <span className="circle"></span>
                            </button>
                        </h2>
                        <div className={"ac-panel" + getStyle(CurrentPanel.SHOP)}>
                            <div className="ac-panel-inner">
                                <Shop temple={props.temple} />
                            </div>
                        </div>
                    </div>
                    {isPilgrimsArticlesExist ? (
                        <div className={"ac" + getActive(CurrentPanel.VISITORS)}>
                            <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.VISITORS))}>
                                <button className="ac-trigger">
                                    {props.t("user.temple.information.visitors")}
                                    <span className="circle"></span>
                                </button>
                            </h2>
                            <div className={"ac-panel" + getStyle(CurrentPanel.VISITORS)}>
                                <div className="ac-panel-inner">
                                    <Articles
                                        articles={props.temple.pilgrimsArticles}
                                        isActive={!getPanel(CurrentPanel.VISITORS)}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {isArticlesExist ? (
                        <div className={"ac" + getActive(CurrentPanel.OTHER)}>
                            <h2 className="ac-header" onClick={() => setCurrentPanel(getPanel(CurrentPanel.OTHER))}>
                                <button className="ac-trigger">
                                    {props.t("user.temple.information.other")}
                                    <span className="circle"></span>
                                </button>
                            </h2>
                            <div className={"ac-panel" + getStyle(CurrentPanel.OTHER)}>
                                <div className="ac-panel-inner">
                                    <Articles
                                        articles={props.temple.articles}
                                        isActive={!getPanel(CurrentPanel.OTHER)}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(TempleInformation);

import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Religion, ReligionCurrent, ReligionSubspecies } from "../../../domain/models/Religions";
import { MainInfoForm, Temple } from "../../../domain/models/Temple";
import { Owner } from "../../../enums/parent-enum";
import { Purpose } from "../../../enums/purpose-enum";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import { MainInfoSchemaFactory } from "../../../validation-schemas/temple-register/main-info-schemas";
import DropDown from "../../base/DropDown";
import Footer from "./footer/Footer";
import EditableImagesList from "../../base/EditableImagesList";
import { maxFilesGroupSize } from "../../../Config";
import { getValidationClass } from "../../../tools/Tools";
import { CreateTempleParams } from "../../../enums/create-temple-params-enum";
import templeMapperFactory from "../../../mappers/TempleMapper";
import { CreateOrUpdateTemplePath } from "../../../enums/create-or-update-temple-path-enum";
import { withTranslation } from "react-i18next";
import { useUpdateFileOrder } from "../../../tools/custom-hooks/useUpdateFileOrder";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import { useFormsValidConfirmation } from "../../../context/FormikFormsProvider";
import ChangeEntityButtons from "../../base/formik/ChangeEntityButtons";
import { NameFormattingServiceImpl } from "../../../services/NameFormattingService";
import InputRestrictionsInfo from "../../base/InputRestrictionsInfo";
import ConflictErrorModal from "../../base/conflict-error-modal/ConflictErrorModal";
import ContentTooLargeErrorModal from "../../base/content-too-large-error-modal/ContentTooLargeErrorModal";

interface MainInfoProps {
    t(key?: string): string;

    temple: Temple;
    setTemple(value: Temple): void;
}

const MainInfo: FC<MainInfoProps> = (props) => {
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);

    const navigate = useNavigate();
    const updateFileOrder = useUpdateFileOrder<Temple>(props.temple, setIsOrderChanged);

    const religionsService = religionsServiceFactory();

    // const templeFiles = props.temple.files || [];

    const religionCurrentComponent: any = useRef();
    const religionSubspeciesComponent: any = useRef();

    const [religion, setReligion] = useState<Religion | undefined>(
        religionsService.getReligionTranslations(props.temple.religionId)
    );

    const [religionCurrent, setReligionCurrent] = useState<ReligionCurrent | undefined>(
        religionsService.getReligionCurrentTranslations(props.t, props.temple.religionId, props.temple.religionCurrentId)
    );

    const [religionSubspecies, setReligionSubspecies] = useState<ReligionSubspecies | undefined>(
        religionsService.getReligionCurrentSubspeciesTranslations(props.t, props.temple.religionId, props.temple.religionCurrentId, props.temple.religionSubspeciesId)
    );

    const handleReligionSelected = (newReligion?: Religion) => {
        if (religion === newReligion) {
            return;
        }
        formik.setFieldValue("religionId", _.get(newReligion, "value"), true);
        setReligion(newReligion);
        clearReligiousCurrent(parseInt(newReligion?.value));
        clearReligiousSubspecies(parseInt(newReligion?.value), null);
    };

    const handleCurrentSelected = (newReligionCurrent?: ReligionCurrent) => {
        if (religionCurrent === newReligionCurrent) {
            return;
        }
        formik.setFieldValue("religionCurrentId", _.get(newReligionCurrent, "value"), true);
        setReligionCurrent(newReligionCurrent);
        clearReligiousSubspecies(parseInt(religion?.value), parseInt(newReligionCurrent?.value));
    };

    const handleSubspeciesSelected = (newReligionSubspecies?: ReligionSubspecies) => {
        if (religionSubspecies === newReligionSubspecies) {
            return;
        }
        formik.setFieldValue("religionSubspeciesId", _.get(newReligionSubspecies, "value"), true);
        setReligionSubspecies(newReligionSubspecies);
    };

    const clearReligiousCurrent = (religionId: number) => {
        (religionCurrentComponent.current as any)?.clearValue();
        setReligionCurrent(undefined);
        formik.setFieldValue("religionCurrentId", undefined);

        religionsService.resetReligionCurrentsTranslations();
        religionsService.getReligionCurrentsTranslations(props.t, religionId);
    };

    const clearReligiousSubspecies = (religionId: number, currentId: number) => {
        (religionSubspeciesComponent.current as any)?.clearValue();
        formik.setFieldValue("religionSubspeciesId", undefined);
        setReligionSubspecies(undefined);

        religionsService.resetReligionSubspeciesTranslations();
        religionsService.getReligionSubspeciesTranslations(props.t, religionId, currentId);
    };

    const initialValues: MainInfoForm = {
        templeName: props.temple?.name,
        religionId: props.temple?.religionId,
        religionCurrentId: props.temple?.religionCurrentId,
        religionSubspeciesId: props.temple?.religionSubspeciesId,
        country: props.temple?.country,
        city: props.temple?.city,
        templeInfo: props.temple?.info,
    };

    const mainInfoSchema = useMemo(() => MainInfoSchemaFactory({ t: props.t }), [props.t]);

    const formik: FormikProps<MainInfoForm> = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: mainInfoSchema.schema,
        onSubmit: async (values) => {
            const mapper = templeMapperFactory();
            let temple = props.temple;

            try {
                setErrorVisibility(false);

                if (_.isEqual(values, formik.initialValues) && !temple.isFilesUpdated) {
                    navigate("/create-temple/abbot".concat(`?${CreateTempleParams.ID}=${temple.originId}`));
                    return;
                }

                temple.assignData({
                    name: values.templeName,
                    religionId: values.religionId,
                    religionCurrentId: values.religionCurrentId,
                    religionSubspeciesId: values.religionSubspeciesId,
                    country: values.country,
                    city: values.city,
                    info: values.templeInfo,
                });

                if (!temple.isMainInfoComplete) {
                    return setErrorVisibility(true);
                }

                const newTemple: Temple | undefined = await templeServiceFactory().createRequestOrUpdateCurrentEntity(
                    mapper.toLatestTempleDTO(temple), CreateOrUpdateTemplePath.MAIN_INFO
                );

                if (!temple.id) {
                    temple.assignChangeServiceFields(newTemple);
                }

                if (newTemple) {
                    window.history.replaceState('', '', `/create-temple/main-info?${CreateTempleParams.ID}=${temple.originId || newTemple.originId}`);

                    props.setTemple(temple);

                    templeServiceFactory().addNewTemple(temple);
                    templeServiceFactory().notifyListeners(temple);
                } else {
                    return setErrorVisibility(true);
                }

                const files = await temple.resolveFiles(newTemple?.id);
                if (files.isErrorVisible) {
                    return setErrorVisibility(true);
                }

                temple.setFiles(temple.getUpdatedFilesList(files.images));

                navigate("/create-temple/abbot".concat(`?${CreateTempleParams.ID}=${temple.originId}`));
            } catch {
                setErrorVisibility(true);
            }
        },
    });

    const { isFormsDataValid } = useFormsValidConfirmation("mainForm", formik);

    useEffect(() => {
        if (isOrderChanged) {
            setIsOrderChanged(false);
        }
    }, [isOrderChanged]);

    const resetMainInfoForm = () => {
        setReligion(
            religionsService.getReligionTranslations(props.temple.religionId)
        );

        setReligionCurrent(
            religionsService.getReligionCurrentTranslations(props.t, props.temple.religionId, props.temple.religionCurrentId)
        );

        setReligionSubspecies(
            religionsService.getReligionCurrentSubspeciesTranslations(props.t, props.temple.religionId, props.temple.religionCurrentId, props.temple.religionSubspeciesId)
        );

        props.temple.resetFiles();
    };

    const templeNameBlurHandle = () => {
        if (formik.values.templeName) {
            formik.setFieldValue("templeName", NameFormattingServiceImpl.normalizeName(formik.values.templeName));
        }
    };

    const cityBlurHandle = () => {
        if (formik.values.city) {
            formik.setFieldValue("city", NameFormattingServiceImpl.normalizeName(formik.values.city));
        }
    };

    const countryBlurHandle = () => {
        if (formik.values.country) {
            formik.setFieldValue("country", NameFormattingServiceImpl.normalizeName(formik.values.country));
        }
    };

    return (
        <div className="date__content">
            <form onSubmit={formik.handleSubmit}>
                <main className="date__main">
                    <div>
                        <h1 className="date__title">
                            {props.t("templeRegister.mainInfo.titleFirst")}
                            <br />
                            {props.t("templeRegister.mainInfo.titleSecond")}
                        </h1>

                        <div className="date__forms">
                            <div className="date__form-block validated-form-field">
                                {isErrorVisible && <p>{props.t("errors.common")}</p>}

                                <input
                                    className={`date__input ${getValidationClass(formik, "templeName")}`}
                                    type="text"
                                    placeholder={props.t("templeRegister.mainInfo.templeName")}
                                    name="templeName"
                                    onChange={formik.handleChange}
                                    onBlur={templeNameBlurHandle}
                                    value={formik.values.templeName}
                                    maxLength={mainInfoSchema.inputRestrictions.templeName.max}
                                />
                                <InputRestrictionsInfo
                                    min={mainInfoSchema.inputRestrictions.templeName.min}
                                    max={mainInfoSchema.inputRestrictions.templeName.max}
                                    error={formik.errors.templeName}
                                />
                            </div>

                            <div className="date__form-block  date__form-wrap validated-form-field">
                                <div className="date__form-block">
                                    <DropDown
                                        name="religionId"
                                        value={religion}
                                        options={religionsService.getReligionsTranslations(props.t)}
                                        onChange={handleReligionSelected}
                                        isError={formik.touched.religionId && !!formik.errors.religionId}
                                        placeholder={props.t("templeRegister.mainInfo.religionPlaceholder")}
                                    ></DropDown>
                                    <p className="form-error">
                                        {formik.touched.religionId && formik.errors.religionId}
                                    </p>
                                </div>

                                <div className="date__form-block">
                                    <DropDown
                                        name="religionCurrentId"
                                        ref={religionCurrentComponent}
                                        value={religionCurrent}
                                        options={religionsService.getReligionCurrentsTranslations(props.t, parseInt(religion?.value))}
                                        onChange={handleCurrentSelected}
                                        isError={formik.touched.religionCurrentId && !!formik.errors.religionCurrentId}
                                        placeholder={props.t("templeRegister.mainInfo.currentPlaceholder")}
                                    ></DropDown>
                                </div>
                            </div>

                            <div className="date__form-block">
                                <DropDown
                                    name="religionSubspeciesId"
                                    ref={religionSubspeciesComponent}
                                    value={religionSubspecies}
                                    options={religionsService.getReligionSubspeciesTranslations(
                                        props.t,
                                        parseInt(religion?.value),
                                        parseInt(religionCurrent?.value)
                                    )}
                                    onChange={handleSubspeciesSelected}
                                    isError={formik.touched.religionSubspeciesId && !!formik.errors.religionSubspeciesId}
                                    placeholder={props.t("templeRegister.mainInfo.subspeciesPlaceholder")}
                                ></DropDown>
                            </div>

                            <div className="date__form-block  date__form-wrap validated-form-field">
                                <div className="date__form-block">
                                    <input
                                        className={`date__input ${getValidationClass(formik, "country")}`}
                                        type="text"
                                        placeholder={props.t("templeRegister.mainInfo.country")}
                                        name="country"
                                        onChange={formik.handleChange}
                                        value={formik.values.country}
                                        onBlur={countryBlurHandle}
                                        maxLength={mainInfoSchema.inputRestrictions.country.max}
                                    />
                                    <InputRestrictionsInfo
                                        min={mainInfoSchema.inputRestrictions.country.min}
                                        max={mainInfoSchema.inputRestrictions.country.max}
                                        error={formik.errors.country}
                                    />
                                </div>

                                <div className="date__form-block">
                                    <input
                                        className={`date__input ${getValidationClass(formik, "city")}`}
                                        type="text"
                                        placeholder={props.t("templeRegister.mainInfo.city")}
                                        name="city"
                                        onChange={formik.handleChange}
                                        value={formik.values.city}
                                        onBlur={cityBlurHandle}
                                        maxLength={mainInfoSchema.inputRestrictions.city.max}
                                    />
                                    <InputRestrictionsInfo
                                        min={mainInfoSchema.inputRestrictions.city.min}
                                        max={mainInfoSchema.inputRestrictions.city.max}
                                        error={formik.errors.city}
                                    />
                                </div>
                            </div>

                            <div className="date__form-block validated-form-field">
                                <textarea
                                    className={`date__textarea date__input ${getValidationClass(formik, "templeInfo")}`}
                                    cols={30}
                                    rows={10}
                                    placeholder={props.t("templeRegister.mainInfo.templeInfo")}
                                    name="templeInfo"
                                    onChange={formik.handleChange}
                                    value={formik.values.templeInfo}
                                    maxLength={mainInfoSchema.inputRestrictions.templeInfo.max}
                                ></textarea>
                                <InputRestrictionsInfo
                                    min={mainInfoSchema.inputRestrictions.templeInfo.min}
                                    max={mainInfoSchema.inputRestrictions.templeInfo.max}
                                    error={formik.errors.templeInfo}
                                />
                            </div>

                            <EditableImagesList
                                files={props.temple.getSortedFiles(SortingOrder.ASC)}
                                setErrorVisibility={setErrorVisibility}
                                setIsFilesUpdated={val => props.temple.setIsFilesUpdated(val)}

                                ownerType={Owner.TEMPLE}
                                purposeType={Purpose.MAIN_INFO}
                                maxImagesCount={maxFilesGroupSize}

                                getMaxOrder={props.temple.getMaxOrder}
                                getNewOrder={props.temple.getNextFileOrder}

                                moveFileToLeft={updateFileOrder(true)}
                                moveFileToRight={updateFileOrder(false)}
                                removeFileByOrder={(order: number) => props.temple.removeFileByOrder(order)}
                            ></EditableImagesList>
                        </div>
                    </div>
                    <ChangeEntityButtons<MainInfoForm>
                        formik={formik}
                        initialValues={initialValues}
                        disableSaveButton={true}
                        resetEntityFormHandler={resetMainInfoForm}
                    />
                </main>

                <ConflictErrorModal />
                <ContentTooLargeErrorModal />
                <Footer isFormsDataValid={isFormsDataValid} />
            </form>
        </div>
    );
};

export default withTranslation()(MainInfo);

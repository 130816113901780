import { FC } from "react";

const HeaderNotificationSVG: FC = () => {
    return (
        <svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.28853 13.4155V18.3603C4.28853 21.0973 3.29912 23.7328 1.5625 25.7925H26.4401C24.7035 23.7328 23.7141 21.0973 23.7141 18.3603V13.4155C23.7141 8.07022 19.3569 3.72153 14.0014 3.72153C8.64573 3.72153 4.28853 8.07022 4.28853 13.4155V13.4155Z"
                fill="#DADADA"
            />
            <path d="M0 31.1871H28V27.8449H0V31.1871Z" fill="#DADADA" />
            <path
                d="M14.0016 35.7607C15.4045 35.7607 16.5486 34.6341 16.5734 33.2398H11.4297C11.4543 34.6341 12.5987 35.7607 14.0016 35.7607Z"
                fill="#DADADA"
            />
            <rect x="12" width="4.40991" height="11.0248" fill="#DADADA" />
        </svg>
    );
};

export default HeaderNotificationSVG;

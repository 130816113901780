import { PaymentsInfo } from "../domain/models/Payment";
import { PaymentsInfoDTO } from "../domain/models/dto/PaymentDTO";
import { fixedToFloatAmountStr } from "../tools/Tools";
import { BaseModelImpl } from "./BaseModelImpl";

class PaymentsInfoModel extends BaseModelImpl<PaymentsInfoDTO> implements PaymentsInfo {
    totalAmount: number;
    doneAmount: number;
    inReviewAmount: number;
    siteCommissionAmount: number;
    projectSupportAmount: number;

    totalCount: number;
    doneCount: number;
    inReviewCount: number;
    siteCommissionCount: number;
    projectSupportCount: number;

    totalAmountFloatStr: string;
    doneAmountFloatStr: string;
    inReviewAmountFloatStr: string;
    siteCommissionAmountFloatStr: string;
    projectSupportAmountFloatStr: string;

    constructor(data: PaymentsInfoDTO) {
        super();
        this.assignData(data);
        this.totalAmountFloatStr = fixedToFloatAmountStr(this.totalAmount);
        this.doneAmountFloatStr = fixedToFloatAmountStr(this.doneAmount);
        this.inReviewAmountFloatStr = fixedToFloatAmountStr(this.inReviewAmount);
        this.siteCommissionAmountFloatStr = fixedToFloatAmountStr(this.siteCommissionAmount);
        this.projectSupportAmountFloatStr = fixedToFloatAmountStr(this.projectSupportAmount);
    }
}

export default PaymentsInfoModel

import _ from "lodash";
import { Mapper } from "../domain/mappers/mapper";
import { CustomerSupportTicket } from "../domain/models/CustomerSupportTicket";
import { CustomerSupportTicketDTO } from "../domain/models/dto/CustomerSupportTicketDTO";
import { CustomerSupportTicketModel } from "../models/CustomerSupportTicketModel";
import { SimpleMapper } from "./SimpleMapper";

class CustomerSupportTicketMapper extends SimpleMapper<
    CustomerSupportTicket,
    CustomerSupportTicketDTO
> {
    protected entityConstructor = CustomerSupportTicketModel;

    protected toDTOFields: string[] = [
        "id",
        "status",
        "comment",
        "firstName",
        "lastName",
        "middleName",
        "email",
        "country",
        "phoneNumber",
        "customerRole",
        "subjectType",
        "conditionsAccepted",
        "language",
        "description",
    ];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        "userId",
        "ticketName",
        "emailSent",
        "language",
        "adminId",
        "isEmailRegistered",
        "createdAt",
        "updatedAt",
    ];

    toDTO(model: CustomerSupportTicket): CustomerSupportTicketDTO {
        const result = super.toDTO(model);

        result.customerRole = !_.isNil(result.customerRole)
            ? +result.customerRole
            : result.customerRole;
        result.subjectType = !_.isNil(result.subjectType)
            ? +result.subjectType
            : result.subjectType;
        result.status = !_.isNil(result.status)
            ? +result.status
            : result.status;

        return result;
    }
}

export default function customerSupportTicketMapperFactory(): Mapper<
    CustomerSupportTicket,
    CustomerSupportTicketDTO
> {
    return new CustomerSupportTicketMapper();
}

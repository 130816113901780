import { FC } from "react";
import { Temple } from "../../../domain/models/Temple";
import FoundTempleCard from "./FoundTempleCard";

interface FoundTemplesCardsListProps {
    temples: Temple[];
}

const FoundTemplesCardsList: FC<FoundTemplesCardsListProps> = (props) => {
    return (
        <div className="church__list">
            {props.temples.map((temple, index) => (
                <FoundTempleCard  temple={temple} key={`found_temple_${index}`} />
            ))}
        </div>
    );
};

export default FoundTemplesCardsList;

import { FC, MutableRefObject, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Prayer } from "../../../../../domain/models/Prayer";
import { PrayerPriceDetail } from "../../../../../domain/models/PrayerPriceDetail";
import { Temple } from "../../../../../domain/models/Temple";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import prayerMapperFactory from "../../../../../mappers/PrayerMapper";
import prayerServiceFactory from "../../../../../services/PrayerServiceImpl";
import DownSVG from "../../../../base/svg/DownSVG";
import UpSVG from "../../../../base/svg/UpSVG";
import WhiteEditButtonSVG from "../../../../base/svg/WhiteEditButtonSVG";
import WhiteRemoveSVG from "../../../../base/svg/WhiteRemoveSVG";
import PrayerDetailsInfo from "./PrayerDetailsInfo";

interface PrayerInfoProps {
    t(key?: string): string;
    temple: Temple;
    prayer: Prayer;

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    setIsAllowSetValues(value: boolean): void;

    isOrderChanged: boolean;
    setIsOrderChanged(value: boolean): void;

    setCurrentPrayer(value: Prayer): void;
    setCurrentDetails(value: PrayerPriceDetail[]): void;
    updatePrayers(updatedPrayer: Prayer): void;
    setRemovableModel(prayer: Prayer): void;

    prayerFormRef: MutableRefObject<any>;
    additionalWrapperClassName: string;
}

const PrayerInfo: FC<PrayerInfoProps> = (props) => {
    const prayerService = prayerServiceFactory();
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    const scrollToPrayerForm = () => props.prayerFormRef.current.scrollIntoView();

    const updateRequirementOrder = (toUp: boolean) => {
        const mapper = prayerMapperFactory();

        const updateRequirement = async () => {
            let prayers;

            if (toUp) {
                prayers = props.temple.upPrayer(props.prayer.order);
            } else {
                prayers = props.temple.downPrayer(props.prayer.order);
            }

            let result: Prayer[];
            result = await Promise.all([
                prayerService.createRequestOrUpdateCurrentEntity(mapper.toDTO(prayers?.prevElement)),
                prayerService.createRequestOrUpdateCurrentEntity(mapper.toDTO(prayers?.currentElement)),
            ]).catch(() => (result = []));

            const current = toUp ? result[0] : result[1];

            if (!current && (prayers.currentElement || prayers.prevElement)) {
                setErrorVisibility(true);
            }

            current.assignRelatedEntities(props.prayer);

            props.setIsOrderChanged(true);
        };

        updateRequirement();
    };

    const editHandler = () => {
        scrollToPrayerForm();

        props.setIsEditing(true);
        props.setIsAllowSetValues(true);
        props.setCurrentPrayer(props.prayer);
        props.setCurrentDetails(props.prayer.prayerPriceDetails);
    };

    useEffect(() => {
        if (props.isOrderChanged) {
            props.setIsOrderChanged(false);
        }
    });

    return (
        <div className={props.additionalWrapperClassName}>
            {isErrorVisible && <p>{props.t("errors.common")}</p>}
            <div className="order__table-wrap">
                <table className="order__table">
                    <thead>
                        <tr>
                            <th>{props.t("templeRegister.orders.name")}</th>
                            <th>{props.t("templeRegister.orders.type")}</th>
                            <th>{props.t("templeRegister.orders.period")}</th>

                            <thead>
                                <th>{props.t("templeRegister.orders.maxPeople")}</th>
                                <th>{props.t("templeRegister.orders.price")}</th>
                            </thead>

                            <th className="order__table-item">{props.t("templeRegister.orders.info")}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>{props.prayer.name}</td>
                            <td>{props.t(`prayer.type.${props.prayer.type}`)}</td>
                            <td>{props.t(`prayer.period.${props.prayer.period}`)}</td>

                            <td>
                                <table>
                                    <tbody>
                                        <>
                                            {props.prayer.prayerPriceDetails[0] &&
                                                props.prayer
                                                    .getSortedPrayerPriceDetails(SortingOrder.ASC)
                                                    .map(
                                                        (detail, index) =>
                                                            detail?.id && (
                                                                <PrayerDetailsInfo
                                                                    key={`prayer-details-info-${index}`}
                                                                    detail={detail}
                                                                    prayer={props.prayer}
                                                                    updatePrayers={props.updatePrayers}
                                                                    setErrorVisibility={setErrorVisibility}
                                                                />
                                                            )
                                                    )}
                                        </>
                                    </tbody>
                                </table>
                            </td>

                            <td className="order__table-item">{props.prayer.info}</td>
                        </tr>
                    </tbody>
                </table>

                {!props.isEditing && (
                    <div className="order__table-buttons">
                        <div className="order__table-filter">
                            <button
                                className="order__table-up order__table-button"
                                onClick={() => updateRequirementOrder(true)}
                                type="button"
                            >
                                <UpSVG />
                            </button>

                            <button
                                className="order__table-down order__table-button"
                                onClick={() => updateRequirementOrder(false)}
                                type="button"
                            >
                                <DownSVG />
                            </button>
                        </div>

                        <div className="order__info-edit">
                            <button onClick={() => props.setRemovableModel(props.prayer)} type="button">
                                <WhiteRemoveSVG />
                            </button>
                            <button onClick={() => editHandler()} type="button">
                                <WhiteEditButtonSVG />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(PrayerInfo);

import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../../types/Translate";
import PayoutCreationModal from "./PayoutCreationModal";
import { TempleStatistic } from "../../../../domain/models/TempleStatistic";
import PayoutCreateSVG from "../../../base/svg/PayoutCreateSVG";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../../base/CustomCheckbox";
import { getCurrentMethodTranslation } from "../../../../enums/payment-method-enum";

interface CreditRowProps {
    t: Translate;
    credit: TempleStatistic;
    setReloadCache: (value: boolean) => void;
    selectRowCallback: () => void;
    isSelectedRow: boolean;
}

const CreditRow: FC<CreditRowProps> = (props) => {
    const navigate = useNavigate();
    const [isPayoutCreationModalActive, setPayoutCreationModalActive] = useState(false);

    const navigateToTemplePayments = () => {
        navigate(`/admin/temples/${props.credit.templeId}/payments`);
    }

    return (
        <tr key={props.credit.id}>
            <td className="td-style--min td-padding1-75 td-padding1-75__short date">
                <CustomCheckbox
                    id={props.credit.id}
                    name={"selectedRowButton" + props.credit.id}
                    defaultValue={false}
                    value={props.isSelectedRow}
                    className="checkbox__input"
                    additionalLabelClassName="date__text"
                    wrapperClassName="order__checkbox simple-checkbox"
                    onChange={props.selectRowCallback}
                />
            </td>
            <td className="td-style--min td-padding1 td-padding1-75__short">
                <div onClick={() => setPayoutCreationModalActive(true)} className="payout-button">
                    <PayoutCreateSVG />
                </div>
            </td>
            <td className="td-style--min" onClick={navigateToTemplePayments}>
                <span className="td-inner--min2">{props.credit.templeName}</span>
            </td>
            <td className="td-style--min">
                <span className="td-inner--min3">{props.credit.finalTempleTransactionAmountFloatStr}$</span>
            </td>
            <td className="td-style--min">
                <span className="td-inner--min2">{props.credit.finalTempleTransactionPrayersAmountFloatStr}$</span>
            </td>
            <td className="status status__table">
                <span>{props.credit.finalTempleTransactionDonationsAmountFloatStr}$</span>
            </td>
            <td className="td-style td-time">{props.credit.finalTransactionCount}</td>
            <td className="td-style">
                <span className="td-inner--min4">{props.credit.finalTransactionPrayersCount}</span>
            </td>
            <td className="td-style">
                <span className="td-inner--min4">{props.credit.finalTransactionDonationsCount}</span>
            </td>
            <td className="td-style--min">
                <span className="td-inner--min3">{getCurrentMethodTranslation(props.t, props.credit.defaultPaymentMethod).label}</span>
            </td>
            <td className="td-style--min">
                <span className="td-inner--min3">{props.credit.dateOfApprovalLatestPayout}</span>
            </td>
            <td className="td-style--min">
                <span className="td-inner--min3">{props.credit.activePayouts}</span>
            </td>
            <PayoutCreationModal
                isPayoutCreationModalActive={isPayoutCreationModalActive}
                closeModalCallback={() => setPayoutCreationModalActive(false)}
                templeId={props.credit.templeId}
                templeName={props.credit.templeName}
                transactionAmount={props.credit.finalTempleTransactionAmountFloatStr}
                templeStatisticId={props.credit.id}
                setReloadCache={props.setReloadCache}
                credit={props.credit}
            />
        </tr>
    );
};

export default withTranslation()(CreditRow);

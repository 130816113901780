import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { pageSize } from "../../../Config";
import { DEFAULT_OFFSET, DEFAULT_PAGE } from "../../../constants";
import { SearchTempleDataWithCount } from "../../../domain/models/SearchData";
import { PublicParams } from "../../../enums/public-params-enum";
import searchServiceFactory from "../../../services/SearchServiceImpl";
import PagePagination from "../../base/PagePagination";
import SearchTempleData from "./SearchTempleData";

const SearchPage: FC = () => {
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(DEFAULT_PAGE);
    const [isChangeSearchData, setIsChangeSearchData] = useState<boolean>(false);

    const [data, setData] = useState<SearchTempleDataWithCount>(null);
    const [prevQuery, setPrevQuery] = useState<string>(null);

    const [searchParams] = useSearchParams();
    const query = searchParams.get(PublicParams.QUERY) as string;

    useEffect(() => {
        if (query !== prevQuery) {
            setCurrentPageIndex(DEFAULT_PAGE);
            setIsChangeSearchData(true);
        }
    }, [query, prevQuery]);

    useEffect(() => {
        const uploadData = async () => {
            const searchService = searchServiceFactory();
            const searchData = await searchService.getSearchData(query, currentPageIndex, pageSize, DEFAULT_OFFSET);

            setData(searchData);
        };

        if ((!data || isChangeSearchData) && query) {
            uploadData();
            setPrevQuery(query);
            setIsChangeSearchData(false);
        }
    }, [data, query, isChangeSearchData, currentPageIndex]);

    return (
        <section className="information">
            <div className="container--min">
                {data?.rows.map((el, index) => (
                    <div key={"search-temple-data" + index}>
                        <SearchTempleData data={el} />
                    </div>
                ))}
            </div>

            {data?.totalPages > DEFAULT_PAGE && (
                <PagePagination
                    additionalPaginationClass="search__data__pagination"
                    pagesCount={data?.totalPages}
                    currentPageIndex={currentPageIndex}
                    setCurrentPageIndex={setCurrentPageIndex}
                    setIsChangeContent={setIsChangeSearchData}
                    visibleItemCount={4}
                />
            )}
        </section>
    );
};

export default SearchPage;

export enum PayoutsSortFields {
    TEMPLE_NAME = "templeName",
    TOTAL_TEMPLE_TRANSACTION_AMOUNT = "transactionAmount",
    TOTAL_TEMPLE_TRANSACTION_PRAYERS_AMOUNT = "transactionPrayersAmount",
    TOTAL_TEMPLE_TRANSACTION_DONATIONS_AMOUNT = "transactionDonationsAmount",
    STATUS = "status",
    CREATED_AT = "createdAt",
    CREATED_BY_NAME = "createdByName",
    UPDATED_AT = "updatedAt",
    RESOLVED_BY_NAME = "resolvedByName",
}

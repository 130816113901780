import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../types/Translate";

interface InputRestrictionsInfoProps {
    t: Translate;

    error: string;
    min?: number;
    max?: number;
    additionalWrapperClassName?: string;
}

const InputRestrictionsInfo: FC<InputRestrictionsInfoProps> = (props) => {
    return (
        <div className={
                "input-restrictions-info "
                + (props.additionalWrapperClassName ? props.additionalWrapperClassName : "")
                + (props.error ? " input-restrictions-info__error" : "")
            }>
            {props.max && props.min ? (
                <div>
                    {props.t("errors.commonErrors.min") + props.min + " "}
                    {props.t("errors.commonErrors.max") + props.max}
                </div>
            ) : (
                <div>
                    {props.error}
                </div>
            )}
        </div>
    );
};

export default withTranslation()(InputRestrictionsInfo);

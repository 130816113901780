import React from "react";
import { FC } from "react";
import { withTranslation } from "react-i18next";
import Select, { components, PlaceholderProps, SingleValueProps } from "react-select";
import { SelectProps } from "../../domain/models/SelectProps";
import { Translate } from "../../types/Translate";

interface IDropDownProps {
    ref?: any;
    options?: SelectProps[];
    value?: SelectProps;
    name: string;
    isError?: boolean;
    onChange: (val: SelectProps) => void;
    placeholder?: string;
    isSearchable?: boolean;
    disableIsClearable?: boolean;

    t: Translate;
    labelPrefix?: string;
    title?: string;

    onMenuOpen?: () => void;
}

const Option = (props: any) => {
    return (
        <div className="select__option" {...props.innerProps}>
            {props.children}
        </div>
    );
};

const getControl = (hostProps: IDropDownProps) => {
    return (props: any) => {
        return (
            <div
                className={"select select_form__select" + (props.menuIsOpen ? " _active" : "")}
                {...props.innerProps}
                title={hostProps.title}
            >
                <div className="select__item">
                    <div className={`select__title ${hostProps.isError ? "invalid" : ""}`}>
                        <div className="select__value select__value__react-select" {...props.innerProps}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const MenuList = (props: any) => {
    return (
        <div className="select__options select__options__react-select custom-select-dropdown-scroll" {...props.innerProps}>
            <div className="select__option"></div>
            {props.children}
        </div>
    );
};

const Menu = (props: any) => {
    return (
        <div className="select__title select__menu" {...props.innerProps}>
            {props.children}
        </div>
    );
};

const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue className="select__value__react-select" {...props}>
        {children}
    </components.SingleValue>
);

const Placeholder = (props: PlaceholderProps<any>) => {
    return <components.Placeholder {...props} />;
};

const FormatOptionLabel = (label: string) => {
    return <div>{label}</div>;
};

const DropDown: FC<IDropDownProps> = React.forwardRef((props: IDropDownProps, ref: any) => {
    return (
        <Select
            placeholder={props.placeholder}
            ref={ref}
            options={props.options}
            value={props.value?.value ? props.value : null}
            isClearable={props.disableIsClearable}
            isSearchable={props.isSearchable}
            name={props.name}
            onChange={(val: any) => {
                props.onChange(val);
            }}
            onMenuOpen={props.onMenuOpen}
            components={{
                Option,
                Control: getControl(props),
                MenuList,
                Menu,
                SingleValue,
                DropdownIndicator: null,
                Placeholder,
            }}
            formatOptionLabel={(data) =>
                FormatOptionLabel(
                    !props.labelPrefix
                        ? data.label
                        : props.options.includes(data)
                        ? props.t(props.labelPrefix + data.label)
                        : ""
                )
            }
            styles={{
                valueContainer: (base) => ({
                    ...base,
                    padding: "0px",
                    fontSize: "14px",
                }),
                placeholder: (base) => ({
                    ...base,
                    marginLeft: "0px",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#858585",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }),
                clearIndicator: (base) => ({
                    ...base,
                    padding: "8px 4px 8px 0px",
                }),
            }}
        />
    );
});

export default withTranslation()(DropDown);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Temple } from "../../../domain/models/Temple";
import Sidebar from "./Sidebar";

interface MainTempleRegisterComponentProps {
    t(key?: string): string;
    temple: Temple | undefined | null;
}

const MainTempleRegisterComponent: FC<MainTempleRegisterComponentProps> = (props) => {
    return (
        <>
            <title>{props.t("templeRegister.base.title")}</title>
            <div className="wrapper">
                <section className="date">
                    <Sidebar  newTemple={props.temple} />

                    <div className="container">
                        <Outlet />
                    </div>
                </section>
            </div>
        </>
    )
}

export default withTranslation()(MainTempleRegisterComponent);

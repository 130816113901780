import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChangeRequestEntity } from "../../../../domain/models/ChangeRequestEntity";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import religionsServiceFactory from "../../../../services/ReligionsServiceImpl";
import templeChangesRequestServiceFactory from "../../../../services/TempleChangesRequestServiceImpl";
import ChangeRequestEntityHeader from "./ChangeRequestEntityHeader";
import ChangeRequestEntityItem from "./ChangeRequestEntityItem";

interface ChangeRequestEntityTabProps {
    isSuperAdminUser?: boolean;
}

const ChangeRequestEntityTab: FC<ChangeRequestEntityTabProps> = (props) => {
    const templeChangesRequestService = templeChangesRequestServiceFactory();
    const { requestId } = useParams();

    const [changeRequestEntities, setChangeRequestEntities] = useState<ChangeRequestEntity[]>();
    const [isReligionsExist, setIsReligionsExist] = useState<boolean>(false);

    const [error, setError] = useState<string>(null);
    const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);

    useEffect(() => {
        const setData = async () => {
            const entities = await templeChangesRequestService.getChangeRequestEntities(requestId);

            setChangeRequestEntities(entities);
        };

        const uploadReligions = async () => {
            setIsReligionsExist(!!(await religionsServiceFactory().getCachedReligions()));
        };

        if (!isReligionsExist) {
            uploadReligions();
        }

        if (!isDataUpdated) {
            setData();
            setIsDataUpdated(true);
        }
    }, [changeRequestEntities, requestId, isReligionsExist, isDataUpdated, templeChangesRequestService]);

    const entities = templeChangesRequestService.getSortedChangeRequestByEntity(
        SortingOrder.ASC,
        changeRequestEntities
    );

    return (
        <main className="main">
            <section className="update">
                <ChangeRequestEntityHeader
                    requestId={requestId}
                    totalCount={templeChangesRequestService.getChangesCount(changeRequestEntities)}
                    isSuperAdminUser={props.isSuperAdminUser}
                    error={error}
                    setError={setError}
                    setIsDataUpdated={setIsDataUpdated}
                />

                <div className="inner__content16">
                    {isReligionsExist && entities?.map((entity, index) => (
                        <div key={"inner__content16" + index}>
                            {
                                <ChangeRequestEntityItem
                                    changeRequestEntity={entity}
                                    isSuperAdminUser={props.isSuperAdminUser}
                                    requestId={requestId}
                                    setError={setError}
                                    setIsDataUpdated={setIsDataUpdated}
                                />
                            }
                        </div>
                    ))}
                </div>
            </section>
        </main>
    );
};

export default ChangeRequestEntityTab;

import { FC } from "react";

interface Temple3DTourIframeProps {
    link: string;
    className?: string
}

const Temple3DTourIframe: FC<Temple3DTourIframeProps> = (props) => {
    return (
        <iframe
            title="3d-tour"
            src={props.link}
            className={props.className || "my360Tour"}
            name="my360Tour"
            height="400px"
            width="600px"
            allowFullScreen>
        </iframe>
    );
}

export default Temple3DTourIframe;

import * as Yup from "yup"
import { email } from "../../constants";
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";
import { Langs } from "../../enums/langs-enum";


export const CorrespondenceSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        correspondenceEmail: {
            min: 2,
            max: 200
        },
    };

    return {
        schema: Yup.object().shape({
            correspondenceEmail: Yup.string()
                .matches(email, t("errors.commonErrors.isNotCorrectEmail"))
                .min(inputRestrictions.correspondenceEmail.min, t("errors.commonErrors.min") + inputRestrictions.correspondenceEmail.min)
                .max(inputRestrictions.correspondenceEmail.max, t("errors.commonErrors.max") + inputRestrictions.correspondenceEmail.max)
                .required(t("errors.commonErrors.required")),
            correspondenceLanguage: Yup.string()
                .oneOf([Langs.UA, Langs.EN, Langs.RU])
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

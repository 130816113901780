import { FC } from "react";
import { Prayer } from "../../../../domain/models/Prayer";
import PriceDetailsItem from "./PriceDetailsItem";

interface OrderPrayerAccordionItemProps {
    prayer: Prayer;
}

const OrderPrayerAccordionItem: FC<OrderPrayerAccordionItemProps> = (props) => {
    return (
        <div className="order-payer__accordion-item">
            <div className="order-payer__accordion-type text">{props.prayer.name + ` (${props.prayer.info})`}</div>
            {props.prayer.prayerPriceDetails.map((priceDetail) => (
                <div key={priceDetail.id}>
                    <PriceDetailsItem  priceDetail={priceDetail} />
                </div>
            ))}
        </div>
    );
};

export default OrderPrayerAccordionItem;

import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";

export enum TransactionType {
    PRAYER = 1,
    DONATE = 2
}

export function getTransactionTypeTranslation(t: Translate): SelectProps[] {
    return [
        { value: TransactionType.PRAYER.toString(), label: t(`transactionType.${TransactionType.PRAYER}`) },
        { value: TransactionType.DONATE.toString(), label: t(`transactionType.${TransactionType.DONATE}`) },
    ];
}

export function getCurrentTransactionTypeTranslation(t: Translate, transactionType: TransactionType): SelectProps {
    return { value: transactionType?.toString(), label: t(transactionType ? `transactionType.${transactionType}` : "") };
}

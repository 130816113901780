import { FC } from "react";
import { withTranslation } from "react-i18next";
import { WeekSchedule } from "../../../../domain/models/WeekSchedule";
import { Week } from "../../../../enums/week-enum";
import { Translate } from "../../../../types/Translate";

interface ScheduleProps {
    t: Translate;
    weekSchedule: WeekSchedule;
}

const Schedule: FC<ScheduleProps> = (props) => {
    return (
        <div className="information__schedule">
            <div className="information__schedule-item">
                <tbody>
                    <tr>
                        <td className="information__schedule-days information__style">
                            {props.t(`weekDays.${Week.MONDAY}`)}
                        </td>
                        <td className="information__schedule-time week__schedule__time information__style">
                            {props.weekSchedule.mondayBegin} - {props.weekSchedule.mondayEnd}
                        </td>
                    </tr>

                    <tr>
                        <td className="information__schedule-days information__style">
                            {props.t(`weekDays.${Week.TUESDAY}`)}
                        </td>
                        <td className="information__schedule-time week__schedule__time information__style">
                            {props.weekSchedule.tuesdayBegin} - {props.weekSchedule.tuesdayEnd}
                        </td>
                    </tr>

                    <tr>
                        <td className="information__schedule-days information__style">
                            {props.t(`weekDays.${Week.WEDNESDAY}`)}
                        </td>
                        <td className="information__schedule-time week__schedule__time information__style">
                            {props.weekSchedule.wednesdayBegin} - {props.weekSchedule.wednesdayEnd}
                        </td>
                    </tr>

                    <tr>
                        <td className="information__schedule-days information__style">
                            {props.t(`weekDays.${Week.THURSDAY}`)}
                        </td>
                        <td className="information__schedule-time week__schedule__time information__style">
                            {props.weekSchedule.thursdayBegin} - {props.weekSchedule.thursdayEnd}
                        </td>
                    </tr>

                    <tr>
                        <td className="information__schedule-days information__style">
                            {props.t(`weekDays.${Week.FRIDAY}`)}
                        </td>
                        <td className="information__schedule-time week__schedule__time information__style">
                            {props.weekSchedule.fridayBegin} - {props.weekSchedule.fridayEnd}
                        </td>
                    </tr>

                    <tr>
                        <td className="information__schedule-days information__style">
                            {props.t(`weekDays.${Week.SATURDAY}`)}
                        </td>
                        <td className="information__schedule-time week__schedule__time information__style">
                            {props.weekSchedule.saturdayBegin} - {props.weekSchedule.saturdayEnd}
                        </td>
                    </tr>

                    <tr>
                        <td className="information__schedule-days information__style">
                            {props.t(`weekDays.${Week.SUNDAY}`)}
                        </td>
                        <td className="information__schedule-time week__schedule__time information__style">
                            {props.weekSchedule.sundayBegin} - {props.weekSchedule.sundayEnd}
                        </td>
                    </tr>
                </tbody>
            </div>
        </div>
    );
};

export default withTranslation()(Schedule);

import { FC, MutableRefObject } from "react";
import { withTranslation } from "react-i18next";
import { Article } from "../../../../../domain/models/Article";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import { Translate } from "../../../../../types/Translate";
import InfoSlide from "../../../../base/InfoSlide";
import PhotoSlider from "../../../../base/photo-slider/PhotoSlider";
import DarkRemoveSVG from "../../../../base/svg/DarkRemoveSVG";
import InfoEditSVG from "../../../../base/svg/InfoEditSVG";

interface ArticleInfoProps {
    t: Translate;

    article: Article;
    setCurrentArticle(value: Article): void;
    setRemovableModel(article: Article): void;
    articleFormRef: MutableRefObject<any>;
    additionalWrapperClassName: string;
}

const ArticleInfo: FC<ArticleInfoProps> = (props) => {
    const isOnePhoto = props.article.files.length === 1;
    const editHandler = () => {
        props.setCurrentArticle(props.article)
        props.articleFormRef && props.articleFormRef.current.scrollIntoView();
    }

    return (
        <>
            <div className={`establish__info ${props.additionalWrapperClassName}`}>
                <div className="establish__info-edit">
                    <button type="button" onClick={() => props.setRemovableModel(props.article)}>
                        <DarkRemoveSVG />
                    </button>
                    <button type="button" onClick={editHandler}>
                        <InfoEditSVG />
                    </button>
                </div>

                <div className={"establish__info-images" + (isOnePhoto ? " one__establish__photo__slider" : " many__establish__photo__slider")}>
                    <PhotoSlider
                        slidesPerView={isOnePhoto ? 1 : 2 }
                        spaceBetween={15}
                        isArrowsDisable={true}
                        allowTouchMove={true}
                        loop={false}
                        content={props.article?.getSortedFiles(SortingOrder.ASC).map((file) => (
                            <InfoSlide
                                filePath={file?.thumbnailOrOriginalPath}
                                imageClass="establish__info-thumbnail"
                            />
                        ))}
                    />
                </div>

                <div className={"establish__info-text" + (isOnePhoto ? " one__establish__info__text" : "")}>
                    <h4 className="establish__info-name">{props.article?.header}</h4>

                    <div className="establish__info-description">{props.article?.body}</div>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(ArticleInfo);

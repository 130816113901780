import { StatusCodes } from "http-status-codes";
import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { errorServiceFactory } from "../../../services/ErrorServiceImpl";
import { Translate } from "../../../types/Translate";
import ModalWindow from "../ModalWindow";

interface ContentTooLargeErrorModalProps {
    t: Translate;
}

const errorService = errorServiceFactory();

const ContentTooLargeErrorModal: FC<ContentTooLargeErrorModalProps> = (props) => {
    const [isVisibility, setVisibility] = useState(false);

    const closeCallback = () => {
        setVisibility(!isVisibility);
        errorService.resetErrorResponseSubject();
    }

    return (
        <Popup
            open={errorService.getStatusFromErrorResponseSubject(StatusCodes.REQUEST_TOO_LONG)}
            modal
            overlayStyle={{ zIndex: 999999 }}
            onClose={closeCallback}
        >
            <ModalWindow
                contentClass="modal-remove__content"
                bodyClass=" "
                closeCallback={closeCallback}
                content={
                    <div className="modal__text">{props.t(`errors.response.${StatusCodes.REQUEST_TOO_LONG}`)}</div>
                }
            />
        </Popup>
    );
};

export default withTranslation()(ContentTooLargeErrorModal);

import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";

export enum Langs {
    UA = "ua",
    EN = "en",
    RU = "ru"
}

export function getLanguageTranslation(t: Translate): SelectProps[] {
    return [
        { value: Langs.UA.toString(), label: t(`enums.language.${Langs.UA}`) },
        { value: Langs.EN.toString(), label: t(`enums.language.${Langs.EN}`) },
        { value: Langs.RU.toString(), label: t(`enums.language.${Langs.RU}`) },
    ];
}

export function getCurrentLanguageTranslation(t: Translate, language: Langs): SelectProps {
    return { value: language?.toString(), label: t(language ? `enums.language.${language}` : "") };
}

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../../../domain/models/Temple";
import { SortingOrder } from "../../../../../enums/sorting-order-enum";
import { Week } from "../../../../../enums/week-enum";
import { Translate } from "../../../../../types/Translate";
import WorshipSchedulePagination from "./WorshipSchedulePagination";
import WorshipSchedulesDay from "./WorshipSchedulesDay";

interface WorshipSchedulesProps {
    temple: Temple;
    t: Translate;
}

const WorshipSchedules: FC<WorshipSchedulesProps> = (props) => {
    const worshipSchedules = props.temple.groupWorshipSchedulesByDay();

    return (
        <div className="information__worship">
            <WorshipSchedulePagination />
            <div className="information__description">
                <div className="information__description-item">{props.t("user.temple.worships.day")}</div>
                <div className="information__description-item">{props.t("user.temple.worships.date")}</div>
                <div className="information__description-item">{props.t("user.temple.worships.hour")}</div>
                <div className="information__description-item">{props.t("user.temple.worships.worship")}</div>
            </div>
            <div className="information__worship-inner">
                <WorshipSchedulesDay
                    dayType={Week.MONDAY}
                    worshipSchedules={props.temple.getSortedWorshipSchedules(SortingOrder.ASC, worshipSchedules.monday)}
                />

                <WorshipSchedulesDay
                    dayType={Week.TUESDAY}
                    worshipSchedules={props.temple.getSortedWorshipSchedules(SortingOrder.ASC, worshipSchedules.tuesday)}
                />

                <WorshipSchedulesDay
                    dayType={Week.WEDNESDAY}
                    worshipSchedules={props.temple.getSortedWorshipSchedules(SortingOrder.ASC, worshipSchedules.wednesday)}
                />

                <WorshipSchedulesDay
                    dayType={Week.THURSDAY}
                    worshipSchedules={props.temple.getSortedWorshipSchedules(SortingOrder.ASC, worshipSchedules.thursday)}
                />

                <WorshipSchedulesDay
                    dayType={Week.FRIDAY}
                    worshipSchedules={props.temple.getSortedWorshipSchedules(SortingOrder.ASC, worshipSchedules.friday)}
                />

                <WorshipSchedulesDay
                    dayType={Week.SATURDAY}
                    worshipSchedules={props.temple.getSortedWorshipSchedules(SortingOrder.ASC, worshipSchedules.saturday)}
                />

                <WorshipSchedulesDay
                    dayType={Week.SUNDAY}
                    worshipSchedules={props.temple.getSortedWorshipSchedules(SortingOrder.ASC, worshipSchedules.sunday)}
                />
            </div>
        </div>
    );
};

export default withTranslation()(WorshipSchedules);

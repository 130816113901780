import { CSVFileService } from "../domain/services/CSVFileService";
import { CSVObject } from '../types/CSVObject';

class CSVFileServiceImpl implements CSVFileService {
    convertToCSVObject<T>(data: T[], keys: (keyof T)[]): CSVObject {
        if (!data.length) {
            return [];
        }

        const object: CSVObject = [keys as string[]];
        data.forEach((element, index) => {
            object.push([]);
            keys.forEach((key) => {
                const elementField = (element[key] || null) as string;
                object[index + 1].push(elementField);
            });
        });

        return object;
    }
}

export default function CSVFileServiceFactory(): CSVFileService {
    return new CSVFileServiceImpl();
}

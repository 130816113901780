import { FC, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { Swiper as ISwiper } from "swiper/types";

import "swiper/css";
import "swiper/css/pagination";
import SwiperArrows from "./SwiperArrows";
import { PHOTO_SLIDER_DEFAULT_MAIN_BULLETS } from "../../../constants";

interface PhotoSliderProps {
    content: any[];
    hideContent?: boolean;

    slidesPerView: number;
    sliderClass?: string;
    spaceBetween?: number;
    centeredSlides?: boolean;
    loop?: boolean;
    clickable?: boolean;
    allowTouchMove?: boolean;

    autoHeight?: boolean;
    autoplayDelay?: number;
    disableAutoplay?: boolean;

    isArrowsDisable?: boolean;
    hideSwiperArrows?: boolean;
    showArrowsPermanently?: boolean;

    dynamicBullets?: boolean;
    dynamicMainBullets?: number;

    currentSwiper?: ISwiper;
    setCurrentSwiper?(value: ISwiper): void;
    onSlideChange?(value: ISwiper): void;
}

const PhotoSlider: FC<PhotoSliderProps> = (props) => {
    let pagination: any = { clickable: !!props.clickable };

    pagination.dynamicBullets = !!props.dynamicBullets;
    pagination.dynamicMainBullets = props.dynamicMainBullets
        ? props.dynamicMainBullets
        : PHOTO_SLIDER_DEFAULT_MAIN_BULLETS;

    const autoplay = !props.disableAutoplay && {
        delay: props.autoplayDelay || 20000,
        disableOnInteraction: false,
    };

    const filesLength = props.content.length;

    const [swiper, setSwiper] = useState<ISwiper>(null);

    useEffect(() => {
        if (props.setCurrentSwiper && !props.currentSwiper && swiper) {
            props.setCurrentSwiper(swiper);
        }
    }, [props, swiper]);

    return (
        <>
            <Swiper
                onSwiper={(swiper) => setSwiper(swiper)}
                onSlideChange={props.onSlideChange}
                allowTouchMove={!!props.allowTouchMove}
                slidesPerView={filesLength < props.slidesPerView ? filesLength : props.slidesPerView}
                spaceBetween={props.spaceBetween || 0}
                centeredSlides={!!props.centeredSlides}
                pagination={!props.isArrowsDisable && pagination}
                navigation={{
                    nextEl: ".popular__arrow--next",
                    prevEl: ".popular__arrow--prev",
                }}
                modules={[Pagination, Autoplay]}
                loop={!!props.loop}
                className={props.sliderClass}
                autoplay={autoplay}
                autoHeight={props.autoHeight}
            >
                {props.content?.map((element, index) => (
                    <SwiperSlide key={index}>{!props.hideContent && element}</SwiperSlide>
                ))}

                {(props.content?.length > props.slidesPerView) || props.showArrowsPermanently ? (
                    !props.isArrowsDisable && <SwiperArrows hideSwiperArrows={props.hideSwiperArrows} />
                ) : (
                    <></>
                )}
            </Swiper>
        </>
    );
};

export default PhotoSlider;

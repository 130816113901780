import moment from "moment";
import { DATETIME_FORMAT } from "../constants";
import { PaymentDTO } from "../domain/models/dto/PaymentDTO";
import { Payment } from "../domain/models/Payment";
import { Temple } from "../domain/models/Temple";
import { PaymentMethod } from "../enums/payment-method-enum";
import { PaymentStatus } from "../enums/payment-status-enum";
import { TransactionType } from "../enums/transaction-type-enum";
import { Currency } from "../enums/currency-enum";

class PaymentModel implements Payment {
    id?: string;
    payoutId?: string;
    templeId?: string;
    prayerId?: string;
    details?: string;
    conditionsAccepted: boolean;
    orderDetails?: string[];

    status?: PaymentStatus;
    transactionType?: TransactionType;
    paymentMethod?: PaymentMethod;
    paymentId?: string;
    adminId?: string;

    recipientPaymentAccountId?: string;

    senderName?: string;
    senderAccountId?: string;
    senderEmail?: string;

    paymentDate?: moment.Moment;
    confirmationDate?: moment.Moment;

    currency: Currency;
    amount?: number;
    recipientAmount?: number;
    platformAmount?: number;

    initialRecipientBalance?: number;
    finalRecipientBalance?: number;
    initialPlatformBalance?: number;
    finalPlatformBalance?: number;

    amountFloatFormat: string;
    recipientAmountFloatFormat: string;
    platformAmountFloatFormat: string;
    initialRecipientBalanceFloatFormat: string;
    initialPlatformBalanceFloatFormat: string;
    finalRecipientBalanceFloatFormat: string;
    finalPlatformBalanceFloatFormat: string;

    createdAt?: Date;
    updatedAt?: Date;

    temple?: Temple;

    get inReview(): boolean {
        return this.status === PaymentStatus.IN_REVIEW
    }

    constructor(data?: PaymentDTO) {
        Object.assign(this, data || {});
    }

    get confirmationDateStr(): string {
        return this.confirmationDate ?
            this.confirmationDate.format(DATETIME_FORMAT)
            : '';
    }

    get paymentDateStr(): string {
        return this.paymentDate ?
            this.paymentDate.format(DATETIME_FORMAT)
            : '';
    }

    get statusDateStr(): string {
        return this.confirmationDateStr || this.paymentDateStr;
    }

    setTemple(temple: Temple) {
        this.temple = temple;
    }

    get isPrayer() {
        return this.transactionType === TransactionType.PRAYER;
    }

    get isDonation() {
        return this.transactionType === TransactionType.DONATE;
    }
}


export default PaymentModel;

import { SearchEntity, SearchTempleData } from "../domain/models/SearchData";
import { SearchTempleDataDTO } from "../domain/models/dto/SearchData";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";

export class SearchTempleDataModel extends BaseRelatedEntityMixinFactory<SearchTempleDataDTO>() implements SearchTempleData {
    templeId: string;
    templeName: string;
    details: SearchEntity[];

    constructor(data?: SearchTempleData) {
        super();

        this.assignData(data);
    }
}

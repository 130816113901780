import { PayoutStatus } from "../../enums/payout-status-enum";
import { ResultWithCount } from "./base-result";
import { PayoutDTO } from "./dto/PayoutDTO";

export interface Payout extends PayoutDTO {
    createdByFullName: string;
    resolvedByFullName: string;

    inReview: boolean;

    transactionAmountFloatStr: string;
    transactionPrayersAmountFloatStr: string;
    transactionDonationsAmountFloatStr: string;

    readonly statusTranslations: string;
    readonly paymentMethodTranslations: string;
    readonly paymentAccountCurrencyTranslations: string;
}

export interface PayoutsCollection extends ResultWithCount {
    rows: Payout[],
}

export interface ResolvePayoutForm {
    status: PayoutStatus;
    comment: string;
    transactionId: string;
}

export interface CreationPayoutForm {
    templeStatisticId: string;
    paymentAccountId: string;
}

export const PayoutCSVFields: (keyof Payout)[] = [
    "paymentMethodTranslations",
    "transactionId",
    "comment",

    "paymentAccountRecipientName",
    "paymentAccountAccountId",
    "paymentAccountCurrencyTranslations",

    "statusTranslations",
    "templeName",

    "transactionAmount",
    "transactionPrayersAmount",
    "transactionDonationsAmount",

    "transactionCount",
    "transactionPrayersCount",
    "transactionDonationsCount",

    "createdByFullName",
    "resolvedByFullName",

    "id",
    "createdAt",
    "updatedAt",
];

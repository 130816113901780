import { FC, useEffect, useReducer } from "react";
import { PublicParams } from "../../../enums/public-params-enum";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";
import { initialConfirmation, confirmationReducer } from "../../../reducers/confirmation-reducer";
import { userServiceFactory } from "../../../services/UserServiceImpl";
import { ConfirmationActionTypes } from "../../../types/reducers/confirmation-reducer";

interface RegistrationConfirmationProps {
    t: Translate;
}

const RegistrationConfirmation: FC<RegistrationConfirmationProps> = (props) => {
    /**
     * Using native location object because react-router-dom
     *      get not correctly token: replaces '+' to 'spaces'
     * And this bug generated 403 api error (token expired),
     *      but token still does not expired
    */
    const registrationToken = window.location.search.split(PublicParams.REGISTRATION_TOKEN + '=')[1];

    const [confirmation, confirmationDispatch] = useReducer(confirmationReducer, initialConfirmation);

    useEffect(() => {
        const confirmRegistration = async () => {
            const userService = userServiceFactory();
            const apiResponse = await userService.confirmRegistration(registrationToken);
            confirmationDispatch({
                type: ConfirmationActionTypes.UPDATE_BY_API_RESPONSE,
                nextValue: { apiResponse }
            });
        }

        confirmRegistration();
    }, []);

    return (
        <div>
            <h2 className="information__title subtitle">{props.t("confirmation.title")}</h2>
            <h3 className="confirmation__title">{props.t("confirmation.subtitle")}<a href="/">{props.t("confirmation.subtitleLink")}</a></h3>
            <i>
                <h3 className="confirmation__title">
                    {
                        confirmation.inProcess
                            ? props.t("confirmation.inProcess")
                            : confirmation.entityNotFound
                            ? props.t("confirmation.error")
                            : confirmation.alreadyConfirmed
                            ? props.t("confirmation.alreadyExist")
                            : confirmation.tokenExpired
                            ? props.t("confirmation.expired")
                            : (
                                <div>{props.t("confirmation.success")}<a href="/login">{props.t("confirmation.link")}</a></div>
                            )
                    }
                </h3>
            </i>
        </div>
    );
}

export default withTranslation()(RegistrationConfirmation);

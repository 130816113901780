import { SelectProps } from "../domain/models/SelectProps";
import { Translate } from "../types/Translate";

export enum PrayerPeriod {
    ONE_DAY = 1,
    LONG_PERIOD = 2,
}

export function getPrayerPeriodsTranslation(t: Translate): SelectProps[] {
    return [
        { value: PrayerPeriod.ONE_DAY.toString(), label: t(`prayer.period.${PrayerPeriod.ONE_DAY}`) },
        { value: PrayerPeriod.LONG_PERIOD.toString(), label: t(`prayer.period.${PrayerPeriod.LONG_PERIOD}`) },
    ];
}

export function getCurrentPrayerPeriodTranslation(t: Translate, period: PrayerPeriod): SelectProps {
    return { value: period?.toString(), label: t(period && `prayer.period.${period}`) };
}

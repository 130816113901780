import { FC, ReactElement } from "react";
import LoadingPanel from "./LoadingPanel";

interface LoadingPanelWithContentProps {
    isVisible: boolean;
    content: ReactElement;
}

const LoadingPanelWithContent: FC<LoadingPanelWithContentProps> = (props) => {
    return (
        <>
            <LoadingPanel isVisible={props.isVisible} />
            <>{props.content}</>
        </>
    );
};

export default LoadingPanelWithContent;

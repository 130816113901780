import { apiURL } from "../Config";
import { PrayerDTO } from "../domain/models/dto/PrayerDTO";
import { Prayer } from "../domain/models/Prayer";
import { PrayerService } from "../domain/services/PrayerService";
import { PrayerPeriod } from "../enums/prayer-period-enum";
import { SortingField } from "../enums/sorting-field-enum";
import prayerMapperFactory from "../mappers/PrayerMapper";
import CollectionTools from "../tools/CollectionTools";
import { get } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { Prayer as PrayerType } from "../enums/prayer-enum";
import { RemoveResponse } from "../types/RemoveResponse";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";

class PrayerServiceImpl extends BaseServiceImpl implements PrayerService {
    async createRequestOrUpdateCurrentEntity(dto: PrayerDTO): Promise<Prayer> {
        if (
            !dto.info ||
            !dto.templeId ||
            !dto.name ||
            !dto.order ||
            !dto.period ||
            !dto.type
        ) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<PrayerDTO, Prayer>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.PRAYER), prayerMapperFactory
        );
    }

    async getPrayerById(id: string): Promise<Prayer> {
        if (!id) {
            return;
        }

        try {
            const prayerMapper = prayerMapperFactory();
            return prayerMapper.fromDTO(
                this.getData(await get(`${apiURL}/api/v1/prayer/${id}`))
            );
        } catch (err) {
            console.log("PrayerServiceImpl.getPrayerById => ERROR:");
            console.log(err);
        }
    }

    async getPrayersByTempleId(templeId: string, getRelations: boolean=false): Promise<Prayer[]> {
        if (!templeId) {
            return;
        }

        try {
            const prayerMapper = prayerMapperFactory();
            return this.getData<Prayer[]>(await get(`${apiURL}/api/v1/temple/${templeId}/prayers?getRelations=${getRelations}`)).map(
                (prayer) => prayerMapper.fromDTO(prayer)
            );
        } catch (err) {
            console.log("PrayerServiceImpl.getPrayersByTempleId => ERROR:");
            console.log(err);
        }
    }

    getSortedPrayersByPeriod(period: PrayerPeriod, prayers: Prayer[]): Prayer[] {
        return CollectionTools.filterEntities<Prayer>(prayers || [], SortingField.PERIOD, period);
    }

    getSortedPrayersByType(type: PrayerType, prayers: Prayer[]): Prayer[] {
        return CollectionTools.filterEntities<Prayer>(prayers || [], SortingField.TYPE, type);
    }

    getPrayerByIdFromPrayers(prayerId: string, prayers: Prayer[]): Prayer {
        return CollectionTools.findEntity<Prayer>(prayers, SortingField.ID, prayerId);
    }

    async removeEntity(entity: Prayer): Promise<RemoveResponse> {
        return await this.removeEntityById<PrayerDTO, Prayer>(entity, this.getV2EntityUrl(EntitiesBaseUrls.PRAYER), prayerMapperFactory);
    }
}

export default function prayerServiceFactory(): PrayerService {
    return new PrayerServiceImpl();
}

import _ from "lodash";
import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ChangeRequestEntity } from "../../../../domain/models/ChangeRequestEntity";
import { UserParams } from "../../../../enums/user-params-enum";
import templeChangesRequestServiceFactory from "../../../../services/TempleChangesRequestServiceImpl";
import { Translate } from "../../../../types/Translate";
import ChangeRequestEntityItemData from "./ChangeRequestEntityItemData";

interface ChangeRequestEntityItemProps {
    t: Translate;

    isSuperAdminUser?: boolean;
    changeRequestEntity: ChangeRequestEntity;
    requestId: string;

    setError: (value: string) => void;
    setIsDataUpdated: (value: boolean) => void;
}

const ChangeRequestEntityItem: FC<ChangeRequestEntityItemProps> = (props) => {
    const templeChangeRequestService = templeChangesRequestServiceFactory();
    const templeId = useSearchParams()[0].get(UserParams.TEMPLE_ID);

    const templeSectionsChanges = templeChangeRequestService.getTempleSectionsChanges(props.changeRequestEntity);

    const approveEntity = () => {
        const execute = async () => {
            const result = await templeChangeRequestService.approveOrDeclineEntity(
                props.changeRequestEntity.id,
                props.changeRequestEntity.entity,
                true
            );
            if (!result) {
                props.setError("errors.common");
            }
        };

        execute();
        props.setIsDataUpdated(false);
    };

    return (
        <div className={!props.changeRequestEntity.isDeleted ? "update__history" : "update__history__deleted"}>
            <div className="update__history-top">
                <div className="update__history-links">
                    <a href="#" className="update__history-link">
                        {props.t(`entity.${props.changeRequestEntity.entity}`)}
                    </a>
                </div>

                <a href={`/temple/${templeId}`} className="update__history-preview">
                    {props.t("updates.update.visitingTemple")}
                </a>
            </div>

            {props.changeRequestEntity.isTemple ? (
                templeSectionsChanges?.map((entity, index) => (
                    <div key={"update__history-body-temple" + index}>
                        {entity.changes.length ? (
                            <>
                                <h3 className="update__section__title">{props.t(`section.${entity.section}`)}</h3>
                                <ChangeRequestEntityItemData
                                    changes={entity.changes}
                                    entityType={props.changeRequestEntity.entity}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                ))
            ) : (
                <ChangeRequestEntityItemData
                    changes={props.changeRequestEntity.changes}
                    entityType={props.changeRequestEntity.entity}
                />
            )}

            <div className="update__history-body" onClick={() => approveEntity()}>
                {props.isSuperAdminUser && props.changeRequestEntity.isRequiredDataExist &&
                    !_.isEmpty(props.changeRequestEntity.changes) &&
                    props.changeRequestEntity.isProcessing && (
                        <button className="update__button">{props.t("updates.update.confirmButton")}</button>
                    )}
            </div>
        </div>
    );
};

export default withTranslation()(ChangeRequestEntityItem);

import _ from "lodash";
import { apiURL } from "../Config";
import { ChangeRequestEntity } from "../domain/models/ChangeRequestEntity";
import { ChangeRequestEntityDTO } from "../domain/models/dto/ChangeRequestEntityDTO";
import { FileDTO } from "../domain/models/dto/FileDTO";
import { TempleChangesRequestDTO } from "../domain/models/dto/TempleChangesRequestDTO";
import { EntityChange, EntityFieldTranslation } from "../domain/models/EntityChange";
import { TempleChangesRequestWithCount } from "../domain/models/TempleChangesRequest";
import { TempleChangesRequestService } from "../domain/services/TempleChangesRequestService";
import { Entity } from "../enums/change-request-entity-enums/entity-enum";
import { ReligionFields } from "../enums/change-request-entity-enums/entity-field-type-enum";
import { TempleSection } from "../enums/change-request-entity-enums/temple-section-enum";
import { ChangesRequestSortFields } from "../enums/changes-request-sort-fields-enum";
import { SortingField } from "../enums/sorting-field-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import changeRequestEntityMapperFactory from "../mappers/ChangeRequestEntityMapper";
import templeChangesRequestMapperFactory from "../mappers/TempleChangesRequestMapper";
import ChangeRequestEntityModel from "../models/ChangeRequestEntityModel";
import CollectionTools from "../tools/CollectionTools";
import { get, patch } from "../tools/Tools";
import { EntityResolvingResponse } from "../types/EntityResolvingResponse";
import { PaginationParams } from "../types/PaginationParams";
import { Translate } from "../types/Translate";
import { BaseServiceImpl } from "./BaseServiceImpl";
import religionsServiceFactory from "./ReligionsServiceImpl";

export class TempleChangesRequestServiceImpl
    extends BaseServiceImpl
    implements TempleChangesRequestService
{
    private static _instance: TempleChangesRequestServiceImpl;
    private static _templeChangesRequests: TempleChangesRequestWithCount = null;

    private constructor() {
        super();
    };

    static getInstance(): TempleChangesRequestService {
        if (!TempleChangesRequestServiceImpl._instance) {
            TempleChangesRequestServiceImpl._instance = new TempleChangesRequestServiceImpl();
        }

        return TempleChangesRequestServiceImpl._instance;
    }

    private async getTempleChangesRequests(params: PaginationParams<ChangesRequestSortFields>): Promise<TempleChangesRequestWithCount> {
        try {
            const templeChangesRequestMapper = templeChangesRequestMapperFactory();
            let url = `${apiURL}/api/v1/temple-changes-requests/?${this.getPaginationParamsUrl(params, ChangesRequestSortFields.STATUS)}`;

            const result = await get(url);

            const templeChangesRequests =
                result.data?.map((temple: TempleChangesRequestDTO) =>
                    templeChangesRequestMapper.fromDTO(temple)
                ) || [];

            return {
                totalPages: result.totalPages,
                totalRows: result.totalRows,
                inReviewCount: result.inReviewCount,
                rows: templeChangesRequests,
            };
        } catch (err) {
            console.log("TempleChangesRequestServiceImpl.getTempleChangesRequests => ERROR:");
            console.log(err);
        }
    }

    async getCachedTempleChangesRequests(
        params: PaginationParams<ChangesRequestSortFields>,
        reloadCache: boolean = false
    ): Promise<TempleChangesRequestWithCount> {
        if (!TempleChangesRequestServiceImpl._templeChangesRequests || reloadCache) {
            TempleChangesRequestServiceImpl._templeChangesRequests = await this.getTempleChangesRequests(params);
        }
        return TempleChangesRequestServiceImpl._templeChangesRequests;
    }

    async getCachedPaginationData(
        page: number,
        limit: number,
        offset: number,
        sort: ChangesRequestSortFields,
        sortingOrder: SortingOrder,
        reloadCache: boolean=false,
        updateData: boolean=false
    ): Promise<TempleChangesRequestWithCount> {
        const data = await this.getPaginationData<TempleChangesRequestWithCount, ChangesRequestSortFields>(
            TempleChangesRequestServiceImpl._templeChangesRequests,
            this.getTempleChangesRequests,
            {page, limit, offset, sort, sortingOrder},
            reloadCache,
            updateData
        );
        TempleChangesRequestServiceImpl._templeChangesRequests = data;

        return data;
    }

    async getChangeRequestEntities(changesRequestId: string): Promise<ChangeRequestEntity[]> {
        try {
            if (!changesRequestId) {
                return;
            }
            const changeRequestEntityMapper = changeRequestEntityMapperFactory();
            const result = await get(`${apiURL}/api/v1/temple-changes-request/${changesRequestId}`);

            return this.getData<ChangeRequestEntityDTO[]>(result).map((request) => {
                return changeRequestEntityMapper.fromDTO(request);
            });
        } catch (err) {
            console.log("TempleChangesRequestServiceImpl.getChangeRequestModelsData => ERROR:");
            console.log(err);
        }
    }

    getChangesCount(entities: ChangeRequestEntity[]): number {
        let result = 0;

        entities?.forEach((entity) => {
            result += entity.changes.reduce((acc, val) => {
                if (val.isGeneralField) {
                    acc++;
                }

                return acc;
            }, 0);
        });

        return result;
    }

    getSortedChangeRequestByEntity(sortingOrder: SortingOrder, entities: ChangeRequestEntity[]): ChangeRequestEntity[] {
        if (_.isEmpty(entities)) {
            return;
        }

        return CollectionTools.sortEntities<ChangeRequestEntity>(entities, sortingOrder, SortingField.ENTITY);
    }

    getTempleSectionsChanges(entity: ChangeRequestEntity): ChangeRequestEntity[] {
        if (!entity.isTemple) {
            return;
        }

        return [
            TempleSection.MAIN,
            TempleSection.ABBOT,
            TempleSection.VISITORS_INFO,
            TempleSection.ADDITIONAL_INFO,
            TempleSection.ORDERS
        ].map((entityType) => {
            return new ChangeRequestEntityModel({
                entity: Entity.TEMPLE,
                status: entity.status,
                id: entity.id,
                isDeleted: entity.isDeleted,
                changes: entity.changes.filter(change => change.isOfType(entityType)) },
                entityType)
        });

    }

    getEntityFieldTranslation(t: Translate, entityType: Entity, entityChange: EntityChange): EntityFieldTranslation {
        if (entityChange.isBooleanField) {
            return {
                isBoolean: true,
                currentValue: !!(entityChange.currentValue),
                originalValue: !!(entityChange.originalValue)
            }
        }

        if (entityChange.isArrayOfFiles) {
            return {
                isArrayOfFile: entityChange.isArrayOfFiles,
                currentValue: CollectionTools.sortEntities<FileDTO>(entityChange.currentValue, SortingOrder.ASC).map((file: FileDTO) => file.thumbnailPath),
                originalValue: CollectionTools.sortEntities<FileDTO>(entityChange.originalValue, SortingOrder.ASC).map((file: FileDTO) => file.thumbnailPath)
            }
        }

        if ((!entityChange.isEnumField && !entityChange.isReligionField) || entityChange.isArrayOfSimpleValues) {
            return {
                currentValue: entityChange.currentValue,
                originalValue: entityChange.originalValue
            }
        }

        if (entityChange.isReligionField) {
            const religionsService = religionsServiceFactory();

            if (entityChange.checkFieldName(ReligionFields.RELIGION_ID)) {
                return {
                    currentValue: t("religions." + religionsService.getReligion(entityChange.currentValue)?.label),
                    originalValue: t("religions." + religionsService.getReligion(entityChange.originalValue)?.label)
                }
            };

            if (entityChange.checkFieldName(ReligionFields.RELIGION_CURRENT_ID)) {
                return {
                    currentValue: t("religionCurrents." + religionsService.getReligionCurrent(entityChange.currentValue)?.label),
                    originalValue: t("religionCurrents." + religionsService.getReligionCurrent(entityChange.originalValue)?.label)
                }
            };

            if (entityChange.checkFieldName(ReligionFields.RELIGION_SUBSPECIES_ID)) {
                return {
                    currentValue: t("religionSubspecies." + religionsService.getReligionCurrentSubspecies(entityChange.currentValue)?.label),
                    originalValue: t("religionSubspecies." + religionsService.getReligionCurrentSubspecies(entityChange.originalValue)?.label)
                }
            };
        }

        let fieldName = entityChange.fieldName;
        if (entityType === Entity.PRAYER) {
            fieldName = "prayer." + entityChange.fieldName;
        }

        return {
            currentValue: entityChange.currentValue && t(`${fieldName}.${entityChange.currentValue}`),
            originalValue: entityChange.originalValue && t(`${fieldName}.${entityChange.originalValue}`)
        };
    }

    async approveOrDeclineAllByChangeRequestId(changeRequestId: string, isApprove: boolean=true): Promise<EntityResolvingResponse> {
        try {
            const result = await patch(`${apiURL}/api/v1/temple-changes-request/${changeRequestId}/${isApprove ? "approve" : "decline"}`);
            return this.getData<EntityResolvingResponse>(result);
        } catch (err) {
            console.log("TempleChangesRequestServiceImpl.approveOrDeclineAllByChangeRequestId => ERROR:");
            console.log(err);
        }
    }

    async approveOrDeclineEntity(entityId: string, entityType: Entity, isApprove?: boolean): Promise<EntityResolvingResponse> {
        try {
            const result = await patch(`${apiURL}/api/v1/temple-changes-request/${isApprove ? "approve" : "decline"}/${entityType}/${entityId}`);
            return this.getData<EntityResolvingResponse>(result);
        } catch (err) {
            console.log("TempleChangesRequestServiceImpl.approveOrDeclineEntity => ERROR:");
            console.log(err);
        }
    }
}

export default function templeChangesRequestServiceFactory(): TempleChangesRequestService {
    return TempleChangesRequestServiceImpl.getInstance();
}

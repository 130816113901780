import { FC } from "react";
import { Artifact } from "../../../../../domain/models/Artifact";

interface ArtifactModalRightProps {
    artifact: Artifact;
}

const ArtifactModalRight: FC<ArtifactModalRightProps> = (props) => {
    return (
        <div className="modal--main__right">
            <div className="modal--main__text text artifact__modal__text">
                <p>
                    {props.artifact.info}
                </p>
            </div>
        </div>
    );
};

export default ArtifactModalRight;

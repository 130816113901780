import { BankAccountDTO } from "../domain/models/dto/BankAccountDTO";
import { BankAccount } from "../domain/models/BankAccount";
import { BankAccountService } from "../domain/services/BankAccountService";
import bankAccountMapperFactory from "../mappers/BankAccountMapper";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { RemoveResponse } from "../types/RemoveResponse";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";

class BankAccountServiceImpl
    extends BaseServiceImpl
    implements BankAccountService
{
    async createRequestOrUpdateCurrentEntity(dto: BankAccountDTO): Promise<BankAccount> {
        if (
            !dto.accountId ||
            !dto.templeId ||
            !dto.currency ||
            !dto.fullName ||
            !dto.iban
        ) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<BankAccountDTO, BankAccount>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.BANK_ACCOUNT), bankAccountMapperFactory
        );
    }

    async removeEntity(entity: BankAccount): Promise<RemoveResponse> {
        return await this.removeEntityById<BankAccountDTO, BankAccount>(entity, this.getV2EntityUrl(EntitiesBaseUrls.BANK_ACCOUNT), bankAccountMapperFactory);
    }
}

export default function bankAccountServiceFactory(): BankAccountService {
    return new BankAccountServiceImpl();
}

import { useFormik } from "formik";
import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { User } from "../../../domain/models/User";
import { userServiceFactory } from "../../../services/UserServiceImpl";
import { PersonalDataRegisterSchemaFactory } from "../../../validation-schemas/user-schemas";
import { RegisterData } from "./Register";
import languageServiceFactory from "../../../services/LanguageServiceImpl";
import { useUpdateInputFieldBackground } from "../../../tools/custom-hooks/form-hooks/useUpdateInputFieldBackground";
import Popup from "reactjs-popup";
import SuccessRegistrationModal from "./SuccessRegistrationModal";

interface PersonalDetailsProps {
    t(key?: string): string;
    setRegisterData(data: RegisterData): void;
    data: RegisterData;
}

const languageService = languageServiceFactory();

const PersonalDetails: FC<PersonalDetailsProps> = (props) => {
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);
    const [isSuccessRegistration, setSuccessRegistration] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            lastname: "",
            phone: "",
            temple: "",
        },
        validationSchema: PersonalDataRegisterSchemaFactory({ t: props.t }),
        onSubmit: async (values, actions) => {
            const { name, lastname, phone, temple } = values;

            actions.setSubmitting(true);

            if (!name || !lastname || !phone || !temple) {
                return;
            }

            try {
                props.setRegisterData({
                    name: name,
                    lastname: lastname,
                    phone: phone,
                    temple: temple,
                });

                const user: User | undefined = await userServiceFactory().register({
                    email: props.data.email || "",
                    password: props.data.password || "",
                    confirmPassword: props.data.confirmPassword || "",
                    firstName: name,
                    lastName: lastname,
                    phone: phone,
                    templeName: temple,
                });

                if (!user) {
                    setErrorVisibility(true);
                    return;
                }

                setSuccessRegistration(true);
            } catch {
                setErrorVisibility(true);
                return;
            }
        },
    });

    const navigate = useNavigate();

    useUpdateInputFieldBackground(formik, "phone");

    const phoneHandler = (value: string) => {
        formik.setFieldValue("phone", value, true);
    };

    const successCloseModalCallback = () => {
        setSuccessRegistration(false);
        navigate("/login");
    }

    return (
        <div>
            <form id="regForm" onSubmit={formik.handleSubmit}>
                {isErrorVisible &&
                    (formik.values.name === "" ||
                        formik.values.lastname === "" ||
                        formik.values.phone === "" ||
                        formik.values.temple === "") &&
                    setErrorVisibility(false)}

                <h1 className="form__title">{props.t("register.personalTitle")}</h1>
                <div className="form__wrap">
                    <input
                        className={`form__input ${formik.touched.name && formik.values.name === "" && "invalid"}`}
                        type="text"
                        name="name"
                        placeholder={props.t("register.name")}
                        onChange={formik.handleChange}
                        defaultValue={formik.values.name}
                    />
                </div>
                <p style={{ marginTop: `${formik.touched.name && formik.errors.name ? "-25px" : "5px"}` }}>
                    {formik.touched.name && formik.errors.name}
                </p>

                <div className="form__wrap">
                    <input
                        className={`form__input ${
                            formik.touched.lastname && formik.values.lastname === "" && "invalid"
                        }`}
                        type="text"
                        name="lastname"
                        placeholder={props.t("register.lastname")}
                        onChange={formik.handleChange}
                        defaultValue={formik.values.lastname}
                    />
                </div>
                <p style={{ marginTop: `${formik.touched.lastname && formik.errors.lastname ? "-25px" : "5px"}` }}>
                    {formik.touched.lastname && formik.errors.lastname}
                </p>

                <div className="form__wrap">
                    <PhoneInput
                        tabIndex="phone"
                        placeholder={props.t("register.phone")}
                        name="phone"
                        value={formik.values.phone}
                        onChange={phoneHandler}
                        international={true}
                        limitMaxLength={true}
                        labels={languageService.getCurrentPhoneNumberLocale()}
                    />
                </div>
                <p style={{ marginTop: `${formik.touched.phone && formik.errors.phone ? "-25px" : "5px"}` }}>
                    {formik.touched.phone && formik.errors.phone}
                </p>

                <div className="form__wrap">
                    <input
                        className={`form__input ${formik.touched.temple && formik.values.temple === "" && "invalid"}`}
                        name="temple"
                        type="text"
                        placeholder={props.t("register.temple")}
                        onChange={formik.handleChange}
                        defaultValue={formik.values.temple}
                    />
                </div>
                <p style={{ marginTop: `${formik.touched.temple && formik.errors.temple ? "-25px" : "5px"}` }}>
                    {formik.touched.temple && formik.errors.temple}
                </p>

                <p>{isErrorVisible && props.t("errors.common")}</p>

                <div className="form__btns">
                    <button className="form__btn" type="submit" id="nextBtn">
                        {props.t("register.next")}
                    </button>
                </div>
            </form>

            <Popup
                open={isSuccessRegistration}
                modal
                overlayStyle={{ zIndex: 999999 }}
                onClose={successCloseModalCallback}
            >
                <SuccessRegistrationModal closeCallback={successCloseModalCallback} />
            </Popup>
        </div>
    );
};

export default withTranslation()(PersonalDetails);

import { FC } from "react";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";

interface DownloadPDFButtonSVGProps {
    t: Translate;
}

const DownloadPDFButtonSVG: FC<DownloadPDFButtonSVGProps> = (props) => {
    return (
        <div className="payout-information-button__print" title={props.t("admin.payouts.details.saveAsPDF")}>
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="28.000000pt"
                height="28.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#57d3db" stroke="none">
                    <path
                        d="M1265 5106 c-84 -21 -147 -57 -211 -121 -104 -103 -134 -195 -134
-405 l0 -130 1323 0 c1286 0 1326 -1 1401 -20 113 -29 190 -73 276 -160 87
-86 131 -163 160 -276 19 -74 20 -113 20 -844 0 -731 -1 -770 -20 -844 -28
-111 -73 -189 -160 -276 -86 -87 -162 -131 -276 -160 -75 -19 -115 -20 -1401
-20 l-1323 0 0 -720 c0 -498 3 -735 11 -772 19 -86 59 -158 124 -223 67 -68
141 -107 231 -125 42 -8 448 -10 1423 -8 l1364 3 -167 195 c-181 211 -220 273
-238 383 -30 181 78 391 240 466 l52 24 0 229 c0 263 11 331 66 425 64 108
175 187 302 214 57 11 414 7 465 -6 l27 -7 0 1390 c0 999 -3 1406 -11 1444
-37 172 -176 311 -347 347 -80 17 -3129 14 -3197 -3z"
                    />
                    <path
                        d="M203 4132 c-100 -35 -171 -115 -193 -216 -8 -35 -10 -278 -8 -796 l3
-745 28 -57 c32 -64 80 -111 146 -142 l46 -21 1675 0 1675 0 57 28 c64 32 111
80 142 146 21 46 21 54 21 821 0 767 0 775 -21 821 -31 66 -78 114 -142 146
l-57 28 -1660 2 c-1573 2 -1663 2 -1712 -15z m909 -420 c120 -59 195 -158 219
-287 29 -158 -65 -330 -221 -404 -48 -22 -71 -26 -175 -29 -184 -4 -165 18
-165 -187 0 -189 -6 -217 -51 -241 -45 -23 -78 -17 -115 20 l-34 34 0 532 0
532 35 35 35 35 203 -4 202 -3 67 -33z m926 23 c76 -18 111 -35 178 -86 92
-70 167 -204 194 -346 8 -38 11 -110 8 -185 -4 -100 -10 -138 -32 -203 -31
-91 -99 -199 -156 -248 -108 -92 -201 -117 -443 -117 -183 0 -213 7 -235 55
-9 19 -12 166 -12 553 0 571 -1 560 54 582 37 15 374 11 444 -5z m1158 -19
c25 -24 34 -43 34 -66 0 -23 -9 -41 -34 -66 l-34 -34 -171 0 -171 0 0 -145 0
-145 155 0 155 0 32 -29 c27 -24 33 -36 33 -71 0 -35 -6 -47 -33 -71 l-32 -29
-155 0 -155 0 0 -224 c0 -219 -1 -226 -22 -251 -49 -56 -134 -40 -164 30 -21
50 -21 1019 0 1069 27 64 35 66 294 66 l234 0 34 -34z"
                    />
                    <path
                        d="M772 3369 l3 -182 115 2 c101 2 120 5 156 26 98 57 119 180 47 266
-45 53 -93 69 -216 69 l-107 0 2 -181z"
                    />
                    <path
                        d="M1740 3151 l0 -401 103 0 c156 0 228 28 294 114 55 72 75 138 80 261
7 160 -19 253 -94 336 -63 70 -99 82 -250 87 l-133 4 0 -401z"
                    />
                    <path
                        d="M4355 1636 c-37 -16 -70 -52 -84 -89 -7 -18 -11 -157 -11 -388 l0
-359 -90 0 c-101 0 -132 -11 -173 -60 -34 -40 -43 -91 -26 -137 19 -49 454
-556 500 -582 43 -25 89 -27 133 -6 33 16 465 509 498 569 24 43 22 94 -5 140
-34 59 -74 76 -184 76 l-93 0 0 365 c0 347 -1 368 -20 405 -35 69 -72 80 -257
80 -109 -1 -167 -5 -188 -14z"
                    />
                </g>
            </svg>
        </div>
    );
};

export default withTranslation()(DownloadPDFButtonSVG);

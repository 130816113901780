import { MouseEvent, MutableRefObject, useRef } from "react";

interface IUsePseudoElementInputButton {
    inputRef: MutableRefObject<any>;
    handler: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const usePseudoElementInputButton = (
    leftButtonBorder: number,
    rightButtonBorder: number,
    buttonCallback: () => void,
    inputCallback?: () => void,
): IUsePseudoElementInputButton => {
    const inputRef = useRef(null);
    const handler = (event: MouseEvent<HTMLButtonElement>) => {
        const inputRect = inputRef.current.getBoundingClientRect();

        if (
            (event.clientX > inputRect.right - leftButtonBorder) &&
            (event.clientX < inputRect.right - rightButtonBorder)
        ) {
            buttonCallback();
        } else {
            inputCallback && inputCallback();
        }
    };

    return { inputRef, handler };
}

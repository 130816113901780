import { FC, RefObject, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../domain/models/Temple";
import { TempleSortFields } from "../../../enums/temple-sort-fields-enum";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import { SortingColumnsHandlers } from "../../../types/SortingColumnsHandlers";
import SortingColumn from "../../base/table/SortingColumn";
import TempleRow from "./TempleRow";

interface TempleTableProps {
    t(key?: string): string;
    temples: Temple[];

    templesTableRef: RefObject<HTMLDivElement>;
    sortingColumnsHandlers: SortingColumnsHandlers;
}

const TempleTable: FC<TempleTableProps> = (props) => {
    const religionService = religionsServiceFactory();
    const [isReligionsExist, setIsReligionsExist] = useState<boolean>(false);

    useEffect(() => {
        const setData = async () => {
            const religions = await religionService.getCachedReligions();
            setIsReligionsExist(Boolean(religions));
        };

        setData();
    });

    return (
        <>
            <div ref={props.templesTableRef}>
                <table id="tableSort" className="inner__table sortable-table">
                    <thead>
                        <tr>
                            <th className="th-style td-padding2">
                                {props.t("admin.temples.table.temple")}
                            </th>
                            <SortingColumn
                                sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                                columnName={TempleSortFields.COUNTRY}
                                columnTranslationName={props.t("admin.temples.table.country")}
                                additionalWrapperClass="td-padding7"
                                currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                                setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                            />
                            <SortingColumn
                                sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                                columnName={TempleSortFields.RELIGION_ID}
                                columnTranslationName={props.t("admin.temples.table.religion")}
                                additionalWrapperClass="td-padding6"
                                currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                                setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                            />
                            <SortingColumn
                                sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                                columnName={TempleSortFields.RELIGION_CURRENT_ID}
                                columnTranslationName={props.t("admin.temples.table.currents")}
                                additionalWrapperClass="td-padding7"
                                currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                                setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                            />
                            <SortingColumn
                                sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                                columnName={TempleSortFields.INCOME}
                                columnTranslationName={props.t("admin.temples.table.income")}
                                additionalWrapperClass="td-padding7"
                                currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                                setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {isReligionsExist && props.temples?.map((temple, index) => (
                            <TempleRow key={`temple_${index}`}  temple={temple} />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default withTranslation()(TempleTable);

import { FC } from "react";

interface LastPageArrowSVGProps {
    additionalClass?: string;
    width?: number;
    height?: number;
    onclick?: () => void;
}

const LastPageArrowSVG: FC<LastPageArrowSVGProps> = (props) => {
    const width = props.width || 48;
    const height = props.height || 48;

    return (
        <svg
            className={"base__arrow__button " + (props.additionalClass || " ")}
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            width={width}
            viewBox="0 -960 960 960"
            onClick={props.onclick}
        >
            <path d="m272-245-43-43 192-192-192-192 43-43 235 235-235 235Zm388 5v-480h60v480h-60Z" />
        </svg>
    );
};

export default LastPageArrowSVG;

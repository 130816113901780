import { TempleExtension } from "../domain/models/TempleExtension";
import { TempleExtensionDTO } from "../domain/models/dto/TempleExtensionDTO";
import { TempleExtensionService } from "../domain/services/TempleExtensionService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import templeExtensionMapperFactory from "../mappers/TempleExtensionMapper";
import { post } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";
import languageServiceFactory from "./LanguageServiceImpl";

class TempleExtensionServiceImpl
    extends BaseServiceImpl
    implements TempleExtensionService
{
    private static CORRESPONDENCE_EMAIL_CONFIRMATION_ROUTE =
        "/correspondence-email-confirmation";

    async createOrUpdateExtensionData(
        model: TempleExtension,
        sendCorrespondenceEmailAgain: boolean = false,
    ): Promise<TempleExtension> {
        try {
            const mapper = templeExtensionMapperFactory();
            const langService = languageServiceFactory();

            return mapper.fromDTO(
                this.getData<TempleExtensionDTO>(
                    await post(
                        this.getBaseEntityUrl(
                            EntitiesBaseUrls.TEMPLE_EXTENSION
                        ),
                        {
                            ...mapper.toDTO(model),
                            language: langService.currentLang,
                            sendCorrespondenceEmailAgain,
                        }
                    )
                )
            );
        } catch (err) {
            console.log(
                "TempleExtensionServiceImpl.createOrUpdateExtensionData => ERROR:"
            );
            console.log(err);
        }
    }

    async confirmCorrespondenceEmail(
        correspondenceEmailToken: string
    ): Promise<boolean> {
        try {
            return !!(
                await post(
                    this.getBaseEntityUrl(EntitiesBaseUrls.TEMPLE_EXTENSION) +
                        TempleExtensionServiceImpl.CORRESPONDENCE_EMAIL_CONFIRMATION_ROUTE,
                    { correspondenceEmailToken }
                )
            ).data;
        } catch (err) {
            console.log("TempleExtensionServiceImpl.confirmCorrespondenceEmail => ERROR:");
            console.log(err);
        }
    }
}

export default function templeExtensionServiceFactory(): TempleExtensionService {
    return new TempleExtensionServiceImpl();
}

import { FileDTO } from "../domain/models/dto/FileDTO";
import { CustomFile } from "../domain/models/CustomFile";
import { FileModel } from "../models/FileModel";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";
import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";

class FileMapperImpl extends SimpleChangesRequestEntityMapper<CustomFile, FileDTO> {
    protected entityConstructor = FileModel;

    private commonFields: string[] = [
        "id",
        "order",
        "ownerType",
        "purposeType",
        "fileType",
        "filePath",
        "thumbnailPath",
        "ownerId",
        "isDeleted",
    ];

    protected toDTOFields: string[] = [...this.commonFields, "dataUrl"];
    protected toChangesDTOFields: string[] = ["parentId"];
    protected fromDTOFields: string[] = [...this.commonFields, ...this.toChangesDTOFields, "status",];

    fromDTO(dto: FileDTO): CustomFile {
        const entity = super.fromDTO(dto);
        entity.initialOrder = entity.order;
        return entity;
    }
}

export default function fileMapperFactory(): ChangesRequestEntityMapper<CustomFile, FileDTO> {
    return new FileMapperImpl()
}

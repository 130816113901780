import React, { useState, useEffect, createContext, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../domain/models/User";

import { userServiceFactory } from "../services/UserServiceImpl";

function AuthProvider(props: any) {
    const navigate = useNavigate();

    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(true);

    const userService = userServiceFactory();

    useEffect(() => {
        (async function () {
            const currentUser = await userService.getUser();
            if (currentUser) {
                setUser(currentUser);
            }

            setLoading(false);
        })();
    }, [userService]);

    const signIn = useCallback(
        async (email, password) => {
            const result = await userService.login(email, password);
            if (result) {
                setUser(result);
            }

            return result;
        },
        [userService]
    );

    const signOut = useCallback(async () => {
        setUser(undefined);
        await userService.logout();
        navigate("/login");
        window.location.reload();
    }, [userService, navigate]);

    return <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />;
}

interface AuthorizationContext {
    user?: User;
    signIn: (email: string, password: string) => Promise<User | undefined>;
    signOut?: any;
    loading?: any;
}

async function defaultSignIn(_email: string, _password: string) {
    return undefined;
}

const AuthContext = createContext<AuthorizationContext>({
    signIn: defaultSignIn,
});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };

import { withTranslation } from "react-i18next";
import { Translate } from "../../../types/Translate";
import { FC, useMemo, useState } from "react";
import Popup from "reactjs-popup";
import ModalWindow from "../../base/ModalWindow";
import DropDown from "../../base/DropDown";
import { PayoutStatus, getCurrentStatusTranslation, getStatusForResolveTranslation } from "../../../enums/payout-status-enum";
import { SelectProps } from "../../../domain/models/SelectProps";
import { Payout, ResolvePayoutForm } from "../../../domain/models/Payout";
import InputRestrictionsInfo from "../../base/InputRestrictionsInfo";
import { useFormik } from "formik";
import { ResolvePayoutSchemaFactory } from "../../../validation-schemas/admin/resolve-payout-schemas";
import { getValidationClass } from "../../../tools/Tools";
import { payoutServiceFactory } from "../../../services/PayoutServiceImpl";

interface PayoutResolveFormModalProps {
    t: Translate;
    payout: Payout;
    isPayoutResolveFormModalActive: boolean;
    closeModalCallback: () => void;
    reloadCache: () => void;
}

const payoutService = payoutServiceFactory();

const PayoutResolveFormModal: FC<PayoutResolveFormModalProps> = (props) => {
    const nullableValues: ResolvePayoutForm = {
        status: PayoutStatus.EXECUTED,
        comment: "",
        transactionId: "",
    };

    const [status, setStatus] = useState<SelectProps>(getCurrentStatusTranslation(props.t, nullableValues.status));
    const [isErrorVisible, setErrorVisibility] = useState(false);

    const isExecuted = (status: SelectProps) => {
        return parseInt(status.value) === PayoutStatus.EXECUTED;
    };

    const resolvePayoutSchema = useMemo(() => ResolvePayoutSchemaFactory({ t: props.t }), [props.t]);

    const resolvePayoutForm = useFormik({
        enableReinitialize: true,
        initialValues: nullableValues,
        validationSchema: resolvePayoutSchema.schema,
        onSubmit: async (values) => {
            try {
                setErrorVisibility(false);

                const result = await payoutService.resolvePayout(
                    props.payout.id,
                    values.status,
                    values.comment,
                    values.transactionId,
                );

                if (!result.status) {
                    return setErrorVisibility(true);
                }

                props.reloadCache && props.reloadCache();
                props.closeModalCallback();
            } catch {
                setErrorVisibility(true);
            }
        },
    });

    const statusHandleChange = (newStatus: SelectProps) => {
        if (status === newStatus) {
            return;
        }

        if (!isExecuted(newStatus)) {
            resolvePayoutForm.setFieldValue("transactionId", nullableValues.transactionId);
        }

        resolvePayoutForm.setFieldValue("status", parseInt(newStatus.value));
        setStatus(newStatus);
    };

    return (
        <Popup
            open={props.isPayoutResolveFormModalActive}
            modal
            overlayStyle={{ zIndex: 999999, flexDirection: "column" }}
            onClose={props.closeModalCallback}
        >
            <ModalWindow
                contentClass="modal-remove__content modal-remove__content__wider"
                bodyClass="information__articles-article"
                closeCallback={props.closeModalCallback}
                content={
                    <div className="payout__form">
                        {isErrorVisible && <p>{props.t("errors.common")}</p>}
                        <div className="date__form-block date select__value">
                            <DropDown
                                name="status"
                                value={status}
                                options={getStatusForResolveTranslation(props.t)}
                                onChange={statusHandleChange}
                                isError={resolvePayoutForm.touched.status && !!resolvePayoutForm.errors.status}
                                placeholder={props.t("admin.payouts.table.status")}
                                disableIsClearable={true}
                            />
                        </div>
                        {!isExecuted(status) ? (
                            ""
                        ) : (
                            <div className="date__form-block">
                                <input
                                    className={`date__input ${getValidationClass(resolvePayoutForm, "transactionId")}`}
                                    name="transactionId"
                                    onChange={resolvePayoutForm.handleChange}
                                    value={resolvePayoutForm.values.transactionId}
                                    type="text"
                                    placeholder={props.t("admin.payouts.resolveModal.transactionId")}
                                    maxLength={resolvePayoutSchema.inputRestrictions.transactionId.max}
                                />
                                <InputRestrictionsInfo error={resolvePayoutForm.errors.transactionId} />
                            </div>
                        )}
                        <div className="date__form-block">
                            <textarea
                                className={`date__textarea date__input  ${getValidationClass(
                                    resolvePayoutForm,
                                    "comment"
                                )}`}
                                name="comment"
                                onChange={resolvePayoutForm.handleChange}
                                value={resolvePayoutForm.values.comment}
                                cols={30}
                                rows={10}
                                placeholder={props.t("admin.payouts.resolveModal.comment")}
                                maxLength={resolvePayoutSchema.inputRestrictions.comment.max}
                            ></textarea>
                            <InputRestrictionsInfo error={resolvePayoutForm.errors.comment} />
                        </div>
                        <div className="modal__buttons__resolve">
                            <button
                                className="modal__button modal__button-remove modal__button-remove__resolve"
                                type="button"
                                onClick={() => resolvePayoutForm.handleSubmit()}
                            >
                                {props.t("admin.payouts.resolveModal.resolveButton")}
                            </button>
                            <button
                                className="modal__button modal__button-cancel modal__button-cancel__resolve"
                                onClick={props.closeModalCallback}
                            >
                                {props.t("admin.payouts.credits.modalDiscardButton")}
                            </button>
                        </div>
                    </div>
                }
            />
        </Popup>
    );
};

export default withTranslation()(PayoutResolveFormModal);

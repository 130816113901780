import { FC } from "react";
import { withTranslation } from "react-i18next";

interface AboutPageProps {
    t(key?: string): string;
}

const AboutPage: FC<AboutPageProps> = (props) => {
    return (
        <section className="about">
            <h1 className="about__title">{props.t("user.about.title")}</h1>
            <div className="about__img about__img--top">
                <picture>
                    {/* <source srcSet="../img/about.webp" type="image/webp" /> */}
                    <img src="../img/about.png" alt="" />
                </picture>
            </div>
            <div className="container--main">
                <div className="about__text">
                    <p>
                        {props.t("user.about.text1")}
                    </p>
                    <p>
                        {props.t("user.about.text2")}
                    </p>
                    <p>
                        {props.t("user.about.text3")}<br />{props.t("user.about.text4")}
                    </p>
                    <span>{props.t("user.about.text5")}</span>
                    <ul>
                        <li>{props.t("user.about.text6")}</li>
                        <li>
                            {props.t("user.about.text7")}
                        </li>
                        <li>{props.t("user.about.text8")}</li>
                        <li>
                            {props.t("user.about.text9")}
                        </li>
                        <li>
                            {props.t("user.about.text10")}
                        </li>
                    </ul>
                    <p>
                        {props.t("user.about.text11")}
                    </p>
                    <p className="about__description">
                        {props.t("user.about.text12")}
                    </p>
                </div>
            </div>
            <div className="about__img about__img--bottom">
                <picture>
                    <source srcSet="img/about.webp" type="image/webp" />
                    <img src="img/about.png" alt="" />
                </picture>
            </div>
        </section>
    );
};

export default withTranslation()(AboutPage);

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Temple } from "../../domain/models/Temple";
import { CreateTempleParams } from "../../enums/create-temple-params-enum";
import { Page } from "../../enums/page-enum";
import { PublicParams } from "../../enums/public-params-enum";
import { SortingOrder } from "../../enums/sorting-order-enum";
import { TempleRegisterPage } from "../../enums/temple-register-page-enum";
import { TempleSortFields } from "../../enums/temple-sort-fields-enum";

export const useRedirectToTempleNextPage = (templeId: string) => {
    const navigate = useNavigate();

    return function(
        nextPage: Page, chooseTemple: boolean=false, sortField?: TempleSortFields, sortingOrder?: SortingOrder
    ) {
        window.scrollTo(0, 0);
        if (!nextPage && !chooseTemple && templeId) {
            navigate(`/temple/${templeId}`);
        } else if (!templeId) {
            let url = `/choose-temple?${PublicParams.REDIRECT}=${nextPage}`;
            if (sortField || sortingOrder) url += `&${PublicParams.RELOAD_CACHE}=true`;
            if (sortField) url += `&${PublicParams.SORT}=${sortField}`;
            if (sortingOrder) url += `&${PublicParams.ORDER}=${sortingOrder}`;
            navigate(url);
        } else {
            navigate(`/temple/${templeId}/${nextPage}`);
        }
    };
};

export const useRedirectToPrevCreateTemplePage = (temple: Temple, prevPage: TempleRegisterPage, isComplete: boolean) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (temple?.id && !isComplete) {
            navigate(`/create-temple/${prevPage}?${CreateTempleParams.ID}=${temple.originId}`);
        }
    }, [navigate, temple, prevPage, isComplete]);
};

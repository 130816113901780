import { PaymentAccountDTO } from "../domain/models/dto/PaymentAccountDTO";
import { PaymentAccount } from "../domain/models/PaymentAccount";
import { SelectProps } from "../domain/models/SelectProps";
import { PaymentAccountService } from "../domain/services/PaymentAccountService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { getCurrentCurrencyTranslation } from "../enums/currency-enum";
import { getCurrentMethodTranslation } from "../enums/payment-method-enum";
import paymentAccountMapperFactory from "../mappers/PaymentAccountMapper";
import { get } from "../tools/Tools";
import { RemoveResponse } from "../types/RemoveResponse";
import { Translate } from "../types/Translate";
import { BaseServiceImpl } from "./BaseServiceImpl";

class PaymentAccountServiceImpl
    extends BaseServiceImpl
    implements PaymentAccountService
{
    async createRequestOrUpdateCurrentEntity(dto: PaymentAccountDTO): Promise<PaymentAccount> {
        if (
            !dto.accountId ||
            !dto.templeId ||
            !dto.currency ||
            !dto.paymentMethod ||
            !dto.recipientName
        ) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<PaymentAccountDTO, PaymentAccount>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.PAYMENT_ACCOUNT), paymentAccountMapperFactory
        );
    }

    async removeEntity(entity: PaymentAccount): Promise<RemoveResponse> {
        return await this.removeEntityById<PaymentAccountDTO, PaymentAccount>(entity, this.getV2EntityUrl(EntitiesBaseUrls.PAYMENT_ACCOUNT), paymentAccountMapperFactory);
    }

    async getPaymentAccountsByTempleId(templeId: string): Promise<PaymentAccount[]> {
        try {
            const mapper = paymentAccountMapperFactory();

            const url = this.getBaseEntityUrl(EntitiesBaseUrls.TEMPLE).concat(`/${templeId}/payment-accounts`);
            const result = await get(url);

            return this.getData<PaymentAccountDTO[]>(result).map((account) => mapper.fromDTO(account));
        } catch (err) {
            console.log("PaymentAccountServiceImpl.getPaymentAccountsByTempleId => ERROR:");
            console.log(err);
        }
    }

    getPaymentAccountSelectProps(paymentAccounts: PaymentAccount[], t: Translate): SelectProps[] {
        return paymentAccounts.map((paymentAccount) => {
            return {
                value: paymentAccount.id,
                label: `${getCurrentMethodTranslation(t, paymentAccount.paymentMethod).label}, ${getCurrentCurrencyTranslation(t, paymentAccount.currency).label}, ${paymentAccount.recipientName} (${paymentAccount.accountId})`,
            }
        });
    }
}

export default function paymentAccountServiceFactory(): PaymentAccountService {
    return new PaymentAccountServiceImpl();
}

import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { DEFAULT_PAGE, PAGE_SIZE_ARRAY } from "../../constants";
import { ResultWithCount } from "../../domain/models/base-result";
import { getPageSizeValue } from "../../tools/Tools";
import { Translate } from "../../types/Translate";
import FirstPageArrowSVG from "./svg/FirstPageArrowSVG";
import LastPageArrowSVG from "./svg/LastPageArrowSVG";
import NextArrowSVG from "./svg/NextArrowSVG";
import PrevArrowSVG from "./svg/PrevArrowSVG";

interface AdminPagePaginationProps<T> {
    t: Translate;

    pagesCount: number;
    data: T;
    reloadCache: boolean;

    serviceCallback: (currentPage: number, pageSize: number, changeContent?: boolean) => Promise<T | void>;
    setData?: (data: T) => void;
    setReloadCache: (value: boolean) => void;

    scrollIntoView?: boolean;
    additionalPaginationClass?: string;
    wrapperRef?: RefObject<HTMLDivElement>;
}

const defaultPageSize = PAGE_SIZE_ARRAY[0];

function AdminPagePagination<T extends ResultWithCount>(props: AdminPagePaginationProps<T>) {
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [changeContent, setChangeContent] = useState(false);

    const scrollHandler = useCallback(() => {
        if (props.wrapperRef) {
            props.scrollIntoView ? props.wrapperRef.current.scrollIntoView() : props.wrapperRef.current?.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
        setChangeContent(true);
    }, [props.scrollIntoView, props.wrapperRef]);

    const nextPageHandler = () => {
        scrollHandler();
        const nextPageIndex = currentPage + 1;
        setCurrentPage(nextPageIndex > props.pagesCount ? 1 : nextPageIndex);
    };

    const prevPageHandler = () => {
        scrollHandler();
        const prevPageIndex = currentPage - 1;
        setCurrentPage(prevPageIndex < 1 ? props.pagesCount : prevPageIndex);
    };

    const firstPageHandler = useCallback(() => {
        scrollHandler();
        setCurrentPage(1);
    }, [scrollHandler]);

    const lastPageHandler = () => {
        scrollHandler();
        setCurrentPage(props.pagesCount);
    };

    const pageSizeHandler = (e: any) => {
        scrollHandler();
        firstPageHandler();
        setPageSize(+e.target.value);
    };

    const isFirstRender = useRef(true);

    useEffect(() => {
        const uploadData = async (forceUpdate: boolean = false) => {
            props.setReloadCache(false);
            setChangeContent(false);

            const result = await props.serviceCallback(currentPage, pageSize, changeContent || forceUpdate);
            result && props.setData && props.setData(result);
        };

        if (!isFirstRender.current && (!props.data || props.reloadCache || changeContent)) {
            uploadData();
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
            uploadData(true);
        }
    }, [props, currentPage, pageSize, changeContent, firstPageHandler]);

    return (
        <div className={"church__pagination church__pagination__admin " + (props.additionalPaginationClass || " ")}>
            {props.pagesCount ? (
                <>
                    <div className="admin__page__pagination">{props.t("base.pagination.itemsPerPage")}</div>
                    <select
                        value={getPageSizeValue(props.data?.rows.length)}
                        onChange={pageSizeHandler}
                        className="admin__page__pagination"
                        name="pageSize"
                        id="adminPageSizeId"
                    >
                        {PAGE_SIZE_ARRAY.map((pageSize) => (
                            <option id={"pageSize" + pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    <div className="admin__page__pagination">
                        {props.t("base.pagination.page")} {currentPage} {props.t("base.pagination.of")}{" "}
                        {props.pagesCount}
                    </div>
                    <FirstPageArrowSVG height={30} onclick={firstPageHandler} />
                    <PrevArrowSVG additionalClass="admin__page__pagination" width={10} onclick={prevPageHandler} />
                    <NextArrowSVG additionalClass="admin__page__pagination" width={10} onclick={nextPageHandler} />
                    <LastPageArrowSVG height={30} onclick={lastPageHandler} />
                </>
            ) : (
                <></>
            )}
        </div>
    );
}

export default withTranslation()(AdminPagePagination);

import { FC, useState } from "react";
import customerSupportTicketServiceFactory from "../../../services/CustomerSupportTicketServiceImpl";
import { SelectProps } from "../../../domain/models/SelectProps";
import { useFormik } from "formik";
import { CustomerSupportTicketEditSchemaFactory } from "../../../validation-schemas/public-pages/customer-support-ticket-schema";
import { withTranslation } from "react-i18next";
import DropDown from "../../base/DropDown";
import _ from "lodash";
import { getValidationClass } from "../../../tools/Tools";
import { RecordStatus, getRecordStatusDropdownOptions, getRecordStatusTranslation } from "../../../enums/record-status";
import { CustomerSupportTicket } from "../../../domain/models/CustomerSupportTicket";

interface CustomerSupportTicketEditFormProps {
    t(key?: string): string;
    customerSupportTicket: CustomerSupportTicket;
    closeCallback: () => void;
}

const CustomerSupportTicketEditForm: FC<CustomerSupportTicketEditFormProps> = (props) => {
    const customerSupportTicketService = customerSupportTicketServiceFactory();

    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    const [status, setStatus] = useState<SelectProps>(
        getRecordStatusTranslation(props.t, props.customerSupportTicket.status)
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: props.customerSupportTicket.status,
            comment: props.customerSupportTicket.comment,
        },
        validationSchema: CustomerSupportTicketEditSchemaFactory({ t: props.t }),
        onSubmit: async (_values) => {
            try {
                setErrorVisibility(false);

                // TODO :: add types
                const result = await customerSupportTicketService.updateCustomerSupportTicket({
                    id: props.customerSupportTicket.id,
                    ...(formik.values as any),
                });

                if (result) {
                    props.customerSupportTicket.assignData(result);
                    props.closeCallback();
                } else {
                    setErrorVisibility(true);
                }
            } catch {
                setErrorVisibility(true);
            }
        },
    });

    const statusChangeHandler = (newStatus: SelectProps) => {
        if (status === newStatus) {
            return;
        }

        formik.setFieldValue("status", _.get(newStatus, "value"));
        setStatus(newStatus);
    };

    return (
        /**
         * Section is added to styles to be working correctly.
         * DropDown styles depends on class "date" of one of the parents.
         * That is wrong. Each component should have independent styles.
         */
        <section className="date cst__form-container">
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <main>
                        <div>
                            <h1 className="date__title">{props.t("admin.customerSupport.editModal.title")}</h1>

                            <div className="date__forms">
                                {isErrorVisible && <p>{props.t("errors.common")}</p>}
                                <div className="date__form-block validated-form-field">
                                    <DropDown
                                        name="status"
                                        value={status}
                                        options={getRecordStatusDropdownOptions(props.t, [
                                            RecordStatus.CREATED.toString(),
                                        ])}
                                        onChange={statusChangeHandler}
                                        isError={formik.touched.status && !!formik.errors.status}
                                        placeholder={props.t("fields.status")}
                                    />
                                    <p className="form-error">{formik.touched.status && formik.errors.status}</p>
                                </div>

                                <div className="date__form-block">
                                    <textarea
                                        className={`date__textarea date__input  ${getValidationClass(
                                            formik,
                                            "comment"
                                        )}`}
                                        name="comment"
                                        onChange={formik.handleChange}
                                        defaultValue={formik.values.comment}
                                        cols={30}
                                        rows={10}
                                        placeholder={props.t("fields.comment")}
                                    ></textarea>

                                    <p className="form-error">{formik.touched.comment && formik.errors.comment}</p>
                                </div>
                            </div>
                        </div>
                    </main>
                    <div className="modal-footer-button-container">
                        <button className="blue-button" type="submit">
                            {props.t("templeRegister.footer.button")}
                        </button>
                        <button onClick={props.closeCallback} className="cancel-button cancel-button__edit" type="button">
                            {props.t("templeRegister.footer.cancelButton")}
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default withTranslation()(CustomerSupportTicketEditForm);

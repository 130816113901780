import { FC, MutableRefObject, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { BankAccount } from "../../../../domain/models/BankAccount";
import { Temple } from "../../../../domain/models/Temple";
import bankAccountMapperFactory from "../../../../mappers/BankAccountMapper";
import bankAccountServiceFactory from "../../../../services/BankAccountServiceImpl";
import { Translate } from "../../../../types/Translate";
import DownSVG from "../../../base/svg/DownSVG";
import UpSVG from "../../../base/svg/UpSVG";
import WhiteEditButtonSVG from "../../../base/svg/WhiteEditButtonSVG";
import WhiteRemoveSVG from "../../../base/svg/WhiteRemoveSVG";

interface BankDetailInfoProps {
    t: Translate;

    account: BankAccount;
    temple: Temple;

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    isOrderChanged: boolean;
    setIsOrderChanged(value: boolean): void;

    setCurrentBankAccount(value: BankAccount): void;
    updateBankAccounts(updatedBankAccount: BankAccount): void;
    setRemovableModel(account: BankAccount): void;
    setIsAllowSetValues(value: boolean): void;

    bankAccountFormRef: MutableRefObject<any>;
    additionalWrapperClassName: string;
}

const BankDetailInfo: FC<BankDetailInfoProps> = (props) => {
    const bankAccountService = bankAccountServiceFactory();
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);

    const scrollToPrayerForm = () => props.bankAccountFormRef.current.scrollIntoView();

    const updateAccountOrder = async (toUp: boolean) => {
        const mapper = bankAccountMapperFactory();

        let accounts;

        if (toUp) {
            accounts = props.temple.upBankAccount(props.account.order);
        } else {
            accounts = props.temple.downBankAccount(props.account.order);
        }

        let result: BankAccount[];
        result = await Promise.all([
            bankAccountService.createRequestOrUpdateCurrentEntity(mapper.toDTO(accounts?.prevElement)),
            bankAccountService.createRequestOrUpdateCurrentEntity(mapper.toDTO(accounts?.currentElement)),
        ]).catch(() => (result = []));

        const current = toUp ? result[0] : result[1];

        if (!current && (accounts.currentElement || accounts.prevElement)) {
            setErrorVisibility(true);
        }

        props.setIsOrderChanged(true);


    };

    const editHandler = () => {
        scrollToPrayerForm();

        props.setIsAllowSetValues(true);
        props.setIsEditing(true);
        props.setCurrentBankAccount(props.account);
    };

    useEffect(() => {
        if (props.isOrderChanged) {
            props.setIsOrderChanged(false);
        }
    });

    return (
        <div className={props.additionalWrapperClassName}>
            {isErrorVisible && <p>{props.t("errors.common")}</p>}
            <div className="order__table-wrap">
                <table className="order__table">
                    <thead>
                        <tr>
                            <th>{props.t("templeRegister.bank.fullName")}</th>
                            <th>{props.t("templeRegister.bank.iban")}</th>
                            <th>{props.t("templeRegister.bank.account")}</th>
                            <th>{props.t("templeRegister.bank.currency")}</th>
                            <th>{" "}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>{props.account.fullName}</td>
                            <td>{props.account.iban}</td>
                            <td>{props.account.accountId}</td>
                            <td>{props.t(`currency.${props.account.currency}`)}</td>
                        </tr>
                    </tbody>
                </table>

                {!props.isEditing && (
                    <div className="order__table-buttons">
                        <div className="order__table-filter">
                            <button
                                className="order__table-up order__table-button"
                                onClick={() => updateAccountOrder(true)}
                                type="button"
                            >
                                <UpSVG />
                            </button>

                            <button
                                className="order__table-down order__table-button"
                                onClick={() => updateAccountOrder(false)}
                                type="button"
                            >
                                <DownSVG />
                            </button>
                        </div>

                        <div className="order__info-edit">
                            <button
                                onClick={() => props.setRemovableModel(props.account)}
                                type="button"
                            >
                                <WhiteRemoveSVG />
                            </button>

                            <button onClick={() => editHandler()} type="button">
                                <WhiteEditButtonSVG />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(BankDetailInfo);

import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";
import { noSpaces } from "../../constants";

export const CreationPayoutSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {}

    return {
        schema: Yup.object().shape({
            templeStatisticId: Yup.string()
                .required(t("errors.commonErrors.required"))
                .matches(noSpaces, t("errors.commonErrors.noSpaces")),
            paymentAccountId: Yup.string()
                .required(t("errors.commonErrors.required"))
                .matches(noSpaces, t("errors.commonErrors.noSpaces")),
        }), inputRestrictions
    };
}

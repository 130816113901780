import { FC } from "react";
import { withTranslation } from "react-i18next";
import { Translate } from "../../../types/Translate";

interface TempleChangesRequestsHeaderProps {
    t: Translate;
    isSuperAdminUser?: boolean;
    total: number;

    error: string;
}

const TempleChangesRequestsHeader: FC<TempleChangesRequestsHeaderProps> = (props) => {
    return (
        <>
            <h1 className="inner__title">{props.t("updates.header")}</h1>
            <div className="inner__info">
                <div className="inner__info-left">
                    <div className="inner__info-number">
                        {props.isSuperAdminUser ? props.t("updates.inReview") : props.t("updates.totalUpdates")}<span>{props.total}</span>
                    </div>
                </div>
            </div>

            {props.error && props.t(props.error)}
            { /* TODO :: add filter for temple-admin */ }
        </>
    );
};

export default withTranslation()(TempleChangesRequestsHeader);

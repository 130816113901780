import { FC, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { SelectProps } from "../../../domain/models/SelectProps";
import { getTempleSortFieldsTranslation } from "../../../enums/temple-sort-fields-enum";
import DropDown from "../../base/DropDown";
import ListSVG from "../../base/svg/ListSVG";
import * as _ from "lodash";
import TempleSearchFilters from "./TempleSearchFilters";
import { TempleSearchParameters } from "../../../domain/models/Temple";

interface TempleSearchPageHeaderProps {
    t(key?: string): string;
    isList: boolean;
    setIsList(value: boolean): void;

    templeSortField: SelectProps;
    updateTempleSortField: (value: SelectProps) => void;
    isSortFieldUpdated: boolean;

    setSearchingParamsUpdated(value: boolean): void;
    setCurrentPageIndex(value: number): void;

    templeSearchParameters: TempleSearchParameters;
    setTempleSearchParameters(value: TempleSearchParameters): void;

    title?: string;
}

const TempleSearchPageHeader: FC<TempleSearchPageHeaderProps> = (props) => {
    const switchLeft = () => {
        !props.isList && props.setIsList(true);
    };

    const switchRight = () => {
        props.isList && props.setIsList(false);
    };

    const filterButtonRef = useRef(null);
    const [isOpenedFilters, setIsOpenedFilters] = useState(false);

    const filtersHandler = (isOpened: boolean) => {
        if (isOpened !== isOpenedFilters && _.get(filterButtonRef, "current")) {
            filterButtonRef.current.classList.toggle("church__right-switch--active");
        }

        setIsOpenedFilters(isOpened);
    };

    return (
        <>
            <div className="church__right-top">
                <div className="church__right-title">{props.title || " "}</div>
                <div className="church__right-buttons">
                    <div className="church__right-block">
                        <button
                            className={
                                "church__right-list church__right-switch church__right-switch" +
                                (props.isList && "--active")
                            }
                            onClick={() => switchLeft()}
                        >
                            <ListSVG />
                        </button>
                        <button
                            className={
                                "church__right-row church__right-switch church__right-switch" +
                                (!props.isList && "--active")
                            }
                            onClick={() => switchRight()}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="#323232"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect x="17" y="5" width="2" height="2" fill="#323232" />
                                <rect x="17" y="11" width="2" height="2" fill="#323232" />
                                <rect x="17" y="17" width="2" height="2" fill="#323232" />
                                <rect x="11" y="5" width="2" height="2" fill="#323232" />
                                <rect x="11" y="11" width="2" height="2" fill="#323232" />
                                <rect x="11" y="17" width="2" height="2" fill="#323232" />
                                <rect x="5" y="5" width="2" height="2" fill="#323232" />
                                <rect x="5" y="11" width="2" height="2" fill="#323232" />
                                <rect x="5" y="17" width="2" height="2" fill="#323232" />
                            </svg>
                        </button>
                    </div>
                    <div className="church__right-block">
                        <div className="church__right-select">
                            <DropDown
                                name="templeSortFields"
                                value={props.isSortFieldUpdated ? null : {
                                    value: props.templeSortField?.value,
                                    label: props.t("templeSortFields." + props.templeSortField?.value),
                                }}
                                options={getTempleSortFieldsTranslation(props.t)}
                                onChange={props.updateTempleSortField}
                                placeholder={props.t("user.religions.header.sort")}
                                isSearchable={false}
                                onMenuOpen={() => filtersHandler(false)}
                            />
                        </div>
                        <div className="church__right-wrap">
                            <button className="church__right-filter" onClick={() => filtersHandler(!isOpenedFilters)} ref={filterButtonRef}>
                                <svg
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M23.3879 19.708C25.1039 19.708 26.4999 21.097 26.4999 22.804C26.4999 24.511 25.1039 25.9 23.3879 25.9C21.6709 25.9 20.2739 24.511 20.2739 22.804C20.2739 21.097 21.6709 19.708 23.3879 19.708ZM23.3879 21.208C22.4979 21.208 21.7739 21.924 21.7739 22.804C21.7739 23.685 22.4979 24.4 23.3879 24.4C24.2769 24.4 24.9999 23.685 24.9999 22.804C24.9999 21.924 24.2769 21.208 23.3879 21.208ZM16.0803 22.0928C16.4943 22.0928 16.8303 22.4288 16.8303 22.8428C16.8303 23.2568 16.4943 23.5928 16.0803 23.5928H9.7793C9.3653 23.5928 9.0293 23.2568 9.0293 22.8428C9.0293 22.4288 9.3653 22.0928 9.7793 22.0928H16.0803ZM12.113 10C13.83 10 15.226 11.39 15.226 13.097C15.226 14.804 13.83 16.192 12.113 16.192C10.397 16.192 9 14.804 9 13.097C9 11.39 10.397 10 12.113 10ZM12.113 11.5C11.224 11.5 10.5 12.216 10.5 13.097C10.5 13.977 11.224 14.692 12.113 14.692C13.003 14.692 13.726 13.977 13.726 13.097C13.726 12.216 13.003 11.5 12.113 11.5ZM25.1911 12.4004C25.6051 12.4004 25.9411 12.7364 25.9411 13.1504C25.9411 13.5644 25.6051 13.9004 25.1911 13.9004H18.8911C18.4771 13.9004 18.1411 13.5644 18.1411 13.1504C18.1411 12.7364 18.4771 12.4004 18.8911 12.4004H25.1911Z"
                                        fill="#323232"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <TempleSearchFilters
                    activeClass={isOpenedFilters}
                    filtersHandler={() => filtersHandler(false)}
                    setCurrentPageIndex={props.setCurrentPageIndex}
                    setSearchingParamsUpdated={props.setSearchingParamsUpdated}
                    setTempleSearchParameters={props.setTempleSearchParameters}
                    templeSearchParameters={props.templeSearchParameters}
                    filterButtonRef={filterButtonRef}
                />
            </div>
        </>
    );
};

export default withTranslation()(TempleSearchPageHeader);

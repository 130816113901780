import { Delete } from "@material-ui/icons";
import { FC } from "react";
import { withTranslation } from "react-i18next";
import { apiURL } from "../../Config";
import { CustomFile } from "../../domain/models/CustomFile";
import DownSVG from "./svg/DownSVG";
import UpSVG from "./svg/UpSVG";

interface IRemovablePhotoProps {
    t(key?: string): string;
    image: CustomFile;

    isFirstImage: boolean;
    isLastImage: boolean;
    useOriginalFilePath: boolean;

    removePhoto: (image: CustomFile) => void;
    moveFileToLeft: (order: number) => void;
    moveFileToRight: (order: number) => void;
}

const RemovableImage: FC<IRemovablePhotoProps> = (props: IRemovablePhotoProps) => {
    const imageSrc = props.image.dataUrl
        ? props.image.dataUrl
        : `${apiURL}/files/${!props.useOriginalFilePath ? props.image.thumbnailOrOriginalPath : props.image.filePath}`;

    return (
        <div className="removable-image__image-container">
            <img src={imageSrc} alt="" width="100" className="date__form-button" />
            <div className="removable-image__delete-icon">
                <span
                    title={props.t("base.photo.remove")}
                    onClick={() => {
                        props.removePhoto(props.image);
                    }}
                >
                    <Delete></Delete>
                </span>
            </div>

            {!(props.isFirstImage && props.isLastImage) && (
                <div className="order__table-filter">
                    <button
                        className="order__table-up order__table-button left__arrow__svg"
                        onClick={() => !props.isFirstImage && props.moveFileToLeft(props.image.order)}
                        type="button"
                    >
                        {!props.isFirstImage && <UpSVG />}
                    </button>

                    <button
                        className="order__table-down order__table-button right__arrow__svg"
                        onClick={() => !props.isLastImage && props.moveFileToRight(props.image.order)}
                        type="button"
                    >
                        {!props.isLastImage && <DownSVG />}
                    </button>
                </div>
            )}
        </div>
    );
};

export default withTranslation()(RemovableImage);

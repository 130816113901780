import { FC } from "react";
import { withTranslation } from "react-i18next";
import ModalButton from "../../base/FilterModalButton";
import Filter from "./TempleFilter";

interface TempleHeaderProps {
    t(key?: string): string;
    totalRows: number;
}

const TempleHeader: FC<TempleHeaderProps> = (props) => {
    return (
        <>
            <h1 className="inner__title">{props.t("admin.temples.header.temples")}</h1>

            <div className="inner__info-left">
                <div className="inner__info-number">
                    {props.t("admin.temples.header.total")}<span>{props.totalRows}</span>
                </div>
            </div>

            <div className="inner__info-right">
                <button className="inner__info-reset">{props.t("admin.temples.header.clearFilter")}</button>

                <ModalButton >
                    <Filter  />
                </ModalButton>
            </div>
        </>
    );
};

export default withTranslation()(TempleHeader);

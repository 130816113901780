export enum CustomerSupportTicketFields {
    TICKET_NAME = "ticketName",
    SUBJECT_TYPE = "subjectType",
    FULL_NAME = "fullName", // Used to sort by CustomerSupportTicket.firstName
    EMAIL = "email",
    CUSTOMER_ROLE = "customerRole",
    STATUS = "status",
    EMAIL_SENT = "emailSent",
    USER_ID = "userId", // May be used to sort by isCustomerRegistered
    IS_EMAIL_REGISTERED = "isEmailRegistered",
    LANGUAGE = "language",
    CREATED_AT = "createdAt",
}

import { FC } from "react";
import { withTranslation } from "react-i18next";
import templeChangesRequestServiceFactory from "../../../../services/TempleChangesRequestServiceImpl";
import { Translate } from "../../../../types/Translate";

interface UpdatesConfirmButtonsProps {
    t: Translate;
    requestId: string;

    setError: (value: string) => void;
    setIsDataUpdated: (value: boolean) => void;
}

const UpdatesConfirmButtons: FC<UpdatesConfirmButtonsProps> = (props) => {
    const templeChangesRequestService = templeChangesRequestServiceFactory();

    const approveOrDeclineRequest = async (isApprove: boolean) => {
        const result = await templeChangesRequestService.approveOrDeclineAllByChangeRequestId(
            props.requestId,
            isApprove
        );
        if (!result) {
            props.setError("errors.common");
        }

        props.setIsDataUpdated(false);
    };

    return (
        <div className="td-confirms" style={{ display: "flex", justifyContent: "end" }}>
            <div className="td-confirm" onClick={() => approveOrDeclineRequest(true)}>
                <div className="update__history-body">
                    <button className="update__button">{props.t("updates.update.approveAllButton")}</button>
                </div>
            </div>

            <div className="td-confirm" onClick={() => approveOrDeclineRequest(false)}>
                <div className="update__history-body">
                    <button className="update__button" style={{ background: "#f0c34e" }}>
                        {props.t("updates.update.declineAllButton")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(UpdatesConfirmButtons);

export enum PayoutFields {
    TEMPLE_NAME = "templeName",
    TRANSACTION_AMOUNT = "transactionAmount",
    TRANSACTION_PRAYERS_AMOUNT = "transactionPrayersAmount",
    TRANSACTION_DONATIONS_AMOUNT = "transactionDonationsAmount",
    TRANSACTION_COUNT = "transactionCount",
    TRANSACTION_PRAYERS_COUNT = "transactionPrayersCount",
    TRANSACTION_DONATIONS_COUNT = "transactionDonationsCount",
    STATUS = "status",
    CREATED_AT = "createdAt",
    UPDATED_AT = "updatedAt",
    RESOLVED_AT = "resolvedAt",
    CREATED_BY_NAME = "createdByName",
    RESOLVED_BY_NAME = "resolvedByName",
    TRANSACTION_ID = "transactionId",
    PAYMENT_METHOD = "paymentMethod",
}

import { Mapper } from "../domain/mappers/mapper";
import { TempleChangesRequest } from "../domain/models/TempleChangesRequest";
import { TempleChangesRequestDTO } from "../domain/models/dto/TempleChangesRequestDTO";
import { TempleChangesRequestModel } from "../models/TempleChangesRequestModel";
import { SimpleMapper } from "./SimpleMapper";

class TempleChangesRequestMapper extends SimpleMapper<TempleChangesRequest, TempleChangesRequestDTO> {
    protected entityConstructor = TempleChangesRequestModel;

    protected toDTOFields: string[] = [
        "id",
        "templeId",
        "approvedById",
        "approvedAt",
    ];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        "status",
        "templeName",
        "adminFirstName",
        "adminLastName",
        "createdAt",
        "updatedAt",
    ];
}


export default function templeChangesRequestMapperFactory(): Mapper<TempleChangesRequest, TempleChangesRequestDTO> {
    return new TempleChangesRequestMapper()
}

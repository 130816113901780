import { FC } from "react";

const UpSVG: FC = () => {
    return (
        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L8 1L15 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    );
};

export default UpSVG;

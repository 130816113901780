import { Artifact } from "../domain/models/Artifact";
import { ArtifactDTO } from "../domain/models/dto/ArtifactDTO";
import { ArtifactService } from "../domain/services/ArtifactService";
import { EntitiesBaseUrls } from "../enums/change-request-entity-enums/entities-base-urls-enum";
import { SortingField } from "../enums/sorting-field-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import artifactMapperFactory from "../mappers/ArtifactMapper";
import CollectionTools from "../tools/CollectionTools";
import { RemoveResponse } from "../types/RemoveResponse";
import { BaseServiceImpl } from "./BaseServiceImpl";

export class ArtifactServiceImpl extends BaseServiceImpl implements ArtifactService {
    async createRequestOrUpdateCurrentEntity(dto: ArtifactDTO): Promise<Artifact> {
        if (!dto.name || !dto.info || !dto.templeId) {
            return null;
        }

        return await this.updateEntityOrCreateChangeRequest<ArtifactDTO, Artifact>(
            dto, this.getV2EntityUrl(EntitiesBaseUrls.ARTIFACT), artifactMapperFactory
        );
    }

    getSortedArtifactsByCreateDate(sortingOrder: SortingOrder, artifacts: Artifact[]): Artifact[] {
        if (!artifacts) {
            return [];
        }

        return CollectionTools.sortEntitiesByLocaleCompare<Artifact>(artifacts, sortingOrder, SortingField.CREATED_AT);
    }

    async removeEntity(entity: Artifact): Promise<RemoveResponse> {
        return await this.removeEntityById<ArtifactDTO, Artifact>(entity, this.getV2EntityUrl(EntitiesBaseUrls.ARTIFACT), artifactMapperFactory);
    }
}

export default function artifactServiceFactory(): ArtifactService {
    return new ArtifactServiceImpl();
}

import { FC, useMemo } from "react";
import { SelectProps } from "../../domain/models/SelectProps";
import { dropDownValueFactory, getDaysForMonth } from "../../tools/Tools";
import { years, months } from "../../constants";
import DropDown from "./DropDown";
import _ from "lodash";
import { getCurrentMonthTranslation, getMonthsTranslation } from "../../types/base-enums/months-enum";
import { Translate } from "../../types/Translate";
import { withTranslation } from "react-i18next";

interface DateParams {
    year: number;
    month: number;
    day: number;
}

interface DateFilterProps {
    t: Translate;
    dateParams: DateParams;
    yearHandler: (year: number) => void;
    monthHandler: (month: number) => void;
    dayHandler: (day: number) => void;
    applyCallback: () => void;
    additionalWrapperClassName?: string;
}

const DateFilter: FC<DateFilterProps> = (props) => {
    const currentYear = useMemo(() => {
        return dropDownValueFactory(props.dateParams.year);
    }, [props.dateParams.year]);

    const currentMonth = useMemo(() => {
        const newMonth = props.dateParams.month;

        return getCurrentMonthTranslation(
            props.t, months.find((el) => el === newMonth)
        );
    }, [props.t, props.dateParams.month]);

    const currentDay = useMemo(() => {
        return dropDownValueFactory(props.dateParams.day);
    }, [props.dateParams.day]);

    const days = useMemo(() => {
        return getDaysForMonth(parseInt(currentYear?.value), parseInt(currentMonth?.value));
    }, [currentYear, currentMonth]);

    const yearHandler = (year: SelectProps) => {
        props.yearHandler(parseInt(year?.value));
    };

    const monthHandler = (month: SelectProps) => {
        const newValue = parseInt(month?.value);
        props.monthHandler(!_.isNaN(newValue) ? newValue : null);
    };

    const dayHandler = (day: SelectProps) => {
        props.dayHandler(parseInt(day?.value));
    };

    return (
        <div className={"inner__info-wrapper " + (props.additionalWrapperClassName || "")}>
            <div className="inner__info-date">
                <DropDown
                    options={years}
                    value={currentYear}
                    name="year"
                    onChange={yearHandler}
                    placeholder={props.t("charts.selectYear")}
                    disableIsClearable={true}
                />
            </div>

            <div className="inner__info-date">
                <DropDown
                    options={getMonthsTranslation(props.t)}
                    value={currentMonth}
                    name="month"
                    onChange={monthHandler}
                    placeholder={props.t("charts.selectMonth")}
                />
            </div>

            <div className="inner__info-date">
                <DropDown
                    options={days}
                    value={currentDay}
                    name="day"
                    onChange={dayHandler}
                    placeholder={props.t("charts.selectDay")}
                />
            </div>

            <button className="inner__info-apply--date" onClick={() => props.applyCallback()}>{props.t("charts.applyButton")}</button>
        </div>
    );
};

export default withTranslation()(DateFilter);

import * as Yup from "yup"
import { SchemaFactoryProps } from "../../types/SchemaFactory";


export const DonateSchemaFactory = (props: SchemaFactoryProps) => {
    const t = props.t;
    return Yup.object().shape({
        templeId: Yup.string().optional(),
        amount: Yup.number().required(t("errors.commonErrors.required")),
        currency: Yup.number()
                .required(t("errors.commonErrors.required")),
        senderName: Yup.string()
            .min(2, t("errors.commonErrors.min") + "2")
            .max(200, t("errors.commonErrors.max") + "200")
            .required(t("errors.commonErrors.required")),
        senderEmail: Yup.string().email(t("errors.commonErrors.isNotCorrectEmail")),
        conditionsAccepted: Yup.boolean().required(t("errors.commonErrors.required"))
            .test(
                "conditionsAccepted",
                t("errors.commonErrors.required"),
                function (value) {
                    return value;
                }
            ),
    });
}

import { ChangeEvent, FC, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Page } from "../../enums/page-enum";
import { SortingOrder } from "../../enums/sorting-order-enum";
import { TempleSearchParameters } from "../../enums/temple-search-parameters-enum";
import { TempleSortFields } from "../../enums/temple-sort-fields-enum";
import { useRedirectToTempleNextPage } from "../../tools/custom-hooks/useRedirect";
import LangSelect from "../base/LangSelect";
import Logo from "../base/Logo";
import { closeBurgerMenu } from "../../tools/ScriptsIntegration";
import _ from "lodash";
import { usePseudoElementInputButton } from "../../tools/custom-hooks/base-hooks/usePseudoElementInputButton";
import { PublicParams } from "../../enums/public-params-enum";
import { useKeyDownEvent } from "../../tools/custom-hooks/base-hooks/useInputKeyDownEvent";

interface PublicPageHeaderProps {
    t(key?: string): string;
}

const LEFT_SEARCH__BUTTON_BORDER = 33;
const RIGHT_SEARCH_BUTTON_BORDER = 9;
const SEARCH_INVALID_INPUT_CLASS = "invalid__input";
const MIN_SEARCH_LENGTH = 5;

const PublicPageHeader: FC<PublicPageHeaderProps> = (props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation().pathname.split("/");

    const currentPath = location[1];
    const currentPage = location[3];
    const currentTempleId = currentPath === Page.TEMPLE && location[2];
    const pageToRedirect = searchParams.get(PublicParams.REDIRECT) as Page;
    const isWorshipLinkExist = Boolean(searchParams.get(TempleSearchParameters.IS_WORSHIP_LINK_EXIST));

    const chooseTempleActive =
        (currentPage || currentPath) === Page.CHOOSE_TEMPLE && !pageToRedirect && !isWorshipLinkExist! ? " active" : "";
    const orderPrayerActive =
        (currentPage || currentPath) === Page.ORDER_PRAYER || pageToRedirect === Page.ORDER_PRAYER ? " active" : "";
    const supportActive =
        currentPage === Page.SUPPORT || pageToRedirect === Page.SUPPORT ? " active" : "";
    const isWorshipLinkExistActive = isWorshipLinkExist ? " active" : "";

    const redirectToTempleNextPage = useRedirectToTempleNextPage(currentTempleId);

    const burgerMenuRef = useRef(null);
    const mobileMenuRef = useRef(null);

    const openBurgerMenu = () => {
        if (!_.get(burgerMenuRef, "current")) {
            return;
        }
        var burgerOverlay = document.querySelector(".menu-overlay");
        burgerMenuRef.current.classList.toggle("active");
        if (_.get(mobileMenuRef, "current")) {
            mobileMenuRef.current.classList.toggle("show_mobile_menu");
        }
        burgerOverlay.classList.toggle("active");
    };

    const [searchInputClass, setSearchInputClass] = useState<string>(null);
    const [searchQuery, setSearchQuery] = useState<string>(null);
    const searchSubmitHandler = () => {
        if (!searchQuery || searchQuery.length < MIN_SEARCH_LENGTH) {
            return setSearchInputClass(SEARCH_INVALID_INPUT_CLASS);
        }

        setSearchInputClass(null);
        navigate(`/search?${PublicParams.QUERY}=${searchQuery}`);
    };

    const searchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchInputClass(value ? null : SEARCH_INVALID_INPUT_CLASS);
        setSearchQuery(value);
    };

    const searchInputKeyDownEventHandle = useKeyDownEvent(searchSubmitHandler);
    const searchInputMobileKeyDownEventHandle = useKeyDownEvent(searchSubmitHandler);

    const searchInputHandlers = usePseudoElementInputButton(
        LEFT_SEARCH__BUTTON_BORDER,
        RIGHT_SEARCH_BUTTON_BORDER,
        searchSubmitHandler
    );
    const searchInputMobileHandlers = usePseudoElementInputButton(
        LEFT_SEARCH__BUTTON_BORDER,
        RIGHT_SEARCH_BUTTON_BORDER,
        searchSubmitHandler
    );

    const reloadCacheParam = "reloadCache=true";

    return (
        <header className="header--main">
            <div className="container--main">
                <div className="header--main__inner">
                    <Logo />
                    <div id="mobile-menu" ref={mobileMenuRef}>
                        <label className="header--main__search-wrap">
                            <input
                                ref={searchInputHandlers.inputRef}
                                onClick={searchInputHandlers.handler}
                                onChange={searchChangeHandler}
                                onKeyDown={searchInputKeyDownEventHandle.handleKeyDown}
                                className="header--main__search"
                                type="text"
                                placeholder={props.t("user.header.search")}
                            />
                        </label>
                        <ul className="header--main__navigation">
                            <li className={"header--main__navigation-item" + chooseTempleActive}>
                                <a
                                    onClick={() => {
                                        closeBurgerMenu();
                                        navigate(`${Page.CHOOSE_TEMPLE}?${reloadCacheParam}`);
                                    }}
                                >
                                    {props.t("user.header.religions")}
                                </a>
                            </li>
                            <li className={"header--main__navigation-item" + orderPrayerActive}>
                                <a
                                    onClick={() => {
                                        closeBurgerMenu();
                                        redirectToTempleNextPage(Page.ORDER_PRAYER, true, TempleSortFields.VISITS);
                                    }}
                                >
                                    {props.t("user.header.orderPrayer")}
                                </a>
                            </li>
                            <li className={"header--main__navigation-item" + supportActive}>
                                <a
                                    onClick={() => {
                                        closeBurgerMenu();
                                        redirectToTempleNextPage(
                                            Page.SUPPORT,
                                            true,
                                            TempleSortFields.CREATED_AT,
                                            SortingOrder.DESC
                                        );
                                    }}
                                >
                                    {props.t("user.header.support")}
                                </a>
                            </li>
                            <li className={"header--main__navigation-item" + isWorshipLinkExistActive}>
                                <a
                                    onClick={() => {
                                        closeBurgerMenu();
                                        navigate(
                                            `/${Page.CHOOSE_TEMPLE}?${TempleSearchParameters.IS_WORSHIP_LINK_EXIST}=true&${reloadCacheParam}`
                                        );
                                    }}
                                >
                                    {props.t("user.header.temple")}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="header--main__right">
                        <label className="header--main__search-wrap">
                            <input
                                ref={searchInputMobileHandlers.inputRef}
                                onClick={searchInputMobileHandlers.handler}
                                onChange={searchChangeHandler}
                                onKeyDown={searchInputMobileKeyDownEventHandle.handleKeyDown}
                                className={"header--main__search " + (searchInputClass || "")}
                                type="text"
                                placeholder={props.t("user.header.search")}
                            />
                        </label>
                        <div className="header--main__wrap">
                            <LangSelect />
                            <button id="burger-menu" ref={burgerMenuRef} onClick={openBurgerMenu}>
                                <div className="box_items">
                                    <div className="box box_item1"></div>
                                    <div className="box box_item2"></div>
                                    <div className="box box_item3"></div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default withTranslation()(PublicPageHeader);

import { PrayerPriceDetailDTO } from "../domain/models/dto/PrayerPriceDetailDTO";
import { PrayerPriceDetail } from "../domain/models/PrayerPriceDetail";
import { Currency } from "../enums/currency-enum";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";

class PrayerPriceDetailModel extends BaseRelatedEntityMixinFactory<PrayerPriceDetailDTO>() implements PrayerPriceDetail {
    prayerId: string;
    maxPeople: number;
    price: number;
    priceFloatFormat: number = 0;
    currency: Currency;

    isChanged: boolean = false;

    constructor(data?: PrayerPriceDetailDTO) {
        super();
        Object.assign(this, data || {});
    }

    setIsChanged(isChanged: boolean): void {
        this.isChanged = isChanged;
    }

    setMaxPeople(maxPeople: number): void {
        this.maxPeople = maxPeople;
    }

    setPrice(price: number): void {
        this.priceFloatFormat = price;
    }
}

export default PrayerPriceDetailModel;

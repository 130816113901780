import { FC } from "react";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";

interface PayoutsHeaderShortenedProps {
    t: Translate;
    totalRows: number;
}

const PayoutsHeaderShortened: FC<PayoutsHeaderShortenedProps> = (props) => {
    return (
        <div className="date">
            <h1 className="inner__title">{props.t("admin.payouts.label")}</h1>
            <div className="inner__info-number">
                {props.t("admin.payouts.totalPayouts")}
                <span>{props.totalRows}</span>
            </div>
        </div>
    );
};

export default withTranslation()(PayoutsHeaderShortened);

import moment from "moment";
import { DATETIME_FORMAT } from "../constants";
import { TempleChangesRequestDTO } from "../domain/models/dto/TempleChangesRequestDTO";
import { TempleChangesRequest } from "../domain/models/TempleChangesRequest";
import { RecordStatus } from "../enums/record-status";

export class TempleChangesRequestModel implements TempleChangesRequest {
    id: string;
    templeId: string;
    status: RecordStatus;
    approvedById?: string;
    approvedAt?: Date;

    createdAt?: Date;
    updatedAt?: Date;

    templeName?: string;
    adminFirstName?: string;
    adminLastName?: string;

    private _createdAtStr: string;
    private _updatedAtStr: string;
    private _approvedAtStr: string;

    constructor(data?: TempleChangesRequestDTO) {
        if (!data) {
            return;
        }
        Object.assign(this, data);
    }

    get fullName() {
        return `${this.adminFirstName} ${this.adminLastName}`;
    }

    get createdAtStr() {
        if (!this._createdAtStr) {
            this._createdAtStr = moment(this.createdAt).format(DATETIME_FORMAT);
        }

        return this._createdAtStr;
    };

    get updatedAtStr() {
        if (!this._updatedAtStr) {
            this._updatedAtStr = moment(this.updatedAt).format(DATETIME_FORMAT);
        }
        return this._updatedAtStr;
    };

    get approvedAtStr() {
        if (!this._approvedAtStr) {
            this._approvedAtStr = moment(this.approvedAt).format(DATETIME_FORMAT);
        }
        return this._approvedAtStr;
    };

    get isApproved() {
        return this.status === RecordStatus.APPROVED;
    }

    get isDeclined() {
        return this.status === RecordStatus.DECLINED;
    }

    get isProcessing() {
        return !this.isApproved && !this.isDeclined;
    }

    get isRequiredDataExist(): boolean {
        return !!this.templeName;
    }
}

import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";


export const ShopSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        shopInfo: {
            min: 5,
            max: 5000
        }
    }

    return {
        schema: Yup.object().shape({
            shopInfo: Yup.string()
                .min(inputRestrictions.shopInfo.min, t("errors.commonErrors.min") + inputRestrictions.shopInfo.min)
                .max(inputRestrictions.shopInfo.max, t("errors.commonErrors.max") + inputRestrictions.shopInfo.max)
                .nullable()
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

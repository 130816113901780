import { FormikProps } from "formik";
import * as queryString from "query-string";
import { PAGE_SIZE_ARRAY, PAYMENTS_DECIMAL_PLACES } from "../constants";
import { errorServiceFactory } from "../services/ErrorServiceImpl";
import { BaseSpec } from "../types/Specs";
import { Translate } from "../types/Translate";
import _ from "lodash";
import moment from "moment";
import { SelectProps } from "../domain/models/SelectProps";

const errorService = errorServiceFactory();

export async function get(url: string, credentials: RequestCredentials = "include") {
    const requestConfig: RequestInit = {
        method: "GET",
        credentials: credentials,
    };

    const response = await fetch(url, requestConfig);

    if (!response.ok) {
        await errorService.handleResponseError(response);
    }
    return response.json();
}

// TODO :: investigate issue with cookies and headers
const defaultHeaders = { "Content-type": "application/json" };
export async function patch(
    url: string,
    data?: any,
    method: string = "PATCH",
    headers: HeadersInit = defaultHeaders,
    stringify: boolean = true
) {
    const response = await fetch(url, {
        method,
        credentials: "include",
        headers: headers,
        body: stringify ? JSON.stringify(data) : data,
    });
    if (!response.ok) {
        await errorService.handleResponseError(response);
    }
    return response.json();
}

export async function post(url: string, data?: any, headers?: HeadersInit, stringify?: boolean) {
    return patch(url, data, "POST", headers, stringify);
}

export async function deleteRequest(url: string, data?: any) {
    return patch(url, data, "DELETE");
}

export function getQueryParams() {
    return queryString.parse(window.location.search);
}

export function getElementPosition(elementClass: string): { x: number; y: number } {
    const item = document.querySelector(elementClass);
    const rect = item.getBoundingClientRect();

    return {
        x: rect.x,
        y: rect.y,
    };
}

export function getValidationClass(form: FormikProps<any>, fieldName: string): string {
    return form && fieldName && form.errors[fieldName] ? "invalid" : "";
}

export function getEntityFieldNameTranslation(t: Translate, fieldName: string): string {
    return t(`fields.${fieldName}`);
}

export function sortByKeys(array: BaseSpec[]) {
    array.sort(function (a, b) {
        const keyA = parseInt(Object.keys(a)[0]);
        const keyB = parseInt(Object.keys(b)[0]);

        return keyA - keyB;
    });

    return array;
}

export function getPageSizeValue(rowsCount: number) {
    const defaultPageSize = PAGE_SIZE_ARRAY[0];
    return PAGE_SIZE_ARRAY.find((pageSize) => pageSize >= rowsCount) || defaultPageSize;
}

export const fixedToFloat = (num: number, decimalPlaces: number = PAYMENTS_DECIMAL_PLACES) => {
    if (typeof num !== 'number') {
        return null;
    }
    return num / Math.pow(10, decimalPlaces);
};

export const floatToStr = (number: number) => {
    if (typeof number !== 'number') {
        return '';
    }
    const result = number.toString();
    if (Number.isInteger(number)) {
        return result + ".00";
    }
    if (_.round(number % 1, PAYMENTS_DECIMAL_PLACES).toString().length === 3) {
        return result + "0";
    } else {
        return result;
    }
};

/**
 * modifies the sum to the form of '125.00' or '1.70'.
 * @param num
 * @returns the sum as a string.
 */
export const fixedToFloatAmountStr = (num: number) => {
    if (typeof num !== 'number') {
        return '';
    }
    const number = fixedToFloat(num, PAYMENTS_DECIMAL_PLACES);
    const result = floatToStr(number);
    return result;
};

export const updateElementStyleByTabIndex = (elementTabIndex: string, styleAttribute: string, value: string) => {
    const element = document.querySelector(`[tabindex="${elementTabIndex}"]`) as HTMLElement;
    element.style[styleAttribute as any] = value;
};

export const dropDownValueFactory = (value: number, label?: string) => {
    if (_.isNil(value)) {
        return null;
    }

    const strValue = value.toString();
    return {
        value: strValue,
        label: label || strValue,
    };
};

export const getDaysForMonth = (year: number, month: number): SelectProps[] => {
    const daysInMonth = moment({
        year,
        month,
    }).daysInMonth();

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
        daysArray.push({ value: i.toString(), label: i.toString() });
    }

    return daysArray;
};

export const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};
export const isEveryElementTruthy = (data: unknown) => {
    return data instanceof Array ? !data.some((el) => !el) : true;
};

export const sendFile = async (file: File, url: string, method: string = "PATCH"): Promise<unknown> => {
    const formData = new FormData();
    formData.append("file", file);
    return patch(url, formData, method, {}, false);
};

export const roundNumber = (num: number, decimalPlaces: number = PAYMENTS_DECIMAL_PLACES): number => {
    return _.ceil(num, decimalPlaces);
}

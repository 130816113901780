import { FC } from "react";

interface FirstPageArrowSVGProps {
    additionalClass?: string;
    width?: number;
    height?: number;
    onclick?: () => void;
}

const FirstPageArrowSVG: FC<FirstPageArrowSVGProps> = (props) => {
    const width = props.width || 48;
    const height = props.height || 48;

    return (
        <svg
            className={"base__arrow__button " + (props.additionalClass || " ")}
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            width={width}
            viewBox="0 -960 960 960"
            onClick={props.onclick}
        >
            <path d="M240-240v-480h60v480h-60Zm447-3L453-477l234-234 43 43-191 191 191 191-43 43Z" />
        </svg>
    );
};

export default FirstPageArrowSVG;

import { FC } from "react";
import { Outlet } from "react-router-dom";
import PublicPageFooter from "./PublicPageFooter";
import PublicPageHeader from "./PublicPageHeader";

interface PublicPageComponentProps {}

const PublicPageComponent: FC<PublicPageComponentProps> = () => {
    return (
        <div className="wrapper--main">
            <PublicPageHeader  />
                <Outlet />
            <PublicPageFooter  />

            <div className="menu-overlay"></div>
        </div>
    );
};

export default PublicPageComponent;

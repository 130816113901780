import { FC, MutableRefObject } from "react";
import { withTranslation } from "react-i18next";
import { BankAccount } from "../../../../domain/models/BankAccount";
import { BankAccountDTO } from "../../../../domain/models/dto/BankAccountDTO";
import { Temple } from "../../../../domain/models/Temple";
import { BankAccountService } from "../../../../domain/services/BankAccountService";
import bankAccountMapperFactory from "../../../../mappers/BankAccountMapper";
import bankAccountServiceFactory from "../../../../services/BankAccountServiceImpl";
import { useOrderedEntityRemove } from "../../../../tools/custom-hooks/remove-hooks/useOrderedEntityRemove";
import { Translate } from "../../../../types/Translate";
import BankDetailInfo from "./BankDetailInfo";

interface BankDetailsProps {
    t: Translate;

    temple: Temple;
    bankAccounts: BankAccount[];

    isEditing: boolean;
    setIsEditing(value: boolean): void;

    isOrderChanged: boolean;
    setIsOrderChanged(value: boolean): void;
    setIsAllowSetValues(value: boolean): void;

    setCurrentBankAccount(value: BankAccount): void;
    updateBankAccounts(updatedBankAccount: BankAccount): void;
    removeBankAccount(order: number): BankAccount[];

    bankAccountFormRef: MutableRefObject<any>;
}

const BankDetails: FC<BankDetailsProps> = (props) => {
    const accounts: BankAccount[] = props.bankAccounts;
    const removeHandlers = useOrderedEntityRemove<BankAccountDTO, BankAccount, BankAccountService>(
        bankAccountServiceFactory,
        bankAccountMapperFactory,
        props.removeBankAccount,
    );

    return (
        <div className="entity-container-scroll">
            {removeHandlers.isErrorVisible && props.t("errors.common")}
            {accounts.map((account, index) => (
                <div key={"bank-accounts" + index}>
                    <BankDetailInfo
                        account={account}
                        temple={props.temple}
                        isEditing={props.isEditing}
                        setIsEditing={props.setIsEditing}
                        isOrderChanged={props.isOrderChanged}
                        setIsOrderChanged={props.setIsOrderChanged}
                        setCurrentBankAccount={props.setCurrentBankAccount}
                        updateBankAccounts={props.updateBankAccounts}
                        setRemovableModel={removeHandlers.setRemovableModel}
                        bankAccountFormRef={props.bankAccountFormRef}
                        setIsAllowSetValues={props.setIsAllowSetValues}
                        additionalWrapperClassName={index === 0 ? "" : "order__table__wrapper"}
                    />
                </div>
            ))}
        </div>
    );
};

export default withTranslation()(BankDetails);

import { FC, RefObject, useRef, useState } from "react";
import { SelectProps } from "../../../domain/models/SelectProps";
import { TempleSearchParameters } from "../../../domain/models/Temple";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import ReligionsDropDown from "../../base/custom-drop-down/ReligionsDropDown";
import CustomDropDown from "../../base/custom-drop-down/CustomDropDown";
import { DEFAULT_PAGE } from "../../../constants";
import { withTranslation } from "react-i18next";
import { useClickOutOfElement } from "../../../tools/custom-hooks/base-hooks/useClickOutOfElement";

interface TempleSearchFiltersProps {
    t(key?: string): string;

    setSearchingParamsUpdated(value: boolean): void;
    setCurrentPageIndex(value: number): void;

    templeSearchParameters: TempleSearchParameters;
    setTempleSearchParameters(value: TempleSearchParameters): void;

    activeClass?: string | boolean;
    filtersHandler?(): void;

    filterButtonRef?: RefObject<HTMLButtonElement>;
}

const TempleSearchFilters: FC<TempleSearchFiltersProps> = (props) => {
    const templeService = templeServiceFactory();

    const churchFilterRef = useRef(null);
    useClickOutOfElement(churchFilterRef, props.filtersHandler, [props.filterButtonRef]);

    const [resetReligions, setResetReligions] = useState<boolean>(false);
    // const [isWorshipLinkExist, setIsWorshipLinkExist] = useState<boolean>(
    //     props.templeSearchParameters?.isWorshipLinkExist
    // );
    const [other, setOther] = useState<string>(
        props.templeSearchParameters?.other
    );

    const submitHandler = () => {
        if (props.filtersHandler) props.filtersHandler();
    }

    const countryHandleChange = (newCountry: SelectProps) => {
        if (props.templeSearchParameters?.country === newCountry) {
            return;
        }

        props.setTempleSearchParameters({...props.templeSearchParameters, country: newCountry});
    };

    const cityHandleChange = (newCity: SelectProps) => {
        if (props.templeSearchParameters?.city === newCity) {
            return;
        }

        props.setTempleSearchParameters({...props.templeSearchParameters, city: newCity});
    };

    const otherHandleChange = (otherInfo: string) => {
        setOther(otherInfo);
        props.setTempleSearchParameters({...props.templeSearchParameters, other: otherInfo});
    };

    const resetValues = () => {
        props.setTempleSearchParameters(null);

        setResetReligions(true);
        // setIsWorshipLinkExist(false);
        setOther("");
        props.setSearchingParamsUpdated(true);

        submitHandler();
    };

    const setReligionId = (value: number) => {
        props.setTempleSearchParameters({
            ...props.templeSearchParameters,
            religionId: value,
            religionCurrentId: undefined,
            religionSubspeciesId: undefined,
        });
    };

    const setReligionCurrentId = (value: number) => {
        props.setTempleSearchParameters({
            ...props.templeSearchParameters,
            religionCurrentId: value,
            religionSubspeciesId: undefined,
        });
    };

    const setReligionSubspeciesId = (value: number) => {
        props.setTempleSearchParameters({...props.templeSearchParameters, religionSubspeciesId: value});
    };

    const searchHandler = () => {
        props.setCurrentPageIndex(DEFAULT_PAGE);
        props.setSearchingParamsUpdated(true);

        submitHandler();
    };

    // const isWorshipLinkExistHandler = () => {
    //     setIsWorshipLinkExist(!isWorshipLinkExist);
    //     props.setTempleSearchParameters({...props.templeSearchParameters, isWorshipLinkExist: !isWorshipLinkExist});
    // };

    return (
        <div ref={churchFilterRef} className={"church__filter " + (props.activeClass ? (
            props.activeClass === true
            ? "active"
            : props.activeClass
        ) : "")}>
            <h1 className="church__filter-title">{props.t("user.religions.filter.title")}</h1>
            <ReligionsDropDown
                itemClass="church__filter-item"
                resetReligions={resetReligions}
                setResetReligions={setResetReligions}
                setReligionId={setReligionId}
                setReligionCurrentId={setReligionCurrentId}
                setReligionSubspeciesId={setReligionSubspeciesId}
            />
            <div className="church__filter-item">
                <CustomDropDown
                    name="country"
                    elements={templeService.getCountries()}
                    currentElement={props.templeSearchParameters?.country}
                    handleElementChange={countryHandleChange}
                    placeholder={props.t("templeRegister.mainInfo.country")}
                />
            </div>
            <div className="church__filter-item">
                <CustomDropDown
                    name="city"
                    elements={templeService.getCities()}
                    currentElement={props.templeSearchParameters?.city}
                    handleElementChange={cityHandleChange}
                    placeholder={props.t("templeRegister.mainInfo.city")}
                />
            </div>
            <div className="church__filter-item">
                <input
                    name="other"
                    className="date__input"
                    type="text"
                    value={other}
                    defaultValue={other}
                    onChange={(e) => otherHandleChange(e.target.value)}
                    placeholder={props.t("templeRegister.mainInfo.customSearchPlaceholder")}
                />
            </div>

            {/* <div className="church__filter-item">
                <CustomCheckbox
                    id="isWorshipLinkExistId"
                    defaultValue={isWorshipLinkExist}
                    value={isWorshipLinkExist}
                    name="isWorshipLinkExist"
                    className="checkbox__input"
                    onChange={isWorshipLinkExistHandler}
                    labelContent={
                        <>{props.t("templeRegister.mainInfo.onlineWorshipPresence")}</>
                    }
                    additionalLabelClassName="date__text"
                />
            </div> */}
            <button className="church__filter-search" onClick={searchHandler}>{props.t("user.religions.filter.searchButton")}</button>
            <button className="church__filter-reset" onClick={resetValues}>{props.t("user.religions.filter.resetButton")}</button>
        </div>
    );
};

export default withTranslation()(TempleSearchFilters);

import { FC } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartPeriodType } from "../../../types/charts-enums/chart-period-type-enum";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";
import { getMonthsListTranslation } from "../../../types/base-enums/months-enum";

interface ChartPeriod {
    label: string;
    data: number[];
}

interface BaseChartProps {
    t: Translate;
    title: string;
    periodType: ChartPeriodType;
    currentPeriod: ChartPeriod;
    prevPeriod: ChartPeriod;
    additionalChartClassName?: string;
}

const BaseChart: FC<BaseChartProps> = (props) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: props.title,
            },
        },
    };

    const labels = props.periodType === ChartPeriodType.YEAR
        ? getMonthsListTranslation(props.t)
        : props.periodType === ChartPeriodType.MONTH
        ? ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]
        : ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

    const data = {
        labels,
        datasets: [
            {
                ...props.currentPeriod,
                backgroundColor: "#4682D8",
            },
            {
                ...props.prevPeriod,
                backgroundColor: "#A8CFFF",
            },
        ],
    };

    return <Bar className={"inner__chart " + (props.additionalChartClassName ? props.additionalChartClassName : '')} options={options} data={data} />;
};

export default withTranslation()(BaseChart);

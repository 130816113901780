import { withTranslation } from "react-i18next";
import { SortingColumnsHandlers } from "../../../types/SortingColumnsHandlers";
import { FC, RefObject } from "react";
import SortingColumn from "../table/SortingColumn";
import { PayoutFields } from "../../../enums/payout-fields-enum";
import PayoutRow from "./PayoutRow";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import { Payout } from "../../../domain/models/Payout";
import { UseTableRowsActionsHandlers } from "../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import CustomCheckbox from "../CustomCheckbox";

interface PayoutsTableProps {
    t(key?: string): string;
    payouts: Payout[];
    sortingColumnsHandlers: SortingColumnsHandlers;
    payoutsTableRef: RefObject<HTMLTableElement>;
    rowsHandlers: UseTableRowsActionsHandlers<Payout, null>;
}

const PayoutsTable: FC<PayoutsTableProps> = (props) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const navigateToPayoutDetails = (payoutId: string) => {
        if (user.isAdmin) {
            return navigate(`/admin/payouts/${payoutId}`);
        }

        navigate(`/temple-admin/payouts/${payoutId}`);
    };
    return (
        <table
            ref={props.payoutsTableRef}
            id="tableSort"
            className="inner__table inner__table__max-content sortable-table"
        >
            <thead>
                <tr>
                    {user.isAdmin ? (
                        <>
                            <th className="td-padding1-75 td-padding1-75__short date">
                                <CustomCheckbox
                                    id="selectAllRowButton"
                                    name="selectAllRowButton"
                                    defaultValue={false}
                                    value={props.rowsHandlers.selectAllRows}
                                    className="checkbox__input"
                                    additionalLabelClassName="date__text"
                                    wrapperClassName="order__checkbox simple-checkbox"
                                    onChange={props.rowsHandlers.selectAllRowsHandler}
                                />
                            </th>
                            <th className="td-padding1 td-padding1-75__short">{props.t("admin.payouts.table.actions.columnLabel")}</th>
                        </>
                    ) : (
                        ""
                    )}
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.TEMPLE_NAME}
                        columnTranslationName={props.t("admin.payouts.table.templeName")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                        additionalWrapperClass={user.isAdmin ? "" : "th-sort-first"}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.TRANSACTION_AMOUNT}
                        columnTranslationName={props.t("admin.payouts.table.transactionAmount")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.TRANSACTION_PRAYERS_AMOUNT}
                        columnTranslationName={props.t("admin.payouts.table.transactionPrayersAmount")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.TRANSACTION_DONATIONS_AMOUNT}
                        columnTranslationName={props.t("admin.payouts.table.transactionDonationsAmount")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.STATUS}
                        columnTranslationName={props.t("admin.payouts.table.status")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <th className="">{props.t("admin.payouts.table.transactionsCount")}</th>
                    <th className="">{props.t("admin.payouts.table.transactionsPrayersCount")}</th>
                    <th className="">{props.t("admin.payouts.table.transactionsDonationsCount")}</th>
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.CREATED_AT}
                        columnTranslationName={props.t("admin.payouts.table.createdAt")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.CREATED_BY_NAME}
                        columnTranslationName={props.t("admin.payouts.table.createdByName")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.UPDATED_AT}
                        columnTranslationName={props.t("admin.payouts.table.updatedAt")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.RESOLVED_BY_NAME}
                        columnTranslationName={props.t("admin.payouts.table.resolvedByName")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                    <th className="td-padding1__right1">{props.t("admin.payouts.table.transactionId")}</th>

                    <SortingColumn
                        sortingOrderHandler={props.sortingColumnsHandlers.setSortingOrder}
                        columnName={PayoutFields.PAYMENT_METHOD}
                        columnTranslationName={props.t("admin.payouts.table.paymentMethod")}
                        disableBaseColumnStyle={true}
                        currentActiveColumn={props.sortingColumnsHandlers.currentActiveColumn}
                        setCurrentActiveColumn={props.sortingColumnsHandlers.setCurrentActiveColumn}
                    />
                </tr>
            </thead>

            <tbody>
                {props.payouts?.map((payout) => (
                    <PayoutRow
                        payout={payout}
                        navigateToPayoutDetails={() => navigateToPayoutDetails(payout.id)}
                        reloadCache={() => props.sortingColumnsHandlers.setReloadCache(true)}
                        key={payout.id}
                        selectRowCallback={() => {
                            props.rowsHandlers.updateSelectedRows(payout);
                            props.rowsHandlers.selectAllRowsHandler(false);
                        }}
                        isSelectedRow={!!props.rowsHandlers.getSelectedRowById(payout.id)}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default withTranslation()(PayoutsTable);

import { BaseEntity } from "../domain/models/BaseEntity";
import { RecordStatus } from "../enums/record-status";
import { BaseModelImpl } from "./BaseModelImpl";

export class BaseEntityImpl<T> extends BaseModelImpl<T> implements BaseEntity {
    id?: string;
    parentId?: string;

    status?: RecordStatus;
    isDeleted?: boolean;

    createdAt?: Date;
    updatedAt?: Date;

    setIsDeleted(isDeleted: boolean) {
        this.isDeleted = isDeleted;
    }

    get isApproved() {
        return this.status === RecordStatus.APPROVED;
    }

    get isDeclined() {
        return this.status === RecordStatus.DECLINED;
    }

    get isProcessing() {
        return !this.isApproved && !this.isDeclined;
    }

    get isOrigin(): boolean {
        return !this.parentId && !!this.id;
    }
}

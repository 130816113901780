import { FC } from "react";

const DarkRemoveSVG: FC = () => {
    return (
        <svg width="21" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 5.25H2.33333V18.9C2.33333 19.457 2.57917 19.9911 3.01675 20.3849C3.45434 20.7787 4.04783 21 4.66667 21H16.3333C16.9522 21 17.5457 20.7787 17.9832 20.3849C18.4208 19.9911 18.6667 19.457 18.6667 18.9V5.25H3.5ZM8.16667 17.85H5.83333V8.4H8.16667V17.85ZM15.1667 17.85H12.8333V8.4H15.1667V17.85ZM15.8877 2.1L14 0H7L5.11233 2.1H0V4.2H21V2.1H15.8877Z"
                fill="#858585"
            />
        </svg>
    );
};

export default DarkRemoveSVG;

import { FC } from "react";

const TableHeaderSVG: FC = () => {
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 0L7 5L12 0L14 1L7 8L3.33786e-06 1L2 0Z" fill="#323232" />
        </svg>
    );
};

export default TableHeaderSVG;

import { FC } from "react";
import DropDown from "../DropDown";
import { Translate } from "../../../types/Translate";
import { SelectProps } from "../../../domain/models/SelectProps";
import { withTranslation } from "react-i18next";
import { Langs, getCurrentLanguageTranslation, getLanguageTranslation } from "../../../enums/langs-enum";

interface LanguageDropDownProps {
    t: Translate;
    language: Langs;
    setLanguage: (value: Langs) => void;
}

const LanguageDropDown: FC<LanguageDropDownProps> = (props) => {
    const languageHandler = (selectProps: SelectProps) => {
        if (!selectProps) {
            return;
        }
        props.setLanguage(selectProps.value as Langs);
    }

    return (
        <DropDown
            options={getLanguageTranslation(props.t)}
            value={getCurrentLanguageTranslation(props.t, props.language)}
            name="language"
            onChange={languageHandler}
            placeholder="select language"
            disableIsClearable={true}
        />
    );
};

export default withTranslation()(LanguageDropDown);

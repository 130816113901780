import { FC } from "react";

const DOL_SVG: FC = () => {
    return (
        <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.72553 27.64C4.97353 27.496 3.54553 27.232 2.44153 26.848C1.36153 26.44 0.557531 26.104 0.0295313 25.84L1.57753 21.7C2.41753 22.108 3.41353 22.48 4.56553 22.816C5.74153 23.152 6.97753 23.32 8.27353 23.32C9.73753 23.32 10.7335 23.128 11.2615 22.744C11.8135 22.336 12.0895 21.772 12.0895 21.052C12.0895 20.596 11.9815 20.212 11.7655 19.9C11.5495 19.564 11.2375 19.264 10.8295 19C10.4215 18.736 9.90553 18.484 9.28153 18.244C8.65753 17.98 7.93753 17.704 7.12153 17.416C6.32953 17.128 5.54953 16.816 4.78153 16.48C4.03753 16.12 3.35353 15.688 2.72953 15.184C2.12953 14.68 1.63753 14.068 1.25353 13.348C0.893531 12.604 0.713531 11.716 0.713531 10.684C0.713531 9.892 0.821531 9.136 1.03753 8.416C1.27753 7.672 1.63753 7 2.11753 6.4C2.59753 5.8 3.22153 5.296 3.98953 4.888C4.75753 4.456 5.66953 4.144 6.72553 3.952V0.0639992H11.2615V3.808C12.5095 3.928 13.5775 4.108 14.4655 4.348C15.3775 4.588 16.0975 4.828 16.6255 5.068L15.5095 9.388C14.7175 9.052 13.7935 8.764 12.7375 8.524C11.7055 8.284 10.6135 8.164 9.46153 8.164C8.30953 8.164 7.48153 8.356 6.97753 8.74C6.47353 9.124 6.22153 9.628 6.22153 10.252C6.22153 10.636 6.29353 10.972 6.43753 11.26C6.60553 11.524 6.85753 11.764 7.19353 11.98C7.52953 12.196 7.93753 12.412 8.41753 12.628C8.92153 12.82 9.52153 13.036 10.2175 13.276C11.2735 13.66 12.2455 14.08 13.1335 14.536C14.0455 14.992 14.8255 15.52 15.4735 16.12C16.1455 16.696 16.6615 17.38 17.0215 18.172C17.4055 18.964 17.5975 19.888 17.5975 20.944C17.5975 21.664 17.4895 22.384 17.2735 23.104C17.0575 23.8 16.6975 24.448 16.1935 25.048C15.6895 25.648 15.0295 26.164 14.2135 26.596C13.4215 27.028 12.4375 27.34 11.2615 27.532V31.924H6.72553V27.64Z"
                fill="#858585"
            />
        </svg>
    );
};

export default DOL_SVG;

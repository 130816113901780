import { ArticleDTO } from "../domain/models/dto/ArticleDTO";
import { Article } from "../domain/models/Article";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";
import { ArticlePurpose } from "../enums/article-purpose-enum";

export class ArticleModel extends BaseRelatedEntityMixinFactory<ArticleDTO>() implements Article {
    templeId: string;
    purposeType: ArticlePurpose;
    header: string;
    body: string;

    constructor(data?: ArticleDTO) {
        super();

        this.assignData(data);
    }
}

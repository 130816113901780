import { FC, MutableRefObject, useState } from "react";
import { withTranslation } from "react-i18next";
import { Temple } from "../../../../domain/models/Temple";
import { CreateTempleParams } from "../../../../enums/create-temple-params-enum";
import { SortingOrder } from "../../../../enums/sorting-order-enum";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import PaymentAccountModel from "../../../../models/PaymentAccount";
import PaymentAccount from "../../../../models/PaymentAccount";
import { useRedirectToPrevCreateTemplePage } from "../../../../tools/custom-hooks/useRedirect";
import { BaseEntityManipulationHooks } from "../../../../tools/custom-hooks/BaseEntityManipulationHooks";
import Footer from "../footer/Footer";
import PaymentDetailForm from "./PaymentDetailForm";
import PaymentDetails from "./PaymentDetails";
import { useFormikFormsContext } from "../../../../context/FormikFormsProvider";

interface MainPaymentDetailsComponentProps {
    t(key?: string): string;
    temple: Temple;
}

const MainPaymentDetailsComponent: FC<MainPaymentDetailsComponentProps> = (props) => {
    const handlers = BaseEntityManipulationHooks<PaymentAccount>(
        PaymentAccountModel,
        props.temple.getSortedPaymentAccounts(SortingOrder.ASC),
        props.temple.setPaymentAccounts.bind(props.temple),
        props.temple.removePaymentAccountByOrder.bind(props.temple),
    );

    useRedirectToPrevCreateTemplePage(props.temple, TempleRegisterPage.ORDERS, props.temple.isOrdersComplete);

    const [errorVisible, setErrorVisibility] = useState<string>(null);

    const [isAllowSetValues, setIsAllowSetValues] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);

    const [paymentAccountFormRef, setPaymentAccountFormRef] = useState<MutableRefObject<any>>(null);

    const { isFormsDataValid } = useFormikFormsContext();

    return (
        <div className="date__content">
            <main className="date__main">
                <h1 className="date__title">{props.t("templeRegister.payment.title")}</h1>

                <PaymentDetails
                    temple={props.temple}
                    paymentAccounts={handlers.entities}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isOrderChanged={isOrderChanged}
                    setIsOrderChanged={setIsOrderChanged}
                    setCurrentPaymentAccount={handlers.setCurrentEntity}
                    setIsAllowSetValues={setIsAllowSetValues}
                    updatePaymentAccounts={handlers.updateEntities}
                    paymentAccountFormRef={paymentAccountFormRef}
                    removePaymentAccount={handlers.removeOrderedEntity}
                />

                <PaymentDetailForm
                    paymentAccount={handlers.currentEntity}
                    temple={props.temple}
                    updatePaymentAccounts={handlers.updateEntities}
                    resetPaymentAccountForm={handlers.resetFormData}

                    setIsEditing={setIsEditing}

                    isAllowSetValues={isAllowSetValues}
                    setIsAllowSetValues={setIsAllowSetValues}

                    isSubmitting={isSubmitting}
                    setIsSubmitting={setIsSubmitting}

                    errorVisible={errorVisible}
                    setErrorVisibility={setErrorVisibility}
                    setPaymentAccountFormRef={setPaymentAccountFormRef}
                />
            </main>
            <Footer
                isButton={true}
                redirectTo={"/create-temple/bank-details".concat(`?${CreateTempleParams.ID}=${props.temple.originId}`)}
                currentPath={TempleRegisterPage.PAYMENT_DETAILS}
                temple={props.temple}
                setErrorVisibility={setErrorVisibility}
                isFormsDataValid={isFormsDataValid}
            />
        </div>
    );
};

export default withTranslation()(MainPaymentDetailsComponent);

import { FC } from "react";
import Popup from "reactjs-popup";
import { Swiper as ISwiper } from "swiper/types";
import { ElementInfoModalSlideProps } from "../../../domain/components/slider-modal-window/ElementInfoModalSlideProps";
import { MainElementInfoSlideProps } from "../../../domain/components/slider-modal-window/MainElementInfoSlideProps";
import ModalWindow from "../ModalWindow";
import PhotoSlider from "../photo-slider/PhotoSlider";
import ElementInfoModal from "./ElementInfoModal";

interface SliderModalWindowLayoutProps<T> {
    slidesPerView: number;
    spaceBetween: number;
    isArrowsDisable?: boolean;
    loop: boolean;
    allowTouchMove: boolean;
    dynamicBullets?: boolean;
    dynamicMainBullets?: number;

    id: string;
    wrapperClassName: string;
    fullScreenModalClassName?: string;
    title?: string;

    singleMainElementIndex?: number;
    singleMainElement?: T;
    elements: T[];

    isSingleMainElement?: boolean;
    MainElementInfoSlide: FC<MainElementInfoSlideProps<T>>;
    ElementInfoModalSlide: FC<ElementInfoModalSlideProps<T>>;

    isVisibleModal: boolean;
    modalSwiper: ISwiper;
    mainSlideIndex: number;
    setMainSlideIndex: (index: number) => void;
    setModalSwiper: (swiper: ISwiper) => void;
    openModal: (index: number) => void;
    closeModal: () => void;
}

function SliderModalWindowLayout<T>(props: SliderModalWindowLayoutProps<T>) {
    return (
        <div id={props.id} className={props.wrapperClassName}>
            <div className="container--min">
                {props.title && <h2 className="basic__title subtitle">{props.title}</h2>}
                <div className="basic__swiper-thumbs">
                    {!props.isSingleMainElement ? (
                        <PhotoSlider
                            slidesPerView={props.slidesPerView}
                            spaceBetween={props.spaceBetween}
                            isArrowsDisable={props.isArrowsDisable}
                            loop={props.loop}
                            allowTouchMove={props.allowTouchMove}
                            dynamicBullets={props.dynamicBullets}
                            dynamicMainBullets={props.dynamicMainBullets}
                            content={props.elements?.map((element, index) => (
                                <props.MainElementInfoSlide
                                    element={element}
                                    openModal={() => props.openModal(index)}
                                />
                            ))}
                        />
                    ) : (
                        <props.MainElementInfoSlide
                            element={props.singleMainElement}
                            openModal={() => props.openModal(props.singleMainElementIndex)}
                        />
                    )}
                </div>
            </div>

            {props.elements.length ? (
                <Popup open={props.isVisibleModal} modal overlayStyle={{ zIndex: 999999 }} onClose={props.closeModal}>
                    <ModalWindow
                        // contentClass="modal--main__content artifact__modal"
                        bodyClass={props.fullScreenModalClassName ? "modal--main__body__fullscreen" : null}
                        contentClass={
                            "modal--main__content artifact__modal" +
                            (props.fullScreenModalClassName ? props.fullScreenModalClassName : "")
                        }
                        content={
                            <ElementInfoModal
                                elements={props.elements}
                                modalSwiper={props.modalSwiper}
                                setModalSwiper={props.setModalSwiper}
                                mainSlideIndex={props.mainSlideIndex}
                                setMainSlideIndex={props.setMainSlideIndex}
                                ElementInfoModalSlide={props.ElementInfoModalSlide}
                            />
                        }
                        closeCallback={props.closeModal}
                    />
                </Popup>
            ) : (
                <></>
            )}
        </div>
    );
}

export default SliderModalWindowLayout;

import _ from 'lodash';
import { ScheduleMapper } from '../domain/mappers/schedule-mapper';
import { SimpleChangesRequestEntityMapper } from './SimpleChangesRequestEntityMapper';

export abstract class SimpleScheduleMapper<I, O> extends SimpleChangesRequestEntityMapper<I, O> implements ScheduleMapper<I, O> {
    protected scheduleFields: string[] = [];

    dateToDTO(data: any): O {
        return _.omitBy(this.scheduleFields.reduce((result, fieldName) => {
            result[fieldName] = data[fieldName] ? data[fieldName] + ":00" : null;
            return result;
        }, data), _.isNil) as O;
    }

    dateFromDTO(dto: any): I {
        return _.omitBy(this.scheduleFields.reduce((result, fieldName) => {
            result[fieldName] = dto[fieldName]?.slice(0, 5);
            return result;
        }, dto), _.isNil) as I;
    }
}

import { StatusCodes } from "http-status-codes";
import { useEffect, useState } from "react";
import { BaseEntity } from "../../../domain/models/BaseEntity";
import { errorServiceFactory } from "../../../services/ErrorServiceImpl";
import { EntityService } from "../../../types/services/EntityService";

interface IUseRemove<Model extends BaseEntity> {
    isErrorVisible: boolean;
    setRemovableModel: (value: Model) => void;
}

export function useRemove<EntityDTO, Model extends BaseEntity, Service extends EntityService<Model, EntityDTO>>(
    serviceFactory: () => Service,
    removeCallback: (id: string) => void
): IUseRemove<Model> {
    const service = serviceFactory();
    const errorService = errorServiceFactory();

    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
    const [removableModel, setRemovableModel] = useState<Model>(null);

    useEffect(() => {
        const remove = async () => {
            const result = await service.removeEntity(removableModel);

            if (result.status && !errorService.getStatusFromErrorResponseSubject(StatusCodes.CONFLICT)) {
                removeCallback(removableModel.id);
            } else {
               setIsErrorVisible(true);
            }
        };

        if (removableModel) {
            remove();
            setRemovableModel(null);
        }
    }, [removableModel, removeCallback, service, errorService]);

    return {isErrorVisible, setRemovableModel};
};

import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";

export const PrayerDetailsSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        maxPeople: {
            min: 1,
            max: 999999
        },
        price: {
            min: 1,
            max: 999999
        }
    }

    return {
        schema: Yup.object().shape({
            id: Yup.string(),
            maxPeople: Yup.number()
                .min(inputRestrictions.maxPeople.min, t("errors.commonErrors.min") + inputRestrictions.maxPeople.min)
                .max(inputRestrictions.maxPeople.max, t("errors.commonErrors.max") + inputRestrictions.maxPeople.max)
                .typeError(t("errors.commonErrors.isNumber"))
                .required(t("errors.commonErrors.required")),
            price: Yup.number()
                .min(inputRestrictions.price.min, t("errors.commonErrors.min") + inputRestrictions.price.min)
                .max(inputRestrictions.price.max, t("errors.commonErrors.max") + inputRestrictions.price.max)
                .typeError(t("errors.commonErrors.isNumber"))
                .required(t("errors.commonErrors.required")),
        }), inputRestrictions
    };
}

import * as _ from 'lodash';
import { SimpleMapper } from "./SimpleMapper";

export abstract class SimpleChangesRequestEntityMapper<I, O> extends SimpleMapper<I, O>{
    protected toChangesDTOFields: string[] = [];

    toChangesDTO(model: I): O {
        return _.pick(model, (this.toDTOFields || this.fromDTOFields).concat(this.toChangesDTOFields)) as any as O;
    }
}

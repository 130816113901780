import * as Yup from "yup";
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";


export const TempleSupportSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        templeSupportInfo: {
            min: 2,
            max: 1000
        },
        prayersInfo: {
            min: 30,
            max: 100
        },
        prayersEmail: {
            min: 2,
            max: 100
        },
        templeSupportEmail: {
            min: 2,
            max: 100
        }
    }

    return {
        schema: Yup.object().shape({
            templeSupportInfo: Yup.string()
                .min(inputRestrictions.templeSupportInfo.min, t("errors.commonErrors.min") + inputRestrictions.templeSupportInfo.min)
                .max(inputRestrictions.templeSupportInfo.max, t("errors.commonErrors.max") + inputRestrictions.templeSupportInfo.max)
                .nullable()
                .test(
                    "templeSupportInfo",
                    t("templeRegister.orders.requiredField"),
                    function (value) {
                        return !(
                            !!!value && !!!this.parent.useStandardTempleSupportInfo
                        );
                    }
                ),
            useStandardTempleSupportInfo: Yup.boolean()
                .nullable()
                .test(
                    "useStandardTempleSupportInfo",
                    t("templeRegister.orders.requiredField"),
                    function (value) {
                        return !(!!!value && !!!this.parent.templeSupportInfo);
                    }
                ),
            prayersInfo: Yup.string()
                .min(inputRestrictions.prayersInfo.min, t("errors.commonErrors.min") + inputRestrictions.prayersInfo.min)
                .max(inputRestrictions.prayersInfo.max, t("errors.commonErrors.max") + inputRestrictions.prayersInfo.max)
                .nullable()
                .test(
                    "prayersInfo",
                    t("templeRegister.orders.requiredField"),
                    function (value) {
                        return !(
                            !!!value && !!!this.parent.useStandardPrayersInfo
                        );
                    }
                ),
            useStandardPrayersInfo: Yup.boolean()
                .nullable()
                .test(
                    "useStandardPrayersInfo",
                    t("templeRegister.orders.requiredField"),
                    function (value) {
                        return !(!!!value && !!!this.parent.prayersInfo);
                    }
                ),
            templeSupportEmail: Yup.string()
                .min(inputRestrictions.templeSupportEmail.min, t("errors.commonErrors.min") + inputRestrictions.templeSupportEmail.min)
                .max(inputRestrictions.templeSupportEmail.max, t("errors.commonErrors.max") + inputRestrictions.templeSupportEmail.max)
                .nullable()
                .test(
                    "templeSupportEmail",
                    t("templeRegister.orders.requiredField"),
                    function (value) {
                        return !(
                            !!!value && !!!this.parent.useStandardTempleSupportEmail
                        );
                    }
                ),
            useStandardTempleSupportEmail: Yup.boolean()
                .nullable()
                .test(
                    "useStandardTempleSupportEmail",
                    t("templeRegister.orders.requiredField"),
                    function (value) {
                        return !(!!!value && !!!this.parent.templeSupportEmail);
                    }
                ),
            prayersEmail: Yup.string()
                .min(inputRestrictions.prayersEmail.min, t("errors.commonErrors.min") + inputRestrictions.prayersEmail.min)
                .max(inputRestrictions.prayersEmail.max, t("errors.commonErrors.max") + inputRestrictions.prayersEmail.max)
                .nullable()
                .test(
                    "prayersEmail",
                    t("templeRegister.orders.requiredField"),
                    function (value) {
                        return !(
                            !!!value && !!!this.parent.useStandardPrayersEmail
                        );
                    }
                ),
            useStandardPrayersEmail: Yup.boolean()
                .nullable()
                .test(
                    "useStandardPrayersEmail",
                    t("templeRegister.orders.requiredField"),
                    function (value) {
                        return !(!!!value && !!!this.parent.prayersEmail);
                    }
                )
        }), inputRestrictions
    };
};

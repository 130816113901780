import { FC } from "react";
import { withTranslation } from "react-i18next";
import { TransactionType } from "../../../enums/transaction-type-enum";
import { EntityChart } from "../../../types/charts/EntityChart";
import { Translate } from "../../../types/Translate";
import ChartFilter from "../../base/charts/ChartFilter";
import TransactionTypeDropDown from "../../base/custom-drop-down/TransactionTypeDropDown";
import FilterModalButton from "../../base/FilterModalButton";
import { ChartType } from "../../../enums/chart-type-enum";

interface ChartsHeaderProps {
    t: Translate;

    currentPeriodChart: EntityChart;
    prevPeriodChart: EntityChart;

    transactionType: TransactionType;
    transactionTypeHandler: (value: TransactionType) => void;

    isAttendance?: boolean;
    isPayments?: boolean;
    setChartType: (value: ChartType) => void;
}

const ChartsHeader: FC<ChartsHeaderProps> = (props) => {
    return (
        <>
            <h1 className="inner__title">{props.t("admin.charts.header")}</h1>

            <div className="inner__graphics-tabs-wrap">
                <div className="inner__graphics-tabs">
                    <div
                        // className={props.isAttendance ? "active" : ""}
                        // onClick={() => props.setChartType(ChartType.ATTENDANCE)}
                    >
                        {props.t("admin.charts.attendance")}
                    </div>
                    <div className={props.isPayments ? "active" : ""} onClick={() => props.setChartType(ChartType.PAYMENTS)}>
                        {props.t("admin.charts.payments")}
                    </div>
                </div>

                {props.isPayments ? (
                    <div className="inner__info-date charts__transaction-dropdown">
                        <TransactionTypeDropDown
                            transactionType={props.transactionType}
                            setTransactionType={props.transactionTypeHandler}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className="inner__tabs-content active">
                <div className="inner__info-right--top">
                    <button className="inner__info-reset" onClick={props.currentPeriodChart.resetChartsParams}>
                        {props.t("templeAdmin.payments.header.main.resetFilter")}
                    </button>

                    <FilterModalButton>
                        <ChartFilter
                            currentPeriodChart={props.currentPeriodChart}
                            prevPeriodChart={props.prevPeriodChart}
                        />
                    </FilterModalButton>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(ChartsHeader);

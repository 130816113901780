import { FC } from "react";
import InfoSlide from "../../InfoSlide";
import PhotoSlider from "../../photo-slider/PhotoSlider";

interface ChangeRequestEntityImageProps {
    files: string[];
}

const ChangeRequestEntityImage: FC<ChangeRequestEntityImageProps> = (props) => {
    return (
        <div className="establish__info-images">
            <PhotoSlider
                slidesPerView={4}
                spaceBetween={15}
                isArrowsDisable={true}
                allowTouchMove={true}
                loop={false}
                content={props.files.map((path) => (
                    <InfoSlide
                        filePath={path}
                        imageClass="establish__info-image establish__info-thumbnail"
                    />
                ))}
            />
        </div>
    );
}

export default ChangeRequestEntityImage;

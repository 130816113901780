import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { AdminStatistic } from "../../../domain/models/Admin";
import statisticServiceFactory from "../../../services/StatisticServiceImpl";
import Payments from "./Payments";
import Temples from "./Temples";

interface MainProps {
    t(key?: string): string;
}

const Main: FC<MainProps> = (props) => {
    const statisticService = statisticServiceFactory();
    const [adminStatistic, setAdminStatistic] = useState<AdminStatistic>();

    useEffect(() => {
        if (adminStatistic) {
            return;
        }

        (async function () {
            const statistic = await statisticService.getCachedSuperAdminStatistic();
            if (statistic) {
                setAdminStatistic(statistic);
            }
        })();
    }, [statisticService, adminStatistic]);

    return (
        <>
            <title>{props.t("admin.main.title")}</title>
            <section className="admin-main">
                <Temples templesInfo={adminStatistic?.templesInfo} temples={adminStatistic?.temples} />
                <Payments
                    paymentsInfo={adminStatistic?.paymentsInfo}
                    templePayments={adminStatistic?.templePayments}
                />
            </section>
        </>
    );
};

export default withTranslation()(Main);

import { FC, useEffect } from "react";
import PhotoSlider from "../photo-slider/PhotoSlider";
import { Swiper as ISwiper } from "swiper/types";
import ElementSwiperArrows from "./ElementSwiperArrows";
import { ElementInfoModalSlideProps } from "../../../domain/components/slider-modal-window/ElementInfoModalSlideProps";

interface ElementInfoModalProps<T> {
    elements: T[];
    ElementInfoModalSlide: FC<ElementInfoModalSlideProps<T>>;

    modalSwiper: ISwiper;
    mainSlideIndex: number;
    setModalSwiper(value: ISwiper): void;
    setMainSlideIndex(value: number): void;
}

const minimumPhotosForLoop = 2;

function ElementInfoModal<T>(props: ElementInfoModalProps<T>) {
    useEffect(() => {
        if (props.modalSwiper?.activeIndex !== props.mainSlideIndex) {
            props.modalSwiper?.slideTo(props.mainSlideIndex);
        }
    });

    return (
        <div className="basic__swiper-wrap">
            <div className="basic__swiper">
                <ElementSwiperArrows
                    swiper={props.modalSwiper}
                    loop={props.elements.length >= minimumPhotosForLoop}
                />
                <PhotoSlider
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={false}
                    isArrowsDisable={true}
                    allowTouchMove={true}
                    disableAutoplay={true}
                    content={props.elements?.map((element) => (
                        <props.ElementInfoModalSlide element={element} />
                    ))}
                    currentSwiper={props.modalSwiper}
                    setCurrentSwiper={props.setModalSwiper}
                    onSlideChange={(swiper) => {
                        if (props.mainSlideIndex === swiper.activeIndex) {
                            return;
                        }
                        props.setMainSlideIndex(swiper.activeIndex);
                    }}
                />
            </div>
        </div>
    );
};

export default ElementInfoModal;

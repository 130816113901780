import * as Yup from "yup"
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";
import { PayoutStatus } from "../../enums/payout-status-enum";
import { noSpaces } from "../../constants";

export const ResolvePayoutSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        comment: {
            min: 2,
            max: 200
        },
        transactionId: {
            min: 2,
            max: 200,
        },
    }

    return {
        schema: Yup.object().shape({
            status: Yup.number()
                .required(t("errors.commonErrors.required")),
            comment: Yup.string()
                .min(inputRestrictions.comment.min, t("errors.commonErrors.min") + inputRestrictions.comment.min)
                .max(inputRestrictions.comment.max, t("errors.commonErrors.max") + inputRestrictions.comment.max)
                .required(t("errors.commonErrors.required")),
            transactionId: Yup.string()
                .min(inputRestrictions.transactionId.min, t("errors.commonErrors.min") + inputRestrictions.transactionId.min)
                .max(inputRestrictions.transactionId.max, t("errors.commonErrors.max") + inputRestrictions.transactionId.max)
                .test(
                    "transactionId",
                    t("errors.commonErrors.required"),
                    function (value) {
                        return !(!value && (this.parent.status === PayoutStatus.EXECUTED));
                    }
                )
                .matches(noSpaces, t("errors.commonErrors.noSpaces")),
        }), inputRestrictions
    };
}

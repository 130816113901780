import { FC, useEffect, useState } from "react";
import TempleAbbot from "./abbot/TempleAbbot";
import MainArtifactInfo from "./basics/MainArtifactInfo";
import TempleInformation from "./information/TempleInformation";
import TempleMain from "./main/TempleMain";
import TempleSupport from "./TempleSupport";
import TempleSidebar from "./TempleSidebar";
import TempleOnline from "./TempleOnline";
import TempleOnline3D from "./TempleOnline3D";
import TempleGallery from "./gallery/TempleGallery";
import TempleContacts from "./TempleContacts";
import { useLocation } from "react-router-dom";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import { Temple as ITemple } from "../../../domain/models/Temple";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import { useWorshipSchedulesContext } from "../../../context/WorshipSchedulesProvider";
import { ArtifactDoubleModalProvider } from "../../../context/ArtifactDoubleModalProvider";

interface TemplePageProps {}

const TemplePage: FC<TemplePageProps> = () => {
    const templeService = templeServiceFactory();
    const religionService = religionsServiceFactory();

    const path = useLocation().pathname.split("/");
    const currentId = path[2];

    const [temple, setTemple] = useState<ITemple>();
    const [isReligionsExist, setIsReligionsExist] = useState<boolean>(false);

    const { isDatesChanged, setIsDatesChanged, getBeginWeekDateStr, getEndWeekDateStr } = useWorshipSchedulesContext();

    useEffect(() => {
        const uploadData = async () => {
            const [temple, religions] = await Promise.all([
                templeService.getTempleById(currentId, true, {
                    beginWorshipScheduleDate: getBeginWeekDateStr(),
                    endWorshipScheduleDate: getEndWeekDateStr(),
                }),
                religionService.getCachedReligions(),
            ]);

            setIsDatesChanged(false);
            setTemple(temple);
            setIsReligionsExist(Boolean(religions));
        };

        if ((!temple && !isReligionsExist) || isDatesChanged) {
            uploadData();
        }
    });

    return (
        <div className="container--huge">
            {temple && isReligionsExist && (
                <div className="wrapper--main__inner">
                    <TempleSidebar temple={temple} />
                    <div className="church__content">
                        <TempleMain temple={temple} />
                        <TempleAbbot temple={temple} />
                        <ArtifactDoubleModalProvider>
                        <MainArtifactInfo temple={temple} />
                        </ArtifactDoubleModalProvider>
                        <TempleInformation temple={temple} />
                        <TempleSupport temple={temple} />
                        {temple.worshipLink ? <TempleOnline temple={temple} /> : <></>}
                        {temple.tourLink ? <TempleOnline3D temple={temple} /> : <></>}
                        <TempleGallery temple={temple} />
                        <TempleContacts temple={temple} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TemplePage;

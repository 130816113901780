import { Mapper } from "../domain/mappers/mapper";
import { AdminStatistic } from "../domain/models/Admin";
import { AdminStatisticDTO } from "../domain/models/dto/AdminDTO";
import { TempleDTO, TemplePaymentsDTO, TemplesInfoDTO } from "../domain/models/dto/TempleDTO";
import { Temple, TemplePayments, TemplesInfo } from "../domain/models/Temple";
import templeMapperFactory from "./TempleMapper";
import AdminStatisticModel from "../models/AdminStatisticModel";
import { SimpleMapper } from "./SimpleMapper";
import templesInfoMapperFactory from "./TemplesInfoMapper";
import { PaymentsInfoDTO } from "../domain/models/dto/PaymentDTO";
import { PaymentsInfo } from "../domain/models/Payment";
import paymentsInfoMapperFactory from "./PaymentsInfoMapper";
import templePaymentsMapperFactory from "./TemplePaymentsMapper";

class AdminStatisticMapper extends SimpleMapper<AdminStatistic, AdminStatisticDTO>{
    protected entityConstructor = AdminStatisticModel;
    private templeMapper: Mapper<Temple, TempleDTO>;
    private templesInfoMapper: Mapper<TemplesInfo, TemplesInfoDTO>
    private paymentsInfoMapper: Mapper<PaymentsInfo, PaymentsInfoDTO>
    private templePaymentsMapper: Mapper<TemplePayments, TemplePaymentsDTO>

    constructor() {
        super();
        this.templesInfoMapper = templesInfoMapperFactory();
        this.paymentsInfoMapper = paymentsInfoMapperFactory();
        this.templeMapper = templeMapperFactory();
        this.templePaymentsMapper = templePaymentsMapperFactory();
    }

    toDTO(entity: AdminStatistic): AdminStatisticDTO {
        return {
            templesInfo: entity.templesInfo,
            paymentsInfo: entity.paymentsInfo,
            temples: entity.temples?.map((temple: Temple) => this.templeMapper.toDTO(temple)),
            templePayments: entity.templePayments,
        };
    }

    fromDTO(data: AdminStatisticDTO): AdminStatistic {
        const adminStatistic = super.fromDTO(data);
        adminStatistic.templesInfo = this.templesInfoMapper.fromDTO(data.templesInfo);
        adminStatistic.paymentsInfo = this.paymentsInfoMapper.fromDTO(data.paymentsInfo);
        adminStatistic.temples = data.temples?.map((temple: TempleDTO) => this.templeMapper.fromDTO(temple)) || [];
        adminStatistic.templePayments = data.templePayments?.map((payment: TemplePaymentsDTO) => this.templePaymentsMapper.fromDTO(payment)) || [];
        return adminStatistic;
    }
}

export default function AdminStatisticMapperFactory(): Mapper<AdminStatistic, AdminStatisticDTO> {
    return new AdminStatisticMapper()
}

import { Mapper } from "../domain/mappers/mapper";
import { EntityChangeDTO } from "../domain/models/dto/ChangeRequestEntityDTO";
import { EntityChange } from "../domain/models/EntityChange";
import EntityChangeModel from "../models/EntityChangeModel";
import { SimpleMapper } from "./SimpleMapper";

class EntityChangeMapper extends SimpleMapper<EntityChange, EntityChangeDTO> {
    protected entityConstructor = EntityChangeModel;

    protected fromDTOFields: string[] = [
        "fieldName",
        "originalValue",
        "currentValue",
        "type",
        "section",
    ];
}

export default function entityChangeMapperFactory(): Mapper<EntityChange, EntityChangeDTO> {
    return new EntityChangeMapper();
}

import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";
import { BankAccountDTO } from "../domain/models/dto/BankAccountDTO";
import BankAccountModel from "../models/BankAccount";
import BankAccount from "../models/BankAccount";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";

class BankAccountMapper extends SimpleChangesRequestEntityMapper<BankAccount, BankAccountDTO> {
    protected entityConstructor = BankAccountModel;

    protected toDTOFields: string[] = [
        'id',
        'order',
        'templeId',
        'fullName',
        'iban',
        'accountId',
        'currency',
        'isDeleted'
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        ...this.toChangesDTOFields,
        "status",
        "createdAt",
        "updatedAt",
    ];

    toDTO(model: BankAccount): BankAccountDTO {
        if (!model) {
            return null;
        }

        const result = super.toDTO(model);
        result.currency = +result.currency;

        return result;
    }
}

export default function bankAccountMapperFactory(): ChangesRequestEntityMapper<
    BankAccount,
    BankAccountDTO
> {
    return new BankAccountMapper();
}

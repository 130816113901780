import { useEffect, useRef, useState } from "react";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import { SortingColumnsHandlers } from "../../../types/SortingColumnsHandlers";

export const useSortingColumns = (defaultSortingOrder: SortingOrder = SortingOrder.ASC): SortingColumnsHandlers => {
    const [sortingOrder, setSortingOrder] = useState<SortingOrder>(defaultSortingOrder);
    const [currentActiveColumn, setCurrentActiveColumn] = useState<string>(null);

    const isFirstRender = useRef(true);
    const [reloadCache, setReloadCache] = useState<boolean>(false);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        setReloadCache(true);
    }, [sortingOrder, currentActiveColumn]);

    return {
        sortingOrder,
        currentActiveColumn,
        reloadCache,
        setSortingOrder,
        setCurrentActiveColumn,
        setReloadCache,
    };
};

import _ from "lodash";
import { Mapper } from "../domain/mappers/mapper";
import { ChangeRequestEntity } from "../domain/models/ChangeRequestEntity";
import { ChangeRequestEntityDTO } from "../domain/models/dto/ChangeRequestEntityDTO";
import ChangeRequestEntityModel from "../models/ChangeRequestEntityModel";
import entityChangeMapperFactory from "./EntityChangeMapper";
import { SimpleMapper } from "./SimpleMapper";

class ChangeRequestEntityMapper extends SimpleMapper<ChangeRequestEntity, ChangeRequestEntityDTO> {
    protected entityConstructor = ChangeRequestEntityModel;

    protected fromDTOFields: string[] = ["entity", "status", "id", "isDeleted"];

    fromDTO(dto: ChangeRequestEntityDTO) {
        const entityChangeMapper = entityChangeMapperFactory();

        const model = super.fromDTO(dto);

        model.changes = !_.isEmpty(dto.changes)
            ? dto.changes.map((detail) =>
                  entityChangeMapper.fromDTO(detail)
              )
            : [];

        return model;
    }
}

export default function changeRequestEntityMapperFactory(): Mapper<ChangeRequestEntity, ChangeRequestEntityDTO> {
    return new ChangeRequestEntityMapper();
}

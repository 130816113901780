import React, { createContext, useCallback, useContext, useEffect } from "react";
import { DEFAULT_SLIDE_INDEX } from "../constants";
import { CustomFile } from "../domain/models/CustomFile";
import { useFullScreenModal } from "../tools/custom-hooks/modal-hooks/useFullScreenModal";
import { useSliderModalWindow } from "../tools/custom-hooks/modal-hooks/useSliderModalWindow";
import { FullScreenModal } from "../types/FullScreenModal";
import { SliderModalWindow } from "../types/SliderModalWindow";

interface IArtifactDoubleModalContext extends SliderModalWindow, FullScreenModal {
    openFullScreenModal: (files: CustomFile[]) => void;
    closeFullScreenModal: () => void;
}

const ArtifactDoubleModalProvider = (props: any) => {
    const artifactModalHandlers = useSliderModalWindow();
    const fullScreenModalHandlers = useFullScreenModal();

    const openFullScreenModal = useCallback((files: CustomFile[]) => {
        fullScreenModalHandlers.openFullScreenModal(files);
        artifactModalHandlers.closeModal();
    }, [artifactModalHandlers, fullScreenModalHandlers]);

    const closeFullScreenModal = useCallback(() => {
        fullScreenModalHandlers.closeFullScreenModal();
        artifactModalHandlers.openModal(artifactModalHandlers.mainSlideIndex);
    }, [artifactModalHandlers, fullScreenModalHandlers]);

    useEffect(() => {
        fullScreenModalHandlers.setActiveImageIndex(DEFAULT_SLIDE_INDEX);
    }, [artifactModalHandlers.mainSlideIndex]);

    return (
        <ArtifactDoubleModalContext.Provider
            value={{
                ...artifactModalHandlers,
                ...fullScreenModalHandlers,
                openFullScreenModal,
                closeFullScreenModal,
            }}
            {...props}
        />
    );
};

const ArtifactDoubleModalContext = createContext<IArtifactDoubleModalContext>({
    // Artifact modal properties
    isVisibleModal: false,
    modalSwiper: null,
    mainSlideIndex: DEFAULT_SLIDE_INDEX,
    setMainSlideIndex: null,
    setModalSwiper: null,
    openModal: null,
    closeModal: null,

    // Full-screen size modal properties
    files: [],
    activeImageIndex: DEFAULT_SLIDE_INDEX,
    openedFullScreenModal: false,
    setActiveImageIndex: null,
    toPreviousPhoto: null,
    toNextPhoto: null,

    // Full-screen size modal handlers
    openFullScreenModal: null,
    closeFullScreenModal: null,
});

const useArtifactDoubleModalContext = () => useContext(ArtifactDoubleModalContext);

export { ArtifactDoubleModalProvider, useArtifactDoubleModalContext };

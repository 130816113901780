import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserParams } from "../../../../enums/user-params-enum";
import { Translate } from "../../../../types/Translate";
import UpdatesConfirmButtons from "./UpdatesConfirmButtons";

interface ChangeRequestEntityHeaderProps {
    t: Translate;

    requestId: string;
    isSuperAdminUser?: boolean;
    totalCount: number;
    error: string;

    setError: (error: string) => void;
    setIsDataUpdated: (value: boolean) => void;
}

const ChangeRequestEntityHeader: FC<ChangeRequestEntityHeaderProps> = (props) => {
    const navigate = useNavigate();
    const updatedAt = useSearchParams()[0].get(UserParams.UPDATED_AT);

    return (
        <div className="update__top">
            <div className="update__top-block" style={{ justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                    <button
                        onClick={() => navigate(`${props.isSuperAdminUser ? "/admin" : "/temple-admin"}/updates`)}
                        className="back-button"
                        style={{ marginTop: "6px" }}
                    >
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1.5 5L6 9" stroke="#F9F9F9" strokeLinecap="round" />
                        </svg>
                    </button>

                    <div className="update__title">
                        {props.t("updates.update.header")} <span>{updatedAt}</span>
                    </div>
                </div>

                {props.isSuperAdminUser && (props.totalCount > 0) && (
                    <UpdatesConfirmButtons
                        requestId={props.requestId}
                        setError={props.setError}
                        setIsDataUpdated={props.setIsDataUpdated}
                    />
                )}
            </div>

            <div className="update__amount">
                {props.t("updates.update.changesCount")}<span>{props.totalCount}</span>
            </div>

            <div className="update__amount">
                {props.error && props.t(props.error)}
            </div>
        </div>
    );
};

export default withTranslation()(ChangeRequestEntityHeader);

import _ from "lodash";
import { ChartService } from "../types/services/ChartService";
import { ChartsParams, ChartsParamsInfo } from "../types/charts/ChartsParams";
import { Translate } from "../types/Translate";

class ChartServiceImpl implements ChartService {
    analyzeChartsParams(chartsParams: ChartsParams): ChartsParamsInfo {
        return {
            onlyYear: chartsParams.year && _.isNil(chartsParams.month) && !chartsParams.day,
            onlyYearAndMonth: chartsParams.year && !_.isNil(chartsParams.month) && !chartsParams.day,
            yearAndMonthAndDay: !!(chartsParams.year && !_.isNil(chartsParams.month) && chartsParams.day),
        }
    }

    getChartLabel(t: Translate, chartsParams: ChartsParams): string {
        let result = "";

        if (!_.isNil(chartsParams.month)) {
            result += " " + t(`months.${chartsParams.month}`) + ",";
        }

        if (chartsParams.day) {
            result += " " + chartsParams.day.toString() + ",";
        }

        if (chartsParams.year) {
            result += " " + chartsParams.year.toString();
        }

        return result;
    }
}

export default function chartServiceFactory(): ChartService {
    return new ChartServiceImpl();
}

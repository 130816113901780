import { FC, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Article } from "../../../../../domain/models/Article";
import { Temple } from "../../../../../domain/models/Temple";
import { ArticleModel } from "../../../../../models/ArticleModel";
import { BaseEntityManipulationHooks } from "../../../../../tools/custom-hooks/BaseEntityManipulationHooks";
import ArticleForm from "./ArticleForm";
import Articles from "./Articles";

interface MainArticleComponentProps {
    t(key?: string): string;
    temple: Temple;
    isPilgrims: boolean;
}

const MainArticleComponent: FC<MainArticleComponentProps> = (props) => {
    const handlers = BaseEntityManipulationHooks<Article>(
        ArticleModel,
        props.isPilgrims ? props.temple.pilgrimsArticles : props.temple.articles,
        props.isPilgrims ? props.temple.setPilgrimsArticles.bind(props.temple) : props.temple.setArticles.bind(props.temple)
    );
    const pilgrimsFormRef = useRef<HTMLDivElement>(null);
    const otherInformationFormRef = useRef<HTMLDivElement>(null);

    return (
        <div className="establish date__section">
            <h2 className="date__subtitle ">
                {props.isPilgrims
                    ? props.t("templeRegister.visitorsInfo.subtitle2")
                    : props.t("templeRegister.visitorsInfo.other")}
            </h2>

            <Articles
                articles={handlers.entities}
                setCurrentArticle={handlers.setCurrentEntity}
                removeArticle={handlers.removeEntity}
                articleFormRef={props.isPilgrims ? pilgrimsFormRef : otherInformationFormRef}
            />

            <ArticleForm
                temple={props.temple}
                article={handlers.currentEntity}
                updateArticles={handlers.updateEntities}
                resetArticleForm={handlers.resetFormData}
                isPilgrims={props.isPilgrims}
                articleFormRef={props.isPilgrims ? pilgrimsFormRef : otherInformationFormRef}
            />
        </div>
    );
};

export default withTranslation()(MainArticleComponent);

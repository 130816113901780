import { TempleExtension } from "../domain/models/TempleExtension";
import { TempleExtensionDTO } from "../domain/models/dto/TempleExtensionDTO";
import { Langs } from "../enums/langs-enum";
import { BaseModelImpl } from "./BaseModelImpl";

export class TempleExtensionModel extends BaseModelImpl<TempleExtensionDTO> implements TempleExtension {
    id?: string;
    originTempleId: string;
    correspondenceEmail: string;
    correspondenceLanguage: Langs;
    isCorrespondenceEmailActive: boolean;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(data?: TempleExtensionDTO) {
        super();
        this.assignData(data);
    }

    setCorrespondenceEmail(value: string): void {
        this.correspondenceEmail = value;
    }

    setCorrespondenceLanguage(value: Langs): void {
        this.correspondenceLanguage = value;
    }

    setOriginTempleId(value: string): void {
        this.originTempleId = value;
    }
}

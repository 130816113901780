import _ from "lodash";
import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";
import { PrayerDTO } from "../domain/models/dto/PrayerDTO";
import { Prayer } from "../domain/models/Prayer";
import PrayerModel from "../models/PrayerModel";
import prayerPriceDetailMapperFactory from "./PrayerPriceDetailMapper";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";

class PrayerMapper extends SimpleChangesRequestEntityMapper<Prayer, PrayerDTO> {
    protected entityConstructor = PrayerModel;

    protected toDTOFields: string[] = [
        "id",
        "templeId",
        "name",
        "type",
        "order",
        "period",
        "info",
        "isDeleted"
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        ...this.toChangesDTOFields,
        "status",
        "createdAt",
        "updatedAt",
    ];

    fromDTO(dto: PrayerDTO) {
        const prayerPriceDetailMapper = prayerPriceDetailMapperFactory();

        const model = super.fromDTO(dto);

        model.prayerPriceDetails = !_.isEmpty(dto.prayerPriceDetails)
            ? dto.prayerPriceDetails.map((detail) =>
                  prayerPriceDetailMapper.fromDTO(detail)
              )
            : [];

        return model;
    }
}

export default function prayerMapperFactory(): ChangesRequestEntityMapper<Prayer, PrayerDTO> {
    return new PrayerMapper();
}

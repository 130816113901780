import _ from "lodash";
import { CustomFile } from "../domain/models/CustomFile";
import {
    BaseTempleRelatedModel,
    BaseResolveFiles,
} from "../domain/models/BaseTempleRelatedModel";
import fileServiceFactory from "../services/FileServiceImpl";
import { ReplacedModel } from "../types/ReplacedModel";
import CollectionTools from "../tools/CollectionTools";
import { SortingOrder } from "../enums/sorting-order-enum";
import fileMapperFactory from "../mappers/FileMapper";
import { errorServiceFactory } from "../services/ErrorServiceImpl";
import { StatusCodes } from "http-status-codes";
import { Constructor } from "../types/Constructor";
import { BaseEntity } from "../domain/models/BaseEntity";
import { IBaseModel } from "../domain/models/BaseModel";
import { BaseOrderedEntity } from "../domain/models/BaseOrderedEntity";
import { BaseOrderedEntityMixin } from "./BaseOrderedEntity";
import { BaseEntityImpl } from "./BaseEntity";

function BaseTempleRelatedModelMixin<T>(
    Base:
        | Constructor<BaseEntity & IBaseModel<T>>
        | Constructor<BaseOrderedEntity>
):
    | Constructor<BaseTempleRelatedModel & BaseOrderedEntity>
    | Constructor<BaseTempleRelatedModel> {
    return class
        extends (Base as Constructor<BaseEntity & IBaseModel<T>>)
        implements BaseTempleRelatedModel
    {
        files: CustomFile[] = [];

        isFilesUpdated: boolean;

        getFilesToRemove(files?: CustomFile[]): CustomFile[] {
            const data = files || this.files;
            return data.filter((image) => image.needToBeRemoved);
        }

        getFilesToCreateOrUpdate(files?: CustomFile[]): CustomFile[] {
            const data = files || this.files;
            return data.filter((image) => {
                return image.isValidChanges || image.isNewValid;
            });
        }

        setCreatedAt(createdAt: Date): void {
            this.createdAt = createdAt;
        }

        setUpdatedAt(updatedAt: Date): void {
            this.updatedAt = updatedAt;
        }

        setFiles(files: CustomFile[]): void {
            this.files = files;
        }

        setIsFilesUpdated(value: boolean): void {
            this.isFilesUpdated = value;
        }

        removeFiles(files: CustomFile[], filesToResolve?: CustomFile[]) {
            if (_.isEmpty(files)) {
                return;
            }
            files.forEach((file) => {
                this.removeFile(file, filesToResolve);
            });
        }

        removeFile(file: CustomFile, filesToResolve?: CustomFile[]) {
            if (!file) {
                return;
            }

            const filesArray = filesToResolve || this.files;

            const index = filesArray.indexOf(file);
            if (index >= 0) {
                filesArray.splice(index, 1);
            }
        }

        removeAbandonedFiles(abandonedFilesToRemove?: CustomFile[]) {
            const files = abandonedFilesToRemove || this.files;
            this.removeFiles(
                files.filter((file) => file.isAbandoned),
                files
            );
        }

        async resolveFiles(
            newEntityId: string,
            filesToResolve?: CustomFile[]
        ): Promise<BaseResolveFiles> {
            const fileService = fileServiceFactory();
            const fileMapper = fileMapperFactory();
            const errorService = errorServiceFactory();

            const files = filesToResolve || this.files;
            let isErrorVisible = false;

            if (newEntityId) {
                files.forEach((file) => {
                    file.setOwnerId(newEntityId);
                });
            }

            const filesToRemove = this.getFilesToRemove(files);
            const filesToCreateOrUpdate = this.getFilesToCreateOrUpdate(files);

            if (!_.isEmpty(filesToRemove)) {
                try {
                    await Promise.all(
                        filesToRemove.map((image) =>
                            fileService.removeEntity(image)
                        )
                    );
                    this.removeFiles(filesToRemove, files);
                } catch (err) {
                    isErrorVisible = true;
                }
            }

            if (
                errorService.getStatusFromErrorResponseSubject(
                    StatusCodes.CONFLICT
                )
            ) {
                return {
                    isErrorVisible: true,
                    images: [],
                };
            }

            this.removeAbandonedFiles(files);

            let images: CustomFile[] = [];
            if (!_.isEmpty(filesToCreateOrUpdate)) {
                try {
                    images = await Promise.all(
                        filesToCreateOrUpdate.map((file) => {
                            return fileService.createRequestOrUpdateCurrentEntity(
                                fileMapper.toDTO(file)
                            );
                        })
                    );
                } catch (err) {
                    isErrorVisible = true;
                }
            }

            if (_.isEmpty(images) && !_.isEmpty(filesToCreateOrUpdate)) {
                isErrorVisible = true;
            }

            return { isErrorVisible, images };
        }

        getUpdatedFilesList(
            newFilesList: CustomFile[],
            oldFilesList?: CustomFile[]
        ): CustomFile[] {
            return CollectionTools.updateEntityList<CustomFile>(
                newFilesList,
                oldFilesList || this.files
            );
        }

        moveFileForward(
            order: number,
            files?: CustomFile[]
        ): ReplacedModel<CustomFile> {
            return CollectionTools.upEntity<CustomFile>(
                order,
                this.getVisibleFiles(files)
            );
        }

        moveFileBackward(
            order: number,
            files?: CustomFile[]
        ): ReplacedModel<CustomFile> {
            return CollectionTools.downEntity<CustomFile>(
                order,
                this.getVisibleFiles(files)
            );
        }

        removeFileByOrder(order: number, files?: CustomFile[]): CustomFile[] {
            return CollectionTools.removeEntity<CustomFile>(
                order,
                files || this.files,
                true
            );
        }

        getNextFileOrder(files?: CustomFile[]): number {
            return (files ? this.getMaxOrder(files) : this.getMaxOrder()) + 1;
        }

        getSortedFiles(
            sortingOrder: SortingOrder,
            files?: CustomFile[]
        ): CustomFile[] {
            return CollectionTools.sortEntities<CustomFile>(
                files || this.files,
                sortingOrder
            );
        }

        getMaxOrder(files?: CustomFile[]): number {
            const filesArray: CustomFile[] = files || this.files;
            return filesArray.reduce((acc: number, file: CustomFile) => {
                if (!file.isDeleted && acc < file.order) {
                    acc = file.order;
                }

                return acc;
            }, 0);
        }

        resetFiles() {
            this.files = this.getResetFiles();
        }

        getResetFiles(files?: CustomFile[]) {
            const filesArray: CustomFile[] = files || this.files;
            return filesArray
                .filter((file) => {
                    return !file.isNewValid;
                })
                .map((file) => {
                    file.setIsDeleted(false);
                    file.setIsChanged(false);
                    file.resetOrder();
                    return file;
                });
        }

        getVisibleFiles(files?: CustomFile[]): CustomFile[] {
            return (files || this.files).filter((file) => !file.isDeleted);
        }
    };
}

export function BaseRelatedOrderedEntityMixinFactory<T>(): Constructor<
    BaseTempleRelatedModel & BaseOrderedEntity
> {
    return BaseTempleRelatedModelMixin<T>(
        BaseOrderedEntityMixin(BaseEntityImpl)
    ) as Constructor<BaseTempleRelatedModel & BaseOrderedEntity>;
}

export function BaseRelatedEntityMixinFactory<T>(): Constructor<BaseTempleRelatedModel> {
    return BaseTempleRelatedModelMixin<T>(
        BaseOrderedEntityMixin(BaseEntityImpl)
    ) as Constructor<BaseTempleRelatedModel>;
}

import { FC, useCallback, useEffect, useState } from "react";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DropDown from "../../base/DropDown";
import { getMethodsTranslation } from "../../../enums/payment-method-enum";
import { SelectProps } from "../../../domain/models/SelectProps";
import { UseTableRowsActionsHandlers } from "../../../tools/custom-hooks/table-hooks/useTableRowsActions";
import PaymentMethodsModal from "./PaymentMethodsModal";
import { CSVLink } from "react-csv";
import CSVFileServiceFactory from "../../../services/CSVFileServiceImpl";
import { Payout, PayoutCSVFields } from "../../../domain/models/Payout";
import DownloadCSVButton from "../../base/svg/DownloadCSVButton";
import CSVImportInput from "../../base/CSVImportInput";
import { payoutServiceFactory } from "../../../services/PayoutServiceImpl";
import { isEveryElementTruthy } from "../../../tools/Tools";
import RequestsCompletedModal from "./RequestsCompletedModal";
import { NameFormattingServiceImpl } from "../../../services/NameFormattingService";
import { useAuth } from "../../../context/AuthProvider";
import { errorServiceFactory } from "../../../services/ErrorServiceImpl";
import { StatusCodes } from "http-status-codes";
import { payoutsCSVPrefix } from "../../../constants";
import { NonValidPayoutDTO } from "../../../domain/models/dto/PayoutDTO";
import NonValidPayoutsModal from "./non-valid-payouts-modal/NonValidPayoutsModal";

interface PayoutsHeaderProps {
    t: Translate;

    isPayouts: boolean;
    isCredits: boolean;
    totalRows: number;

    payoutsToResolve: Payout[];
    paymentMethod: SelectProps;
    paymentMethodHandleChange: (value: SelectProps) => void;

    rowsHandlers: UseTableRowsActionsHandlers<any, any>;
}

const CSVFileService = CSVFileServiceFactory();
const payoutService = payoutServiceFactory();

const PayoutsHeader: FC<PayoutsHeaderProps> = (props) => {
    const navigate = useNavigate();
    const [isVisibilityPaymentMethodsModal, setVisibilityPaymentMethodsModal] = useState(false);
    const [importedCSVFile, setImportedCSVFile] = useState<File>(null);
    const [csvForced, setCSVForced] = useState(false);
    const [isRequestsCompletedModalActive, setRequestsCompletedModalActive] = useState(false);

    const [successRequests, setSuccessRequests] = useState<number>(0);
    const [nonValidPayouts, setNonValidPayouts] = useState<NonValidPayoutDTO[]>([]);
    const [error, setError] = useState("");

    const isCSVFileReadyToSend = importedCSVFile && props.isPayouts;
    const isCreditsReadyToSend = !!props.rowsHandlers.getSelectedRowsCount() && props.isCredits;
    const isVisibilitySendDataButton = isCreditsReadyToSend || isCSVFileReadyToSend;

    const { user } = useAuth();

    const sendDataCallback = useCallback(async () => {
        setError("");
        setSuccessRequests(0);
        setNonValidPayouts([]);
        setRequestsCompletedModalActive(true);

        const errorService = errorServiceFactory();

        const result = await (isCSVFileReadyToSend
            ? payoutService.resolvePayoutsByCSV(importedCSVFile, csvForced)
            : props.rowsHandlers.sendData());

        const isCreditsResult = result instanceof Array;

        if (csvForced) {
            setCSVForced(false);
        }

        // Non-valid payouts existing (200)
        if (!isCreditsResult && !result?.status && result?.nonValid?.length) {
            setNonValidPayouts(result.nonValid);
            setError(props.t(`errors.payouts.${StatusCodes.BAD_REQUEST}`));
            return errorService.resetErrorResponseSubject();
        }

        // Bad request error (400)
        if (errorService.getStatusFromErrorResponseSubject(StatusCodes.BAD_REQUEST)) {
            setError(props.t(`errors.payouts.${StatusCodes.BAD_REQUEST}`));
            return errorService.resetErrorResponseSubject();
        }

        // Not found error (404)
        if (errorService.getStatusFromErrorResponseSubject(StatusCodes.NOT_FOUND)) {
            setError(props.t(`errors.payouts.${StatusCodes.NOT_FOUND}`));
            return errorService.resetErrorResponseSubject();
        }

        // Common error (any)
        if (!result || !isEveryElementTruthy(result)) {
            return setError(props.t("errors.common"));
        }

        // Success request (200)
        if (isCSVFileReadyToSend && !isCreditsResult) {
            setSuccessRequests(result.success);
            props.rowsHandlers.setReloadCache(true);
            return setImportedCSVFile(null);
        }
    }, [importedCSVFile, csvForced, isCSVFileReadyToSend, props]);

    const sendDataHandler = useCallback(() => {
        if (props.rowsHandlers.isSelectedRowsError()) {
            return setVisibilityPaymentMethodsModal(true);
        }

        sendDataCallback();
    }, [props.rowsHandlers, sendDataCallback]);

    const paymentsMethodsModalCloseCallback = useCallback(() => {
        setVisibilityPaymentMethodsModal(false);
    }, []);

    const acceptPaymentMethodsCallback = async () => {
        await sendDataCallback();
        paymentsMethodsModalCloseCallback();
    };

    useEffect(() => {
        if (importedCSVFile) {
            sendDataHandler();
        }
    }, [importedCSVFile, csvForced]);

    const getSuccessModalText = () => {
        return props.isCredits
            ? props.t("admin.payouts.successPayoutsCreation")
            : `${props.t("admin.payouts.successPayoutsResolving")}: ${successRequests}`;
    };

    return (
        <div className="date">
            <h1 className="inner__title">{props.t("admin.payouts.label")}</h1>
            <div className="inner__info-number">
                {props.isPayouts
                    ? props.t("admin.payouts.totalPayouts")
                    : props.t("admin.payouts.credits.totalCredits")}
                <span>{props.totalRows}</span>
            </div>
            <div className="inner__graphics-tabs-wrap">
                <div className="inner__graphics-tabs">
                    <div className={props.isCredits ? "" : "active"} onClick={() => navigate("/admin/payouts")}>
                        {props.t("admin.payouts.label")}
                    </div>
                    <div className={props.isPayouts ? "" : "active"} onClick={() => navigate("/admin/payouts/credits")}>
                        {props.t("admin.payouts.credits.label")}
                    </div>
                </div>
                <div className="inner__info-date charts__transaction-dropdown">
                    <DropDown
                        name="defaultPaymentMethod"
                        value={props.paymentMethod}
                        options={getMethodsTranslation(props.t)}
                        onChange={props.paymentMethodHandleChange}
                        placeholder={props.t("fields.paymentMethod")}
                        disableIsClearable={true}
                    />
                </div>
                {props.isPayouts ? (
                    <>
                        <CSVImportInput
                            title={props.t("admin.payouts.uploadCSV")}
                            setFileCallback={setImportedCSVFile}
                        />
                        <CSVLink
                            data={CSVFileService.convertToCSVObject<Payout>(props.payoutsToResolve, PayoutCSVFields)}
                            onClick={(e: any) => (!props.payoutsToResolve.length ? e.preventDefault() : null)}
                            filename={NameFormattingServiceImpl.getFileName(user.fullName, payoutsCSVPrefix)}
                        >
                            <DownloadCSVButton
                                title={props.t("admin.payouts.downloadCSV")}
                                disable={!!!props.payoutsToResolve.length}
                            />
                        </CSVLink>
                    </>
                ) : (
                    <></>
                )}

                {props.isCredits ? (
                    <button
                        onClick={sendDataHandler}
                        className={
                            "payout-information-button payout-information-button__finish " +
                            (!isVisibilitySendDataButton ? "payout-information-button__disabled" : "")
                        }
                        disabled={!isVisibilitySendDataButton}
                    >
                        {props.t("admin.payouts.credits.createPayoutsButton")}
                    </button>
                ) : (
                    <></>
                )}
            </div>

            {nonValidPayouts.length && !successRequests && props.isPayouts ? (
                <NonValidPayoutsModal
                    isModalActive={isRequestsCompletedModalActive}
                    closeModalCallback={() => setRequestsCompletedModalActive(false)}
                    forcedCallback={() => setCSVForced(true)}
                    payouts={nonValidPayouts}
                />
            ) : (
                <RequestsCompletedModal
                    isModalActive={isRequestsCompletedModalActive}
                    closeModalCallback={() => setRequestsCompletedModalActive(false)}
                    error={error}
                    success={getSuccessModalText()}
                />
            )}
            <PaymentMethodsModal
                isModalActive={isVisibilityPaymentMethodsModal}
                closeModalCallback={paymentsMethodsModalCloseCallback}
                acceptPaymentMethodsCallback={acceptPaymentMethodsCallback}
            />
        </div>
    );
};

export default withTranslation()(PayoutsHeader);

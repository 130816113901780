export enum SortingField {
    ORDER = "order",
    MAX_PEOPLE = "maxPeople",
    BEGIN = "begin",
    PERIOD = "period",
    TYPE = "type",
    ID = "id",
    CREATED_AT = "createdAt",
    ENTITY = "entity"
};

import * as Yup from "yup"
import { SchemaFactoryProps } from "../../types/SchemaFactory";

const timeValidate = Yup.string().matches(/^([0-9]{2})\:([0-9]{2})$/, " ").nullable();

export const WeekScheduleSchemaFactory = (props: SchemaFactoryProps) => {
    const t = props.t;

    return Yup.object().shape({
        mondayBegin: timeValidate.test("mondayBegin", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.mondayEnd) || (!value && this.parent.mondayEnd)) && !value);
        }).test("mondayBegin", t("errors.commonErrors.beginLowerThanEnd"), function(value) {
            return !(value > this.parent.mondayEnd);
        }),
        mondayEnd: timeValidate.test("mondayEnd", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.mondayBegin) || (!value && this.parent.mondayBegin)) && !value);
        }),
        tuesdayBegin: timeValidate.test("tuesdayBegin", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.tuesdayEnd) || (!value && this.parent.tuesdayEnd)) && !value);
        }).test("tuesdayBegin", t("errors.commonErrors.beginLowerThanEnd"), function(value) {
            return !(value > this.parent.tuesdayEnd);
        }),
        tuesdayEnd: timeValidate.test("tuesdayEnd", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.tuesdayBegin) || (!value && this.parent.tuesdayBegin)) && !value);
        }),
        wednesdayBegin: timeValidate.test("wednesdayBegin", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.wednesdayEnd) || (!value && this.parent.wednesdayEnd)) && !value);
        }).test("wednesdayBegin", t("errors.commonErrors.beginLowerThanEnd"), function(value) {
            return !(value > this.parent.wednesdayEnd);
        }),
        wednesdayEnd: timeValidate.test("wednesdayEnd", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.wednesdayBegin) || (!value && this.parent.wednesdayBegin)) && !value);
        }),
        thursdayBegin: timeValidate.test("thursdayBegin", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.thursdayEnd) || (!value && this.parent.thursdayEnd)) && !value);
        }).test("thursdayBegin", t("errors.commonErrors.beginLowerThanEnd"), function(value) {
            return !(value > this.parent.thursdayEnd);
        }),
        thursdayEnd: timeValidate.test("thursdayEnd", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.thursdayBegin) || (!value && this.parent.thursdayBegin)) && !value);
        }),
        fridayBegin: timeValidate.test("fridayBegin", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.fridayEnd) || (!value && this.parent.fridayEnd)) && !value);
        }).test("fridayBegin", t("errors.commonErrors.beginLowerThanEnd"), function(value) {
            return !(value > this.parent.fridayEnd);
        }),
        fridayEnd: timeValidate.test("fridayEnd", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.fridayBegin) || (!value && this.parent.fridayBegin)) && !value);
        }),
        saturdayBegin: timeValidate.test("saturdayBegin", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.saturdayEnd) || (!value && this.parent.saturdayEnd)) && !value);
        }).test("saturdayBegin", t("errors.commonErrors.beginLowerThanEnd"), function(value) {
            return !(value > this.parent.saturdayEnd);
        }),
        saturdayEnd: timeValidate.test("saturdayEnd", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.saturdayBegin) || (!value && this.parent.saturdayBegin)) && !value);
        }),
        sundayBegin: timeValidate.test("sundayBegin", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.sundayEnd) || (!value && this.parent.sundayEnd)) && !value);
        }).test("sundayBegin", t("errors.commonErrors.beginLowerThanEnd"), function(value) {
            return !(value > this.parent.sundayEnd);
        }),
        sundayEnd: timeValidate.test("sundayEnd", t("errors.commonErrors.beginWithEnd"), function(value) {
            return !(((value && !this.parent.sundayBegin) || (!value && this.parent.sundayBegin)) && !value);
        })
    });
}

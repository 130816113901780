import _ from "lodash";
import { ChangesRequestEntityMapper } from "../domain/mappers/changes-request-entity-mapper";
import { Article } from "../domain/models/Article";
import { ArticleDTO } from "../domain/models/dto/ArticleDTO";
import { ArticleModel } from "../models/ArticleModel";
import fileMapperFactory from "./FileMapper";
import { SimpleChangesRequestEntityMapper } from "./SimpleChangesRequestEntityMapper";

class ArticleMapper extends SimpleChangesRequestEntityMapper<Article, ArticleDTO> {
    protected entityConstructor = ArticleModel;

    protected toDTOFields: string[] = [
        "id",
        "templeId",
        "purposeType",
        "header",
        "body",
        "isDeleted"
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        ...this.toChangesDTOFields,
        'status',
        'createdAt',
        'updatedAt',
    ];

    fromDTO(model: ArticleDTO): Article {
        const fileMapper = fileMapperFactory();

        if (!model) {
            return new ArticleModel();
        }

        const article = super.fromDTO(model);

        article.files = !_.isEmpty(model.files) ? model.files.map(file => fileMapper.fromDTO(file)) : [];

        return article;
    }
}


export default function articleMapperFactory(): ChangesRequestEntityMapper<Article, ArticleDTO> {
    return new ArticleMapper()
}

import { TemplePayments } from "../domain/models/Temple";
import { TemplePaymentsDTO } from "../domain/models/dto/TempleDTO";
import { fixedToFloatAmountStr } from "../tools/Tools";
import { BaseModelImpl } from "./BaseModelImpl";

class TemplePaymentsModel extends BaseModelImpl<TemplePaymentsDTO> implements TemplePayments {
    id: string;
    name: string;
    totalPaymentsAmount: number;

    totalPaymentsAmountFloatStr: string;

    constructor(data: TemplePaymentsDTO) {
        super();
        this.assignData(data);
        this.totalPaymentsAmountFloatStr = fixedToFloatAmountStr(this.totalPaymentsAmount);
    }
}

export default TemplePaymentsModel

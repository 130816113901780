import { useCallback, useState } from "react";
import { DEFAULT_SLIDE_INDEX } from "../../../constants";
import { CustomFile } from "../../../domain/models/CustomFile";
import { FullScreenModal } from "../../../types/FullScreenModal";
import { isNumber } from "lodash";

interface IUseFullScreenModal extends FullScreenModal {
    openFullScreenModal(files: CustomFile[], imageIndex?: number): void;
    closeFullScreenModal(): void;
}

export const useFullScreenModal = (): IUseFullScreenModal => {
    const [files, setFiles] = useState<CustomFile[]>([]);
    const [activeImageIndex, setActiveImageIndex] = useState<number>(DEFAULT_SLIDE_INDEX);
    const [openedFullScreenModal, setOpenedFullScreenModal] = useState<boolean>(false);

    const toPreviousPhoto = useCallback(() => {
        if (!openedFullScreenModal) {
            return;
        }

        setActiveImageIndex(activeImageIndex > 0 ? activeImageIndex - 1 : activeImageIndex);
    }, [openedFullScreenModal, activeImageIndex]);

    const toNextPhoto = useCallback(() => {
        if (!openedFullScreenModal) {
            return;
        }

        setActiveImageIndex(activeImageIndex < files.length - 1 ? activeImageIndex + 1 : activeImageIndex);
    }, [openedFullScreenModal, activeImageIndex, files]);

    const openFullScreenModal = useCallback((newFiles: CustomFile[], imageIndex?: number) => {
        if (!newFiles?.length) {
            return;
        }

        isNumber(imageIndex) && setActiveImageIndex(imageIndex);
        setFiles(newFiles);
        setOpenedFullScreenModal(true);
    }, []);

    const closeFullScreenModal = useCallback(() => {
        setOpenedFullScreenModal(false);
        setFiles([]);
    }, []);

    return {
        files,
        activeImageIndex,
        openedFullScreenModal,
        setActiveImageIndex,
        toPreviousPhoto,
        toNextPhoto,
        openFullScreenModal,
        closeFullScreenModal,
    };
};

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
import { withTranslation } from "react-i18next";
import { paypalClientId } from "../../../Config";
import { BasePayPalDTO } from "../../../domain/models/dto/PayPalPaymentDTO";
import { LanguageService } from "../../../domain/services/LanguageService";
import PayPalService from "../../../domain/services/PayPalService";
import { Langs } from "../../../enums/langs-enum";
import languageServiceFactory from "../../../services/LanguageServiceImpl";
import { payPalServiceFactory } from "../../../services/PayPalServiceImpl";

const CURRENCY = "USD";
export interface PayPalCheckoutButtonButtonProps {
    value: number;
    data: any;

    isValidData: boolean;

    setCommonPaymentError: () => void;
    paymentHandler: (paymentResult: any) => void;

    executeServiceMethod: (paypalDTO: BasePayPalDTO, data: any) => Promise<any>;
}

class PayPalCheckoutButton extends React.Component<PayPalCheckoutButtonButtonProps, any> {
    service: PayPalService;
    langService: LanguageService;

    constructor(props: PayPalCheckoutButtonButtonProps) {
        super(props);
        this.service = payPalServiceFactory();
        this.langService = languageServiceFactory();
    }

    render = () => {
        return (
            <React.Fragment>
                <PayPalScriptProvider
                    options={{
                        "client-id": paypalClientId,
                        "disable-funding": "paylater,credit",
                        commit: true,
                        currency: CURRENCY,
                        locale: this.langService.currentPaypalLang
                    }}
                >
                    <PayPalButtons
                        style={{
                            color: "blue",
                            shape: "pill",
                        }}
                        disabled={!this.props.isValidData}
                        createOrder={async (_data, _actions) => {
                            const response = await this.service.createPayment(this.props.value, CURRENCY);
                            if (!response?.links.length) {
                                this.props.setCommonPaymentError();
                                return;
                            }

                            var token;
                            for (let link of response.links) {
                                if (link.rel === "approval_url") {
                                    token = link.href.match(/EC-\w+/)[0];
                                }
                            }
                            return token;
                        }}
                        onApprove={async (data, _actions) => {
                            const result = await this.props.executeServiceMethod({
                                language: this.langService.currentLang as Langs,
                                paypalPaymentId: data.paymentID,
                                paypalPayerId: data.payerID,
                            }, this.props.data);
                            this.props.paymentHandler(result);
                        }}
                    ></PayPalButtons>
                </PayPalScriptProvider>
            </React.Fragment>
        );
    };
}

export default withTranslation()(PayPalCheckoutButton);

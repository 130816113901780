import { FC } from "react";

interface CustomCheckboxProps {
    id?: string;
    name?: string;
    className?: string;
    wrapperClassName?: string;

    value?: boolean;
    defaultValue: boolean;
    onChange?: () => void;

    errors?: string;
    labelContent?: any;
    additionalLabelClassName?: string;
}

const CustomCheckbox: FC<CustomCheckboxProps> = (props) => {
    return (
        <div className={props.wrapperClassName || "order__checkbox"}>
            <input
                id={props.id || ""}
                defaultChecked={!!props.defaultValue}
                checked={props.value}
                type="checkbox"
                name={props.name || ""}
                className={props.className || "checkbox__input"}
                onChange={() => {
                    props.onChange && props.onChange();
                }}
            />
            <label
                htmlFor={props.id || ""}
                className={"checkbox__label " + (props.additionalLabelClassName ? props.additionalLabelClassName : "")}
            >
                {props.labelContent}
            </label>
            {props.errors}
        </div>
    );
};

export default CustomCheckbox;

import { PrayerDTO } from "../domain/models/dto/PrayerDTO";
import { Prayer } from "../domain/models/Prayer";
import { PrayerPriceDetail } from "../domain/models/PrayerPriceDetail";
import { Prayer as PrayerEnum } from "../enums/prayer-enum";
import { PrayerPeriod } from "../enums/prayer-period-enum";
import { SortingField } from "../enums/sorting-field-enum";
import { SortingOrder } from "../enums/sorting-order-enum";
import CollectionTools from "../tools/CollectionTools";
import { BaseRelatedOrderedEntityMixinFactory } from "./BaseTempleRelatedModel";

class PrayerModel extends BaseRelatedOrderedEntityMixinFactory<PrayerDTO>() implements Prayer {
    templeId: string;
    order: number;
    name: string;
    type: PrayerEnum;
    period: PrayerPeriod;
    info: string;
    prayerPriceDetails?: PrayerPriceDetail[];

    constructor(data?: PrayerDTO) {
        super();
        Object.assign(this, data || {});
    }

    assignRelatedEntities(prayer: Prayer): void {
        this.prayerPriceDetails = prayer.prayerPriceDetails || [];
    }

    setPrayerPriceDetails(details: PrayerPriceDetail[]): void {
        this.prayerPriceDetails = details;
    }

    getSortedPrayerPriceDetails(sortingOrder: SortingOrder, details?: PrayerPriceDetail[]): PrayerPriceDetail[] {
        return CollectionTools.sortEntities<PrayerPriceDetail>(details || this.prayerPriceDetails, sortingOrder, SortingField.MAX_PEOPLE);
    }

    getMaxPriceDetailByPeopleAmount(peopleAmount: number): number {
        return this.getSortedPrayerPriceDetails(SortingOrder.ASC).find(
            priceDetail => priceDetail.maxPeople >= peopleAmount
        )?.priceFloatFormat || 0;
    }
}

export default PrayerModel;

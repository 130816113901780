import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { apiURL } from "../../../Config";
import { Temple } from "../../../domain/models/Temple";
import { Page } from "../../../enums/page-enum";
import { PublicParams } from "../../../enums/public-params-enum";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import { useRedirectToTempleNextPage } from "../../../tools/custom-hooks/useRedirect";
import { Translate } from "../../../types/Translate";
import NoPhotoSVG from "../../base/svg/NoPhotoSVG";
import TitleTruncateWrapper from "../../base/TitleTruncateWrapper";

interface CardsListProps {
    t: Translate;
    temple: Temple;
}

const FoundTempleCard: FC<CardsListProps> = (props) => {
    const religionService = religionsServiceFactory();

    const filePath = props.temple.getSortedFiles(SortingOrder.ASC)[0].filePath;

    const redirect = useSearchParams()[0].get(PublicParams.REDIRECT) as Page;

    const redirectToNextPage = useRedirectToTempleNextPage(props.temple.id);

    return (
        <a className="church__list-link" onClick={() => redirectToNextPage(redirect, false)}>
            <div className="church__list-item">
                <div className="church__list-img">
                    <picture>
                        {filePath ? <img src={filePath && `${apiURL}/files/${filePath}`} alt="" /> : <NoPhotoSVG />}
                    </picture>
                </div>
                <div className="church__list-inner">
                    {/* <span className="hover-text-title" data-hover={props.temple.name}>
                        <div className="church__card-text">
                            <div className="church__list-text text-truncate">{props.temple.name}</div>
                        </div>
                    </span> */}

                    <TitleTruncateWrapper
                        content={<h3 className="church__list-text">{props.temple.name}</h3>}
                        dataHover={props.temple.name}
                        wrapperClassName="hover-text-title"
                        textSpecsArray={[{ 1200: 38 }, { 99999: 44 }]}
                    />
                    <div className="church__card-text text-truncate">
                        {props.t("religions." + religionService.getReligion(props.temple.religionId)?.label)}
                    </div>
                    <div className="church__card-text text-truncate">
                        {props.t(
                            "religionCurrents." +
                                religionService.getReligionCurrent(props.temple.religionCurrentId)?.label
                        )}
                    </div>
                    <div className="church__card-text text-truncate">
                        {props.t(
                            "religionSubspecies." +
                                religionService.getReligionCurrentSubspecies(props.temple.religionSubspeciesId)?.label
                        )}
                    </div>
                    <div className="church__card-text text-truncate">
                        {props.temple.country}, {props.temple.city}
                    </div>
                </div>
            </div>
        </a>
    );
};

export default withTranslation()(FoundTempleCard);

import { FC } from "react";
import { Navigate, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import MainAdminComponent from "../components/admin/MainAdminComponent";
import Main from "../components/admin/main/Main";
import Temples from "../components/admin/temples/Temples";
import Temple from "../components/admin/temples/temple/Temple";
import BasePayment from "../components/base/payments/BasePayment";
import BasePayments from "../components/base/payments/BasePayments";
import BaseUpdates from "../components/base/change-requests/TempleChangesRequestsTab";
import BaseUpdate from "../components/base/change-requests/change-request/ChangeRequestEntityTab";
import CustomerSupportTicketsPage from "../components/admin/customer-support/CustomerSupportTicketsPage";
import CustomerSupportTicketDetails from "../components/admin/customer-support/CustomerSupportTicketDetails";
import Charts from "../components/admin/charts/Charts";
import PayoutsPage from "../components/base/payouts-tab/PayoutsPage";
import PayoutDetails from "../components/base/payouts-tab/payout-details/PayoutDetails";
import { PayoutsPageType } from "../enums/payouts-page-enum";

interface AdminRoutesProps {}

const AdminRoutes: FC<AdminRoutesProps> = () => {
    return (
        <Routes>
            <Route path="/" element={<MainAdminComponent />}>
                <Route path="main" element={<Main />} />

                <Route path="temples" element={<Temples />} />
                <Route path="temples/:templeId/details" element={<Temple isSuperAdminUser={true} />} />
                <Route
                    path="temples/:templeId/payments"
                    element={<Temple isSuperAdminUser={true} isTemplePayments={true} />}
                />
                <Route
                    path="temples/:templeId/payments/:paymentId"
                    element={<BasePayment isSuperAdminUser={true} isTemplePayments={true} />}
                />
                <Route path="temples/:templeId/visits" element={<Temple isSuperAdminUser={true} />} />

                <Route path="payments" element={<BasePayments isSuperAdminUser={true} />} />
                <Route path="payments/:paymentId" element={<BasePayment isSuperAdminUser={true} />} />

                <Route path="updates" element={<BaseUpdates isSuperAdminUser={true} />} />
                <Route path="updates/:requestId" element={<BaseUpdate isSuperAdminUser={true} />} />

                {/* <Route path="visited" element={<h1>visited</h1>} /> */}
                {/* <Route path="messenger" element={<h1>messenger</h1>} /> */}

                <Route path="charts" element={<Charts />} />
                {/* <Route path="transfers" element={<h1>transfers</h1>} /> */}
                <Route path="customer-support-tickets" element={<CustomerSupportTicketsPage />} />
                <Route
                    path="customer-support-tickets/:customerSupportTicketId"
                    element={<CustomerSupportTicketDetails />}
                />
                <Route path="payouts" element={<PayoutsPage payoutsPageType={PayoutsPageType.PAYOUTS}/>} />
                <Route path="payouts/credits" element={<PayoutsPage payoutsPageType={PayoutsPageType.CREDITS}/>} />
                <Route path="payouts/:payoutId" element={<PayoutDetails />} />

                <Route path="/" element={<Navigate to="main" replace />} />
                <Route path="*" element={<Navigate to="main" replace />} />
            </Route>
        </Routes>
    );
};

export default AdminRoutes;

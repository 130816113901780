import { BaseEntity } from "../domain/models/BaseEntity";
import { BaseOrderedEntity } from "../domain/models/BaseOrderedEntity";
import { Constructor } from "../types/Constructor";

export function BaseOrderedEntityMixin(
    Base: Constructor<BaseEntity>
): Constructor<BaseOrderedEntity> {
    return class extends Base implements BaseOrderedEntity {
        private isChanged?: boolean = false;

        order: number;
        initialOrder: number;

        get needToBeRemoved(): boolean {
            return !!(this.id && this.isDeleted);
        }

        get isAbandoned(): boolean {
            return !this.id && !!this.isDeleted;
        }

        get isValidChanges(): boolean {
            return this.id && !this.isDeleted && this.isChanged;
        }

        markAsRemoved() {
            this.setIsDeleted(true);
        }

        markAsChanged() {
            this.setIsChanged(true);
        }

        setIsChanged(isChanged: boolean): void {
            this.isChanged = isChanged;
        }

        resetOrder() {
            this.order = this.initialOrder;
        }
    };
}

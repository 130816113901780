import { FormikProps } from "formik";
import { FC } from "react";
import { withTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";
import { TIME_MASK } from "../../../../../constants";
import { WeekScheduleForm } from "../../../../../domain/models/dto/WeekScheduleDTO";
import { Temple } from "../../../../../domain/models/Temple";
import { getValidationClass } from "../../../../../tools/Tools";

interface WeekScheduleProps {
    t(key?: string): string;
    temple: Temple;

    isErrorVisible: boolean;
    weekScheduleForm: FormikProps<WeekScheduleForm>;
}

const WeekSchedule: FC<WeekScheduleProps> = (props) => {
    const begin = props.t("templeRegister.schedules.begin");
    const end = props.t("templeRegister.schedules.end");

    return (
        <div className="schedule">
            <h2 className="date__subtitle schedule__subtitle">{props.t("templeRegister.schedules.title")}</h2>

            <div className="schedule__items">
                {props.isErrorVisible && <p>{props.t("errors.common")}</p>}

                <div className="schedule__block-item">
                    <div className="schedule__block-day">{props.t("templeRegister.schedules.monday")}</div>

                    <div className="schedule__block-time">
                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "mondayBegin"
                                )}`}
                                type="text"
                                name="mondayBegin"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.mondayBegin}
                                placeholder={begin}
                            />
                        </div>

                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "mondayEnd"
                                )}`}
                                type="text"
                                name="mondayEnd"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.mondayEnd}
                                placeholder={end}
                            />
                        </div>
                    </div>
                </div>
                <div className="schedule__block-item">
                    {props.weekScheduleForm.errors.mondayBegin}
                    {props.weekScheduleForm.errors.mondayEnd}
                </div>

                <div className="schedule__block-item">
                    <div className="schedule__block-day">{props.t("templeRegister.schedules.tuesday")}</div>

                    <div className="schedule__block-time">
                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "tuesdayBegin"
                                )}`}
                                type="text"
                                name="tuesdayBegin"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.tuesdayBegin}
                                placeholder={begin}
                            />
                        </div>

                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "tuesdayEnd"
                                )}`}
                                type="text"
                                name="tuesdayEnd"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.tuesdayEnd}
                                placeholder={end}
                            />
                        </div>
                    </div>
                </div>
                <div className="schedule__block-item">
                    {props.weekScheduleForm.errors.tuesdayBegin}
                    {props.weekScheduleForm.errors.tuesdayEnd}
                </div>

                <div className="schedule__block-item">
                    <div className="schedule__block-day">{props.t("templeRegister.schedules.wednesday")}</div>

                    <div className="schedule__block-time">
                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "wednesdayBegin"
                                )}`}
                                type="text"
                                name="wednesdayBegin"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.wednesdayBegin}
                                placeholder={begin}
                            />
                        </div>

                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "wednesdayEnd"
                                )}`}
                                type="text"
                                name="wednesdayEnd"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.wednesdayEnd}
                                placeholder={end}
                            />
                        </div>
                    </div>
                </div>
                <div className="schedule__block-item">
                    {props.weekScheduleForm.errors.wednesdayBegin}
                    {props.weekScheduleForm.errors.wednesdayEnd}
                </div>

                <div className="schedule__block-item">
                    <div className="schedule__block-day">{props.t("templeRegister.schedules.thursday")}</div>

                    <div className="schedule__block-time">
                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "thursdayBegin"
                                )}`}
                                type="text"
                                name="thursdayBegin"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.thursdayBegin}
                                placeholder={begin}
                            />
                        </div>

                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "thursdayEnd"
                                )}`}
                                type="text"
                                name="thursdayEnd"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.thursdayEnd}
                                placeholder={end}
                            />
                        </div>
                    </div>
                </div>
                <div className="schedule__block-item">
                    {props.weekScheduleForm.errors.thursdayBegin}
                    {props.weekScheduleForm.errors.thursdayEnd}
                </div>

                <div className="schedule__block-item">
                    <div className="schedule__block-day">{props.t("templeRegister.schedules.friday")}</div>

                    <div className="schedule__block-time">
                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "fridayBegin"
                                )}`}
                                type="text"
                                name="fridayBegin"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.fridayBegin}
                                placeholder={begin}
                            />
                        </div>

                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "fridayEnd"
                                )}`}
                                type="text"
                                name="fridayEnd"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.fridayEnd}
                                placeholder={end}
                            />
                        </div>
                    </div>
                </div>
                <div className="schedule__block-item">
                    {props.weekScheduleForm.errors.fridayBegin}
                    {props.weekScheduleForm.errors.fridayEnd}
                </div>

                <div className="schedule__block-item">
                    <div className="schedule__block-day">{props.t("templeRegister.schedules.saturday")}</div>

                    <div className="schedule__block-time">
                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "saturdayBegin"
                                )}`}
                                type="text"
                                name="saturdayBegin"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.saturdayBegin}
                                placeholder={begin}
                            />
                        </div>

                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "saturdayEnd"
                                )}`}
                                type="text"
                                name="saturdayEnd"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.saturdayEnd}
                                placeholder={end}
                            />
                        </div>
                    </div>
                </div>
                <div className="schedule__block-item">
                    {props.weekScheduleForm.errors.saturdayBegin}
                    {props.weekScheduleForm.errors.saturdayEnd}
                </div>

                <div className="schedule__block-item">
                    <div className="schedule__block-day">{props.t("templeRegister.schedules.sunday")}</div>

                    <div className="schedule__block-time">
                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "sundayBegin"
                                )}`}
                                type="text"
                                name="sundayBegin"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.sundayBegin}
                                placeholder={begin}
                            />
                        </div>

                        <div className="schedule__input-wrap">
                            <ReactInputMask
                                mask={TIME_MASK}
                                className={`schedule__input  ${getValidationClass(
                                    props.weekScheduleForm,
                                    "sundayEnd"
                                )}`}
                                type="text"
                                name="sundayEnd"
                                onChange={props.weekScheduleForm.handleChange}
                                value={props.weekScheduleForm.values.sundayEnd}
                                placeholder={end}
                            />
                        </div>
                    </div>
                </div>
                <div className="schedule__block-item">
                    {props.weekScheduleForm.errors.sundayBegin}
                    {props.weekScheduleForm.errors.sundayEnd}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(WeekSchedule);

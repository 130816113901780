import { FC, useCallback, useRef, useState } from "react";
import CustomerSupportTicketsHeader from "./CustomerSupportTicketsHeader";
import { CustomerSupportTicketsCollection } from "../../../domain/models/CustomerSupportTicket";
import customerSupportTicketServiceFactory from "../../../services/CustomerSupportTicketServiceImpl";
import { CustomerSupportTicketFields } from "../../../enums/customer-support-ticket-fields-enum";
import CustomerSupportTicketsTable from "./CustomerSupportTicketsTable";
import { DEFAULT_OFFSET } from "../../../constants";
import { useSortingColumns } from "../../../tools/custom-hooks/table-hooks/useSortingColumns";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import AdminPagePagination from "../../base/AdminPagePagination";

interface CustomerSupportTicketsPageProps {}

const CustomerSupportTicketsPage: FC<CustomerSupportTicketsPageProps> = (_props) => {
    const [data, setData] = useState<CustomerSupportTicketsCollection>(null);
    const dataTableRef = useRef<HTMLDivElement>(null);
    const columnsHandlers = useSortingColumns(SortingOrder.DESC);

    const paginationCallback = useCallback(
        async (currentPage: number, pageSize: number, changeContent: boolean) =>
            customerSupportTicketServiceFactory().getCachedCustomerSupportTickets(
                currentPage,
                pageSize,
                DEFAULT_OFFSET,
                columnsHandlers.currentActiveColumn as CustomerSupportTicketFields,
                columnsHandlers.sortingOrder,
                changeContent || columnsHandlers.reloadCache
            ),
        [columnsHandlers.currentActiveColumn, columnsHandlers.reloadCache, columnsHandlers.sortingOrder]
    );

    return (
        <>
            {data && (
                <>
                    <CustomerSupportTicketsHeader totalRows={data?.totalRows} />
                    <div ref={dataTableRef} className="inner__content23">
                        <CustomerSupportTicketsTable
                            customerSupportTickets={data?.rows}
                            sortingColumnsHandlers={columnsHandlers}
                        />
                    </div>
                </>
            )}

            <div className="church__pagination__admin__block">
                <AdminPagePagination
                    additionalPaginationClass="search__data__pagination"
                    pagesCount={data?.totalPages}
                    wrapperRef={dataTableRef}
                    data={data}
                    reloadCache={columnsHandlers.reloadCache}
                    setData={setData}
                    setReloadCache={columnsHandlers.setReloadCache}
                    serviceCallback={paginationCallback}
                />
            </div>
        </>
    );
};

export default CustomerSupportTicketsPage;

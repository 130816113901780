import { FC } from "react";
import { useSwiper } from "swiper/react";

interface SwiperArrowsProps {
    hideSwiperArrows: boolean;
}

const SwiperArrows: FC<SwiperArrowsProps> = (props) => {
    const swiper = useSwiper();

    return (
        <div className="popular__arrows">
            {!props.hideSwiperArrows && (
                <div className="popular__arrow popular__arrow--prev" onClick={() => swiper?.slidePrev()}>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z"
                            fill="#323232"
                        />
                    </svg>
                </div>
            )}
            <div className="popular__pagination"></div>
            {!props.hideSwiperArrows && (
                <div className="popular__arrow popular__arrow--next" onClick={() => swiper?.slideNext()}>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.14645 3.14645C7.95118 3.34171 7.95118 3.65829 8.14645 3.85355L11.2929 7H2.5C2.22386 7 2 7.22386 2 7.5C2 7.77614 2.22386 8 2.5 8H11.2929L8.14645 11.1464C7.95118 11.3417 7.95118 11.6583 8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L12.8536 7.85355C13.0488 7.65829 13.0488 7.34171 12.8536 7.14645L8.85355 3.14645C8.65829 2.95118 8.34171 2.95118 8.14645 3.14645Z"
                            fill="#323232"
                        />
                    </svg>
                </div>
            )}
        </div>
    );
};

export default SwiperArrows;

import { FC } from "react";

const EditPencilSVG: FC = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.4 9.66L0 14L4.34 12.6L1.4 9.66Z" fill="#858585"/>
            <path d="M9.21334 1.82356L2.38281 8.65408L5.35261 11.6239L12.1831 4.79336L9.21334 1.82356Z" fill="#858585"/>
            <path d="M13.7888 2.17L11.8288 0.21C11.5488 -0.07 11.1287 -0.07 10.8487 0.21L10.2188 0.84L13.1588 3.78L13.7888 3.15C14.0688 2.87 14.0688 2.45 13.7888 2.17Z" fill="#858585"/>
        </svg>
    )
}

export default EditPencilSVG;

import { apiURL } from "../Config";
import { SearchTempleDataDTO } from "../domain/models/dto/SearchData";
import { SearchTempleDataWithCount } from "../domain/models/SearchData";
import { SearchService } from "../domain/services/SearchService";
import searchTempleDataMapperFactory from "../mappers/SearchTempleDataMapper";
import { get } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";

class SearchServiceImpl extends BaseServiceImpl implements SearchService {
    async getSearchData(query: string, page: number, limit: number, offset: number): Promise<SearchTempleDataWithCount> {
        const searchTempleDataMapper = searchTempleDataMapperFactory();
        try {
            const data = await get(
                `${apiURL}/api/v1/search?query=${query}&page=${page}&limit=${limit}&offset=${offset}`
            );

            const searchData = this.getData<SearchTempleDataDTO[]>(data, []).map(dto => searchTempleDataMapper.fromDTO(dto));

            return {
                totalPages: data.totalPages,
                totalRows: data.totalRows,
                rows: searchData,
            };
        } catch (err) {
            console.log("SearchServiceImpl.getSearchData => ERROR:");
            console.log(err);
        }
    }
}

export default function searchServiceFactory(): SearchService {
    return new SearchServiceImpl();
}

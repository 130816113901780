import * as Yup from "yup";
import { SchemaFactoryProps } from "../../types/SchemaFactory";
import { isValidPhoneNumber } from "react-phone-number-input";

export const CustomerSupportTicketSchemaFactory = (props: SchemaFactoryProps) => {
    const t = props.t;
    return Yup.object().shape({
        firstName: Yup.string()
            .min(1, t("errors.commonErrors.min") + "1")
            .max(40, t("errors.commonErrors.max") + "40")
            .required(t("errors.commonErrors.required")),
        lastName: Yup.string()
            .min(1, t("errors.commonErrors.min") + "1")
            .max(40, t("errors.commonErrors.max") + "40")
            .required(t("errors.commonErrors.required")),
        middleName: Yup.string()
            .min(1, t("errors.commonErrors.min") + "1")
            .max(40, t("errors.commonErrors.max") + "40"),
        email: Yup.string()
            .email(t("errors.userLogin.email"))
            .max(100, t("errors.commonErrors.max") + "100")
            .required(t("errors.commonErrors.required")),
        country: Yup.string()
            .min(2, t("errors.commonErrors.min") + "2")
            .max(100, t("errors.commonErrors.max") + "100")
            .required(t("errors.commonErrors.required")),
        phoneNumber: Yup.string()
            .required(t("errors.commonErrors.required"))
            .test(
                "phoneNumber",
                t("errors.commonErrors.invalidPhoneNumber"),
                function (value) {
                    return value && isValidPhoneNumber(value);
                }
            ),
        customerRole: Yup.number()
            .nullable()
            .required(t("errors.commonErrors.required")),
        subjectType: Yup.number()
            .nullable()
            .required(t("errors.commonErrors.required")),
        conditionsAccepted: Yup.boolean().oneOf(
            [true],
            t("errors.commonErrors.required")
        ),
        description: Yup.string()
            .min(5, t("errors.commonErrors.min") + "1")
            .max(5000, t("errors.commonErrors.max") + "5000")
            .required(t("errors.commonErrors.required")),
    });
};

export const CustomerSupportTicketEditSchemaFactory = (
    props: SchemaFactoryProps
) => {
    const t = props.t;
    return Yup.object().shape({
        status: Yup.number().required(t("errors.commonErrors.required")),
        comment: Yup.string()
            .min(5, t("errors.commonErrors.min") + "1")
            .max(5000, t("errors.commonErrors.max") + "5000")
            .required(t("errors.commonErrors.required")),
    });
};

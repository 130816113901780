import { FC, useRef } from "react";
import { CustomFile } from "../../../domain/models/CustomFile";
import NextArrowSVG from "../svg/NextArrowSVG";
import PrevArrowSVG from "../svg/PrevArrowSVG";
import { apiURL } from "../../../Config";
import { useClickOutOfElement } from "../../../tools/custom-hooks/base-hooks/useClickOutOfElement";

interface FullScreenModalProps {
    photos: CustomFile[];
    closeCallback: () => void;
    activeIndex: number;
    toPreviousPhoto: () => void;
    toNextPhoto: () => void;
}

const FullScreenModal: FC<FullScreenModalProps> = (props) => {
    const filePath = props.photos[props.activeIndex]?.filePath;
    const imageRef = useRef(null);
    const prevArrowRef = useRef(null);
    const nextArrowRef = useRef(null);
    useClickOutOfElement(imageRef, props.closeCallback, [prevArrowRef, nextArrowRef]);
    return (
        <div className="full-screen-modal__wrap">
            <div ref={prevArrowRef} className="full-screen-modal__prev-arrow">
                <PrevArrowSVG onclick={props.toPreviousPhoto} />
            </div>
            <img
                src={filePath ? `${apiURL}/files/${filePath}` : null}
                alt=""
                className="full-screen-modal__img"
                ref={imageRef}
            />
            <span className="full-screen-modal__close" onClick={props.closeCallback}>
                &times;
            </span>
            <div ref={nextArrowRef} className="full-screen-modal__next-arrow">
                <NextArrowSVG onclick={props.toNextPhoto} />
            </div>
        </div>
    );
};

export default FullScreenModal;

import { FC, useState } from "react";
import LoginDetails from "./LoginDetails";
import PersonalDetails from "./PersonalDetails";

interface RegisterProps {}

export interface RegisterData {
    email?: string;
    password?: string;
    confirmPassword?: string;

    name?: string;
    lastname?: string;
    phone?: string;
    temple?: string;
}

const Register: FC<RegisterProps> = () => {
    const [isNextFormVisible, setNextFormVisibility] = useState<boolean>(false);
    const [registerData, setRegisterData] = useState<RegisterData>({
        email: "",
        password: "",
        confirmPassword: "",

        name: "",
        lastname: "",
        phone: "",
        temple: "",
    });

    return (
        <div className="form">
            <div className="form__inner">
                {!isNextFormVisible ? (
                    <LoginDetails
                        goToNextStep={setNextFormVisibility}
                        data={registerData}
                        setRegisterData={setRegisterData}
                    />
                ) : (
                    <PersonalDetails data={registerData} setRegisterData={setRegisterData} />
                )}
            </div>
        </div>
    );
};

export default Register;

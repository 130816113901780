import { FC } from "react";
import Popup from "reactjs-popup";
import ModalWindow from "../../../base/ModalWindow";
import { NonValidPayoutDTO } from "../../../../domain/models/dto/PayoutDTO";
import NonValidPayoutsTable from "./NonValidPayoutsTable";
import { Translate } from "../../../../types/Translate";
import { withTranslation } from "react-i18next";

interface NonValidPayoutsModalProps {
    t: Translate;
    isModalActive: boolean;
    payouts: NonValidPayoutDTO[];
    closeModalCallback: () => void;
    forcedCallback: () => void;
}

const NonValidPayoutsModal: FC<NonValidPayoutsModalProps> = (props) => {
    return (
        <Popup
            open={props.isModalActive}
            modal
            overlayStyle={{ zIndex: 999999, flexDirection: "column" }}
            onClose={props.closeModalCallback}
        >
            <ModalWindow
                contentClass="modal-remove__content non-valid-payouts-table-modal"
                bodyClass="information__articles-article"
                closeCallback={props.closeModalCallback}
                content={
                    <div className="inner__graphics-tabs-wrap inner__graphics-tabs-wrap__none-valid">
                        <NonValidPayoutsTable payouts={props.payouts} />
                        <div className="non-valid-payouts-table-modal__buttons">
                            <button
                                onClick={props.closeModalCallback}
                                className="modal__button modal__button-cancel modal__button-cancel__resolve"
                            >
                                {props.t("admin.payouts.nonValid.close")}
                            </button>
                            <button
                                onClick={props.forcedCallback}
                                className="modal__button modal__button-remove modal__button-remove__resolve"
                            >
                                {props.t("admin.payouts.nonValid.forced")}
                            </button>
                        </div>
                    </div>
                }
            />
        </Popup>
    );
};

export default withTranslation()(NonValidPayoutsModal);

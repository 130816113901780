import { useFormik } from "formik";
import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { MAX_VALUE } from "../../../constants";
import { DonateDTO } from "../../../domain/models/dto/PaymentDTO";
import { PayPalPayment } from "../../../domain/models/PayPalPayment";
import { payPalServiceFactory } from "../../../services/PayPalServiceImpl";
import { DonateSchemaFactory } from "../../../validation-schemas/public-pages/donate-schemas";
import CustomCheckbox from "../../base/CustomCheckbox";
import SuccessPaymentModal from "../../base/SuccessPaymentModal";
import DOL_SVG from "../../base/svg/offering-currency-checkbox/DOL_SVG";
import PayPalCheckoutButton from "../../integrations/paypal/PayPalCheckoutButton";
import { Currency } from "../../../enums/currency-enum";

interface SupportOfferingWithIntegrationProps {
    t(key?: string): string;
    isTempleSupport: boolean;
    templeName: string;
}

const SupportOfferingWithIntegration: FC<SupportOfferingWithIntegrationProps> = (props) => {
    const navigate = useNavigate();

    const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState<boolean>(false);

    const [errorVisible, setErrorVisibility] = useState<string>(null);
    const initialValues: DonateDTO = {
        templeId: undefined,
        senderName: undefined,
        senderEmail: undefined,
        amount: undefined,
        currency: Currency.USD,
        conditionsAccepted: false,
    };

    const location = useLocation().pathname.split("/");
    const templeId = location[2];

    const paypalService = payPalServiceFactory();

    const donateForm = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: DonateSchemaFactory({ t: props.t }),
        onSubmit: () => {},
    });

    const isFormFilled = !!(donateForm.values.amount && donateForm.values.senderName);

    const createPaymentHandler = () => {
        setErrorVisibility("errors.common");
    };

    const paymentHandler = (paymentResult: PayPalPayment) => {
        if (paymentResult && paymentResult.isApproved) {
            setErrorVisibility(null);
            setIsVisibleSuccessModal(true);

            window.scrollTo(0, 0);
            return;
        }

        let error = "";
        if (!paymentResult) {
            error = "payments.paymentFailedToCreate";
        } else if (paymentResult.isFailed) {
            error = "payments.paymentFailed";
        } else if (paymentResult.isCreated) {
            error = "payments.paymentCreated";
        } else if (paymentResult.isNotCreated) {
            error = "payments.paymentWasNotCreated";
        } else {
            error = "errors.common";
        }
        setErrorVisibility(error);
    };

    const updateConditionsAccepted = () => {
        donateForm.setFieldValue("conditionsAccepted", !donateForm.values.conditionsAccepted);
    };

    return (
        <div className="offering__block">
            <div className="offering__text text">{props.t("user.support.offeringText")}</div>
            <div style={{ justifyContent: "center" }} className="offering__currency">
                {/* <div className="offering__currency-item">
                    <label>
                        <input checked type="radio" name="currency" className="offering__currency-checkbox" />
                        <div className="checkbox__style">
                            <GRN_SVG />
                        </div>
                    </label>
                </div> */}
                <div className="offering__currency-item">
                    <label>
                        <input
                            defaultChecked={true}
                            type="radio"
                            name="currency"
                            className="offering__currency-checkbox"
                        />
                        <div className="checkbox__style">
                            <DOL_SVG />
                        </div>
                    </label>
                </div>
                {/* <div className="offering__currency-item">
                    <label>
                        <input checked type="radio" name="currency" className="offering__currency-checkbox" />
                        <div className="checkbox__style">
                            <EU_SVG />
                        </div>
                    </label>
                </div> */}
                {/* <div className="offering__currency-item">
                    <label>
                        <input checked type="radio" name="currency" className="offering__currency-checkbox" />
                        <div className="checkbox__style">
                            <RU_SVG />
                        </div>
                    </label>
                </div> */}
            </div>

            <NumericFormat
                name="amount"
                isAllowed={({ floatValue }) => floatValue <= MAX_VALUE}
                decimalScale={2}
                type="text"
                className="offering__input"
                placeholder="0.00"
                onChange={donateForm.handleChange}
                value={donateForm.values.amount}
            />
            {donateForm.errors.amount}

            <input
                name="senderName"
                type="text"
                className="offering__input"
                placeholder={props.t("user.support.fullNamePlaceholder")}
                onChange={donateForm.handleChange}
                value={donateForm.values.senderName}
            />
            {donateForm.errors.senderName}
            <label>
                <input
                    type="text"
                    placeholder={props.t("user.prayer.payer.email")}
                    className="order-payer__form"
                    name="senderEmail"
                    onChange={donateForm.handleChange}
                    defaultValue={donateForm.values.senderEmail}
                />
                {donateForm.errors.senderEmail}
            </label>
            <CustomCheckbox
                id="formAgreement"
                name="conditionsAccepted"
                onChange={updateConditionsAccepted}
                defaultValue={donateForm.values.conditionsAccepted}
                className="checkbox__input"
                wrapperClassName="order-payer__checkbox"
                labelContent={
                    <span>
                        {props.t("user.prayer.payer.agreement1")}
                        <a href="#">{props.t("user.prayer.payer.agreement2")}</a>
                        {props.t("user.prayer.payer.agreement3")}
                    </span>
                }
                errors={donateForm.errors.conditionsAccepted}
            />

            <div className="button-wrap">
                {/* <button
                    type="button"
                    className="offering__button"
                    onClick={() => donateForm.handleSubmit()}
                >
                        {props.t("user.support.offeringButton")}
                </button> */}
                <PayPalCheckoutButton
                    value={donateForm.values.amount}
                    data={{ ...donateForm.values, templeId: templeId }}
                    isValidData={donateForm.isValid && isFormFilled}
                    setCommonPaymentError={createPaymentHandler}
                    paymentHandler={paymentHandler}
                    executeServiceMethod={paypalService.executeDonatePayment}
                ></PayPalCheckoutButton>
                {errorVisible && <p>{props.t(errorVisible)}</p>}
            </div>

            <Popup open={isVisibleSuccessModal} modal overlayStyle={{ zIndex: 999999 }} onClose={() => navigate("/")}>
                <SuccessPaymentModal
                    title={
                        props.t("user.support.successModal.title1") +
                        (props.templeName
                            ? props.t("user.support.successModal.title2") + props.templeName
                            : props.t("user.support.successModal.title3")
                        ) + props.t("user.support.successModal.title4")
                    }
                    redirectToMainPage={() => navigate(templeId ? `/temple/${templeId}` : "/")}
                />
            </Popup>
        </div>
    );
};

export default withTranslation()(SupportOfferingWithIntegration);

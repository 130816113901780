import * as Yup from "yup";
import { SchemaFactoryProps, SchemaFactoryData } from "../../types/SchemaFactory";


const timeValidate = (props: SchemaFactoryProps) =>
    Yup.string()
        .matches(/^([0-9]{2})\:([0-9]{2})$/, " ")
        .required(props.t("errors.commonErrors.required"));

const dateValidate = (props: SchemaFactoryProps) =>
    Yup.string()
        .matches(/^([0-9]{4})\-([0-1]{1})([0-9]{1})\-([0-9]{2})$/, " ")
        .required(props.t("errors.commonErrors.required"));

export const WorshipScheduleSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const inputRestrictions = {
        name: {
            min: 5,
            max: 100
        },
    }

    return {
        schema: Yup.object().shape({
            id: Yup.string(),
            name: Yup.string()
                .min(inputRestrictions.name.min, t("errors.commonErrors.min") + inputRestrictions.name.min)
                .max(inputRestrictions.name.max, t("errors.commonErrors.max") + inputRestrictions.name.max)
                .required(t("errors.commonErrors.required")),
            date: dateValidate({ t }),
            begin: timeValidate({ t }).test(
                "begin",
                t("errors.commonErrors.beginLowerThanEnd"),
                function (value) {
                    return !(value > this.parent.end);
                }
            ),
            end: timeValidate({ t }),
        }), inputRestrictions
    };
};

import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Temple } from "../../../../domain/models/Temple";
import religionsServiceFactory from "../../../../services/ReligionsServiceImpl";

interface TempleInfoProps {
    t(key?: string): string;
    temple: Temple;
}

const TempleInfo: FC<TempleInfoProps> = (props) => {
    const t = props.t;
    const navigate = useNavigate();
    const religionService = religionsServiceFactory();

    const [isReligionsExist, setIsReligionsExist] = useState<boolean>(false);

    const religionLabel =
        isReligionsExist && props.t("religions." + religionService.getReligion(props.temple.religionId)?.label);
    const religionCurrentLabel =
        isReligionsExist &&
        props.t("religionCurrents." + religionService.getReligionCurrent(props.temple.religionCurrentId)?.label);
    const religionSubspeciesLabel =
        isReligionsExist &&
        props.t(
            "religionSubspecies." +
                religionService.getReligionCurrentSubspecies(props.temple.religionSubspeciesId)?.label
        );

    useEffect(() => {
        if (!props.temple.id) {
            navigate("/admin/temples");
        }

        const setData = async () => {
            const religions = await religionService.getCachedReligions();
            setIsReligionsExist(Boolean(religions));
        };

        setData();
    });

    return (
        <div className="holy__tabs-content active">
            <div className="inner__content4">
                <table className="holy__info">
                    <tbody>
                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.register")}</td>
                            <td className="holy__info-value">{props.temple.createdAt}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.confirm")}</td>
                            {/* TODO */}
                            <td className="holy__info-value">{props.temple.updatedAt}</td>
                        </tr>
                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.name")}</td>
                            <td className="holy__info-value">{props.temple.name}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.country")}</td>
                            <td className="holy__info-value">{props.temple.country}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.city")}</td>
                            <td className="holy__info-value">{props.temple.city}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.religion")}</td>
                            <td className="holy__info-value">{religionLabel}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.current")}</td>
                            <td className="holy__info-value">{religionCurrentLabel}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.subspecies")}</td>
                            <td className="holy__info-value">{religionSubspeciesLabel}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.representative")}</td>
                            <td className="holy__info-value">{props.temple.admin.fullName}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.phone")}</td>
                            <td className="holy__info-value">{props.temple.contactPhone}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.email")}</td>
                            <td className="holy__info-value">{props.temple.contactEmail}</td>
                        </tr>

                        <tr>
                            <td className="holy__info-descr">{t("admin.temples.temple.info.abbot")}</td>
                            <td className="holy__info-value">{props.temple.abbotInfo}</td>
                        </tr>

                        {props.temple.paymentAccounts?.map((account) => (
                            <tr>
                                <td className="holy__info-descr">{t("admin.temples.temple.info.payments")}</td>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="holy__info-value holy__info-pay">
                                                    {t(`paymentMethod.${account.paymentMethod}`)}
                                                </td>
                                                <td className="holy__info-identifier">
                                                    {t("admin.temples.temple.info.paymentsId")}
                                                </td>
                                                <td className="holy__info-value holy__info-pay">{account.accountId}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        ))}

                        {props.temple.bankAccounts.map((bankAccount) => (
                            <tr>
                                <td className="holy__info-descr">
                                    {t("admin.temples.temple.info.bank")}({t(`currency.${bankAccount.currency}`)})
                                </td>
                                <td className="holy__info-value">
                                    <p>{bankAccount.fullName}</p>
                                    <p>{bankAccount.accountId}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default withTranslation()(TempleInfo);

import { FC } from "react";

interface TogglePasswordProps {
    isPasswordVisible: boolean;
    setPasswordVisible(value: boolean): void;
}

const TogglePassword: FC<TogglePasswordProps> = (props) => {
    return (
        <div className={`form__eye ${props.isPasswordVisible && "active"}`} id="toggleRepeat">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => props.setPasswordVisible(!props.isPasswordVisible)}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 9C11.206 9.00524 10.4459 9.32299 9.88447 9.88447C9.32299 10.4459 9.00524 11.206 9 12C9 13.642 10.358 15 12 15C13.641 15 15 13.642 15 12C15 10.359 13.641 9 12 9Z"
                    fill="#858585"
                />
                <path
                    d="M11.9993 5C4.36631 5 2.07231 11.617 2.05131 11.684L1.94531 12L2.05031 12.316C2.07231 12.383 4.36631 19 11.9993 19C19.6323 19 21.9263 12.383 21.9473 12.316L22.0533 12L21.9483 11.684C21.9263 11.617 19.6323 5 11.9993 5ZM11.9993 17C6.64831 17 4.57531 13.154 4.07331 12C4.57731 10.842 6.65131 7 11.9993 7C17.3503 7 19.4233 10.846 19.9253 12C19.4213 13.158 17.3473 17 11.9993 17Z"
                    fill="#858585"
                />
            </svg>
        </div>
    );
};

export default TogglePassword;

import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { User } from "../../../domain/models/User";
import { userServiceFactory } from "../../../services/UserServiceImpl";
import { Translate } from "../../../types/Translate";

interface AdminTempleNameEditorProps {
    t: Translate;

    user: User;
    setIsClicked: (value: boolean) => void;
}

const minTempleNameLength = 2;
const maxTempleNameLength = 200;

const AdminTempleNameEditor: FC<AdminTempleNameEditorProps> = (props) => {
    const [newTempleName, setNewTempleName] = useState<string>(props.user.templeName);
    const [error, setError] = useState<string>(null);
    const updateTempleName = async () => {
        const userService = userServiceFactory();
        const user = await userService.updateUser(newTempleName);
        if (!user) {
            return setError(props.t("errors.common"));
        }

        props.user.setTempleName(newTempleName);
        props.setIsClicked(false);
    };

    const updateName = (value: string) => {
        setError(
            value.length < minTempleNameLength
                ? props.t("errors.commonErrors.min") + minTempleNameLength
                : value.length >= maxTempleNameLength
                ? props.t("errors.commonErrors.max") + maxTempleNameLength
                : null
        );
        setNewTempleName(value);
    };

    return (
        <div className="sidebar__church__name__rename">
            <div className="sidebar__church__name__rename-tab">
                <input
                  maxLength={maxTempleNameLength}
                  minLength={minTempleNameLength}
                  className={"sidebar__church__name__rename-block " + (error ? "sidebar__church__name__invalid" : "")}
                  defaultValue={props.user.templeName}
                  type="text"
                  onChange={(e) => updateName(e.target.value)}
               />

              <button
                 className="sidebar__church__name__button sidebar__church__name__button__save"
                 onClick={() => updateTempleName()}
              >
                  <img src="/img/save_button.png" className="sidebar__church__name__button__img"></img>
              </button>
              <button
                 className="sidebar__church__name__button sidebar__church__name__button__close"
                  onClick={() => props.setIsClicked(false)}
             >
                  <img src="/img/close_button.png" className="sidebar__church__name__button__img"></img>
              </button>
            </div>
            {error}
        </div>
    );
};

export default withTranslation()(AdminTempleNameEditor);

import moment from "moment";
import { DATE_FORMAT } from "../constants";
import { ScheduleMapper } from "../domain/mappers/schedule-mapper";
import { WorshipScheduleDTO } from "../domain/models/dto/WorshipScheduleDTO";
import { WorshipSchedule } from "../domain/models/WorshipSchedule";
import { WorshipScheduleModel } from "../models/WorshipSchedule";
import { SimpleScheduleMapper } from "./SimpleScheduleMapper";

class WorshipScheduleMapper extends SimpleScheduleMapper<WorshipSchedule, WorshipScheduleDTO> {
    protected entityConstructor = WorshipScheduleModel;

    protected scheduleFields = [
        "begin",
        "end",
    ];

    protected toDTOFields: string[] = [
        ...this.scheduleFields,
        "id",
        "templeId",
        "name",
        "date",
        "isDeleted"
    ];

    protected toChangesDTOFields: string[] = ["parentId"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        ...this.toChangesDTOFields,
        ...this.scheduleFields,
        "status",
        "createdAt",
        "updatedAt",
    ];

    fromDTO(model: WorshipScheduleDTO): WorshipSchedule {
        if (!model) {
            return new WorshipScheduleModel();
        }

        this.dateFromDTO(model);
        const worshipSchedule = super.fromDTO(model);
        worshipSchedule.date = moment(model.date, DATE_FORMAT);

        return worshipSchedule;
    }

    toDTO(model: WorshipSchedule): WorshipScheduleDTO {
        if (!model) {
            return;
        }

        const worshipSchedule = super.toDTO(model);
        worshipSchedule.date = model.date.format(DATE_FORMAT);
        this.dateToDTO(worshipSchedule);

        return worshipSchedule;
    }
}

export default function worshipScheduleMapperFactory(): ScheduleMapper<WorshipSchedule, WorshipScheduleDTO> {
    return new WorshipScheduleMapper()
}

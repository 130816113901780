import { PayPalLinkDTO, PayPalPaymentDTO } from "../domain/models/dto/PayPalPaymentDTO";
import { PayPalPayment } from "../domain/models/PayPalPayment";
import { TransactionState } from "../enums/PayPalTransactionStatus";

export class PayPalPaymentModel implements PayPalPayment {
    state: TransactionState;
    transactionId: string;
    links: PayPalLinkDTO[];

    constructor(data: PayPalPaymentDTO) {
        Object.assign(this, data);
    }

    get isNotCreated(): boolean {
        return this.state === TransactionState.NOT_CREATED;
    }

    get isCreated(): boolean {
        return this.state === TransactionState.CREATED;
    }

    get isApproved(): boolean {
        return this.state === TransactionState.APPROVED;
    }

    get isFailed(): boolean {
        return this.state === TransactionState.FAILED;
    }
}

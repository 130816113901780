import { useEffect } from "react";
import { DEFAULT_INPUT_COLOR, INVALID_INPUT_COLOR } from "../../../constants";
import { updateElementStyleByTabIndex } from "../../Tools";
import { FormikProps } from "formik";

const backgroundColor = "backgroundColor";

/**
 * Update backgroundColor of input depending on the form.errors by field tabindex (search tabindex by fieldName);
 * You must be defined tabindex on your input field strictly fieldName;
 * @param form FormikProps<any>
 * @param fieldName string
 *
 */
export const useUpdateInputFieldBackground = (form: FormikProps<any>, fieldName: string) => {
    useEffect(() => {
        updateElementStyleByTabIndex(
            fieldName,
            backgroundColor,
            form.errors[fieldName] ? INVALID_INPUT_COLOR : DEFAULT_INPUT_COLOR
        );
    }, [form.errors, fieldName]);
}

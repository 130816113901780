import { Mapper } from "../domain/mappers/mapper";
import { SearchFieldDTO } from "../domain/models/dto/SearchData";
import { SearchField } from "../domain/models/SearchData";
import { SearchFieldModel } from "../models/SearchFieldModel";
import { SimpleMapper } from "./SimpleMapper";

class SearchFieldMapper extends SimpleMapper<SearchField, SearchFieldDTO> {
    protected entityConstructor = SearchFieldModel;

    protected fromDTOFields: string[] = [
        "fieldName",
        "value",
    ];
}


export default function searchFieldMapperFactory(): Mapper<SearchField, SearchFieldDTO> {
    return new SearchFieldMapper();
}

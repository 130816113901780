import moment from "moment";
import { CustomerSupportTicket } from "../domain/models/CustomerSupportTicket";
import { CustomerSupportTicketDTO } from "../domain/models/dto/CustomerSupportTicketDTO";
import { RecordStatus } from "../enums/record-status";
import { SupportTicketCustomerRole } from "../enums/support-ticket-customer-role";
import { SupportTicketSubject } from "../enums/support-ticket-subject";
import { DATETIME_FORMAT } from "../constants";
import { BaseModelImpl } from "./BaseModelImpl";

export class CustomerSupportTicketModel
    extends BaseModelImpl<CustomerSupportTicketDTO>
    implements CustomerSupportTicket
{
    id?: string;
    userId?: string;
    ticketName?: string;
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    country: string;
    phoneNumber: string;
    customerRole: SupportTicketCustomerRole;
    subjectType: SupportTicketSubject;
    conditionsAccepted: boolean;
    description: string;
    emailSent?: boolean;
    status?: RecordStatus;
    comment?: string;
    language?: string;
    createdAt?: Date;
    updatedAt?: Date;

    private _createdAtStr: string;
    private _updatedAtStr: string;

    constructor(data: CustomerSupportTicketDTO) {
        super();
        this.assignData(data);
    }

    get inReview(): boolean {
        return this.status === RecordStatus.IN_REVIEW;
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}${
            this.middleName ? " " + this.middleName : ""
        }`;
    }

    get isCustomerRegistered(): boolean {
        return !!this.userId;
    }

    get createdAtStr() {
        if (!this._createdAtStr) {
            this._createdAtStr = moment(this.createdAt).format(DATETIME_FORMAT);
        }

        return this._createdAtStr;
    }

    get updatedAtStr() {
        if (!this._updatedAtStr) {
            this._updatedAtStr = moment(this.updatedAt).format(DATETIME_FORMAT);
        }
        return this._updatedAtStr;
    }
}

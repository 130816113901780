import { Moment } from "moment";
import { WorshipScheduleDTO } from "../domain/models/dto/WorshipScheduleDTO";
import { WorshipSchedule } from "../domain/models/WorshipSchedule";
import { BaseRelatedEntityMixinFactory } from "./BaseTempleRelatedModel";

export class WorshipScheduleModel extends BaseRelatedEntityMixinFactory<WorshipScheduleDTO>() implements WorshipSchedule {
    templeId: string;
    name: string;
    date: Moment;
    begin: string;
    end: string;

    constructor(data?: WorshipScheduleDTO) {
        super();
        this.assignData(data);
    }
}

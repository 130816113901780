import { FC, useState } from "react";
import { EntityChart } from "../../../types/charts/EntityChart";
import DateFilter from "../DateFilter";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";

interface ChartFilterProps {
    t: Translate;
    currentPeriodChart: EntityChart;
    prevPeriodChart: EntityChart;
}

const ChartFilter: FC<ChartFilterProps> = (props) => {
    const [isVisiblePrevChartsFilter, setVisiblePrevChartsFilter] = useState<boolean>(false);

    return (
        <div className="inner__info-dates active">
            <DateFilter
                dateParams={props.currentPeriodChart.chartsParams}
                yearHandler={props.currentPeriodChart.yearHandler}
                monthHandler={props.currentPeriodChart.monthHandler}
                dayHandler={props.currentPeriodChart.dayHandler}
                applyCallback={props.currentPeriodChart.applyChartsParams}
            />

            <div className="inner__info-wrap">
                <button
                    className={"inner__info-compare " + (isVisiblePrevChartsFilter ? "active" : "")}
                    onClick={() => setVisiblePrevChartsFilter(!isVisiblePrevChartsFilter)}
                >
                    <span>{props.t("charts.compareButton")}</span>
                    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L4.5 5L8 1" stroke="#323232" strokeLinecap="round" />
                    </svg>
                </button>
            </div>

            {isVisiblePrevChartsFilter ? (
                <DateFilter
                    dateParams={props.prevPeriodChart.chartsParams}
                    yearHandler={props.prevPeriodChart.yearHandler}
                    monthHandler={props.prevPeriodChart.monthHandler}
                    dayHandler={props.prevPeriodChart.dayHandler}
                    applyCallback={props.prevPeriodChart.applyChartsParams}
                    additionalWrapperClassName="inner__info-wrapper--compare active"
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default withTranslation()(ChartFilter);

import _ from "lodash";
import { Mapper } from "../domain/mappers/mapper";
import { ReligionCurrentDTO, ReligionSubspeciesDTO } from "../domain/models/dto/ReligionsDTO";
import { ReligionCurrent, ReligionSubspecies } from "../domain/models/Religions";
import religionSubspeciesMapperFactory from "./ReligionSubspeciesMapper";


class ReligionCurrentMapper implements Mapper<ReligionCurrent, ReligionCurrentDTO> {
    subspeciesMapper: Mapper<ReligionSubspecies, ReligionSubspeciesDTO>;

    constructor() {
        this.subspeciesMapper = religionSubspeciesMapperFactory()
    }

    toDTO(entity: ReligionCurrent): ReligionCurrentDTO {
        return {
            id: parseInt(entity.value),
            label: entity.label,
            religionSubspecies: !_.isEmpty(entity.religionSubspecies)
                ? entity.religionSubspecies.map(subspecies => this.subspeciesMapper.toDTO(subspecies))
                : []
        }
    }

    fromDTO(data: ReligionCurrentDTO): ReligionCurrent {
        return {
            value: data.id.toString(),
            label: data.label,
            religionSubspecies: !_.isEmpty(data.religionSubspecies)
                ? data.religionSubspecies!.map(subspecies => this.subspeciesMapper.fromDTO(subspecies))
                : []
        }
    }
}

export default function religionCurrentMapperFactory(): Mapper<ReligionCurrent, ReligionCurrentDTO> {
    return new ReligionCurrentMapper()
}

import { FC, useState } from "react";
import CorrespondenceForm from "./CorrespondenceForm";
import { CorrespondenceForm as ICorrespondenceForm, Temple } from "../../../../domain/models/Temple";
import Footer from "../footer/Footer";
import { useFormsValidConfirmation } from "../../../../context/FormikFormsProvider";
import { useRedirectToPrevCreateTemplePage } from "../../../../tools/custom-hooks/useRedirect";
import { TempleRegisterPage } from "../../../../enums/temple-register-page-enum";
import { useNavigate } from "react-router-dom";
import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import { CorrespondenceSchemaFactory } from "../../../../validation-schemas/temple-register/correspondence-schemas";
import { Translate } from "../../../../types/Translate";
import { withTranslation } from "react-i18next";
import templeExtensionServiceFactory from "../../../../services/TempleExtensionServiceImpl";
import Popup from "reactjs-popup";
import ModalWindow from "../../../base/ModalWindow";
import ConflictErrorModal from "../../../base/conflict-error-modal/ConflictErrorModal";
import { TempleExtensionModel } from "../../../../models/TempleExtensionModel";
import languageServiceFactory from "../../../../services/LanguageServiceImpl";
import { Langs } from "../../../../enums/langs-enum";

interface CorrespondencePageProps {
    t: Translate;
    temple: Temple;
}

interface CorrespondenceDataResponse {
    status: boolean;
    sendCorrespondenceEmailAgain?: boolean;
}

const nextUrl = "/temple-admin";

const CorrespondencePage: FC<CorrespondencePageProps> = (props) => {
    const templeExtensionService = templeExtensionServiceFactory();
    const navigate = useNavigate();
    const [isErrorVisible, setErrorVisibility] = useState<boolean>(false);
    const [isCorrespondenceEmailSended, setCorrespondenceEmailSended] = useState<boolean>(false);
    const [isNavigateToNextRequired, setNavigateToNextRequired] = useState<boolean>(false);

    useRedirectToPrevCreateTemplePage(props.temple, TempleRegisterPage.GALLERIES, props.temple.isGalleriesComplete);

    const initialValues: ICorrespondenceForm = {
        correspondenceEmail: props.temple.templeExtension?.correspondenceEmail || "",
        correspondenceLanguage:
            props.temple.templeExtension?.correspondenceLanguage || (languageServiceFactory().currentLang as Langs),
    };

    const sendCorrespondenceData = async (temple: Temple, data: ICorrespondenceForm): Promise<CorrespondenceDataResponse> => {
        const incorrectlyResponse = {
            status: false,
            sendCorrespondenceEmailAgain: false,
        };

        if (!props.temple.templeExtension) {
            props.temple.setTempleExtension(new TempleExtensionModel());
        }

        const sendCorrespondenceEmailAgain =
            !!data.correspondenceEmail &&
            !!temple.templeExtension.correspondenceEmail &&
            temple.templeExtension.correspondenceEmail !== data.correspondenceEmail;

        temple.setOriginIdToExtension();
        temple.templeExtension.setCorrespondenceEmail(data.correspondenceEmail);
        temple.templeExtension.setCorrespondenceLanguage(data.correspondenceLanguage);

        if (!temple.isCorrespondenceComplete) {
            setErrorVisibility(true);
            return incorrectlyResponse;
        }

        const updatedExtension = await templeExtensionService.createOrUpdateExtensionData(
            temple.templeExtension,
            sendCorrespondenceEmailAgain
        );

        if (!updatedExtension) {
            setErrorVisibility(true);
            return incorrectlyResponse;
        }

        temple.setTempleExtension(updatedExtension);
        return {
            status: true,
            sendCorrespondenceEmailAgain
        };
    };

    const correspondenceForm: FormikProps<ICorrespondenceForm> = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: CorrespondenceSchemaFactory({ t: props.t }).schema,
        onSubmit: async (values) => {
            const temple = props.temple;

            try {
                setErrorVisibility(false);

                if (_.isEqual(values, correspondenceForm.initialValues)) {
                    navigate(nextUrl);
                    return;
                }

                const result = await sendCorrespondenceData(temple, values);
                if (!result.status) {
                    return;
                }

                if (result.sendCorrespondenceEmailAgain) {
                    setCorrespondenceEmailSended(result.sendCorrespondenceEmailAgain);
                    setNavigateToNextRequired(true);
                    return;
                }

                navigate(nextUrl);
            } catch (err) {
                setErrorVisibility(true);
            }
        },
    });

    const { isFormsDataValid } = useFormsValidConfirmation("correspondenceForm", correspondenceForm);

    const editFormHandler = async () => {
        const result = await sendCorrespondenceData(props.temple, correspondenceForm.values);
        if (!result.status) {
            return setErrorVisibility(true);
        }

        setCorrespondenceEmailSended(result.sendCorrespondenceEmailAgain);
    };

    const closeModalCallback = () => {
        setCorrespondenceEmailSended(false);
        if (isNavigateToNextRequired) {
            navigate(nextUrl);
            setNavigateToNextRequired(false);
        }
    };

    return (
        <div className="date__content">
            <form onSubmit={correspondenceForm.handleSubmit}>
                <main className="date__main">
                    {isErrorVisible && <p>{props.t("errors.common")}</p>}
                    <CorrespondenceForm
                        form={correspondenceForm}
                        initialValues={initialValues}
                        editFormHandler={editFormHandler}
                        disableSaveButton={props.temple.templeExtension?.isCorrespondenceEmailActive}
                    />
                </main>

                <Popup
                    open={isCorrespondenceEmailSended}
                    modal
                    overlayStyle={{ zIndex: 999999 }}
                    onClose={closeModalCallback}
                >
                    <ModalWindow
                        contentClass="modal-remove__content"
                        bodyClass=" "
                        closeCallback={closeModalCallback}
                        content={
                            <>
                                <div className="modal__title">
                                    {props.t("templeRegister.correspondence.successEmailModalText")}{" "}
                                    {correspondenceForm.values.correspondenceEmail}
                                </div>
                            </>
                        }
                    />
                </Popup>
                <ConflictErrorModal />
                <Footer isFormsDataValid={isFormsDataValid} />
            </form>
        </div>
    );
};

export default withTranslation()(CorrespondencePage);

import { FC } from "react";
import { MainElementInfoSlideProps } from "../../../../domain/components/slider-modal-window/MainElementInfoSlideProps";
import { CustomFile } from "../../../../domain/models/CustomFile";
import MainImageSlide from "../../../base/photo-slider/MainImageSlide";

const TempleAbbotPhoto: FC<MainElementInfoSlideProps<CustomFile>> = (props) => {
    return (
        <MainImageSlide
            filePath={props.element?.filePath}
            slideOnClick={props.openModal}
            swiperImageClassName="abbot__image"
        />
    );
};

export default TempleAbbotPhoto;

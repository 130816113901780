import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TempleInfo from "./TempleInfo";
import templeServiceFactory from "../../../../services/TempleServiceImpl";
import { Temple as ITemple } from "../../../../domain/models/Temple";
import BasePayments from "../../../base/payments/BasePayments";
import { withTranslation } from "react-i18next";

interface TempleProps {
    t(key?: string): string;
    isSuperAdminUser: boolean;
    isTemplePayments?: boolean;
}

enum TemplePath {
    VISITS = "visits",
    PAYMENTS = "payments",
    DETAILS = "details",
}

const Temple: FC<TempleProps> = (props) => {
    const navigate = useNavigate();
    const path = useLocation().pathname.split("/");
    const currentId = path[3];

    const [temple, setTemple] = useState<ITemple>();

    const isDetails = path[4] === TemplePath.DETAILS;
    const isPayments = path[4] === TemplePath.PAYMENTS;
    const isVisits = path[4] === TemplePath.VISITS;

    useEffect(() => {
        const setData = async () => {
            setTemple(await templeServiceFactory().getTempleById(currentId, true));
        };

        if (!temple) {
            setData();
        }
    });

    return (
        <>
            <div className="inner-page__topping">
                <div className="inner-page__wrap">
                    <button onClick={() => navigate("/admin/temples")} className="back-button">
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1.5 5L6 9" stroke="#F9F9F9" strokeLinecap="round" />
                        </svg>
                    </button>
                    <div className="inner-page__title">{temple?.name}</div>
                </div>

                <a className="inner-page__review" href={`/temple/${temple?.id}`}>
                    {props.t("admin.temples.temple.main.review")}
                </a>
            </div>

            <div className="holy">
                <div className="holy__tabs">
                    <div className="holy__tabs-caption">
                        <div
                            onClick={() => navigate(`/admin/temples/${currentId}/details`)}
                            className={isDetails ? "active" : undefined}
                        >
                            {props.t("admin.temples.temple.main.info")}
                        </div>
                        <div
                            // onClick={() => navigate(`/admin/temples/${currentId}/visits`)}
                            // className={isVisits ? "active" : undefined}
                        >
                            {props.t("admin.temples.temple.main.activities")}
                        </div>
                        <div
                            onClick={() => navigate(`/admin/temples/${currentId}/payments`)}
                            className={isPayments ? "active" : undefined}
                        >
                            {props.t("admin.temples.temple.main.payments")}
                        </div>
                    </div>

                    {temple && isDetails && <TempleInfo  temple={temple} />}
                    {temple && isPayments && (
                        <BasePayments

                            templeId={temple.id}
                            isSuperAdminUser={props.isSuperAdminUser}
                            isTemplePayments={props.isTemplePayments}
                            additionalChartClassName='inner__chart-alignment'
                            additionalTableClassName='inner__content23__temple-payments-page'
                        />
                    )}
                    {temple && isVisits && <h1>visits</h1>}
                </div>
            </div>
        </>
    );
};

export default withTranslation()(Temple);

import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Page } from "../../enums/page-enum";
import { SortingOrder } from "../../enums/sorting-order-enum";
import { TempleSearchParameters } from "../../enums/temple-search-parameters-enum";
import { TempleSortFields } from "../../enums/temple-sort-fields-enum";
import {useRedirectToTempleNextPage} from "../../tools/custom-hooks/useRedirect";
import Logo from "../base/Logo";

interface PublicPageFooterProps {
    t(key?: string): string;
}

const PublicPageFooter: FC<PublicPageFooterProps> = (props) => {
    const location = useLocation().pathname.split("/");
    const navigate = useNavigate();

    const currentPath = location[1];
    const currentTempleId = (currentPath === Page.TEMPLE) && location[2];

    const showFullWebSiteVersion = () => {
        const viewport = document.querySelector("meta[name=viewport]");
        if (viewport) {
            viewport.setAttribute("content", "width=1600");
        }
    };

    const redirectToTempleNextPage = useRedirectToTempleNextPage(currentTempleId);

    const reloadCacheParam = "reloadCache=true";

    return (
        <section className="footer--main">
            <div className="container--main">
                <div className="footer--main__inner">
                    <div className="footer--main__top">
                        <div className="footer--main__block">
                            <Logo />
                            <div className="footer--main__social">
                                <a href="#" className="footer--main__social-link"></a>
                                <a href="#" className="footer--main__social-link"></a>
                                <a href="#" className="footer--main__social-link"></a>
                            </div>
                        </div>
                        <div className="footer--main__buttons">
                            <a href="/faq" className="footer--main__button footer--main__button--blue">
                                {props.t("user.faq.footerButton")}
                            </a>
                            <a href="/support" className="footer--main__button footer--main__button--yellow">
                                {props.t("user.footer.support")}
                            </a>
                            <span className="footer--main__rights">© 2021 all rights reserved</span>
                        </div>
                    </div>
                    <div className="footer--main__bottom">
                        <div className="footer--main__middle">
                            <span className="footer--main__pages">{props.t("user.footer.pages")}</span>
                            <div className="footer--main__middle-inner">
                                <div className="footer--main__block">
                                    <ul className="footer--main__navigation">
                                        <li className="footer--main__navigation-item">
                                            <a href="/">{props.t("user.footer.main")}</a>
                                        </li>
                                        <li className="footer--main__navigation-item">
                                            <a href="/choose-temple">{props.t("user.footer.popular")}</a>
                                        </li>
                                        <li className="footer--main__navigation-item">
                                            <a href="/about">{props.t("user.footer.about")}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer--main__block">
                                    <ul className="footer--main__navigation">
                                        <li className="footer--main__navigation-item">
                                            <a onClick={() => {
                                                window.scrollTo(0, 0);
                                                navigate(`/${Page.CHOOSE_TEMPLE}?${TempleSearchParameters.IS_WORSHIP_LINK_EXIST}=true&${reloadCacheParam}`);
                                            }}>{props.t("user.footer.temple")}</a>
                                        </li>
                                        <li className="footer--main__navigation-item">
                                            <a onClick={() => redirectToTempleNextPage(Page.ORDER_PRAYER, true, TempleSortFields.VISITS)}>
                                                {props.t("user.footer.orderPrayer")}
                                            </a>
                                        </li>
                                        <li className="footer--main__navigation-item">
                                            <a onClick={() => redirectToTempleNextPage(Page.SUPPORT, true, TempleSortFields.CREATED_AT, SortingOrder.DESC)}>
                                                {props.t("user.footer.offering")}
                                            </a>
                                        </li>
                                        <li className="footer--main__navigation-item">
                                            <a href="/support">{props.t("user.footer.support")}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer--main__block">
                            <ul className="footer--main__navigation">
                                <li className="footer--main__navigation-item footer--main__navigation-item--grey footer--main__navigation-item--grey__disable">
                                    <a href="/">{props.t("user.footer.main")}</a>
                                </li>
                                <li className="footer--main__navigation-item footer--main__navigation-item--grey footer--main__navigation-item--grey__disable">
                                    <a href="/choose-temple">{props.t("user.footer.popular")}</a>
                                </li>
                                <li className="footer--main__navigation-item footer--main__navigation-item--grey">
                                    <a href="/about">{props.t("user.footer.about")}</a>
                                </li>
                                <li className="footer--main__navigation-item footer--main__navigation-item--grey">
                                    <a href="/temple-admin">{props.t("user.footer.templeRepresentatives")}</a>
                                </li>
                                <li className="footer--main__navigation-item footer--main__navigation-item--grey">
                                    <a href="#">{props.t("user.footer.confidentiality")}</a>
                                </li>
                                <li className="footer--main__navigation-item footer--main__navigation-item--grey">
                                    <a href="#">{props.t("user.footer.rules")}</a>
                                </li>
                            </ul>
                            <button className="footer--main__navigation-btn" onClick={showFullWebSiteVersion}>
                                {props.t("user.footer.fullVersion")}
                            </button>
                        </div>
                        <div className="footer--main__buttons">
                            <a href="/faq" className="footer--main__button footer--main__button--blue">
                                {props.t("user.faq.footerButton")}
                            </a>
                            <a href="/support" className="footer--main__button footer--main__button--yellow">
                                {props.t("user.footer.support")}
                            </a>
                            <span className="footer--main__rights">© 2021 all rights reserved</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(PublicPageFooter);

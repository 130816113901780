import { FC, useEffect, useState } from "react";
import TempleSearchFilters from "./TempleSearchFilters";
import TempleSearchPageHeader from "./TempleSearchPageHeader";
import TempleSearchPageContent from "./TempleSearchPageContent";
import religionsServiceFactory from "../../../services/ReligionsServiceImpl";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import PagePagination from "../../base/PagePagination";
import { TempleSearchParameters, TempleWithCount } from "../../../domain/models/Temple";
import { DEFAULT_OFFSET, DEFAULT_PAGE, TEMPLE_PAGE_SIZE } from "../../../constants";
import { getCurrentTempleSortFieldTranslation, TempleSortFields } from "../../../enums/temple-sort-fields-enum";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import { useSearchParams } from "react-router-dom";
import { PublicParams } from "../../../enums/public-params-enum";
import { Page } from "../../../enums/page-enum";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";
import { SelectProps } from "../../../domain/models/SelectProps";
import { TempleSearchParameters as TempleSearchParametersEnum } from "../../../enums/temple-search-parameters-enum";

interface TempleSearchPageProps {
    t: Translate;
}

const TempleSearchPage: FC<TempleSearchPageProps> = (props) => {
    const [isList, setIsList] = useState<boolean>(false);
    const [isDataExist, setIsDataExist] = useState<boolean>(false);
    const [isSearchingParamsUpdated, setSearchingParamsUpdated] = useState<boolean>(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const redirectPage = searchParams.get(PublicParams.REDIRECT) as Page;
    const sortField = searchParams.get(PublicParams.SORT) as TempleSortFields;
    const sortingOrder = searchParams.get(PublicParams.ORDER) as SortingOrder;
    const reloadCache = Boolean(searchParams.get(PublicParams.RELOAD_CACHE));
    const isWorshipLinkExist = Boolean(searchParams.get(TempleSearchParametersEnum.IS_WORSHIP_LINK_EXIST));

    const headerTitle = isWorshipLinkExist ? TempleSearchParametersEnum.IS_WORSHIP_LINK_EXIST : redirectPage;

    const [isSortFieldParamExist, setIsSortFieldParamExist] = useState(false);

    const [templeSearchParameters, setTempleSearchParameters] = useState<TempleSearchParameters>({
        religionId: null,
        religionCurrentId: null,
        religionSubspeciesId: null,
        country: null,
        city: null,
        other: null,
        isWorshipLinkExist: isWorshipLinkExist,
    });
    const [templeSortField, setTempleSortField] = useState<SelectProps>(
        getCurrentTempleSortFieldTranslation(props.t, TempleSortFields.POPULAR)
    );

    const [temples, setTemples] = useState<TempleWithCount>();
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(DEFAULT_PAGE);

    const [isChangeTemple, setIsChangeTemple] = useState<boolean>(false);

    useEffect(() => {
        const religionService = religionsServiceFactory();
        const templeService = templeServiceFactory();

        if (sortField) {
            setTempleSortField(getCurrentTempleSortFieldTranslation(props.t, sortField));
            searchParams.delete(PublicParams.SORT);
            setSearchParams(searchParams);
            setIsSortFieldParamExist(!!sortField);
            return;
        }

        if (isWorshipLinkExist !== templeSearchParameters?.isWorshipLinkExist) {
            setTempleSearchParameters({ ...templeSearchParameters, isWorshipLinkExist });
            return;
        }

        const uploadData = async () => {
            const [temples, religions, cities, countries] = await Promise.all([
                templeService.getCachedTemples(
                    currentPageIndex,
                    TEMPLE_PAGE_SIZE,
                    DEFAULT_OFFSET,
                    sortField || (templeSortField?.value as TempleSortFields) || TempleSortFields.NAME,
                    sortingOrder || SortingOrder.ASC,
                    [],
                    false,
                    false,
                    true,
                    isChangeTemple || isSearchingParamsUpdated || reloadCache,
                    templeSearchParameters?.city?.label,
                    templeSearchParameters?.country?.label,
                    templeSearchParameters?.religionId,
                    templeSearchParameters?.religionCurrentId,
                    templeSearchParameters?.religionSubspeciesId,
                    templeSearchParameters?.other,
                    templeSearchParameters?.isWorshipLinkExist
                ),
                religionService.getCachedReligions(),
                templeService.getCachedCities(),
                templeService.getCachedCountries(),
            ]);

            setIsChangeTemple(false);
            setSearchingParamsUpdated(false);
            setTemples(temples);
            setIsDataExist(!!religions.length && !!cities.length && !!countries.length);
        };

        if (!isDataExist || !temples || isSearchingParamsUpdated || isChangeTemple || reloadCache) {
            uploadData();

            if (reloadCache) {
                searchParams.delete(PublicParams.RELOAD_CACHE);
                setSearchParams(searchParams);
            }
        }
    }, [
        props.t,
        temples,
        currentPageIndex,
        isChangeTemple,
        isSearchingParamsUpdated,
        isDataExist,
        templeSearchParameters,
        templeSortField,
        sortingOrder,
        reloadCache,
        searchParams,
        setSearchParams,
        sortField,
        isWorshipLinkExist,
    ]);

    const updateTempleSortField = (value: SelectProps) => {
        setTempleSortField(value);
        setSearchingParamsUpdated(true);
        setIsSortFieldParamExist(false);
    };

    return (
        <>
            <div className="main--main">
                <div className="church">
                    <div className="container--big">
                        <div className="church__inner">
                            <TempleSearchFilters
                                setCurrentPageIndex={setCurrentPageIndex}
                                setSearchingParamsUpdated={setSearchingParamsUpdated}
                                setTempleSearchParameters={setTempleSearchParameters}
                                templeSearchParameters={templeSearchParameters}
                            />
                            <div className="church__right">
                                <TempleSearchPageHeader
                                    isList={isList}
                                    setIsList={setIsList}
                                    templeSortField={templeSortField}
                                    updateTempleSortField={updateTempleSortField}
                                    isSortFieldUpdated={isSortFieldParamExist}
                                    setCurrentPageIndex={setCurrentPageIndex}
                                    setSearchingParamsUpdated={setSearchingParamsUpdated}
                                    setTempleSearchParameters={setTempleSearchParameters}
                                    templeSearchParameters={templeSearchParameters}
                                    title={headerTitle && props.t(`user.religions.redirectPages.${headerTitle}`)}
                                />
                                <div className="church__no-temples-title">
                                    {temples?.rows.length === 0
                                        ? props.t("user.religions.header.withoutTemplesTitle")
                                        : ""}
                                </div>
                                {isDataExist && <TempleSearchPageContent isList={isList} temples={temples} />}
                                <div className="choose__temple__pagination">
                                    {temples?.totalPages > DEFAULT_PAGE && (
                                        <PagePagination
                                            pagesCount={temples.totalPages}
                                            isSearchingParamsUpdated={isSearchingParamsUpdated}
                                            currentPageIndex={currentPageIndex}
                                            setCurrentPageIndex={setCurrentPageIndex}
                                            setIsChangeContent={setIsChangeTemple}
                                            visibleItemCount={4}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(TempleSearchPage);
